import React from 'react';
import MainCard from 'ui-component/cards/MainCard';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useStyles } from 'views/utilities/Style';
import { CurrencyFormatter } from 'utils/utils';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

function OpeningBalance({ doc }) {
    const classes = useStyles();
    const globalDefaults = useSelector((state) => state.login.globalDefaults);
    const { t } = useTranslation();

    return (
        <MainCard
            border
            isWhite
            title={
                <Box display={'flex'} alignItems={'center'}>
                    <ReceiptLongIcon sx={{ mr: 1 }} /> {t('documents:openblnc')}
                </Box>
            }
            boxShadow
            divider
        >
            <div className={classes.orderDetailsContainer}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>N°</TableCell>
                            <TableCell align="left">{t('documents:mp')}</TableCell>
                            <TableCell align="right">{t('documents:oa')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {doc?.balance_details?.map((el, i) => (
                            <TableRow key={i}>
                                <TableCell>
                                    <strong>{el?.idx}</strong>
                                </TableCell>
                                <TableCell align="left">
                                    <strong>{el?.mode_of_payment}</strong>
                                </TableCell>
                                <TableCell align="right">
                                    <strong>
                                        <CurrencyFormatter
                                            locale="fr-FR"
                                            minimumFractionDigits={3}
                                            value={el?.opening_amount}
                                            currency={globalDefaults?.default_currency}
                                        />
                                    </strong>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        </MainCard>
    );
}

export default OpeningBalance;
