import React, { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Button, ClickAwayListener, Grid, Modal, Typography, useMediaQuery } from '@mui/material';
import { Box, Stack, useTheme } from '@mui/system';
import useFetch from 'hooks/useFetch';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { SetBulkEditFields, SetBulkEditIds, SetNotification } from 'store/services/api';
import MainCard from 'ui-component/cards/MainCard';
import { formatErpResponse } from 'utils/utils';
import { modalStyle } from 'views/utilities/ModalStyle';
import DataRender from 'ui-component/tables/DataRender';
import { useTranslation } from 'react-i18next';
import Switch from '@mui/material/Switch';
import SupplierModalForm from './component/SupplierModalForm';
import { styled } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import { DataGrid } from '@mui/x-data-grid';
import { frLocal } from '../../utils/dataGridFRLocal';
import SupplierDownloader from './component/SupplierDownloader';
import DataImporter from './component/DataImporter';

const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)'
        }
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff'
            }
        }
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200
        })
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box'
    }
}));

const SupplierList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [selectionModel, setSelectionModel] = useState([]);

    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const [openEdit, setOpenEdit] = useState(false);
    const [openEditBulk, setOpenEditBulk] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [openMiniForm, setOpenMiniForm] = React.useState(false);
    const handleOpenForm = () => setOpenMiniForm(true);
    const handleCloseForm = () => setOpenMiniForm(false);
    const [loadingButtonForm, setLoadingButtonForm] = React.useState(false);
    const [loadingButton, setLoadingButton] = useState(false);
    const [loadingBulkEdit, setLoadingBulkEdit] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [openDownloader, setOpenDownloader] = useState(false);
    const [openImporter, setOpenImporter] = useState(false);

    const handleOpenDownloader = () => setOpenDownloader(true);
    const handleCloseDownloader = () => setOpenDownloader(false);
    const handleOpenImporter = () => setOpenImporter(true);
    const handleCloseImporter = () => setOpenImporter(false);

    const handleEditClose = () => {
        setOpenEdit(false);
        setSelectionModel([]);
    };
    const handleDeleteClose = () => {
        setOpenDelete(false);
        setSelectionModel([]);
    };
    const handleBulkEditClose = () => {
        setOpenEditBulk(false);
        dispatch(SetBulkEditFields([]));
    };
    const [disabled, setDisabled] = useState(1);

    const supplierReqData = {
        doctype: 'Supplier',
        fields: [
            '`tabSupplier`.`name`',
            '`tabSupplier`.`fournisseur_code`',
            '`tabSupplier`.`supplier_group`',
            /*'`tabSupplier`.`supplier_primary_contact`',*/
            '`tabSupplier`.`supplier_name`',
            '`tabSupplier`.`supplier_type`',
            '`tabSupplier`.`disabled`'
        ],
        start: 0,
        page_length: 500,
        order_by: '`tabSupplier`.`modified` desc'
    };

    const { data, isPending, refetch } = useFetch(`/api/get-list`, supplierReqData, 'POST');

    const handleEdit = (row) => {
        navigate(`/oms/supplier-details/${row}`);
    };

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };
    const [selectionFieldsModel, setSelectionFieldsModel] = useState([]);

    const columns = [
        { field: 'id', headerName: 'ID', flex: 1, visible: false },
        {
            field: 'fieldName',
            headerName: t('common:nom-du-champ'),
            flex: 1
        }
    ];

    const rows = [
        { id: 'supplier_group', fieldName: t('common:Supplier-Group') },
        { id: 'supplier_name', fieldName: t('common:Supplier-Name') },
        { id: 'disabled', fieldName: t('common:Disable') },
        { id: 'supplier_type', fieldName: t('common:Supplier-Type') }
    ];

    const columnsImport = ['supplier_name', 'country', 'supplier_type', 'supplier_group', 'mobile_no', 'fournisseur_code', 'email_id'];
    const handleBulkEdit = () => {
        dispatch(SetBulkEditIds([]));
        dispatch(SetBulkEditIds(selectionModel));

        dispatch(SetBulkEditFields([]));
        dispatch(SetBulkEditFields(selectionFieldsModel));

        navigate('/oms/fournisseur-bulk-edit');
    };
    const fieldSelection = (
        <Box display={'grid'} sx={{ placeItems: 'center' }}>
            <ClickAwayListener onClickAway={() => handleBulkEditClose()}>
                <Box width={500} mt={'5%'}>
                    <MainCard divider title={t('common:editer-en-masse')}>
                        <Typography id="modal-modal-description" sx={{ fontSize: 14 }}>
                            {t('common:Sélectionner-les-champs-à-modifier')}
                        </Typography>
                        <Box height={400} width={'100%'} mt={3}>
                            <DataGrid
                                initialState={{
                                    columns: {
                                        columnVisibilityModel: {
                                            id: false
                                        }
                                    }
                                }}
                                rows={rows}
                                columns={columns}
                                checkboxSelection
                                getRowId={(row) => row.id}
                                localeText={frLocal}
                                hideFooterPagination
                                hideFooterSelectedRowCount
                                rowSelectionModel={selectionFieldsModel}
                                onRowSelectionModelChange={(newSelectionModel) => {
                                    setSelectionFieldsModel(newSelectionModel);
                                }}
                            />
                        </Box>
                        <Box display={'flex'} justifyContent={'flex-end'} mt={0}>
                            <Box>
                                <Grid container spacing={1}>
                                    <Grid item>
                                        <Button variant="outlined" color="blue" size="large" onClick={handleBulkEditClose}>
                                            {t('common:cancel')}
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button color="blue" size="large" onClick={() => handleBulkEdit()} variant="contained">
                                            {t('common:contine')}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </MainCard>
                </Box>
            </ClickAwayListener>
        </Box>
    );

    const handleBulkChangeStatus = () => {
        const editSupplierStatus = {
            doctype: 'Supplier',
            freeze: true,
            docnames: selectionModel,
            action: 'update',
            data: {
                disabled: disabled
            }
        };
        setLoadingBulkEdit(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/update-status`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(editSupplierStatus)
        })
            .then((res) => {
                setLoadingBulkEdit(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                    throw Error('Something went wrong');
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    dispatch(SetNotification({ message: 'Le statut du fournisseur a été modifié avec succès.', code: 'success' }));
                    handleEditClose();
                    refetch({});
                    setDisabled(1);
                }
            });
    };

    const handleDelete = (row) => {
        setLoadingButton(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/delete-doc`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doctype: 'Supplier', name: row[0] })
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(
                        SetNotification({
                            code: 'warning',
                            message: t('common:supplierLinkedToRFQ', { supplier: row[0] })
                        })
                    );
                    console.log(data?.message);
                } else {
                    dispatch(SetNotification({ code: 'success', message: t('common:supplierDeletedSuccess') }));
                    refetch({});
                    handleClose();
                }
            });
    };
    const handleBulkDelete = () => {
        const rows = selectionModel;
        setLoadingButton(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/delete-items`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doctype: 'Supplier', items: rows })
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data._server_messages || data.exc_type === 'LinkExistsError') {
                    try {
                        dispatch(
                            SetNotification({
                                code: 'warning',
                                message: t('common:suppliersBulkDeleteError')
                            })
                        );
                        handleDeleteClose();
                    } catch (e) {
                        dispatch(
                            SetNotification({
                                code: 'warning',
                                message: t('common:suppliersBulkDeleteError')
                            })
                        );
                        handleDeleteClose();
                    }
                    return;
                }

                if (!data._server_messages && data.exc_type !== 'LinkExistsError') {
                    dispatch(
                        SetNotification({
                            code: 'success',
                            message: t('common:suppliersBulkDeleted')
                        })
                    );
                    handleDeleteClose();
                    setTimeout(() => {
                        refetch({});
                    }, 2000);
                }
            })
            .catch(() => {
                setLoadingButton(false);
                dispatch(
                    SetNotification({
                        code: 'error',
                        message: t('common:AnErrorHasOccurred')
                    })
                );
            });
    };

    const changeStatusConfirm = (
        <Box sx={modalStyle}>
            <MainCard divider title={t('core:confirm')}>
                <Typography id="modal-modal-description" sx={{ fontSize: 14 }}>
                    {t('core:changeStatus')} <strong>{selectionModel.join(', ')}</strong> ?
                </Typography>
                <Box display={'flex'} justifyContent={'center'} mt={3}>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Typography>{t('core:deactivated')}</Typography>
                        <AntSwitch
                            onClick={() => setDisabled(disabled === 1 ? 0 : 1)}
                            name="disabled"
                            inputProps={{ 'aria-label': 'ant design' }}
                        />
                        <Typography>{t('core:activated')}</Typography>
                    </Stack>
                </Box>
                <Box display={'flex'} justifyContent={'flex-end'} mt={3}>
                    <Box>
                        <Grid container spacing={1}>
                            <Grid item>
                                <LoadingButton
                                    color="blue"
                                    size="large"
                                    onClick={() => handleBulkChangeStatus()}
                                    variant="contained"
                                    loading={loadingBulkEdit}
                                >
                                    {t('users:yes')}
                                </LoadingButton>
                            </Grid>
                            <Grid item>
                                <Button color="blue" size="large" variant="outlined" onClick={handleEditClose}>
                                    {t('users:no')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </MainCard>
        </Box>
    );
    const BulkDeleteConfirm = (
        <Box sx={modalStyle}>
            <MainCard divider title={t('core:confirm')}>
                <Typography id="modal-modal-description" sx={{ fontSize: 14 }}>
                    {t('common:delete')} <strong>{selectionModel.join(', ')}</strong> ?
                </Typography>
                <Box display={'flex'} justifyContent={'flex-end'} mt={3}>
                    <Box>
                        <Grid container spacing={1}>
                            <Grid item>
                                <Button color="blue" size="large" variant="outlined" onClick={handleDeleteClose}>
                                    {t('users:no')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <LoadingButton
                                    color="blue"
                                    size="large"
                                    onClick={() => handleBulkDelete()}
                                    variant="contained"
                                    loading={loadingBulkEdit}
                                >
                                    {t('users:yes')}
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </MainCard>
        </Box>
    );

    const dataImporter = (
        <Box sx={modalStyle}>
            <MainCard divider title={t('core:import')}>
                <DataImporter refetch={refetch} handleClose={handleClose} columns={columnsImport} doctype="Warehouse" />
            </MainCard>
        </Box>
    );

    const title = (
        <Box p={-1} display={'flex'} justifyContent={'space-between'}>
            {t('core:suppList')}
            <Box>
                <Grid container spacing={1}>
                    <Grid item>
                        {/*  <Button
                            startIcon={<AddCircleIcon />}
                            variant="outlined"
                            color="blue"
                            sx={{ marginTop: -3 }}
                            onClick={handleOpenForm}
                        >
                            {t('core:addSupplier')}
                        </Button>*/}

                        <Button
                            startIcon={<AddCircleIcon />}
                            variant="outlined"
                            color="blue"
                            onClick={() => navigate('/oms/new-supplier')}
                            sx={{ marginTop: -3 }}
                        >
                            {t('core:addSupplier')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="text" color="blue" onClick={handleOpenImporter} sx={{ marginTop: -3, ml: 1 }}>
                            {t('core:import')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="text" color="blue" onClick={handleOpenDownloader} sx={{ marginTop: -3, ml: 1 }}>
                            {t('common:export')}
                        </Button>
                    </Grid>
                    {Array.isArray(selectionModel) && selectionModel?.length !== 0 && (
                        <Grid item>
                            <Button
                                startIcon={<EditIcon color="warning" />}
                                variant="outlined"
                                color="warning"
                                sx={{ marginTop: -3 }}
                                spacing={1}
                                onClick={() => setOpenEdit(true)}
                            >
                                {t('core:editStatus')}
                            </Button>
                            <Button
                                startIcon={<EditIcon />}
                                variant="outlined"
                                color="blue"
                                sx={{ marginTop: -3, marginLeft: 1 }}
                                spacing={1}
                                onClick={() => setOpenEditBulk(true)}
                            >
                                {t('core:edit')}
                            </Button>
                            <Button
                                startIcon={<DeleteIcon color="error" />}
                                variant="outlined"
                                color="error"
                                sx={{ marginTop: -3, marginLeft: 1 }}
                                onClick={() => setOpenDelete(true)}
                            >
                                {t('common:delete')}
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </Box>
        </Box>
    );

    const addSupplier = (
        <Box sx={modalStyle}>
            <MainCard divider title={t('core:addSupplier')}>
                <SupplierModalForm
                    handleClose={handleCloseForm}
                    loadingButton={loadingButtonForm}
                    setLoadingButton={setLoadingButtonForm}
                    refetch={refetch}
                />
            </MainCard>
        </Box>
    );

    return (
        <div>
            <DataRender
                refetch={refetch}
                data={data}
                tag={'ID'}
                loading={isPending}
                selectionModel={selectionModel}
                title={title}
                setSelectionModel={setSelectionModel}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                tag={t('columns:reference')}
                hasCheckBoxes
                notshow={['name']}
            />
            <Modal onClose={handleEditClose} open={openEdit}>
                {changeStatusConfirm}
            </Modal>
            <Modal onClose={handleDeleteClose} open={openDelete}>
                {BulkDeleteConfirm}
            </Modal>
            <Modal onClose={handleBulkEditClose} open={openEditBulk}>
                {fieldSelection}
            </Modal>
            <Modal open={openMiniForm} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                {addSupplier}
            </Modal>
            <Modal onClose={handleCloseImporter} open={openImporter}>
                {dataImporter}
            </Modal>
            <Modal onClose={handleCloseDownloader} open={openDownloader}>
                <Box sx={modalStyle}>
                    <MainCard divider title={t('common:ExportSuppliers')}>
                        <SupplierDownloader suppliersData={data} isPending={isPending} refetch={refetch} />
                    </MainCard>
                </Box>
            </Modal>
        </div>
    );
};
export default SupplierList;
