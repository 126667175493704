import { Box, Typography } from '@mui/material';
import React from 'react';
import MainCard from 'ui-component/cards/MainCard';
import PersonIcon from '@mui/icons-material/Person';
import { useTranslation } from 'react-i18next';

function UserDetails({ doc }) {
    const { t } = useTranslation();
    return (
        <MainCard
            border
            isWhite
            title={
                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} gap={5}>
                    <Box display={'flex'} alignItems={'center'}>
                        <PersonIcon sx={{ mr: 1 }} /> {t('documents:ud')}
                    </Box>
                </Box>
            }
            boxShadow
            divider
        >
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: 50 }}>
                <div>
                    <Typography color={'GrayText'} variant="body2" fontWeight={500}>
                        {t('documents:comp')}:
                        <Typography color={'ActiveCaption'} variant="body2" fontWeight={700}>
                            {doc?.company}
                        </Typography>
                    </Typography>
                </div>
                <div>
                    <Typography color={'GrayText'} variant="body2" fontWeight={500}>
                        {t('documents:posp')}:
                        <Typography color={'ActiveCaption'} variant="body2" fontWeight={700}>
                            {doc?.pos_profile}
                        </Typography>
                    </Typography>
                    <Typography color={'GrayText'} variant="body2" fontWeight={500}>
                        {t('documents:cashier')}:
                        <Typography color={'ActiveCaption'} variant="body2" fontWeight={700}>
                            {doc?.user}
                        </Typography>
                    </Typography>
                </div>
            </div>
        </MainCard>
    );
}

export default UserDetails;
