import React from 'react';
import MainCard from 'ui-component/cards/MainCard';
import PersonIcon from '@mui/icons-material/Person';
import { Box, Typography } from '@mui/material';
import { formatDate } from 'helpers/utils';
import { useTranslation } from 'react-i18next';

function CustomerOrderDetails({ doc, isCustomer }) {
    const { t } = useTranslation();
    return (
        <MainCard
            border
            isWhite
            title={
                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} gap={5}>
                    <Box display={'flex'} alignItems={'center'}>
                        <PersonIcon sx={{ mr: 1 }} /> {isCustomer ? t('documents:cus') : t('documents:sup')}
                    </Box>
                    <Typography color={'ActiveCaption'} variant="body2" fontWeight={500}>
                        {t('documents:placed')}: <strong>{formatDate(doc?.creation, 'DD-MM-YYYY HH:mm')}</strong>
                    </Typography>
                </Box>
            }
            boxShadow
            divider
        >
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: 50 }}>
                <div>
                    <Typography color={'GrayText'} variant="body2" fontWeight={500}>
                        {isCustomer ? t('documents:cus') : t('documents:sup')}:
                        <Typography color={'ActiveCaption'} variant="body2" fontWeight={700}>
                            {isCustomer ? doc?.customer_name : doc?.supplier_name}
                        </Typography>
                    </Typography>
                    <Typography color={'GrayText'} variant="body2" fontWeight={500}>
                        Email:
                        <Typography color={'ActiveCaption'} variant="body2" fontWeight={700}>
                            {doc?.contact_email ?? '-'}
                        </Typography>
                    </Typography>
                    <Typography color={'GrayText'} variant="body2" fontWeight={500}>
                        {t('documents:phone')}:
                        <Typography color={'ActiveCaption'} variant="body2" fontWeight={700}>
                            {doc?.contact_mobile ?? '-'}
                        </Typography>
                    </Typography>
                </div>
                <div>
                    <Typography color={'GrayText'} variant="body2" fontWeight={500}>
                        {isCustomer ? (
                            <>
                                {t('documents:paydue')}:
                                <Typography color={'ActiveCaption'} variant="body2" fontWeight={700}>
                                    {formatDate(doc?.due_date, 'DD-MM-YYYY')}
                                </Typography>
                            </>
                        ) : (
                            <>
                                {t('documents:reqby')}:
                                <Typography color={'ActiveCaption'} variant="body2" fontWeight={700}>
                                    {formatDate(doc?.items?.[0]?.schedule_date, 'DD-MM-YYYY')}
                                </Typography>
                            </>
                        )}
                    </Typography>
                    {doc?.is_consolidated === 1 ? (
                        <Typography color={'GrayText'} variant="body2" fontWeight={500}>
                            {t('documents:consolidated')}: <span>&#10003;</span>
                        </Typography>
                    ) : null}

                    {doc?.loyalty_program ? (
                        <Typography color={'GrayText'} variant="body2" fontWeight={500}>
                            {t('documents:lp')}:
                            <Typography color={'ActiveCaption'} variant="body2" fontWeight={700}>
                                {doc?.loyalty_program}
                            </Typography>
                        </Typography>
                    ) : null}
                </div>
            </div>
        </MainCard>
    );
}

export default CustomerOrderDetails;
