import { Button, Grid, Modal, Typography, IconButton, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import DataRender from 'ui-component/tables/DataRender';
import useFetch from 'hooks/useFetch';
import { LoadingButton } from '@mui/lab';
import MainCard from 'ui-component/cards/MainCard';
import { modalStyle } from 'views/utilities/ModalStyle';
import { useDispatch } from 'react-redux';
import { SetNotification } from 'store/services/api';
import { formatErpResponse } from 'utils/utils';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { useTranslation } from 'react-i18next';
import DetailModal from './component/DetailModal';

const SalesPersonList = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [selectionModel, setSelectionModel] = useState([]);

    const [open, setOpen] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [loadingBulkEdit, setLoadingBulkEdit] = useState(false);
    const [detailModalOpen, setDetailModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    const currencyReqData = {
        doctype: 'Sales Person',
        fields: [
            '`tabSales Person`.`name`',
            '`tabSales Person`.`sales_person_code`',
            '`tabSales Person`.`sales_person_name`',
            '`tabSales Person`.`cin`',
            '`tabSales Person`.`grade`',
            '`tabSales Person`.`enabled`',
            '`tabSales Person`.`mobile`',
            '`tabSales Person`.`personal_email`',
            '`tabSales Person`.`company`',
            '`tabSales Person`.`date_of_joining`'
        ],
        order_by: '`tabSales Person`.`modified` desc',
        start: 0,
        page_length: 500
    };

    const { data, isPending, refetch } = useFetch(`/api/get-list`, currencyReqData, 'POST');

    const handleEdit = (row) => {
        navigate(`/core/vendeur/${row}`);
    };

    const handleDeleteClose = () => {
        setOpenDelete(false);
        setSelectionModel([]);
    };

    const handleOpenDetailModal = (row) => {
        setSelectedRow(row);
        setDetailModalOpen(true);
    };

    const handleCloseDetailModal = () => {
        setDetailModalOpen(false);
        setSelectedRow(null);
    };

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const handleDelete = (row) => {
        setLoadingButton(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/delete-doc`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doctype: 'Sales Person', name: row[0] })
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    dispatch(SetNotification({ message: t('common:vendeurDeleteSuccess'), code: 'success' }));
                    handleClose();
                    refetch({});
                }
            });
    };

    const handleBulkDelete = () => {
        const rows = selectionModel;
        setLoadingButton(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/delete-items`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doctype: 'Sales Person', items: rows })
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(
                        SetNotification({
                            code: 'warning',
                            message: t('common:clientsBulkDeleteError')
                        })
                    );
                } else {
                    dispatch(SetNotification({ code: 'success', message: t('common:salesPersonBulkDeleteSuccess') }));
                    handleDeleteClose();
                    setTimeout(() => {
                        refetch({});
                    }, 2000);
                }
                window.location.reload();
            });
    };

    const deleteConfirm = (
        <Box sx={modalStyle}>
            <MainCard divider title={t('common:confirm')}>
                <Typography id="modal-modal-description" sx={{ fontSize: 14 }}>
                    {t('common:deletePermanently')} <strong>{selectionModel}</strong> ?
                </Typography>
                <Box display={'flex'} justifyContent={'flex-end'} mt={3}>
                    <Box>
                        <Grid container spacing={1}>
                            <Grid item>
                                <Button color="blue" size="large" variant="outlined" onClick={handleClose}>
                                    {t('common:non')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <LoadingButton
                                    color="blue"
                                    size="large"
                                    onClick={() => handleDelete(selectionModel)}
                                    variant="contained"
                                    loading={loadingButton}
                                >
                                    {t('common:oui')}
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </MainCard>
        </Box>
    );

    const BulkDeleteConfirm = (
        <Box sx={modalStyle}>
            <MainCard divider title={t('core:confirm')}>
                <Typography id="modal-modal-description" sx={{ fontSize: 14 }}>
                    {t('common:delete')} <strong>{selectionModel.join(', ')}</strong> ?
                </Typography>
                <Box display={'flex'} justifyContent={'flex-end'} mt={3}>
                    <Box>
                        <Grid container spacing={1}>
                            <Grid item>
                                <Button color="blue" size="large" variant="outlined" onClick={handleDeleteClose}>
                                    {t('users:no')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <LoadingButton
                                    color="blue"
                                    size="large"
                                    onClick={() => handleBulkDelete()}
                                    variant="contained"
                                    loading={loadingBulkEdit}
                                >
                                    {t('users:yes')}
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </MainCard>
        </Box>
    );

    const title = (
        <Box p={-1} display={'flex'} justifyContent={'space-between'}>
            <Grid>
                {t('core:sp')}
                <IconButton
                    aria-haspopup="true"
                    onClick={() => navigate('/core/vendeur-tree')}
                    color="inherit"
                    aria-label="cart"
                    variant="rounded"
                    size="small"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.mediumAvatar,
                        transition: 'all .2s ease-in-out',
                        background: 'transparent !important',
                        color: theme.palette.primary.dark,
                        borderRadius: 50,
                        height: 20,
                        ml: 2,
                        mt: -0.5
                    }}
                >
                    <AccountTreeIcon
                        sx={{
                            height: 20
                        }}
                        color="blue"
                    />
                </IconButton>
            </Grid>
            <Box>
                <Grid container spacing={1}>
                    {false && (
                        <>
                            <Grid item>
                                {selectionModel.length === 1 && (
                                    <Button
                                        startIcon={<DeleteIcon color="error" />}
                                        variant="outlined"
                                        color="error"
                                        onClick={handleOpen}
                                        sx={{ marginTop: -3 }}
                                    >
                                        {t('common:delete')}
                                    </Button>
                                )}
                            </Grid>
                            <Grid item>
                                {selectionModel.length === 1 && (
                                    <Button
                                        startIcon={<EditIcon />}
                                        variant="outlined"
                                        color="blue"
                                        onClick={() => handleEdit(selectionModel)}
                                        sx={{ marginTop: -3 }}
                                    >
                                        {t('common:edit')}
                                    </Button>
                                )}
                            </Grid>
                        </>
                    )}
                    <Grid item>
                        <Button
                            startIcon={<AddCircleIcon />}
                            variant="outlined"
                            color="blue"
                            onClick={() => navigate('/core/new-vendeur')}
                            sx={{ marginTop: -3 }}
                        >
                            {t('core:addsp')}
                        </Button>
                    </Grid>
                    {Array.isArray(selectionModel) && selectionModel?.length !== 0 && (
                        <Grid item>
                            <Button
                                startIcon={<DeleteIcon color="error" />}
                                variant="outlined"
                                color="error"
                                sx={{ marginTop: -3, marginLeft: 1 }}
                                onClick={() => setOpenDelete(true)}
                            >
                                {t('common:delete')}
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </Box>
        </Box>
    );

    return (
        <div>
            <DataRender
                refetch={refetch}
                data={data}
                tag={'ID'}
                loading={isPending}
                selectionModel={selectionModel}
                title={title}
                setSelectionModel={setSelectionModel}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                hasCheckBoxes
                notshow={['name']}
                handleOpenDetailModal={handleOpenDetailModal}
                Visualize={true}
            />
            <Modal onClose={handleClose} open={open}>
                {deleteConfirm}
            </Modal>
            <Modal onClose={handleDeleteClose} open={openDelete}>
                {BulkDeleteConfirm}
            </Modal>
            <DetailModal open={detailModalOpen} onClose={handleCloseDetailModal} row={selectedRow} />
        </div>
    );
};

export default SalesPersonList;
