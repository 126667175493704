import { IconButton, InputAdornment, Modal, OutlinedInput, Pagination, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import { Box } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import DeleteIcon from '@mui/icons-material/Delete';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';
import DoneIcon from '@mui/icons-material/Done';
import Loader from 'ui-component/Loader';
import PerfectScrollbar from 'react-perfect-scrollbar';
import SearchIcon from '@mui/icons-material/Search';
import { GlobalfilterData } from 'utils/formatColumnsHeaders';
import { useEffect } from 'react';
import RenameForm from './RenameForm';

const Reasons = ({ isReasonsPending, reasons, refetch, handleReasonInsert, handleReasonDelete, doctype, title, handleReasonEdit }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const [page, setPage] = useState(1);
    const [filteredReason, setFilteredReason] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [newReason, setNewReason] = useState('');
    const [isInserting, setIsInserting] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editingReason, setEditingReason] = useState(null);

    const [editState, setEditState] = useState({
        doctype: '',
        docname: '',
        new_name: ''
    });

    const handleEdit = (reason) => {
        setEditingReason(reason);
        setEditState({
            doctype: doctype,
            docname: reason.name,
            new_name: reason.name
        });
        setIsEditModalOpen(true);
    };

    const handleRenameReason = async (newName) => {
        await handleReasonEdit(editingReason, newName);
        handleCloseEditModal();
        refetch();
    };

    const handleSearchReason = (event) => {
        setSearchTerm(event.target.value);
        let data = GlobalfilterData(reasons, event.target.value.toLowerCase(), ['name']);
        setFilteredReason(data?.length ? data : []);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsInserting(true);
        await handleReasonInsert(newReason);
        setNewReason('');
        setIsInserting(false);
    };

    const handleDelete = async (reason) => {
        await handleReasonDelete(reason);
    };

    const handleCloseEditModal = () => {
        setIsEditModalOpen(false);
        setEditingReason(null);
    };

    useEffect(() => {
        if (reasons?.length > 0) {
            setFilteredReason(reasons);
        }
    }, [reasons]);

    if (isReasonsPending) return <Loader />;

    return (
        <MainCard
            border
            isWhite
            title={
                <Box p={-1} display={'flex'} justifyContent={'space-between'}>
                    <Box display={'flex'} alignItems={'center'}>
                        <PsychologyAltIcon sx={{ mr: 1 }} /> {title}
                    </Box>
                    <Box>
                        <TextField
                            id="standard-helperText"
                            placeholder={t('settings:text-51')}
                            variant="standard"
                            value={searchTerm}
                            onChange={handleSearchReason}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Box>
                </Box>
            }
        >
            <form onSubmit={handleSubmit}>
                <OutlinedInput
                    placeholder={t('settings:text-52')}
                    endAdornment={
                        <LoadingButton
                            loading={isInserting}
                            endIcon={<DoneIcon color={newReason === '' ? 'disabled' : 'blue'} />}
                            variant="contained"
                            disabled={newReason === ''}
                            type="submit"
                        >
                            {t('settings:text-53')}
                        </LoadingButton>
                    }
                    value={newReason}
                    onChange={(e) => setNewReason(e.target.value)}
                    sx={{ width: '100%' }}
                />
            </form>
            <Box sx={{ mt: 2 }}>
                <PerfectScrollbar style={{ overflowX: 'hidden' }}>
                    {filteredReason?.slice((page - 1) * 5, page * 5)?.map((reason, index) => (
                        <Box
                            key={index}
                            sx={{
                                bgcolor: theme.palette.grey[100],
                                borderRadius: 2,
                                p: 1,
                                mb: 1,
                                display: 'flex',
                                flexDirection: 'row',
                                flexGrow: 1,
                                cursor: 'pointer',
                                alignItems: 'center'
                            }}
                        >
                            <Box width={'95%'}>
                                <Typography fontWeight={800} fontSize={13} color="black" sx={{ ml: 1 }}>
                                    <span style={{ color: theme.palette.blue.main, marginLeft: 2, fontWeight: 500 }}>{reason?.name}</span>
                                </Typography>
                            </Box>
                            <Box display={'flex'} alignItems={'center'}>
                                {doctype !== 'Quotation Lost Reason' && (
                                    <IconButton onClick={() => handleEdit(reason)}>
                                        <EditIcon color="primary" />
                                    </IconButton>
                                )}
                                <IconButton onClick={() => handleDelete(reason)}>
                                    <DeleteIcon color="error" />
                                </IconButton>
                            </Box>
                        </Box>
                    ))}

                    {Math.ceil(filteredReason?.length / 5) > 0 && (
                        <Box display="flex" justifyContent="center" m={4}>
                            <Pagination
                                color="primary"
                                count={Math.ceil(filteredReason?.length / 5)}
                                onChange={(e, newPage) => setPage(newPage)}
                                page={page}
                            />
                        </Box>
                    )}
                </PerfectScrollbar>
            </Box>

            <Modal
                open={isEditModalOpen}
                onClose={handleCloseEditModal}
                aria-labelledby="edit-reason-modal"
                aria-describedby="modal-to-edit-reason-name"
            >
                <RenameForm
                    state={editState}
                    setState={setEditState}
                    message_success={t('crm:edited_motif_retour_success')}
                    title={t('crm:edit_motif_retour')}
                    handleClose={handleCloseEditModal}
                    handleRename={handleRenameReason}
                />
            </Modal>
        </MainCard>
    );
};

export default Reasons;
