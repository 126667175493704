import { Divider, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded';
import PersonIcon from '@mui/icons-material/Person';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import { formatColumnsHeaders, formatDate } from 'utils/formatColumnsHeaders';
import LogoSection from 'layout/MainLayout/LogoSection';
import { useStyles } from 'views/utilities/Style';
import { useTranslation } from 'react-i18next';

const PrintItemListTemplate = React.forwardRef(({ data }, ref) => {
    const { t, i18n } = useTranslation();
    const globalDefaults = useSelector((state) => state.login.globalDefaults);
    const userDetails = useSelector((state) => state.login.userDetails);
    const classes = useStyles();
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);

    const getColumns = (data) => {
        let columns = [];
        if (data && Array.isArray(data) && data?.length !== 0) {
            Object?.keys(data[0]).map((column) => {
                columns.push({ field: column, headerName: t(formatColumnsHeaders(column)), flex: 1 });
            });

            columns = columns.filter((el) => el.field !== 'name' && el.field !== 'creation');
            return columns;
        }
        return [];
    };
    const getLanguage = () => i18n.language || window.localStorage.i18nextLng;
    useEffect(() => {
        if (data[0]) {
            setRows(data);
            setColumns(getColumns(data));
        }
    }, [data, getLanguage()]);

    return (
        <div ref={ref}>
            <Box p={2} display={'flex'} flexDirection={'column'} flexGrow={1}>
                {/* company-section */}
                <Box p={2} pl={5} display={'flex'} flexDirection={'column'} letterSpacing={1}>
                    <Typography variant="h7" fontSize={11} fontWeight={700}>
                        <BusinessRoundedIcon sx={{ mb: -0.5, mr: 1 }} />
                        {globalDefaults?.default_company}
                    </Typography>
                    <Divider />
                    <Typography variant="h7" fontSize={11} fontWeight={700}>
                        <LocationOnRoundedIcon sx={{ mb: -0.5, mr: 1 }} />
                        {globalDefaults.country}
                    </Typography>
                    <Divider />
                    <Typography variant="h7" fontSize={11} fontWeight={700}>
                        <ArticleRoundedIcon sx={{ mb: -0.5, mr: 1 }} />
                        Liste Produits
                    </Typography>
                    <Divider />
                    <Typography variant="h7" fontSize={11} fontWeight={700}>
                        <PersonIcon sx={{ mb: -0.5, mr: 1 }} />
                        {userDetails.email}
                    </Typography>
                    <Divider />
                    <Typography variant="h7" fontSize={11} fontWeight={700}>
                        <CalendarMonthRoundedIcon sx={{ mb: -0.5, mr: 1 }} />
                        {formatDate(Date.now(), 'YYYY-MM-DD HH:mm')}
                    </Typography>
                    <Divider />
                </Box>

                {/* content */}
                <Box pl={5} pr={5} mt={4}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((el, i) => (
                                    <TableCell key={i}>{el.headerName}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((el, i) => (
                                <TableRow key={i}>
                                    {columns.map((e, k) => (
                                        <TableCell key={k}>{el[e.field]}</TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Box>

                {/* doc-footer */}
                <Box>
                    <Divider />
                    <Box sx={{ opacity: 0.3 }} mt={3} display={'flex'} justifyContent="center">
                        <LogoSection />
                    </Box>
                </Box>
            </Box>
        </div>
    );
});

export default PrintItemListTemplate;
