// assets
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { ROLE } from '../configs/constants';
// constant
const icons = {
    LocalShippingIcon
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const oms = {
    id: 'oms',
    title: 'OMS',
    caption: 'système de gestion des commandes',
    type: 'group',
    role: [ROLE.ADMIN, ROLE.CASHIER, ROLE.ADMIN_WAREHOUSE],
    children: [
        {
            id: 'OMS',
            title: 'core:oms',
            type: 'collapse',
            icon: icons.LocalShippingIcon,
            children: [
                {
                    id: 'supplier',
                    title: 'core:supplier',
                    type: 'collapse',
                    children: [
                        {
                            id: 'supplier',
                            title: 'core:supplier',
                            type: 'item',
                            url: '/oms/supplier',
                            isPublic: false,
                            role: ['Cashier']
                        },
                        {
                            id: 'supplier-settings',
                            title: 'core:supplierSettings',
                            type: 'collapse',
                            children: [
                                {
                                    id: 'supplier',
                                    title: 'core:suppliercode',
                                    type: 'item',
                                    url: '/oms/supplier-settings',
                                    isPublic: false,
                                    role: ['Cashier']
                                },
                                {
                                    id: 'supplier',
                                    title: 'common:Supplier-Group',
                                    type: 'item',
                                    url: '/oms/supplier-category-list'
                                }
                            ]
                        }
                    ]
                },
                //{
                //  id: 'oms-dashboard',
                //  title: 'common:dash',
                // type: 'item',
                //url: '/oms/dashboard'
                //},
                {
                    id: 'company',
                    title: 'core:companies',
                    type: 'item',
                    url: '/oms/company'
                },
                {
                    id: 'magasins',
                    title: 'core:stores',
                    type: 'item',
                    url: '/oms/magasins'
                },
                {
                    id: 'depots',
                    title: 'core:warehouses',
                    type: 'item',
                    url: '/oms/depots'
                },
                {
                    id: 'pos',
                    title: 'core:pos',
                    type: 'item',
                    url: '/oms/pos'
                },
                {
                    id: 'pricelist',
                    title: 'core:pricelistsmenu',
                    type: 'collapse',
                    children: [
                        {
                            id: 'products',
                            title: 'core:pricelistsmenutypes',
                            type: 'item',
                            url: '/oms/price_list'
                        }
                    ]
                },
                {
                    id: 'buying',
                    title: 'core:buying',
                    type: 'item',
                    url: '/oms/buying-settings'
                }
            ]
        }
    ]
};

export default oms;
