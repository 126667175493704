import { LoadingButton } from '@mui/lab';
import { Button, Grid, Modal, Typography } from '@mui/material';
import { Box } from '@mui/system';
import useFetch from 'hooks/useFetch';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import MainCard from 'ui-component/cards/MainCard';
import DataRender from 'ui-component/tables/DataRender';
import { modalStyle } from 'views/utilities/ModalStyle';
import { SetNotification } from 'store/services/api';
import { useDispatch } from 'react-redux';
import { formatErpResponse } from 'utils/utils';
import { useTranslation } from 'react-i18next';

const CatgoryItemList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    const { t } = useTranslation();
    const [selectionModel, setSelectionModel] = useState([]);

    const [open, setOpen] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [openNoDataModal, setOpenNoDataModal] = useState(false);

    const itemGroupsReqData = {
        doctype: 'Item',
        fields: [
            '`tabItem`.`name`',
            '`tabItem`.`item_name`',
            '`tabItem`.`item_code`',
            '`tabItem`.`item_collection`',
            '`tabItem`.`standard_rate`'
        ],
        filters: [['Item', 'item_group', '=', id]],
        start: 0,
        page_length: 500
    };

    const { data, isPending, refetch } = useFetch(`/api/get-list`, itemGroupsReqData, 'POST');

    const handleEdit = (row) => {
        const Row = row.replace(/\//g, '%2F');
        navigate(`/pim/product-view/${Row}`);
    };
    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const handleDelete = (row) => {
        setLoadingButton(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/delete-doc`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doctype: 'Item Group', name: row[0] })
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    dispatch(SetNotification({ code: 'success', message: 'La catégorie a été supprimée avec succès' }));
                    refetch({});
                    handleClose();
                }
            });
    };

    useEffect(() => {
        if (!isPending && data && data.length === 0) {
            setOpenNoDataModal(true);
        } else {
            setOpenNoDataModal(false);
        }
    }, [data, isPending]);

    const handleCloseNoDataModal = () => {
        navigate(-1);
    };

    const deleteConfirm = (
        <Box sx={modalStyle}>
            <MainCard divider title={t('common:confirm')}>
                <Typography id="modal-modal-description" sx={{ fontSize: 14 }}>
                    {t('common:deletePermanently')} <strong>{selectionModel}</strong> ?
                </Typography>
                <Box display={'flex'} justifyContent={'flex-end'} mt={3}>
                    <Box>
                        <Grid container spacing={1}>
                            <Grid item>
                                <Button variant="outlined" color="blue" size="large" onClick={handleClose}>
                                    {t('common:non')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <LoadingButton
                                    color="blue"
                                    size="large"
                                    onClick={() => handleDelete(selectionModel)}
                                    variant="contained"
                                    loading={loadingButton}
                                >
                                    {t('common:oui')}
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </MainCard>
        </Box>
    );

    const title = (
        <Box p={-1} display={'flex'} justifyContent={'space-between'}>
            <Grid>{t('Liste Des Produits :')}</Grid>
        </Box>
    );

    const noDataModalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: 2
    };

    return (
        <div>
            <DataRender refetch={refetch} title={title} data={data} loading={isPending} handleEdit={handleEdit} />
            <Modal onClose={handleClose} open={open}>
                {deleteConfirm}
            </Modal>
            <Modal
                open={openNoDataModal}
                onClose={handleCloseNoDataModal}
                aria-labelledby="no-data-modal-title"
                aria-describedby="no-data-modal-description"
            >
                <Box sx={noDataModalStyle}>
                    <Typography id="no-data-modal-title" variant="h6" component="h2">
                        {t('columns:noDataFound')}
                    </Typography>
                    <Typography id="no-data-modal-description" sx={{ mt: 2 }}>
                        {t('columns:norelateditems')}
                    </Typography>
                    <Button onClick={handleCloseNoDataModal} sx={{ mt: 2 }}>
                        {t('columns:backbutton')}
                    </Button>
                </Box>
            </Modal>
        </div>
    );
};

export default CatgoryItemList;
