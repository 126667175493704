import React from 'react';
import MainCard from 'ui-component/cards/MainCard';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box } from '@mui/system';
import InputTextField from 'ui-component/form/InputTextField';
import SelectForm from 'ui-component/form/SelectForm';
import InputCheckboxField from 'ui-component/form/InputCheckboxField';
import { LoadingButton } from '@mui/lab';
import useGetAxios from 'hooks/useGetAxios';
import { useEffect } from 'react';
import Loader from 'ui-component/Loader';
import useFetch from 'hooks/useFetch';
import { formatErpResponse } from 'utils/utils';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { SetNotification } from 'store/services/api';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

const SystemSettings = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [loadingButton, setLoadingButton] = React.useState(false);

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const {
        data: doc,
        isPending,
        refetch: refetchDoc,
        docinfo
    } = useGetAxios(`${process.env.REACT_APP_API_URI}/api/details?doctype=System%20Settings&name=System%20Settings`);

    const [state, setState] = React.useState({
        country: '',
        language: '',
        time_zone: '',
        enable_onboarding: 0,
        date_format: '',
        time_format: 0,
        number_format: '',
        float_precision: '',
        currency_precision: '',
        first_day_of_the_week: '',
        backup_limit: '',
        dormant_days: '',
        apply_strict_user_permissions: 0,
        allow_guests_to_upload_files: 0,
        session_expiry: '',
        session_expiry_mobile: '',
        document_share_key_expiry: '',
        deny_multiple_sessions: 0,
        allow_login_using_mobile_number: 0,
        allow_login_using_user_name: 0,
        allow_error_traceback: 0,
        strip_exif_metadata_from_uploaded_images: 0,
        allow_older_web_view_links: 0,
        logout_on_password_reset: 0,
        force_user_to_reset_password: '',
        password_reset_limit: '',
        enable_password_policy: 0,
        minimum_password_score: '',
        allow_consecutive_login_attempts: '',
        allow_login_after_fail: '',
        enable_two_factor_auth: 0,
        bypass_2fa_for_retricted_ip_users: 0,
        bypass_restrict_ip_check_if_2fa_enabled: 0,
        two_factor_method: '',
        otp_issuer_name: '',
        email_retry_limit: '',
        email_footer_address: '',
        disable_standard_email_footer: 0,
        hide_footer_in_auto_email_reports: 0,
        attach_view_link: 0,
        enable_prepared_report_auto_deletion: 0,
        prepared_report_expiry_period: '',
        max_auto_email_report_per_user: '',
        disable_system_update_notification: 0,
        disable_change_log_notification: 0
    });

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };

    const handleChangeCheckbox = (event, value) => {
        setState({ ...state, [event.target.name]: value ? 1 : 0 });
    };

    const countryReqData = {
        doctype: 'Country',
        fields: ['`tabCountry`.`name`']
    };

    const { data: countries, isPending: isPendingcountries } = useFetch(`/api/get-list`, countryReqData, 'POST');

    const languageReqData = {
        doctype: 'Language',
        fields: ['`tabLanguage`.`name`'],
        filters: [['Language', 'enabled', '=', 1]]
    };

    const { data: languages, isPending: isPendinglanguages } = useFetch(`/api/get-list`, languageReqData, 'POST');

    useEffect(() => {
        setState({
            country: doc.country,
            language: doc.language,
            time_zone: doc.time_zone,
            enable_onboarding: doc.enable_onboarding,
            date_format: doc.date_format,
            time_format: doc.time_format,
            number_format: doc.number_format,
            float_precision: doc.float_precision,
            currency_precision: doc.currency_precision,
            first_day_of_the_week: doc.first_day_of_the_week,
            backup_limit: doc.backup_limit,
            dormant_days: doc.dormant_days,
            apply_strict_user_permissions: doc.apply_strict_user_permissions,
            allow_guests_to_upload_files: doc.allow_guests_to_upload_files,
            session_expiry: doc.session_expiry,
            session_expiry_mobile: doc.session_expiry_mobile,
            document_share_key_expiry: doc.document_share_key_expiry,
            deny_multiple_sessions: doc.deny_multiple_sessions,
            allow_login_using_mobile_number: doc.allow_login_using_mobile_number,
            allow_login_using_user_name: doc.allow_login_using_user_name,
            allow_error_traceback: doc.allow_error_traceback,
            strip_exif_metadata_from_uploaded_images: doc.strip_exif_metadata_from_uploaded_images,
            allow_older_web_view_links: doc.allow_older_web_view_links,
            logout_on_password_reset: doc.logout_on_password_reset,
            force_user_to_reset_password: doc.force_user_to_reset_password,
            password_reset_limit: doc.password_reset_limit,
            enable_password_policy: doc.enable_password_policy,
            minimum_password_score: doc.minimum_password_score,
            allow_consecutive_login_attempts: doc.allow_consecutive_login_attempts,
            allow_login_after_fail: doc.allow_login_after_fail,
            enable_two_factor_auth: doc.enable_two_factor_auth,
            bypass_2fa_for_retricted_ip_users: doc.bypass_2fa_for_retricted_ip_users,
            bypass_restrict_ip_check_if_2fa_enabled: doc.bypass_restrict_ip_check_if_2fa_enabled,
            two_factor_method: doc.two_factor_method,
            otp_issuer_name: doc.otp_issuer_name,
            email_footer_address: doc.email_footer_address,
            email_retry_limit: doc.email_retry_limit,
            disable_standard_email_footer: doc.disable_standard_email_footer,
            hide_footer_in_auto_email_reports: doc.hide_footer_in_auto_email_reports,
            attach_view_link: doc.attach_view_link,
            enable_prepared_report_auto_deletion: doc.enable_prepared_report_auto_deletion,
            prepared_report_expiry_period: doc.prepared_report_expiry_period,
            max_auto_email_report_per_user: doc.max_auto_email_report_per_user,
            disable_system_update_notification: doc.disable_system_update_notification,
            disable_change_log_notification: doc.disable_change_log_notification
        });
    }, [doc]);

    const handleSubmit = () => {
        const data = {
            ...doc,
            doctype: 'System Settings',
            country: state.country,
            language: state.language,
            time_zone: state.time_zone,
            enable_onboarding: state.enable_onboarding,
            date_format: state.date_format,
            time_format: state.time_format,
            number_format: state.number_format,
            float_precision: state.float_precision,
            currency_precision: state.currency_precision,
            first_day_of_the_week: state.first_day_of_the_week,
            backup_limit: state.backup_limit,
            dormant_days: state.dormant_days,
            apply_strict_user_permissions: state.apply_strict_user_permissions,
            allow_guests_to_upload_files: state.allow_guests_to_upload_files,
            session_expiry: state.session_expiry,
            session_expiry_mobile: state.session_expiry_mobile,
            document_share_key_expiry: state.document_share_key_expiry,
            deny_multiple_sessions: state.deny_multiple_sessions,
            allow_login_using_mobile_number: state.allow_login_using_mobile_number,
            allow_login_using_user_name: state.allow_login_using_user_name,
            allow_error_traceback: state.allow_error_traceback,
            strip_exif_metadata_from_uploaded_images: state.strip_exif_metadata_from_uploaded_images,
            allow_older_web_view_links: state.allow_older_web_view_links,
            logout_on_password_reset: state.logout_on_password_reset,
            force_user_to_reset_password: state.force_user_to_reset_password,
            password_reset_limit: state.password_reset_limit,
            enable_password_policy: state.enable_password_policy,
            minimum_password_score: state.minimum_password_score,
            allow_consecutive_login_attempts: state.allow_consecutive_login_attempts,
            allow_login_after_fail: state.allow_login_after_fail,
            enable_two_factor_auth: state.enable_two_factor_auth,
            bypass_2fa_for_retricted_ip_users: state.bypass_2fa_for_retricted_ip_users,
            bypass_restrict_ip_check_if_2fa_enabled: state.bypass_restrict_ip_check_if_2fa_enabled,
            two_factor_method: state.two_factor_method,
            otp_issuer_name: state.otp_issuer_name,
            email_footer_address: state.email_footer_address,
            email_retry_limit: state.email_retry_limit,
            disable_standard_email_footer: state.disable_standard_email_footer,
            hide_footer_in_auto_email_reports: state.hide_footer_in_auto_email_reports,
            attach_view_link: state.attach_view_link,
            enable_prepared_report_auto_deletion: state.enable_prepared_report_auto_deletion,
            prepared_report_expiry_period: state.prepared_report_expiry_period,
            max_auto_email_report_per_user: state.max_auto_email_report_per_user,
            disable_system_update_notification: state.disable_system_update_notification,
            disable_change_log_notification: state.disable_change_log_notification
        };
        setLoadingButton(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doc: data, action: 'Save' })
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'error', message: formatErpResponse(data?.message) }));
                } else {
                    dispatch(SetNotification({ code: 'success', message: 'System Settings successfully updated' }));
                    refetchDoc({});
                }
            });
    };
    const availableTimezones = [
        'Africa/Abidjan',
        'Africa/Accra',
        'Africa/Addis_Ababa',
        'Africa/Algiers',
        'Africa/Asmara',
        'Africa/Bamako',
        'Africa/Bangui',
        'Africa/Banjul',
        'Africa/Bissau',
        'Africa/Blantyre',
        'Africa/Brazzaville',
        'Africa/Bujumbura',
        'Africa/Cairo',
        'Africa/Casablanca',
        'Africa/Ceuta',
        'Africa/Conakry',
        'Africa/Dakar',
        'Africa/Dar_es_Salaam',
        'Africa/Djibouti',
        'Africa/Douala',
        'Africa/El_Aaiun',
        'Africa/Freetown',
        'Africa/Gaborone',
        'Africa/Harare',
        'Africa/Johannesburg',
        'Africa/Juba',
        'Africa/Kampala',
        'Africa/Khartoum',
        'Africa/Kigali',
        'Africa/Kinshasa',
        'Africa/Lagos',
        'Africa/Libreville',
        'Africa/Lome',
        'Africa/Luanda',
        'Africa/Lubumbashi',
        'Africa/Lusaka',
        'Africa/Malabo',
        'Africa/Maputo',
        'Africa/Maseru',
        'Africa/Mbabane',
        'Africa/Mogadishu',
        'Africa/Monrovia',
        'Africa/Nairobi',
        'Africa/Ndjamena',
        'Africa/Niamey',
        'Africa/Nouakchott',
        'Africa/Ouagadougou',
        'Africa/Porto-Novo',
        'Africa/Sao_Tome',
        'Africa/Timbuktu',
        'Africa/Tripoli',
        'Africa/Tunis',
        'Africa/Windhoek',
        'America/Adak',
        'America/Anchorage',
        'America/Anguilla',
        'America/Antigua',
        'America/Araguaina',
        'America/Argentina/Buenos_Aires',
        'America/Argentina/Catamarca',
        'America/Argentina/Cordoba',
        'America/Argentina/Jujuy',
        'America/Argentina/La_Rioja',
        'America/Argentina/Mendoza',
        'America/Argentina/Rio_Gallegos',
        'America/Argentina/Salta',
        'America/Argentina/San_Juan',
        'America/Argentina/San_Luis',
        'America/Argentina/Tucuman',
        'America/Argentina/Ushuaia',
        'America/Aruba',
        'America/Asuncion',
        'America/Atikokan',
        'America/Bahia',
        'America/Bahia_Banderas',
        'America/Barbados',
        'America/Belem',
        'America/Belize',
        'America/Blanc-Sablon',
        'America/Boa_Vista',
        'America/Bogota',
        'America/Boise',
        'America/Cambridge_Bay',
        'America/Campo_Grande',
        'America/Cancun',
        'America/Caracas',
        'America/Catamarca',
        'America/Cayenne',
        'America/Cayman',
        'America/Chicago',
        'America/Chihuahua',
        'America/Coral_Harbour',
        'America/Cordoba',
        'America/Costa_Rica',
        'America/Creston',
        'America/Cuiaba',
        'America/Curacao',
        'America/Danmarkshavn',
        'America/Dawson',
        'America/Dawson_Creek',
        'America/Denver',
        'America/Detroit',
        'America/Dominica',
        'America/Edmonton',
        'America/Eirunepe',
        'America/El_Salvador',
        'America/Fortaleza',
        'America/Glace_Bay',
        'America/Godthab',
        'America/Goose_Bay',
        'America/Grand_Turk',
        'America/Grenada',
        'America/Guadeloupe',
        'America/Guatemala',
        'America/Guayaquil',
        'America/Guyana',
        'America/Halifax',
        'America/Havana',
        'America/Hermosillo',
        'America/Indiana/Indianapolis',
        'America/Indiana/Knox',
        'America/Indiana/Marengo',
        'America/Indiana/Petersburg',
        'America/Indiana/Tell_City',
        'America/Indiana/Vevay',
        'America/Indiana/Vincennes',
        'America/Indiana/Winamac',
        'America/Indianapolis',
        'America/Inuvik',
        'America/Iqaluit',
        'America/Jamaica',
        'America/Juneau',
        'America/Kentucky/Louisville',
        'America/Kentucky/Monticello',
        'America/La_Paz',
        'America/Lima',
        'America/Los_Angeles',
        'America/Maceio',
        'America/Managua',
        'America/Manaus',
        'America/Marigot',
        'America/Martinique',
        'America/Matamoros',
        'America/Mazatlan',
        'America/Menominee',
        'America/Merida',
        'America/Metlakatla',
        'America/Mexico_City',
        'America/Miquelon',
        'America/Moncton',
        'America/Monterrey',
        'America/Montevideo',
        'America/Montreal',
        'America/Montserrat',
        'America/Nassau',
        'America/New_York',
        'America/Nipigon',
        'America/Nome',
        'America/Noronha',
        'America/North_Dakota/Beulah',
        'America/North_Dakota/Center',
        'America/North_Dakota/New_Salem',
        'America/Ojinaga',
        'America/Panama',
        'America/Pangnirtung',
        'America/Paramaribo',
        'America/Phoenix',
        'America/Port-au-Prince',
        'America/Port_of_Spain',
        'America/Porto_Acre',
        'America/Porto_Velho',
        'America/Puerto_Rico',
        'America/Rainy_River',
        'America/Rankin_Inlet',
        'America/Recife',
        'America/Regina',
        'America/Resolute',
        'America/Rio_Branco',
        'America/Santa_Isabel',
        'America/Santarem',
        'America/Santiago',
        'America/Santo_Domingo',
        'America/Sao_Paulo',
        'America/Scoresbysund',
        'America/Sitka',
        'America/St_Barthelemy',
        'America/St_Johns',
        'America/St_Kitts',
        'America/St_Lucia',
        'America/St_Thomas',
        'America/St_Vincent',
        'America/Swift_Current',
        'America/Tegucigalpa',
        'America/Thule',
        'America/Thunder_Bay',
        'America/Tijuana',
        'America/Toronto',
        'America/Tortola',
        'America/Vancouver',
        'America/Whitehorse',
        'America/Winnipeg',
        'America/Yakutat',
        'America/Yellowknife',
        'Antarctica/Casey',
        'Antarctica/Davis',
        'Antarctica/DumontDUrville',
        'Antarctica/Macquarie',
        'Antarctica/Mawson',
        'Antarctica/McMurdo',
        'Antarctica/Palmer',
        'Antarctica/Rothera',
        'Antarctica/Syowa',
        'Antarctica/Vostok',
        'Asia/Aden',
        'Asia/Almaty',
        'Asia/Amman',
        'Asia/Anadyr',
        'Asia/Aqtau',
        'Asia/Aqtobe',
        'Asia/Ashgabat',
        'Asia/Baghdad',
        'Asia/Bahrain',
        'Asia/Baku',
        'Asia/Bangkok',
        'Asia/Beirut',
        'Asia/Bishkek',
        'Asia/Brunei',
        'Asia/Choibalsan',
        'Asia/Chongqing',
        'Asia/Chungking',
        'Asia/Colombo',
        'Asia/Dacca',
        'Asia/Damascus',
        'Asia/Dhaka',
        'Asia/Dili',
        'Asia/Dubai',
        'Asia/Dushanbe',
        'Asia/Gaza',
        'Asia/Harbin',
        'Asia/Hebron',
        'Asia/Ho_Chi_Minh',
        'Asia/Hong_Kong',
        'Asia/Hovd',
        'Asia/Irkutsk',
        'Asia/Jakarta',
        'Asia/Jayapura',
        'Asia/Jerusalem',
        'Asia/Kabul',
        'Asia/Kamchatka',
        'Asia/Karachi',
        'Asia/Kashgar',
        'Asia/Kathmandu',
        'Asia/Katmandu',
        'Asia/Khandyga',
        'Asia/Kolkata',
        'Asia/Krasnoyarsk',
        'Asia/Kuala_Lumpur',
        'Asia/Kuching',
        'Asia/Kuwait',
        'Asia/Macau',
        'Asia/Magadan',
        'Asia/Makassar',
        'Asia/Manila',
        'Asia/Muscat',
        'Asia/Nicosia',
        'Asia/Novokuznetsk',
        'Asia/Novosibirsk',
        'Asia/Omsk',
        'Asia/Oral',
        'Asia/Phnom_Penh',
        'Asia/Pontianak',
        'Asia/Pyongyang',
        'Asia/Qatar',
        'Asia/Qyzylorda',
        'Asia/Rangoon',
        'Asia/Riyadh',
        'Asia/Sakhalin',
        'Asia/Samarkand',
        'Asia/Seoul',
        'Asia/Shanghai',
        'Asia/Singapore',
        'Asia/Taipei',
        'Asia/Tashkent',
        'Asia/Tbilisi',
        'Asia/Tehran',
        'Asia/Thimphu',
        'Asia/Tokyo',
        'Asia/Ulaanbaatar',
        'Asia/Urumqi',
        'Asia/Ust-Nera',
        'Asia/Vientiane',
        'Asia/Vladivostok',
        'Asia/Yakutsk',
        'Asia/Yekaterinburg',
        'Asia/Yerevan',
        'Atlantic/Azores',
        'Atlantic/Bermuda',
        'Atlantic/Canary',
        'Atlantic/Cape_Verde',
        'Atlantic/Faroe',
        'Atlantic/Madeira',
        'Atlantic/Reykjavik',
        'Atlantic/South_Georgia',
        'Atlantic/St_Helena',
        'Atlantic/Stanley',
        'Australia/Adelaide',
        'Australia/Brisbane',
        'Australia/Broken_Hill',
        'Australia/Currie',
        'Australia/Darwin',
        'Australia/Eucla',
        'Australia/Hobart',
        'Australia/Lindeman',
        'Australia/Lord_Howe',
        'Australia/Melbourne',
        'Australia/Perth',
        'Australia/Sydney',
        'CET',
        'CST6CDT',
        'EET',
        'EST',
        'EST5EDT',
        'Eire',
        'Etc/GMT',
        'Etc/GMT+1',
        'Etc/GMT+10',
        'Etc/GMT+11',
        'Etc/GMT+12',
        'Etc/GMT+2',
        'Etc/GMT+3',
        'Etc/GMT+4',
        'Etc/GMT+5',
        'Etc/GMT+6',
        'Etc/GMT+7',
        'Etc/GMT+8',
        'Etc/GMT+9',
        'Etc/GMT-1',
        'Etc/GMT-10',
        'Etc/GMT-11',
        'Etc/GMT-12',
        'Etc/GMT-13',
        'Etc/GMT-14',
        'Etc/GMT-2',
        'Etc/GMT-3',
        'Etc/GMT-4',
        'Etc/GMT-5',
        'Etc/GMT-6',
        'Etc/GMT-7',
        'Etc/GMT-8',
        'Etc/GMT-9',
        'Etc/UCT',
        'Etc/UTC',
        'Europe/Amsterdam',
        'Europe/Andorra',
        'Europe/Athens',
        'Europe/Belgrade',
        'Europe/Berlin',
        'Europe/Brussels',
        'Europe/Bucharest',
        'Europe/Budapest',
        'Europe/Chisinau',
        'Europe/Copenhagen',
        'Europe/Dublin',
        'Europe/Gibraltar',
        'Europe/Helsinki',
        'Europe/Istanbul',
        'Europe/Jersey',
        'Europe/Kaliningrad',
        'Europe/Kiev',
        'Europe/Lisbon',
        'Europe/London',
        'Europe/Luxembourg',
        'Europe/Madrid',
        'Europe/Malta',
        'Europe/Minsk',
        'Europe/Monaco',
        'Europe/Moscow',
        'Europe/Oslo',
        'Europe/Paris',
        'Europe/Prague',
        'Europe/Riga',
        'Europe/Rome',
        'Europe/Samara',
        'Europe/Simferopol',
        'Europe/Sofia',
        'Europe/Stockholm',
        'Europe/Tallinn',
        'Europe/Tirane',
        'Europe/Uzhgorod',
        'Europe/Vaduz',
        'Europe/Vienna',
        'Europe/Vilnius',
        'Europe/Volgograd',
        'Europe/Warsaw',
        'Europe/Zaporozhye',
        'Europe/Zurich',
        'HST',
        'Hongkong',
        'Iceland',
        'Indian/Antananarivo',
        'Indian/Chagos',
        'Indian/Christmas',
        'Indian/Cocos',
        'Indian/Comoro',
        'Indian/Kerguelen',
        'Indian/Mahe',
        'Indian/Maldives',
        'Indian/Mauritius',
        'Indian/Mayotte',
        'Indian/Reunion',
        'MET',
        'MST',
        'MST7MDT',
        'PST8PDT',
        'Pacific/Apia',
        'Pacific/Auckland',
        'Pacific/Chatham',
        'Pacific/Chuuk',
        'Pacific/Easter',
        'Pacific/Efate',
        'Pacific/Enderbury',
        'Pacific/Fakaofo',
        'Pacific/Fiji',
        'Pacific/Funafuti',
        'Pacific/Galapagos',
        'Pacific/Gambier',
        'Pacific/Guadalcanal',
        'Pacific/Guam',
        'Pacific/Honolulu',
        'Pacific/Johnston',
        'Pacific/Kiritimati',
        'Pacific/Kosrae',
        'Pacific/Kwajalein',
        'Pacific/Majuro',
        'Pacific/Marquesas',
        'Pacific/Midway',
        'Pacific/Nauru',
        'Pacific/Niue',
        'Pacific/Norfolk',
        'Pacific/Noumea',
        'Pacific/Pago_Pago',
        'Pacific/Palau',
        'Pacific/Pitcairn',
        'Pacific/Pohnpei',
        'Pacific/Port_Moresby',
        'Pacific/Rarotonga',
        'Pacific/Saipan',
        'Pacific/Tahiti',
        'Pacific/Tarawa',
        'Pacific/Tongatapu',
        'Pacific/Wake',
        'Pacific/Wallis',
        'WET'
    ];

    const timeFormats = ['HH:mm', 'HH:mm:ss'];
    const dateFormats = ['yyyy-mm-dd', 'dd-mm-yyyy', 'dd.mm.yyyy', 'dd/mm/yyyy', 'mm/dd/yyyy', 'mm-dd-yyyy'];
    const numberFormats = [
        '#,###.##',
        '#.###,##',
        '# ###.##',
        '# ###,##',
        "#'###.##",
        '#,###.##',
        '#,##,###.##',
        '#,###.###',
        '#.###',
        '#,###'
    ];
    const float_precision = ['', '2', '3', '4', '5', '6', '7', '8', '9'];
    const currency_precision = ['', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    const first_day_of_the_week = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const minimum_password_score = ['2', '3', '4'];
    const two_factor_method = ['Email', 'SMS', 'OTP App'];

    if (isPending && (isPendingcountries || isPendinglanguages)) return <Loader />;
    return (
        <>
            <MainCard
                border
                isWhite
                boxShadow
                divider
                title={
                    <Box display={'flex'} alignItems={'center'}>
                        <SettingsIcon sx={{ mr: 1 }} /> {t('settings:text-100')}
                    </Box>
                }
                sx={{ height: '7%' }}
            >
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', height: '100%' }}>
                    <div style={{ width: '100%' }}>
                        <SelectForm
                            fullwidth
                            label={t('settings:text-101')}
                            data={countries ?? countries}
                            name="country"
                            value={state.country}
                            sx={{ mb: 2 }}
                            handleChange={handleChange}
                            propToRender={'name'}
                        />
                        <SelectForm
                            fullwidth
                            label={t('settings:text-102')}
                            data={languages ?? languages}
                            name="language"
                            value={state.language}
                            sx={{ mb: 2 }}
                            handleChange={handleChange}
                            propToRender={'name'}
                        />
                    </div>
                    <div style={{ width: '100%' }}>
                        <SelectForm
                            fullwidth
                            label={t('settings:text-103')}
                            name="time_zone"
                            value={state.time_zone}
                            sx={{ mb: 2 }}
                            data={availableTimezones}
                            handleChange={handleChange}
                        />

                        <InputCheckboxField
                            fullwidth
                            label={t('settings:text-104')}
                            name="enable_onboarding"
                            value={state.enable_onboarding === 1 ? true : false}
                            sx={{ mb: 2 }}
                            handleChange={handleChangeCheckbox}
                        />
                    </div>
                </div>
            </MainCard>

            <MainCard
                border
                isWhite
                boxShadow
                divider
                title={
                    <Box display={'flex'} alignItems={'center'}>
                        <SettingsIcon sx={{ mr: 1 }} /> {t('settings:text-105')}
                    </Box>
                }
                sx={{ height: '9%', mt: 2 }}
            >
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', height: '100%' }}>
                    <div style={{ width: '100%' }}>
                        <SelectForm
                            fullwidth
                            label={t('settings:text-106')}
                            name="date_format"
                            value={state.date_format}
                            sx={{ mb: 2 }}
                            data={dateFormats}
                            handleChange={handleChange}
                        />
                        <SelectForm
                            fullwidth
                            label={t('settings:text-107')}
                            name="time_format"
                            value={state.time_format}
                            sx={{ mb: 2 }}
                            data={timeFormats}
                            handleChange={handleChange}
                        />
                        <SelectForm
                            fullwidth
                            label={t('settings:text-108')}
                            name="number_format"
                            value={state.number_format}
                            sx={{ mb: 2 }}
                            data={numberFormats}
                            handleChange={handleChange}
                        />
                    </div>
                    <div style={{ width: '100%' }}>
                        <SelectForm
                            fullwidth
                            label={t('settings:text-109')}
                            name="float_precision"
                            value={state.float_precision}
                            sx={{ mb: 2 }}
                            data={float_precision}
                            handleChange={handleChange}
                        />
                        <SelectForm
                            fullwidth
                            label={t('settings:text-110')}
                            name="currency_precision"
                            value={state.currency_precision}
                            sx={{ mb: 2 }}
                            data={currency_precision}
                            handleChange={handleChange}
                            helper={t('settings:text-157')}
                        />
                        <SelectForm
                            fullwidth
                            label={t('settings:text-111')}
                            name="first_day_of_the_week"
                            value={state.first_day_of_the_week}
                            sx={{ mb: 2 }}
                            data={first_day_of_the_week}
                            handleChange={handleChange}
                        />
                    </div>
                </div>
            </MainCard>

            <MainCard
                border
                isWhite
                boxShadow
                divider
                title={
                    <Box display={'flex'} alignItems={'center'}>
                        <SettingsIcon sx={{ mr: 1 }} /> {t('settings:text-112')}
                    </Box>
                }
                sx={{ height: '5%', mt: 2 }}
            >
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', height: '100%' }}>
                    <div style={{ width: '100%' }}>
                        <InputTextField
                            fullwidth
                            type="text"
                            label={t('settings:text-113')}
                            name="backup_limit"
                            value={state.backup_limit}
                            sx={{ mb: 2 }}
                            handleChange={handleChange}
                            helper={t('settings:text-158')}
                        />
                    </div>
                    <div style={{ width: '100%' }}></div>
                </div>
            </MainCard>

            <MainCard
                border
                isWhite
                boxShadow
                divider
                title={
                    <Box display={'flex'} alignItems={'center'}>
                        <SettingsIcon sx={{ mr: 1 }} /> {t('settings:text-114')}
                    </Box>
                }
                sx={{ height: '5%', mt: 2 }}
            >
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', height: '100%' }}>
                    <div style={{ width: '100%' }}>
                        <InputTextField
                            fullwidth
                            type="text"
                            label={t('settings:text-115')}
                            name="dormant_days"
                            value={state.dormant_days}
                            sx={{ mb: 2 }}
                            handleChange={handleChange}
                            helper={t('settings:text-161')}
                        />
                    </div>
                    <div style={{ width: '100%' }}></div>
                </div>
            </MainCard>

            <MainCard
                border
                isWhite
                boxShadow
                divider
                title={
                    <Box display={'flex'} alignItems={'center'}>
                        <SettingsIcon sx={{ mr: 1 }} /> {t('settings:text-116')}
                    </Box>
                }
                sx={{ height: '6%', mt: 2 }}
            >
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', height: '100%' }}>
                    <div style={{ width: '100%' }}>
                        <InputCheckboxField
                            fullwidth
                            label={t('settings:text-117')}
                            name="apply_strict_user_permissions"
                            value={state?.apply_strict_user_permissions === 1 ? true : false}
                            handleChange={handleChangeCheckbox}
                            sx={{ mb: 2 }}
                        />
                        <Typography variant="caption" display="block" gutterBottom align="center">
                            {t('settings:text-162')}
                            <br />
                            {t('settings:text-163')}
                            <br />
                            {t('settings:text-164')}
                        </Typography>
                    </div>
                    <div style={{ width: '100%' }}>
                        <InputCheckboxField
                            fullwidth
                            label={t('settings:text-118')}
                            name="apply_strict_user_permissions"
                            value={state?.apply_strict_user_permissions === 1 ? true : false}
                            handleChange={handleChangeCheckbox}
                            sx={{ mb: 2 }}
                        />
                        <Typography variant="caption" display="block" gutterBottom align="center">
                            {t('settings:text-165')}
                            <br />
                            {t('settings:text-166')}
                            <br />
                            {t('settings:text-167')}
                        </Typography>
                    </div>
                </div>
            </MainCard>

            <MainCard
                border
                isWhite
                boxShadow
                divider
                title={
                    <Box display={'flex'} alignItems={'center'}>
                        <SettingsIcon sx={{ mr: 1 }} /> {t('settings:text-119')}
                    </Box>
                }
                sx={{ height: '14%', mt: 2 }}
            >
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', height: '100%' }}>
                    <div style={{ width: '100%' }}>
                        <InputTextField
                            fullwidth
                            type="text"
                            label={t('settings:text-120')}
                            name="session_expiry"
                            value={state.session_expiry}
                            sx={{ mb: 2 }}
                            handleChange={handleChange}
                            helper={t('settings:text-168')}
                        />
                        <InputTextField
                            fullwidth
                            type="text"
                            label={t('settings:text-121')}
                            name="session_expiry_mobile"
                            value={state.session_expiry_mobile}
                            sx={{ mb: 2 }}
                            handleChange={handleChange}
                            helper={t('settings:text-169')}
                        />
                        <InputTextField
                            fullwidth
                            type="text"
                            label={t('settings:text-122')}
                            name="document_share_key_expiry"
                            value={state.document_share_key_expiry}
                            sx={{ mb: 2 }}
                            handleChange={handleChange}
                            helper={t('settings:text-170')}
                        />
                    </div>
                    <div style={{ width: '100%' }}>
                        <InputCheckboxField
                            fullwidth
                            label={t('settings:text-123')}
                            name="deny_multiple_sessions"
                            value={state?.deny_multiple_sessions === 1 ? true : false}
                            handleChange={handleChangeCheckbox}
                            sx={{ mb: 2 }}
                        />
                        <div style={{ width: '70%', textAlign: 'right', marginLeft: 'auto' }}>
                            <Typography variant="caption" display="block" gutterBottom align="center">
                                {t('settings:text-171')}
                            </Typography>
                        </div>
                        <InputCheckboxField
                            fullwidth
                            label={t('settings:text-124')}
                            name="allow_login_using_mobile_number"
                            value={state?.allow_login_using_mobile_number === 1 ? true : false}
                            handleChange={handleChangeCheckbox}
                            sx={{ mb: 2 }}
                        />
                        <div style={{ width: '79%', textAlign: 'right', marginLeft: 'auto' }}>
                            <Typography variant="caption" display="block" gutterBottom align="center">
                                {t('settings:text-172')}
                            </Typography>
                        </div>
                        <InputCheckboxField
                            fullwidth
                            label={t('settings:text-125')}
                            name="allow_login_using_user_name"
                            value={state?.allow_login_using_user_name === 1 ? true : false}
                            handleChange={handleChangeCheckbox}
                            sx={{ mb: 2 }}
                        />
                        <div style={{ width: '81%', textAlign: 'right', marginLeft: 'auto' }}>
                            <Typography variant="caption" display="block" gutterBottom align="center">
                                {t('settings:text-173')}
                            </Typography>
                        </div>
                        <InputCheckboxField
                            fullwidth
                            label={t('settings:text-126')}
                            name="state.allow_error_traceback"
                            value={state?.allow_error_traceback === 1 ? true : false}
                            handleChange={handleChangeCheckbox}
                            sx={{ mb: 2 }}
                        />
                        <InputCheckboxField
                            fullwidth
                            label={t('settings:text-127')}
                            name="strip_exif_metadata_from_uploaded_images"
                            value={state?.strip_exif_metadata_from_uploaded_images === 1 ? true : false}
                            handleChange={handleChangeCheckbox}
                            sx={{ mb: 2 }}
                        />
                        <InputCheckboxField
                            fullwidth
                            label={t('settings:text-128')}
                            name="allow_older_web_view_links"
                            value={state?.allow_older_web_view_links === 1 ? true : false}
                            handleChange={handleChangeCheckbox}
                            sx={{ mb: 2 }}
                        />
                    </div>
                </div>
            </MainCard>

            <MainCard
                border
                isWhite
                boxShadow
                divider
                title={
                    <Box display={'flex'} alignItems={'center'}>
                        <SettingsIcon sx={{ mr: 1 }} /> {t('settings:text-129')}
                    </Box>
                }
                sx={{ height: '10%', mt: 2 }}
            >
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', height: '100%' }}>
                    <div style={{ width: '100%' }}>
                        <InputCheckboxField
                            fullwidth
                            label={t('settings:text-130')}
                            name="logout_on_password_reset"
                            value={state?.logout_on_password_reset === 1 ? true : false}
                            handleChange={handleChangeCheckbox}
                            sx={{ mb: 2 }}
                        />
                        <InputTextField
                            fullwidth
                            type="text"
                            label={t('settings:text-131')}
                            name="force_user_to_reset_password"
                            value={state.force_user_to_reset_password}
                            sx={{ mb: 2 }}
                            handleChange={handleChange}
                            helper={t('settings:text-174')}
                        />
                        <InputTextField
                            fullwidth
                            type="text"
                            label={t('settings:text-132')}
                            name="password_reset_limit"
                            value={state.password_reset_limit}
                            sx={{ mb: 2 }}
                            handleChange={handleChange}
                            helper={t('settings:text-175')}
                        />
                    </div>
                    <div style={{ width: '100%' }}>
                        <InputCheckboxField
                            fullwidth
                            label={t('settings:text-133')}
                            name="enable_password_policy"
                            value={state?.enable_password_policy === 1 ? true : false}
                            handleChange={handleChangeCheckbox}
                            sx={{ mb: 2 }}
                        />
                        <div style={{ width: '80%', textAlign: 'right', marginLeft: 'auto' }}>
                            <Typography variant="caption" display="block" gutterBottom align="center">
                                {t('settings:text-176')}
                            </Typography>
                            <Typography variant="caption" display="block" gutterBottom align="center">
                                {t('settings:text-177')}
                            </Typography>
                            <Typography variant="caption" display="block" gutterBottom align="center">
                                {t('settings:text-178')}
                            </Typography>
                        </div>
                        <br></br>
                        <SelectForm
                            fullwidth
                            label={t('settings:text-134')}
                            name="minimum_password_score"
                            value={state.minimum_password_score}
                            sx={{ mb: 2 }}
                            data={minimum_password_score}
                            handleChange={handleChange}
                        />
                    </div>
                </div>
            </MainCard>

            <MainCard
                border
                isWhite
                boxShadow
                divider
                title={
                    <Box display={'flex'} alignItems={'center'}>
                        <SettingsIcon sx={{ mr: 1 }} /> {t('settings:text-135')}
                    </Box>
                }
                sx={{ height: '6%', mt: 2 }}
            >
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', height: '100%' }}>
                    <div style={{ width: '100%' }}>
                        <InputTextField
                            fullwidth
                            label={t('settings:text-136')}
                            name="allow_consecutive_login_attempts"
                            value={state.allow_consecutive_login_attempts}
                            handleChange={handleChange}
                            sx={{ mb: 2 }}
                        />
                    </div>
                    <div style={{ width: '100%' }}>
                        <InputTextField
                            fullwidth
                            type="text"
                            label={t('settings:text-137')}
                            name="allow_login_after_fail"
                            value={state.allow_login_after_fail}
                            sx={{ mb: 2 }}
                            handleChange={handleChange}
                            helper={t('settings:text-179')}
                        />
                    </div>
                </div>
            </MainCard>

            <MainCard
                border
                isWhite
                boxShadow
                divider
                title={
                    <Box display={'flex'} alignItems={'center'}>
                        <SettingsIcon sx={{ mr: 1 }} /> {t('settings:text-138')}
                    </Box>
                }
                sx={{ height: '15%', mt: 2 }}
            >
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', height: '100%' }}>
                    <div style={{ width: '100%' }}>
                        <InputCheckboxField
                            fullwidth
                            label={t('settings:text-139')}
                            name="enable_two_factor_auth"
                            value={state?.enable_two_factor_auth === 1 ? true : false}
                            handleChange={handleChangeCheckbox}
                            sx={{ mb: 2 }}
                        />
                        <InputCheckboxField
                            fullwidth
                            label={t('settings:text-140')}
                            name="bypass_2fa_for_retricted_ip_users"
                            value={state?.bypass_2fa_for_retricted_ip_users === 1 ? true : false}
                            handleChange={handleChangeCheckbox}
                            sx={{ mb: 2 }}
                        />
                        <div style={{ width: '60%', textAlign: 'right', marginLeft: 'auto' }}>
                            <Typography variant="caption" display="block" gutterBottom align="center">
                                {t('settings:text-180')}
                            </Typography>
                        </div>
                        <InputCheckboxField
                            fullwidth
                            label={t('settings:text-141')}
                            name="bypass_restrict_ip_check_if_2fa_enabled"
                            value={state?.bypass_restrict_ip_check_if_2fa_enabled === 1 ? true : false}
                            handleChange={handleChangeCheckbox}
                            sx={{ mb: 2 }}
                        />
                        <div style={{ width: '60%', textAlign: 'left', marginLeft: 'auto' }}>
                            <Typography variant="caption" display="block" gutterBottom align="center">
                                {t('settings:text-181')}
                            </Typography>
                        </div>
                        <br></br>
                        <SelectForm
                            fullwidth
                            label={t('settings:text-142')}
                            name="two_factor_method"
                            value={state.two_factor_method}
                            sx={{ mb: 2 }}
                            data={two_factor_method}
                            handleChange={handleChange}
                            helper={t('settings:text-182')}
                        />
                        <InputTextField
                            fullwidth
                            type="text"
                            label={t('settings:text-143')}
                            name="otp_issuer_name"
                            value={state.otp_issuer_name}
                            sx={{ mb: 2 }}
                            handleChange={handleChange}
                        />
                    </div>
                    <div style={{ width: '100%' }}></div>
                </div>
            </MainCard>

            <MainCard
                border
                isWhite
                boxShadow
                divider
                title={
                    <Box display={'flex'} alignItems={'center'}>
                        <SettingsIcon sx={{ mr: 1 }} /> {t('settings:text-144')}
                    </Box>
                }
                sx={{ height: '10%', mt: 2 }}
            >
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', height: '100%' }}>
                    <div style={{ width: '100%' }}>
                        <InputTextField
                            fullwidth
                            multiline
                            rows="6"
                            type="text"
                            label={t('settings:text-156')}
                            name="email_footer_address"
                            value={state.email_footer_address}
                            sx={{ mb: 2 }}
                            handleChange={handleChange}
                            helper={t('settings:text-183')}
                        />
                        <InputTextField
                            fullwidth
                            type="text"
                            label={t('settings:text-145')}
                            name="email_retry_limit"
                            value={state.email_retry_limit}
                            sx={{ mb: 2 }}
                            handleChange={handleChange}
                        />
                    </div>
                    <div style={{ width: '100%' }}>
                        <InputCheckboxField
                            fullwidth
                            label={t('settings:text-146')}
                            name="disable_standard_email_footer"
                            value={state?.disable_standard_email_footer === 1 ? true : false}
                            handleChange={handleChangeCheckbox}
                            sx={{ mb: 2 }}
                        />
                        <InputCheckboxField
                            fullwidth
                            label={t('settings:text-147')}
                            name="hide_footer_in_auto_email_reports"
                            value={state?.hide_footer_in_auto_email_reports === 1 ? true : false}
                            handleChange={handleChangeCheckbox}
                            sx={{ mb: 2 }}
                        />
                        <InputCheckboxField
                            fullwidth
                            label={t('settings:text-148')}
                            name="attach_view_link"
                            value={state?.attach_view_link === 1 ? true : false}
                            handleChange={handleChangeCheckbox}
                            sx={{ mb: 2 }}
                        />
                    </div>
                </div>
            </MainCard>

            <MainCard
                border
                isWhite
                boxShadow
                divider
                title={
                    <Box display={'flex'} alignItems={'center'}>
                        <SettingsIcon sx={{ mr: 1 }} /> {t('settings:text-149')}
                    </Box>
                }
                sx={{ height: '7%', mt: 2 }}
            >
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', height: '100%' }}>
                    <div style={{ width: '100%' }}>
                        <InputCheckboxField
                            fullwidth
                            label={t('settings:text-150')}
                            name="enable_prepared_report_auto_deletion"
                            value={state?.enable_prepared_report_auto_deletion === 1 ? true : false}
                            handleChange={handleChangeCheckbox}
                            sx={{ mb: 2 }}
                        />
                        <InputTextField
                            fullwidth
                            type="text"
                            label={t('settings:text-151')}
                            name="prepared_report_expiry_period"
                            value={state.prepared_report_expiry_period}
                            sx={{ mb: 2 }}
                            handleChange={handleChange}
                            helper={t('settings:text-184')}
                        />
                    </div>
                    <div style={{ width: '100%' }}>
                        <InputTextField
                            fullwidth
                            type="text"
                            label={t('settings:text-152')}
                            name="max_auto_email_report_per_user"
                            value={state.max_auto_email_report_per_user}
                            sx={{ mb: 2 }}
                            handleChange={handleChange}
                        />
                    </div>
                </div>
            </MainCard>

            <MainCard
                border
                isWhite
                boxShadow
                divider
                title={
                    <Box display={'flex'} alignItems={'center'}>
                        <SettingsIcon sx={{ mr: 1 }} /> {t('settings:text-153')}
                    </Box>
                }
                sx={{ height: '6%', mt: 2 }}
            >
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', height: '100%' }}>
                    <div style={{ width: '100%' }}>
                        <InputCheckboxField
                            fullwidth
                            label={t('settings:text-154')}
                            name="disable_system_update_notification"
                            value={state?.disable_system_update_notification === 1 ? true : false}
                            handleChange={handleChangeCheckbox}
                            sx={{ mb: 2 }}
                        />
                        <InputCheckboxField
                            fullwidth
                            label={t('settings:text-155')}
                            name="disable_change_log_notification"
                            value={state?.disable_change_log_notification === 1 ? true : false}
                            handleChange={handleChangeCheckbox}
                            sx={{ mb: 2 }}
                        />
                    </div>
                    <div style={{ width: '100%' }}></div>
                </div>
            </MainCard>

            <Box display={'flex'} justifyContent={'right'} mt={3}>
                <LoadingButton variant="contained" size="large" color="blue" onClick={handleSubmit} loading={loadingButton}>
                    {t('settings:text-50')}
                </LoadingButton>
            </Box>
        </>
    );
};

export default SystemSettings;
