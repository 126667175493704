import { Box, Button, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import useGetAxios from 'hooks/useGetAxios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import Loader from 'ui-component/Loader';
import { useStyles } from 'views/utilities/Style';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MainCard from 'ui-component/cards/MainCard';
import InputTextField from 'ui-component/form/InputTextField';
import { LoadingButton } from '@mui/lab';
import { SetNotification } from 'store/services/api';
import { formatErpResponse } from 'utils/utils';
import SelectForm from 'ui-component/form/SelectForm';
import useFetch from '../../hooks/useFetch';

function UOMForm() {
    const classes = useStyles();
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { data: doc, isPending } = useGetAxios(`${process.env.REACT_APP_API_URI}/api/details?doctype=UOM&name=${id}`);

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const [loadingButton, setLoadingButton] = useState(false);

    const [state, setState] = useState({
        enabled: 1,
        must_be_whole_number: 0,
        uom_name: '',
        code_uom: ''
    });

    const { data: UomLists } = useFetch(
        `/api/get-list`,
        { doctype: 'UOM Category', fields: ['`tabUOM Category`.`name`'], start: 0, page_length: 50 },
        'POST'
    );

    const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
    };

    const [defaultUom, setDefaultUom] = useState('');
    const handleSelectChangeUom = (event) => {
        const {
            target: { value }
        } = event;
        setDefaultUom(value);
    };

    const handleResetUom = () => {
        setDefaultUom('');
    };

    const saveDisbaled = () => {
        return !state.uom_name || !state.code_uom;
    };

    const handleSaveUOM = async () => {
        setLoadingButton(true);
        const data = {
            doc: {
                ...(!id && { __islocal: 1 }),
                ...(id && {
                    creation: doc.creation,
                    modified: doc.modified,
                    owner: doc.owner,
                    modified_by: doc.owner,
                    uom_famille: defaultUom,
                    name: doc.name
                }),
                doctype: 'UOM',
                enabled: state.enabled,
                must_be_whole_number: state.must_be_whole_number,
                uom_name: state.uom_name,
                code_uom: state.code_uom,
                uom_famille: defaultUom,
                __unsaved: 1
            },
            action: 'Save'
        };
        fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(data)
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    dispatch(SetNotification({ code: 'success', message: t('common:uom-create') }));
                    navigate('/pim/uoms');
                }
            });
    };

    useEffect(() => {
        if (id && !Array.isArray(doc) && doc) {
            setState({
                ...state,
                enabled: doc?.enabled,
                must_be_whole_number: doc?.must_be_whole_number,
                uom_name: doc?.uom_name,
                code_uom: doc?.code_uom,
                uom_famille: defaultUom
            });
            setDefaultUom(doc?.uom_famille || '');
        }
    }, [id, doc, state, defaultUom]);

    const title = (
        <Box p={-1} display={'flex'} justifyContent={'space-between'}>
            <Box display={'flex'} flexDirection={'column'}>
                <ArrowBackIcon className={classes.backLink} onClick={() => navigate(-1)} />
                <Box sx={id && doc?.enabled === 0 ? { opacity: 0.7 } : {}}>
                    {id ? <span>{t('common:uom') + ': ' + id}</span> : <span>{t('common:uom')}</span>}
                </Box>
            </Box>
        </Box>
    );

    if (id && isPending) return <Loader />;

    return (
        <MainCard title={title}>
            <Grid container spacing={2} display={'flex'} justifyContent={'left'} flexDirection={'column'}>
                <FormControlLabel
                    label={t('common:uom-enab')}
                    control={
                        <Checkbox
                            color="blue"
                            checked={Boolean(state.enabled)}
                            onChange={() => setState({ ...state, enabled: state.enabled === 1 ? 0 : 1 })}
                        />
                    }
                />
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <FormControlLabel
                        label={t('common:uom-who')}
                        control={
                            <Checkbox
                                color="blue"
                                checked={Boolean(state.must_be_whole_number)}
                                onChange={() => setState({ ...state, must_be_whole_number: state.must_be_whole_number === 1 ? 0 : 1 })}
                            />
                        }
                    />
                    <Typography id="modal-modal-description" sx={{ fontSize: 11, fontWeight: 'bold' }}>
                        {t('common:uom-note')}
                    </Typography>
                </Box>
                <InputTextField
                    required
                    error={state.code_uom === ''}
                    label={t('core:code')}
                    value={state.code_uom}
                    name="code_uom"
                    handleChange={handleChange}
                    disabled={id ? true : false}
                />
                <InputTextField
                    required
                    error={state.uom_name === ''}
                    label={t('common:uom-name')}
                    value={state.uom_name}
                    name="uom_name"
                    handleChange={handleChange}
                    disabled={id ? true : false}
                />
                <SelectForm
                    sx={{ mt: -2 }}
                    label={t('common:uom-famille')}
                    name="uom_famille"
                    value={defaultUom}
                    handleChange={handleSelectChangeUom}
                    data={UomLists}
                    propToRender={'name'}
                    reset={true}
                    handleReset={handleResetUom}
                />
            </Grid>
            <Grid container xs={12} sm={12} md={8} lg={12} xl={12}>
                <Grid item xs={12}>
                    <Box display={'flex'} justifyContent={'center'} mt={4}>
                        <Button sx={{ mr: 1 }} onClick={() => navigate(-1)}>
                            {t('common:cancel')}
                        </Button>
                        <LoadingButton
                            onClick={handleSaveUOM}
                            variant="contained"
                            color="blue"
                            size="large"
                            loading={loadingButton}
                            disabled={saveDisbaled()}
                        >
                            {t('common:save')}
                        </LoadingButton>
                    </Box>
                </Grid>
            </Grid>
        </MainCard>
    );
}

export default UOMForm;
