import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React from 'react';
import { useStyles } from 'views/utilities/Style';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { CurrencyFormatter } from 'utils/utils';
import { useSelector } from 'react-redux';
import { formatColumnsHeaders } from 'utils/formatColumnsHeaders';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import * as XLSX from 'xlsx';
import { formatDate } from 'helpers/utils';

function Report({ doc, title, isBalance }) {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const globalDefaults = useSelector((state) => state.login.globalDefaults);

    function isFloat(value) {
        if (typeof value === 'number' && !Number.isNaN(value) && !Number.isInteger(value)) {
            return true;
        }
        return false;
    }

    function formatResult(row, column, isResult) {
        if (isResult === true) {
            if (column.fieldtype === 'Currency') {
                return row ? (
                    <CurrencyFormatter
                        locale="fr-FR"
                        currency={globalDefaults?.default_currency}
                        minimumFractionDigits={3}
                        value={parseFloat(row)}
                    />
                ) : (
                    '-'
                );
            } else if (column.fieldtype === 'Percent') {
                return row ? (
                    <span className={parseFloat(row[column.fieldname]) < 0 ? classes.redText : classes.greenText}>
                        {parseFloat(row).toFixed(2) + ' %'}
                    </span>
                ) : (
                    <span className={classes.redText}>{0?.toFixed(1)} %</span>
                );
            }
            return isFloat(row) ? row?.toFixed(2) : row;
        } else if (column.fieldtype === 'Currency') {
            return row[column.fieldname] ? (
                <CurrencyFormatter
                    locale="fr-FR"
                    currency={globalDefaults?.default_currency}
                    minimumFractionDigits={3}
                    value={parseFloat(row[column.fieldname])}
                />
            ) : (
                '-'
            );
        } else if (column.fieldtype === 'Percent') {
            return row[column.fieldname] ? (
                <span className={parseFloat(row[column.fieldname]) < 0 ? classes.redText : classes.greenText}>
                    {parseFloat(row[column.fieldname]).toFixed(2) + ' %'}
                </span>
            ) : (
                <span className={classes.redText}>{0?.toFixed(1)} %</span>
            );
        }
        return isFloat(row[column.fieldname]) ? row[column.fieldname]?.toFixed(2) : row[column.fieldname] ?? '-';
    }

    function convertArrayOfObjectsToCSV(data) {
        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const keys = Object.keys(data[0]);

        let result = keys.join(columnDelimiter);
        result += lineDelimiter;

        data.forEach((item) => {
            if (!Array.isArray(item)) {
                let ctr = 0;
                keys.forEach((key) => {
                    if (ctr > 0) result += columnDelimiter;
                    result += item[key];
                    ctr++;
                });
                result += lineDelimiter;
            }
        });

        return result;
    }

    function downloadCSV(data, filename = 'export.csv') {
        let csv = convertArrayOfObjectsToCSV(data);
        if (csv == null) return;

        if (!csv.startsWith('data:text/csv')) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }

        const link = document.createElement('a');
        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    }

    function downloadXLSX(data, filename = 'export.xlsx') {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, filename);
    }

    const currentDate = new Date();

    return (
        <>
            <PerfectScrollbar component="div" style={{ maxHeight: 400 }}>
                <Table className={classes.simpleTable}>
                    <TableHead className={classes.TableHead}>
                        <TableRow>
                            {doc?.columns?.map((column, index) => (
                                <TableCell align={['Currency', 'Percent'].includes(column.fieldtype) ? 'right' : 'left'} key={index}>
                                    {t(formatColumnsHeaders(column.fieldname))}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {doc?.result?.map((row, rowIndex) =>
                            Array.isArray(row) ? (
                                <TableRow key={rowIndex} className={classes.TableFooter}>
                                    {doc?.columns?.map((column, colIndex) => (
                                        <TableCell
                                            className={classes.TableFooterCell}
                                            align={['Currency', 'Percent'].includes(column.fieldtype) ? 'right' : 'left'}
                                            key={colIndex}
                                        >
                                            {formatResult(row[colIndex], column, true)}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ) : (
                                <TableRow key={rowIndex}>
                                    {doc?.columns?.map((column, colIndex) => (
                                        <TableCell
                                            className={row?.indent === 1 ? null : classes.cardBoldText}
                                            align={['Currency', 'Percent'].includes(column.fieldtype) ? 'right' : 'left'}
                                            key={colIndex}
                                            style={
                                                column.fieldname === 'in_qty' ||
                                                column.fieldname === 'delivered_qty' ||
                                                column.fieldname === 'billed_amount'
                                                    ? { color: theme.palette.success.main }
                                                    : column.fieldname === 'out_qty' || column.fieldname === 'delay'
                                                    ? { color: theme.palette.error.main }
                                                    : isBalance && column.fieldname === 'average_qty'
                                                    ? { color: theme.palette.warning.main }
                                                    : {}
                                            }
                                        >
                                            {formatResult(row, column, false)}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            )
                        )}
                    </TableBody>
                </Table>
            </PerfectScrollbar>
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'flex-end'} mt={1}>
                <Button
                    variant="text"
                    onClick={() => downloadCSV(doc?.result, `[VNDIT] ${title} [${formatDate(currentDate, 'YYYY-MM-DD HH:mm')}].csv`)}
                >
                    CSV
                </Button>
                <Button
                    variant="text"
                    onClick={() => downloadXLSX(doc?.result, `[VNDIT] ${title} [${formatDate(currentDate, 'YYYY-MM-DD HH:mm')}].xlsx`)}
                >
                    XLSX
                </Button>
            </Box>
        </>
    );
}

export default Report;
