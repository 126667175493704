import { useNavigate, useParams } from 'react-router';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/system';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { Grid } from '@mui/material';

import { LoadingButton } from '@mui/lab';
import { useStyles } from 'views/utilities/Style';
import InputTextField from 'ui-component/form/InputTextField';
import MainCard from 'ui-component/cards/MainCard';
import useFetch from 'hooks/useFetch';
import Loader from 'ui-component/Loader';
import SelectForm from 'ui-component/form/SelectForm';
import { SetNotification } from 'store/services/api';
import { useEffect } from 'react';
import useGetAxios from 'hooks/useGetAxios';
const SousFamilleProductEdit = () => {
    const classes = useStyles();
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [libelle, setLibille] = useState('');
    const [familleSelected, setFamilleSelected] = useState('');
    const [abrege, setAbrege] = useState('');
    const [code, setCode] = useState('');
    const [loadingButton, setLoadingButton] = useState(false);
    const { data: familleList } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Famille',
            fields: ['`tabFamille`.`name`', '`tabFamille`.`libelle`', '`tabFamille`.`abrege`']
        },
        'POST'
    );
    const { data: doc, isPending } = useGetAxios(`${process.env.REACT_APP_API_URI}/api/details?doctype=SousFamille&name=${id}`);
    useEffect(() => {
        if (doc) {
            setLibille(doc.libelle || '');
            setAbrege(doc.abrege || '');
            setCode(doc.code || '');
            setFamilleSelected(doc.famille || '');
        }
    }, [doc]);
    const handleEditFamille = () => {
        setLoadingButton(true);
        const header = {
            Accept: 'application/json',
            'content-type': 'application/json',
            'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem('user'))?.token
        };
        const requestData = {
            doc: {
                ...(!id && { __islocal: 1 }),
                ...(id && {
                    creation: doc.creation,
                    modified: doc.modified,
                    owner: doc.owner,
                    modified_by: doc.owner,
                    name: doc.name
                }),
                doctype: 'SousFamille',
                code: code,
                libelle: libelle,
                abrege: abrege,
                famille: familleSelected,

                __unsaved: 1
            },
            action: 'Save'
        };
        fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(requestData)
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                setLoadingButton(false);
                dispatch(SetNotification({ code: 'success', message: 'La sous-famille a été modifiée avec succès' }));
                return navigate('/pim/sous-famille-products-view');
            })
            .then((data) => {})
            .catch((error) => {
                dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
            });
    };

    const handleSfamille = (e) => {
        setFamilleSelected(e.target.value);
    };
    const handleCode = (e) => {
        setCode(e.target.value);
    };

    const handleSelect = (e) => {
        setFamilleSelected(e.target.value);
    };

    const handleResetFamille = () => {
        setFamilleSelected('');
    };

    const disabledFieldStyle = {
        opacity: 0.7,
        pointerEvents: 'none'
    };

    if (id && isPending) return <Loader />;
    const title = (
        <Box p={-1} display={'flex'} flexDirection={'column'}>
            <ArrowBackIcon className={classes.backLink} onClick={() => navigate(-1)} />
            {id ? `${t('common:modifier-sous-famille-des-produits')} : ${doc?.name}` : ''}
        </Box>
    );

    return (
        <Box>
            <MainCard title={title}>
                <Grid container spacing={2} display={'flex'} justifyContent={'left'} flexDirection={'column'}>
                    <div style={disabledFieldStyle}>
                        <InputTextField
                            handleChange={(e) => setCode(e.target.value)}
                            value={code}
                            label={t('common:code')}
                            name="code"
                            disabled={true}
                            sx={{ mb: 2 }}
                        />
                    </div>
                    <div>
                        <InputTextField
                            handleChange={(e) => setLibille(e.target.value)}
                            value={libelle}
                            label={t('common:uom_name')}
                            name="Libellé"
                            sx={{ mb: 2 }}
                        />
                    </div>
                    {/*<InputTextField
                       handleChange={(e) => setAbrege(e.target.value)}
                        value={abrege}
                        label={'Abrégé'}
                        name="Abrégé"
                    />*/}
                    <div>
                        <SelectForm
                            label={t('common:famille')}
                            data={familleList}
                            name={'famille'}
                            propToRender={'name'}
                            handleChange={handleSfamille}
                            reset={true}
                            handleReset={handleResetFamille}
                            value={familleSelected}
                            disabled={undefined}
                            helper={undefined}
                            error={undefined}
                            required={undefined}
                            sx={undefined}
                            fullwidth={undefined}
                            flagAddDefaultCustomer={undefined}
                            refetch={undefined}
                            id={undefined}
                            setFormState={undefined}
                            flagParentProductBundle={undefined}
                            handleOpenParentProductBdl={undefined}
                            isMultiple={undefined}
                            isLink={undefined}
                            isFilter={undefined}
                            renderDisplay={(option) => option.libelle}
                        ></SelectForm>
                    </div>
                </Grid>
                <Grid container item xs={12} sm={12} md={8} lg={8} xl={8} spacing={4} display={'flex'} justifyContent={'center'}>
                    <Grid item mt={3}>
                        <LoadingButton
                            variant="contained"
                            size="large"
                            color="blue"
                            onClick={() => handleEditFamille()}
                            loading={loadingButton}
                            disabled={!familleSelected || !code || !libelle}
                        >
                            {t('common:save')}
                        </LoadingButton>
                    </Grid>
                </Grid>
            </MainCard>
        </Box>
    );
};
export default SousFamilleProductEdit;
