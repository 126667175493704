import { Box, Typography } from '@mui/material';
import React from 'react';
import MainCard from 'ui-component/cards/MainCard';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { formatDate } from 'helpers/utils';
import { useTranslation } from 'react-i18next';

function PeriodDetails({ doc }) {
    const { t } = useTranslation();
    return (
        <MainCard
            border
            isWhite
            title={
                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} gap={5}>
                    <Box display={'flex'} alignItems={'center'}>
                        <DateRangeIcon sx={{ mr: 1 }} /> {t('documents:pdls')}
                    </Box>
                </Box>
            }
            boxShadow
            divider
        >
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: 50 }}>
                <div>
                    <Typography color={'GrayText'} variant="body2" fontWeight={500}>
                        {t('documents:psd')}:
                        <Typography color={'ActiveCaption'} variant="body2" fontWeight={700}>
                            {formatDate(doc?.period_start_date, 'DD-MM-YYYY HH:mm')}
                        </Typography>
                    </Typography>
                    {doc?.period_end_date ? (
                        <Typography color={'GrayText'} variant="body2" fontWeight={500}>
                            {t('documents:ped')}:
                            <Typography color={'ActiveCaption'} variant="body2" fontWeight={700}>
                                {formatDate(doc?.period_end_date, 'DD-MM-YYYY HH:mm')}
                            </Typography>
                        </Typography>
                    ) : null}
                </div>
                <div>
                    <Typography color={'GrayText'} variant="body2" fontWeight={500}>
                        {t('documents:pdate')}:
                        <Typography color={'ActiveCaption'} variant="body2" fontWeight={700}>
                            {formatDate(doc?.posting_date, 'DD-MM-YYYY')}
                        </Typography>
                    </Typography>
                    {doc?.posting_time ? (
                        <Typography color={'GrayText'} variant="body2" fontWeight={500}>
                            {t('documents:ptime')}:
                            <Typography color={'ActiveCaption'} variant="body2" fontWeight={700}>
                                {doc?.posting_time}
                            </Typography>
                        </Typography>
                    ) : null}
                    {doc?.pos_opening_entry ? (
                        <Typography color={'GrayText'} variant="body2" fontWeight={500}>
                            {t('documents:poe')}:
                            <Typography color={'ActiveCaption'} variant="body2" fontWeight={700}>
                                {doc?.pos_opening_entry}
                            </Typography>
                        </Typography>
                    ) : null}
                </div>
            </div>
        </MainCard>
    );
}

export default PeriodDetails;
