import { Box, IconButton, Tooltip } from '@mui/material';
import { BarChart } from '@mui/x-charts';
import useFetch from 'hooks/useFetch';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import MainCard from 'ui-component/cards/MainCard';
import CachedIcon from '@mui/icons-material/Cached';
import { useTranslation } from 'react-i18next';

function StockValue() {
    const { t } = useTranslation();
    const globalDefaults = useSelector((state) => state.login.globalDefaults);

    // Chart state for MUI BarChart
    const [chartState, setChartState] = useState({
        categories: [],
        series: [{ name: 'Stock Value', data: [] }]
    });

    // Fetch the stock value chart data
    const {
        data: stockValueCharts,
        isPending: isStockValueChartsPending,
        refetch
    } = useFetch(`/api/get-warehouse-wise-stock-chart`, { chart_name: 'Warehouse wise Stock Value' }, 'POST');

    // Update chart state with fetched data
    useEffect(() => {
        if (stockValueCharts?.datasets?.[0]?.values && stockValueCharts?.labels) {
            setChartState({
                categories: stockValueCharts.labels,
                series: [{ name: `Stock Value (${globalDefaults?.default_currency})`, data: stockValueCharts.datasets[0].values }]
            });
        }
    }, [stockValueCharts, globalDefaults]);

    return (
        <MainCard
            title={
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box color="ActiveCaption">{t('dashboard:stval')}</Box>
                    <Tooltip title={t('dashboard:resync')}>
                        <IconButton size="small" onClick={() => refetch()}>
                            <CachedIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            }
            isWhite
            boxShadow
            divider
        >
            {isStockValueChartsPending ? (
                'Loading...'
            ) : (
                <Box height={300}>
                    <BarChart
                        series={chartState.series} // Series data for the bar chart
                        xAxis={[{ data: chartState.categories, scaleType: 'band' }]} // Categories for the x-axis
                        height={300}
                        margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
                    />
                </Box>
            )}
        </MainCard>
    );
}

export default StockValue;
