import { Checkbox, Divider, FormControlLabel, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import LogoSection from 'layout/MainLayout/LogoSection';
import React from 'react';
import { useSelector } from 'react-redux';
import TemplateProdForm from './TemplateProdForm';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded';
import PersonIcon from '@mui/icons-material/Person';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import { formatDate } from 'utils/formatColumnsHeaders';
import { useTranslation } from 'react-i18next';

const PrintItemTemplate = React.forwardRef(({ item }, ref) => {
    const globalDefaults = useSelector((state) => state.login.globalDefaults);
    const userDetails = useSelector((state) => state.login.userDetails);
    const { t } = useTranslation();

    const getItemType = () => {
        if (item?.has_variants == 1) return t('products:productTemplate');
        if (item?.variant_of) return 'Variante';
        return 'Produit Simple';
    };

    const listVariants = item?.attributes?.map((option, i) => {
        return (
            <Grid item xs={2} key={i}>
                <Box sx={{ bgcolor: 'Menu', mr: 2, mb: 1, borderRadius: 1 }}>
                    <FormControlLabel
                        control={<Checkbox sx={{ ml: 2 }} color="blue" checked={true} />}
                        label={
                            <Typography fontWeight={'500'} fontSize={12} color={'primary'}>
                                {option.attribute}
                            </Typography>
                        }
                        key={i}
                    />
                </Box>
            </Grid>
        );
    });
    return (
        <div ref={ref}>
            <Box p={2} display={'flex'} flexDirection={'column'} flexGrow={1}>
                {/* company-section */}
                <Box p={2} pl={5} display={'flex'} flexDirection={'column'} letterSpacing={1}>
                    <Typography variant="h7" fontSize={11} fontWeight={700}>
                        <BusinessRoundedIcon sx={{ mb: -0.5, mr: 1 }} />
                        {globalDefaults?.default_company}
                    </Typography>
                    <Divider />
                    <Typography variant="h7" fontSize={11} fontWeight={700}>
                        <LocationOnRoundedIcon sx={{ mb: -0.5, mr: 1 }} />
                        {globalDefaults.country}
                    </Typography>
                    <Divider />
                    <Typography variant="h7" fontSize={11} fontWeight={700}>
                        <ArticleRoundedIcon sx={{ mb: -0.5, mr: 1 }} />
                        {t('products:productSheet')}
                    </Typography>
                    <Divider />
                    <Typography variant="h7" fontSize={11} fontWeight={700}>
                        <PersonIcon sx={{ mb: -0.5, mr: 1 }} />
                        {userDetails.email}
                    </Typography>
                    <Divider />
                    <Typography variant="h7" fontSize={11} fontWeight={700}>
                        <CalendarMonthRoundedIcon sx={{ mb: -0.5, mr: 1 }} />
                        {formatDate(Date.now(), 'YYYY-MM-DD HH:mm')}
                    </Typography>
                    <Divider />
                </Box>

                {/* content */}
                <Box sx={{ position: 'relative', minHeight: '100vh', paddingBottom: '200px' }}>
                    <Box height={800} pl={5} pr={5} mt={4} p={4}>
                        <Typography variant="h4">{getItemType()}</Typography>
                        <TemplateProdForm
                            doc={item}
                            isSimple={!item?.has_variants}
                            brands={[{ name: item?.brand }]}
                            companies={[{ name: item?.item_defaults?.[0]?.company }]}
                            itemAttributes={item?.attributes}
                            itemGroups={[{ name: item?.item_group }]}
                            itemTaxTemplates={[{ name: item?.taxes?.[0]?.item_tax_template }]}
                            priceLists={[{ name: item?.item_defaults?.[0]?.default_price_list }]}
                            warehouses={[{ name: item?.item_defaults?.[0]?.default_warehouse }]}
                            toPrint
                        />
                        {/* active variants */}
                        <Typography variant="h4">Variantes :</Typography>
                        <Box sx={{ flexGrow: 1, mt: 3 }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Grid container>{listVariants}</Grid>
                            </div>
                        </Box>
                    </Box>
                </Box>

                {/* doc-footer */}
                <Box>
                    <Divider />
                    <Box sx={{ opacity: 0.3 }} mt={3} display={'flex'} justifyContent="center">
                        <LogoSection />
                    </Box>
                </Box>
            </Box>
        </div>
    );
});

export default PrintItemTemplate;
