import React from 'react';
import { InputLabel, Grid, FormControlLabel, Checkbox, Typography, Box } from '@mui/material';
import { useStyles } from 'views/utilities/Style';
import { useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';

const InputCheckboxField = (props) => {
    const classes = useStyles();
    const { handleChange, label, value, name, error, required, sx, fullwidth, disabled } = props;
    return (
        <Grid
            container
            item
            xs={!fullwidth && 12}
            sm={!fullwidth && 12}
            md={!fullwidth && 8}
            lg={!fullwidth && 8}
            xl={!fullwidth && 8}
            spacing={4}
            display={'flex'}
            alignItems={'center'}
            sx={sx}
        >
            <Grid item xs={4}>
                <InputLabel error={error ?? error} required={required} className={classes.inputLabel}></InputLabel>
            </Grid>
            <Grid item xs={8}>
                <Box sx={{ bgcolor: 'Menu', borderRadius: '10px' }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                sx={{ ml: 2, padding: '8.9px 14px', fontSize: 12 }}
                                color="blue"
                                value={value}
                                onChange={(e) => handleChange(e, !value)}
                                checked={value}
                                name={name}
                                disabled={disabled}
                            />
                        }
                        label={
                            <Typography fontWeight={'500'} fontSize={12} color={'primary'}>
                                {label}
                            </Typography>
                        }
                    />
                </Box>
            </Grid>
        </Grid>
    );
};

export default InputCheckboxField;
