import * as React from 'react';
import Stack from '@mui/material/Stack';
import { TextField, InputLabel, Grid, Typography, Box } from '@mui/material';
import { useStyles } from 'views/utilities/Style';
import moment from 'moment';
import InfoIcon from '@mui/icons-material/Info';

export default function FormDateTime(props) {
    const classes = useStyles();
    const { handleChange, label, value, name, error, required, type, disabled, sx, fullwidth, isValidDate, helper, nextDate, maxdate } =
        props;
    const currentDate = moment().format('YYYY-MM-DD');
    return (
        <Grid
            sx={sx}
            container
            item
            xs={!fullwidth && 12}
            sm={!fullwidth && 12}
            md={!fullwidth && 8}
            lg={!fullwidth && 8}
            xl={!fullwidth && 8}
            spacing={4}
            display={'flex'}
            alignItems={'center'}
        >
            {label && (
                <Grid item xs={4}>
                    <InputLabel error={error ?? error} required={required} className={classes.inputLabel}>
                        {label}
                    </InputLabel>
                </Grid>
            )}
            <Grid item xs={!label ? 12 : 8}>
                <Stack component="form" noValidate spacing={3}>
                    <TextField
                        disabled={disabled}
                        error={error ?? error}
                        value={value}
                        name={name}
                        onChange={handleChange}
                        id="datetime-local"
                        type={type ? type : 'datetime-local'}
                        InputLabelProps={{
                            shrink: true
                        }}
                        inputProps={{
                            min: isValidDate ? currentDate : nextDate ? nextDate : '',
                            max: maxdate ? maxdate : '',
                            style: { fontWeight: 600 }
                        }}
                    />
                </Stack>
                {helper && (
                    <Box display={'flex'}>
                        <InfoIcon sx={{ fontSize: 14 }} color={error ? 'error' : 'blue'} />
                        <Typography color={error ? 'error' : 'blue'} sx={{ fontSize: 12, ml: 0.5 }}>
                            {helper}
                        </Typography>
                    </Box>
                )}
            </Grid>
        </Grid>
    );
}
