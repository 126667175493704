import React from 'react';
import { CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    loaderContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50vh' // Adjust the height as needed
    }
}));

export const CircularProgressLoader = () => {
    const classes = useStyles();

    return (
        <div className={classes.loaderContainer}>
            <CircularProgress color="primary" />
        </div>
    );
};
