import { LoadingButton } from '@mui/lab';
import { Button, Checkbox, FormControlLabel, Grid, Modal, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useFetch from 'hooks/useFetch';
import useGetAxios from 'hooks/useGetAxios';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import MainCard from 'ui-component/cards/MainCard';
import Loader from 'ui-component/Loader';
import EditIcon from '@mui/icons-material/Edit';
import { useStyles } from 'views/utilities/Style';
import { modalStyle } from 'views/utilities/ModalStyle';
import SelectForm from 'ui-component/form/SelectForm';
import { SetNotification } from 'store/services/api';
import { useDispatch } from 'react-redux';
import { formatErpResponse } from 'utils/utils';
import { useTranslation } from 'react-i18next';
import InputTextField from 'ui-component/form/InputTextField';

const ItemGroupDetails = () => {
    const classes = useStyles();
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { data, isPending } = useGetAxios(`${process.env.REACT_APP_API_URI}/api/details?doctype=Item%20Group&name=${id}`);

    const itemGroupsReqData = {
        doctype: 'Item Group',
        fields: ['`tabItem Group`.`name`'],
        filters: [['Item Group', 'is_group', '=', 1]],
        start: 0,
        page_length: 500
    };

    const { data: itemGroupsParents, isPending: parentsIsPending } = useFetch(`/api/get-list`, itemGroupsReqData, 'POST');

    const [parent, setParent] = useState(null);
    const [isGroup, setIsGroup] = useState(null);
    const [loadingButton, setLoadingButton] = useState(false);
    const [itemGroupCode, setItemGroupCode] = useState('');

    useEffect(() => {
        if (data) {
            setItemGroupCode(data.item_group_code || '');
        }
    }, [data]);

    const handleItemGroupCodeChange = (event) => {
        setItemGroupCode(event.target.value);
    };

    const handleSelectChange = (event) => {
        const {
            target: { value }
        } = event;
        setParent(value);
    };

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [newName, setNewName] = useState('');
    const handleChangeNewName = (e) => setNewName(e.target.value);

    const saveItemGroupData = {
        doctype: 'Item Group',
        freeze: true,
        docnames: [id],
        action: 'update',
        data: {
            is_group: isGroup == null ? data.is_group : isGroup ? 1 : 0,
            parent_item_group: parent ? parent : data?.parent_item_group,
            item_group_code: itemGroupCode
        }
    };

    const renameItemGroupData = {
        doctype: 'Item Group',
        docname: id,
        name: newName,
        new_name: newName,
        enqueue: true,
        merge: 0
    };

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const handleSaveItemGroup = async () => {
        setLoadingButton(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/update-status`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(saveItemGroupData)
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    dispatch(SetNotification({ code: 'success', message: 'La catégorie a été modifée avec succès.' }));
                    navigate('/pim/products-category-list-view');
                }
            });
    };

    const handleRenameItemGroup = async () => {
        fetch(`${process.env.REACT_APP_API_URI}/api/rename-doc`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(renameItemGroupData)
        })
            .then((res) => {
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                    throw Error('Something went wrong');
                }
                return res.json();
            })
            .then((data) => {
                dispatch(SetNotification({ code: 'success', message: 'La catégorie a été renommée avec succès.' }));
                handleClose();
                navigate(`/pim/category-view/${renameItemGroupData.name}`);
            });
    };

    const renameForm = (
        <Box sx={modalStyle}>
            <MainCard divider title={`Renommer ${id}`}>
                <TextField
                    onChange={handleChangeNewName}
                    value={newName}
                    required
                    sx={{ width: '100%' }}
                    label="Nouveau nom de catégorie"
                    id="outlined-size-small"
                />
                {/* <FormControlLabel
                    sx={{ mt: 2 }}
                    label="Fusionner avec l'existant (cette opération ne peut être annulée)."
                    control={<Checkbox checked={false} />}
                />*/}
                <Box display={'flex'} justifyContent={'flex-end'} mt={3}>
                    <LoadingButton color="blue" size="large" variant="contained" disabled={newName === ''} onClick={handleRenameItemGroup}>
                        Renommer
                    </LoadingButton>
                </Box>
            </MainCard>
        </Box>
    );

    if (isPending || parentsIsPending) return <Loader />;

    return (
        <div>
            <MainCard
                title={
                    <Box p={-1} display={'flex'} justifyContent={'space-between'}>
                        <Box display={'flex'} flexDirection={'column'}>
                            <ArrowBackIcon className={classes.backLink} onClick={() => navigate(-1)} />
                            <Box>
                                {`${t('columns:category')} : ` + id}{' '}
                                <EditIcon sx={{ fontSize: 17, ml: 1, cursor: 'pointer' }} onClick={handleOpen} />
                            </Box>
                        </Box>
                        {false && (
                            <Box>
                                <Button variant="outlined" color="blue">
                                    {t('common:products')}
                                </Button>
                            </Box>
                        )}
                    </Box>
                }
            >
                <FormControlLabel
                    label={t('common:group')}
                    control={
                        <Checkbox
                            color="blue"
                            checked={isGroup == null ? Boolean(data.is_group) : isGroup}
                            onChange={() => (isGroup == null ? setIsGroup(!data.is_group) : setIsGroup(!isGroup))}
                        />
                    }
                />
                <Typography id="modal-modal-description" sx={{ fontSize: 11 }}>
                    {t('common:msgcheckbox')}
                </Typography>
                <InputTextField
                    width="40%"
                    label={t('products:categoryCode')}
                    value={itemGroupCode}
                    onChange={handleItemGroupCodeChange}
                    fullWidth
                    margin="normal"
                />
                <SelectForm
                    sx={{ mt: 0 }}
                    label={t('common:parent')}
                    name="parent_item_group"
                    value={parent ? parent : data?.parent_item_group}
                    handleChange={handleSelectChange}
                    data={itemGroupsParents ?? itemGroupsParents}
                    propToRender={'name'}
                />
                <Grid container xs={12} sm={12} md={8} lg={8} xl={8}>
                    <Grid item xs={12}>
                        <Box display={'flex'} justifyContent={'center'} mt={4}>
                            <Button sx={{ mr: 1 }} onClick={() => navigate(-1)}>
                                {t('common:cancel')}
                            </Button>
                            <LoadingButton
                                onClick={handleSaveItemGroup}
                                variant="contained"
                                color="blue"
                                size="large"
                                loading={loadingButton}
                            >
                                {t('common:save')}
                            </LoadingButton>
                        </Box>
                    </Grid>
                </Grid>
            </MainCard>
            <Modal onClose={handleClose} open={open}>
                {renameForm}
            </Modal>
        </div>
    );
};

export default ItemGroupDetails;
