import { Box } from '@mui/system';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useStyles } from 'views/utilities/Style';
import { useNavigate, useParams } from 'react-router';
import useGetAxios from 'hooks/useGetAxios';
import {
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Modal,
    OutlinedInput,
    Select,
    TextField,
    Typography
} from '@mui/material';
import Loader from 'ui-component/Loader';
import { modalStyle } from 'views/utilities/ModalStyle';
import useFetch from 'hooks/useFetch';
import { SetDoc2Duplicate, SetNotification } from 'store/services/api';
import { useDispatch } from 'react-redux';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PrintIcon from '@mui/icons-material/Print';
import { formatErpResponse } from 'utils/utils';
import ReactToPrint from 'react-to-print';
import { useTranslation } from 'react-i18next';
//import ProducDetails from '../components/ProducDetails';
import GenericProducDetails from '../components/GenericProducDetails';
import PrintItemTemplate from '../components/PrintItemTemplate';

const TemplateProdVariantDetails = () => {
    const classes = useStyles();
    const componentRef = useRef();
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    // fetching DATA & init states
    // >>>

    const fieldsInherit = [
        'standard_rate',
        'valuation_rate',
        'prix_unitaire_ttc',
        'prix_achat_ht',
        'prix_de_vente_ht',
        'prix_de_revient',
        'last_purchase_rate',
        'item_tax_category',
        'item_collection',
        'famillies',
        'sousfamillies',
        'tablelibres',
        'sousitem_group',
        'soustablelibres',
        'image'
    ];

    const itemAttributesReqData = {
        doctype: 'Item Attribute',
        fields: ['`tabItem Attribute Value`.`attribute_value`', '`tabItem Attribute Value`.`parent`'],
        start: 0,
        page_length: 500
    };

    const { data: itemAttributesValues } = useFetch(`/api/get-list`, itemAttributesReqData, 'POST');

    const [modalId, setModalId] = useState(0);
    const [open, setOpen] = useState(false);

    const handleOpen = (modalId) => {
        setOpen(true);
        setModalId(modalId);
    };

    const handleClose = () => setOpen(false);

    const { data: doc, isPending, refetch } = useGetAxios(`${process.env.REACT_APP_API_URI}/api/details?doctype=Item&name=${id}`);
    const { data: files, refetch: refetchFiles } = useGetAxios(
        `${process.env.REACT_APP_API_URI}/api/load-file?doctype=Item&name=${id}`,
        null,
        true
    );

    const [selectedAttValues, setSelectedAttValues] = useState({});
    const [singleVariantState, setSingleVariantState] = useState({});

    const [editToggled, setEditToggled] = useState(false);

    // state changes handlers
    // >>>

    const handleAttributesChange = (e, attribute) => {
        const value = e.target.name;
        setSelectedAttValues((prev) => {
            const currentValues = prev[attribute] || [];
            if (e.target.checked) {
                return { ...prev, [attribute]: [...currentValues, value] };
            } else {
                return { ...prev, [attribute]: currentValues.filter((v) => v !== value) };
            }
        });
    };

    const handleSelectChange = (event) => {
        const {
            target: { value, name }
        } = event;
        setSingleVariantState({ ...singleVariantState, [name]: value });
    };

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    // handlers
    // >>>

    const handleToggleDisabled = () => {
        const updateData = {
            doctype: 'Item',
            freeze: true,
            docnames: [id],
            action: 'update',
            data: {
                disabled: doc.disabled === 1 ? 0 : 1
            }
        };
        fetch(`${process.env.REACT_APP_API_URI}/api/update-status`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(updateData)
        })
            .then((res) => {
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    dispatch(
                        SetNotification({
                            code: 'success',
                            message: `Le produit modéle a été ${doc.disabled === 1 ? 'activé' : 'desactivé'} avec succès`
                        })
                    );
                    handleClose();
                    refetch({});
                }
            });
    };

    const handleDuplicate = () => {
        const docToSave = {
            ...doc,
            image: doc.image
        };
        dispatch(SetDoc2Duplicate(null));
        dispatch(SetDoc2Duplicate(docToSave));
        navigate('/pim/add-products-variant');
    };
    const generateCombinations = (attributes) => {
        const keys = Object.keys(attributes);
        const values = Object.values(attributes);

        // Use reduce to build all combinations by accumulating attribute values
        return values.reduce(
            (acc, currValues, idx) => {
                const key = keys[idx];
                const temp = [];

                // For each combination so far, add each value of the current attribute
                acc.forEach((combination) => {
                    currValues.forEach((value) => {
                        temp.push({ ...combination, [key]: value });
                    });
                });

                return temp;
            },
            [{}]
        );
    };

    // Function to parse stringified JSON data
    const parseJSONFields = (data) => {
        Object.keys(data).forEach((key) => {
            if (typeof data[key] === 'string' && data[key].startsWith('[') && data[key].endsWith(']')) {
                try {
                    data[key] = JSON.parse(data[key]);
                } catch (error) {
                    console.error(`Failed to parse field ${key}:`, error);
                }
            }
        });
        return data;
    };

    // Example usage within handleCreateVariants or another function
    const handleCreateVariants = async () => {
        // Generate all combinations of selected attribute values
        const attributeCombinations = generateCombinations(selectedAttValues);

        if (!attributeCombinations || attributeCombinations.length === 0) {
            console.error('No attribute combinations generated.');
            dispatch(SetNotification({ code: 'error', message: 'No valid attribute combinations found.' }));
            return;
        }

        for (const attributes of attributeCombinations) {
            const data = {
                item: id, // ID of the template item
                args: attributes // Current attribute combination
            };

            try {
                // Step 1: Create a single variant with the provided structure
                const response = await fetch(`${process.env.REACT_APP_API_URI}/api/create-single-variant`, {
                    method: 'POST',
                    headers: header,
                    body: JSON.stringify(data)
                });

                if (!response.ok) {
                    throw new Error(`Failed to create variant: ${response.statusText}`);
                }

                const variantData = await response.json();
                const variant = Array.isArray(variantData) ? variantData[0] : variantData;

                if (!variant) {
                    throw new Error('No variant was created');
                }

                // Step 2: Inherit specified fields from the main item, parsing any stringified JSON fields
                const inheritedFields = {
                    uom: doc.uom, // Ensure UOM is inherited from the template item
                    image: doc.image || null
                };

                fieldsInherit.forEach((field) => {
                    if (field === 'image') return;
                    let fieldData = doc[field];

                    if (fieldData && typeof fieldData === 'string') {
                        try {
                            fieldData = JSON.parse(fieldData);
                        } catch (error) {
                            console.error(`Error parsing field "${field}":`, error);
                        }
                    }
                    inheritedFields[field] = fieldData;
                });

                if (files && files.length > 0) {
                    const imageFiles = files.filter((file) => file.file_type === 'image');

                    if (imageFiles.length > 0) {
                        inheritedFields.attached_files = imageFiles;
                    }
                }

                // Step 3: Merge the created variant with inherited fields and additional properties
                const processedVariant = {
                    ...variant.message,
                    ...inheritedFields,
                    item_code: `${doc.item_code}-${Object.values(attributes).join('-')}`,
                    variant_of: doc.item_code,
                    doctype: 'Item'
                };

                // Step 4: Save the processed variant
                try {
                    const saveResponse = await fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
                        method: 'POST',
                        headers: header,
                        body: JSON.stringify({ doc: processedVariant, action: 'Save' })
                    });

                    if (!saveResponse.ok) {
                        const errorData = await saveResponse.json();
                        const errorMessage = errorData.message || JSON.stringify(errorData);

                        if (errorMessage.includes('exists with same attributes')) {
                            dispatch(
                                SetNotification({
                                    code: 'error',
                                    message: t('products:variantExistsError')
                                })
                            );
                        } else {
                            dispatch(
                                SetNotification({
                                    code: 'error',
                                    message: `Erreur lors de l'enregistrement de la variante: ${errorMessage}`
                                })
                            );
                        }
                        throw new Error(errorMessage);
                    }
                } catch (error) {
                    if (!error.message.includes('exists with same attributes')) {
                        dispatch(
                            SetNotification({
                                code: 'error',
                                message: `Erreur lors de l'enregistrement de la variante: ${error.message}`
                            })
                        );
                    }
                    return; // Exit the loop if there's an error
                }
            } catch (error) {
                dispatch(SetNotification({ code: 'error', message: error.message }));
                return; // Exit the loop if there's an error
            }
        }

        // Notify success after all variants are processed
        dispatch(SetNotification({ code: 'success', message: t('products:variantCreateSuccess') }));
        handleClose();
        navigate(`/pim/products-variant-view`);
    };

    const handleCreateSingleVariant = async () => {
        const data = {
            item: id,
            args: singleVariantState
        };
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/create-single-variant`, {
                method: 'POST',
                headers: header,
                body: JSON.stringify(data)
            });

            if (!response.ok) {
                throw new Error('Failed to create variant');
            }

            const variantData = await response.json();

            const inheritedFields = {
                image: doc.image || null
            };

            fieldsInherit.forEach((field) => {
                if (field === 'image') return;
                if (doc[field] !== undefined) {
                    inheritedFields[field] = doc[field];
                }
            });

            if (files && files.length > 0) {
                const imageFiles = files.filter((file) => file.file_type === 'image');

                if (imageFiles.length > 0) {
                    inheritedFields.attached_files = imageFiles;
                }
            }

            const mergedVariantData = {
                ...variantData.message,
                ...inheritedFields,
                item_code: `${doc.item_code}-${Object.values(singleVariantState).join('-')}`,
                variant_of: doc.item_code
            };

            const saveResponse = await fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
                method: 'POST',
                headers: header,
                body: JSON.stringify({ doc: mergedVariantData, action: 'Save' })
            });

            if (!saveResponse.ok) {
                throw new Error('Failed to save variant');
            }

            dispatch(SetNotification({ code: 'success', message: t('products:prodVariantCreateSuccess') }));
            handleClose();
            navigate(`/pim/products-variant-view`);
        } catch (error) {
            dispatch(SetNotification({ code: 'error', message: formatErpResponse(error.message) }));
        }
    };

    // local functions
    // >>>

    const getVariantSum = useCallback(() => {
        let sum = 1;
        Object.values(selectedAttValues)?.map((el) => {
            sum *= el.length;
        });
        return sum;
    }, [selectedAttValues]);

    const checkVariants = useCallback(() => {
        if (getVariantSum() === 0) return true;
        if (Object.keys(selectedAttValues).length !== doc?.attributes?.length) return true;
        return Object.values(selectedAttValues).some((values) => values.length === 0);
    }, [selectedAttValues, doc?.attributes, getVariantSum]);

    const generateNumericOptions = (attribute) => {
        const options = [];
        for (let i = attribute.from_range; i <= attribute.to_range; i += attribute.increment || 1) {
            options.push(i);
        }
        return options;
    };

    // modal forms
    // >>>

    const addVariants = (
        <Box sx={modalStyle}>
            <MainCard divider title={t('common:Sélectionner_les_valeurs')}>
                {t('common:msg_prod_var')}
                <Grid container spacing={1} mt={2} justifyContent={'space-between'}>
                    {doc?.attributes?.map((att, i) => (
                        <Grid item key={i}>
                            <Box bgcolor={'Menu'} borderRadius={2}>
                                <Typography sx={{ p: 1 }} fontWeight={'600'} variant="h4">
                                    {att.attribute}:
                                </Typography>
                                <Divider />
                                <Box ml={1} sx={{ height: 150, overflowY: 'scroll' }}>
                                    {(att.numeric_values === 1
                                        ? generateNumericOptions(att)
                                        : itemAttributesValues?.filter((el) => el.parent === att.attribute)
                                    )?.map((el, index) => (
                                        <Box height={35} key={index}>
                                            <FormControlLabel
                                                label={att.numeric_values === 1 ? el : el.attribute_value}
                                                control={
                                                    <Checkbox
                                                        defaultValue={false}
                                                        name={att.numeric_values === 1 ? el.toString() : el.attribute_value}
                                                        onChange={(e) => handleAttributesChange(e, att.attribute)}
                                                    />
                                                }
                                            />
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
                <Box display={'flex'} justifyContent={'right'} mt={2}>
                    <Button color="blue" variant="contained" size="large" onClick={handleCreateVariants} disabled={checkVariants()}>
                        {/* {`Créer ${getVariantSum()} Variantes`} */}
                        {getVariantSum() == 1
                            ? t('common:créer_une_seule_variante')
                            : getVariantSum() == 2
                            ? t('common:créer2variante')
                            : getVariantSum() == 3
                            ? t('common:créer3variante')
                            : getVariantSum() == 4
                            ? t('common:créer4variante')
                            : getVariantSum() == 5
                            ? t('common:créer5variante')
                            : getVariantSum() == 6
                            ? t('common:créer6variante')
                            : getVariantSum() == 7
                            ? t('common:créer7variante')
                            : getVariantSum() == 8
                            ? t('common:créer8variante')
                            : getVariantSum() == 9
                            ? t('common:créer9variante')
                            : getVariantSum() == 10
                            ? t('common:créer10variante')
                            : t('common:créer_plusieurs_variants')}
                    </Button>
                </Box>
            </MainCard>
        </Box>
    );

    useEffect(() => {
        if (doc?.attributes) {
            const initialState = doc.attributes.reduce((acc, att) => {
                acc[att.attribute] = '';
                return acc;
            }, {});
            setSingleVariantState(initialState);
        }
    }, [doc]);

    const handleAttributeChange = (event) => {
        const { name, value } = event.target;
        const numericValue = parseFloat(value);

        if (!doc || !doc.attributes) return;

        const attribute = doc.attributes.find((att) => att.attribute === name);

        if (
            attribute &&
            attribute.numeric_values === 1 &&
            !isNaN(numericValue) &&
            numericValue >= attribute.from_range &&
            numericValue <= attribute.to_range
        ) {
            setSingleVariantState((prevState) => ({
                ...prevState,
                [name]: numericValue
            }));
        } else {
            setSingleVariantState((prevState) => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    const Validated = () => {
        if (!doc || !doc.attributes) return false;

        return doc.attributes.every((att) => {
            const value = singleVariantState[att.attribute];
            if (att.numeric_values === 1) {
                const numericValue = parseFloat(value);
                return !isNaN(numericValue) && numericValue >= att.from_range && numericValue <= att.to_range;
            }
            return value !== '';
        });
    };

    const addOneVariant = (
        <Box sx={modalStyle}>
            <MainCard divider title={t('common:créer_une_seule_variante')}>
                <Grid container direction={'column'} spacing={1} justifyContent={'space-between'}>
                    {doc &&
                        doc.attributes &&
                        doc.attributes.map((att, i) => {
                            return (
                                <Grid item key={i}>
                                    <FormControl fullWidth sx={{ mb: 1 }}>
                                        {att.numeric_values === 1 ? (
                                            <TextField
                                                required
                                                type="number"
                                                name={att.attribute}
                                                label={att.attribute}
                                                value={singleVariantState[att.attribute] || ''}
                                                onChange={handleAttributeChange}
                                                inputProps={{
                                                    min: att.from_range,
                                                    max: att.to_range
                                                }}
                                                helperText={`${t('products:Min_Value')}: ${att.from_range}, ${t('products:Max_Value')}: ${
                                                    att.to_range
                                                }, ${t('products:increment')}: ${att.increment}`}
                                                error={
                                                    singleVariantState[att.attribute] !== '' &&
                                                    (parseFloat(singleVariantState[att.attribute]) < att.from_range ||
                                                        parseFloat(singleVariantState[att.attribute]) > att.to_range)
                                                }
                                            />
                                        ) : (
                                            <>
                                                <InputLabel id={`${att.attribute}-label`} required>
                                                    {att.attribute}
                                                </InputLabel>
                                                <Select
                                                    required
                                                    name={att.attribute}
                                                    labelId={`${att.attribute}-label`}
                                                    value={singleVariantState[att.attribute] || ''}
                                                    onChange={handleAttributeChange}
                                                    input={<OutlinedInput label={att.attribute} />}
                                                >
                                                    {itemAttributesValues
                                                        ?.filter((el) => el.parent === att.attribute)
                                                        ?.map((el, i) => (
                                                            <MenuItem key={i} value={el.attribute_value}>
                                                                {el.attribute_value}
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                            </>
                                        )}
                                    </FormControl>
                                </Grid>
                            );
                        })}
                </Grid>
                <Box display={'flex'} justifyContent={'right'} mt={2}>
                    <Button color="blue" variant="contained" size="large" onClick={handleCreateSingleVariant} disabled={!Validated()}>
                        {t('common:create')}
                    </Button>
                </Box>
            </MainCard>
        </Box>
    );

    const disableAlert = (
        <Box sx={modalStyle}>
            <MainCard divider title={t('common:confirm')}>
                <Typography id="modal-modal-description" sx={{ fontSize: 14 }}>
                    {doc?.disabled === 0 ? t('products:unarchive') : t('products:archive')} <strong>{doc?.item_name}</strong> ?
                </Typography>
                <Box display={'flex'} justifyContent={'flex-end'} mt={3}>
                    <Box>
                        <Grid container spacing={1}>
                            <Grid item>
                                <Button variant="outlined" color="blue" size="large" onClick={handleClose}>
                                    {t('common:non')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button color="blue" size="large" onClick={() => handleToggleDisabled()} variant="contained">
                                    {t('common:oui')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </MainCard>
        </Box>
    );

    const formOpened = () => {
        if (doc) {
            if (editToggled) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    };

    if (isPending) return <Loader />;

    return (
        <div>
            <MainCard
                title={
                    <Box p={-1} display={'flex'} justifyContent={'space-between'}>
                        <Box p={-1} display={'flex'} flexDirection={'column'}>
                            <ArrowBackIcon className={classes.backLink} onClick={() => navigate(-1)} />
                            {`${t('products:product')}: ${id}`}
                        </Box>
                        <Box>
                            {doc?.has_variants === 1 ? (
                                <Box>
                                    <Button
                                        disabled={formOpened()}
                                        startIcon={<AddCircleIcon />}
                                        variant="outlined"
                                        sx={{ ml: 1 }}
                                        color="blue"
                                        onClick={() => handleOpen(2)}
                                    >
                                        {t('common:créer_une_seule_variante')}
                                    </Button>
                                    <Button
                                        disabled={formOpened()}
                                        startIcon={<AddCircleIcon />}
                                        variant="outlined"
                                        color="blue"
                                        sx={{ ml: 1 }}
                                        onClick={() => handleOpen(1)}
                                    >
                                        {t('common:créer_plusieurs_variants')}
                                    </Button>
                                    <Button
                                        sx={{ ml: 1 }}
                                        disabled={formOpened()}
                                        onClick={() => handleOpen(3)}
                                        startIcon={doc?.disabled == 0 ? <ArchiveIcon color="error" /> : <UnarchiveIcon color="error" />}
                                        variant="outlined"
                                        color="error"
                                    >
                                        {doc?.disabled === 0 ? t('products:archive') : t('products:unarchive')}
                                    </Button>
                                    <Button
                                        disabled={formOpened()}
                                        startIcon={<ContentCopyIcon color="warning" />}
                                        variant="outlined"
                                        color="warning"
                                        sx={{ ml: 1 }}
                                        onClick={() => handleDuplicate()}
                                    >
                                        {t('products:duplicate')}
                                    </Button>
                                    <Button
                                        startIcon={<EditIcon color={formOpened() ? 'Menu' : ''} />}
                                        variant={formOpened() ? 'contained' : 'outlined'}
                                        color="blue"
                                        sx={{ ml: 1 }}
                                        onClick={() => setEditToggled(true)}
                                    >
                                        {t('common:edit')}
                                    </Button>
                                    <ReactToPrint
                                        trigger={() => (
                                            <IconButton sx={{ ml: 1 }} disabled={formOpened()} aria-label="delete" color="blue">
                                                <PrintIcon />
                                            </IconButton>
                                        )}
                                        content={() => componentRef.current}
                                    />
                                </Box>
                            ) : (
                                <Box>
                                    <Button
                                        sx={{ ml: 1 }}
                                        disabled={formOpened()}
                                        startIcon={doc?.disabled == 0 ? <ArchiveIcon color="error" /> : <UnarchiveIcon color="error" />}
                                        variant="outlined"
                                        color="error"
                                        onClick={() => handleOpen(3)}
                                    >
                                        {doc?.disabled === 0 ? t('products:archive') : t('products:unarchive')}
                                    </Button>
                                    <Button
                                        disabled={formOpened()}
                                        startIcon={<ContentCopyIcon color="warning" />}
                                        variant="outlined"
                                        color="warning"
                                        sx={{ ml: 1 }}
                                        onClick={() => handleDuplicate()}
                                    >
                                        {t('products:duplicate')}
                                    </Button>
                                    <Button
                                        startIcon={<EditIcon color={formOpened() ? 'Menu' : ''} />}
                                        variant={formOpened() ? 'contained' : 'outlined'}
                                        color="blue"
                                        sx={{ ml: 1 }}
                                        onClick={() => setEditToggled(true)}
                                    >
                                        {t('common:edit')}
                                    </Button>
                                    <ReactToPrint
                                        trigger={() => (
                                            <IconButton sx={{ ml: 1 }} disabled={formOpened()} aria-label="delete" color="blue">
                                                <PrintIcon />
                                            </IconButton>
                                        )}
                                        content={() => componentRef.current}
                                    />
                                </Box>
                            )}
                        </Box>
                    </Box>
                }
            >
                {doc && doc.has_variants === 1 && (
                    <GenericProducDetails
                        isTemplate={true}
                        doc={doc}
                        editToggled={editToggled}
                        storedFiles={files}
                        refetchFiles={refetchFiles}
                    />
                )}
                {doc && doc.has_variants === 0 && (
                    <GenericProducDetails
                        isTemplate={false}
                        doc={doc}
                        editToggled={editToggled}
                        storedFiles={files}
                        refetchFiles={refetchFiles}
                    />
                )}
            </MainCard>
            <Box display={'none'}>
                <PrintItemTemplate ref={componentRef} item={doc} />
            </Box>
            <Modal onClose={handleClose} open={open}>
                {modalId === 1 ? addVariants : modalId === 2 ? addOneVariant : modalId === 3 ? disableAlert : <div>Oops!</div>}
            </Modal>
        </div>
    );
};

export default TemplateProdVariantDetails;
