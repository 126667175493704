import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { Avatar, Box, Chip, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import TotalIncomeCard from 'ui-component/cards/Skeleton/TotalIncomeCard';

// assets
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useSelector } from 'react-redux';
import { CurrencyFormatter } from 'utils/utils';

// styles
const CardWrapper = styled(MainCard)(({ theme }) => ({
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.light,
    overflow: 'hidden',
    position: 'relative',
    '&:after': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        background: `linear-gradient(210.04deg, ${theme.palette.primary[200]} -50.94%, rgba(144, 202, 249, 0) 83.49%)`,
        borderRadius: '50%',
        top: -30,
        right: -180
    },
    '&:before': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        background: `linear-gradient(140.9deg, ${theme.palette.primary[200]} -14.02%, rgba(144, 202, 249, 0) 77.58%)`,
        borderRadius: '50%',
        top: -160,
        right: -130
    }
}));

// ==============================|| DASHBOARD - TOTAL INCOME DARK CARD ||============================== //

const TotalIncomeDarkCard = ({ isLoading, title, result, icon, percentage, since, isCurr }) => {
    const theme = useTheme();
    const globalDefaults = useSelector((state) => state.login.globalDefaults);

    return (
        <>
            {isLoading ? (
                <TotalIncomeCard />
            ) : (
                <CardWrapper border={false} content={false}>
                    <Box sx={{ py: 2, pl: 1, pb: 0.5 }}>
                        <List sx={{ py: 0 }}>
                            <ListItem alignItems="center" disableGutters sx={{ py: 0 }}>
                                <ListItemAvatar>
                                    <Avatar
                                        variant="rounded"
                                        sx={{
                                            ...theme.typography.commonAvatar,
                                            ...theme.typography.largeAvatar,
                                            backgroundColor: theme.palette.primary[800],
                                            color: '#fff'
                                        }}
                                    >
                                        {icon}
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    sx={{
                                        py: 0,
                                        mt: 0.45,
                                        mb: 0.45
                                    }}
                                    primary={
                                        <Typography variant="subtitle2" color={'Menu'} fontSize={14} fontWeight={600}>
                                            {title}
                                        </Typography>
                                    }
                                    secondary={
                                        <div
                                            style={{
                                                marginTop: 0.25,
                                                width: '100%',
                                                display: 'flex',
                                                flexDirection: 'row',
                                                gap: 10,
                                                justifyContent: 'space-between'
                                            }}
                                        >
                                            <Typography variant="h4" color={'#fff'}>
                                                {!isCurr ? (
                                                    result
                                                ) : (
                                                    <CurrencyFormatter
                                                        locale="fr-FR"
                                                        currency={globalDefaults?.default_currency}
                                                        minimumFractionDigits={3}
                                                        value={result}
                                                    />
                                                )}
                                            </Typography>
                                            <div style={{ marginRight: 10, whiteSpace: 'nowrap' }}>
                                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5 }}>
                                                    {percentage > 0 ? (
                                                        <ArrowDropUpIcon color="success" />
                                                    ) : percentage < 0 ? (
                                                        <ArrowDropDownIcon color="error" />
                                                    ) : null}
                                                    {since && (
                                                        <Chip
                                                            size="small"
                                                            label={percentage?.toFixed(2) + ' %'}
                                                            color={percentage === 0 ? 'primary' : percentage > 0 ? 'success' : 'error'}
                                                        />
                                                    )}
                                                    <Typography variant="subtitle2">{since}</Typography>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                />
                            </ListItem>
                        </List>
                    </Box>
                </CardWrapper>
            )}
        </>
    );
};

TotalIncomeDarkCard.propTypes = {
    isLoading: PropTypes.bool
};

export default TotalIncomeDarkCard;
