// project imports

// action - state management
import * as actionTypes from '../actions';

export const initialState = {
    notification: null,
    notificationBrodcast: null
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_NOTIFICATION:
            return {
                ...state,
                notification: action.notification
            };
        case actionTypes.SET_NOTIFICATION_BRODCAST:
            return {
                ...state,
                notificationBrodcast: action.payload
            };
        default:
            return state;
    }
};

export default notificationReducer;
