import React, { useState } from 'react';
import { Modal, Box, TextField, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const SupplierRenameModal = ({ isOpen, onClose, currentName, onSave }) => {
    const { t } = useTranslation();
    const [newName, setNewName] = useState(currentName);

    const handleSave = () => {
        onSave(newName);
        onClose();
    };

    return (
        <Modal open={isOpen} onClose={onClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2
                }}
            >
                <Typography variant="h6" component="h2" gutterBottom>
                    {t('common:Rename_Supplier')}: {currentName}
                </Typography>
                <TextField
                    fullWidth
                    label={t('common:New_Supplier_Name')}
                    value={newName}
                    onChange={(e) => setNewName(e.target.value)}
                    margin="normal"
                />
                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button onClick={onClose} sx={{ mr: 1 }} variant="outlined" color="blue">
                        {t('common:cancel')}
                    </Button>
                    <Button onClick={handleSave} variant="contained" color="blue">
                        {t('common:save')}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default SupplierRenameModal;
