import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useStyles } from 'views/utilities/Style';
import MainCard from 'ui-component/cards/MainCard';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useFetch from 'hooks/useFetch';
import Reasons from './components/Reasons';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { SetNotification } from 'store/services/api';
import { formatErpResponse } from 'utils/utils';
import StockSettings from './components/StockSettings';
import NamingSeries from './components/NamingSeries';
import SystemSettings from 'views/systemsettings/SystemSettings';
//import PrintSettings from 'views/printsettings/SystemSettings';

const Settings = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [value, setValue] = useState('1');

    useEffect(() => {
        const s_Tab = localStorage.getItem('settingsTab');
        if (s_Tab) {
            setValue(s_Tab);
        }
    }, []);

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const reasonsReqData = {
        doctype: 'Opportunity Lost Reason',
        fields: ['`tabOpportunity Lost Reason`.`name`'],
        start: 0,
        page_length: 50,
        order_by: '`tabOpportunity Lost Reason`.`modified` desc'
    };

    const reasonsReconciliationStockReqData = {
        doctype: 'Quotation Lost Reason',
        fields: ['`tabQuotation Lost Reason`.`name`'],
        start: 0,
        page_length: 50,
        order_by: '`tabQuotation Lost Reason`.`modified` desc'
    };

    const { data: reasons, isPending: isReasonsPending, refetch: refetchReasons } = useFetch(`/api/get-list`, reasonsReqData, 'POST');
    const {
        data: stockReconciliationReasons,
        isPending: isStockReconciliationReasonsPending,
        refetch: refetchStockReconciliationReasons
    } = useFetch(`/api/get-list`, reasonsReconciliationStockReqData, 'POST');

    const handleChange = (event, newValue) => {
        setValue(newValue);
        localStorage.setItem('settingsTab', newValue);
    };

    const handleReasonInsert = async (doctype, reason, refetchFunction) => {
        try {
            const content = {
                doctype: doctype,
                [doctype === 'Opportunity Lost Reason' ? 'lost_reason' : 'order_lost_reason']: reason
            };
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
                method: 'POST',
                headers: header,
                body: JSON.stringify({
                    doc: content,
                    action: 'Save'
                })
            });
            const data = await response.json();
            if (data?.success === false) {
                dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
            } else {
                dispatch(SetNotification({ code: 'success', message: t('crm:added_motif_retour_success') }));
                refetchFunction();
                handleReload();
            }
        } catch (error) {
            console.error('Error inserting reason:', error);
            dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
        }
    };

    const handleReasonDelete = async (doctype, reason, refetchFunction) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/delete-doc`, {
                method: 'POST',
                headers: header,
                body: JSON.stringify({ doctype: doctype, name: reason?.name })
            });
            const data = await response.json();
            if (data?.success === false) {
                dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
            } else {
                dispatch(SetNotification({ code: 'success', message: t('crm:deleted_motif_retour_success') }));
                refetchFunction();
                handleReload();
            }
        } catch (error) {
            console.error('Error deleting reason:', error);
            dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
        }
    };

    const handleReasonEdit = async (doctype, reason, newName) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/rename-doc`, {
                method: 'POST',
                headers: header,
                body: JSON.stringify({
                    doctype: doctype,
                    docname: reason.name,
                    new_name: newName
                })
            });
            const data = await response.json();
            if (data?.success === false) {
                dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
            } else {
                dispatch(SetNotification({ code: 'success', message: t('crm:edited_motif_retour_success') }));
                refetchReasons();
                handleReload();
            }
        } catch (error) {
            console.error('Error editing reason:', error);
            dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
        }
    };

    const handleReload = () => {
        window.location.reload();
    };

    const title = (
        <Box p={-1} display={'flex'} flexDirection={'column'}>
            <ArrowBackIcon className={classes.backLink} onClick={() => navigate(-1)} />
            {t('crm:settings')}
        </Box>
    );

    return (
        <Box width={'100%'}>
            <MainCard title={title}>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="settings tabs">
                                <Tab label={t('settings:text-100')} value="1" />
                                <Tab label={t('settings:ss')} value="2" />
                                <Tab label={t('settings:r')} value="3" />
                                <Tab label={t('settings:ns')} value="4" />
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <SystemSettings />
                        </TabPanel>
                        <TabPanel value="2">
                            <StockSettings />
                        </TabPanel>
                        <TabPanel value="3">
                            <Reasons
                                reasons={reasons}
                                isReasonsPending={isReasonsPending}
                                refetch={refetchReasons}
                                handleReasonInsert={(reason) => handleReasonInsert('Opportunity Lost Reason', reason, refetchReasons)}
                                handleReasonDelete={(reason) => handleReasonDelete('Opportunity Lost Reason', reason, refetchReasons)}
                                handleReasonEdit={(reason, newName) => handleReasonEdit('Opportunity Lost Reason', reason, newName)}
                                doctype="Opportunity Lost Reason"
                                title={t('crm:returnreasons')}
                            />
                            <Box mt={2}>
                                <Reasons
                                    reasons={stockReconciliationReasons}
                                    isReasonsPending={isStockReconciliationReasonsPending}
                                    refetch={refetchStockReconciliationReasons}
                                    handleReasonInsert={(reason) =>
                                        handleReasonInsert('Quotation Lost Reason', reason, refetchStockReconciliationReasons)
                                    }
                                    handleReasonDelete={(reason) =>
                                        handleReasonDelete('Quotation Lost Reason', reason, refetchStockReconciliationReasons)
                                    }
                                    doctype="Quotation Lost Reason"
                                    title={t('crm:stockreason')}
                                />
                            </Box>
                        </TabPanel>
                        <TabPanel value="4">
                            <NamingSeries />
                        </TabPanel>
                    </TabContext>
                </Box>
            </MainCard>
        </Box>
    );
};

export default Settings;
