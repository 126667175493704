import { useNavigate, useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import React, { useState } from 'react';

import { Box } from '@mui/system';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useFetch from 'hooks/useFetch';
import { useTranslation } from 'react-i18next';
import { SetNotification } from 'store/services/api';
import Loader from 'ui-component/Loader';
import MainCard from 'ui-component/cards/MainCard';
import InputTextField from 'ui-component/form/InputTextField';
import { useStyles } from 'views/utilities/Style';
import { formatErpResponse } from 'utils/utils';

const FamilleProductAdd = () => {
    const classes = useStyles();
    const { id } = useParams();
    const [item, setItem] = useState('');
    const [code, setCode] = useState('');
    const [libelle, setLibille] = useState('');
    const [abrege, setAbrege] = useState('');
    const [sfamille, setsfamille] = useState('');

    const [selectedSfamille, setSelectedSfamille] = useState([]);
    const [loadingButton, setLoadingButton] = useState(false);
    const [objectService, setObjectService] = useState([]);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    function removeSpaces(word) {
        return word.replace(/\s+/g, '');
    }

    function removeUpToWord(str, word) {
        const index = str.indexOf(word);
        if (index !== -1) {
            return str.substring(index + word.length);
        }
        return str;
    }

    const handleService = (selectedService) => {
        const sfamillleName = selectedService.target.value;
        const selectedServiceObject = sousfamille.find((sfamille) => sfamille.libelle === sfamillleName);
        setObjectService(selectedServiceObject);
        setSelectedSfamille([...selectedSfamille, selectedServiceObject]);
    };

    const { data: sousfamille, isPending } = useFetch(
        `/api/get-list`,
        {
            doctype: 'SousFamille',
            fields: ['`tabSousFamille`.`libelle`', '`tabSousFamille`.`abrege`', '`tabSousFamille`.`code`']
        },
        'POST'
    );

    const handleAddFamille = () => {
        setLoadingButton(true);
        const header = {
            Accept: 'application/json',
            'content-type': 'application/json',
            'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem('user'))?.token
        };
        const requestData = {
            doctype: 'Famille',
            code: code,
            libelle: libelle,
            abrege: abrege
        };

        fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doc: requestData, requestData, action: 'Save' })
        })
            .then((res) => res.json())
            .then((data) => {
                setLoadingButton(false);
                if (data?.success === false) {
                    dispatch(
                        SetNotification({
                            code: 'warning',
                            message:
                                removeUpToWord(formatErpResponse(data.message), 'already ') == 'exists'
                                    ? t('common:msg_family_code')
                                    : t(`common:${removeSpaces(formatErpResponse(data.message))}`)
                        })
                    );
                } else {
                    navigate('/pim/famille-products-view');
                    dispatch(SetNotification({ code: 'success', message: t('common:family_create_success') }));
                }
            })
            .catch((error) => {
                setLoadingButton(false);
                dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
            });
    };

    const handleCode = (e) => {
        setCode(e.target.value);
    };
    const handleLibelle = (e) => {
        setLibille(e.target.value);
    };
    const handleAbrege = (e) => {
        setAbrege(e.target.value);
    };

    const handleDelete = (index) => {
        const newSelectedItems = [...selectedSfamille];
        newSelectedItems.splice(index, 1);
        setSelectedSfamille(newSelectedItems);
    };
    if (isPending) return <Loader />;
    const title = (
        <Box p={-1} display={'flex'} flexDirection={'column'}>
            <ArrowBackIcon className={classes.backLink} onClick={() => navigate(-1)} />
            {t('common:ajout-famille-des-produits')}
        </Box>
    );

    return (
        <Box>
            <MainCard title={title}>
                <Grid container spacing={2} display={'flex'} justifyContent={'left'} flexDirection={'column'}>
                    <InputTextField handleChange={handleCode} value={code} label={t('common:code')} name="code" required={true} />

                    <InputTextField
                        handleChange={handleLibelle}
                        value={libelle}
                        label={t('common:uom_name')}
                        name="libelle"
                        required={true}
                    />
                    {/*  <InputTextField
                       handleChange={handleAbrege}
                        value={abrege}
                        label={'Abrégé'}
                        name="Abrégé"
                    />*/}
                </Grid>

                <Grid container item xs={12} sm={12} md={8} lg={8} xl={8} spacing={4} display={'flex'} justifyContent={'center'}>
                    <Grid item mt={3}>
                        <LoadingButton
                            variant="contained"
                            size="large"
                            color="blue"
                            onClick={() => handleAddFamille()}
                            loading={loadingButton}
                        >
                            {t('common:save')}
                        </LoadingButton>
                    </Grid>
                </Grid>
            </MainCard>
        </Box>
    );
};
export default FamilleProductAdd;
