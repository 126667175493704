/* eslint-disable */
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import MainCard from 'ui-component/cards/MainCard';
import { useStyles } from 'views/utilities/Style';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
    Grid,
    Typography,
    Button,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    OutlinedInput,
    Checkbox,
    TextField,
    FormControlLabel
} from '@mui/material';
import InputTextField from 'ui-component/form/InputTextField';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';
import { LoadingButton } from '@mui/lab';
import useGetAxios from 'hooks/useGetAxios';
import Loader from 'ui-component/Loader';
import { SetNotification } from 'store/services/api';
import { useDispatch } from 'react-redux';
import { formatErpResponse } from 'utils/utils';
import useFetch from 'hooks/useFetch';
import { useBlocker } from 'hooks/useBlocker';
import NavigationModal from 'ui-component/cards/NavigationModal';
import { useTranslation } from 'react-i18next';

const AddItemAttributeForm = () => {
    const classes = useStyles();
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [numericValuesChecked, setNumericValuesChecked] = useState(false);
    const [automaticChecked, setAutomaticChecked] = useState(false);
    const [valueFrom, setValueFrom] = useState(1);
    const [valueTo, setValueTo] = useState(2);
    const [valueIncrement, setValueIncrement] = useState(1);
    const [formIsDirty, setFormIsDirty] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    function removeSpaces(word) {
        return word.replace(/\s+/g, '');
    }

    useBlocker(({ retry }) => {
        setModalOpen(true);
    }, formIsDirty);

    const handleCloseBlocker = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }
        setModalOpen(false);
        setFormIsDirty(false);
    };

    const { data: doc, isPending } = useGetAxios(`${process.env.REACT_APP_API_URI}/api/details?doctype=Item%20Attribute&name=${id}`);

    const { data, isAttributesPending } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Item Attribute',
            fields: ['`tabItem Attribute`.`name`']
        },
        'POST'
    );

    const [loadingButton, setLoadingButton] = useState(false);
    const [attValues, setAttValues] = useState([]);
    const [attValuesState, setAttValuesState] = useState({
        value: '',
        abbr: ''
    });
    const handleChangeAttValues = (e) => {
        setFormIsDirty(true);
        setAttValuesState({ ...attValuesState, [e.target.name]: e.target.value });
    };
    const handleAddAttValues = () => {
        const rowIndex = attValues.findIndex((el) => el.value == attValuesState.value);
        if (rowIndex == -1) {
            setAttValues([...attValues, attValuesState]);
        }
        handleClose();
        setAttValuesState({ value: '', abbr: '' });
    };

    const handleDelete = (el) => {
        const data = attValues.filter((row) => row.value != el.value);
        setAttValues(data);
    };

    const [attState, setAttState] = useState({
        name: '',
        attribute_name: '',
        attribute_code: ''
    });

    const handleChange = (e) => {
        setFormIsDirty(true);
        setAttState({ ...attState, [e.target.name]: e.target.value });
    };

    const isAttributeNameValid = attState.attribute_name !== '';
    const areAttributeValuesFilled = numericValuesChecked || attValues.length > 0;

    const [open, setOpen] = useState(true);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setAttValuesState({ value: '', abbr: '' });
    };
    const handleNumericValuesChange = (event) => {
        setNumericValuesChecked(event.target.checked);
    };
    const handleNumericValuesFrom = (event) => {
        setValueFrom(event.target.value);
    };
    const handleNumericValuesTo = (event) => {
        setValueTo(event.target.value);
    };
    const handleNumericValuesIncrement = (event) => {
        setValueIncrement(event.target.value);
    };
    const handleCheckChange = (event) => {
        setAutomaticChecked(event.target.checked);
    };
    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };
    const handleSaveItemAttribute = async () => {
        setFormIsDirty(false);
        setLoadingButton(true);
        let item_attribute_values = [];
        attValues.map((el) => {
            item_attribute_values.push({
                ...(id && { parent: id, __unedited: false }),
                doctype: 'Item Attribute Value',
                attribute_value: el.value,
                abbr: el.abbr,
                parentfield: 'item_attribute_values',
                parenttype: 'Item Attribute',
                __islocal: 1,
                __unsaved: 1
            });
        });

        const data = {
            doc: {
                ...(!id && { __islocal: 1 }),
                ...(id && {
                    creation: doc.creation,
                    modified: doc.modified,
                    owner: doc.owner,
                    modified_by: doc.owner,
                    name: doc.name
                }),
                doctype: 'Item Attribute',
                attribute_name: attState.attribute_name,
                attribute_code: attState.attribute_code,
                item_attribute_values: item_attribute_values,
                numeric_values: numericValuesChecked ? 1 : 0,
                from_range: valueFrom,
                increment: valueIncrement,
                to_range: valueTo,
                automatic: automaticChecked ? 1 : 0,
                __unsaved: 1
            },
            action: 'Save'
        };
        fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((data) => {
                setLoadingButton(false);
                if (data.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: t(`common:${removeSpaces(formatErpResponse(data.message))}`) }));
                    // console.log(data.message);
                } else {
                    if (id && doc.attribute_name !== attState.attribute_name) {
                        return handleRenameItemAttribute();
                    }
                    dispatch(SetNotification({ code: 'success', message: t('common:type_variant_crée_succés') }));
                    navigate('/pim/product-attributes');
                }
            })
            .catch((error) => {
                setLoadingButton(false);
                dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
            });
    };

    useEffect(() => {
        if (id && !Array.isArray(doc) && doc) {
            setAttState({
                attribute_name: doc.attribute_name,
                attribute_code: doc.attribute_code
            });
            let values = [];
            doc?.item_attribute_values?.map((el) => {
                values.push({
                    value: el.attribute_value,
                    abbr: el.abbr
                });
            });
            setAttValues(values);
        }
    }, [id, doc]);

    const title = (
        <Box p={-1} display={'flex'} flexDirection={'column'}>
            <ArrowBackIcon className={classes.backLink} onClick={() => navigate(-1)} />
            {id ? `Variante: ${id}` : t('common:new-variant')}
        </Box>
    );

    function isStringIncluded(array, searchString) {
        for (let i = 0; i < array?.length; i++) {
            if (array[i]?.name?.toLowerCase() === searchString?.toLowerCase()) {
                if (id) {
                    if (id === searchString) {
                        return false;
                    } else {
                        return true;
                    }
                } else {
                    return true;
                }
            }
        }
        return false;
    }

    const handleGenerateCode = () => {
        const randomCode = generateRandomCode(8);
        setAttState({ ...attState, attribute_code: randomCode });
    };

    const generateRandomCode = (length) => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    };

    if (id && isPending) return <Loader />;

    return (
        <Box>
            <MainCard title={title}>
                <Grid container spacing={2} display={'flex'} justifyContent={'left'} flexDirection={'column'}>
                    <Box display="flex" alignItems="center">
                        <FormControlLabel
                            label={t('products:automatic')}
                            control={<Checkbox color="primary" checked={automaticChecked} onChange={handleCheckChange} />}
                        />
                        {automaticChecked && <Button onClick={handleGenerateCode}>{t('products:Genecode')}</Button>}
                    </Box>
                    <InputTextField
                        label={t('products:attcode')}
                        value={attState.attribute_code}
                        name="attribute_code"
                        handleChange={handleChange}
                        disabled={automaticChecked}
                        maxLength={8}
                    />
                    <InputTextField
                        label={t('common:nom')}
                        value={attState.attribute_name}
                        name="attribute_name"
                        handleChange={handleChange}
                        helper={isStringIncluded(data, attState.attribute_name) ? 'Existe déjà' : null}
                    />
                </Grid>
                <Box display="flex" alignItems="center">
                    <Checkbox
                        checked={numericValuesChecked}
                        onChange={handleNumericValuesChange}
                        color="primary"
                        inputProps={{ 'aria-label': t('common:numeric-values') }}
                    />
                    <Typography variant="body1" style={{ marginLeft: '8px' }}>
                        {t('common:numeric-values')}
                    </Typography>
                </Box>
                {numericValuesChecked ? (
                    <Box mt={3}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="From"
                                    type="number"
                                    value={valueFrom}
                                    onChange={handleNumericValuesFrom}
                                    name="from"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField label="To" type="number" value={valueTo} name="to" onChange={handleNumericValuesTo} fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Increment"
                                    type="number"
                                    value={valueIncrement}
                                    name="increment"
                                    onChange={handleNumericValuesIncrement}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Box>
                ) : (
                    <Grid container xs={12} sm={12} md={8} lg={8} xl={8}>
                        <Grid item xs={12}>
                            <Box mt={3}>
                                <Grid container justifyContent={'space-between'}>
                                    <Typography fontWeight={600} variant="h4">
                                        {t('common:values')}
                                    </Typography>
                                    <Button color="blue" onClick={handleOpen}>
                                        <AddIcon sx={{ marginLeft: -1 }} /> {t('common:addValue')}
                                    </Button>
                                </Grid>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                <strong>{t('common:valueDesignation')}</strong>
                                            </TableCell>
                                            <TableCell>
                                                <strong>{t('common:Abbreviation')}</strong>
                                            </TableCell>
                                            <TableCell align="right" width={50}></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {attValues.map((el, i) => (
                                            <TableRow key={i}>
                                                <TableCell>{el.value}</TableCell>
                                                <TableCell sx={{ textTransform: 'uppercase' }}>{el.abbr}</TableCell>
                                                <TableCell align="right" width={50}></TableCell>
                                                <TableCell align="right" width={50}>
                                                    <DeleteIcon color="error" onClick={() => handleDelete(el)} sx={{ cursor: 'pointer' }} />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        {open && (
                                            <TableRow key={999}>
                                                <TableCell>
                                                    <OutlinedInput
                                                        sx={{ height: 28 }}
                                                        placeholder={t('common:valueDesignation')}
                                                        value={attValuesState.value}
                                                        name="value"
                                                        onChange={handleChangeAttValues}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <OutlinedInput
                                                        sx={{ height: 28 }}
                                                        placeholder={t('common:Abbreviation')}
                                                        value={attValuesState.abbr}
                                                        name="abbr"
                                                        onChange={handleChangeAttValues}
                                                    />
                                                </TableCell>
                                                <TableCell align="right" width={50}>
                                                    <DoneIcon
                                                        color={attValuesState.value == '' || attValuesState.abbr == '' ? 'disabled' : ''}
                                                        onClick={
                                                            attValuesState.value == '' || attValuesState.abbr == ''
                                                                ? null
                                                                : handleAddAttValues
                                                        }
                                                        sx={{ cursor: 'pointer' }}
                                                    />
                                                </TableCell>
                                                <TableCell align="right" width={50}>
                                                    <DeleteIcon color="error" onClick={handleClose} sx={{ cursor: 'pointer' }} />
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Grid>
                    </Grid>
                )}
                <Box display={'flex'} justifyContent={'center'} mt={3}>
                    <Button sx={{ mr: 1 }} onClick={() => navigate(-1)}>
                        {t('common:cancel')}
                    </Button>
                    <LoadingButton
                        disabled={!isAttributeNameValid || !areAttributeValuesFilled}
                        loading={loadingButton}
                        size="large"
                        variant="contained"
                        color="blue"
                        onClick={handleSaveItemAttribute}
                    >
                        {t('common:save')}
                    </LoadingButton>
                </Box>
            </MainCard>
            <NavigationModal open={modalOpen} onClose={handleCloseBlocker} />
        </Box>
    );
};

export default AddItemAttributeForm;
