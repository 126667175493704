import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/system';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
    Button,
    Checkbox,
    Chip,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    FormHelperText,
    InputAdornment
} from '@mui/material';
import useFetch from 'hooks/useFetch';
import { useTranslation } from 'react-i18next';
import { SetNotification } from 'store/services/api';
import Loader from 'ui-component/Loader';
import MainCard from 'ui-component/cards/MainCard';
import { useStyles } from 'views/utilities/Style';
import useGetAxios from 'hooks/useGetAxios';
import validator from 'validator';
import { formatErpResponse } from 'utils/utils';
import FormDateTime from 'ui-component/dateTime/FormDateTime';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import ApartmentIcon from '@mui/icons-material/Apartment';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import { SetDoc2Duplicate } from 'store/services/api';
import CountriesSelect from './component/CountriesSelect';

const SuppliersForm = ({ doc, isEdit, isDuplicating }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [loadingButton, setLoadingButton] = useState(false);

    const [showSupplierCodeSettings, setShowSupplierCodeSettings] = useState(false);
    const docToDuplicate = useSelector((state) => state.pim.docToDuplicate);

    const handleCompanyChange = (index, value) => {
        const updatedCompanyData = [...companyData];
        updatedCompanyData[index].company = value;
        setCompanyData(updatedCompanyData);
    };

    const handleAddCompany = () => {
        setCompanyData([
            ...companyData,
            {
                docstatus: 0,
                doctype: 'Allowed To Transact With',
                __islocal: 1,
                __unsaved: 1,
                company: '',
                parentfield: 'companies',
                parenttype: 'Supplier',
                __unedited: true
            }
        ]);
    };
    const handleRemoveCompany = (index) => {
        const updatedCompanyData = [...companyData];
        updatedCompanyData.splice(index, 1);
        setCompanyData(updatedCompanyData);
    };

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const [state, setState] = useState(() => {
        if (docToDuplicate) {
            return {
                doctype: 'Supplier',
                supplier_name: `${docToDuplicate.supplier_name} (Copy)`,
                fournisseur_code: '',
                pan: docToDuplicate.pan || '',
                supplier_code_settings: docToDuplicate.supplier_code_settings || '',
                supplier_type: docToDuplicate.supplier_type || '',
                supplier_group: docToDuplicate.supplier_group || '',
                allow_purchase_invoice_creation_without_purchase_order:
                    docToDuplicate.allow_purchase_invoice_creation_without_purchase_order || 0,
                allow_purchase_invoice_creation_without_purchase_receipt:
                    docToDuplicate.allow_purchase_invoice_creation_without_purchase_receipt || 0,
                warn_rfqs: docToDuplicate.warn_rfqs || 0,
                warn_pos: docToDuplicate.warn_pos || 0,
                prevent_rfqs: docToDuplicate.prevent_rfqs || 0,
                prevent_pos: docToDuplicate.prevent_pos || 0,
                is_transporter: docToDuplicate.is_transporter || 0,
                is_internal_supplier: docToDuplicate.is_internal_supplier || 0,
                disabled: docToDuplicate.disabled || 0,
                irs_1099: docToDuplicate.irs_1099 || 0,
                on_hold: docToDuplicate.on_hold || 0,
                hold_type: docToDuplicate.hold_type || '',
                release_date: docToDuplicate.release_date || '',
                tax_id: docToDuplicate.tax_id || '',
                country: docToDuplicate.country || '',
                represents_company: docToDuplicate.represents_company || '',
                tax_category: docToDuplicate.tax_category || '',
                email_id: docToDuplicate.email_id || '',
                mobile_no: docToDuplicate.mobile_no || '',
                address_line1: docToDuplicate.__onload?.addr_list[0]?.address_line1 || '',
                city: docToDuplicate.__onload?.addr_list[0]?.city || '',
                companies: docToDuplicate.companies || [],
                default_currency: docToDuplicate.default_currency || '',
                default_price_list: docToDuplicate.default_price_list || '',
                tax_withholding_category: docToDuplicate.tax_withholding_category || '',
                default_bank_account: docToDuplicate.default_bank_account || '',
                payment_terms: docToDuplicate.payment_terms || ''
            };
        } else {
            return {
                doctype: 'Supplier',
                supplier_name: isEdit ? doc?.supplier_name : '',
                fournisseur_code: isEdit ? doc?.fournisseur_code : '',
                pan: isEdit ? doc?.pan : '',
                supplier_code_settings: isEdit ? doc?.supplier_code_settings : '',
                supplier_type: isEdit ? doc?.supplier_type : '',
                supplier_group: isEdit ? doc?.supplier_group : '',
                allow_purchase_invoice_creation_without_purchase_order: isEdit
                    ? doc?.allow_purchase_invoice_creation_without_purchase_order
                    : 0,
                allow_purchase_invoice_creation_without_purchase_receipt: isEdit
                    ? doc?.allow_purchase_invoice_creation_without_purchase_receipt
                    : 0,
                warn_rfqs: isEdit ? doc?.warn_rfqs : 0,
                warn_pos: isEdit ? doc?.warn_pos : 0,
                prevent_rfqs: isEdit ? doc?.prevent_rfqs : 0,
                prevent_pos: isEdit ? doc?.prevent_pos : 0,
                is_transporter: isEdit ? doc?.is_transporter : 0,
                is_internal_supplier: isEdit ? doc?.is_internal_supplier : 0,
                disabled: isEdit ? doc?.disabled : 0,
                irs_1099: isEdit ? doc?.irs_1099 : 0,
                on_hold: isEdit ? doc?.on_hold : 0,
                hold_type: isEdit ? doc?.hold_type : '',
                release_date: isEdit ? doc?.release_date : '',
                tax_id: isEdit ? doc?.tax_id : '',
                country: isEdit ? doc?.country : '',
                represents_company: isEdit ? doc?.represents_company : '',
                tax_category: isEdit ? doc?.tax_category : '',
                email_id: isEdit ? doc?.email_id : '',
                mobile_no: isEdit ? doc?.mobile_no : '',
                address_line1: isEdit ? doc?.__onload?.addr_list[0]?.address_line1 : '',
                city: isEdit ? doc?.__onload?.addr_list[0]?.city : '',
                companies: isEdit ? doc?.companies : [],
                default_currency: isEdit ? doc?.default_currency : '',
                default_price_list: isEdit ? doc?.default_price_list : '',
                tax_withholding_category: isEdit ? doc?.tax_withholding_category : '',
                default_bank_account: isEdit ? doc?.default_bank_account : '',
                payment_terms: isEdit ? doc?.payment_terms : ''
            };
        }
    });

    const [companyData, setCompanyData] = useState(docToDuplicate?.companies || (isEdit ? doc?.companies : []));

    const [error, setError] = useState({
        supplier_name: false,
        fournisseur_code: false,
        supplier_code_settings: false,
        supplier_type: false,
        supplier_group: false,
        email_id: false,
        mobile_no: false,
        country: false
    });

    const suppliercodesettingsReqData = {
        doctype: 'Supplier Code Settings',
        fields: [
            '`tabSupplier Code Settings`.`name`',
            '`tabSupplier Code Settings`.`chrono_length`',
            '`tabSupplier Code Settings`.`automatic`',
            '`tabSupplier Code Settings`.`length`',
            '`tabSupplier Code Settings`.`prefix`'
        ],
        page_length: 1
    };
    const { data: suppliercodesettings, isPending: isPendingSupplierCodeSettings } = useFetch(
        `/api/get-list`,
        suppliercodesettingsReqData,
        'POST'
    );

    let globalAutomatic;
    let globalChronoLength;
    let globalPrefix;
    let globalLength;
    //onsole.log("suppliercodesettings",suppliercodesettings);
    if (suppliercodesettings) {
        suppliercodesettings.forEach((setting) => {
            const name = setting.name;
            const ChronoLength = setting.chrono_length;
            const automatic = setting.automatic;
            const prefix = setting.prefix;
            const length = setting.length;

            globalAutomatic = automatic;
            globalChronoLength = ChronoLength;
            globalPrefix = prefix;
            globalLength = length;
        });
    }

    //console.log("globalAutomatic",globalAutomatic);
    //console.log("globalChronoLength",globalChronoLength);
    // console.log("globalPrefix",globalPrefix);
    //console.log("globalLength",globalLength);

    const supplierReqData = {
        doctype: 'Supplier',
        fields: ['`tabSupplier`.`name`', '`tabSupplier`.`supplier_name`', '`tabSupplier`.`fournisseur_code`'],
        start: 0,
        page_length: 1,
        order_by: '`tabSupplier`.`creation` desc'
    };
    const { data: lastsupplier, isPending: isPendingLastSupplier } = useFetch(`/api/get-list`, supplierReqData, 'POST');

    let lastPart = null;
    if (lastsupplier && lastsupplier.length > 0) {
        const supplierCode = lastsupplier[0].fournisseur_code;
        if (supplierCode && typeof supplierCode === 'string') {
            const parts = supplierCode.split('-');
            lastPart = parseInt(parts[parts.length - 1], 10);
        } else {
            console.warn('Invalid or missing fournisseur_code in lastsupplier[0]');
        }
    } else {
        console.warn('No lastsupplier found or lastsupplier is empty');
    }

    //console.log("lastPart",lastPart);

    const isValidSupplierCode = (code) => {
        const pattern = new RegExp(
            `^${globalPrefix}-[A-Z]{${globalLength - globalChronoLength - globalPrefix.length}}-\\d{${globalChronoLength}}$`
        );
        return pattern.test(code);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setError({ ...error, [name]: false });

        if (name === 'fournisseur_code' && !isValidSupplierCode(value)) {
            setError({ ...error, [name]: true });
        }

        setState({ ...state, [name]: value });
    };

    function generateCode() {
        const randomLength = globalLength - globalChronoLength - globalPrefix.length;
        const allowedChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const randomString = Array(randomLength)
            .fill(null)
            .map(() => allowedChars[Math.floor(Math.random() * allowedChars.length)])
            .join('');

        let chronoString;
        if (lastPart !== null) {
            chronoString = (lastPart + 1).toString().padStart(globalChronoLength, '0');
        } else {
            chronoString = '0001';
        }

        return `${globalPrefix}-${randomString}-${chronoString}`;
    }

    const handleGenerateCode = () => {
        const generatedCode = generateCode();
        setState({ ...state, fournisseur_code: generatedCode });
    };

    const supplierGroupReqData = {
        doctype: 'Supplier Group',
        fields: [
            '`tabSupplier Group`.`name`',
            '`tabSupplier Group`.`supplier_group_name`',
            '`tabSupplier Group`.`parent_supplier_group`',
            '`tabSupplier Group`.`is_group`'
        ],
        order_by: '`tabSupplier Group`.`modified` desc'
    };

    const { data: supplierGroup, isSupplierGroupPending } = useFetch(`/api/get-list`, supplierGroupReqData, 'POST');
    const { data: countries, isPending: isCountriesPending } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Country',
            fields: ['`tabCountry`.`name`']
        },
        'POST'
    );

    const pricelistReqData = {
        doctype: 'Price List',
        fields: ['`tabPrice List`.`name`']
    };
    const { data: priceLists, isPending: isPendingPriceLists } = useFetch(`/api/get-list`, pricelistReqData, 'POST');

    const { data: currencies, isPending: isCurrenciesPending } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Currency',
            fields: ['`tabCurrency`.`name`']
        },
        'POST'
    );

    const suppliersReqData = {
        doctype: 'Supplier',
        fields: ['`tabSupplier`.`name`', '`tabSupplier`.`supplier_name`', '`tabSupplier`.`fournisseur_code`'],
        start: 0,
        page_length: 500,
        order_by: '`tabSupplier`.`modified` desc'
    };

    const { data: suppliers, isPending: isPendingSuppliers } = useFetch(`/api/get-list`, suppliersReqData, 'POST');

    //console.log(suppliers);
    if (suppliers && Array.isArray(suppliers) && suppliers.length > 0) {
        const supplierCodes = suppliers
            .map((supplier) => {
                if (supplier.fournisseur_code && typeof supplier.fournisseur_code === 'string') {
                    const supplierCode = supplier.fournisseur_code;
                    return supplierCode.split('-').pop();
                } else {
                    console.warn(`Missing or invalid fournisseur_code in supplier:`, supplier);
                    return null;
                }
            })
            .filter(Boolean);

        console.log(supplierCodes);
    } else {
        console.warn('No suppliers found or suppliers is not an array');
    }

    const bankaccountReqData = {
        doctype: 'Bank Account',
        fields: ['`tabBank Account`.`name`']
    };
    const { data: bankAccounts, isPending: isPendingbankAccounts } = useFetch(`/api/get-list`, bankaccountReqData, 'POST');

    const taxwithhodlingReqData = {
        doctype: 'Tax Withholding Category',
        fields: ['`tabTax Withholding Category`.`name`']
    };
    const { data: taxwithhodlingcat, isPending: isPendingTaxWithHodlingCat } = useFetch(`/api/get-list`, taxwithhodlingReqData, 'POST');

    const { data: companies, isPending: isCompaniesPending } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Company',
            fields: ['`tabCompany`.`name`']
        },
        'POST'
    );

    const { data: paymentterms, isPending: isPaymentTermsPending } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Payment Terms Template',
            fields: ['`tabPayment Terms Template`.`name`']
        },
        'POST'
    );

    const { data: taxcategories, isPending: isTaxCategoriesPending } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Tax Category',
            fields: ['`tabTax Category`.`name`']
        },
        'POST'
    );
    const { data: settings, isPending: isSettingsPending } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Supplier Code Settings',
            fields: [
                '`tabSupplier Code Settings`.`name`',
                '`tabSupplier Code Settings`.`prefix`',
                '`tabSupplier Code Settings`.`length`',
                '`tabSupplier Code Settings`.`chrono_length`'
            ]
        },
        'POST'
    );
    const { data: doc_contact } = useGetAxios(
        `${process.env.REACT_APP_API_URI}/api/details?doctype=Contact&name=${doc?.supplier_primary_contact}`
    );

    const { data: doc_address } = useGetAxios(
        `${process.env.REACT_APP_API_URI}/api/details?doctype=Address&name=${doc?.supplier_primary_address}`
    );

    const supplierGroupName = supplierGroup?.map((obj) => obj.supplier_group_name);

    const data1 = [
        {
            name: 'supplier_type',
            label: t('columns:supplierType'),
            value: state.supplier_type,
            items: ['Company', 'Individual'],
            error: error.supplier_type
        }
    ];

    const data2 = [
        {
            name: 'supplier_group',
            label: t('columns:supplierGroup'),
            value: state.supplier_group,
            items: supplierGroupName,
            error: error.supplier_group
        }
    ];

    const handleAddSupplier = async () => {
        setLoadingButton(true);
        if (isEdit) {
            await savecontact();
            await saveAddress();
            var editSupplier = doc;
            editSupplier.supplier_name = state?.supplier_name;
            editSupplier.fournisseur_code = state?.fournisseur_code;
            editSupplier.pan = state?.pan;
            editSupplier.tax_id = state?.tax_id;
            editSupplier.allow_purchase_invoice_creation_without_purchase_order =
                state?.allow_purchase_invoice_creation_without_purchase_order;
            editSupplier.allow_purchase_invoice_creation_without_purchase_receipt =
                state?.allow_purchase_invoice_creation_without_purchase_receipt;
            editSupplier.warn_rfqs = state?.warn_rfqs;
            editSupplier.warn_pos = state?.warn_pos;
            editSupplier.prevent_rfqs = state?.prevent_rfqs;
            editSupplier.prevent_pos = state?.prevent_pos;
            editSupplier.irs_1099 = state?.irs_1099;
            editSupplier.disabled = state?.disabled;
            editSupplier.is_transporter = state?.is_transporter;
            editSupplier.is_internal_supplier = state?.is_internal_supplier;
            editSupplier.supplier_code_settings = state?.supplier_code_settings;
            editSupplier.address_line1 = state?.address_line1;
            editSupplier.supplier_type = state?.supplier_type;
            editSupplier.supplier_group = state?.supplier_group;
            editSupplier.country = state?.country;
            editSupplier.represents_company = state?.represents_company;
            editSupplier.on_hold = state?.on_hold;
            editSupplier.hold_type = state?.hold_type;
            editSupplier.release_date = state?.release_date;
            editSupplier.tax_category = state?.tax_category;
            editSupplier.city = state?.city;
            editSupplier.default_currency = state?.default_currency;
            editSupplier.default_price_list = state?.default_price_list;
            editSupplier.default_bank_account = state?.default_bank_account;
            editSupplier.tax_withholding_category = state?.tax_withholding_category;
            editSupplier.payment_terms = state?.payment_terms;
        }
        const finalState = {
            ...state,
            companies: companyData
        };
        fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doc: isEdit ? editSupplier : finalState, action: 'Save' })
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    if (data?.message?.toLowerCase().includes('fournisseur code must be unique')) {
                        dispatch(
                            SetNotification({
                                code: 'warning',
                                message: t('common:uniqueFournisseurCodeError')
                            })
                        );
                    } else {
                        dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                    }
                } else {
                    if (!isEdit) {
                        if (isDuplicating) {
                            dispatch(SetNotification({ message: t('core:duplicateSuppMsg'), code: 'success' }));
                        } else {
                            dispatch(SetNotification({ message: t('core:createSuppMsg'), code: 'success' }));
                        }
                        navigate('/oms/supplier');
                    } else {
                        dispatch(SetNotification({ message: t('core:updateSuppMsg'), code: 'success' }));
                        navigate('/oms/supplier');
                    }
                }
            });
    };

    useEffect(() => {
        if (docToDuplicate) {
            setState((prevState) => ({
                ...prevState,
                supplier_name: '',
                fournisseur_code: '',
                pan: docToDuplicate.pan,
                supplier_code_settings: docToDuplicate.supplier_code_settings,
                supplier_type: docToDuplicate.supplier_type,
                supplier_group: docToDuplicate.supplier_group,
                allow_purchase_invoice_creation_without_purchase_order:
                    docToDuplicate.allow_purchase_invoice_creation_without_purchase_order,
                allow_purchase_invoice_creation_without_purchase_receipt:
                    docToDuplicate.allow_purchase_invoice_creation_without_purchase_receipt,
                warn_rfqs: docToDuplicate.warn_rfqs,
                warn_pos: docToDuplicate.warn_pos,
                prevent_rfqs: docToDuplicate.prevent_rfqs,
                prevent_pos: docToDuplicate.prevent_pos,
                is_transporter: docToDuplicate.is_transporter,
                is_internal_supplier: docToDuplicate.is_internal_supplier,
                disabled: docToDuplicate.disabled,
                irs_1099: docToDuplicate.irs_1099,
                on_hold: docToDuplicate.on_hold,
                hold_type: docToDuplicate.hold_type,
                release_date: docToDuplicate.release_date,
                tax_id: docToDuplicate.tax_id,
                country: docToDuplicate.country,
                represents_company: docToDuplicate.represents_company,
                tax_category: docToDuplicate.tax_category,
                email_id: docToDuplicate.email_id,
                mobile_no: docToDuplicate.mobile_no,
                address_line1: docToDuplicate.__onload?.addr_list[0]?.address_line1,
                city: docToDuplicate.__onload?.addr_list[0]?.city,
                default_currency: docToDuplicate.default_currency,
                default_price_list: docToDuplicate.default_price_list,
                tax_withholding_category: docToDuplicate.tax_withholding_category,
                default_bank_account: docToDuplicate.default_bank_account,
                payment_terms: docToDuplicate.payment_terms
            }));
            setCompanyData(docToDuplicate.companies || []);
        }
    }, [docToDuplicate]);

    const savecontact = async () => {
        if ((state?.mobile_no !== doc?.mobile_no || state?.email_id !== doc?.email_id) && doc_contact) {
            const var_cantact = doc_contact;
            if (var_cantact?.email_ids?.length > 0) var_cantact.email_ids[0].email_id = state?.email_id;
            if (var_cantact?.phone_nos?.length > 0) {
                var_cantact.phone_nos[0].phone = state?.mobile_no;
                var_cantact.phone_nos[0].is_primary_phone = 1;
            }
            await fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
                method: 'POST',
                headers: header,
                body: JSON.stringify({ doc: var_cantact, action: 'Save' })
            })
                .then((res) => {
                    if (!res.ok) {
                        dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                    }
                    return res.json();
                })
                .then((data) => {
                    return;
                });
        } else {
            return;
        }
    };

    const saveAddress = async () => {
        if (
            (state?.address_line1 !== doc?.__onload?.addr_list[0]?.address_line1 || state?.city !== doc?.__onload?.addr_list[0]?.city) &&
            doc_address
        ) {
            const var_address = doc_address;
            var_address.address_line1 = state?.address_line1;
            var_address.city = state?.city;
            var_address.is_primary_address = 1;

            await fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
                method: 'POST',
                headers: header,
                body: JSON.stringify({ doc: var_address, action: 'Save' })
            })
                .then((res) => {
                    if (!res.ok) {
                        dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                    }
                    return res.json();
                })
                .then((data) => {
                    return;
                });
        } else {
            return;
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const requiredFields = ['supplier_name', 'supplier_type', 'supplier_group', 'email_id', 'mobile_no', 'country'];

        if (globalAutomatic === 0) {
            requiredFields.push('fournisseur_code');
        }

        let isValid = true;
        const nextState = { ...state };
        const nextError = { ...error };

        requiredFields.forEach((field) => {
            if (nextState[field].trim() === '') {
                nextError[field] = true;
                isValid = false;
            } else {
                nextError[field] = false;
            }

            if (field === 'email_id' && nextState[field] !== '' && !validator.isEmail(nextState[field])) {
                nextError[field] = true;
                isValid = false;
            }

            if (field === 'mobile_no') {
                if (nextState[field].length !== 8) {
                    nextError[field] = true;
                    isValid = false;
                    dispatch(SetNotification({ code: 'error', message: t('core:exactly-8-digits') }));
                } else if (!validator.isMobilePhone(nextState[field])) {
                    nextError[field] = true;
                    isValid = false;
                }
            }
        });

        setError(nextError);
        if (isValid) {
            handleAddSupplier();
        }
    };

    const handleChangeCheckbox = (e) => {
        setShowSupplierCodeSettings(e.target.checked);
        if (e.target.checked) {
            setState((prevState) => ({
                ...prevState,
                fournisseur_code: isEdit ? prevState.fournisseur_code : '',
                supplier_code_settings: isEdit ? prevState.supplier_code_settings : ''
            }));
        } else {
            setState((prevState) => ({
                ...prevState,
                fournisseur_code: isEdit ? prevState.fournisseur_code : '',
                supplier_code_settings: isEdit ? prevState.supplier_code_settings : ''
            }));
        }
    };

    const getRepeatedString = (char, count) => {
        return count > 0 ? char.repeat(count) : '';
    };

    const safeValue = (value, defaultValue) => {
        return value !== undefined && value !== null ? value : defaultValue;
    };

    const safePrefix = safeValue(globalPrefix, '');
    const safeLength = safeValue(globalLength, 0);
    const safeChronoLength = safeValue(globalChronoLength, 0);

    const randomPartLength = Math.max(0, safeLength - safeChronoLength - safePrefix.length - (safePrefix ? 1 : 0));
    const chronoPartLength = Math.max(0, safeChronoLength);

    const handleSupplierChangeCheckbox = (event, value) => {
        setState({ ...state, [event.target.name]: value ? 1 : 0 });
    };

    useEffect(() => {
        return () => {
            dispatch(SetDoc2Duplicate(null));
        };
    }, [dispatch]);

    if (
        isSupplierGroupPending ||
        isCountriesPending ||
        isSettingsPending ||
        isCompaniesPending ||
        isTaxCategoriesPending ||
        isCurrenciesPending ||
        isPendingPriceLists ||
        isPendingTaxWithHodlingCat ||
        isPendingbankAccounts ||
        isPaymentTermsPending
    )
        return <Loader />;

    const hold_type_options = ['Invoices', 'Payments', 'All'];

    return (
        <>
            <MainCard
                border
                isWhite
                boxShadow
                divider
                title={
                    <Box display={'flex'} alignItems={'center'}>
                        <CoPresentIcon sx={{ mr: 1 }} />
                        {isDuplicating ? t('core:duplicateSupplier') : t('core:addSupplier')}
                    </Box>
                }
                sx={{ height: '100%' }}
            >
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        padding: '0 20px',
                        boxSizing: 'border-box'
                    }}
                >
                    <div style={{ width: '45%' }}>
                        <FormControl fullWidth sx={{ mt: 1 }} required>
                            <InputLabel error={error.supplier_name}> {t('columns:supplierName')}</InputLabel>
                            <OutlinedInput
                                label={t('columns:supplierName')}
                                autoComplete="off"
                                className={classes.formControll}
                                error={error.supplier_name}
                                placeholder={t('columns:supplierName')}
                                name="supplier_name"
                                value={state.supplier_name}
                                onChange={handleChange}
                            />
                        </FormControl>
                        <FormControl fullWidth sx={{ mt: 1 }} required>
                            <InputLabel error={error.fournisseur_code}>{t('columns:supplierCode')}</InputLabel>
                            <OutlinedInput
                                label={t('columns:supplierCode')}
                                autoComplete="off"
                                className={classes.formControll}
                                placeholder={t('columns:supplierCode')}
                                name="fournisseur_code"
                                value={state.fournisseur_code}
                                onChange={handleChange}
                                disabled={globalAutomatic === 1}
                                endAdornment={
                                    globalAutomatic === 1 && (
                                        <InputAdornment position="end">
                                            <Button variant="contained" color="primary" onClick={handleGenerateCode} sx={{ ml: 1 }}>
                                                {t('common:automatic_generation')}
                                            </Button>
                                        </InputAdornment>
                                    )
                                }
                            />
                            {error.fournisseur_code && (
                                <FormHelperText error>
                                    {t('common:invalid_supplier_code_format', {
                                        format: `${safePrefix}-${getRepeatedString('X', randomPartLength + 1)}-${getRepeatedString(
                                            '9',
                                            chronoPartLength
                                        )}`,
                                        totalLength: safeLength + 2,
                                        prefix: safePrefix || t('common:no_prefix'),
                                        prefixLength: safePrefix ? safePrefix.length : 0
                                    })}
                                </FormHelperText>
                            )}
                        </FormControl>
                        {/* <FormControlLabel
                            control={
                                <Checkbox
                                    name="irs_1099"
                                    value={state?.irs_1099 === 1 ? true : false}
                                    onChange={handleSupplierChangeCheckbox}
                                />
                            }
                            label={t('core:Is_IRS_1099')}
                        />*/}
                        <FormControl fullWidth sx={{ mt: 1 }} required>
                            <InputLabel error={error.email_id}>{t('core:Email')}</InputLabel>
                            <OutlinedInput
                                label={t('core:Is_Email')}
                                value={state.email_id}
                                onChange={handleChange}
                                autoComplete="off"
                                className={classes.formControll}
                                placeholder={t('core:Email')}
                                name="email_id"
                                error={error.email_id}
                            />
                        </FormControl>

                        <FormControl fullWidth sx={{ mt: 1 }} required>
                            <InputLabel error={error?.city}>{t('columns:city')}</InputLabel>
                            <OutlinedInput
                                label={t('columns:city')}
                                value={state.city}
                                onChange={handleChange}
                                autoComplete="off"
                                className={classes.formControll}
                                placeholder={t('columns:city')}
                                name="city"
                                error={error?.city}
                            />
                        </FormControl>
                        <FormControl fullWidth sx={{ mt: 1 }} required>
                            <InputLabel error={error?.address_line1}> {t('crm:addr')}</InputLabel>
                            <OutlinedInput
                                label={t('crm:addr')}
                                value={state.address_line1}
                                onChange={handleChange}
                                autoComplete="off"
                                className={classes.formControll}
                                placeholder={t('crm:addr')}
                                name="address_line1"
                                error={error?.address_line1}
                            />
                        </FormControl>
                        <FormControl fullWidth sx={{ mt: 1 }} required>
                            <InputLabel error={error.mobile_no}>{t('crm:phonenum')}</InputLabel>
                            <OutlinedInput
                                label={t('crm:phonenum')}
                                value={state.mobile_no}
                                onChange={(e) => {
                                    const value = e.target.value.replace(/\D/g, '').slice(0, 8);
                                    handleChange({ target: { name: 'mobile_no', value } });
                                }}
                                autoComplete="off"
                                className={classes.formControll}
                                placeholder={t('crm:phonenum')}
                                name="mobile_no"
                                error={error.mobile_no}
                                inputProps={{
                                    maxLength: 8,
                                    pattern: '[0-9]*'
                                }}
                            />
                            <FormHelperText error={error.mobile_no}>
                                {error.mobile_no ? t('core:exactly-8-digits') : t('core:8-digit-number')}
                            </FormHelperText>
                        </FormControl>
                        <CountriesSelect
                            value={state?.country}
                            onChange={handleChange}
                            error={error?.country}
                            countries={countries}
                            required
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="is_transporter"
                                    value={state?.is_transporter === 1 ? true : false}
                                    onChange={handleSupplierChangeCheckbox}
                                />
                            }
                            label={t('core:Is_Transporter')}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="is_internal_supplier"
                                    checked={state.is_internal_supplier}
                                    onChange={handleSupplierChangeCheckbox}
                                />
                            }
                            label={t('core:Is_Internal_Supplier')}
                        />
                        {state.is_internal_supplier == true && (
                            <FormControl fullWidth sx={{ mt: 1 }}>
                                <InputLabel mt={0.5} id="demo-simple-select-label">
                                    {t('core:Represents_Company')}
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label={t('core:represents_company')}
                                    name="represents_company"
                                    value={state?.represents_company}
                                    onChange={handleChange}
                                >
                                    {Array.isArray(companies) &&
                                        companies.map((item, index) => (
                                            <MenuItem style={{ fontSize: 12 }} value={item.name} key={index}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        )}
                        {state.represents_company && state.is_internal_supplier == true && (
                            <>
                                <Box mt={2} mb={2}>
                                    <Divider>
                                        <Chip label={t('core:Allowed_To_Transact_With')} icon={<ApartmentIcon color="blue" />} />
                                    </Divider>
                                </Box>
                                {companyData.map((company, index) => (
                                    <Box key={index} mt={2}>
                                        <FormControl fullWidth>
                                            <InputLabel>{t('core:Company')}</InputLabel>
                                            <Select value={company.company} onChange={(e) => handleCompanyChange(index, e.target.value)}>
                                                {companies.map((comp) => (
                                                    <MenuItem key={comp.name} value={comp.name}>
                                                        {comp.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <IconButton onClick={() => handleRemoveCompany(index)}>
                                            <DeleteOutlineIcon />
                                        </IconButton>
                                    </Box>
                                ))}
                                <Box mt={2} mb={2}>
                                    <Button variant="contained" onClick={handleAddCompany}>
                                        {t('core:add_company')}
                                    </Button>
                                </Box>
                                <hr></hr>
                            </>
                        )}
                        <FormControl fullWidth sx={{ mt: 1 }}>
                            <InputLabel mt={0.5} id="demo-simple-select-label">
                                {t('core:billing_currency')}
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label={t('core:billing_currency')}
                                name="default_currency"
                                value={state?.default_currency}
                                onChange={handleChange}
                            >
                                {Array.isArray(currencies) &&
                                    currencies.map((item, index) => (
                                        <MenuItem style={{ fontSize: 12 }} value={item.name} key={index}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                        {/* <FormControl fullWidth sx={{ mt: 1 }}>
                            <InputLabel mt={0.5} id="demo-simple-select-label">
                                {t('core:Default_Company_Bank_acc')}
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label={t('core:Default_Company_Bank_acc')}
                                name="default_bank_account"
                                value={state?.default_bank_account}
                                onChange={handleChange}
                            >
                                {Array.isArray(bankAccounts) &&
                                    bankAccounts.map((item, index) => (
                                        <MenuItem style={{ fontSize: 12 }} value={item.name} key={index}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth sx={{ mt: 1 }}>
                            <InputLabel mt={0.5} id="demo-simple-select-label">
                                {t('core:Default_Payment_Terms_Template')}
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label={t('core:Default_Payment_Terms_Template')}
                                name="payment_terms"
                                value={state?.payment_terms}
                                onChange={handleChange}
                            >
                                {Array.isArray(paymentterms) &&
                                    paymentterms.map((item, index) => (
                                        <MenuItem style={{ fontSize: 12 }} value={item.name} key={index}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>*/}
                        {/*<FormControlLabel
                            control={
                                <Checkbox
                                    name="on_hold"
                                    value={state?.on_hold === 1 ? true : false}
                                    onChange={handleSupplierChangeCheckbox}
                                />
                            }
                            label={t('core:on_hold')}
                        />*/}
                        {state.on_hold == true && (
                            <>
                                <Box mt={2} mb={2}>
                                    <Divider>
                                        <Chip label={t('core:date_and_hold_type')} icon={<InsertInvitationIcon color="blue" />} />
                                    </Divider>
                                </Box>
                                <FormDateTime
                                    fullwidth
                                    type="date"
                                    name="release_date"
                                    value={state?.release_date}
                                    handleChange={handleChange}
                                    sx={{ mb: 1 }}
                                />
                                <FormControl fullWidth sx={{ mt: 1 }}>
                                    <InputLabel mt={0.5} id="demo-simple-select-label">
                                        {t('core:hold_type')}
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label={t('core:hold_type')}
                                        name="hold_type"
                                        value={state?.hold_type}
                                        onChange={handleChange}
                                    >
                                        {Array.isArray(hold_type_options) &&
                                            hold_type_options.map((item, index) => (
                                                <MenuItem style={{ fontSize: 12 }} value={item} key={index}>
                                                    {item}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </>
                        )}
                    </div>
                    <div style={{ width: '45%' }}>
                        {data1.map((row, index) => (
                            <FormControl fullWidth sx={{ mt: 1 }} required key={index}>
                                <InputLabel id="demo-simple-select-label" error={row.error}>
                                    {row.label}
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label={row.label}
                                    name={row.name}
                                    value={row.value}
                                    onChange={handleChange}
                                    error={row.error}
                                >
                                    {Array.isArray(row.items) &&
                                        row.items.map((item, index) => (
                                            <MenuItem style={{ fontSize: 12 }} value={item} key={index}>
                                                {t(`core:${item.toLowerCase()}`)}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        ))}
                        {data2.map((row, index) => (
                            <FormControl fullWidth sx={{ mt: 1 }} required>
                                <InputLabel id="demo-simple-select-label" error={row.error}>
                                    {row.label}
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label={row.label}
                                    name={row.name}
                                    value={row.value}
                                    onChange={handleChange}
                                    error={row.error}
                                >
                                    {Array.isArray(row.items) &&
                                        row.items.map((item, index) => (
                                            <MenuItem style={{ fontSize: 12 }} value={item} key={index}>
                                                {item}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        ))}
                        {/*<FormControl fullWidth sx={{ mt: 1 }}>
                            <InputLabel> {t('core:PAN')}</InputLabel>
                            <OutlinedInput
                                label={t('core:PAN')}
                                autoComplete="off"
                                className={classes.formControll}
                                placeholder={t('core:PAN')}
                                name="pan"
                                value={state.pan}
                                onChange={handleChange}
                            />
                        </FormControl>*/}
                        <FormControl fullWidth sx={{ mt: 1 }}>
                            <InputLabel> {t('core:Tax_ID')}</InputLabel>
                            <OutlinedInput
                                label={t('core:Tax_ID')}
                                autoComplete="off"
                                className={classes.formControll}
                                placeholder={t('core:Tax_ID')}
                                name="tax_id"
                                value={state.tax_id}
                                onChange={handleChange}
                            />
                        </FormControl>
                        <FormControl fullWidth sx={{ mt: 1, mb: 2 }}>
                            <InputLabel mt={0.5} id="demo-simple-select-label">
                                {t('core:Tax_Category')}
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label={t('core:Tax_Category')}
                                name="tax_category"
                                value={state?.tax_category}
                                onChange={handleChange}
                            >
                                {Array.isArray(taxcategories) &&
                                    taxcategories.map((item, index) => (
                                        <MenuItem style={{ fontSize: 12 }} value={item.name} key={index}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                        <div style={{ width: '45%' }}></div>

                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <div style={{ flex: '0 0 48%' }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="allow_purchase_invoice_creation_without_purchase_order"
                                            value={state?.allow_purchase_invoice_creation_without_purchase_order === 1 ? true : false}
                                            onChange={handleSupplierChangeCheckbox}
                                        />
                                    }
                                    label={t('core:Allow_Purchase_Order')}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="allow_purchase_invoice_creation_without_purchase_receipt"
                                            value={state?.allow_purchase_invoice_creation_without_purchase_receipt === 1 ? true : false}
                                            onChange={handleSupplierChangeCheckbox}
                                        />
                                    }
                                    label={t('core:Allow_Purchase_Receipt')}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="disabled"
                                            value={state?.disabled === 1 ? true : false}
                                            onChange={handleSupplierChangeCheckbox}
                                        />
                                    }
                                    label={t('core:disable')}
                                />
                            </div>
                            <div style={{ flex: '0 0 48%' }}></div>
                        </div>
                        <FormControl fullWidth sx={{ mt: 1 }}>
                            <InputLabel mt={0.5} id="demo-simple-select-label">
                                {t('core:price_list')}
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label={t('core:price_list')}
                                name="default_price_list"
                                value={state?.default_price_list}
                                onChange={handleChange}
                            >
                                {Array.isArray(priceLists) &&
                                    priceLists.map((item, index) => (
                                        <MenuItem style={{ fontSize: 12 }} value={item.name} key={index}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                        {/*<FormControl fullWidth sx={{ mt: 1 }}>
                            <InputLabel mt={0.5} id="demo-simple-select-label">
                                {t('core:Tax_Withholding_Category')}
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label={t('core:Tax_Withholding_Category')}
                                name="tax_withholding_category"
                                value={state?.tax_withholding_category}
                                onChange={handleChange}
                            >
                                {Array.isArray(taxwithhodlingcat) &&
                                    taxwithhodlingcat.map((item, index) => (
                                        <MenuItem style={{ fontSize: 12 }} value={item.name} key={index}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="warn_rfqs"
                                    value={state?.warn_rfqs === 1 ? true : false}
                                    onChange={handleSupplierChangeCheckbox}
                                    disabled
                                />
                            }
                            label={t('core:Warn_RFQs')}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="warn_pos"
                                    value={state?.warn_pos === 1 ? true : false}
                                    onChange={handleSupplierChangeCheckbox}
                                    disabled
                                />
                            }
                            label={t('core:Warn_POs')}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="prevent_rfqs"
                                    value={state?.prevent_rfqs === 1 ? true : false}
                                    onChange={handleSupplierChangeCheckbox}
                                    disabled
                                />
                            }
                            label={t('core:Prevent_RFQs')}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="prevent_pos"
                                    value={state?.prevent_pos === 1 ? true : false}
                                    onChange={handleSupplierChangeCheckbox}
                                    disabled
                                />
                            }
                            label={t('core:Prevent_POs')}
                        />*/}
                    </div>
                </div>
                <Box display={'flex'} justifyContent={'flex-end'} mt={2}>
                    <Box>
                        <Grid container spacing={1}>
                            <Grid item>
                                <LoadingButton
                                    color="blue"
                                    size="large"
                                    variant="contained"
                                    loading={loadingButton}
                                    onClick={handleSubmit}
                                    disabled={
                                        state.supplier_name.trim() === '' ||
                                        state.fournisseur_code.trim() === '' ||
                                        state.email_id.trim() === '' ||
                                        state.city.trim() === '' ||
                                        state.address_line1.trim() === '' ||
                                        state.mobile_no.trim() === '' ||
                                        state.country.trim() === '' ||
                                        state.supplier_type.trim() === '' ||
                                        state.supplier_group.trim() === ''
                                    }
                                >
                                    {t('common:save')}
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </MainCard>
            {/*<Box>
            <MainCard title={title}>
                <Grid container spacing={2} display={'flex'} justifyContent={'left'} flexDirection={'column'}>
                <FormControl fullWidth required>
                            <InputLabel error={error.supplier_name}> {t('columns:supplierName')}</InputLabel>
                            <OutlinedInput
                                label={t('columns:supplierName')}
                                autoComplete="off"
                                className={classes.formControll}
                                error={error.supplier_name}
                                placeholder={t('columns:supplierName')}
                                name="supplier_name"
                                value={state.supplier_name}
                                onChange={handleChange}
                            />
                        </FormControl>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={showSupplierCodeSettings}
                                    onChange={handleChangeCheckbox}
                                    name="checkedB"
                                    color="primary"
                                />
                            }
                            label={t('core:automaticcodegeneration')}
                        />
                        {showSupplierCodeSettings && (
                            <FormControl fullWidth sx={{ mt: 1 }}>
                                <InputLabel error={error?.supplier_code_settings} mt={0.5} id="demo-simple-select-label">
                                    {t('core:suppliercodeSetting')}
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label={t('core:suppliercodeSetting')}
                                    name="supplier_code_settings"
                                    value={state?.supplier_code_settings}
                                    onChange={handleChange}
                                    error={error?.supplier_code_settings}
                                >
                                    {Array.isArray(settings) &&
                                        settings.map((item, index) => (
                                            <MenuItem style={{ fontSize: 12 }} value={item.name} key={index}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        )}
                        <FormControl fullWidth sx={{ mt: 1 }} required>
                            <InputLabel error={error.supplier_code}>{t('columns:supplierCode')}</InputLabel>
                            <OutlinedInput
                                label={t('columns:supplierCode')}
                                value={state.supplier_code}
                                onChange={handleChange}
                                autoComplete="off"
                                className={classes.formControll}
                                placeholder="Supplier code"
                                name="supplier_code"
                                error={error.supplier_code}
                                readOnly={showSupplierCodeSettings}
                            />
                        </FormControl>
                        <Box mt={2} >
                        <FormControl fullWidth>
                            <InputLabel> {t('columns:pan')}</InputLabel>
                            <OutlinedInput
                                label={t('columns:pan')}
                                autoComplete="off"
                                className={classes.formControll}
                                placeholder={t('columns:pan')}
                                name="pan"
                                value={state.pan}
                                onChange={handleChange}
                            />
                        </FormControl>
                        </Box>
                        <Box mt={2} >
                        <InputCheckboxField
                            fullwidth
                            label={t('settings:ALLOW_PURCHASE')}
                            name="allow_purchase_invoice_creation_without_purchase_order"
                            value={state?.allow_purchase_invoice_creation_without_purchase_order === 1 ? true : false}
                            handleChange={handleSupplierChangeCheckbox }
                            sx={{ mb: 2 }}
                        />
                        </Box>
                        <Box mt={2} >
                        <InputCheckboxField
                            fullwidth
                            label={t('settings:ALLOW_PURCHASEs')}
                            name="allow_purchase_invoice_creation_without_purchase_receipt"
                            value={state?.allow_purchase_invoice_creation_without_purchase_receipt === 1 ? true : false}
                            handleChange={handleSupplierChangeCheckbox }
                            sx={{ mb: 2 }}
                        />
                        </Box>
                        <Box mt={2} >
                        <InputCheckboxField
                            fullwidth
                            label={t('settings:disabled')}
                            name="disabled"
                            value={state?.disabled === 1 ? true : false}
                            handleChange={handleSupplierChangeCheckbox }
                            sx={{ mb: 2 }}
                        />
                        </Box>
                        
                        <Box mt={2} >
                        <FormControl fullWidth>
                            <InputLabel> {t('columns:tax_id')}</InputLabel>
                            <OutlinedInput
                                label={t('columns:tax_id')}
                                autoComplete="off"
                                className={classes.formControll}
                                placeholder={t('columns:tax_id')}
                                name="tax_id"
                                value={state.tax_id}
                                onChange={handleChange}
                            />
                        </FormControl>
                        </Box>
                        <Box mt={2} >
                        <InputCheckboxField
                            fullwidth
                            label={t('settings:irs_1099')}
                            name="irs_1099"
                            value={state?.irs_1099 === 1 ? true : false}
                            handleChange={handleSupplierChangeCheckbox }
                            sx={{ mb: 2 }}
                        />
                        </Box>
                        <FormControl fullWidth sx={{ mt: 1 }}>
                                        <InputLabel mt={0.5} id="demo-simple-select-label">
                                            {t('core:tax_category')}
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label={t('core:tax_category')}
                                            name="tax_category"
                                            value={state?.tax_category}
                                            onChange={handleChange}
                                        >
                                            {Array.isArray(taxcategories) &&
                                                taxcategories.map((item, index) => (
                                                    <MenuItem style={{ fontSize: 12 }} value={item.name} key={index}>
                                                        {item.name}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                        <Box mt={2} >
                        <InputCheckboxField
                            fullwidth
                            label={t('settings:warn_rfqs')}
                            name="warn_rfqs"
                            value={state?.warn_rfqs === 1 ? true : false}
                            handleChange={handleSupplierChangeCheckbox }
                            sx={{ mb: 2 }}
                            disabled
                        />
                        </Box>
                        <Box mt={2} >
                        <InputCheckboxField
                            fullwidth
                            label={t('settings:warn_pos')}
                            name="warn_pos"
                            value={state?.warn_pos === 1 ? true : false}
                            handleChange={handleSupplierChangeCheckbox }
                            sx={{ mb: 2 }}
                            disabled
                        />
                        </Box>
                        <Box mt={2} >
                        <InputCheckboxField
                            fullwidth
                            label={t('settings:prevent_rfqs')}
                            name="prevent_rfqs"
                            value={state?.prevent_rfqs === 1 ? true : false}
                            handleChange={handleSupplierChangeCheckbox }
                            sx={{ mb: 2 }}
                            disabled
                        />
                        </Box>
                        <Box mt={2} >
                        <InputCheckboxField
                            fullwidth
                            label={t('settings:prevent_pos')}
                            name="prevent_pos"
                            value={state?.prevent_pos === 1 ? true : false}
                            handleChange={handleSupplierChangeCheckbox }
                            sx={{ mb: 2 }}
                            disabled
                        />
                        </Box>
                        <Box justifyContent="center">
                            {data.map((row, index) => (
                                <Box mt={2} key={index}>
                                    <FormControl fullWidth required>
                                        <InputLabel id="demo-simple-select-label" error={row.error}>
                                            {row.label}
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label={row.label}
                                            name={row.name}
                                            value={row.value}
                                            onChange={handleChange}
                                            error={row.error}
                                        >
                                            {Array.isArray(row.items) &&
                                                row.items.map((item, index) => (
                                                    <MenuItem style={{ fontSize: 12 }} value={item} key={index}>
                                                        {item}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            ))}
                        </Box>
                        <Box mt={2}>
      <Typography variant="h6">{t('core:companies')}</Typography>
      {companyData.map((company, index) => (
        <Box key={index} mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
            <SelectForm
    fullwidth
    label={t('core:companies')}
    name="companies"
    value={state.companies}
    data={companies.map((company) => company.name)}
    handleChange={handleChange}
  />
            </Grid>
            <Grid item xs={6}>
              <IconButton onClick={() => handleRemoveCompany(index)}>
                <DeleteOutlineIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      ))}
      <Box mt={2}>
        <Button variant="contained" onClick={handleAddCompany}>
          {t('core:addCompany')}
        </Button>
      </Box>
    </Box>
                        <Box mt={1}>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <FormControl fullWidth sx={{ mt: 1 }} required>
                                        <InputLabel error={error.email_id}>Email</InputLabel>
                                        <OutlinedInput
                                            label={'Email'}
                                            value={state.email_id}
                                            onChange={handleChange}
                                            autoComplete="off"
                                            className={classes.formControll}
                                            placeholder="Email"
                                            name="email_id"
                                            error={error.email_id}
                                        />
                                    </FormControl>

                                    <FormControl fullWidth sx={{ mt: 1 }} required>
                                        <InputLabel error={error?.city}>{t('columns:city')}</InputLabel>
                                        <OutlinedInput
                                            label={t('columns:city')}
                                            value={state.city}
                                            onChange={handleChange}
                                            autoComplete="off"
                                            className={classes.formControll}
                                            placeholder={t('columns:city')}
                                            name="city"
                                            error={error?.city}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth sx={{ mt: 1 }} required>
                                        <InputLabel error={error?.address_line1}> {t('crm:addr')}</InputLabel>
                                        <OutlinedInput
                                            label={t('crm:addr')}
                                            value={state.address_line1}
                                            onChange={handleChange}
                                            autoComplete="off"
                                            className={classes.formControll}
                                            placeholder={t('crm:addr')}
                                            name="address_line1"
                                            error={error?.address_line1}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl fullWidth sx={{ mt: 1 }} required>
                                        <InputLabel error={error.mobile_no}> {t('crm:phonenum')}</InputLabel>
                                        <OutlinedInput
                                            label={t('crm:phonenum')}
                                            value={state.mobile_no}
                                            onChange={handleChange}
                                            autoComplete="off"
                                            className={classes.formControll}
                                            placeholder={t('crm:phonenum')}
                                            name="mobile_no"
                                            error={error.mobile_no}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth sx={{ mt: 1 }} required>
                                        <InputLabel error={error?.country} mt={0.5} id="demo-simple-select-label">
                                            {t('core:country')}
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label={t('core:country')}
                                            name="country"
                                            value={state?.country}
                                            onChange={handleChange}
                                            error={error?.country}
                                        >
                                            {Array.isArray(countries) &&
                                                countries.map((item, index) => (
                                                    <MenuItem style={{ fontSize: 12 }} value={item.name} key={index}>
                                                        {item.name}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                  
                        <InputCheckboxField
                            fullwidth
                            label={t('settings:is_transporter')}
                            name="is_transporter"
                            value={state?.is_transporter === 1 ? true : false}
                            handleChange={handleSupplierChangeCheckbox }
                            sx={{ mb: 2 }}
                        />
                       
                        <InputCheckboxField
                            fullwidth
                            label={t('settings:is_internal_supplier')}
                            name="is_internal_supplier"
                            value={state?.is_internal_supplier === 1 ? true : false}
                            handleChange={handleSupplierChangeCheckbox }
                            sx={{ mb: 2 }}
                        />
                         <InputCheckboxField
                            fullwidth
                            label={t('settings:on_hold')}
                            name="on_hold"
                            value={state?.on_hold === 1 ? true : false}
                            handleChange={handleSupplierChangeCheckbox }
                            sx={{ mb: 2 }}
                        />
                        <SelectForm
                            fullwidth
                            label={t('settings:hold_type')}
                            name="hold_type"
                            value={state?.hold_type}
                            sx={{ mb: 2 }}
                            data={hold_type_options}
                            handleChange={handleChange}
                        />
                         <FormDateTime
                            fullwidth
                            type="date"
                            label={t('settings:release_date')}
                            name="release_date"
                            value={state?.release_date}
                            handleChange={handleChange}
                            sx={{ mb: 2 }}
                        />
                        <FormControl fullWidth sx={{ mt: 1 }}>
                                        <InputLabel mt={0.5} id="demo-simple-select-label">
                                            {t('core: represents_company')}
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label={t('core:represents_company')}
                                            name="represents_company"
                                            value={state?.represents_company}
                                            onChange={handleChange}
                                        >
                                            {Array.isArray(companies) &&
                                                companies.map((item, index) => (
                                                    <MenuItem style={{ fontSize: 12 }} value={item.name} key={index}>
                                                        {item.name}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                        
                                    {isEdit && (
                                        <Box display={'flex'} alignItems={'left'} sx={{ mt: 2 }}>
                                            <Tooltip title={t('core:addContact')} arrow>
                                                <IconButton
                                                    className={classes.addAddresIcon}
                                                    onClick={() => navigate(`/oms/add-contact/${doc?.name}`)}
                                                >
                                                    <AddIcon color="blue" />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    )}
                                </Grid>
                            </Grid>
                        </Box>

                </Grid>
                

                <Grid container item xs={12} sm={12} md={8} lg={8} xl={8} spacing={4} display={'flex'} justifyContent={'center'}>
                    <Grid item mt={3}>
                    <LoadingButton color="blue" size="large" variant="contained" loading={loadingButton} onClick={handleSubmit}>
                                {t('common:save')}
                            </LoadingButton>
                    </Grid>
                </Grid>
            </MainCard>
        </Box>*/}
        </>
    );
};
export default SuppliersForm;
