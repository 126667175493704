import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { CurrencyFormatter, formatErpResponse } from 'utils/utils';
import Logo from 'ui-component/Logo';
import { formatDate } from 'helpers/utils';
import { useTranslation } from 'react-i18next';
import { Chip } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(1)
    },
    section: {
        padding: `${theme.spacing(1)} 0px`
    },
    content: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: theme.spacing(2)
    },
    table: {
        borderCollapse: 'collapse',
        width: '100%',
        border: '1px solid #000',
        borderSpacing: 0,
        color: 'black'
    },
    th: {
        padding: '1px 4px',
        textAlign: 'left',
        lineHeight: '14px',
        background: '#f2f2f2',
        border: '1px solid #000',
        fontSize: 9
    },
    td: {
        padding: '1px 4px',
        border: '1px solid #000',
        lineHeight: '14px',
        fontSize: 9
    },
    tdTotal: {
        padding: '1px 4px',
        border: '1px solid #000',
        fontSize: 9,
        lineHeight: '11px'
    },
    // footer: {
    //     position: 'fixed',
    //     bottom: 0,
    //     width: '100%'
    // },
    avoidPageBreak: {
        position: 'relative',
        padding: theme.spacing(2),
        pageBreakBefore: 'always',
        pageBreakAfter: 'avoid',
        pageBreakInside: 'avoid',
        color: 'black',
        textAlign: 'justify'
    }
}));

const Footer = () => (
    <div className="footer">
        <p style={{ lineHeight: 1.5, textAlign: 'right', fontSize: 11, fontWeight: 500 }}>
            I agree to French Riviera Furniture LLC Terms and Conditions: . . . . . . . . . . . . . . . . . . . . . . . . .
        </p>
        <div
            style={{
                display: 'flex',
                alignItems: 'center'
            }}
        >
            <Logo />
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '70%'
                }}
            >
                <p style={{ lineHeight: 1.25, textAlign: 'center', fontSize: 9, marginLeft: '10px' }}>
                    GAUTIER DUBAI – Sheikh Zayed Road – Infinity Building – Tel: +971 4 3881 336 – Fax: +971 4 3881 337
                    <br />
                    City Centre Mirdif – Level 1 – Tel: +971 4 6652 449 <br />
                    P.O Box 124133 – Email: <a>Dubai@gautier.ae</a> – Website: <a>www.gautier.ae</a>
                    <br />
                    Independent store exploited by French Riviera Furniture LLC – TRN 100355053800003
                </p>
            </div>
        </div>
    </div>
);

const PrintDocumentsTemplate = React.forwardRef(({ doc, docinfo, isInventory, isPOS }, ref) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const defaultCompany = useSelector((state) => state.login.detailsDefaultCompany);
    const globalDefaults = useSelector((state) => state.login.globalDefaults);

    useEffect(() => {
        if (doc?.other_charges_calculation) {
            var htmlString = doc?.other_charges_calculation;
            document.getElementById('other_charges_calculation').innerHTML = htmlString;
        }
    }, [doc]);

    function getStatus() {
        if (doc) {
            if (doc?.status) {
                return doc?.status;
            } else {
                if (doc?.docstatus === 0) {
                    return 'Draft';
                } else if (doc?.docstatus === 1) {
                    return 'Submitted';
                } else {
                    return 'Cancelled';
                }
            }
        }
        return 'Unknown';
    }

    return (
        <div ref={ref}>
            <div className="content">
                <div className={classes.header}>
                    <Logo />
                    <h3 style={{ display: 'flex', alignItems: 'center' }}>
                        {doc?.doctype}: {doc?.name}{' '}
                        <Chip style={{ margin: 0, fontWeight: 'bold', fontSize: 14 }} color="primary" label={getStatus()} />
                    </h3>
                </div>

                <div className={classes.content}>
                    <div className={classes.section}>
                        {defaultCompany?.name === 'Gautier' ? (
                            <>
                                <p style={{ margin: 0, lineHeight: 1.2, fontWeight: 'bold', fontSize: 10 }}>FRENCH RIVIERA FURNITURE LLC</p>
                                <p style={{ margin: 0, lineHeight: 1.2, fontWeight: 'bold', fontSize: 10 }}>HABIB BANK AG ZURICH</p>
                                <p style={{ margin: 0, lineHeight: 1.2, fontWeight: 'bold', fontSize: 10 }}>DEIRA, DUBAI</p>
                                {doc?.doctype === 'Sales Invoice' && doc?.status === 'Partly Paid' ? null : (
                                    <>
                                        <p style={{ margin: 0, lineHeight: 1.2, fontWeight: 'bold', fontSize: 10 }}>SWIFT: HBZUAEADXXX</p>
                                        <p style={{ margin: 0, lineHeight: 1.2, fontWeight: 'bold', fontSize: 10 }}>
                                            AE67 0290 1902 1050 0999 294
                                        </p>
                                    </>
                                )}
                            </>
                        ) : null}
                    </div>
                    <div className={classes.section}>
                        <p style={{ margin: 0, lineHeight: 1.2, fontWeight: 'bold', fontSize: 10 }}>
                            {doc?.customer_name ? doc?.customer_name : doc?.supplier_name ? doc?.supplier_name : null}
                        </p>
                        {doc?.primary_address ? (
                            <p style={{ margin: 0, lineHeight: 1.2, fontWeight: 'bold', fontSize: 10 }}>
                                {t('documents:addr')}: {formatErpResponse(doc?.primary_address)}
                            </p>
                        ) : null}
                        {doc?.contact_mobile ? (
                            <p style={{ margin: 0, lineHeight: 1.2, fontWeight: 'bold', fontSize: 10 }}>
                                {t('documents:phone')}: {doc?.contact_mobile}
                            </p>
                        ) : null}
                        {doc?.contact_email ? (
                            <p style={{ margin: 0, lineHeight: 1.2, fontWeight: 'bold', fontSize: 10 }}>
                                {t('documents:mail')}: {doc?.contact_email}
                            </p>
                        ) : null}
                        {doc?.loyalty_program ? (
                            <p style={{ margin: 0, lineHeight: 1.2, fontWeight: 'bold', fontSize: 10 }}>
                                {t('documents:lp')}: {doc?.loyalty_program}
                            </p>
                        ) : null}
                    </div>
                </div>

                <div className={classes.content}>
                    <div className={classes.section}>
                        <p style={{ margin: 0, lineHeight: 1.5, fontWeight: 'bold', fontSize: 10 }}>{t('documents:ref')}</p>
                        <p style={{ margin: 0, lineHeight: 1.5, fontSize: 10 }}>
                            {t('documents:createdby')}: {doc?.owner === 'Administrator' ? 'System' : doc?.owner}
                        </p>
                        <p style={{ margin: 0, lineHeight: 1.5, fontSize: 10 }}>
                            {t('documents:doc')} #: {doc?.name}
                        </p>
                        <p style={{ margin: 0, lineHeight: 1.5, fontSize: 10 }}>
                            {t('documents:date')}: {formatDate(doc?.creation, 'DD-MM-YYYY HH:mm')}
                        </p>
                        {doc?.schedule_date ? (
                            <p style={{ margin: 0, lineHeight: 1.5, fontSize: 10 }}>
                                {t('documents:reqby')}: {formatDate(doc?.schedule_date, 'DD-MM-YYYY')}
                            </p>
                        ) : null}
                        {doc?.doctype === 'Material Request' ? (
                            <p style={{ margin: 0, lineHeight: 1.5, fontSize: 10 }}>
                                {t('documents:purp')}: {doc?.material_request_type}
                            </p>
                        ) : doc?.doctype === 'Stock Entry' ? (
                            <p style={{ margin: 0, lineHeight: 1.5, fontSize: 10 }}>
                                {t('documents:setype')}: {doc?.stock_entry_type}
                            </p>
                        ) : null}
                        {doc?.doctype === 'Stock Entry' ? (
                            <p style={{ margin: 0, lineHeight: 1.5, fontSize: 10 }}>
                                {t('documents:purp')}: {doc?.purpose}
                            </p>
                        ) : null}
                    </div>

                    {docinfo?.comments?.length != 0 && (
                        <div className={classes.section}>
                            <p style={{ margin: 0, lineHeight: 1.5, fontWeight: 'bold', fontSize: 10 }}>Notes/Comments</p>
                            {doc?.motif ? (
                                <p style={{ margin: 0, lineHeight: 1.5, fontSize: 10 }} key={doc?.motif}>
                                    {doc?.motif}
                                </p>
                            ) : null}
                            {docinfo?.comments &&
                                docinfo?.comments?.map((comment, index) => (
                                    <p style={{ margin: 0, lineHeight: 1.5, fontSize: 10 }} key={index}>
                                        {formatErpResponse(comment?.content)}
                                    </p>
                                ))}
                        </div>
                    )}

                    {isPOS ? (
                        <div className={classes.section}>
                            <p style={{ margin: 0, lineHeight: 1.5, fontSize: 10 }}>
                                {t('documents:psd')}: {formatDate(doc?.period_start_date, 'DD-MM-YYYY HH:mm')}
                            </p>
                            {doc?.period_end_date ? (
                                <p style={{ margin: 0, lineHeight: 1.5, fontSize: 10 }}>
                                    {t('documents:ped')}: {formatDate(doc?.period_end_date, 'DD-MM-YYYY HH:mm')}
                                </p>
                            ) : null}
                            <p style={{ margin: 0, lineHeight: 1.5, fontSize: 10 }}>
                                {t('documents:pdate')}: {formatDate(doc?.posting_date, 'DD-MM-YYYY')}
                            </p>
                            {doc?.posting_time ? (
                                <p style={{ margin: 0, lineHeight: 1.5, fontSize: 10 }}>
                                    {t('documents:ptime')}: {doc?.posting_time}
                                </p>
                            ) : null}
                            {doc?.pos_opening_entry ? (
                                <p style={{ margin: 0, lineHeight: 1.5, fontSize: 10 }}>
                                    {t('documents:poe')}: {doc?.pos_opening_entry}
                                </p>
                            ) : null}
                        </div>
                    ) : null}

                    {isPOS ? (
                        <div className={classes.section}>
                            <p style={{ margin: 0, lineHeight: 1.5, fontSize: 10 }}>
                                {t('documents:posp')}: {doc?.pos_profile}
                            </p>
                            <p style={{ margin: 0, lineHeight: 1.5, fontSize: 10 }}>
                                {t('documents:cashier')}: {doc?.user}
                            </p>
                        </div>
                    ) : null}
                </div>

                {doc?.items && !isInventory ? (
                    <table className={classes.table}>
                        <thead>
                            <tr className={classes.tableRow}>
                                <th className={classes.th}>N°</th>
                                <th className={classes.th}>{t('documents:prod')}</th>
                                <th className={classes.th}>{t('documents:qty')}</th>
                                <th className={classes.th}>{t('documents:disc')}</th>
                                <th className={classes.th}>{t('documents:rate')}</th>
                                <th className={classes.th}>{t('documents:amnt')}</th>
                                <th className={classes.th}>{t('documents:warehouse')}</th>
                            </tr>
                        </thead>
                        <tbody className={classes.tableBody}>
                            {doc?.items?.map((row, index) => [
                                <tr key={index} className={classes.tableRow}>
                                    <td className={classes.td}>{index + 1}</td>
                                    <td className={classes.td}>
                                        {row?.item_code}: {row?.item_name}
                                    </td>
                                    <td className={classes.td}>
                                        {row?.qty} {row?.stock_uom}
                                    </td>
                                    <td className={classes.td} align="right">
                                        <CurrencyFormatter
                                            locale="fr-FR"
                                            minimumFractionDigits={3}
                                            value={row?.discount_amount}
                                            currency={doc?.currency ?? globalDefaults?.default_currency}
                                        />
                                    </td>
                                    <td className={classes.td} align="right">
                                        <CurrencyFormatter
                                            locale="fr-FR"
                                            minimumFractionDigits={3}
                                            value={row?.rate}
                                            currency={doc?.currency ?? globalDefaults?.default_currency}
                                        />
                                    </td>
                                    <td className={classes.td} align="right">
                                        <CurrencyFormatter
                                            locale="fr-FR"
                                            minimumFractionDigits={3}
                                            value={row?.amount}
                                            currency={doc?.currency ?? globalDefaults?.default_currency}
                                        />
                                    </td>
                                    <td className={classes.td}>
                                        <strong>{row?.warehouse}</strong>
                                    </td>
                                </tr>,

                                index === doc?.items?.length - 1 && (
                                    <React.Fragment key="tfoot">
                                        <tr className={classes.tableRow}>
                                            <td className={classes.tdTotal} style={{ textAlign: 'right', fontWeight: 'bold' }} colSpan="6">
                                                {t('documents:ttlQty')}
                                            </td>
                                            <td className={classes.tdTotal} align="right">
                                                {doc?.total_qty}
                                            </td>
                                        </tr>
                                        <tr className={classes.tableRow}>
                                            <td className={classes.tdTotal} style={{ textAlign: 'right', fontWeight: 'bold' }} colSpan="6">
                                                Total
                                            </td>
                                            <td className={classes.tdTotal} align="right">
                                                <CurrencyFormatter
                                                    locale="fr-FR"
                                                    minimumFractionDigits={3}
                                                    value={doc?.total}
                                                    currency={doc?.currency ?? globalDefaults?.default_currency}
                                                />
                                            </td>
                                        </tr>

                                        {doc?.taxes?.map((taxe, i) => (
                                            <>
                                                <tr className={classes.tableRow}>
                                                    <td
                                                        className={classes.tdTotal}
                                                        style={{ textAlign: 'right', fontWeight: 'bold' }}
                                                        colSpan="6"
                                                    >
                                                        {taxe?.description}
                                                    </td>
                                                    <td className={classes.tdTotal} align="right">
                                                        <CurrencyFormatter
                                                            locale="fr-FR"
                                                            minimumFractionDigits={3}
                                                            value={taxe?.tax_amount}
                                                            currency={doc?.currency ?? globalDefaults?.default_currency}
                                                        />
                                                    </td>
                                                </tr>
                                            </>
                                        ))}

                                        <tr className={classes.tableRow}>
                                            <td className={classes.tdTotal} style={{ textAlign: 'right', fontWeight: 'bold' }} colSpan="6">
                                                {t('documents:ada')}
                                            </td>
                                            <td className={classes.tdTotal} align="right">
                                                <CurrencyFormatter
                                                    locale="fr-FR"
                                                    minimumFractionDigits={3}
                                                    value={doc?.discount_amount}
                                                    currency={doc?.currency ?? globalDefaults?.default_currency}
                                                />
                                            </td>
                                        </tr>

                                        <tr className={classes.tableRow}>
                                            <td className={classes.tdTotal} style={{ textAlign: 'right', fontWeight: 'bold' }} colSpan="6">
                                                {t('documents:gt')}
                                            </td>
                                            <td className={classes.tdTotal} align="right">
                                                <CurrencyFormatter
                                                    locale="fr-FR"
                                                    minimumFractionDigits={3}
                                                    value={doc?.grand_total}
                                                    currency={doc?.currency ?? globalDefaults?.default_currency}
                                                />
                                            </td>
                                        </tr>

                                        <tr className={classes.tableRow}>
                                            <td className={classes.tdTotal} style={{ textAlign: 'right', fontWeight: 'bold' }} colSpan="6">
                                                {t('documents:rt')}
                                            </td>
                                            <td className={classes.tdTotal} align="right">
                                                <CurrencyFormatter
                                                    locale="fr-FR"
                                                    minimumFractionDigits={3}
                                                    value={doc?.rounded_total}
                                                    currency={doc?.currency ?? globalDefaults?.default_currency}
                                                />
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                )
                            ])}
                        </tbody>
                    </table>
                ) : doc?.items && isInventory ? (
                    <table className={classes.table}>
                        <thead>
                            <tr className={classes.tableRow}>
                                <th className={classes.th}>N°</th>
                                <th className={classes.th}>{t('documents:prod')}</th>
                                <th className={classes.th}>
                                    {doc?.doctype === 'Stock Entry' ? t('documents:twarehouse') : t('documents:reqby')}
                                </th>
                                <th className={classes.th}>{t('documents:desc')}</th>
                                <th className={classes.th}>{t('documents:itemgroup')}</th>
                                <th className={classes.th}>{t('documents:qty')}</th>
                                <th className={classes.th}>
                                    {doc?.doctype === 'Stock Entry' ? t('documents:vrate') : t('documents:twarehouse')}
                                </th>
                                {doc?.doctype === 'Stock Entry' ? <th className={classes.th}>{t('documents:amnt')}</th> : null}
                            </tr>
                        </thead>
                        <tbody className={classes.tableBody}>
                            {doc?.items?.map((row, index) => [
                                <tr key={index} className={classes.tableRow}>
                                    <td className={classes.td}>{row?.idx}</td>
                                    <td className={classes.td}>
                                        {row?.item_code}: {row?.item_name}
                                    </td>
                                    <td className={classes.td}>{doc?.doctype === 'Stock Entry' ? row?.t_warehouse : row?.schedule_date}</td>
                                    <td className={classes.td}>{row?.description}</td>
                                    <td className={classes.td}>{row?.item_group}</td>
                                    <td className={classes.td}>
                                        <strong>
                                            {row?.qty} {row?.stock_uom}
                                        </strong>
                                    </td>
                                    <td className={classes.td}>
                                        {doc?.doctype === 'Stock Entry' ? (
                                            <CurrencyFormatter
                                                locale="fr-FR"
                                                minimumFractionDigits={3}
                                                value={row?.valuation_rate}
                                                currency={globalDefaults?.default_currency}
                                            />
                                        ) : (
                                            row?.warehouse
                                        )}
                                    </td>
                                    {doc?.doctype === 'Stock Entry' ? (
                                        <td className={classes.td}>
                                            <strong>
                                                <CurrencyFormatter
                                                    locale="fr-FR"
                                                    minimumFractionDigits={3}
                                                    value={row?.amount}
                                                    currency={globalDefaults?.default_currency}
                                                />
                                            </strong>
                                        </td>
                                    ) : null}
                                </tr>
                            ])}
                        </tbody>
                    </table>
                ) : null}

                {doc?.payments?.length ? (
                    <div className={classes.section}>
                        <p style={{ margin: 0, lineHeight: 1.5, fontWeight: 'bold', fontSize: 10 }}>{t('documents:p')}</p>
                        <table className={classes.table}>
                            <thead>
                                <tr className={classes.tableRow}>
                                    <th className={classes.th}>{t('documents:date')}</th>
                                    <th className={classes.th}>{t('documents:createdby')}</th>
                                    <th className={classes.th}>{t('documents:pref')}</th>
                                    <th className={classes.th}>{t('documents:mp')}</th>
                                    <th className={classes.th}>{t('documents:pam')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {doc?.payments?.map((pay, index) => (
                                    <tr className={classes.tableRow} key={index}>
                                        <td className={classes.td}>{formatDate(pay?.creation, 'DD-MM-YYYY HH:mm')}</td>
                                        <td className={classes.td}>{pay?.owner}</td>
                                        <td className={classes.td}>{pay?.name}</td>
                                        <td className={classes.td}>{pay?.mode_of_payment}</td>
                                        <td className={classes.td}>
                                            <CurrencyFormatter
                                                locale="fr-FR"
                                                currency={globalDefaults?.default_currency}
                                                minimumFractionDigits={3}
                                                value={pay?.amount}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : doc?.payment_schedule?.length ? (
                    <div className={classes.section}>
                        <p style={{ margin: 0, lineHeight: 1.5, fontWeight: 'bold', fontSize: 10 }}>{t('documents:ps')}</p>
                        <table className={classes.table}>
                            <thead>
                                <tr className={classes.tableRow}>
                                    <th className={classes.th}>{t('documents:date')}</th>
                                    <th className={classes.th}>{t('documents:pref')}</th>
                                    <th className={classes.th}>{t('documents:pa')}</th>
                                    <th className={classes.th}>{t('documents:da')}</th>
                                    <th className={classes.th}>{t('documents:pam')}</th>
                                    <th className={classes.th}>{t('documents:o')}</th>
                                    <th className={classes.th}>{t('documents:ddp')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {doc?.payment_schedule?.map((pay, index) => (
                                    <tr className={classes.tableRow} key={index}>
                                        <td className={classes.td}>{formatDate(pay?.creation, 'DD-MM-YYYY HH:mm')}</td>
                                        <td className={classes.td}>{pay?.name}</td>
                                        <td className={classes.td}>
                                            <CurrencyFormatter
                                                locale="fr-FR"
                                                currency={globalDefaults?.default_currency}
                                                minimumFractionDigits={3}
                                                value={pay?.payment_amount}
                                            />
                                        </td>
                                        <td className={classes.td}>
                                            <CurrencyFormatter
                                                locale="fr-FR"
                                                currency={globalDefaults?.default_currency}
                                                minimumFractionDigits={3}
                                                value={pay?.discounted_amount}
                                            />
                                        </td>
                                        <td className={classes.td}>
                                            <CurrencyFormatter
                                                locale="fr-FR"
                                                currency={globalDefaults?.default_currency}
                                                minimumFractionDigits={3}
                                                value={pay?.paid_amount}
                                            />
                                        </td>
                                        <td className={classes.td}>
                                            <CurrencyFormatter
                                                locale="fr-FR"
                                                currency={globalDefaults?.default_currency}
                                                minimumFractionDigits={3}
                                                value={pay?.outstanding}
                                            />
                                        </td>
                                        <td className={classes.td}>{formatDate(pay?.due_date, 'DD-MM-YYYY')}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : null}

                {doc?.other_charges_calculation ? (
                    <div className={classes.section}>
                        <div style={{ margin: 0, lineHeight: 1.5, fontWeight: 'bold', fontSize: 10 }} id="other_charges_calculation" />
                    </div>
                ) : null}

                {doc?.doctype === 'POS Opening Entry' ? (
                    <div className={classes.section}>
                        <p style={{ margin: 0, lineHeight: 1.5, fontWeight: 'bold', fontSize: 10 }}>{t('documents:openblnc')}</p>
                        <table className={classes.table}>
                            <thead>
                                <tr className={classes.tableRow}>
                                    <th className={classes.th}>N°</th>
                                    <th className={classes.th}>{t('documents:mp')}</th>
                                    <th className={classes.th}>{t('documents:oa')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {doc?.balance_details?.map((el, index) => (
                                    <tr className={classes.tableRow} key={index}>
                                        <td className={classes.td}>{el?.idx}</td>
                                        <td className={classes.td}>{el?.mode_of_payment}</td>
                                        <td className={classes.td}>
                                            <CurrencyFormatter
                                                locale="fr-FR"
                                                currency={globalDefaults?.default_currency}
                                                minimumFractionDigits={3}
                                                value={el?.opening_amount}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : null}

                {doc?.doctype === 'POS Closing Entry' ? (
                    <>
                        <div className={classes.section}>
                            <p style={{ margin: 0, lineHeight: 1.5, fontWeight: 'bold', fontSize: 10 }}>{t('documents:pt')}</p>
                            <table className={classes.table}>
                                <thead>
                                    <tr className={classes.tableRow}>
                                        <th className={classes.th}>N°</th>
                                        <th className={classes.th}>{t('documents:pi')}</th>
                                        <th className={classes.th}>{t('documents:date')}</th>
                                        <th className={classes.th}>{t('documents:cus')}</th>
                                        <th className={classes.th}>{t('documents:amnt')}</th>
                                        <th className={classes.th}>{t('documents:isr')}</th>
                                        <th className={classes.th}>{t('documents:ra')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {doc?.pos_transactions?.map((el, index) => (
                                        <tr className={classes.tableRow} key={index}>
                                            <td className={classes.td}>{el?.idx}</td>
                                            <td className={classes.td}>{el?.pos_invoice}</td>
                                            <td className={classes.td}>{formatDate(el?.posting_date, 'DD-MM-YYYY')}</td>
                                            <td className={classes.td}>{el?.customer}</td>
                                            <td className={classes.td}>
                                                <CurrencyFormatter
                                                    locale="fr-FR"
                                                    minimumFractionDigits={3}
                                                    value={el?.grand_total}
                                                    currency={globalDefaults?.default_currency}
                                                />
                                            </td>
                                            <td className={classes.td}>{el?.is_return === 1 ? <span>&#10003;</span> : null}</td>
                                            <td className={classes.td}>{el?.return_against}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        <div className={classes.section}>
                            <p style={{ margin: 0, lineHeight: 1.5, fontWeight: 'bold', fontSize: 10 }}>{t('documents:pr')}</p>
                            <table className={classes.table}>
                                <thead>
                                    <tr className={classes.tableRow}>
                                        <th className={classes.th}>N°</th>
                                        <th className={classes.th}>{t('documents:mp')}</th>
                                        <th className={classes.th}>{t('documents:oa')}</th>
                                        <th className={classes.th}>{t('documents:ea')}</th>
                                        <th className={classes.th}>{t('documents:ca')}</th>
                                        <th className={classes.th}>{t('documents:diff')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {doc?.payment_reconciliation?.map((el, index) => (
                                        <tr className={classes.tableRow} key={index}>
                                            <td className={classes.td}>{el?.idx}</td>
                                            <td className={classes.td}>{el?.mode_of_payment}</td>
                                            <td className={classes.td}>
                                                <CurrencyFormatter
                                                    locale="fr-FR"
                                                    minimumFractionDigits={3}
                                                    value={el?.opening_amount}
                                                    currency={globalDefaults?.default_currency}
                                                />
                                            </td>
                                            <td className={classes.td}>
                                                <CurrencyFormatter
                                                    locale="fr-FR"
                                                    minimumFractionDigits={3}
                                                    value={el?.expected_amount}
                                                    currency={globalDefaults?.default_currency}
                                                />
                                            </td>
                                            <td className={classes.td}>
                                                <CurrencyFormatter
                                                    locale="fr-FR"
                                                    minimumFractionDigits={3}
                                                    value={el?.closing_amount}
                                                    currency={globalDefaults?.default_currency}
                                                />
                                            </td>
                                            <td className={classes.td}>
                                                <CurrencyFormatter
                                                    locale="fr-FR"
                                                    minimumFractionDigits={3}
                                                    value={el?.difference}
                                                    currency={globalDefaults?.default_currency}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        <div className={classes.section}>
                            <p style={{ margin: 0, lineHeight: 1.5, fontWeight: 'bold', fontSize: 10 }}>{t('documents:ttls')}</p>
                            <table className={classes.table}>
                                <thead>
                                    <tr className={classes.tableRow}>
                                        <th className={classes.th}>N°</th>
                                        <th className={classes.th}>{t('documents:ah')}</th>
                                        <th className={classes.th}>{t('documents:rate')}</th>
                                        <th className={classes.th}>{t('documents:amnt')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {doc?.taxes?.map((el, index) => (
                                        <tr className={classes.tableRow} key={index}>
                                            <td className={classes.td}>{el?.idx}</td>
                                            <td className={classes.td}>{el?.account_head}</td>
                                            <td className={classes.td}>{el?.rate?.toFixed(1)}%</td>
                                            <td className={classes.td}>
                                                <CurrencyFormatter
                                                    locale="fr-FR"
                                                    minimumFractionDigits={3}
                                                    value={el?.amount}
                                                    currency={globalDefaults?.default_currency}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            <div className={classes.section}>
                                <p style={{ margin: 0, lineHeight: 1.5, fontSize: 10 }}>
                                    {t('documents:gt')}:{' '}
                                    <CurrencyFormatter
                                        locale="fr-FR"
                                        minimumFractionDigits={3}
                                        value={doc?.grand_total}
                                        currency={globalDefaults?.default_currency}
                                    />
                                </p>

                                <p style={{ margin: 0, lineHeight: 1.5, fontSize: 10 }}>
                                    {t('documents:nt')}:{' '}
                                    <CurrencyFormatter
                                        locale="fr-FR"
                                        minimumFractionDigits={3}
                                        value={doc?.net_total}
                                        currency={globalDefaults?.default_currency}
                                    />
                                </p>

                                <p style={{ margin: 0, lineHeight: 1.5, fontSize: 10 }}>
                                    {t('documents:ttlQty')}: {doc?.total_quantity}
                                </p>
                            </div>
                        </div>
                    </>
                ) : null}

                {defaultCompany?.name === 'Gautier' ? (
                    <div className={classes.avoidPageBreak}>
                        <p style={{ margin: 0, lineHeight: 1.3, fontSize: 12, fontWeight: 600 }}>Terms and Conditions</p>
                        <br />
                        <p style={{ margin: 0, lineHeight: 1.3, fontSize: 12 }}>
                            * Merchandise not paid in full for within 90 days of deposit invoice will be considered abandoned. In addition
                            to any other remedy, French Riviera Furniture LLC shall be entitled to liquidate the merchandise, retain all
                            deposits, and apply such proceeds to the unpaid invoiced balances.
                            <br /> <br />
                            * Failure to make a claim within this period constitutes acceptance of the merchandise and a waiver of claims.
                            French Riviera Furniture LLC shall be entitled to repair or replace the damaged pieces, no refund.
                            <br /> <br />
                            * The purchaser is entitled of a free 30-day storage of the purchased goods starting from the issuing date of
                            the invoice, afterwards a monthly charge of 250 Dirhams per cubic meter should be paid by the purchaser in
                            advance with a minimum charge of 150 dirhams when applicable.
                            <br /> <br />
                            * All purchased and ordered items cannot be returned or exchanged. For special cases of undelivered goods, an
                            exchange request is mandatory and French Riviera Furniture LLC shall be entitled to deny the request, or approve
                            it with a deduction of 35% of the original price of the goods, the purchaser will be receiving a credit note for
                            the rest of the amount to be redeemed through the purchase of French Riviera Furniture LLC (Gautier Dubai)
                            goods.
                            <br /> <br />* Delivery dates are estimates unless a fixed date for the transactions has been expressly agreed.
                            if the purchaser is responsible for any delay of the delivery, service or the assembly of the goods, we are
                            entitled without prejudice to any other claims, to demand payment fees in accordance with the case. Cancelation
                            or modification of delivery date/time should be done at least 48 hours in advance.
                            <br /> <br />
                            * A delivery and assembly fee of 250 Dirhams is applicable for any invoice not exceeding the total of 5000
                            Dirhams.
                            <br /> <br />
                            * Delivery and assembly charges: Dubai/Sharjah/Ajman-Free, Rak-500 Dirhams, Abu Dhabi/Al Ain-550 Dirhams and
                            Fujeirah-600 Dirhams * Merchandise not paid in full for within 90 days of deposit invoice will be considered
                            abandoned. In addition to any other remedy, French Riviera Furniture LLC shall be entitled to liquidate the
                            merchandise, retain all deposits, and apply such proceeds to the unpaid invoiced balances.
                            <br /> <br />
                            * Failure to make a claim within this period constitutes acceptance of the merchandise and a waiver of claims.
                            French Riviera Furniture LLC shall be entitled to repair or replace the damaged pieces, no refund.
                            <br /> <br />
                            * The purchaser is entitled of a free 30-day storage of the purchased goods starting from the issuing date of
                            the invoice, afterwards a monthly charge of 250 Dirhams per cubic meter should be paid by the purchaser in
                            advance with a minimum charge of 150 dirhams when applicable.
                            <br /> <br />
                            * All purchased and ordered items cannot be returned or exchanged. For special cases of undelivered goods, an
                            exchange request is mandatory and French Riviera Furniture LLC shall be entitled to deny the request, or approve
                            it with a deduction of 35% of the original price of the goods, the purchaser will be receiving a credit note for
                            the rest of the amount to be redeemed through the purchase of French Riviera Furniture LLC (Gautier Dubai)
                            goods.
                            <br /> <br />* Delivery dates are estimates unless a fixed date for the transactions has been expressly agreed.
                            if the purchaser is responsible for any delay of the delivery, service or the assembly of the goods, we are
                            entitled without prejudice to any other claims, to demand payment fees in accordance with the case. Cancelation
                            or modification of delivery date/time should be done at least 48 hours in advance.
                            <br /> <br />
                            * A delivery and assembly fee of 250 Dirhams is applicable for any invoice not exceeding the total of 5000
                            Dirhams.
                            <br /> <br />* Delivery and assembly charges: Dubai/Sharjah/Ajman-Free, Rak-500 Dirhams, Abu Dhabi/Al Ain-550
                            Dirhams and Fujeirah-600 Dirhams
                        </p>
                    </div>
                ) : null}
            </div>
            {defaultCompany?.name === 'Gautier' ? <Footer /> : null}
        </div>
    );
});

export default PrintDocumentsTemplate;
