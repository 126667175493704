import { Box } from '@mui/system';
import useReportQuery from 'hooks/useReportQuery';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import MainCard from 'ui-component/cards/MainCard';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { InputLabel } from '@mui/material';
import SelectForm from 'ui-component/form/SelectForm';
import useFetch from 'hooks/useFetch';
import FormDateTime from 'ui-component/dateTime/FormDateTime';
import DynamicChart from 'views/SalesDashboard/components/DynamicChart';
import Nothing2Show from 'views/SalesDashboard/components/Nothing2Show';
import Report from 'views/SalesDashboard/components/Report';
import moment from 'moment';
import ChartPimDash from 'views/SalesDashboard/components/ChartPimDash';

function SalesOrderAnalysis() {
    const globalDefaults = useSelector((state) => state.login.globalDefaults);
    const { t } = useTranslation();

    const [reqData, setReqData] = useState({
        report_name: 'Sales Order Analysis',
        filters: {
            company: globalDefaults?.default_company,
            from_date: moment().subtract(1, 'months').format('YYYY-MM-DD'),
            to_date: moment().format('YYYY-MM-DD'),
            sales_order: [],
            status: []
        }
    });

    const handleChangeFilters = (e) => {
        setReqData({ ...reqData, filters: { ...reqData.filters, [e.target.name]: e.target.value } });
    };

    const { data: doc, isPending } = useReportQuery(`${process.env.REACT_APP_API_URI}/api/report-query`, reqData);

    const { data: salesOrders, isPending: isSalesOrderPending } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Sales Order',
            fields: ['`tabSales Order`.`name`'],
            filters: [['Sales Order', 'company', '=', globalDefaults?.default_company]],
            order_by: '`tabSales Order`.`modified` desc',
            start: 0,
            page_length: 40
        },
        'POST'
    );
    return (
        <div style={{ opacity: isPending ? 0.5 : 1, transition: 'all .2s', marginTop: 20 }}>
            <div style={{ display: 'flex', flexDirection: 'row', gap: 20 }}>
                {/* filters */}
                <div style={{ width: '20%' }}>
                    <MainCard
                        isWhite
                        title={
                            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} gap={5}>
                                <Box color={'ActiveCaption'} display={'flex'} alignItems={'center'}>
                                    {t('dashboard:f')}
                                </Box>
                                <FilterAltIcon />
                            </Box>
                        }
                        boxShadow
                        divider
                        sx={{ width: '100%' }}
                    >
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                            <div>
                                <InputLabel sx={{ fontSize: 12, p: 0.5 }}>{t('dashboard:fd')}</InputLabel>
                                <FormDateTime
                                    fullwidth={true}
                                    type="date"
                                    name="from_date"
                                    value={reqData.filters.from_date}
                                    handleChange={handleChangeFilters}
                                />
                            </div>
                            <div>
                                <InputLabel sx={{ fontSize: 12, p: 0.5 }}>{t('dashboard:td')}</InputLabel>
                                <FormDateTime
                                    fullwidth={true}
                                    type="date"
                                    name="to_date"
                                    value={reqData.filters.to_date}
                                    handleChange={handleChangeFilters}
                                />
                            </div>
                            <div>
                                <InputLabel sx={{ fontSize: 12, p: 0.5 }}>{t('columns:status')}</InputLabel>
                                <SelectForm
                                    isMultiple
                                    fullwidth={true}
                                    name="status"
                                    value={reqData.filters.status}
                                    handleChange={handleChangeFilters}
                                    data={['To Bill', 'To Deliver', 'To Deliver and Bill', 'Completed']}
                                />
                            </div>

                            <div>
                                <InputLabel sx={{ fontSize: 12, p: 0.5 }}>{t('crm:salesorder')}</InputLabel>
                                <SelectForm
                                    isMultiple
                                    fullwidth={true}
                                    name="sales_order"
                                    value={reqData.filters.sales_order}
                                    handleChange={handleChangeFilters}
                                    data={salesOrders}
                                    propToRender={'name'}
                                />
                            </div>
                        </div>
                    </MainCard>
                </div>

                {/* charts */}
                <div style={{ width: '80%' }}>
                    <ChartPimDash
                        type={'bar'}
                        title={t('dashboard:salesOrderAnalysis')}
                        doc={doc}
                        isPending={isPending}
                        reqData={reqData}
                    />
                </div>
            </div>

            {/* report */}
            <MainCard
                title={
                    <Box color={'ActiveCaption'} display={'flex'} alignItems={'center'}>
                        Sales Order Analysis
                    </Box>
                }
                isWhite
                boxShadow
                divider
                sx={{ marginTop: 2 }}
            >
                {doc?.result?.length === 0 ? (
                    <Nothing2Show />
                ) : (
                    <>
                        <Report doc={doc} />
                    </>
                )}
            </MainCard>
        </div>
    );
}

export default SalesOrderAnalysis;
