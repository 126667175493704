// assets
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import { ROLE } from '../configs/constants';

// constant
const icons = {
    FolderOutlinedIcon
};

const documents = {
    id: 'documents',
    title: 'documents-directory',
    caption: '',
    type: 'group',
    role: [ROLE.ADMIN],
    children: [
        {
            id: 'documents-directory',
            title: 'documents:dd',
            type: 'collapse',
            icon: icons.FolderOutlinedIcon,
            children: [
                {
                    id: 'sales-documents',
                    title: 'documents:sd',
                    type: 'item',
                    url: '/documents/sales-docs'
                },
                {
                    id: 'buying-documents',
                    title: 'documents:pd',
                    type: 'item',
                    url: '/documents/buying-docs'
                },
                {
                    id: 'inventory-documents',
                    title: 'documents:id',
                    type: 'item',
                    url: '/documents/inventory-docs'
                },
                {
                    id: 'pos-documents',
                    title: 'documents:posd',
                    type: 'item',
                    url: '/documents/pos-docs'
                }
            ]
        }
    ]
};

export default documents;
