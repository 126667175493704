import React, { useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box } from '@mui/system';
import InputTextField from 'ui-component/form/InputTextField';
import SelectForm from 'ui-component/form/SelectForm';
import InputCheckboxField from 'ui-component/form/InputCheckboxField';
import { LoadingButton } from '@mui/lab';
import { useEffect } from 'react';
import Loader from 'ui-component/Loader';
import { formatErpResponse } from 'utils/utils';
import { useDispatch } from 'react-redux';
import { SetNotification } from 'store/services/api';
import { useTranslation } from 'react-i18next';
import useFetch from 'hooks/useFetch';
import useGetAxios from 'hooks/useGetAxios';

const BarcodeSettings = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [loadingButton, setLoadingButton] = React.useState(false);
    const [itemsWithBarcodes, setItemsWithBarcodes] = useState([]);

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const {
        data: doc,
        isPending,
        refetch: refetchDoc,
        docinfo
    } = useGetAxios(`${process.env.REACT_APP_API_URI}/api/details?doctype=Barcode%20Settings&name=Barcodes%20Settings`);

    const barcodesettingsReqData = {
        doctype: 'Barcode Settings',
        fields: [
            '`tabBarcode Settings`.`name`',
            '`tabBarcode Settings`.`barcode_type`',
            '`tabBarcode Settings`.`suffix`',
            '`tabBarcode Settings`.`automatic`',
            '`tabBarcode Settings`.`prefix`'
        ]
    };
    const { data: barcodesettings, isPending: isPendingBarcodeSettings } = useFetch(`/api/get-list`, barcodesettingsReqData, 'POST');

    console.log('settings', barcodesettings);

    let globalAutomatic;
    let barcodeTypeOptions;
    let globalPrefix;

    if (barcodesettings) {
        barcodesettings.forEach((setting) => {
            const name = setting.name;
            const barcode_type = setting.barcode_type;
            const suffix = setting.suffix;
            const automatic = setting.automatic;
            const prefix = setting.prefix;
            console.log('name', name);
            console.log('barcode_type', barcode_type);
            console.log('suffix', suffix);
            console.log('automatic', automatic);

            globalAutomatic = automatic;
            barcodeTypeOptions = barcode_type;
            globalPrefix = prefix;
        });
    }

    const itemsReqData = {
        doctype: 'Item',
        fields: ['`tabItem`.`name`', '`tabItem`.`item_code`', '`tabItem`.`item_name`'],
        limit: 1
    };
    const { data: items, isPending: isPendingItems } = useFetch(`/api/get-list`, itemsReqData, 'POST');

    console.log('items', items);

    const barcodesReqData = {
        doctype: 'Item Barcode',
        fields: ['`tabItem Barcode`.`name`', '`tabItem Barcode`.`barcode_type_name`', '`tabItem Barcode`.`parent`'],
        order_by: '`tabItem Barcode`.`creation` DESC',
        limit: 1
    };
    const { data: barcodes, isPending: isPendingBarcodes } = useFetch(`/api/get-list`, barcodesReqData, 'POST');

    console.log('barcodes', barcodes);

    let globalSuffix;

    if (barcodes) {
        barcodes.forEach((barcode) => {
            const barcodeName = barcode.name;
            console.log('name', barcodeName);

            globalSuffix = barcodeName;
        });
    }
    console.log('globalItemName', globalSuffix);

    const [state, setState] = React.useState({
        barcode_type: '',
        suffix: '',
        automatic: 0
    });

    const getBarcodeLength = (barcodeType) => {
        switch (barcodeType) {
            case 'EAN-12':
            case 'UPC-A':
                return 12;
            case 'EAN-8':
                return 8;
            case 'EAN-13':
                return 13;
            case 'ITF':
                return 14;
            case 'Code-39':
                return 10;
            default:
                return 10;
        }
    };

    const barcodeOptions = [
        { label: 'EAN-12', value: 'EAN-12' },
        { label: 'ITF', value: 'ITF' },
        { label: 'EAN-8', value: 'EAN-8' },
        { label: 'EAN-13', value: 'EAN-13' },
        { label: 'Code-39', value: 'Code-39' },
        { label: 'UPC-A', value: 'UPC-A' }
    ];

    const barcodeLength = getBarcodeLength(state.barcode_type);

    const handleChange = (event) => {
        const { name, value } = event.target;
        let updatedState = { ...state, [name]: name === 'suffix' ? globalSuffix : value };

        if (name === 'barcode_type') {
            const barcodeLength = getBarcodeLength(value);
            updatedState = { ...updatedState, barcode_length: barcodeLength };
        }

        setState(updatedState);
    };

    const handleChangeCheckbox = (event, value) => {
        setState({ ...state, [event.target.name]: value ? 1 : 0 });
    };

    useEffect(() => {
        if (doc) {
            setState({
                barcode_type: doc.barcode_type,
                suffix: globalSuffix, // Set suffix to globalSuffix
                automatic: doc.automatic,
                prefix: doc.prefix
            });
        }
    }, [doc, globalSuffix]);

    const handleSubmit = () => {
        const data = {
            ...doc,
            doctype: 'Barcode Settings',
            barcode_type: state.barcode_type,
            suffix: state.suffix,
            automatic: state.automatic,
            prefix: state.prefix
        };
        setLoadingButton(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doc: data, action: 'Save' })
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'error', message: formatErpResponse(data?.message) }));
                } else {
                    dispatch(SetNotification({ code: 'success', message: t('common:barcodeMsg') }));
                    refetchDoc({});
                }
            });
    };
    console.log('1', state.automatic);
    console.log('2', globalAutomatic);

    if (isPending) return <Loader />;

    return (
        <>
            <MainCard
                border
                isWhite
                boxShadow
                divider
                title={
                    <Box display={'flex'} alignItems={'center'}>
                        <SettingsIcon sx={{ mr: 1 }} />
                        {t('products:barcode_settings')}
                    </Box>
                }
                sx={{ height: '70%', mt: 2 }}
            >
                <Box display="flex" flexWrap="wrap">
                    <Box sx={{ width: '50%', pr: 2 }}>
                        <InputCheckboxField
                            fullwidth
                            label={t('products:barcautomaticgen')}
                            name="automatic"
                            value={state.automatic === 1 ? true : false}
                            sx={{ mb: 2 }}
                            handleChange={handleChangeCheckbox}
                        />
                        {state.automatic === 1 && (
                            <>
                                <SelectForm
                                    fullwidth
                                    label={t('products:barcode_type')}
                                    name="barcode_type"
                                    value={state.barcode_type}
                                    sx={{ mb: 2 }}
                                    data={['EAN-8', 'EAN-12', 'EAN-13', 'UPC-A', 'CODE-39', 'ITF']}
                                    handleChange={handleChange}
                                />
                                <SelectForm
                                    fullwidth
                                    label={t('products:barcode_length')}
                                    name="barcode_length"
                                    value={barcodeLength}
                                    sx={{ mb: 2 }}
                                    data={[8, 10, 12, 13, 14]}
                                    handleChange={handleChange}
                                    disabled
                                />
                                <InputTextField
                                    fullwidth
                                    label={t('products:barcode_prefix')}
                                    name="prefix"
                                    value={state.prefix}
                                    sx={{ mb: 2 }}
                                    handleChange={handleChange}
                                />
                            </>
                        )}
                        <InputTextField
                            fullwidth
                            label={t('products:barcode_suffix')}
                            name="suffix"
                            value={state.suffix}
                            handleChange={handleChange}
                        />
                    </Box>
                </Box>
            </MainCard>
            <Box display={'flex'} justifyContent={'right'} mt={3}>
                <LoadingButton variant="contained" size="large" color="blue" onClick={handleSubmit} loading={loadingButton}>
                    {t('settings:text-50')}
                </LoadingButton>
            </Box>
            {/*<BarcodesForm automatic={state.automatic} />*/}
        </>
    );
};

export default BarcodeSettings;
