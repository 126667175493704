import React, { useEffect, useState } from 'react';
import { modalStyle } from 'views/utilities/ModalStyle';
import { Button, Modal, Snackbar, SnackbarContent } from '@mui/material';
import { Box } from '@mui/system';

import MainCard from 'ui-component/cards/MainCard';

import FileList from './components/FileList';
import LoadingList from './components/LoadingList';
import AddAssets from '../../ui-component/form/addAssets';
import { makeStyles } from '@mui/styles';
import { t } from 'i18next';
const useStyles = makeStyles((theme) => ({
    successSnackbar: {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.success.contrastText,
        borderRadius: theme.shape.borderRadius
    }
}));

const AllFilesList = () => {
    const [loadingButton, setLoadingButton] = useState(false);
    const [open, setOpen] = useState(false);
    const [openMiniForm, setOpenMiniForm] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [isItemFile, setItemFile] = useState([]);
    const [isDataFetched, setDataFetched] = useState(true);
    const classes = useStyles();
    const handleOpenForm = () => setOpenMiniForm(true);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setOpenMiniForm(false);
    };

    const fetchData = async () => {
        try {
            const doctype = 'Item';
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/get-assets-file`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'content-type': 'application/json',
                    'X-API-Key': process.env.REACT_APP_API_KEY,
                    Authorization: JSON.parse(localStorage.getItem('user'))?.token
                },
                body: JSON.stringify({ doctype })
            });

            const data = await response.json();
            setItemFile(data);
            setDataFetched(false);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        fetchData(); // Call the fetchData function to trigger data fetching
    }, []);
    // useEffect(() => {
    //     fetchData();
    // }, []);
    // const {
    //     data: filesList,
    //     isPending: isFilesListPending,
    //     refetch: refetchFilesList
    // } = useGetAxios(`${process.env.REACT_APP_API_URI}/api/get-files-list`, null, true);

    const title = (
        <Box p={-1} display="flex" justifyContent="space-between">
            {t('common:filesList')}
            <Button variant="contained" color="blue" component="label" onClick={handleOpenForm}>
                {t('common:import')}
            </Button>
        </Box>
    );

    const addAssets = (
        <Box sx={modalStyle}>
            <MainCard divider title={t('products:addassets')}>
                <AddAssets
                    handleClose={handleClose}
                    loadingButton={loadingButton}
                    setLoadingButton={setLoadingButton}
                    doc={selectedFile}
                    isEdit={false}
                    onSuccess={() => setUploadSuccess(true)}
                />
            </MainCard>
        </Box>
    );

    useEffect(() => {
        if (uploadSuccess) {
            fetchData();
            // Set a timeout to reset the uploadSuccess state after a certain duration
            const timeout = setTimeout(() => {
                setUploadSuccess(false);
            }, 3000); // 3 seconds

            return () => clearTimeout(timeout);
        }
    }, [uploadSuccess]);

    return (
        <>
            <MainCard title={title}>
                {isDataFetched ? <LoadingList /> : <FileList files={isItemFile ?? []} refetchFilesList={fetchData} />}
            </MainCard>
            <Modal open={openMiniForm} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                {addAssets}
            </Modal>
            {uploadSuccess && (
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                    }}
                    open={uploadSuccess}
                    autoHideDuration={6000}
                    onClose={() => setUploadSuccess(false)}
                >
                    <SnackbarContent className={classes.successSnackbar} message={t('products:uploadsucces')} />
                </Snackbar>
            )}
        </>
    );
};

export default AllFilesList;
