// assets
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import { ROLE } from '../configs/constants';
// constant
const icons = {
    FavoriteBorderOutlinedIcon
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
    id: 'pages',
    type: 'group',
    role: [ROLE.ADMIN, ROLE.ADMIN_WAREHOUSE],
    children: [
        {
            id: 'core',
            title: 'core:core',
            type: 'collapse',
            icon: icons.FavoriteBorderOutlinedIcon,
            children: [
                {
                    id: 'core-dashboard',
                    title: 'common:dash',
                    type: 'item',
                    url: '/core/dashboard'
                },
                {
                    id: 'mode-of-payment',
                    title: 'core:pm',
                    type: 'item',
                    url: '/core/mode-of-payment'
                },
                {
                    id: 'sales-person',
                    title: 'core:sp',
                    type: 'item',
                    url: '/core/vendeurs'
                },
                {
                    id: 'gestion',
                    title: 'dashboard:taxes',
                    type: 'collapse',
                    children: [
                        {
                            id: 'taxes',
                            title: 'core:taxmod',
                            type: 'item',
                            url: '/core/taxes-list'
                        },
                        {
                            id: 'category_taxe',
                            title: 'core:cat-tax',
                            type: 'item',
                            url: '/core/category-taxes-list'
                        },
                        {
                            id: 'taux_de_change',
                            title: 'core:taux-change',
                            type: 'item',
                            url: '/core/taux-de-change'
                        }
                    ]
                },
                {
                    id: 'currencies',
                    title: 'core:crr',
                    type: 'item',
                    url: '/core/currencies-list'
                },
                {
                    id: 'discount',
                    title: 'core:dcnt',
                    type: 'item',
                    url: '/core/discount'
                },
                {
                    id: 'commercial-condition',
                    title: 'crm:cndtcomm',
                    type: 'item',
                    url: '/core/pricing-rules'
                },
                {
                    id: 'settings',
                    title: 'crm:settings',
                    type: 'item',
                    url: '/core/global-settings'
                }
            ]
        }
    ]
};

export default pages;
