import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { Alert, CssBaseline, Snackbar, StyledEngineProvider, Typography } from '@mui/material';
// routing
import Routes from 'routes';
// defaultTheme
import themes from 'themes';
// project imports
import NavigationScroll from 'layout/NavigationScroll';
import { SetNotification } from 'store/services/api';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningIcon from '@mui/icons-material/Warning';

import { useTranslation } from 'react-i18next';
// ==============================|| APP ||============================== //

const App = () => {
    const dispatch = useDispatch();
    const customization = useSelector((state) => state.customization);
    const defaultCompany = useSelector((state) => state.login.detailsDefaultCompany);
    const notification = useSelector((state) => state.notification.notification);
    const local = useSelector((state) => state.theme.local);
    const user = useSelector((state) => state.login.user);
    const { t, i18n } = useTranslation();
    const handleClose = () => {
        dispatch(SetNotification(null));
    };

    useEffect(() => {
        i18n.changeLanguage(local);
    }, [local]);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization, defaultCompany)}>
                <div>
                    {notification && (
                        <Snackbar
                            onClose={handleClose}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            open={notification ? true : false}
                            autoHideDuration={2000}
                        >
                            <Alert
                                iconMapping={{
                                    success: <CheckCircleOutlineIcon color="menu" stroke={1.5} size="1.3rem" />,
                                    error: <ErrorOutlineIcon color="menu" stroke={1.5} size="1.3rem" />,
                                    warning: <WarningIcon color="menu" stroke={1.5} size="1.3rem" />
                                }}
                                variant="filled"
                                severity={notification ? notification?.code : 'success'}
                            >
                                <Typography variant="h5" color={'Menu'}>
                                    {notification?.message}
                                </Typography>
                            </Alert>
                        </Snackbar>
                    )}
                    <CssBaseline />
                    <NavigationScroll>
                        <Routes />
                    </NavigationScroll>
                </div>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
