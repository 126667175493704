import {
    SET_NOTIFICATION,
    SET_USER,
    SET_WAREHOUSES,
    SET_USER_DETAILS,
    SET_GLOBAL_DEFAULTS,
    USER_LOGOUT,
    SET_DOC_2_DUPLICATE,
    SET_BULK_EDIT_IDS,
    SET_BULK_EDIT_FIELDS,
    SET_LOCAL,
    SET_DETAILS_ITEMS_QUOTATION,
    SET_MAPPED_SALESORDER,
    SET_MAPPED_DELIVERYNOTE,
    SET_MAPPED_SALESINVOICE,
    SET_DETAILS_DEFAULTS_COMPANY,
    GET_SELLING_SETTINGS,
    SET_NOTIFICATION_BRODCAST
} from 'store/actions';
import axios from 'axios';
import { ROLE } from '../../configs/constants';

export const LoadSellingSettings = (sellingsettings) => {
    return {
        type: GET_SELLING_SETTINGS,
        payload: sellingsettings
    };
};
export const SetUser = (data) => {
    return {
        type: SET_USER,
        user: data
    };
};

export const SetUserDetails = (data) => {
    return {
        type: SET_USER_DETAILS,
        userDetails: data
    };
};

export const SetGlobalDefaults = (data) => {
    return {
        type: SET_GLOBAL_DEFAULTS,
        globalDefaults: data
    };
};

export const SetdetailsDefaultsCompany = (data) => {
    return {
        type: SET_DETAILS_DEFAULTS_COMPANY,
        detailsDefaultCompany: data
    };
};
export const SetNotification = (data) => {
    return {
        type: SET_NOTIFICATION,
        notification: data
    };
};
export const SetNotificationBrodcast = (data) => {
    return {
        type: SET_NOTIFICATION_BRODCAST,
        payload: data
    };
};

export const SetDoc2Duplicate = (data) => {
    return {
        type: SET_DOC_2_DUPLICATE,
        doc: data
    };
};

export const SetItemsQuotation = (data) => {
    return {
        type: SET_DETAILS_ITEMS_QUOTATION,
        doc: data
    };
};

export const SetBulkEditIds = (data) => {
    return {
        type: SET_BULK_EDIT_IDS,
        rows: data
    };
};

export const SetBulkEditFields = (data) => {
    return {
        type: SET_BULK_EDIT_FIELDS,
        fields: data
    };
};

export const SetLocal = (data) => {
    return {
        type: SET_LOCAL,
        local: data
    };
};
const setLocale = (role) => {
    switch (role) {
        case ROLE.SALES:
            return 'en';
        case ROLE.ADMIN:
            return 'en';
        default:
            return 'en';
    }
};
export const getSellingSettings = async (token) => {
    const header = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
            Authorization: token
        }
    };
    const url = `${process.env.REACT_APP_API_URI}/api/details?doctype=Selling+Settings&name=Selling+Settings`;
    const response = await axios.get(url, header);
    if (response)
        return {
            default_customer_group: response?.data?.docs[0]?.customer_group,
            default_selling_price_list: response?.data?.docs[0]?.selling_price_list,
            default_territory: response?.data?.docs[0]?.territory
        };
    else return {};
};
export const UserLogin = (content) => {
    return async (dispatch) => {
        const data = JSON.stringify({
            email: content.email,
            password: content.password
        });
        const header = {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-API-Key': `${process.env.REACT_APP_API_KEY}`
            }
        };
        return axios
            .post(`${process.env.REACT_APP_API_URI}/api/login`, data, header)
            .then(async function (response) {
                if (response?.data?.role == 'Cashier') {
                    dispatch(SetNotification({ message: 'Les caissiers ne sont pas autorisés', code: 'error' }));
                } else {
                    dispatch(SetUser(response?.data));
                    localStorage.setItem('user', JSON.stringify(response?.data));
                    console.log('user inserted in local storage');
                    axios.post(
                        `${process.env.REACT_APP_API_URI}/api/status`,
                        JSON.stringify({
                            email: response?.data?.email,
                            status: 'Online'
                        }),
                        header
                    );
                    await axios
                        .get(`${process.env.REACT_APP_API_URI}/api/details?doctype=User&name=${response?.data?.email}`, {
                            headers: {
                                Accept: 'application/json',
                                'Content-Type': 'application/json',
                                'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
                                Authorization: response?.data?.token
                            }
                        })
                        .then(async ({ data }) => {
                            dispatch(SetLocal(setLocale(response?.data.role)));
                            const sellingsettings = await getSellingSettings(response?.data?.token);
                            dispatch(LoadSellingSettings(sellingsettings));
                            await axios
                                .get(`${process.env.REACT_APP_API_URI}/api/details?doctype=Global%20Defaults&name=Global%20Defaults`, {
                                    headers: {
                                        Accept: 'application/json',
                                        'Content-Type': 'application/json',
                                        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
                                        Authorization: response?.data?.token
                                    }
                                })
                                .then(async ({ data }) => {
                                    dispatch(SetGlobalDefaults(data?.docs[0]));
                                    await axios
                                        .get(
                                            `${process.env.REACT_APP_API_URI}/api/details?doctype=Company&name=${data?.docs[0]?.default_company}`,
                                            {
                                                headers: {
                                                    Accept: 'application/json',
                                                    'Content-Type': 'application/json',
                                                    'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
                                                    Authorization: response?.data?.token
                                                }
                                            }
                                        )
                                        .then(({ data }) => {
                                            dispatch(SetdetailsDefaultsCompany(data?.docs[0]));
                                        });
                                });

                            dispatch(SetUserDetails(data?.docs[0]));
                            dispatch(SetNotification({ message: "L'utilisateur est connecté avec succès", code: 'success' }));
                        });
                }
            })
            .catch(function (error) {
                dispatch(SetNotification({ message: error?.response?.data?.message, code: 'error' }));
            });
    };
};

export const UserLogout = () => {
    return async (dispatch) => {
        const header = {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
                Authorization: JSON.parse(localStorage.getItem('user'))?.token
            }
        };
        return axios.post(`${process.env.REACT_APP_API_URI}/api/logout`, {}, header).then(async () => {
            // clearing cookies
            window?.localStorage?.removeItem('user');
            localStorage?.clear();
            sessionStorage?.clear();
            indexedDB?.deleteDatabase('localforage');

            await axios
                .post(
                    `${process.env.REACT_APP_API_URI}/api/status`,
                    JSON.stringify({
                        email: JSON.parse(localStorage.getItem('user'))?.email,
                        status: 'Offline'
                    }),
                    header
                )
                .then(async function (status) {});
            dispatch(SetNotification({ message: "L'utilisateur s'est déconnecté avec succès", code: 'success' }));
            dispatch({
                type: USER_LOGOUT
            });
        });
    };
};

// load warehouses
export const LoadWarehouses = () => {
    return async (dispatch) => {
        const data = JSON.stringify({
            doctype: 'Warehouse',
            fields: [
                '`tabWarehouse`.`name`',
                '`tabWarehouse`.`creation`',
                '`tabWarehouse`.`modified`',
                '`tabWarehouse`.`is_group`',
                '`tabWarehouse`.`company`',
                '`tabWarehouse`.`city`',
                '`tabWarehouse`.`warehouse_name`'
            ]
        });
        const header = {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
                Authorization: JSON.parse(localStorage.getItem('user'))?.token
            }
        };
        return axios.post(`${process.env.REACT_APP_API_URI}/api/warehouses`, data, header).then(({ data }) => {
            dispatch(SetWarehouse(data));
        });
    };
};

export const SetWarehouse = (content) => {
    return {
        type: SET_WAREHOUSES,
        warehouses: content
    };
};

export const CreateWarehouse = (content) => {
    return async (dispatch) => {
        const data = JSON.stringify({
            doc: content
        });
        const header = {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-API-Key': `${process.env.REACT_APP_API_KEY}`
            }
        };
        return axios
            .post(`${process.env.REACT_APP_API_URI}/api/warehouses/create`, data, header)
            .then(function (response) {})
            .catch(function (error) {});
    };
};

export const searchLink = (content) => {
    const data = JSON.stringify({
        doc: content
    });
    const header = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem('user'))?.token
        }
    };
    return axios
        .post(`${process.env.REACT_APP_API_URI}/api/link/search`, content, header)
        .then((response) => {})
        .catch((err) => {
            return [];
        });
};

/* SALES MAPPED DOCS FUNCTIONS */

export const SetMappedSalesOrder = (data) => {
    return {
        type: SET_MAPPED_SALESORDER,
        doc: data
    };
};

export const SetMappedDeliveryNote = (data) => {
    return {
        type: SET_MAPPED_DELIVERYNOTE,
        doc: data
    };
};

export const SetMappedSalesInvoice = (data) => {
    return {
        type: SET_MAPPED_SALESINVOICE,
        doc: data
    };
};
