import { Box } from '@mui/material';
import useFetch from 'hooks/useFetch';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { SetNotification } from 'store/services/api';
import DataRender from 'ui-component/tables/DataRender';
import { formatErpResponse } from 'utils/utils';

function SalesDocumentsList() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const defaultCompany = useSelector((state) => state.login.detailsDefaultCompany);
    const { t } = useTranslation();

    const salesOrderReqData = {
        doctype: 'Sales Order',
        fields: ['`tabSales Order`.`name`', '`tabSales Order`.`creation`', '`tabSales Order`.`customer`', '`tabSales Order`.`status`'],
        filters: [['Sales Order', 'company', '=', defaultCompany?.name]],
        start: 0,
        page_length: 50
    };

    const salesInvoiceReqData = {
        doctype: 'Sales Invoice',
        fields: [
            '`tabSales Invoice`.`name`',
            '`tabSales Invoice`.`creation`',
            '`tabSales Invoice`.`customer`',
            '`tabSales Invoice`.`status`'
        ],
        filters: [['Sales Invoice', 'company', '=', defaultCompany?.name]],
        start: 0,
        page_length: 50
    };

    const posReqData = {
        doctype: 'POS Invoice',
        fields: ['`tabPOS Invoice`.`name`', '`tabPOS Invoice`.`creation`', '`tabPOS Invoice`.`customer`', '`tabPOS Invoice`.`status`'],
        filters: [['POS Invoice', 'company', '=', defaultCompany?.name]],
        start: 0,
        page_length: 50
    };

    const deliveryReqData = {
        doctype: 'Delivery Note',
        fields: [
            '`tabDelivery Note`.`name`',
            '`tabDelivery Note`.`creation`',
            '`tabDelivery Note`.`customer`',
            '`tabDelivery Note`.`status`'
        ],
        filters: [['Delivery Note', 'company', '=', defaultCompany?.name]],
        start: 0,
        page_length: 50
    };

    const quotationReqData = {
        doctype: 'Quotation',
        fields: ['`tabQuotation`.`name`', '`tabQuotation`.`creation`', '`tabQuotation`.`title`', '`tabQuotation`.`status`'],
        filters: [['Quotation', 'company', '=', defaultCompany?.name]],
        start: 0,
        page_length: 50
    };

    const {
        data: salesOrders,
        isPending: isPendingSalesOrders,
        refetch: refetchSalesOrders
    } = useFetch(`/api/get-list`, salesOrderReqData, 'POST');

    const {
        data: salesInvoices,
        isPending: isPendingSalesInvoices,
        refetch: refetchSalesInvoices
    } = useFetch(`/api/get-list`, salesInvoiceReqData, 'POST');

    const {
        data: posInvoices,
        isPending: isPendingPosInvoices,
        refetch: refetchPosInvoices
    } = useFetch(`/api/get-list`, posReqData, 'POST');

    const {
        data: deliveries,
        isPending: isPendingDeliveries,
        refetch: refetchDeliveries
    } = useFetch(`/api/get-list`, deliveryReqData, 'POST');

    const {
        data: quotations,
        isPending: isPendingQuotations,
        refetch: refetchQuotations
    } = useFetch(`/api/get-list`, quotationReqData, 'POST');

    function sortObjectsByDate(array) {
        array.sort((a, b) => {
            const dateA = new Date(a.creation);
            const dateB = new Date(b.creation);

            return dateB - dateA;
        });

        return array;
    }

    const [data, setData] = useState([]);
    const [selectionModel, setSelectionModel] = useState([]);

    const handleEdit = (row) => {
        navigate(`/documents/sales-docs/${row}`);
    };

    function handleRefetch(doctype) {
        switch (doctype) {
            case 'Sales Invoice':
                return refetchSalesInvoices({});
            case 'POS Invoice':
                return refetchPosInvoices({});
            case 'Delivery Note':
                return refetchDeliveries({});
            case 'Sales Order':
                return refetchSalesOrders({});
            case 'Quotation':
                return refetchQuotations({});
            default:
                return;
        }
    }

    const lookup = {
        'ACC-SINV': 'Sales Invoice',
        'ACC-PSINV': 'POS Invoice',
        'MAT-DN': 'Delivery Note',
        'SAL-ORD': 'Sales Order',
        'SAL-QTN': 'Quotation'
    };

    function getDocType(name) {
        for (let key in lookup) {
            if (name.startsWith(key)) {
                return lookup[key];
            }
        }
        return 'Unknown';
    }

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const handleDelete = (row) => {
        fetch(`${process.env.REACT_APP_API_URI}/api/delete-doc`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doctype: getDocType(row[0]), name: row[0] })
        })
            .then((res) => {
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: 'An error has occurred' }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    dispatch(SetNotification({ code: 'success', message: `${getDocType(row[0])} successfully deleted` }));
                    handleRefetch(getDocType(row[0]));
                }
            });
    };

    useEffect(() => {
        if (!isPendingSalesOrders && !isPendingSalesInvoices && !isPendingPosInvoices && !isPendingDeliveries && !isPendingQuotations) {
            let result = [];
            salesOrders.forEach((el) => {
                result.push({ ...el, document: 'Sales Order' });
            });
            salesInvoices.forEach((el) => {
                result.push({ ...el, document: 'Sales Invoice' });
            });
            posInvoices.forEach((el) => {
                result.push({ ...el, document: 'POS Invoice' });
            });
            deliveries.forEach((el) => {
                result.push({ ...el, document: 'Delivery Note' });
            });
            quotations.forEach((el) => {
                result.push({ name: el.name, creation: el.creation, customer: el.title, status: el.status, document: 'Quotation' });
            });
            setData(result);
        }
    }, [isPendingSalesOrders, isPendingSalesInvoices, isPendingPosInvoices, isPendingDeliveries, isPendingQuotations]);

    return (
        <>
            <DataRender
                title={
                    <Box p={-1} display={'flex'} justifyContent={'space-between'}>
                        {t('documents:sd')}
                    </Box>
                }
                isViewAction
                data={sortObjectsByDate(data)}
                loading={
                    isPendingSalesOrders || isPendingSalesInvoices || isPendingPosInvoices || isPendingDeliveries || isPendingQuotations
                }
                selectionModel={selectionModel}
                setSelectionModel={setSelectionModel}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
            />
        </>
    );
}

export default SalesDocumentsList;
