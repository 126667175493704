import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import React from 'react';
import MainCard from 'ui-component/cards/MainCard';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { formatDate } from 'helpers/utils';
import { useTranslation } from 'react-i18next';

function InventoryListDetails({ doc }) {
    const { t } = useTranslation();
    return (
        <>
            <MainCard border isWhite boxShadow>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: 50 }}>
                    <div>
                        <Typography color={'GrayText'} variant="body2" fontWeight={500}>
                            {t('documents:comp')}:
                            <Typography color={'ActiveCaption'} variant="body2" fontWeight={700}>
                                {doc?.company}
                            </Typography>
                        </Typography>
                    </div>
                    <div>
                        <Typography color={'GrayText'} variant="body2" fontWeight={500}>
                            {t('documents:pdate')}:
                            <Typography color={'ActiveCaption'} variant="body2" fontWeight={700}>
                                {formatDate(doc?.created_at, 'DD-MM-YYYY')}
                            </Typography>
                        </Typography>
                        <Typography color={'GrayText'} variant="body2" fontWeight={500}>
                            {t('documents:tdate')}:
                            <Typography color={'ActiveCaption'} variant="body2" fontWeight={700}>
                                {formatDate(doc?.updated_at, 'DD-MM-YYYY')}
                            </Typography>
                        </Typography>
                    </div>
                    <div>
                        <Typography color={'GrayText'} variant="body2" fontWeight={500}>
                            {t('documents:warehouse')}:
                            <Typography color={'ActiveCaption'} variant="body2" fontWeight={700}>
                                {doc?.warehouse ?? '-'}
                            </Typography>
                        </Typography>
                        <Typography color={'GrayText'} variant="body2" fontWeight={500}>
                            {t('documents:createdby')}:
                            <Typography color={'ActiveCaption'} variant="body2" fontWeight={700}>
                                {doc?.owner}
                            </Typography>
                        </Typography>
                    </div>
                </div>
            </MainCard>
            <MainCard
                border
                sx={{ marginTop: 2 }}
                title={
                    <Box display={'flex'} alignItems={'center'}>
                        <Inventory2Icon sx={{ mr: 1 }} /> {t('documents:prods')}
                    </Box>
                }
                boxShadow
                divider
            >
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>N°</TableCell>
                            <TableCell>{t('documents:prod')}</TableCell>
                            <TableCell align="right">{t('documents:foundqty')}</TableCell>
                            <TableCell align="right">{t('documents:beforeqty')}</TableCell>
                            <TableCell align="right">{t('documents:diff')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {doc?.products?.map((el, i) => (
                            <TableRow key={i}>
                                <TableCell>
                                    <strong>{i + 1}</strong>
                                </TableCell>
                                <TableCell>
                                    <strong>{el?.item_code}</strong>
                                </TableCell>
                                <TableCell align="right">
                                    <strong>{el?.found_qty}</strong>
                                </TableCell>
                                <TableCell align="right">
                                    <strong>{el?.qty_before}</strong>
                                </TableCell>
                                <TableCell align="right">
                                    <strong>{+el?.qty_before - +el?.found_qty}</strong>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </MainCard>
        </>
    );
}

export default InventoryListDetails;
