import { Box } from '@mui/system';
import useGetAxios from 'hooks/useGetAxios';
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import MainCard from 'ui-component/cards/MainCard';
import Loader from 'ui-component/Loader';
import { useStyles } from 'views/utilities/Style';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InfoIcon from '@mui/icons-material/Info';
import { Chip, Divider, Grid, Typography, FormControlLabel, Checkbox, IconButton } from '@mui/material';
import InputTextField from 'ui-component/form/InputTextField';
import FormDateTime from 'ui-component/dateTime/FormDateTime';
import moment from 'moment';
import useFetch from 'hooks/useFetch';
import SelectForm from 'ui-component/form/SelectForm';
import { formatErpResponse } from 'utils/utils';
import PersonIcon from '@mui/icons-material/Person';
import { LoadingButton } from '@mui/lab';
import { SetMappedDeliveryNote, SetMappedSalesInvoice, SetNotification } from 'store/services/api';
import ReactToPrint from 'react-to-print';
import PrintIcon from '@mui/icons-material/Print';
import PrintInvoiceTemplate from 'views/invoices/sales/SalesInvoice/components/PrintInvoiceTemplate';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ProductTableWithoutPricing from 'ui-component/tables/ProductTableWithoutPricing';
import EmailSender from 'ui-component/emailSender/EmailSender';
import BundleItemsTable from 'views/products/productBundle/BundleItemsTable';
import html2pdf from 'html2pdf.js';
import { useBlocker } from 'hooks/useBlocker';
import NavigationModal from 'ui-component/cards/NavigationModal';
import Comments from 'ui-component/comments/Comments';
import { useTranslation } from 'react-i18next';

const BonLivraisonForm = () => {
    const classes = useStyles();
    const { id } = useParams();
    const { t } = useTranslation();
    const componentRef = useRef();
    const globalDefaults = useSelector((state) => state.login.globalDefaults);
    const mappedDeliveryNote = useSelector((state) => state.mappedDocs.mappedDeliveryNote);

    const [formIsDirty, setFormIsDirty] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    useBlocker(({ retry }) => {
        setModalOpen(true);
    }, formIsDirty);

    const handleCloseBlocker = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }
        setModalOpen(false);
        setFormIsDirty(false);
    };

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [editDate, setEditDate] = useState(false);

    const [mappedInvoiceLoadingButton, setMappedInvoiceLoadingButton] = useState(false);

    const [company, setCompany] = useState(globalDefaults?.default_company);

    const {
        data: doc,
        isPending,
        refetch: refetchDoc,
        docinfo
    } = useGetAxios(`${process.env.REACT_APP_API_URI}/api/details?doctype=Delivery%20Note&name=${id}`);

    const { data: currencies, isPending: isCurrenciesPending } = useFetch(
        `/api/get-list`,
        { doctype: 'Currency', fields: ['`tabCurrency`.`name`'], filters: [['Currency', 'enabled', '=', 1]] },
        'POST'
    );

    const { data: warehouses, isPending: isWarehousesPending } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Warehouse',
            fields: ['`tabWarehouse`.`name`'],
            filters: [
                ['Warehouse', 'is_group', '=', 0],
                ['Warehouse', 'disabled', '=', 0],
                ['Warehouse', 'company', '=', company]
            ]
        },
        'POST'
    );

    const { data: sellingPriceLists, isPending: isSellingPriceListsPending } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Price List',
            fields: ['`tabPrice List`.`name`'],
            filters: [
                ['Price List', 'selling', '=', 1],
                ['Price List', 'name', 'not like', '%ignore']
            ]
        },
        'POST'
    );

    const { data: taxesAndCharges, isPending: isTaxesAndChargesPending } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Sales Taxes and Charges Template',
            fields: ['`tabSales Taxes and Charges Template`.`name`'],
            filters: [['Sales Taxes and Charges Template', 'company', '=', company]]
        },
        'POST'
    );

    const {
        data: customers,
        isPending: isCustomersPending,
        refetch
    } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Customer',
            fields: ['`tabCustomer`.`name`', '`tabCustomer`.`mobile_no`'],
            filters: [['Customer', 'disabled', '=', 0]],
            order_by: '`tabCustomer`.`modified` desc'
        },
        'POST'
    );

    const [selectedItems, setSelectedItems] = useState([]);

    const [loadingButton, setLoadingButton] = useState(false);

    const [formState, setFormState] = useState({
        posting_date: moment().format('YYYY-MM-DD'),
        posting_time: moment().format('HH:mm:ss'),
        currency: globalDefaults?.default_currency,
        selling_price_list: '',
        set_warehouse: ''
    });

    const [addDiscountFormState, setAddDiscountFormState] = useState({
        coupon_code: '',
        additional_discount_percentage: 0,
        discount_amount: '',
        apply_discount_on: 'Grand Total'
    });

    const [customerFormState, setCustomerFormState] = useState({
        party_name: ''
    });
    const [taxFormState, setTaxFormState] = useState({
        taxes_and_charges: ''
    });
    const [customerDetails, setCustomerDetails] = useState(null);
    const [defaultTaxDetails, setDefaultTaxDetails] = useState(null);

    const [customerFormErrState, setCustomerFormErrState] = useState({});

    const [formErrState, setFormErrState] = useState({
        posting_date: false,
        posting_time: false,
        currency: false,
        selling_price_list: false,
        items: false
    });

    const handleChange = (e) => {
        setFormIsDirty(true);
        setFormState({ ...formState, [e.target.name]: e.target.value });
        // err handlers
        if (e.target.value == '') {
            setFormErrState({ ...formErrState, [e.target.name]: true });
        } else {
            setFormErrState({ ...formErrState, [e.target.name]: false });
        }
    };

    const handleDiscntChange = (e) => {
        setFormIsDirty(true);
        setAddDiscountFormState({ ...addDiscountFormState, [e.target.name]: e.target.value });
    };

    const handleCustomerChange = (e) => {
        setFormIsDirty(true);
        setCustomerFormState({ ...customerFormState, [e.target.name]: e.target.value });

        // err handlers
        if (e.target.value == '') {
            setCustomerFormErrState({ ...customerFormErrState, [e.target.name]: true });
        } else {
            setCustomerFormErrState({ ...customerFormErrState, [e.target.name]: false });
        }
    };
    const handleDefaultTaxChange = (e) => {
        setTaxFormState({ ...taxFormState, taxes_and_charges: e.target.value });

        const data = { master_doctype: 'Sales Taxes and Charges Template', master_name: e.target.value };
        if (e?.target?.value) {
            fetch(`${process.env.REACT_APP_API_URI}/api/get-taxes-template`, {
                method: 'POST',
                headers: header,
                body: JSON.stringify(data)
            })
                .then((res) => {
                    return res.json();
                })
                .then((data) => {
                    if (data?.message) {
                        setDefaultTaxDetails(data?.message);
                    }
                });
        }
    };

    const handleSaveDeliveryNote = () => {
        setFormIsDirty(false);
        setLoadingButton(true);
        let taxes = [];
        let itemTaxes = [];
        let items = [];

        selectedItems?.map((el) => {
            items.push({ ...el, warehouse: formState?.set_warehouse });
            if (el?.item_tax_rate && el?.item_tax_rate !== '{}') {
                itemTaxes.push(Object?.keys(JSON?.parse(el?.item_tax_rate))[0]);
            }
        });
        let uniqueItemTaxes = [...new Set(itemTaxes)];

        uniqueItemTaxes.map((el) => {
            taxes.push({
                doctype: 'Sales Taxes and Charges',
                charge_type: 'On Net Total',
                parentfield: 'taxes',
                parenttype: 'Delivery Note',
                description: el?.substring(0, el?.lastIndexOf(' ')),
                account_head: el
            });
        });

        const selectedTaxTemplate = {
            doctype: 'Sales Taxes and Charges',
            charge_type: defaultTaxDetails?.[0]?.charge_type,
            parentfield: 'taxes',
            parenttype: 'Delivery Note',
            description: defaultTaxDetails?.[0]?.description,
            account_head: defaultTaxDetails?.[0]?.account_head,
            tax_amount: defaultTaxDetails?.[0]?.tax_amount,
            rate: defaultTaxDetails?.[0]?.rate
        };

        let data = {
            doctype: 'Delivery Note',
            __unsaved: 1,
            __islocal: 1,
            company: company,
            currency: formState.currency,
            selling_price_list: formState.selling_price_list,
            ignore_pricing_rule: 1,
            apply_discount_on: addDiscountFormState.apply_discount_on,
            group_same_items: 0,
            items: items,
            posting_date: formState.posting_date,
            posting_time: formState.posting_time,
            lr_date: moment().format('YYYY-MM-DD'),
            customer: customerFormState?.party_name,
            customer_name: customerFormState?.party_name,
            taxes_and_charges: taxFormState?.taxes_and_charges,
            // customerDetails
            contact_email: customerDetails?.email_id,
            contact_mobile: customerDetails?.mobile_no,
            //customer_name: customerDetails?.customer_name,
            customer_group: customerDetails?.customer_group,
            territory: customerDetails?.territory,
            taxes: taxFormState?.taxes_and_charges ? [selectedTaxTemplate] : taxes,
            additional_discount_percentage: parseFloat(addDiscountFormState?.additional_discount_percentage),
            discount_amount: parseFloat(addDiscountFormState?.discount_amount),
            set_warehouse: formState?.set_warehouse,
            set_posting_time: 1
        };

        fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doc: data, action: 'Submit' })
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    dispatch(SetNotification({ code: 'success', message: 'Delivery Note successfully created' }));
                    dispatch(SetMappedDeliveryNote(null));
                    navigate('/sales/delivery-notes');
                }
            });
    };

    const handleCancelDeliveryNote = () => {
        setLoadingButton(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/cancel-doc`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doctype: 'Delivery Note', name: id })
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    dispatch(SetNotification({ code: 'success', message: 'Delivery Note successfully cancelled' }));
                    navigate('/sales/delivery-notes');
                }
            });
    };

    const makeMappedsalesInvoice = () => {
        setMappedInvoiceLoadingButton(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/make-mapped-doc`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ method: 'erpnext.stock.doctype.delivery_note.delivery_note.make_sales_invoice', source_name: id })
        })
            .then((res) => {
                setMappedInvoiceLoadingButton(false);
                return res.json();
            })
            .then((data) => {
                if (data?.message) {
                    dispatch(SetMappedSalesInvoice(data?.message));
                    navigate('/sales/tax-invoice');
                }
            });
    };

    useEffect(() => {
        if (id && doc?.name && taxesAndCharges.length !== 0) {
            setFormState({
                posting_date: doc?.posting_date,
                posting_time: moment(doc.posting_time, 'HH:mm:ss').format('HH:mm:ss'),
                currency: doc?.currency,
                selling_price_list: doc?.selling_price_list,
                set_warehouse: doc?.set_warehouse
            });
            setAddDiscountFormState({
                coupon_code: doc?.coupon_code,
                additional_discount_percentage: doc?.additional_discount_percentage,
                discount_amount: doc?.discount_amount,
                apply_discount_on: doc?.apply_discount_on
            });
            setCustomerFormState({
                party_name: doc?.customer_name
            });
            handleDefaultTaxChange({
                target: {
                    value: doc?.taxes_and_charges
                        ? doc?.taxes_and_charges
                        : doc?.taxes?.length === 1
                        ? taxesAndCharges?.find((obj) => obj?.name?.includes(doc?.taxes?.[0]?.account_head))?.name
                        : ''
                }
            });
        }
    }, [doc, taxesAndCharges]);

    useEffect(() => {
        if (mappedDeliveryNote && taxesAndCharges?.length !== 0) {
            setFormState({
                ...formState,
                currency: mappedDeliveryNote?.currency,
                selling_price_list: mappedDeliveryNote?.selling_price_list,
                set_warehouse: mappedDeliveryNote?.set_warehouse
            });
            setAddDiscountFormState({
                coupon_code: mappedDeliveryNote?.coupon_code,
                additional_discount_percentage: mappedDeliveryNote?.additional_discount_percentage,
                discount_amount: mappedDeliveryNote?.discount_amount,
                apply_discount_on: mappedDeliveryNote?.apply_discount_on
            });
            setCustomerFormState({
                party_name: mappedDeliveryNote?.customer_name
            });
            handleDefaultTaxChange({
                target: {
                    value: mappedDeliveryNote?.taxes_and_charges
                        ? mappedDeliveryNote?.taxes_and_charges
                        : mappedDeliveryNote?.taxes?.length === 1
                        ? taxesAndCharges?.find((obj) => obj?.name?.includes(mappedDeliveryNote?.taxes?.[0]?.account_head))?.name
                        : ''
                }
            });
        }
    }, [mappedDeliveryNote, taxesAndCharges]);

    useEffect(() => {
        if (customerFormState?.party_name) {
            fetch(`${process.env.REACT_APP_API_URI}/api/details?doctype=Customer&name=${customerFormState?.party_name}`, {
                method: 'GET',
                headers: header
            })
                .then((res) => {
                    return res.json();
                })
                .then((data) => {
                    if (data?.docs[0]) {
                        setCustomerDetails(data?.docs[0]);
                    }
                });
        }
    }, [customerFormState?.party_name]);

    const disableSaveButton = () => {
        if (
            selectedItems?.length === 0 ||
            !formState?.posting_date ||
            !formState?.posting_time ||
            !formState?.currency ||
            !formState?.selling_price_list ||
            !formState?.set_warehouse ||
            !customerFormState.party_name
        ) {
            return true;
        }
        return false;
    };

    const handleDownloadPdf = () => {
        const options = {
            filename: `${id}.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
        };
        const element = componentRef.current;

        html2pdf().from(element).set(options).save();
    };

    const title = (
        <Box p={-1} display={'flex'} justifyContent={'space-between'}>
            <Box p={-1} display={'flex'} flexDirection={'column'}>
                <ArrowBackIcon
                    className={classes.backLink}
                    onClick={() => {
                        navigate(-1);
                        dispatch(SetMappedDeliveryNote(null));
                    }}
                />
                <Box display={'flex'}>
                    {id ? `${t('core:bonlivraison')}: ${doc?.name}` : t('core:newdeliverynote')}
                    {id && <Chip size="small" color="warning" variant="outlined" sx={{ ml: 1 }} label={doc?.status} />}
                </Box>
            </Box>
            <Box>
                {doc?.status === 'To Bill' && (
                    <LoadingButton
                        startIcon={<ReceiptIcon color={'Menu'} />}
                        variant={'contained'}
                        color="blue"
                        sx={{ ml: 1 }}
                        onClick={makeMappedsalesInvoice}
                        loading={mappedInvoiceLoadingButton}
                    >
                        {`Make an Invoice`}
                    </LoadingButton>
                )}
                {doc?.name && (
                    <>
                        <ReactToPrint
                            trigger={() => (
                                <IconButton sx={{ ml: 1 }} aria-label="delete" color="blue">
                                    <PrintIcon />
                                </IconButton>
                            )}
                            content={() => componentRef.current}
                            bodyClass="print-body"
                            copyStyles={true}
                            documentTitle={id}
                            pageStyle={`
                                @page {
                                    size: A4;
                                    margin: 1cm;
                                }
                                @media print {
                                    body {
                                        background-color: #f5f5f5;
                                    }
                                    .content {
                                        page-break-before: always;
                                    }
                                    .footer {
                                        position: fixed;
                                        bottom: 0;
                                        left: 0;
                                        opacity: 0.7;
                                        right: 0;
                                        background-color: #f5f5f5;
                                    }
                                }
                            `}
                            removeAfterPrint={true}
                        />
                        {/* <IconButton onClick={handleDownloadPdf} aria-label="delete">
                            <PictureAsPdfIcon />
                        </IconButton> */}
                        <EmailSender doctype={'Sales Order'} name={id} recipientEmail={customerDetails?.email_id} />
                    </>
                )}
            </Box>
        </Box>
    );

    useEffect(() => {
        if (!id) {
            if (sellingPriceLists?.length) {
                setFormState({
                    ...formState,
                    selling_price_list: sellingPriceLists?.[0]?.name
                });
            }
        }
    }, [sellingPriceLists]);

    useEffect(() => {
        const taxWithFive = taxesAndCharges.filter((tax) => tax?.name?.includes('5%'));
        if (taxWithFive?.length) {
            handleDefaultTaxChange({
                target: {
                    value: taxWithFive[0]?.name
                }
            });
        }
    }, [taxesAndCharges]);

    if (id && isPending) return <Loader />;
    if (isCurrenciesPending || isWarehousesPending || isSellingPriceListsPending || isTaxesAndChargesPending || isCustomersPending)
        return <Loader />;
    return (
        <Box>
            <MainCard title={title}>
                <Box display={'flex'} mt={-4} mb={2}>
                    <InfoIcon sx={{ mt: 0.6 }} />
                    <Typography p={1} variant="h5" color="primary">
                        {t('core:deliverynotedesc')}
                    </Typography>
                </Box>
                <Grid container spacing={2}>
                    <Grid item xs={7}>
                        <Box mt={2} mb={2}>
                            <Divider>
                                <Chip label={t('core:Delivery-to')} />
                            </Divider>
                        </Box>
                        {!id && (
                            <FormControlLabel
                                sx={{ mt: 2 }}
                                label={t('core:checkboxdate')}
                                control={<Checkbox disabled={id} checked={editDate} onChange={() => setEditDate(!editDate)} />}
                            />
                        )}
                        <FormDateTime
                            required
                            type="date"
                            label={t('core:postdate')}
                            name="posting_date"
                            error={formErrState.posting_date}
                            value={formState.posting_date}
                            handleChange={handleChange}
                            disabled={!editDate}
                        />
                        <FormDateTime
                            sx={{ mt: -2 }}
                            required
                            type="time"
                            label={t('core:postingtime')}
                            name="posting_time"
                            error={formErrState.posting_time}
                            value={formState.posting_time}
                            handleChange={handleChange}
                            disabled={!editDate}
                        />
                        <SelectForm
                            sx={{ mt: -2 }}
                            label={t('products:warehouse')}
                            error={formErrState?.set_warehouse}
                            value={formState.set_warehouse}
                            handleChange={handleChange}
                            data={warehouses}
                            name="set_warehouse"
                            propToRender="name"
                            required
                            disabled={id}
                        />
                        {false && (
                            <>
                                <Box mt={2} mb={2}>
                                    <Divider>
                                        <Chip label="Currency & Price List" />
                                    </Divider>
                                </Box>
                                <SelectForm
                                    sx={{ mb: 2 }}
                                    label={'Currency'}
                                    error={formErrState?.currency}
                                    value={formState.currency}
                                    handleChange={handleChange}
                                    data={currencies}
                                    name="currency"
                                    propToRender="name"
                                    required
                                    disabled={id}
                                />
                                <SelectForm
                                    label={'Price List'}
                                    error={formErrState?.selling_price_list}
                                    value={formState.selling_price_list}
                                    handleChange={handleChange}
                                    data={sellingPriceLists}
                                    name="selling_price_list"
                                    propToRender="name"
                                    required
                                    disabled={id}
                                />
                            </>
                        )}
                        <Box mt={2} mb={2}>
                            <Divider>
                                <Chip label={t('core:TaxesandCharges')} />
                            </Divider>
                        </Box>
                        <SelectForm
                            sx={{ mb: 2 }}
                            label={t('core:TVA')}
                            value={taxFormState.taxes_and_charges}
                            handleChange={handleDefaultTaxChange}
                            data={taxesAndCharges}
                            name="taxes_and_charges"
                            propToRender="name"
                            disabled={id}
                        />
                        <Box mt={2} mb={2}>
                            <Divider>
                                <Chip label={t('core:AddDiscount')} />
                            </Divider>
                        </Box>
                        <InputTextField
                            sx={{ mb: 2 }}
                            isPercentage
                            type="number"
                            handleChange={handleDiscntChange}
                            value={addDiscountFormState.additional_discount_percentage}
                            label={t('core:AddDiscountt')}
                            name="additional_discount_percentage"
                            disabled={id}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <MainCard
                            title={
                                <Box display={'flex'}>
                                    <PersonIcon sx={{ mr: 1.5, ml: -1.5 }} />
                                    {t('core:Customer')}
                                </Box>
                            }
                            divider
                            border
                        >
                            <SelectForm
                                required
                                fullwidth
                                label={t('core:Customer')}
                                error={customerFormErrState?.party_name}
                                value={customerFormState?.party_name}
                                handleChange={handleCustomerChange}
                                data={customers}
                                name="party_name"
                                propToRender="name"
                                flagAddDefaultCustomer
                                refetch={refetch}
                                setFormState={setCustomerFormState}
                                disabled={id}
                            />
                            <Box mt={2} mb={2}>
                                <Divider>
                                    <Chip label={t('core:Customerdet')} />
                                </Divider>
                            </Box>
                            <Box mt={1}>
                                <Typography id="modal-modal-description" sx={{ fontSize: 14, mt: 1 }}>
                                    <strong>{t('core:Namecl')}:</strong>{' '}
                                    {customerDetails?.customer_name ? customerDetails?.customer_name : '-'}
                                </Typography>
                                <Typography id="modal-modal-description" sx={{ fontSize: 14, mt: 1 }}>
                                    <strong>{t('core:Phonecl')}:</strong> {customerDetails?.mobile_no ? customerDetails?.mobile_no : '-'}
                                </Typography>
                                <Typography id="modal-modal-description" sx={{ fontSize: 14, mt: 1 }}>
                                    <strong>{t('core:Addresscl')}:</strong>{' '}
                                    {customerDetails?.primary_address ? formatErpResponse(customerDetails?.primary_address) : '-'}
                                </Typography>
                            </Box>
                        </MainCard>
                    </Grid>
                </Grid>
                <Box mt={3}>
                    <ProductTableWithoutPricing
                        company={company}
                        selectedItems={selectedItems}
                        setSelectedItems={setSelectedItems}
                        priceList={formState.selling_price_list}
                        currency={formState.currency}
                        addDiscountFormState={addDiscountFormState}
                        initialState={mappedDeliveryNote ? mappedDeliveryNote?.items : id ? doc?.items : []}
                        uneditable={doc?.name ? true : false}
                        doctype={'Delivery Note'}
                        defaultTaxDetails={defaultTaxDetails}
                        setFormIsDirty={setFormIsDirty}
                    />
                </Box>
                {doc?.packed_items && doc?.packed_items?.length !== 0 && (
                    <Box mt={3}>
                        <BundleItemsTable packedItems={doc?.packed_items} currency={doc?.currency} />
                    </Box>
                )}
                {id && (
                    <Box mt={2}>
                        <Comments docinfo={docinfo} refetch={refetchDoc} doc={doc} />
                    </Box>
                )}
                <Box display={'flex'} justifyContent={'right'} mt={3}>
                    <LoadingButton
                        disabled={doc?.name ? false : disableSaveButton()}
                        loading={loadingButton}
                        variant="contained"
                        size="large"
                        color={doc?.name ? 'error' : 'blue'}
                        onClick={doc?.name ? handleCancelDeliveryNote : handleSaveDeliveryNote}
                    >
                        {doc?.name ? t('common:cancel') : t('crm:save')}
                    </LoadingButton>
                </Box>
            </MainCard>
            {doc?.name && (
                <Box display={'none'}>
                    <PrintInvoiceTemplate
                        ref={componentRef}
                        doc={doc}
                        docinfo={docinfo}
                        selectedItems={selectedItems}
                        customerDetails={customerDetails}
                        defaultTaxDetails={defaultTaxDetails}
                        isDeliveryNote
                    />
                </Box>
            )}
            <NavigationModal open={modalOpen} onClose={handleCloseBlocker} />
        </Box>
    );
};

export default BonLivraisonForm;
