import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import React from 'react';
import MainCard from 'ui-component/cards/MainCard';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { formatDate } from 'helpers/utils';
import { CurrencyFormatter } from 'utils/utils';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

function InventoryProductsDetails({ doc }) {
    const { t } = useTranslation();
    const globalDefaults = useSelector((state) => state.login.globalDefaults);
    return (
        <>
            <MainCard border isWhite boxShadow>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: 50 }}>
                    <div>
                        <Typography color={'GrayText'} variant="body2" fontWeight={500}>
                            {doc?.doctype === 'Material Request' ? t('documents:purp') : t('documents:setype')}:
                            <Typography color={'ActiveCaption'} variant="body2" fontWeight={700}>
                                {doc?.doctype === 'Material Request' ? doc?.material_request_type : doc?.stock_entry_type}
                            </Typography>
                        </Typography>
                        {doc?.doctype === 'Stock Entry' ? (
                            <Typography color={'GrayText'} variant="body2" fontWeight={500}>
                                {t('documents:purp')}:
                                <Typography color={'ActiveCaption'} variant="body2" fontWeight={700}>
                                    {doc?.purpose}
                                </Typography>
                            </Typography>
                        ) : null}
                    </div>
                    <div>
                        {doc?.doctype === 'Material Request' ? (
                            <>
                                <Typography color={'GrayText'} variant="body2" fontWeight={500}>
                                    {t('documents:reqby')}:
                                    <Typography color={'ActiveCaption'} variant="body2" fontWeight={700}>
                                        {formatDate(doc?.transaction_date, 'DD-MM-YYYY')}
                                    </Typography>
                                </Typography>
                                <Typography color={'GrayText'} variant="body2" fontWeight={500}>
                                    {t('documents:tdate')}:
                                    <Typography color={'ActiveCaption'} variant="body2" fontWeight={700}>
                                        {formatDate(doc?.schedule_date, 'DD-MM-YYYY')}
                                    </Typography>
                                </Typography>
                            </>
                        ) : (
                            <>
                                <Typography color={'GrayText'} variant="body2" fontWeight={500}>
                                    {t('documents:pdate')}:
                                    <Typography color={'ActiveCaption'} variant="body2" fontWeight={700}>
                                        {formatDate(doc?.posting_date, 'DD-MM-YYYY')}
                                    </Typography>
                                </Typography>
                                <Typography color={'GrayText'} variant="body2" fontWeight={500}>
                                    {t('documents:ptime')}:
                                    <Typography color={'ActiveCaption'} variant="body2" fontWeight={700}>
                                        {doc?.posting_time}
                                    </Typography>
                                </Typography>
                            </>
                        )}
                    </div>
                    {doc?.doctype === 'Stock Entry' && doc?.process_loss_percentage ? (
                        <div>
                            <Typography color={'GrayText'} variant="body2" fontWeight={500}>
                                Process Loss (%):
                                <Typography color={'ActiveCaption'} variant="body2" fontWeight={700}>
                                    {doc?.process_loss_percentage?.toFixed(1)} %
                                </Typography>
                            </Typography>
                            <Typography color={'GrayText'} variant="body2" fontWeight={500}>
                                Process Loss Qty:
                                <Typography color={'ActiveCaption'} variant="body2" fontWeight={700}>
                                    {doc?.process_loss_qty}
                                </Typography>
                            </Typography>
                        </div>
                    ) : null}
                </div>
            </MainCard>
            <MainCard
                border
                sx={{ marginTop: 2 }}
                title={
                    <Box display={'flex'} alignItems={'center'}>
                        <Inventory2Icon sx={{ mr: 1 }} /> {t('documents:prods')}
                    </Box>
                }
                boxShadow
                divider
            >
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>N°</TableCell>
                            <TableCell>{t('documents:prod')}</TableCell>
                            <TableCell align="right">
                                {doc?.doctype === 'Stock Entry' ? t('documents:twarehouse') : t('documents:reqby')}
                            </TableCell>
                            <TableCell align="right">{t('documents:desc')}</TableCell>
                            <TableCell align="right">{t('documents:itemgroup')}</TableCell>
                            <TableCell align="right">{t('documents:qty')}</TableCell>
                            <TableCell align="right">
                                {doc?.doctype === 'Stock Entry' ? t('documents:vrate') : t('documents:twarehouse')}
                            </TableCell>
                            {doc?.doctype === 'Stock Entry' ? <TableCell align="right">{t('documents:amnt')}</TableCell> : null}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {doc?.items?.map((el, i) => (
                            <TableRow key={i}>
                                <TableCell>
                                    <strong>{el?.idx}</strong>
                                </TableCell>
                                <TableCell>
                                    <strong>
                                        {el?.item_code}: {el?.item_name}
                                    </strong>
                                </TableCell>
                                <TableCell align="right">
                                    <strong>{doc?.doctype === 'Stock Entry' ? el?.t_warehouse : el?.schedule_date}</strong>
                                </TableCell>
                                <TableCell align="right">
                                    <strong>{el?.description}</strong>
                                </TableCell>
                                <TableCell align="right">
                                    <strong>{el?.item_group}</strong>
                                </TableCell>
                                <TableCell align="right">
                                    <strong>
                                        {el?.qty} {el?.stock_uom}
                                    </strong>
                                </TableCell>
                                <TableCell align="right">
                                    <strong>
                                        {doc?.doctype === 'Stock Entry' ? (
                                            <CurrencyFormatter
                                                locale="fr-FR"
                                                minimumFractionDigits={3}
                                                value={el?.valuation_rate}
                                                currency={globalDefaults?.default_currency}
                                            />
                                        ) : (
                                            el?.warehouse
                                        )}
                                    </strong>
                                </TableCell>
                                {doc?.doctype === 'Stock Entry' ? (
                                    <TableCell align="right">
                                        <strong>
                                            <CurrencyFormatter
                                                locale="fr-FR"
                                                minimumFractionDigits={3}
                                                value={el?.amount}
                                                currency={globalDefaults?.default_currency}
                                            />
                                        </strong>
                                    </TableCell>
                                ) : null}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {doc?.doctype === 'Stock Entry' ? (
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'right' }}>
                        <div
                            style={{
                                margin: 10,
                                width: '50%',
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'row',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Typography variant="caption" color={'primary'}>
                                {t('documents:ttldiff')}:{' '}
                                <strong>
                                    <CurrencyFormatter
                                        locale="fr-FR"
                                        minimumFractionDigits={3}
                                        value={doc?.value_difference}
                                        currency={globalDefaults?.default_currency}
                                    />
                                </strong>
                                <br /> {t('documents:ttlamount')}:{' '}
                                <strong>
                                    <CurrencyFormatter
                                        locale="fr-FR"
                                        minimumFractionDigits={3}
                                        value={doc?.total_amount}
                                        currency={globalDefaults?.default_currency}
                                    />
                                </strong>
                            </Typography>
                            <Typography variant="caption" color={'primary'}>
                                {t('documents:isopen')}: <strong>{doc?.is_opening}</strong>
                            </Typography>
                        </div>
                    </div>
                ) : null}
            </MainCard>
        </>
    );
}

export default InventoryProductsDetails;
