import { useNavigate } from 'react-router';
import React, { useState } from 'react';
import useFetch from '../../../../hooks/useFetch';
import { Box } from '@mui/system';
import { modalStyle } from '../../../utilities/ModalStyle';
import MainCard from '../../../../ui-component/cards/MainCard';
import { Button, Grid, Modal, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DataRender from '../../../../ui-component/tables/DataRender';
import { SetNotification } from '../../../../store/services/api';
import { useDispatch } from 'react-redux';
import { formatErpResponse } from '../../../../utils/utils';
import { useTranslation } from 'react-i18next';

const ServicePrestationList = () => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const { data, isPending, refetch } = useFetch(`api/get-service-prestation`, [], 'POST');
    console.log(data);
    const handleEdit = (row) => {
        navigate(`/pim/edit-service-provision/${row}`);
    };
    const deleteConfirm = (
        <Box sx={modalStyle}>
            <MainCard divider title={'common:confirm'}>
                <Typography id="modal-modal-description" sx={{ fontSize: 14 }}>
                    {'common:deletePermanently'} <strong>{selectionModel}</strong> ?
                </Typography>
                <Box display={'flex'} justifyContent={'flex-end'} mt={3}>
                    <Box>
                        <Grid container spacing={1}>
                            <Grid item>
                                <Button color="blue" size="large" variant="outlined" onClick={handleClose}>
                                    {t('common:non')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <LoadingButton
                                    color="blue"
                                    size="large"
                                    //onClick={() => handleDelete(selectionModel)}
                                    variant="contained"
                                    loading={loadingButton}
                                >
                                    {t('common:oui')}
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </MainCard>
        </Box>
    );
    const handleDelete = (row) => {
        const header = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem('user'))?.token
        };
        setLoadingButton(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/delete-doc`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doctype: 'Service', name: row[0] })
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    dispatch(SetNotification({ code: 'success', message: `Service a été supprimée avec succès 55555` }));
                    refetch({});
                    handleClose();
                }
            });
    };
    const title = (
        <Box p={-1} sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
            {'Service'}
            <Box>
                <Grid container spacing={1}>
                    {false}
                    <Grid item>
                        <Button
                            startIcon={<AddCircleIcon />}
                            variant="outlined"
                            color="blue"
                            onClick={() => navigate('/pim/add-service-provision')}
                            sx={{ marginTop: -3 }}
                        >
                            {'Ajouter Service'}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );

    return (
        <div>
            <DataRender
                refetch={refetch}
                data={data}
                loading={isPending}
                title={title}
                // setSelectionModel={setSelectionModel}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                notshow={['name']}
            />
            <Modal onClose={handleClose} open={open}>
                {deleteConfirm}
            </Modal>
        </div>
    );
};
export default ServicePrestationList;
