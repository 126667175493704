import React from 'react';
import MainCard from 'ui-component/cards/MainCard';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useStyles } from 'views/utilities/Style';
import { CurrencyFormatter } from 'utils/utils';
import { useTranslation } from 'react-i18next';

function ProductsOrderDetails({ doc, isSales }) {
    const { t } = useTranslation();
    const classes = useStyles();
    return (
        <MainCard
            border
            title={
                <Box display={'flex'} alignItems={'center'}>
                    <Inventory2Icon sx={{ mr: 1 }} /> {t('documents:prods')}
                </Box>
            }
            boxShadow
            divider
        >
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>N°</TableCell>
                        <TableCell>{t('documents:prod')}</TableCell>
                        <TableCell align="right">{t('documents:qty')}</TableCell>
                        <TableCell align="right">{t('documents:disc')}</TableCell>
                        <TableCell align="right">{t('documents:rate')}</TableCell>
                        <TableCell align="right">{t('documents:amnt')}</TableCell>
                        {/* {isSales ? <TableCell align="center">Grand Commission</TableCell> : <TableCell align="center">Apply TDS</TableCell>} */}
                        <TableCell align="right">{t('documents:warehouse')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {doc?.items?.map((el, i) => (
                        <TableRow key={i}>
                            <TableCell>
                                <strong>{el?.idx}</strong>
                            </TableCell>
                            <TableCell>
                                <strong>
                                    {el?.item_code}: {el?.item_name}
                                </strong>
                            </TableCell>
                            <TableCell align="right">
                                <strong>
                                    {el?.qty} {el?.stock_uom}
                                </strong>
                            </TableCell>
                            <TableCell align="right">
                                <strong>
                                    <CurrencyFormatter
                                        locale="fr-FR"
                                        minimumFractionDigits={3}
                                        value={el?.discount_amount}
                                        currency={doc?.currency}
                                    />
                                </strong>
                            </TableCell>
                            <TableCell align="right">
                                <strong>
                                    <CurrencyFormatter locale="fr-FR" minimumFractionDigits={3} value={el?.rate} currency={doc?.currency} />
                                </strong>
                            </TableCell>
                            <TableCell align="right">
                                <strong>
                                    <CurrencyFormatter
                                        locale="fr-FR"
                                        minimumFractionDigits={3}
                                        value={el?.amount}
                                        currency={doc?.currency}
                                    />
                                </strong>
                            </TableCell>
                            {/* <TableCell align="center">
                                <strong>
                                    {el?.grant_commission === 1 || el?.apply_tds === 1 ? <span>&#10003;</span> : <span>&#10060;</span>}{' '}
                                </strong>
                            </TableCell> */}
                            <TableCell align="right">
                                <strong>{el?.warehouse}</strong>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'right' }}>
                <div
                    style={{
                        margin: 10,
                        width: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                    }}
                >
                    <Typography variant="caption" color={'primary'}>
                        {t('documents:ttlQty')}: {doc?.total_qty}
                    </Typography>
                    <Typography variant="caption" color={'primary'}>
                        Total: <CurrencyFormatter locale="fr-FR" minimumFractionDigits={3} value={doc?.total} currency={doc?.currency} />
                        {/* {doc?.tax_withholding_net_total ? (
                            <>
                                <br /> Tax Withholding Net Total:{' '}
                                <CurrencyFormatter
                                    locale="fr-FR"
                                    minimumFractionDigits={3}
                                    value={doc?.tax_withholding_net_total}
                                    currency={doc?.currency}
                                />
                            </>
                        ) : null} */}
                    </Typography>
                </div>
            </div>

            <div className={classes.orderDetailsContainer}>
                {doc?.taxes?.map((taxe, i) => (
                    <React.Fragment key={i}>
                        <div className={classes.orderDetailsRow}>
                            <div className={classes.orderDetailsBold}>{taxe?.description}</div>
                            <div className={classes.orderDetails}>
                                <CurrencyFormatter
                                    locale="fr-FR"
                                    minimumFractionDigits={3}
                                    value={taxe?.tax_amount}
                                    currency={doc?.currency}
                                />
                            </div>
                        </div>
                    </React.Fragment>
                ))}
                <div className={classes.orderDetailsRow}>
                    <div className={classes.orderDetailsBold}>{t('documents:ada')}</div>
                    <div className={classes.orderDetails}>
                        -{' '}
                        <CurrencyFormatter locale="fr-FR" minimumFractionDigits={3} value={doc?.discount_amount} currency={doc?.currency} />
                    </div>
                </div>
                <div className={classes.orderDetailsRow}>
                    <Typography color={'primary'} className={classes.orderDetailsBold}>
                        {t('documents:gt')}:
                    </Typography>
                    <Typography color={'primary'} className={classes.orderDetailsBold}>
                        <CurrencyFormatter locale="fr-FR" minimumFractionDigits={3} value={doc?.grand_total} currency={doc?.currency} />
                    </Typography>
                </div>
                <div className={classes.orderDetailsRow}>
                    <div className={classes.orderDetailsBold}>{t('documents:rt')}:</div>
                    <div className={classes.orderDetails}>
                        <CurrencyFormatter locale="fr-FR" minimumFractionDigits={3} value={doc?.rounded_total} currency={doc?.currency} />
                    </div>
                </div>
                <div className={classes.orderDetailsRowStart}>
                    <div className={classes.orderDetailsBold}>{t('documents:words')}:</div>
                    <div className={classes.orderDetails}>{doc?.in_words}</div>
                </div>
                {doc?.motif ? (
                    <div className={classes.orderDetailsRowStart}>
                        <div className={classes.orderDetailsBold}>{t('documents:motif')}:</div>
                        <div className={classes.orderDetails}>{doc?.motif}</div>
                    </div>
                ) : null}
                {/* {doc?.amount_eligible_for_commission ? (
                    <div className={classes.orderDetailsRowStart}>
                        <div className={classes.orderDetailsBold}>Amount Eligible for Commission:</div>
                        <div className={classes.orderDetails}>
                            <CurrencyFormatter
                                locale="fr-FR"
                                minimumFractionDigits={3}
                                value={doc?.amount_eligible_for_commission}
                                currency={doc?.currency}
                            />
                        </div>
                    </div>
                ) : null} */}
            </div>
        </MainCard>
    );
}

export default ProductsOrderDetails;
