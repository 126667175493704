import React, { useMemo } from 'react';
import { Modal, Box, Typography, Button, Divider, IconButton, Grid, Paper } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import useFetch from 'hooks/useFetch';

const TranslationsGender = {
    'Prefer not to say': 'Préfère ne pas répondre',
    'Non-Conforming': 'Non-conforme',
    Genderqueer: 'Genderqueer',
    Transgender: 'Transgenre',
    Other: 'Autre',
    Female: 'Femme',
    Male: 'Homme'
};

const TranslationsContract = {
    Apprentice: 'Apprenti',
    Intern: 'Stagiaire',
    Piecework: 'Travail à la pièce',
    Commission: 'Commission',
    Contract: 'Contrat',
    Probation: "Période d'essai",
    'Part-time': 'Temps partiel',
    'Full-time': 'Temps plein'
};

const DetailModal = ({ open, onClose, row }) => {
    const theme = useTheme();
    const { t } = useTranslation();

    const TranslationsShift = {
        Morning: t('core:morning'),
        Evening: t('core:evening'),
        Night: t('core:night'),
        Custom: t('core:custom')
    };

    const { data: SalesPersons, isPending: SalesPersonsPending } = useFetch(
        `/api/get-list`,
        { doctype: 'Sales Person', fields: ['*'] },
        'POST'
    );

    const filteredSalesPerson = useMemo(() => {
        if (!SalesPersons || !row) return null;
        return SalesPersons.find((fsalesPerson) => fsalesPerson.name === row.name);
    }, [SalesPersons, row]);

    if (!row) return null;

    const translationGender = (gender) => TranslationsGender[gender] || gender;
    const translationContract = (type) => TranslationsContract[type] || type;
    const translationShift = (shift) => TranslationsShift[shift] || shift;

    const details = [
        { label: 'core:spname', value: filteredSalesPerson?.sales_person_name },
        { label: 'core:spcode', value: filteredSalesPerson?.sales_person_code },
        { label: 'core:gender', value: translationGender(filteredSalesPerson?.gender) },
        { label: 'core:enabled', value: row?.enabled ? t('common:oui') : t('common:non') },
        { label: 'core:contract-type', value: translationContract(filteredSalesPerson?.employment_type) },
        { label: 'core:compname', value: filteredSalesPerson?.company },
        { label: 'core:grade', value: filteredSalesPerson?.grade },
        { label: 'core:cin', value: filteredSalesPerson?.cin },
        { label: 'crm:Prefered_Email', value: filteredSalesPerson?.prefered_email },
        { label: 'crm:Personal_Email', value: filteredSalesPerson?.personal_email },
        { label: 'crm:Date_of_Joining', value: filteredSalesPerson?.date_of_joining },
        { label: 'crm:birth_date', value: filteredSalesPerson?.date_of_birth },
        { label: 'columns:mobileNo', value: filteredSalesPerson?.mobile },
        { label: 'core:shift_type', value: translationShift(filteredSalesPerson?.shift_type) },
        { label: 'core:shift_start_time', value: filteredSalesPerson?.shift_start_time },
        { label: 'core:shift_end_time', value: filteredSalesPerson?.shift_end_time }
    ];

    return (
        <Modal open={open} onClose={onClose}>
            <Paper
                elevation={24}
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '80%',
                    maxWidth: 1000,
                    maxHeight: '90vh',
                    overflow: 'auto',
                    bgcolor: 'background.paper',
                    borderRadius: 2
                }}
            >
                <Box sx={{ p: 3 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                        <Typography variant="h5" component="h2" fontWeight="bold" color="primary">
                            {t('common:consultation')} {row.name}
                        </Typography>
                        <IconButton onClick={onClose} size="large" sx={{ color: 'grey.500' }}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Divider sx={{ mb: 3 }} />
                    <Grid container spacing={3}>
                        {details.map(({ label, value }) => (
                            <Grid item xs={12} sm={6} key={label}>
                                <Box
                                    sx={{
                                        bgcolor: 'grey.50',
                                        p: 2,
                                        borderRadius: 1,
                                        border: 1,
                                        borderColor: 'grey.200'
                                    }}
                                >
                                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                        {t(label)}
                                    </Typography>
                                    <Typography variant="body1" fontWeight="medium">
                                        {value || '-'}
                                    </Typography>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                    <Box display="flex" justifyContent="flex-end" mt={4}>
                        <Button
                            variant="contained"
                            onClick={onClose}
                            sx={{
                                bgcolor: theme.palette.primary.main,
                                '&:hover': {
                                    bgcolor: theme.palette.primary.dark
                                },
                                px: 4,
                                py: 1
                            }}
                        >
                            {t('core:closebutton')}
                        </Button>
                    </Box>
                </Box>
            </Paper>
        </Modal>
    );
};

export default DetailModal;
