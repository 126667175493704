import { Box, Button, Grid, IconButton, OutlinedInput, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import React, { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';
import { useTranslation } from 'react-i18next';
import SelectForm from 'ui-component/form/SelectForm';

function ReorderForm({ reorderState, setReorderState, field, warehouses, disabled }) {
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setRow2Insert({
            doctype: 'Item Reorder',
            material_request_type: '',
            parentfield: 'reorder_levels',
            parenttype: 'Item',
            warehouse: '',
            warehouse_reorder_level: 0,
            warehouse_reorder_qty: 0
        });
    };

    const [row2Insert, setRow2Insert] = useState({
        doctype: 'Item Reorder',
        material_request_type: '',
        parentfield: 'reorder_levels',
        parenttype: 'Item',
        warehouse: '',
        warehouse_reorder_level: 0,
        warehouse_reorder_qty: 0
    });

    const handleChangeValues = (e) => {
        setRow2Insert({ ...row2Insert, [e.target.name]: e.target.value });
    };

    const handleInsert = () => {
        setReorderState([...reorderState, row2Insert]);
        handleClose();
    };

    const handleDelete = (el) => {
        setReorderState(reorderState.filter((row) => row.warehouse !== el.warehouse));
    };

    const updateWarehouseAvailability = (warehouses) => {
        if (reorderState?.length) {
            return warehouses?.filter((warehouse) => !reorderState?.some((state) => state?.warehouse === warehouse?.name));
        }
        return warehouses;
    };

    return (
        <div style={{ padding: 20 }}>
            <Box>
                <Grid container justifyContent={'space-between'} display={'flex'} alignItems={'center'}>
                    <Typography fontWeight={600} variant="h4">
                        {t('products:ReorderLevels')}
                    </Typography>
                    <Button color="blue" onClick={handleOpen} disabled={disabled}>
                        <AddIcon sx={{ marginLeft: -1 }} /> {t('core:addN')}
                    </Button>
                </Grid>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <strong> {t('products:warehouse')}</strong>
                            </TableCell>
                            <TableCell>
                                <strong> {t('products:reOrderLevel')}</strong>
                            </TableCell>
                            <TableCell>
                                <strong> {t('products:reOrderQty')}</strong>
                            </TableCell>
                            <TableCell>
                                <strong> {t('products:materialRequestType')}</strong>
                            </TableCell>
                            <TableCell align="right" width={50}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reorderState?.map((el, i) => (
                            <TableRow key={i}>
                                <TableCell>
                                    <strong>{el.warehouse}</strong>
                                </TableCell>
                                <TableCell>{el.warehouse_reorder_level}</TableCell>
                                <TableCell>{el.warehouse_reorder_qty}</TableCell>
                                <TableCell>{el.material_request_type}</TableCell>
                                <TableCell align="right" width={50}>
                                    <IconButton disabled={disabled} onClick={() => handleDelete(el)}>
                                        <DeleteIcon color="error" />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                        {(open || reorderState?.length === 0) && !disabled && (
                            <TableRow key={999}>
                                <TableCell>
                                    <SelectForm
                                        fullwidth
                                        handleChange={handleChangeValues}
                                        value={row2Insert.warehouse}
                                        name="warehouse"
                                        data={updateWarehouseAvailability(warehouses)}
                                        propToRender={'name'}
                                    />
                                </TableCell>
                                <TableCell>
                                    <OutlinedInput
                                        type="number"
                                        value={row2Insert.warehouse_reorder_level}
                                        onChange={handleChangeValues}
                                        name="warehouse_reorder_level"
                                        placeholder={'Re-order Level'}
                                    />
                                </TableCell>
                                <TableCell>
                                    <OutlinedInput
                                        type="number"
                                        value={row2Insert.warehouse_reorder_qty}
                                        onChange={handleChangeValues}
                                        name="warehouse_reorder_qty"
                                        placeholder={'Re-order Qty'}
                                    />
                                </TableCell>
                                <TableCell>
                                    <SelectForm
                                        fullwidth
                                        handleChange={handleChangeValues}
                                        value={row2Insert.material_request_type}
                                        name="material_request_type"
                                        data={['Purchase', 'Transfer', 'Material Issue', 'Manufacture']}
                                    />
                                </TableCell>
                                <TableCell align="right" width={100}>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <IconButton
                                            onClick={handleInsert}
                                            disabled={
                                                !row2Insert.warehouse ||
                                                !row2Insert.material_request_type ||
                                                !row2Insert.warehouse_reorder_qty
                                            }
                                        >
                                            <DoneIcon
                                                color={
                                                    !row2Insert.warehouse ||
                                                    !row2Insert.material_request_type ||
                                                    !row2Insert.warehouse_reorder_qty
                                                        ? 'disabled'
                                                        : ''
                                                }
                                            />
                                        </IconButton>
                                        <IconButton onClick={handleClose}>
                                            <DeleteIcon color="error" />
                                        </IconButton>
                                    </div>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Box>
        </div>
    );
}

export default ReorderForm;
