import React, { useMemo } from 'react';
import { Modal, Box, Typography, Button, Divider, IconButton, Grid, Paper } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import useFetch from 'hooks/useFetch';

const DetailModal = ({ open, onClose, row }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const { data: itemdefault, isPending: ItemDefaultPending } = useFetch(
        `/api/get-list`,
        { doctype: 'Item Default', fields: ['*'], filters: [['Item Default', 'parentfield', '=', 'brand_defaults']] },
        'POST'
    );

    const filteredItemDefault = useMemo(() => {
        if (!itemdefault || !row) return null;
        return itemdefault.find((item) => item.parent === row.name);
    }, [itemdefault, row]);

    const { data: brands, isPending: BrandsPending } = useFetch(`/api/get-list`, { doctype: 'Brand', fields: ['*'] }, 'POST');

    const filteredBrand = useMemo(() => {
        if (!brands || !row) return null;
        return brands.find((fbrand) => fbrand.name === row.name);
    }, [brands, row]);

    if (!row) return null;

    const details = [
        { label: 'common:brand_code', value: filteredBrand?.code_brand },
        { label: 'products:brand_name', value: row.brand || row.name },
        { label: 'common:description', value: row.description },
        { label: 'crm:company', value: filteredItemDefault?.company },
        { label: 'products:default_warehouse', value: filteredItemDefault?.default_warehouse },
        { label: 'products:default_price_list', value: filteredItemDefault?.default_price_list }
    ];

    return (
        <Modal open={open} onClose={onClose}>
            <Paper
                elevation={24}
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '80%',
                    maxWidth: 600,
                    maxHeight: '90vh',
                    overflow: 'auto',
                    bgcolor: 'background.paper',
                    borderRadius: 2
                }}
            >
                <Box sx={{ p: 3 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                        <Typography variant="h5" component="h2" fontWeight="bold" color="primary">
                            {t('common:consultation')} {row.name}
                        </Typography>
                        <IconButton onClick={onClose} size="large" sx={{ color: 'grey.500' }}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Divider sx={{ mb: 3 }} />
                    <Grid container spacing={3}>
                        {details.map(({ label, value }) => (
                            <Grid item xs={12} sm={6} key={label}>
                                <Box
                                    sx={{
                                        bgcolor: 'grey.50',
                                        p: 2,
                                        borderRadius: 1,
                                        border: 1,
                                        borderColor: 'grey.200'
                                    }}
                                >
                                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                        {t(label)}
                                    </Typography>
                                    <Typography variant="body1" fontWeight="medium">
                                        {value || '-'}
                                    </Typography>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                    <Box display="flex" justifyContent="flex-end" mt={4}>
                        <Button
                            variant="contained"
                            onClick={onClose}
                            sx={{
                                bgcolor: theme.palette.primary.main,
                                '&:hover': {
                                    bgcolor: theme.palette.primary.dark
                                },
                                px: 4,
                                py: 1
                            }}
                        >
                            {t('core:closebutton')}
                        </Button>
                    </Box>
                </Box>
            </Paper>
        </Modal>
    );
};

export default DetailModal;
