// action - state management
import * as actionTypes from '../actions';

export const initialState = {
    warehouses: [],
    users: []
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const warehouseReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_WAREHOUSES:
            return {
                ...state,
                warehouses: action.depot
            };
        case actionTypes.SET_USERS:
            return {
                ...state,
                users: action.data
            };
        default:
            return state;
    }
};

export default warehouseReducer;
