import { LoadingButton } from '@mui/lab';
import { Grid, Checkbox, FormControlLabel, TextField, Button } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { useStyles } from 'views/utilities/Style';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MainCard from 'ui-component/cards/MainCard';
import InputTextField from 'ui-component/form/InputTextField';
import SelectForm from 'ui-component/form/SelectForm';
import useGetAxios from 'hooks/useGetAxios';
import useFetch from 'hooks/useFetch';
import Loader from 'ui-component/Loader';
import { SetNotification } from 'store/services/api';
import { useEffect } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { SetDoc2Duplicate } from 'store/services/api';
import AutoCompleteField from '../../ui-component/form/AutoCompleteField';

const EditTarif = () => {
    const classes = useStyles();
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { data: doc, isPending } = useGetAxios(`${process.env.REACT_APP_API_URI}/api/details?doctype=Price%20List&name=${id}`);
    const [loadingButton, setLoadingButton] = useState(false);
    const [currency, setCurrency] = useState('');
    const [price_code, setPriceCode] = useState('');
    const [selling, setSelling] = useState(true);
    const [uomDependent, setUomDependent] = useState(false);
    const [price_list_name, setPriceListName] = useState('');
    const [country, setCountry] = useState();
    const [enabled, setEnabled] = useState(true);
    const [countries, setCountries] = useState();
    const [state, setState] = useState({
        country: []
    });

    const [error, setError] = React.useState({
        country: false
    });

    const { data: currencyList, isPending: isCurrencyPending } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Currency',
            fields: ['`tabCurrency`.`name`', '`tabCurrency`.`currency_name`'],
            filters: [['Currency', 'enabled', '=', 1]]
        },
        'POST'
    );
    const { data: countryList, isPending: isCountryPending } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Country',
            fields: ['`tabCountry`.`name`']
        },
        'POST'
    );
    useEffect(() => {
        // Initialize state when doc is available
        if (doc) {
            setPriceListName(doc.price_list_name);
            setPriceCode(doc.price_code || '');
            setCurrency(doc.currency);
            setSelling(doc.selling);
            setUomDependent(doc.price_not_uom_dependent !== undefined ? doc.price_not_uom_dependent : false);
            setEnabled(doc.enabled !== undefined ? doc.enabled : true);
            if (doc.countries) {
                const countryNames = doc.countries.map((country) => country.country);
                setState({ country: countryNames.map((name) => ({ name })) });
            }
        }
    }, [doc]);

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };
    useEffect(() => {
        state.country.map((country) => {
            setCountries(
                state.country.map((country) => ({
                    doctype: 'Price List Country',
                    country: country.name,
                    parentfield: 'countries',
                    parenttype: 'Price List'
                }))
            );
        });
    }, [state.country]);

    const handleAddTarif = async () => {
        setLoadingButton(true);

        const requestData = {
            doc: {
                ...(!id && { __islocal: 1 }),
                ...(id && {
                    creation: doc.creation,
                    modified: doc.modified,
                    owner: doc.owner,
                    modified_by: doc.owner,
                    name: doc.name
                }),
                doctype: 'Price List',
                currency: currency,
                countries: countries,
                selling: selling,
                price_list_name: price_list_name,
                price_code: price_code,
                price_not_uom_dependent: uomDependent,
                enabled: enabled,
                __unsaved: 1
            },
            action: 'Save'
        };

        fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(requestData)
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                if (price_list_name !== doc.price_list_name) {
                    const dataFormRenameTitleCollection = {
                        doctype: 'Price List',
                        docname: doc.price_list_name,
                        new_name: price_list_name
                    };

                    return fetch(`${process.env.REACT_APP_API_URI}/api/rename-doc`, {
                        method: 'POST',
                        headers: header,
                        body: JSON.stringify(dataFormRenameTitleCollection)
                    });
                }
            })
            .then((response) => {
                if (response && !response.ok) {
                    throw new Error('Network response was not ok');
                }

                setLoadingButton(false);
                dispatch(SetNotification({ code: 'success', message: t('common:msg_modif_type_tarif_vente') }));
                return navigate('/pim/tarifs');
            })
            .catch((error) => {
                dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                console.error('Error:', error);
            });
    };

    const handleChangeSelectmultiple = (event) => {
        setError({ ...error, [event.target.name]: false });
        setState({ ...state, [event.target.name]: event.target.value });
    };

    const title = (
        <Box p={-1} display={'flex'} flexDirection={'column'}>
            <ArrowBackIcon className={classes.backLink} onClick={() => navigate(-1)} />
            {t('core:editpricelistselling')}
        </Box>
    );

    const handleChange = (event) => {
        if (event.target.name === 'price_list_name') {
            setPriceListName(event.target.value);
        } else if (event.target.name === 'price_code') {
            setPriceCode(event.target.value);
        }
    };

    const handleDuplicate = () => {
        dispatch(SetDoc2Duplicate(null));
        dispatch(SetDoc2Duplicate(doc));
        navigate('/pim/add-tarif');
    };

    const disabledFieldStyle = {
        opacity: 0.7,
        pointerEvents: 'none'
    };

    if (isCurrencyPending && isCountryPending) return <Loader />;
    return (
        <Box>
            <MainCard title={title}>
                {id ? (
                    <Button
                        startIcon={<ContentCopyIcon color="warning" />}
                        variant="outlined"
                        color="warning"
                        sx={{ ml: 1 }}
                        onClick={() => handleDuplicate()}
                    >
                        {t('products:duplicate')}
                    </Button>
                ) : null}
                <Grid container spacing={2} justifyContent="center" alignItems="center" pl={20}>
                    {/* Left Section */}
                    <Grid item xs={6}>
                        <Grid container spacing={2} direction="column">
                            {/* Enable Field */}
                            <Grid item>
                                <InputTextField
                                    required
                                    handleChange={handleChange}
                                    value={price_code}
                                    label={t('core:price_code')}
                                    name="price_code"
                                    disabled
                                    sx={{ opacity: 0.7 }}
                                />
                            </Grid>
                            <Grid item>
                                <InputTextField
                                    handleChange={handleChange}
                                    value={price_list_name}
                                    label={t('core:pricelistnameselling')}
                                    name="price_list_name"
                                />
                            </Grid>
                            {/* Currency Select */}
                            <Grid item>
                                <SelectForm
                                    label={t('columns:currency')}
                                    value={currency || 'Currency'}
                                    handleChange={(e) => setCurrency(e.target.value)}
                                    data={currencyList}
                                    name="currency"
                                    propToRender="name"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item width={'200%'}>
                                <AutoCompleteField
                                    multiple
                                    label={t('core:countries')}
                                    options={countryList || []}
                                    value={state.country}
                                    name="country"
                                    handleChange={(newValue, name) => {
                                        setState({
                                            ...state,
                                            country: newValue.map((country) => ({ name: country.name }))
                                        });
                                    }}
                                />
                            </Grid>
                            {/* Sellling Checkbox */}
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={true}
                                            onChange={(e) => setSelling(e.target.checked)}
                                            name="selling"
                                            color="primary"
                                            disabled
                                        />
                                    }
                                    label={t('core:selling')}
                                    style={{ display: 'none' }}
                                />
                            </Grid>
                            {/* price not UOM dependent */}
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={uomDependent}
                                            onChange={(e) => setUomDependent(e.target.checked)}
                                            name="uomDependent"
                                            color="primary"
                                        />
                                    }
                                    label={t('core:PriceNotUOMDependent')}
                                />
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={enabled}
                                            onChange={(e) => setEnabled(e.target.checked)}
                                            name="enabled"
                                            color="primary"
                                        />
                                    }
                                    label={t('columns:enabled')}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Middle Section */}
                    <Grid item xs={6}></Grid>

                    {/* Save Button */}
                    <Grid item xs={12}>
                        <Grid container justifyContent="center">
                            <Grid item>
                                <LoadingButton
                                    loading={loadingButton}
                                    variant="contained"
                                    size="large"
                                    color="blue"
                                    onClick={handleAddTarif}
                                >
                                    {t('common:save')}
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </MainCard>
        </Box>
    );
};

export default EditTarif;
