import React from 'react';
import { Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import InsertDriveFileRoundedIcon from '@mui/icons-material/InsertDriveFileRounded';

const MyFileLink = ({ file }) => {
    const theme = useTheme();
    const handleDownload = (event) => {
        event.preventDefault();
        const url = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = url;
        link.download = file.name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    let preview = null;
    if (file?.type?.startsWith('image/')) {
        preview = (
            <img
                style={{ marginRight: 5, maxWidth: 1000, maxHeight: 1000, objectFit: 'cover' }}
                src={URL.createObjectURL(file)}
                alt={file.name}
            />
        );
    } else {
        preview = <InsertDriveFileRoundedIcon sx={{ marginRight: 5, width: 64, height: 64, objectFit: 'cover' }} />;
    }

    return (
        <Paper
            sx={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                '&:hover': {
                    backgroundColor: theme.palette.action.hover
                },
                padding: theme.spacing(2),
                marginBottom: theme.spacing(1)
            }}
            onClick={handleDownload}
        >
            {preview}

            {/* <Typography variant="subtitle1">{file.name}</Typography> */}
        </Paper>
    );
};
export default MyFileLink;
