import { Box } from '@mui/system';
import { Button, Grid, Modal, Typography } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import useFetch from 'hooks/useFetch';
import { SetNotification } from 'store/services/api';
import MainCard from 'ui-component/cards/MainCard';
import DataRender from 'ui-component/tables/DataRender';
import { formatErpResponse } from 'utils/utils';
import { modalStyle } from 'views/utilities/ModalStyle';
import DetailModal from './component/DetailModal';
import DataImporter from './component/DataImporter';
const FamilleProductList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [detailModalOpen, setDetailModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [openImporter, setOpenImporter] = useState(false);
    const { t } = useTranslation();
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleOpenImporter = () => setOpenImporter(true);
    const handleCloseImporter = () => setOpenImporter(false);

    function removeUpToWord(str, word) {
        const index = str.indexOf(word);
        if (index !== -1) {
            return str.substring(index + word.length);
        }
        return str;
    }

    const familleReqData = {
        doctype: 'Famille',
        fields: ['`tabFamille`.`code`', '`tabFamille`.`name`', '`tabFamille`.`libelle`'],
        // filters: [['Item Collection', 'disabled', '=', 0]],
        start: 0,
        page_length: 50
    };
    const { data, isPending, refetch } = useFetch(`/api/get-list`, familleReqData, 'POST');
    const handleEdit = (row) => {
        navigate(`/pim/edit-famille-produit/${row}`);
    };

    const columnsImport = ['code', 'name', 'libelle'];
    const handleDelete = (row) => {
        const header = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem('user'))?.token
        };
        setLoadingButton(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/delete-doc`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doctype: 'Famille', name: row[0] })
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(
                        SetNotification({
                            code: 'warning',
                            message: `${t('common:msg_family_delete')} : ${removeUpToWord(
                                formatErpResponse(data?.message),
                                'SousFamille '
                            )}`
                        })
                    );
                } else {
                    dispatch(SetNotification({ code: 'success', message: t('common:family_delete_success') }));
                    refetch({});
                    handleClose();
                }
            });
    };
    const deleteConfirm = (
        <Box sx={modalStyle}>
            <MainCard divider title={'common:confirm'}>
                <Typography id="modal-modal-description" sx={{ fontSize: 14 }}>
                    {'common:deletePermanently'} <strong>{selectionModel}</strong> ?
                </Typography>
                <Box display={'flex'} justifyContent={'flex-end'} mt={3}>
                    <Box>
                        <Grid container spacing={1}>
                            <Grid item>
                                <Button color="blue" size="large" variant="outlined" onClick={handleClose}>
                                    {t('common:non')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <LoadingButton
                                    color="blue"
                                    size="large"
                                    //onClick={() => handleDelete(selectionModel)}
                                    variant="contained"
                                    loading={loadingButton}
                                >
                                    {t('common:oui')}
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </MainCard>
        </Box>
    );

    const dataImporter = (
        <Box sx={modalStyle}>
            <MainCard divider title={t('core:import')}>
                <DataImporter refetch={refetch} handleClose={handleClose} columns={columnsImport} doctype="Famille" />
            </MainCard>
        </Box>
    );

    const title = (
        <Box p={-1} sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
            {t('common:famille-des-produits')}
            <Box>
                <Grid container spacing={1}>
                    {false}
                    <Grid item>
                        <Button
                            startIcon={<AddCircleIcon />}
                            variant="outlined"
                            color="blue"
                            onClick={() => navigate('/pim/add-famille-products')}
                            sx={{ marginTop: -3 }}
                        >
                            {t('common:ajout-famille-des-produits')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="text" color="blue" onClick={handleOpenImporter} sx={{ marginTop: -3, ml: 1 }}>
                            {t('core:import')}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );

    const handleOpenDetailModal = (row) => {
        setSelectedRow(row);
        setDetailModalOpen(true);
    };

    const handleCloseDetailModal = () => {
        setDetailModalOpen(false);
        setSelectedRow(null);
    };

    return (
        <div>
            <DataRender
                refetch={refetch}
                data={data}
                loading={isPending}
                title={title}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                notshow={['name']}
                handleOpenDetailModal={handleOpenDetailModal}
                selectionModel={selectionModel}
                setSelectionModel={setSelectionModel}
                Visualize={true}
            />
            <Modal onClose={handleClose} open={open}>
                {deleteConfirm}
            </Modal>
            <DetailModal open={detailModalOpen} onClose={handleCloseDetailModal} row={selectedRow} />
            <Modal onClose={handleCloseImporter} open={openImporter}>
                {dataImporter}
            </Modal>
        </div>
    );
};
export default FamilleProductList;
