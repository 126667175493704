import { Avatar, IconButton, OutlinedInput, Typography } from '@mui/material';
import React, { useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import CommentIcon from '@mui/icons-material/Comment';
import { Box } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import { formatErpResponse } from 'utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { SetNotification } from 'store/services/api';
import DeleteIcon from '@mui/icons-material/Delete';
import { t } from 'i18next';

const Comments = ({ docinfo, refetch, doc }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.login.userDetails);

    const [loadingCmmntBtn, setLoadingCmmntBtn] = useState(false);
    const [comment, setComment] = useState('');
    const [comments, setComments] = useState([]);

    const handleCommentChange = (event) => {
        setComment(event.target.value);
    };

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const handleCommentSubmit = (event) => {
        event.preventDefault();
        setLoadingCmmntBtn(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/make-comment`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({
                reference_doctype: doc?.doctype,
                reference_name: doc?.name,
                content: `<div class="ql-editor read-mode"><p>${comment}</p></div>`,
                comment_email: user?.email,
                comment_by: user?.full_name
            })
        })
            .then((res) => {
                setLoadingCmmntBtn(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    refetch({});
                    setComment('');
                    dispatch(SetNotification({ code: 'success', message: 'Comment Added' }));
                }
            });
        // setComments([...comments, comment]);
    };

    const handleDeleteComment = (comment) => {
        fetch(`${process.env.REACT_APP_API_URI}/api/delete-doc`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doctype: 'Comment', name: comment?.name })
        })
            .then((res) => {
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    dispatch(SetNotification({ code: 'success', message: 'Comment Deleted' }));
                    refetch({});
                }
            });
    };

    function getRelativeCreationDate(creationDate) {
        const now = new Date();
        const creationDateObject = new Date(creationDate);
        const diff = now.getTime() - creationDateObject.getTime();
        const diffInSeconds = Math.round(diff / 1000);

        if (diffInSeconds < 60) {
            return 'just now';
        }

        const diffInMinutes = Math.round(diffInSeconds / 60);
        if (diffInMinutes < 60) {
            return `${diffInMinutes} minute${diffInMinutes > 1 ? 's' : ''} ago`;
        }

        const diffInHours = Math.round(diffInMinutes / 60);
        if (diffInHours < 24) {
            return `${diffInHours} hour${diffInHours > 1 ? 's' : ''} ago`;
        }

        const diffInDays = Math.round(diffInHours / 24);
        if (diffInDays < 7) {
            return `${diffInDays} day${diffInDays > 1 ? 's' : ''} ago`;
        }

        const diffInWeeks = Math.round(diffInDays / 7);
        return `${diffInWeeks} week${diffInWeeks > 1 ? 's' : ''} ago`;
    }

    function getUser(owner) {
        if (user?.name === owner) {
            return 'You';
        } else {
            return owner;
        }
    }

    return (
        <MainCard
            border
            boxShadow
            isWhite
            title={
                <Box display={'flex'} alignItems={'center'}>
                    <CommentIcon sx={{ mr: 1 }} /> {t('products:comments')}
                </Box>
            }
        >
            <form onSubmit={handleCommentSubmit}>
                <OutlinedInput
                    multiline
                    rows={4}
                    placeholder="..."
                    endAdornment={
                        <LoadingButton variant="contained" disabled={comment === ''} loading={loadingCmmntBtn} color="blue" type="submit">
                            {t('products:Commenter')}
                        </LoadingButton>
                    }
                    value={comment}
                    onChange={handleCommentChange}
                    sx={{ width: '100%' }}
                />
            </form>
            <Box sx={{ mt: 2 }}>
                {docinfo?.comments &&
                    docinfo?.comments?.map((comment, index) => (
                        <Box
                            key={index}
                            sx={{
                                bgcolor: theme.palette.grey[100],
                                borderRadius: 2,
                                p: 1,
                                mb: 1,
                                display: 'flex',
                                flexDirection: 'row',
                                flexGrow: 1,
                                cursor: 'pointer'
                            }}
                        >
                            <Avatar />
                            <Box width={'95%'}>
                                <Typography fontWeight={800} fontSize={13} color="black" sx={{ ml: 1 }}>
                                    <span>{getUser(comment?.owner)}</span>
                                    <span style={{ color: theme.palette.blue.main, marginLeft: 10, fontWeight: 500 }}>
                                        {getRelativeCreationDate(comment?.creation)}
                                    </span>
                                </Typography>
                                <Typography color="GrayText" sx={{ ml: 1, overflow: 'auto', wordWrap: 'break-word' }}>
                                    {formatErpResponse(comment?.content)}
                                </Typography>
                            </Box>
                            <Box display={'flex'} alignItems={'center'}>
                                <IconButton onClick={() => handleDeleteComment(comment)}>
                                    <DeleteIcon color="error" />
                                </IconButton>
                            </Box>
                        </Box>
                    ))}
            </Box>
        </MainCard>
    );
};

export default Comments;
