import PropTypes from 'prop-types';
import React from 'react';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Drawer, useMediaQuery } from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import { BrowserView, MobileView } from 'react-device-detect';

// project imports
import MenuList from './MenuList';
import { drawerWidth } from 'store/constant';
import { useSelector } from 'react-redux';

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = ({ drawerOpen, drawerToggle, window }) => {
    const theme = useTheme();
    const defaultCompany = useSelector((state) => state.login.detailsDefaultCompany);
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

    const drawer = (
        <>
            <BrowserView
                style={{
                    width: drawerWidth - 20,
                    position: 'fixed',
                    height: !matchUpMd ? 'calc(100vh - 0px)' : 'calc(100vh - 88px)',
                    background: defaultCompany?.name === 'Gautier' ? '#8ebe55' : theme.palette.primary.dark,
                    borderTopRightRadius: 15,
                    borderBottomRightRadius: 15
                }}
            >
                <PerfectScrollbar style={{ overflowX: 'hidden' }}>
                    <MenuList />
                </PerfectScrollbar>
            </BrowserView>
            <MobileView>
                <Box sx={{ px: 2 }}>
                    <MenuList />
                </Box>
            </MobileView>
        </>
    );

    const container = window !== undefined ? () => window.document.body : undefined;

    return (
        <Box component="nav" sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : 'auto' }} aria-label="mailbox folders">
            <Drawer
                container={container}
                variant={matchUpMd ? 'persistent' : 'temporary'}
                anchor="left"
                open={drawerOpen}
                onClose={drawerToggle}
                sx={{
                    '& .MuiDrawer-paper': {
                        background: 'none',
                        width: drawerWidth - 20,
                        borderRight: 'none',
                        borderTopRightRadius: 15,
                        borderBottomRightRadius: 15,
                        [theme.breakpoints.up('md')]: {
                            top: '88px'
                        }
                    }
                }}
                ModalProps={{ keepMounted: true }}
                color="inherit"
            >
                {drawer}
            </Drawer>
        </Box>
    );
};

Sidebar.propTypes = {
    drawerOpen: PropTypes.bool,
    drawerToggle: PropTypes.func,
    window: PropTypes.object
};

export default Sidebar;
