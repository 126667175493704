import { Box, InputLabel } from '@mui/material';
import useReportQuery from 'hooks/useReportQuery';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useSelector } from 'react-redux';
import MainCard from 'ui-component/cards/MainCard';
import SelectForm from 'ui-component/form/SelectForm';
import DynamicChart from 'views/SalesDashboard/components/DynamicChart';
import Nothing2Show from 'views/SalesDashboard/components/Nothing2Show';
import Report from 'views/SalesDashboard/components/Report';
import useFetch from 'hooks/useFetch';

function PurchaseReceiptTrends() {
    const { t } = useTranslation();
    const globalDefaults = useSelector((state) => state.login.globalDefaults);

    const [reqData, setReqData] = useState({
        report_name: 'Purchase Receipt Trends',
        filters: {
            company: globalDefaults?.default_company,
            period: 'Monthly',
            fiscal_year: globalDefaults?.current_fiscal_year,
            period_based_on: 'posting_date',
            based_on: 'Item'
        },
        are_default_filters: true
    });
    const handleChangeFilters = (e) => {
        setReqData({ ...reqData, filters: { ...reqData.filters, [e.target.name]: e.target.value } });
    };
    const { data: years, isPending: isYearsPending } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Fiscal Year',
            fields: ['`tabFiscal Year`.`name`'],
            start: 0,
            page_length: 20
        },
        'POST'
    );
    const { data: doc, isPending } = useReportQuery(`${process.env.REACT_APP_API_URI}/api/report-query`, reqData);
    return (
        <div style={{ opacity: isPending ? 0.5 : 1, transition: 'all .2s', marginTop: 20 }}>
            <div style={{ display: 'flex', flexDirection: 'row', gap: 20 }}>
                {/* filters */}
                <div style={{ width: '20%' }}>
                    <MainCard
                        isWhite
                        title={
                            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} gap={5}>
                                <Box color={'ActiveCaption'} display={'flex'} alignItems={'center'}>
                                    {t('dashboard:f')}
                                </Box>
                                <FilterAltIcon />
                            </Box>
                        }
                        boxShadow
                        divider
                        sx={{ width: '100%' }}
                    >
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                            <div>
                                <InputLabel sx={{ fontSize: 12, p: 0.5 }}>{t('dashboard:fy')}</InputLabel>
                                <SelectForm
                                    fullwidth={true}
                                    name="fiscal_year"
                                    value={reqData.filters.fiscal_year}
                                    handleChange={handleChangeFilters}
                                    data={years}
                                    propToRender={'name'}
                                />
                            </div>
                            <div>
                                <InputLabel sx={{ fontSize: 12, p: 0.5 }}>{t('dashboard:gb')}</InputLabel>
                                <SelectForm
                                    fullwidth={true}
                                    name="based_on"
                                    value={reqData.filters.based_on}
                                    handleChange={handleChangeFilters}
                                    data={['Item', 'Item Group', 'Supplier', 'Supplier Group']}
                                />
                            </div>
                            <div>
                                <InputLabel sx={{ fontSize: 12, p: 0.5 }}>{t('dashboard:p')}</InputLabel>
                                <SelectForm
                                    fullwidth={true}
                                    name="period"
                                    value={reqData.filters.period}
                                    handleChange={handleChangeFilters}
                                    data={['Monthly', 'Quarterly', 'Half-Yearly', 'Yearly']}
                                />
                            </div>
                        </div>
                    </MainCard>
                </div>

                {/* charts */}
                <div style={{ width: '80%' }}>
                    <DynamicChart type={'bar'} title={t('dashboard:prt')} doc={doc} isPending={isPending} reqData={reqData} />
                </div>
            </div>

            {/* report */}
            <MainCard
                title={
                    <Box color={'ActiveCaption'} display={'flex'} alignItems={'center'}>
                        {t('dashboard:prt')}
                    </Box>
                }
                isWhite
                boxShadow
                divider
                sx={{ marginTop: 2 }}
            >
                {doc?.result?.length === 0 ? (
                    <Nothing2Show />
                ) : (
                    <>
                        <Report doc={doc} title={t('dashboard:prt')} />
                    </>
                )}
            </MainCard>
        </div>
    );
}

export default PurchaseReceiptTrends;
