/* eslint-disable no-constant-condition */
import { Box, Button, IconButton, Modal } from '@mui/material';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import React, { useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import { modalStyle } from 'views/utilities/ModalStyle';
import { formatErpResponse } from 'utils/utils';
import { LoadingButton } from '@mui/lab';
import { SetNotification } from 'store/services/api';
import { useDispatch } from 'react-redux';
import InputTextField from 'ui-component/form/InputTextField';
import { t } from 'i18next';

const EmailSender = ({ recipientEmail, doctype, name, disabled }) => {
    const dispatch = useDispatch();

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [loadingButton, setLoadingButton] = useState(false);

    const [recipient, setRecipient] = useState(recipientEmail ?? '');

    const handleSendEmail = () => {
        setLoadingButton(true);
        const data = {
            recipients: recipient,
            subject: `${doctype}: ${name}`,
            content: '<div class="ql-editor read-mode"><p><br></p></div>',
            doctype: doctype,
            name: name,
            send_email: 1,
            print_html: null,
            send_me_a_copy: 0,
            print_format: 'Standard',
            attachments: [],
            _lang: 'en',
            read_receipt: 0,
            print_letterhead: 1
        };
        if (true) {
            fetch(`${process.env.REACT_APP_API_URI}/api/make-email`, {
                method: 'POST',
                headers: header,
                body: JSON.stringify(data)
            })
                .then((res) => {
                    setLoadingButton(false);
                    if (!res.ok) {
                        dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                    }
                    return res.json();
                })
                .then((data) => {
                    if (data?.message) {
                        if (data?.success === false) {
                            dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                        } else {
                            dispatch(SetNotification({ code: 'success', message: 'Email Sent successfully' }));
                            handleClose();
                        }
                    }
                });
        }
    };

    const handleChange = (e) => {
        setRecipient(e.target.value);
    };

    function isEmail(recipient) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(recipient);
    }

    const emailInput = (
        <Box sx={modalStyle}>
            <MainCard divider title={t('core:Envoyer_un_Email')}>
                <InputTextField
                    required
                    fullwidth
                    sx={{ mb: 2 }}
                    type="email"
                    error={!isEmail(recipient)}
                    handleChange={handleChange}
                    value={recipient}
                    label={t('core:destinataire')}
                    name="recipient"
                />
                <Box display={'flex'} justifyContent={'right'}>
                    <Button variant="outlined" color="blue" size="large" onClick={handleClose}>
                        {t('common:cancel')}
                    </Button>
                    <LoadingButton
                        sx={{ ml: 2 }}
                        variant="contained"
                        color="blue"
                        size="large"
                        loading={loadingButton}
                        onClick={handleSendEmail}
                        disabled={!isEmail(recipient)}
                    >
                        {t('core:send')}
                    </LoadingButton>
                </Box>
            </MainCard>
        </Box>
    );

    return (
        <>
            <IconButton disabled={disabled} onClick={handleOpen} aria-label="delete">
                <SendRoundedIcon color={disabled ? 'disabled' : 'blue'} />
            </IconButton>
            <Modal onClose={handleClose} open={open}>
                {emailInput}
            </Modal>
        </>
    );
};

export default EmailSender;
