import {
    Box,
    Button,
    Checkbox,
    IconButton,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    Modal,
    Pagination,
    Typography
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTheme } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { SetNotification } from 'store/services/api';
import { modalStyle } from 'views/utilities/ModalStyle';
import MainCard from 'ui-component/cards/MainCard';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';

function FileList({ files, refetchFilesList }) {
    console.log('files[0]', files[0]);
    console.log('files---', files);
    const theme = useTheme();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [page, setPage] = useState(1);
    const [isDeleting, setIsDeleting] = useState(false);
    const [fileName2Delete, setFileName2Delete] = useState(null);
    const [open, setOpen] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [isDownloading, setIsDownloading] = useState(false);
    const handleOpen = (name) => {
        setOpen(true);
        setFileName2Delete(name);
    };

    const handleSelectFile = (file) => {
        setSelectedFiles((prev) => (prev.some((f) => f.name === file.name) ? prev.filter((f) => f.name !== file.name) : [...prev, file]));
    };

    const sortedFiles = useMemo(() => {
        return [...files].sort((a, b) => new Date(b.creation) - new Date(a.creation));
    }, [files]);

    const handleBulkDelete = async () => {
        setIsDeleting(true);
        try {
            await Promise.all(
                selectedFiles.map((file) =>
                    fetch(`${process.env.REACT_APP_API_URI}/api/delete-assets-file`, {
                        method: 'POST',
                        headers: header,
                        body: JSON.stringify({ name: file.name })
                    })
                )
            );
            setIsDeleting(false);
            dispatch(SetNotification({ code: 'success', message: t('common:delete-files-success') }));
            refetchFilesList();
            setSelectedFiles([]);
        } catch (error) {
            setIsDeleting(false);
            dispatch(SetNotification({ code: 'error', message: 'An error occurred during bulk delete' }));
            console.error(error);
        }
    };
    const handleBulkDownload = () => {
        setIsDownloading(true);

        selectedFiles.forEach((file) => {
            fetch(`${process.env.REACT_APP_IMAGE_URL}${file.file_url}`)
                .then((response) => response.blob())
                .then((blob) => {
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', file.file_name);
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                    window.URL.revokeObjectURL(url);
                })
                .catch((error) => {
                    console.error('Download failed:', error);
                    dispatch(SetNotification({ code: 'error', message: `Failed to download ${file.file_name}` }));
                });
        });

        setIsDownloading(false);
        dispatch(SetNotification({ code: 'success', message: t('common:download-file-success') }));
        setSelectedFiles([]);
    };

    const handleClose = () => {
        setOpen(false);
        setFileName2Delete('');
    };

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const handleDownload = (files) => {
        // const url = process.env.REACT_APP_API_URI + file?.url;
        const url = `${process.env.REACT_APP_IMAGE_URL}` + files.file_url;
        //
        window.open(url, '_blank');
    };

    const handleDelete = async () => {
        setIsDeleting(true);
        await fetch(`${process.env.REACT_APP_API_URI}/api/delete-assets-file`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ name: fileName2Delete })
        })
            .then(() => {
                setIsDeleting(false);
                dispatch(SetNotification({ code: 'success', message: t('common:delete-file-success') }));
                refetchFilesList();
                handleClose();
            })
            .catch((error) => {
                setIsDeleting(false);
                dispatch(SetNotification({ code: 'error', message: 'An error has occurred' }));
                console.error(error); // Log the error to the console for debugging purposes
            });
    };

    const deleteAlert = (
        <Box sx={modalStyle}>
            <MainCard divider title={t('common:confirm')}>
                <Typography id="modal-modal-description" sx={{ fontSize: 14 }}>
                    {t('common:supprimer-définitivement-le-fichier')} <strong>{files.name}</strong> ?
                </Typography>

                <Box display={'flex'} justifyContent={'flex-end'} gap={1} mt={3}>
                    <LoadingButton loading={isDeleting} color="blue" size="large" onClick={() => handleDelete()} variant="contained">
                        {t('common:delete')}
                    </LoadingButton>
                    <Button variant="outlined" color="blue" size="large" onClick={() => handleClose()}>
                        {t('common:non')}
                    </Button>
                </Box>
            </MainCard>
        </Box>
    );
    return (
        <>
            {selectedFiles.length > 0 && (
                <Box display="flex" gap={2} mb={2}>
                    <LoadingButton onClick={handleBulkDownload} loading={isDownloading} variant="contained" color="primary">
                        {t('common:download-selected-files')}
                    </LoadingButton>
                    <LoadingButton onClick={handleBulkDelete} loading={isDeleting} variant="contained" color="error">
                        {t('common:delete-selected-files')}
                    </LoadingButton>
                </Box>
            )}
            {sortedFiles && sortedFiles.length > 0 ? (
                <ImageList cols={6} gap={20}>
                    {sortedFiles?.slice((page - 1) * 20, page * 20)?.map((el) => (
                        <ImageListItem key={el.name}>
                            {el?.file_url &&
                            ['png', 'jpg', 'jpeg', 'gif', 'webp', 'avif'].includes(el.file_url?.split('.').pop()?.toLowerCase()) ? (
                                <img
                                    srcSet={`${process.env.REACT_APP_IMAGE_URL}${el?.file_url}`}
                                    src={`${process.env.REACT_APP_IMAGE_URL}${el?.file_url}`}
                                    alt={el?.file_url}
                                    loading="lazy"
                                    style={{
                                        width: '100',
                                        height: '200px',
                                        borderTopLeftRadius: 10,
                                        borderTopRightRadius: 10,
                                        boxShadow: '0px 2px 8px #091F461D'
                                    }}
                                />
                            ) : el?.file_url.split('.').pop()?.toLowerCase() === 'mp4' ? (
                                // eslint-disable-next-line jsx-a11y/media-has-caption
                                <video
                                    src={`${process.env.REACT_APP_IMAGE_URL}${el?.file_url}`}
                                    controls
                                    style={{
                                        width: '100%',
                                        height: '200px',
                                        borderTopLeftRadius: 10,
                                        borderTopRightRadius: 10,
                                        boxShadow: '0px 2px 8px #091F461D'
                                    }}
                                />
                            ) : (
                                <div
                                    style={{
                                        height: 200,
                                        backgroundColor: 'Menu',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        padding: 40,
                                        borderTopLeftRadius: 10,
                                        borderTopRightRadius: 10,
                                        boxShadow: '0px 2px 8px #091F461D'
                                    }}
                                >
                                    <InsertDriveFileIcon color="disabled" sx={{ height: 40, width: 40 }} />
                                </div>
                            )}
                            <ImageListItemBar
                                title={el?.attached_to_doctype + ': ' + el?.file_name}
                                subtitle={
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                                        <div>{el?.file}</div>
                                        <div style={{ color: theme.palette.grey[400] }}>Added: {el?.creation}</div>
                                    </div>
                                }
                                actionIcon={
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <IconButton
                                            onClick={() => handleDownload(el)}
                                            sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                            aria-label={`info about ${el?.file}`}
                                        >
                                            <FileDownloadIcon />
                                        </IconButton>
                                        <IconButton
                                            key={el.name}
                                            onClick={() => handleOpen(el.name)}
                                            sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                            aria-label={`info about ${el?.file_url}`}
                                        >
                                            <DeleteIcon color="error" style={{ opacity: 0.5 }} />
                                        </IconButton>
                                        <Checkbox
                                            checked={selectedFiles.some((f) => f.name === el.name)}
                                            onChange={() => handleSelectFile(el)}
                                        />
                                    </div>
                                }
                            />
                        </ImageListItem>
                    ))}
                </ImageList>
            ) : (
                <div></div>
            )}

            {Math.ceil(sortedFiles?.length / 20) > 0 && (
                <Box display="flex" justifyContent="center" m={4}>
                    <Pagination
                        onChange={(event, newPage) => setPage(newPage)}
                        page={page}
                        count={Math.ceil(sortedFiles?.length / 20)}
                        color="primary"
                    />
                </Box>
            )}
            <Modal onClose={handleClose} open={open}>
                {deleteAlert}
            </Modal>
        </>
    );
}

export default FileList;
