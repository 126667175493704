{
    /* eslint-disable */
}
import { Box } from '@mui/system';
import React, { useCallback, useRef, useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useStyles } from 'views/utilities/Style';
import { useNavigate, useParams } from 'react-router';
import useGetAxios from 'hooks/useGetAxios';
import {
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Modal,
    OutlinedInput,
    Select,
    Typography
} from '@mui/material';
import Loader from 'ui-component/Loader';
import { modalStyle } from 'views/utilities/ModalStyle';
import useFetch from 'hooks/useFetch';
import ProducDetails from './ProducDetails';
import { SetDoc2Duplicate, SetNotification } from 'store/services/api';
import { useDispatch } from 'react-redux';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PrintIcon from '@mui/icons-material/Print';
import { formatErpResponse } from 'utils/utils';
import ReactToPrint from 'react-to-print';
import PrintItemTemplate from './PrintItemTemplate';
import { useTranslation } from 'react-i18next';

const ServiceProdDetails = () => {
    const classes = useStyles();
    const componentRef = useRef();
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    // fetching DATA & init states
    // >>>

    const itemAttributesReqData = {
        doctype: 'Item Attribute',
        fields: ['`tabItem Attribute Value`.`attribute_value`', '`tabItem Attribute Value`.`parent`'],
        start: 0,
        page_length: 500
    };

    const { data: itemAttributesValues, isAttributesPending } = useFetch(`/api/get-list`, itemAttributesReqData, 'POST');

    const [modalId, setModalId] = useState(0);
    const [open, setOpen] = useState(false);

    const handleOpen = (modalId) => {
        setOpen(true);
        setModalId(modalId);
    };

    const handleClose = () => setOpen(false);

    const { data: doc, isPending, refetch } = useGetAxios(`${process.env.REACT_APP_API_URI}/api/details?doctype=Item&name=${id}`);
    const {
        data: files,
        isFilesPending,
        refetch: refetchFiles
    } = useGetAxios(`${process.env.REACT_APP_API_URI}/api/load-file?doctype=Item&name=${id}`, null, true);

    const [selectedAttValues, setSelectedAttValues] = useState({});
    const [singleVariantState, setSingleVariantState] = useState({});

    const [editToggled, setEditToggled] = useState(false);

    // state changes handlers
    // >>>

    const handleAttributesChange = (e, attribute) => {
        if (selectedAttValues.hasOwnProperty(attribute)) {
            let data = [...selectedAttValues[attribute]];
            const dataIndex = data.findIndex((el) => el === e.target.name);
            if (dataIndex == -1 && e.target.checked) {
                data.push(e.target.name);
                setSelectedAttValues({ ...selectedAttValues, [attribute]: data });
            } else if (dataIndex != -1 && !e.target.checked) {
                data = data.filter((el) => el != e.target.name);
                setSelectedAttValues({ ...selectedAttValues, [attribute]: data });
            }
        } else {
            if (e.target.checked) {
                setSelectedAttValues({ ...selectedAttValues, [attribute]: [e.target.name] });
            }
        }
    };

    const handleSelectChange = (event) => {
        const {
            target: { value }
        } = event;
        setSingleVariantState({ ...singleVariantState, [event.target.name]: value });
    };

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    // handlers
    // >>>

    const handleToggleDisabled = () => {
        const updateData = {
            doctype: 'Item',
            freeze: true,
            docnames: [id],
            action: 'update',
            data: {
                disabled: doc.disabled === 1 ? 0 : 1
            }
        };
        fetch(`${process.env.REACT_APP_API_URI}/api/update-status`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(updateData)
        })
            .then((res) => {
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    dispatch(
                        SetNotification({
                            code: 'success',
                            message: `${t('users:Le_produit_de_prestation_a_été')} ${
                                doc.disabled === 1 ? t('common:enabled') : t('users:disabled')
                            } ${t('users:avec_succès')}`
                        })
                    );
                    handleClose();
                    refetch({});
                }
            });
    };

    const handleDuplicate = () => {
        dispatch(SetDoc2Duplicate(null));
        dispatch(SetDoc2Duplicate(doc));
        navigate('/pim/add-products');
    };

    const handleCreateVariants = () => {
        const data = {
            item: id,
            args: selectedAttValues
        };
        fetch(`${process.env.REACT_APP_API_URI}/api/create-multiple-variant`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(data)
        })
            .then((res) => {
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                    throw Error('Something went wrong');
                }
                return res.json();
            })
            .then((data) => {
                dispatch(SetNotification({ code: 'success', message: t('common:variantCreateSuccess') }));
                handleClose();
                navigate(`/pim/products-view`);
            });
    };

    const handleCreateSingleVariant = async () => {
        const data = {
            item: id,
            args: singleVariantState
        };
        fetch(`${process.env.REACT_APP_API_URI}/api/create-single-variant`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(data)
        })
            .then((res) => {
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                    throw Error('Something went wrong');
                }
                return res.json();
            })
            .then((data) => {
                // save the variant item

                fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
                    method: 'POST',
                    headers: header,
                    body: JSON.stringify({ doc: data?.message, action: 'Save' })
                })
                    .then((res) => {
                        if (!res.ok) {
                            dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                            throw Error('Something went wrong');
                        }
                        return res.json();
                    })
                    .then((data) => {
                        dispatch(SetNotification({ code: 'success', message: t('common:prodVariantCreateSuccess') }));
                        handleClose();
                        navigate(`/pim/products-view`);
                    });
            });
    };

    // local functions
    // >>>

    const getVariantSum = useCallback(() => {
        let sum = 1;
        Object.values(selectedAttValues)?.map((el) => {
            sum *= el.length;
        });
        return sum;
    }, [selectedAttValues]);

    const checkVariants = useCallback(() => {
        if (getVariantSum() == 0) return true;
        if (Object.values(selectedAttValues)?.length !== doc?.attributes?.length) return true;
        Object.values(selectedAttValues)?.map((el) => {
            if (el.length == 0) return true;
        });
        return false;
    }, [selectedAttValues]);

    // modal forms
    // >>>

    const addVariants = (
        <Box sx={modalStyle}>
            <MainCard divider title={t('common:Sélectionner_les_valeurs')}>
                {t('common:msg_prod_var')}
                <Grid container spacing={1} mt={2} justifyContent={'space-between'}>
                    {doc?.attributes?.map((att, i) => (
                        <Grid item key={i}>
                            <Box bgcolor={'Menu'} borderRadius={2}>
                                <Typography sx={{ p: 1 }} fontWeight={'600'} variant="h4">
                                    {att.attribute}:
                                </Typography>
                                <Divider />
                                <Box ml={1} sx={{ height: 150, overflowY: 'scroll' }}>
                                    {itemAttributesValues
                                        ?.filter((el) => el.parent === att.attribute)
                                        ?.map((el, index) => (
                                            <Box height={35} key={index}>
                                                <FormControlLabel
                                                    label={el.attribute_value}
                                                    control={
                                                        <Checkbox
                                                            defaultValue={false}
                                                            name={el.attribute_value}
                                                            onChange={(e) => handleAttributesChange(e, att.attribute)}
                                                        />
                                                    }
                                                />
                                            </Box>
                                        ))}
                                </Box>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
                <Box display={'flex'} justifyContent={'right'} mt={2}>
                    <Button color="blue" variant="contained" size="large" onClick={handleCreateVariants} disabled={checkVariants()}>
                        {/* {`Créer ${getVariantSum()} Variantes`} */}
                        {getVariantSum() == 1
                            ? t('common:créer_une_seule_variante')
                            : getVariantSum() == 2
                            ? t('common:créer2variante')
                            : getVariantSum() == 3
                            ? t('common:créer3variante')
                            : getVariantSum() == 4
                            ? t('common:créer4variante')
                            : getVariantSum() == 5
                            ? t('common:créer5variante')
                            : getVariantSum() == 6
                            ? t('common:créer6variante')
                            : getVariantSum() == 7
                            ? t('common:créer7variante')
                            : getVariantSum() == 8
                            ? t('common:créer8variante')
                            : getVariantSum() == 9
                            ? t('common:créer9variante')
                            : getVariantSum() == 10
                            ? t('common:créer10variante')
                            : t('common:créer_plusieurs_variants')}
                    </Button>
                </Box>
            </MainCard>
        </Box>
    );

    const addOneVariant = (
        <Box sx={modalStyle}>
            <MainCard divider title={'Créer une variante'}>
                <Grid container direction={'column'} spacing={1} justifyContent={'space-between'}>
                    {doc?.attributes?.map((att, i) => (
                        <Grid item key={i}>
                            <FormControl fullWidth sx={{ mb: 1 }}>
                                <InputLabel id="demo-name-label" required>
                                    {att.attribute}
                                </InputLabel>
                                <Select
                                    required
                                    name={att.attribute}
                                    labelId="demo-name-label"
                                    id="demo-multiple-name"
                                    value={singleVariantState[att.attribute] ?? ''}
                                    onChange={handleSelectChange}
                                    input={<OutlinedInput label={att.attribute} />}
                                >
                                    {itemAttributesValues
                                        ?.filter((el) => el.parent === att.attribute)
                                        ?.map((name, i) => (
                                            <MenuItem key={i} value={name?.attribute_value}>
                                                {name?.attribute_value}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    ))}
                </Grid>
                <Box display={'flex'} justifyContent={'right'} mt={2}>
                    <Button
                        color="blue"
                        variant="contained"
                        size="large"
                        onClick={handleCreateSingleVariant}
                        disabled={doc?.attributes?.length !== Object.values(singleVariantState).length}
                    >
                        {t('common:create')}
                    </Button>
                </Box>
            </MainCard>
        </Box>
    );

    const disableAlert = (
        <Box sx={modalStyle}>
            <MainCard divider title={t('common:confirm')}>
                <Typography id="modal-modal-description" sx={{ fontSize: 14 }}>
                    {doc?.disabled === 0 ? t('products:unarchive') : t('products:archive')} <strong>{doc?.item_name}</strong> ?
                </Typography>
                <Box display={'flex'} justifyContent={'flex-end'} mt={3}>
                    <Box>
                        <Grid container spacing={1}>
                            <Grid item>
                                <Button variant="outlined" color="blue" size="large" onClick={handleClose}>
                                    {t('common:non')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button color="blue" size="large" onClick={() => handleToggleDisabled()} variant="contained">
                                    {t('common:oui')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </MainCard>
        </Box>
    );

    const formOpened = () => {
        if (doc) {
            if (editToggled) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    };

    if (isPending) return <Loader />;

    return (
        <div>
            <MainCard
                title={
                    <Box p={-1} display={'flex'} justifyContent={'space-between'}>
                        <Box p={-1} display={'flex'} flexDirection={'column'}>
                            <ArrowBackIcon className={classes.backLink} onClick={() => navigate(-1)} />
                            {`${t('products:product')}: ${id}`}
                        </Box>
                        <Box>
                            {doc?.has_variants === 1 ? (
                                <Box>
                                    <Button
                                        disabled={formOpened()}
                                        startIcon={<AddCircleIcon />}
                                        variant="outlined"
                                        sx={{ ml: 1 }}
                                        color="blue"
                                        onClick={() => handleOpen(2)}
                                    >
                                        {t('common:créer_une_seule_variante')}
                                    </Button>
                                    <Button
                                        disabled={formOpened()}
                                        startIcon={<AddCircleIcon />}
                                        variant="outlined"
                                        color="blue"
                                        sx={{ ml: 1 }}
                                        onClick={() => handleOpen(1)}
                                    >
                                        {t('common:créer_plusieurs_variants')}
                                    </Button>
                                    <Button
                                        sx={{ ml: 1 }}
                                        disabled={formOpened()}
                                        onClick={() => handleOpen(3)}
                                        startIcon={doc?.disabled == 0 ? <ArchiveIcon color="error" /> : <UnarchiveIcon color="error" />}
                                        variant="outlined"
                                        color="error"
                                    >
                                        {doc?.disabled === 0 ? t('products:archive') : t('products:unarchive')}
                                    </Button>
                                    <Button
                                        disabled={formOpened()}
                                        startIcon={<ContentCopyIcon color="warning" />}
                                        variant="outlined"
                                        color="warning"
                                        sx={{ ml: 1 }}
                                        onClick={() => handleDuplicate()}
                                    >
                                        {t('products:duplicate')}
                                    </Button>
                                    <Button
                                        startIcon={<EditIcon color={formOpened() ? 'Menu' : ''} />}
                                        variant={formOpened() ? 'contained' : 'outlined'}
                                        color="blue"
                                        sx={{ ml: 1 }}
                                        onClick={() => setEditToggled(true)}
                                    >
                                        {t('common:edit')}
                                    </Button>
                                    <ReactToPrint
                                        trigger={() => (
                                            <IconButton sx={{ ml: 1 }} disabled={formOpened()} aria-label="delete" color="blue">
                                                <PrintIcon />
                                            </IconButton>
                                        )}
                                        content={() => componentRef.current}
                                    />
                                </Box>
                            ) : (
                                <Box>
                                    <Button
                                        sx={{ ml: 1 }}
                                        disabled={formOpened()}
                                        startIcon={doc?.disabled == 0 ? <ArchiveIcon color="error" /> : <UnarchiveIcon color="info" />}
                                        variant="outlined"
                                        color={doc?.disabled == 0 ? 'error' : 'success'}
                                        onClick={() => handleOpen(3)}
                                    >
                                        {doc?.disabled === 1 ? t('products:archive') : t('products:unarchive')}
                                    </Button>
                                    <Button
                                        disabled={formOpened()}
                                        startIcon={<ContentCopyIcon color="warning" />}
                                        variant="outlined"
                                        color="warning"
                                        sx={{ ml: 1 }}
                                        onClick={() => handleDuplicate()}
                                    >
                                        {t('products:duplicate')}
                                    </Button>
                                    <Button
                                        startIcon={<EditIcon color={formOpened() ? 'Menu' : ''} />}
                                        variant={formOpened() ? 'contained' : 'outlined'}
                                        color="blue"
                                        sx={{ ml: 1 }}
                                        onClick={() => setEditToggled(true)}
                                    >
                                        {t('common:edit')}
                                    </Button>
                                    <ReactToPrint
                                        trigger={() => (
                                            <IconButton sx={{ ml: 1 }} disabled={formOpened()} aria-label="delete" color="blue">
                                                <PrintIcon />
                                            </IconButton>
                                        )}
                                        content={() => componentRef.current}
                                    />
                                </Box>
                            )}
                        </Box>
                    </Box>
                }
            >
                {doc && doc.has_variants === 1 && (
                    <ProducDetails isTemplate={true} doc={doc} editToggled={editToggled} storedFiles={files} refetchFiles={refetchFiles} />
                )}
                {doc && doc.has_variants === 0 && (
                    <ProducDetails isTemplate={false} doc={doc} editToggled={editToggled} storedFiles={files} refetchFiles={refetchFiles} />
                )}
            </MainCard>
            <Box display={'none'}>
                <PrintItemTemplate ref={componentRef} item={doc} />
            </Box>
            <Modal onClose={handleClose} open={open}>
                {modalId === 1 ? addVariants : modalId === 2 ? addOneVariant : modalId === 3 ? disableAlert : <div>Oops!</div>}
            </Modal>
        </div>
    );
};

export default ServiceProdDetails;
