import React from 'react';
import StockValue from './StockValue';
import PurchaseReceiptTrends from './PurchaseReceiptTrends';
import StockLedger from './StockLedger';
import StockAnalytics from './StockAnalytics';

function InventoryDashboard() {
    return (
        <div>
            <StockValue />
            <StockLedger />
            <PurchaseReceiptTrends />
            <StockAnalytics />
        </div>
    );
}

export default InventoryDashboard;
