import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import MainCard from 'ui-component/cards/MainCard';
import { frLocal } from 'utils/dataGridFRLocal';
import { Button, Grid, Modal, Typography } from '@mui/material';
import { modalStyle } from 'views/utilities/ModalStyle';
import { formatColumnsHeaders, formatData, formatColumnsString } from 'utils/formatColumnsHeaders';
import { useTranslation } from 'react-i18next';

const SimpleTable = ({
    data,
    loading,
    selectionModel,
    setSelectionRow,
    setSelectionModel,
    title,
    autoHeight,
    columnsName,
    userDetails,
    hasCheckBoxes,
    isSales,
    tag
}) => {
    const { t, i18n } = useTranslation();
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 8,
        page: 0
    });
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
    };

    const detailsConfirm = (
        <Box sx={modalStyle}>
            <MainCard divider title={'Information utilisateur'}>
                <Typography id="modal-modal-description" sx={{ fontSize: 14 }}>
                    <strong>Nom et Prénom :</strong> {userDetails?.full_name ? userDetails?.full_name : '-'}
                </Typography>
                <Typography id="modal-modal-description" sx={{ fontSize: 14 }}>
                    <strong>Prénom :</strong> {userDetails?.first_name ? userDetails?.first_name : '-'}
                </Typography>
                <Typography id="modal-modal-description" sx={{ fontSize: 14 }}>
                    <strong>Nom d&apos;Utilisateur :</strong> {userDetails?.username ? userDetails?.username : '-'}
                </Typography>
                <Box display={'flex'} justifyContent={'flex-end'} mt={3}>
                    <Box>
                        <Grid container spacing={1}>
                            <Grid item>
                                <Button variant="outlined" color="blue" size="large" onClick={handleClose}>
                                    Fermer
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </MainCard>
        </Box>
    );
    const getColumns = (data) => {
        let columns = [];
        if (data && Array.isArray(data) && data?.length !== 0) {
            columnsName?.map((column) => {
                columns.push({
                    field: column,
                    headerName: isSales ? formatColumnsString(column) : t(formatColumnsHeaders(column, tag)),
                    flex: 1,
                    cellClassName: (params) => {
                        if (params.field === 'status' && params.value === 'Offline') {
                            return 'red-cell';
                        } else if (params.field === 'status' && params.value === 'Online') {
                            return 'green-cell';
                        }
                        return '';
                    }
                });
            });
            return columns;
        }
        return [];
    };
    const getLanguage = () => i18n.language || window.localStorage.i18nextLng;
    useEffect(() => {
        if (!loading && data && data?.length > 0) {
            setRows(formatData(data));
            setColumns(getColumns(data));
        }
    }, [data, getLanguage()]);

    return (
        <Box>
            <MainCard title={title}>
                <Box sx={autoHeight ? { height: autoHeight } : { height: 780 }}>
                    <DataGrid
                        checkboxSelection={hasCheckBoxes}
                        onRowDoubleClick={(rowData) => {
                            !hasCheckBoxes && setSelectionRow(rowData.row);
                            !hasCheckBoxes && handleOpen();
                        }}
                        onRowSelectionModelChange={(newSelectionModel) => {
                            setSelectionModel(newSelectionModel);
                        }}
                        rowSelectionModel={selectionModel}
                        experimentalFeatures={{ newEditingApi: true }}
                        loading={loading}
                        rows={rows}
                        columns={columns}
                        getRowId={(row) => row.name}
                        localeText={frLocal}
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                        hideFooterPagination
                        hideFooterSelectedRowCount
                    />
                    <style>
                        {`
          .red-cell {
            color: red !important;
          }
          .green-cell {
            color: green !important;
          }
        `}
                    </style>
                </Box>
            </MainCard>

            <Modal onClose={handleClose} open={open}>
                {detailsConfirm}
            </Modal>
        </Box>
    );
};
export default SimpleTable;
