import { forwardRef } from 'react';
import React from 'react';
// material-ui
import { Alert, Typography } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import { Box } from '@mui/system';

const ServerMSG = forwardRef(({ message, key }, ref) => {
    return (
        <Box ref={ref} key={key}>
            <Alert
                iconMapping={{
                    warning: <WarningIcon color="warning" stroke={1.5} size="1.3rem" />
                }}
                sx={{ bgcolor: 'Menu' }}
                variant="outlined"
                severity="warning"
            >
                <Typography color={'primary'}>{message}</Typography>
            </Alert>
        </Box>
    );
});

export default ServerMSG;
