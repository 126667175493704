import React, { useEffect } from 'react';
import { Grid, Box, Button, FormControlLabel, Checkbox } from '@mui/material';
import { useStyles } from 'views/utilities/Style';
import InputTextField from './InputTextField';
import { useNavigate } from 'react-router';
import useFetch from 'hooks/useFetch';
import Loader from 'ui-component/Loader';
import { LoadingButton } from '@mui/lab';
import SelectForm from './SelectForm';
import FormDateTime from 'ui-component/dateTime/FormDateTime';
import { useTranslation } from 'react-i18next';

const GenericForm = ({ loadingButton, state, error, handleSubmit, handleChange, isDisabled, setIsDisabled, isDepot, id }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const [warehouses, setWarehouses] = React.useState([]);
    const [isParentWrhPending, setIsParentWrhPending] = React.useState(false);
    const customerReqData = {
        doctype: 'Customer',
        fields: [
            '`tabCustomer`.`name`',
            '`tabCustomer`.`customer_group`',
            '`tabCustomer`.`territory`',
            '`tabCustomer`.`customer_name`',
            '`tabCustomer`.`customer_type`',
            '`tabCustomer`.`disabled`'
        ],
        start: 0,
        page_length: 500,
        order_by: '`tabCustomer`.`modified` desc'
    };

    const companyReqData = {
        doctype: 'Company',
        fields: [
            '`tabCompany`.`name`',
            '`tabCompany`.`docstatus`',
            '`tabCompany`.`country`',
            '`tabCompany`.`parent_company`',
            '`tabCompany`.`monthly_sales_target`',
            '`tabCompany`.`total_monthly_sales`',
            '`tabCompany`.`credit_limit`',
            '`tabCompany`.`company_logo`',
            '`tabCompany`.`default_currency`'
        ],
        start: 0,
        page_length: 500
    };
    const warehouseTypeReqData = {
        doctype: 'Warehouse Type',
        fields: ['`tabWarehouse Type`.`name`', '`tabWarehouse Type`.`creation`', '`tabWarehouse Type`.`modified`'],
        start: 0,
        page_length: 500
    };

    const pricelistReqData = {
        doctype: 'Price List',
        fields: ['`tabPrice List`.`name`', '`tabPrice List`.`price_list_name`', '`tabPrice List`.`currency`'],
        filters: [['Price List', 'buying', '=', 1]],
        start: 0,
        page_length: 500
    };

    const { isPending: isCustomerPending } = useFetch(`/api/get-list`, customerReqData, 'POST');
    const { data: company, isPending: isCompanyPending } = useFetch(`/api/get-list`, companyReqData, 'POST');
    const { data: warehouseType, isPending: isWrhTypePending } = useFetch(`/api/get-list`, warehouseTypeReqData, 'POST');
    const { isPending: isPricelistePending } = useFetch(`/api/get-list`, pricelistReqData, 'POST');

    /* FETCHING AGAIN */
    const fetchParentWarehouse = () => {
        setIsParentWrhPending(true);
        let parentWarehouseReqData = {
            doctype: 'Warehouse',
            fields: [
                '`tabWarehouse`.`name`',
                '`tabWarehouse`.`company`',
                '`tabWarehouse`.`disabled`',
                '`tabWarehouse`.`city`',
                '`tabWarehouse`.`warehouse_name`'
            ],
            filters: [
                ['Warehouse', 'company', '=', state?.company],
                ['Warehouse', 'disabled', '=', 0],
                ['Warehouse', 'is_storage', '=', 0],
                ['Warehouse', 'is_group', '=', 1]
            ]
        };
        fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(parentWarehouseReqData)
        })
            .then((res) => {
                setIsParentWrhPending(false);
                return res.json();
            })
            .then((data) => {
                setWarehouses(data);
            });
    };
    useEffect(() => {
        fetchParentWarehouse();
    }, [state?.company]);

    if (isCompanyPending || isCustomerPending || isParentWrhPending || isWrhTypePending || isPricelistePending) return <Loader />;
    return (
        <Box>
            <FormControlLabel
                label={`${t('core:qdisable')} ${isDepot ? t('core:warehouse') : t('core:store')}.`}
                control={<Checkbox checked={isDisabled} onChange={() => setIsDisabled(!isDisabled)} />}
            />
            <Grid container rowSpacing={2}>
                <InputTextField
                    required
                    label={t('columns:DepotCode')}
                    error={error.warehouse_code}
                    value={state.warehouse_code}
                    name="warehouse_code"
                    handleChange={handleChange}
                />
                <InputTextField
                    required
                    label={t('columns:DepotName')}
                    error={error.warehouse_name}
                    value={state.warehouse_name}
                    name="warehouse_name"
                    handleChange={handleChange}
                />
                <SelectForm
                    required
                    label={t('core:compname')}
                    name="company"
                    value={state.company}
                    error={error.company}
                    handleChange={handleChange}
                    data={company ? company : []}
                    propToRender={'name'}
                    id={id}
                />
                <SelectForm
                    label={t('core:depotparent')}
                    name="parent_warehouse"
                    value={state.parent_warehouse}
                    handleChange={handleChange}
                    data={warehouses ? warehouses : []}
                    propToRender={'name'}
                />
                {/*<SelectForm
                    label={t('core:defcust')}
                    name="default_customer"
                    value={state.default_customer}
                    handleChange={handleChange}
                    data={customer ? customer : []}
                    propToRender={'name'}
                    flagAddDefaultCustomer={flagAddDefaultCustomer}
                    refetch={refetch}
                />*/}

                {false && (
                    <SelectForm
                        label="Type de magasin"
                        name="warehouse_type"
                        value={state.warehouse_type}
                        handleChange={handleChange}
                        data={warehouseType ? warehouseType : []}
                        propToRender={'name'}
                    />
                )}
                <InputTextField label={t('core:addr1')} value={state.address_line_1} name="address_line_1" handleChange={handleChange} />
                <InputTextField label={t('core:addr2')} value={state.address_line_2} name="address_line_2" handleChange={handleChange} />
                <InputTextField label={t('core:phn1')} value={state.phone_no} name="phone_no" handleChange={handleChange} />
                <InputTextField label={t('core:phn2')} value={state.mobile_no} name="mobile_no" handleChange={handleChange} />
                <InputTextField label={t('core:email')} value={state.email} name="email" handleChange={handleChange} />
                <InputTextField label={t('core:city')} value={state.city} name="city" handleChange={handleChange} />
                {false && (
                    <>
                        <FormDateTime
                            label={"Date d'ouverture"}
                            name="open_date_depot"
                            value={state.open_date_depot}
                            handleChange={handleChange}
                        />
                        <FormDateTime
                            label={'Date de fermeture'}
                            name="close_date_depot"
                            value={state.close_date_depot}
                            handleChange={handleChange}
                        />
                    </>
                )}
            </Grid>
            <Box sx={{ mt: 5, ml: 30 }} className={classes.formBlock}>
                <Button onClick={() => navigate(-1)} sx={{ mr: 1 }}>
                    {t('common:cancel')}
                </Button>
                <LoadingButton
                    loading={loadingButton}
                    variant="contained"
                    color="blue"
                    size="large"
                    onClick={handleSubmit}
                    disabled={state.warehouse_name === '' || state.warehouse_code === '' || state.company === ''}
                >
                    {t('common:save')}
                </LoadingButton>
            </Box>
        </Box>
    );
};

export default GenericForm;
