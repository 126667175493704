import { Box } from '@mui/system';
import React from 'react';
import { modalStyle } from 'views/utilities/ModalStyle';
import MainCard from './MainCard';
import ServerMSG from './ServerMSG';
import { Button, Modal } from '@mui/material';
import { t } from 'i18next';

const NavigationModal = ({ open, onClose }) => {
    return (
        <Modal open={open} onClose={onClose} aria-labelledby="modal-title" aria-describedby="modal-description">
            <Box sx={modalStyle}>
                <MainCard divider title={t('common:confirm')}>
                    <Box>
                        <ServerMSG message={t('core:confirmsave')} key={'ServerMSG-1'} />
                    </Box>
                    <Box display={'flex'} justifyContent={'flex-end'} mt={3}>
                        <Box>
                            <Button variant="outlined" color="blue" size="large" onClick={onClose}>
                                {t('core:closebutton')}
                            </Button>
                        </Box>
                    </Box>
                </MainCard>
            </Box>
        </Modal>
    );
};

export default NavigationModal;
