import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import useFetch from '../../../hooks/useFetch';
import { SetNotification } from '../../../store/services/api';
import { Box } from '@mui/system';
import { modalStyle } from '../../utilities/ModalStyle';
import MainCard from '../../../ui-component/cards/MainCard';
import { Button, Grid, Modal, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DataRender from '../../../ui-component/tables/DataRender';
import { formatErpResponse } from '../../../utils/utils';

const ProductFinancierList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [selectionModel, setSelectionModel] = useState([]);

    const [open, setOpen] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [loadingBulkEdit, setLoadingBulkEdit] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const familleReqData = {
        doctype: 'Item Financier',
        fields: [
            '`tabItem Financier`.`name`',
            '`tabItem Financier`.`code`',
            '`tabItem Financier`.`libelle`',
            '`tabItem Financier`.`type`',
            '`tabItem Financier`.`disabled`',
            '`tabItem Financier`.`date_de_début`',
            '`tabItem Financier`.`jusquau`'
        ],

        filters: [],
        start: 0,
        page_length: 50
    };

    const { data, isPending, refetch } = useFetch(`/api/get-list`, familleReqData, 'POST');

    const handleEdit = (row) => {
        navigate(`/pim/product-financier/${row}`);
    };

    const handleDeleteClose = () => {
        setOpenDelete(false);
        setSelectionModel([]);
    };

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    function extractStringBetweenWords(input, word1, word2) {
        const regex = new RegExp(`${word1}(.*?)${word2}`, 'i'); // 'i' pour insensible à la casse
        const match = input.match(regex); // Utilise la regex pour chercher une correspondance

        if (match && match[1]) {
            return match[1].trim(); // Retourne la chaîne entre les deux mots
        }
        return ''; // Retourne une chaîne vide si aucune correspondance n'est trouvée
    }

    function extractStringFromWordToEnd(input, startWord) {
        const regex = new RegExp(`${startWord}(.*)`, 'i'); // 'i' pour insensible à la casse
        const match = input.match(regex); // Utilise la regex pour chercher une correspondance

        if (match && match[1]) {
            return match[1].trim(); // Retourne la chaîne à partir du mot donné jusqu'à la fin
        }
        return ''; // Retourne une chaîne vide si aucune correspondance n'est trouvée
    }

    const handleDelete = (row) => {
        setLoadingButton(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/delete-doc`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doctype: 'Item Financier', name: row[0] })
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(
                        SetNotification({
                            code: 'warning',
                            message:
                                t('common:cannotDeleteOrCancelBecauseItemFinancier') +
                                extractStringBetweenWords(formatErpResponse(data?.message), 'Financier ', 'is linked') +
                                t('common:isLinkedWithPosInvoice') +
                                extractStringFromWordToEnd(formatErpResponse(data?.message), 'Invoice ')
                        })
                    );
                } else {
                    dispatch(SetNotification({ code: 'success', message: t('common:productFinancierDeleteSuccess') }));
                    refetch({});
                    handleClose();
                    window.location.reload();
                }
            });
    };

    const handleBulkDelete = () => {
        const rows = selectionModel;
        setLoadingButton(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/delete-items`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doctype: 'Item Financier', items: rows })
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(
                        SetNotification({
                            code: 'warning',
                            message: t('common:itemfinanciersBulkDeleteError')
                        })
                    );
                } else {
                    dispatch(SetNotification({ code: 'success', message: t('common:itemfinanciersBulkDeleted') }));
                    handleDeleteClose();
                    setTimeout(() => {
                        refetch({});
                    }, 2000);
                }
            });
    };

    const BulkDeleteConfirm = (
        <Box sx={modalStyle}>
            <MainCard divider title={t('core:confirm')}>
                <Typography id="modal-modal-description" sx={{ fontSize: 14 }}>
                    {t('common:delete')} <strong>{selectionModel.join(', ')}</strong> ?
                </Typography>
                <Box display={'flex'} justifyContent={'flex-end'} mt={3}>
                    <Box>
                        <Grid container spacing={1}>
                            <Grid item>
                                <Button color="blue" size="large" variant="outlined" onClick={handleDeleteClose}>
                                    {t('users:no')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <LoadingButton
                                    color="blue"
                                    size="large"
                                    onClick={() => handleBulkDelete()}
                                    variant="contained"
                                    loading={loadingBulkEdit}
                                >
                                    {t('users:yes')}
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </MainCard>
        </Box>
    );

    const deleteConfirm = (
        <Box sx={modalStyle}>
            <MainCard divider title={t('common:confirm')}>
                <Typography id="modal-modal-description" sx={{ fontSize: 14 }}>
                    {t('common:deletePermanently')} <strong>{selectionModel}</strong> ?
                </Typography>
                <Box display={'flex'} justifyContent={'flex-end'} mt={3}>
                    <Box>
                        <Grid container spacing={1}>
                            <Grid item>
                                <Button color="blue" size="large" variant="outlined" onClick={handleClose}>
                                    {t('common:non')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <LoadingButton
                                    color="blue"
                                    size="large"
                                    onClick={() => handleDelete(selectionModel)}
                                    variant="contained"
                                    loading={loadingButton}
                                >
                                    {t('common:oui')}
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </MainCard>
        </Box>
    );

    const title = (
        <Box p={-1} sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
            {t('products:financierList')}
            <Box>
                <Grid container spacing={1}>
                    {false && (
                        <>
                            <Grid item>
                                {selectionModel.length === 1 && (
                                    <Button
                                        startIcon={<DeleteIcon color="error" />}
                                        variant="outlined"
                                        color="error"
                                        onClick={handleOpen}
                                        sx={{ marginTop: -3 }}
                                    >
                                        Supprimer
                                    </Button>
                                )}
                            </Grid>
                            <Grid item>
                                {selectionModel.length === 1 && (
                                    <Button
                                        startIcon={<EditIcon />}
                                        variant="outlined"
                                        color="blue"
                                        onClick={() => handleEdit(selectionModel)}
                                        sx={{ marginTop: -3 }}
                                    >
                                        {t('common:edit')}
                                    </Button>
                                )}
                            </Grid>
                        </>
                    )}
                    <Grid item>
                        <Button
                            startIcon={<AddCircleIcon />}
                            variant="outlined"
                            color="blue"
                            onClick={() => navigate('/pim/add-product-financier')}
                            sx={{ marginTop: -3 }}
                        >
                            {t('products:financier-add')}
                        </Button>
                    </Grid>
                    {Array.isArray(selectionModel) && selectionModel?.length !== 0 && (
                        <Grid item>
                            <Button
                                startIcon={<DeleteIcon color="error" />}
                                variant="outlined"
                                color="error"
                                sx={{ marginTop: -3, marginLeft: 1 }}
                                onClick={() => setOpenDelete(true)}
                            >
                                {t('common:delete')}
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </Box>
        </Box>
    );

    return (
        <div>
            <DataRender
                refetch={refetch}
                data={data}
                loading={isPending}
                selectionModel={selectionModel}
                title={title}
                setSelectionModel={setSelectionModel}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                notshow={['name']}
                hasCheckBoxes
            />
            <Modal onClose={handleClose} open={open}>
                {deleteConfirm}
            </Modal>
            <Modal onClose={handleDeleteClose} open={openDelete}>
                {BulkDeleteConfirm}
            </Modal>
        </div>
    );
};

export default ProductFinancierList;
