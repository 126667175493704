import { Box, IconButton } from '@mui/material';
import useGetAxios from 'hooks/useGetAxios';
import React, { useRef } from 'react';
import { useNavigate, useParams } from 'react-router';
import Loader from 'ui-component/Loader';
import MainCard from 'ui-component/cards/MainCard';
import Comments from 'ui-component/comments/Comments';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useStyles } from 'views/utilities/Style';
import CustomerOrderDetails from './components/CustomerOrderDetails';
import ProductsOrderDetails from './components/ProductsOrderDetails';
import { useTheme } from '@mui/material/styles';
import ReactToPrint from 'react-to-print';
import PrintIcon from '@mui/icons-material/Print';
import PrintDocumentsTemplate from './components/PrintDocumentsTemplate';

function BuyingDocumentsDetails() {
    const navigate = useNavigate();
    const theme = useTheme();
    const classes = useStyles();
    const { id } = useParams();
    const componentRef = useRef();

    const lookup = {
        'PUR-ORD': 'Purchase%20Order',
        'ACC-PINV': 'Purchase%20Invoice',
        'MAT-PRE': 'Purchase%20Receipt',
        'PUR-SQTN': 'Supplier%20Quotation'
    };

    const lookup4Color = {
        Draft: theme.palette.error.main,
        Return: theme.palette.error.main,
        Paid: theme.palette.success.main,
        'Partly Paid': theme.palette.warning.main,
        Unpaid: theme.palette.warning.main,
        Overdue: theme.palette.error.main,
        'Credit Note Issued': theme.palette.info.main,
        Submitted: theme.palette.success.main,
        'Unpaid and Discounted': theme.palette.warning.main,
        'Partly Paid and Discounted': theme.palette.warning.main,
        'Overdue and Discounted': theme.palette.error.main,
        'Internal Transfer': theme.palette.info.main,
        'On Hold': theme.palette.warning.main,
        'To Deliver and Bill': theme.palette.success.main,
        'To Bill': theme.palette.success.main,
        'To Deliver': theme.palette.success.main,
        Completed: theme.palette.success.main,
        Cancelled: theme.palette.error.main,
        Closed: theme.palette.warning.main,
        Consolidated: theme.palette.success.main,
        'Return Issued': theme.palette.error.main,
        Open: theme.palette.warning.main,
        Replied: theme.palette.info.main,
        Expired: theme.palette.error.main,
        Lost: theme.palette.error.main,
        Ordered: theme.palette.success.main,
        'Partially Ordered': theme.palette.warning.main
    };

    function getDocType(name) {
        for (let key in lookup) {
            if (name.startsWith(key)) {
                return lookup[key];
            }
        }
        return 'Unknown';
    }

    function getColor(name) {
        for (let key in lookup4Color) {
            if (name?.toLowerCase() === key?.toLowerCase()) {
                return lookup4Color[key];
            }
        }
        return theme.palette.info.main;
    }

    const {
        data: doc,
        isPending,
        refetch: refetchDoc,
        docinfo
    } = useGetAxios(`${process.env.REACT_APP_API_URI}/api/details?doctype=${getDocType(id)}&name=${id}`);

    const title = (
        <Box p={-1} display={'flex'} justifyContent={'space-between'}>
            <Box p={-1} display={'flex'} flexDirection={'column'}>
                <ArrowBackIcon
                    className={classes.backLink}
                    onClick={() => {
                        navigate(-1);
                    }}
                />
                <Box display={'flex'} alignItems={'center'}>
                    {`${doc?.doctype}: ${doc?.name}`}
                    <ReactToPrint
                        trigger={() => (
                            <IconButton sx={{ ml: 1 }} aria-label="delete" color="blue">
                                <PrintIcon />
                            </IconButton>
                        )}
                        content={() => componentRef.current}
                        bodyClass="print-body"
                        copyStyles={true}
                        documentTitle={id}
                        pageStyle={`
                                @page {
                                    size: A4;
                                    margin: 1cm;
                                }
                                @media print {
                                    body {
                                        background-color: #f5f5f5;
                                    }
                                    .content {
                                        page-break-before: always;
                                    }
                                    .footer {
                                        position: fixed;
                                        bottom: 0;
                                        left: 0;
                                        opacity: 0.7;
                                        right: 0;
                                        background-color: #f5f5f5;
                                    }
                                }
                            `}
                        removeAfterPrint={true}
                    />
                </Box>
            </Box>
        </Box>
    );

    function status(status) {
        const color = getColor(status);
        return (
            <div
                style={{
                    zIndex: 999,
                    width: 130,
                    height: 130,
                    position: 'absolute',
                    backgroundColor: color,
                    top: 0,
                    right: 0,
                    clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0% 100%)',
                    background: `linear-gradient(to bottom left, ${color} 50%, transparent 50%)`,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 20,
                    border: 'none'
                }}
            >
                <span
                    style={{
                        display: 'inline-block',
                        transform: 'rotate(45deg)',
                        paddingBottom: 50,
                        fontWeight: 600,
                        fontSize: 18,
                        textAlign: 'center',
                        color: 'Menu'
                    }}
                >
                    {status}
                </span>
            </div>
        );
    }

    if (id && isPending) return <Loader />;

    return (
        <MainCard sx={{ position: 'relative' }} title={title}>
            {status(doc?.status)}
            <CustomerOrderDetails doc={doc} isCustomer={false} />
            <Box mt={2}>
                <ProductsOrderDetails doc={doc} isSales={false} />
            </Box>
            {id && (
                <Box mt={2}>
                    <Comments docinfo={docinfo} refetch={refetchDoc} doc={doc} />
                </Box>
            )}

            {doc?.name && (
                <Box display={'none'}>
                    <PrintDocumentsTemplate ref={componentRef} doc={doc} docinfo={docinfo} />
                </Box>
            )}
        </MainCard>
    );
}

export default BuyingDocumentsDetails;
