import React from 'react';
import { Box } from '@mui/system';
import Loader from 'ui-component/Loader';
import useGetAxios from 'hooks/useGetAxios';
import SimpleTable from 'ui-component/simpleTable/SimpleTable';
import { useTranslation } from 'react-i18next';

const OnlineUsers = () => {
    const { t } = useTranslation();
    const { data, isPending } = useGetAxios(`${process.env.REACT_APP_API_URI}/api/users`, null, true);
    const title = (
        <Box p={-1} display={'flex'} justifyContent={'space-between'}>
            {t('users:Connected_salespeople')}
        </Box>
    );

    const columnsName = ['name', 'email', 'role', 'status'];

    if (isPending) return <Loader />;
    return (
        <div>
            <SimpleTable title={title} columnsName={columnsName} data={data} />
        </div>
    );
};

export default OnlineUsers;
