import axios from 'axios';
import { API_BASE_URL } from 'configs/AppConfig';
import history from '../history';

// import { message, notification } from 'antd';
//
const service = axios.create({
    baseURL: API_BASE_URL,
    timeout: 60000
});
// Config
const ENTRY_ROUTE = '/login';
const TOKEN_PAYLOAD_KEY = 'authorization';
const PUBLIC_REQUEST_KEY = 'public-request';
const API_KEY = 'X-API-Key';

// // API Request interceptor
service.interceptors.request.use(
    (config, dispatch) => {
        const jwtToken = JSON.parse(localStorage.getItem('user'))?.token;

        if (jwtToken) {
            config.headers[TOKEN_PAYLOAD_KEY] = `${jwtToken}`;
            config.headers[API_KEY] = `${process.env.REACT_APP_API_KEY}`;
        }

        if (!jwtToken && !config.headers[PUBLIC_REQUEST_KEY]) {
            history.push(ENTRY_ROUTE);
            window.location.reload();
        }

        return config;
    },
    (error) => {
        // Do something with request error here
        //   message.error('Une erreur est survenue');
        Promise.reject(error);
    }
);

// API respone interceptor
service.interceptors.response.use(
    (response) => {
        return response.data;
    },
    (error) => {
        // let notificationParam = {
        //     message: ''
        // };

        // Remove token and redirect
        if (error.response.status === 401) {
            // notificationParam.message = 'Session expirée';
            localStorage.removeItem('user');
            history.push(ENTRY_ROUTE);
            window.location.reload();
        } else {
            //notificationParam.message = "Une erreur est survenue"

            //message.error(notificationParam.message)

            return Promise.reject(error);
        }
    }
);

export default service;
