import React from 'react';
import { useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import { InputLabel, TextField, Button, Grid, OutlinedInput, Divider, Chip, Modal } from '@mui/material';
import { Box } from '@mui/system';
import { useLocation, useNavigate, useParams } from 'react-router';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import useFetch from 'hooks/useFetch';
import Loader from 'ui-component/Loader';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useStyles } from 'views/utilities/Style';
import { useEffect } from 'react';
import moment from 'moment';
import useGetAxios from 'hooks/useGetAxios';
import { SetNotification } from 'store/services/api';
import { formatErpResponse } from 'utils/utils';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import DataRender from 'ui-component/tables/DataRender';
import { modalStyle } from 'views/utilities/ModalStyle';
import { CircularProgressLoader } from 'ui-component/CircularProgressLoader';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { LoadingButton } from '@mui/lab';

const EditSupplierCatalog = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [stateValues, setStateValues] = useState([]);
    const [open, setOpen] = useState(false);
    const [loadingButton, setLoadingButton] = React.useState(false);
    const [loadingMinOrderQty, setLoadingMinOrderQty] = React.useState(false);
    const [loadingPrice, setLoadingPrice] = React.useState(false);
    const [selectionModel, setSelectionModel] = React.useState([]);
    const [itemPriceDetails, setItemPriceDetails] = React.useState(null);

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };
    const [state, setState] = useState({
        name: '',
        price_list_rate: 0,
        lead_time_days: 0,
        valid_from: '',
        valid_upto: ''
    });

    const handleClose = () => {
        setOpen(false);
        setState({ price_list_rate: 0, lead_time_days: 0, valid_upto: '' });
    };

    const handleOpenForm = () => {
        setOpen(true);
        const lastValidDate = getLastValidDate(itemsPricesBySupplier);
        if (lastValidDate) {
            setState({ ...state, valid_from: lastValidDate });
        }
    };

    const [error, setError] = useState({ price_list_rate: false, valid_upto: false });
    const [itemDetails, setItemDetails] = useState({
        min_order_qty: 0
    });

    const handleChangeItem = (e) => {
        setItemDetails({ ...itemDetails, [e.target.name]: e.target.value });
    };
    const handleChangePrice = (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
        // err handlers
        if ((e.target.name === 'price_list_rate' && e.target.value == 0) || (e.target.name === 'valid_upto' && e.target.value == '')) {
            setError({ ...error, [e.target.name]: true });
        } else {
            setError({ ...error, [e.target.name]: false });
        }
    };
    const location = useLocation();
    const itemCode = location.state;
    const { id } = useParams();

    const { data: doc, isPending, refetch } = useGetAxios(`${process.env.REACT_APP_API_URI}/api/details?doctype=Item&name=${itemCode}`);

    const itemsReqDataBySupplier = {
        doctype: 'Item',
        doctype: 'Item Price',
        fields: [
            '`tabItem Price`.`name`',
            '`tabItem Price`.`item_code`',
            '`tabItem Price`.`price_list`',
            '`tabItem Price`.`price_list_rate`',
            '`tabItem Price`.`lead_time_days`',
            '`tabItem Price`.`valid_from`',
            '`tabItem Price`.`valid_upto`'
        ],
        filters: [
            ['Item Price', 'item_code', '=', itemCode],
            ['Item Price', 'supplier', '=', id],
            ['Item Price', 'price_list', '=', 'Standard Buying']
        ]
    };

    const {
        data: itemsPricesBySupplier,
        isPending: isItemPending,
        refetch: refetchItem
    } = useFetch(`/api/get-list`, itemsReqDataBySupplier, 'POST');

    useEffect(() => {
        if (itemsPricesBySupplier) {
            setStateValues({
                ...stateValues,
                itemsPricesBySupplier: itemsPricesBySupplier
            });
        }
        if (doc) {
            setItemDetails({ min_order_qty: doc.min_order_qty });
        }
    }, [id, itemsPricesBySupplier, doc]);

    const getLastValidDate = (array) => {
        const lastDate = array?.findLast((item) => item.valid_upto);
        if (moment(lastDate?.valid_upto, 'YYYY-MM-DD', true).isValid()) {
            return lastDate?.valid_upto;
        } else {
            return null;
        }
    };

    const handleAdd = async () => {
        let data = {
            ...(itemPriceDetails && {
                creation: itemPriceDetails?.creation,
                modified: itemPriceDetails?.modified,
                owner: itemPriceDetails?.owner,
                modified_by: itemPriceDetails?.owner,
                name: itemPriceDetails?.name
            }),
            ...(!itemPriceDetails && { __islocal: 1 }),
            doctype: 'Item Price',
            price_list: 'Standard Buying',
            supplier: id,
            buying: 1,
            selling: 0,
            currency: 'TND',
            item_code: itemPriceDetails?.item_code ? itemPriceDetails?.item_code : itemCode,
            lead_time_days: state?.lead_time_days,
            price_list_rate: state?.price_list_rate,
            valid_from: state?.valid_from,
            valid_upto: state?.valid_upto,
            __unsaved: 1
        };
        setLoadingButton(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doc: data, action: 'Save' })
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: 'An error has occurred' }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    const successMessage = itemPriceDetails ? t('common:priceEditSuccess') : t('common:productAddSuccess');

                    dispatch(SetNotification({ code: 'success', message: successMessage }));
                    setOpen(false);
                    refetchItem({});
                    setState({
                        name: '',
                        price_list_rate: 0,
                        lead_time_days: 0,
                        valid_from: moment().format('YYYY-MM-DD'),
                        valid_upto: ''
                    });
                }
            });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const fieldsToValidate = ['price_list_rate', 'valid_upto'];

        let isValid = true;
        const nextError = { ...error };

        fieldsToValidate.forEach((field) => {
            if (field === 'price_list_rate' && state[field] === 0) {
                nextError[field] = true;
                isValid = false;
            }
            if (field === 'valid_upto' && state[field] === '') {
                nextError[field] = true;
                isValid = false;
            }
        });

        setError(nextError);
        if (isValid) {
            handleAdd();
        }
    };

    const handleUpdateOrderQty = (event) => {
        event.preventDefault();
        const data = {
            ...doc,
            min_order_qty: itemDetails?.min_order_qty
        };
        setLoadingMinOrderQty(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doc: data, action: 'Save' })
        })
            .then((res) => {
                setLoadingMinOrderQty(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: 'An error has occurred' }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    dispatch(SetNotification({ code: 'success', message: t('common:Min_order_Qty_successfully_updated') }));
                }
            });
    };

    const handleEdit = (row) => {
        setLoadingPrice(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/details?doctype=Item+Price&name=${row}`, {
            method: 'GET',
            headers: header
        })
            .then((res) => {
                setLoadingPrice(false);
                return res.json();
            })
            .then((data) => {
                if (data?.docs[0]) {
                    setItemPriceDetails(data.docs[0]);
                    setState({
                        price_list_rate: data.docs[0].price_list_rate,
                        lead_time_days: data.docs[0].lead_time_days,
                        valid_from: data.docs[0].valid_from,
                        valid_upto: data.docs[0].valid_upto
                    });
                }
            });

        setOpen(true);
    };

    const handleDelete = (row) => {
        fetch(`${process.env.REACT_APP_API_URI}/api/delete-doc`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doctype: 'Item Price', name: row[0] })
        })
            .then((res) => {
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    dispatch(SetNotification({ message: 'Prix supprimée avec succès', code: 'success' }));
                    refetchItem({});
                }
            });
    };

    const updatePriceModal = (
        <Box sx={modalStyle}>
            <MainCard
                divider
                title={
                    <Box display={'flex'} alignItems={'center'}>
                        <LocalShippingIcon sx={{ mr: 1 }} /> {t('common:edit')} {itemDetails?.item_code}
                    </Box>
                }
            >
                {loadingPrice ? (
                    <CircularProgressLoader />
                ) : (
                    <Box p={1}>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <TextField
                                    name="price_list_rate"
                                    sx={{ width: '100%' }}
                                    label={t('core:price')}
                                    id="outlined-size-small"
                                    type="number"
                                    value={state.price_list_rate}
                                    error={error.price_list_rate}
                                    onChange={handleChangePrice}
                                    InputLabelProps={{ shrink: true, style: { position: 'absolute', top: 0 } }}
                                />

                                <TextField
                                    name="lead_time_days"
                                    sx={{ width: '100%', mt: 2 }}
                                    label={t('core:leadTimeInDay')}
                                    id="outlined-size-small"
                                    type="number"
                                    value={state.lead_time_days}
                                    onChange={handleChangePrice}
                                    InputLabelProps={{ shrink: true, style: { position: 'absolute', top: 0 } }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    name="valid_from"
                                    value={state.valid_from}
                                    onChange={handleChangePrice}
                                    sx={{ width: '100%' }}
                                    label={t('crm:validfrom')}
                                    id="outlined-size-small"
                                    type="date"
                                    inputProps={{
                                        min:
                                            itemsPricesBySupplier && getLastValidDate(itemsPricesBySupplier) != null
                                                ? getLastValidDate(itemsPricesBySupplier)
                                                : moment().format('YYYY-MM-DD')
                                    }}
                                    InputLabelProps={{ shrink: true, style: { position: 'absolute', top: 0 } }}
                                />

                                <TextField
                                    shrink={true}
                                    name="valid_upto"
                                    sx={{ width: '100%', mt: 2 }}
                                    label={t('crm:validtill')}
                                    id="outlined-size-small"
                                    type="date"
                                    value={state.valid_upto}
                                    error={error.valid_upto}
                                    onChange={handleChangePrice}
                                    inputProps={{
                                        min: moment(state?.valid_from).add(1, 'days').format('YYYY-MM-DD')
                                    }}
                                    InputLabelProps={{ shrink: true, style: { position: 'absolute', top: 0 } }}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                )}

                <Box display={'flex'} justifyContent={'flex-end'} mt={3}>
                    <Box>
                        <Grid container spacing={1}>
                            <Grid item>
                                <Button variant="outlined" color="blue" size="large" onClick={handleClose}>
                                    {t('common:cancel')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <LoadingButton loading={loadingButton} color="blue" size="large" variant="contained" onClick={handleSubmit}>
                                    {t('common:confirm')}
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </MainCard>
        </Box>
    );
    const title = (
        <Box p={-1} display={'flex'} flexDirection={'column'}>
            <ArrowBackIcon className={classes.backLink} onClick={() => navigate(-1)} />
            {t('core:editCatalogSupplier')}
        </Box>
    );
    if (isItemPending && isPending) return <Loader />;
    return (
        <MainCard title={title}>
            <Grid container spacing={2} display={'flex'} justifyContent={'left'} flexDirection={'column'}>
                <form onSubmit={handleUpdateOrderQty}>
                    <Grid container item xs={12} sm={12} md={8} lg={8} xl={8} spacing={4} display={'flex'} alignItems={'center'}>
                        <Grid item xs={4}>
                            <InputLabel className={classes.inputLabel}> {t('products:minOrderQty')}</InputLabel>
                        </Grid>
                        <Grid item xs={8}>
                            <OutlinedInput
                                id="outlined-adornment-weight"
                                endAdornment={
                                    <LoadingButton
                                        loading={loadingMinOrderQty}
                                        color="blue"
                                        type="submit"
                                        variant="contained"
                                        disabled={itemDetails.min_order_qty === 0}
                                    >
                                        Edit
                                    </LoadingButton>
                                }
                                aria-describedby="outlined-weight-helper-text"
                                inputProps={{
                                    'aria-label': 'weight'
                                }}
                                type="number"
                                value={itemDetails.min_order_qty}
                                name="min_order_qty"
                                onChange={handleChangeItem}
                            />
                        </Grid>
                    </Grid>
                </form>
            </Grid>

            <Divider sx={{ m: 2 }}>
                <Chip label={t('core:itemPriceList')} />
            </Divider>
            <MainCard
                divider
                border
                title={
                    <Box display={'flex'}>
                        <LocalShippingIcon sx={{ mr: 1.5, ml: -1.5 }} />
                        {t('core:itemPrice')}
                    </Box>
                }
            >
                <Box mt={2}>
                    <Grid container spacing={1} display={'flex'} alignItems={'left'} justifyContent={'flex-end'}>
                        <Grid item>
                            <Button
                                startIcon={<AddCircleIcon />}
                                variant="outlined"
                                color="blue"
                                onClick={handleOpenForm}
                                sx={{ marginTop: -3 }}
                            >
                                {t('core:addPrice')}
                            </Button>
                        </Grid>
                    </Grid>
                    <DataRender
                        refetch={refetchItem}
                        data={itemsPricesBySupplier}
                        loading={isItemPending}
                        selectionModel={selectionModel}
                        setSelectionModel={setSelectionModel}
                        tag={t('columns:reference')}
                        handleEdit={handleEdit}
                        handleDelete={handleDelete}
                    />

                    <Modal onClose={handleClose} open={open}>
                        {updatePriceModal}
                    </Modal>
                </Box>
            </MainCard>
        </MainCard>
    );
};

export default EditSupplierCatalog;
