import React from 'react';
import { Box } from '@mui/material';
import StockBalance from './components/StockBalance';
import InventoryDashboard from './components/InventoryDashboard';
import PIMChips from './components/PIMChips';
import SalesOrderAnalysis from './components/SalesOrderAnalysis';

const DashboardPIM = () => {
    return (
        <>
            <Box>
                <PIMChips />
            </Box>
            <Box mt={2}>
                <StockBalance />
            </Box>
            <Box mt={2}>
                <InventoryDashboard />
            </Box>
            <Box mt={2}>
                <SalesOrderAnalysis />
            </Box>
        </>
    );
};

export default DashboardPIM;
