import { Box } from '@mui/material';
import useFetch from 'hooks/useFetch';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { SetNotification } from 'store/services/api';
import DataRender from 'ui-component/tables/DataRender';
import { formatErpResponse } from 'utils/utils';

function POSDocumentsList() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const defaultCompany = useSelector((state) => state.login.detailsDefaultCompany);
    const { t } = useTranslation();

    const posClosingReqData = {
        doctype: 'POS Closing Entry',
        fields: [
            '`tabPOS Closing Entry`.`name`',
            '`tabPOS Closing Entry`.`creation`',
            '`tabPOS Closing Entry`.`period_start_date`',
            '`tabPOS Closing Entry`.`period_end_date`',
            '`tabPOS Closing Entry`.`status`',
            '`tabPOS Closing Entry`.`pos_profile`'
        ],
        filters: [['POS Closing Entry', 'company', '=', defaultCompany?.name]],
        start: 0,
        page_length: 50
    };

    const posOpeningReqData = {
        doctype: 'POS Opening Entry',
        fields: [
            '`tabPOS Opening Entry`.`name`',
            '`tabPOS Opening Entry`.`creation`',
            '`tabPOS Opening Entry`.`period_start_date`',
            '`tabPOS Opening Entry`.`period_end_date`',
            '`tabPOS Opening Entry`.`status`',
            '`tabPOS Opening Entry`.`pos_profile`'
        ],
        filters: [
            ['POS Opening Entry', 'company', '=', defaultCompany?.name],
            ['POS Opening Entry', 'status', '=', 'Open']
        ],
        start: 0,
        page_length: 50
    };

    const {
        data: posClosings,
        isPending: isPendingPosClosings,
        refetch: refetchPosClosings
    } = useFetch(`/api/get-list`, posClosingReqData, 'POST');

    const {
        data: posOpenings,
        isPending: isPendingPosOpenings,
        refetch: refetchPosOpenings
    } = useFetch(`/api/get-list`, posOpeningReqData, 'POST');

    function sortObjectsByDate(array) {
        array.sort((a, b) => {
            const dateA = new Date(a.creation);
            const dateB = new Date(b.creation);

            return dateB - dateA;
        });

        return array;
    }

    const [data, setData] = useState([]);
    const [selectionModel, setSelectionModel] = useState([]);

    const handleEdit = (row) => {
        navigate(`/documents/pos-docs/${row}`);
    };

    function handleRefetch(doctype) {
        switch (doctype) {
            case 'POS Closing Entry':
                return refetchPosClosings({});
            case 'POS Opening Entry':
                return refetchPosOpenings({});
            default:
                return;
        }
    }

    const lookup = {
        'POS-CLO': 'POS Closing Entry',
        'POS-OPE': 'POS Opening Entry'
    };

    function getDocType(name) {
        for (let key in lookup) {
            if (name.startsWith(key)) {
                return lookup[key];
            }
        }
        return 'Unknown';
    }

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const handleDelete = (row) => {
        fetch(`${process.env.REACT_APP_API_URI}/api/delete-doc`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doctype: getDocType(row[0]), name: row[0] })
        })
            .then((res) => {
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: 'An error has occurred' }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    dispatch(SetNotification({ code: 'success', message: `${getDocType(row[0])} successfully deleted` }));
                    handleRefetch(getDocType(row[0]));
                }
            });
    };

    useEffect(() => {
        if (!isPendingPosClosings && !isPendingPosOpenings) {
            let result = [];
            posClosings.forEach((el) => {
                result.push({ ...el, doctype: 'Z Report' });
            });
            posOpenings.forEach((el) => {
                result.push({ ...el, doctype: 'X Report' });
            });
            setData(result);
        }
    }, [isPendingPosClosings, isPendingPosOpenings]);

    return (
        <>
            <DataRender
                title={
                    <Box p={-1} display={'flex'} justifyContent={'space-between'}>
                        {t('documents:posd')}
                    </Box>
                }
                isViewAction
                data={sortObjectsByDate(data)}
                loading={isPendingPosClosings || isPendingPosOpenings}
                selectionModel={selectionModel}
                setSelectionModel={setSelectionModel}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
            />
        </>
    );
}

export default POSDocumentsList;
