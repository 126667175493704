import { LoadingButton } from '@mui/lab';
import { Button, Grid, Modal, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import useFetch from 'hooks/useFetch';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { SetNotification } from 'store/services/api';
import MainCard from 'ui-component/cards/MainCard';
import DataRender from 'ui-component/tables/DataRender';
import { formatErpResponse } from 'utils/utils';
import { modalStyle } from 'views/utilities/ModalStyle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import { useEffect } from 'react';
const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)'
        }
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff'
            }
        }
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200
        })
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box'
    }
}));

const TarifPricingRulesList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [selectionModel, setSelectionModel] = useState([]);
    const [loadingBulkEdit, setLoadingBulkEdit] = useState(false);

    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);
    const [enabled, setEnabled] = useState(true);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleEditClose = () => {
        setOpenEdit(false);
        setSelectionModel([]);
    };
    const pricingRulesReqData = {
        doctype: 'Pricing Rule',
        fields: [
            '`tabPricing Rule`.`creation`',
            '`tabPricing Rule`.`name`',
            '`tabPricing Rule`.`title`',
            '`tabPricing Rule`.`applicable_for`',
            '`tabPricing Rule`.`apply_on`',
            '`tabPricing Rule`.`disable`',
            '`tabPricing Rule`.`currency`',
            '`tabPricing Rule`.`priority`'
        ],
        filters: [['Pricing Rule', 'pricing_rule_check', '=', 1]],
        start: 0,
        page_length: 500
    };

    const { data, isPending, refetch } = useFetch(`/api/get-list`, pricingRulesReqData, 'POST');
    const [disable, setDisable] = useState(data.disable);
    const handleBulkChangeStatus = () => {
        const editPriceRulesStatus = {
            doctype: 'Pricing Rule',
            freeze: true,
            docnames: selectionModel,
            action: 'update',
            data: {
                disable: enabled ? 0 : 1
            }
        };
        setLoadingBulkEdit(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/update-status`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(editPriceRulesStatus)
        })
            .then((res) => {
                setLoadingBulkEdit(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                    throw Error('Something went wrong');
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    dispatch(SetNotification({ message: 'Le statut a été modifié avec succès.', code: 'success' }));
                    handleEditClose();
                    refetch({});
                }
            });
    };

    const handleEdit = (row) => {
        navigate(`/pim/pricing-rule/${row}`);
    };

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const handleDelete = (row) => {
        setLoadingButton(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/delete-doc`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doctype: 'Pricing Rule', name: row[0] })
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    dispatch(SetNotification({ code: 'success', message: 'La Règle de Tarification a été supprimée avec succès' }));
                    refetch({});
                    handleClose();
                }
            });
    };

    useEffect(() => {
        if (selectionModel.length > 0) {
            const selectedRules = data.filter((rule) => selectionModel.includes(rule.name));
            const allEnabled = selectedRules.every((rule) => rule.disable === 0);
            setEnabled(allEnabled);
        }
    }, [selectionModel, data]);

    const deleteConfirm = (
        <Box sx={modalStyle}>
            <MainCard divider title={t('common:confirm')}>
                <Typography id="modal-modal-description" sx={{ fontSize: 14 }}>
                    {t('common:deletePermanently')} <strong>{selectionModel}</strong> ?
                </Typography>
                <Box display={'flex'} justifyContent={'flex-end'} mt={3}>
                    <Box>
                        <Grid container spacing={1}>
                            <Grid item>
                                <Button color="blue" size="large" variant="outlined" onClick={handleClose}>
                                    {t('common:non')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <LoadingButton
                                    color="blue"
                                    size="large"
                                    onClick={() => handleDelete(selectionModel)}
                                    variant="contained"
                                    loading={loadingButton}
                                >
                                    {t('common:oui')}
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </MainCard>
        </Box>
    );
    const changeStatusConfirm = (
        <Box sx={modalStyle}>
            <MainCard divider title={t('core:confirm')}>
                <Typography id="modal-modal-description" sx={{ fontSize: 14 }}>
                    {t('core:changeStatus')} <strong>{selectionModel.join(', ')}</strong> ?
                </Typography>
                <Box display={'flex'} justifyContent={'center'} mt={3}>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <AntSwitch
                            checked={enabled}
                            onChange={() => setEnabled(!enabled)}
                            name="enabled"
                            inputProps={{ 'aria-label': 'enable/disable' }}
                        />
                        <Typography>{enabled ? t('core:activated') : t('core:deactivated')}</Typography>
                    </Stack>
                </Box>
                <Box display={'flex'} justifyContent={'flex-end'} mt={3}>
                    <Box>
                        <Grid container spacing={1}>
                            <Grid item>
                                <Button color="blue" size="large" variant="outlined" onClick={handleEditClose}>
                                    {t('users:no')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <LoadingButton
                                    color="blue"
                                    size="large"
                                    onClick={() => handleBulkChangeStatus()}
                                    variant="contained"
                                    loading={loadingBulkEdit}
                                >
                                    {t('users:yes')}
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </MainCard>
        </Box>
    );

    const title = (
        <Box p={-1} display={'flex'} justifyContent={'space-between'}>
            {t('crm:pricingrules')}
            <Box>
                <Grid container spacing={1}>
                    <Grid item>
                        <Button
                            startIcon={<AddCircleIcon />}
                            variant="outlined"
                            color="blue"
                            onClick={() => navigate('/pim/pricing-rule')}
                            sx={{ marginTop: -3 }}
                        >
                            {t('crm:addpricingrules')}
                        </Button>
                    </Grid>
                    {Array.isArray(selectionModel) && selectionModel?.length !== 0 && (
                        <Grid item>
                            <Button
                                startIcon={<EditIcon color="warning" />}
                                variant="outlined"
                                color="warning"
                                sx={{ marginTop: -3 }}
                                spacing={1}
                                onClick={() => setOpenEdit(true)}
                            >
                                {t('core:editStatus')}
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </Box>
        </Box>
    );

    return (
        <div>
            <DataRender
                refetch={refetch}
                data={data}
                loading={isPending}
                selectionModel={selectionModel}
                title={title}
                setSelectionModel={setSelectionModel}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                hasCheckBoxes
            />
            <Modal onClose={handleClose} open={open}>
                {deleteConfirm}
            </Modal>
            <Modal onClose={handleEditClose} open={openEdit}>
                {changeStatusConfirm}
            </Modal>
        </div>
    );
};

export default TarifPricingRulesList;
