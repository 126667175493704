import { useState, useEffect } from 'react';
import service from '../network/intercaptor';

const useFetch = (url, reqData, method) => {
    const [data, setData] = useState([]);
    const [isPending, setIsPending] = useState(true);
    const [error, setError] = useState(null);
    const [shouldRefetch, refetch] = useState({}); // triggering a force rerender

    let config = {
        method: 'POST',
        url: `/api/get-list`,
        data: [],
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-API-Key': `${process.env.REACT_APP_API_KEY}`
        }
    };

    useEffect(() => {
        setIsPending(true);
        service
            .request({ ...config, url: url, method: method, data: reqData })
            .then((data) => {
                if (data?.message || data?.message == 0) {
                    setData(data?.message);
                } else {
                    setData(data);
                }

                setIsPending(false);
                setError(null);
            })
            .catch((err) => {
                setIsPending(false);
                setError(err.message);
            });
    }, [url, shouldRefetch]);

    return { data, isPending, error, refetch };
};
export default useFetch;
