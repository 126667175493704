import { Box } from '@mui/material';
import useFetch from 'hooks/useFetch';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { SetNotification } from 'store/services/api';
import DataRender from 'ui-component/tables/DataRender';
import { formatErpResponse } from 'utils/utils';

function BuyingDocumentsList() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const defaultCompany = useSelector((state) => state.login.detailsDefaultCompany);
    const { t } = useTranslation();

    const purchaseOrderReqData = {
        doctype: 'Purchase Order',
        fields: [
            '`tabPurchase Order`.`name`',
            '`tabPurchase Order`.`creation`',
            '`tabPurchase Order`.`supplier_name`',
            '`tabPurchase Order`.`status`'
        ],
        filters: [['Purchase Order', 'company', '=', defaultCompany?.name]],
        start: 0,
        page_length: 50
    };

    const purchaseInvoiceReqData = {
        doctype: 'Purchase Invoice',
        fields: [
            '`tabPurchase Invoice`.`name`',
            '`tabPurchase Invoice`.`creation`',
            '`tabPurchase Invoice`.`title`',
            '`tabPurchase Invoice`.`status`'
        ],
        filters: [['Purchase Invoice', 'company', '=', defaultCompany?.name]],
        start: 0,
        page_length: 50
    };

    const purchaseReceiptReqData = {
        doctype: 'Purchase Receipt',
        fields: [
            '`tabPurchase Receipt`.`name`',
            '`tabPurchase Receipt`.`creation`',
            '`tabPurchase Receipt`.`title`',
            '`tabPurchase Receipt`.`status`'
        ],
        filters: [['Purchase Receipt', 'company', '=', defaultCompany?.name]],
        start: 0,
        page_length: 50
    };

    const supplierQuotationReqData = {
        doctype: 'Supplier Quotation',
        fields: [
            '`tabSupplier Quotation`.`name`',
            '`tabSupplier Quotation`.`creation`',
            '`tabSupplier Quotation`.`title`',
            '`tabSupplier Quotation`.`status`'
        ],
        filters: [['Supplier Quotation', 'company', '=', defaultCompany?.name]],
        start: 0,
        page_length: 50
    };

    const {
        data: purchaseOrders,
        isPending: isPendingPurchaseOrders,
        refetch: refetchPurchaseOrders
    } = useFetch(`/api/get-list`, purchaseOrderReqData, 'POST');

    const {
        data: purchaseInvoices,
        isPending: isPendingPurchaseInvoices,
        refetch: refetchPurchaseInvoices
    } = useFetch(`/api/get-list`, purchaseInvoiceReqData, 'POST');

    const {
        data: purchaseReceipts,
        isPending: isPendingPurchaseReceipts,
        refetch: refetchPurchaseReceipts
    } = useFetch(`/api/get-list`, purchaseReceiptReqData, 'POST');

    const {
        data: supplierQuotations,
        isPending: isPendingSupplierQuotations,
        refetch: refetchSupplierQuotations
    } = useFetch(`/api/get-list`, supplierQuotationReqData, 'POST');

    function sortObjectsByDate(array) {
        array.sort((a, b) => {
            const dateA = new Date(a.creation);
            const dateB = new Date(b.creation);

            return dateB - dateA;
        });

        return array;
    }

    const [data, setData] = useState([]);
    const [selectionModel, setSelectionModel] = useState([]);

    const handleEdit = (row) => {
        navigate(`/documents/buying-docs/${row}`);
    };

    function handleRefetch(doctype) {
        switch (doctype) {
            case 'Purchase Order':
                return refetchPurchaseOrders({});
            case 'Purchase Invoice':
                return refetchPurchaseInvoices({});
            case 'Purchase Receipt':
                return refetchPurchaseReceipts({});
            case 'Supplier Quotation':
                return refetchSupplierQuotations({});
            default:
                return;
        }
    }

    const lookup = {
        'PUR-ORD': 'Purchase Order',
        'ACC-PINV': 'Purchase Invoice',
        'MAT-PRE': 'Purchase Receipt',
        'PUR-SQTN': 'Supplier Quotation'
    };

    function getDocType(name) {
        for (let key in lookup) {
            if (name.startsWith(key)) {
                return lookup[key];
            }
        }
        return 'Unknown';
    }

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const handleDelete = (row) => {
        fetch(`${process.env.REACT_APP_API_URI}/api/delete-doc`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doctype: getDocType(row[0]), name: row[0] })
        })
            .then((res) => {
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: 'An error has occurred' }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    dispatch(SetNotification({ code: 'success', message: `${getDocType(row[0])} successfully deleted` }));
                    handleRefetch(getDocType(row[0]));
                }
            });
    };

    useEffect(() => {
        if (!isPendingPurchaseOrders && !isPendingPurchaseInvoices && !isPendingPurchaseReceipts && !isPendingSupplierQuotations) {
            let result = [];
            purchaseOrders.forEach((el) => {
                result.push({
                    name: el.name,
                    creation: el.creation,
                    supplier: el.supplier_name,
                    status: el.status,
                    document: 'Purchase Order'
                });
            });
            purchaseInvoices.forEach((el) => {
                result.push({ name: el.name, creation: el.creation, supplier: el.title, status: el.status, document: 'Purchase Invoice' });
            });
            purchaseReceipts.forEach((el) => {
                result.push({ name: el.name, creation: el.creation, supplier: el.title, status: el.status, document: 'Purchase Receipt' });
            });
            supplierQuotations.forEach((el) => {
                result.push({
                    name: el.name,
                    creation: el.creation,
                    supplier: el.title,
                    status: el.status,
                    document: 'Supplier Quotation'
                });
            });
            setData(result);
        }
    }, [isPendingPurchaseOrders, isPendingPurchaseInvoices, isPendingPurchaseReceipts, isPendingSupplierQuotations]);

    return (
        <>
            <DataRender
                title={
                    <Box p={-1} display={'flex'} justifyContent={'space-between'}>
                        {t('documents:pd')}
                    </Box>
                }
                isViewAction
                data={sortObjectsByDate(data)}
                loading={isPendingPurchaseOrders || isPendingPurchaseInvoices || isPendingPurchaseReceipts || isPendingSupplierQuotations}
                selectionModel={selectionModel}
                setSelectionModel={setSelectionModel}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
            />
        </>
    );
}

export default BuyingDocumentsList;
