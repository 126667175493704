import React from 'react';
import { useState, useRef, useEffect } from 'react';

// material-ui
import { useTheme, styled } from '@mui/material/styles';
import {
    Box,
    Button,
    CardActions,
    Chip,
    ClickAwayListener,
    Divider,
    Grid,
    Paper,
    Popper,
    Typography,
    useMediaQuery,
    Badge,
    IconButton,
    Drawer,
    Snackbar,
    SnackbarContent,
    Slide
} from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';
// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import NotificationList from './NotificationList';

// assets
import { IconBell } from '@tabler/icons';
import { useTranslation } from 'react-i18next';
import { FormatNotifications as formatBroadcast } from 'helpers/utilsBrodcast';

import { io } from 'socket.io-client';
import { Brodcast } from 'helpers/brodcast';
import { SetNotificationBrodcast } from 'store/services/api';
import useFetch from 'hooks/useFetch';
import { FormatNotifications } from 'helpers/utils';

// ==============================|| NOTIFICATION ||============================== //
const TransitionDown = (props) => {
    return <Slide {...props} direction="down" />;
};
const NotificationSection = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
    const [open, setOpen] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [keyMsgBrodcast, setKeyMsgBrodcast] = useState(0);

    const [filter, setFilter] = useState('All');

    const { data: notificationList, isPending, refetch: refetchNotifications } = useFetch(`/api/notifications`, {}, 'POST');

    const notificationBrodcast = useSelector((state) => state.notification.notificationBrodcast);

    function readByCurrentUser(users, currentUserId) {
        return users?.some((user) => user.id === currentUserId);
    }

    function getUnreadNotifsPerUser() {
        let currentUserId = JSON.parse(localStorage.getItem('user'))?.id;
        if (!notificationList || !currentUserId || notificationList?.length === 0) {
            return null;
        } else {
            const notificationRows = notificationList?.filter((e) => e != null && e != undefined);
            let resultRows = [];
            notificationRows?.forEach((el) => {
                if (!readByCurrentUser(el.users, currentUserId)) {
                    resultRows.push(el);
                }
            });
            const formatted = FormatNotifications(resultRows)?.filter((e) => e != null && e != undefined);

            if (formatted?.length) {
                return formatted?.length;
            } else {
                return null;
            }
        }
    }

    const StyledBadge = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-badge': {
            border: `2px solid ${theme.palette.background.paper}`
        }
    }));

    const anchorRef = useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleCloseSnackbar = () => {
        dispatch(SetNotificationBrodcast(null));
    };

    const prevOpen = useRef(open);

    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    useEffect(() => {
        if (localStorage.getItem('user')) {
            var socket = io(`${process.env.REACT_APP_BROADCASTER_URL}`);
            socket.on('connect', function () {
                let id = process.env.REACT_APP_COMPANY;
                console.log(`socket ${id} connected`);
                socket.emit('join', id);
            });
            const talonProps = Brodcast(dispatch);

            socket.on('broadcast', (arg) => {
                if (socket.connected) {
                    setKeyMsgBrodcast(keyMsgBrodcast + 1);
                    if (localStorage.getItem('user')) {
                        let content = formatBroadcast(arg.response);
                        console.log('msg from socket');
                        dispatch(SetNotificationBrodcast(content));
                        refetchNotifications({});
                    }
                }
            });
        }
    }, [localStorage.getItem('user')]);

    return (
        <>
            {/* Icon */}
            <Box
                sx={{
                    ml: 2,
                    mr: 3,
                    [theme.breakpoints.down('md')]: {
                        mr: 2
                    }
                }}
            >
                <IconButton
                    ref={anchorRef}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    color="inherit"
                    aria-label="cart"
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.mediumAvatar,
                        transition: 'all .2s ease-in-out',
                        background: 'transparent !important',
                        color: theme.palette.primary.dark,
                        borderRadius: '12px'
                    }}
                >
                    <StyledBadge badgeContent={getUnreadNotifsPerUser()} color="blue">
                        <IconBell stroke={1.5} size="1.3rem" />
                    </StyledBadge>
                </IconButton>
            </Box>

            {/* Snackbar */}
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={notificationBrodcast ? true : false}
                onClose={handleCloseSnackbar}
                maxSnack={3}
                key={keyMsgBrodcast}
                autoHideDuration={2000}
                TransitionComponent={TransitionDown}
            >
                <SnackbarContent
                    style={{
                        backgroundColor: '#2F4B7C',
                        color: '#fff',
                        border: 'none'
                    }}
                    message={<span id="client-snackbar">{notificationBrodcast ?? notificationBrodcast}</span>}
                />
            </Snackbar>

            {/* Popper */}
            <Popper
                placement={matchesXs ? 'bottom' : 'bottom-end'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [matchesXs ? 5 : 0, 20]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions position={matchesXs ? 'top' : 'top-right'} in={open} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                    <Grid container direction="column">
                                        <Grid item xs={12} sx={{ backgroundColor: 'Menu', width: '100%', p: 2 }}>
                                            <div
                                                style={{
                                                    width: '100%',
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between'
                                                }}
                                            >
                                                <Typography variant="h3" color={'black'} fontWeight={800}>
                                                    {t('common:allNotification')}
                                                </Typography>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    <Chip
                                                        component={'button'}
                                                        onClick={() => setFilter('All')}
                                                        size="small"
                                                        label={'All'}
                                                        sx={{
                                                            marginLeft: 1,
                                                            cursor: 'pointer'
                                                        }}
                                                    />
                                                    <Chip
                                                        component={'button'}
                                                        onClick={() => setFilter('Unread')}
                                                        size="small"
                                                        label={'Unread'}
                                                        sx={{
                                                            marginLeft: 1,
                                                            color: theme.palette.background.default,
                                                            bgcolor: theme.palette.blue.main,
                                                            cursor: 'pointer'
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </Grid>
                                        <Divider />
                                        <Grid item xs={12}>
                                            <NotificationList
                                                filter={filter}
                                                isPopper={true}
                                                notificationList={notificationList}
                                                refetchNotifications={refetchNotifications}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Divider />
                                    <CardActions sx={{ p: 0, justifyContent: 'center', backgroundColor: 'Menu' }}>
                                        <Button
                                            sx={{ width: '100%', height: '100%' }}
                                            disableElevation
                                            variant="text"
                                            color="blue"
                                            onClick={(e) => {
                                                setOpenDrawer(true);
                                                setOpen(false);
                                            }}
                                        >
                                            {t('common:viewAll')}
                                        </Button>
                                    </CardActions>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>

            <Drawer variant="temporary" anchor={'right'} sx={{ left: 'auto' }} onClose={() => setOpenDrawer(false)} open={openDrawer}>
                <PerfectScrollbar>
                    <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                        <NotificationList
                            filter={filter}
                            isPopper={false}
                            notificationList={notificationList}
                            refetchNotifications={refetchNotifications}
                        />
                    </MainCard>
                </PerfectScrollbar>
            </Drawer>
        </>
    );
};

export default NotificationSection;
