import React from 'react';
import MainCard from 'ui-component/cards/MainCard';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box } from '@mui/system';
import InputTextField from 'ui-component/form/InputTextField';
import SelectForm from 'ui-component/form/SelectForm';
import InputCheckboxField from 'ui-component/form/InputCheckboxField';
import { LoadingButton } from '@mui/lab';
import { useEffect } from 'react';
import Loader from 'ui-component/Loader';
import { formatErpResponse } from 'utils/utils';
import { useDispatch } from 'react-redux';
import { SetNotification } from 'store/services/api';
import { useTranslation } from 'react-i18next';
import useFetch from 'hooks/useFetch';
import useGetAxios from 'hooks/useGetAxios';

const BuyingSettings = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [loadingButton, setLoadingButton] = React.useState(false);

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const {
        data: doc,
        isPending,
        refetch: refetchDoc,
        docinfo
    } = useGetAxios(`${process.env.REACT_APP_API_URI}/api/details?doctype=Buying%20Settings&name=Buying%20Settings`);

    const [state, setState] = React.useState({
        supp_master_name: '',
        buying_price_list: '',
        maintain_same_rate_action: '',
        po_required: '',
        pr_required: '',
        maintain_same_rate: 0,
        allow_multiple_items: 0,
        bill_for_rejected_quantity_in_purchase_invoice: 0,
        backflush_raw_materials_of_subcontract_based_on: '',
        over_transfer_allowance: 0,
        supplier_group: '',
        role_to_override_stop_action: ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setState((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleChangeCheckbox = (event, value) => {
        setState({ ...state, [event.target.name]: value ? 1 : 0 });
    };

    const pricelistReqData = {
        doctype: 'Price List',
        fields: ['`tabPrice List`.`name`'],
        filters: [['Price List', 'buying', '=', 1]]
    };
    const { data: priceLists, isPending: isPendingPriceLists } = useFetch(`/api/get-list`, pricelistReqData, 'POST');

    const supplierGroupReqData = {
        doctype: 'Supplier Group',
        fields: ['`tabSupplier Group`.`name`']
    };
    const { data: supplierGroups, isPending: isPendingSupplierGroups } = useFetch(`/api/get-list`, supplierGroupReqData, 'POST');

    const roleListreqData = {
        doctype: 'Role',
        fields: ['`tabRole`.`name`']
    };

    const { data: roleLists, isPending: isPendingRoleLists } = useFetch(`/api/get-list`, roleListreqData, 'POST');

    useEffect(() => {
        if (doc) {
            setState({
                supp_master_name: doc.supp_master_name,
                buying_price_list: doc.buying_price_list,
                maintain_same_rate_action: doc.maintain_same_rate_action,
                po_required: doc.po_required,
                pr_required: doc.pr_required,
                maintain_same_rate: doc.maintain_same_rate,
                allow_multiple_items: doc.allow_multiple_items,
                bill_for_rejected_quantity_in_purchase_invoice: doc.bill_for_rejected_quantity_in_purchase_invoice,
                backflush_raw_materials_of_subcontract_based_on: doc.backflush_raw_materials_of_subcontract_based_on,
                over_transfer_allowance: doc.over_transfer_allowance,
                supplier_group: doc.supplier_group,
                role_to_override_stop_action: doc.role_to_override_stop_action
            });
        }
    }, [doc]);

    const handleSubmit = () => {
        const data = {
            ...doc,
            doctype: 'Buying Settings',
            supp_master_name: state.supp_master_name,
            buying_price_list: state.buying_price_list,
            maintain_same_rate_action: state.maintain_same_rate_action,
            po_required: state.po_required,
            pr_required: state.pr_required,
            maintain_same_rate: state.maintain_same_rate,
            allow_multiple_items: state.allow_multiple_items,
            bill_for_rejected_quantity_in_purchase_invoice: state.bill_for_rejected_quantity_in_purchase_invoice,
            backflush_raw_materials_of_subcontract_based_on: state.backflush_raw_materials_of_subcontract_based_on,
            over_transfer_allowance: state.over_transfer_allowance,
            supplier_group: state.supplier_group,
            role_to_override_stop_action: state.role_to_override_stop_action
        };
        setLoadingButton(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doc: data, action: 'Save' })
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'error', message: formatErpResponse(data?.message) }));
                } else {
                    dispatch(SetNotification({ code: 'success', message: 'Buying Settings successfully updated' }));
                    refetchDoc({});
                }
            });
    };
    const handleNumericInputChange = (event) => {
        const inputValue = event.target.value;

        if (/^\d*\.?\d*$/.test(inputValue)) {
            setState((prevState) => ({
                ...prevState,
                over_transfer_allowance: inputValue
            }));
        }
    };

    const supplierMasterNameOptions = ['Supplier Name', 'Naming Series', 'Auto Name'];
    const porequiredOptions = ['Yes', 'No'];
    const roprequiredOptions = ['Yes', 'No'];
    const backflushRawMaterialsOptions = ['Material Transferred for Subcontract', 'BOM'];
    const maintainSameRateOptions = ['Warn', 'Stop'];

    if (isPending && (isPendingPriceLists || isPendingSupplierGroups || isPendingRoleLists)) return <Loader />;

    return (
        <>
            <MainCard
                border
                isWhite
                boxShadow
                divider
                title={
                    <Box display={'flex'} alignItems={'center'}>
                        <SettingsIcon sx={{ mr: 1 }} />
                        {t('core:buyingsettings')}
                    </Box>
                }
                sx={{ height: '70%', mt: 2 }}
            >
                <Box display="flex" flexWrap="wrap">
                    <Box sx={{ width: '50%', pr: 2 }}>
                        <SelectForm
                            fullwidth
                            label={t('core:supplier-master-name')}
                            name="supp_master_name"
                            value={state.supp_master_name}
                            sx={{ mb: 2 }}
                            data={supplierMasterNameOptions}
                            handleChange={handleChange}
                        />
                    </Box>
                    <Box sx={{ width: '50%', pr: 2 }}>
                        <SelectForm
                            fullwidth
                            label={t('core:prrequired')}
                            name="pr_required"
                            value={state.po_required}
                            sx={{ mb: 2 }}
                            data={roprequiredOptions}
                            handleChange={handleChange}
                        />
                    </Box>
                    <Box sx={{ width: '50%', pr: 2 }}>
                        <SelectForm
                            fullwidth
                            label={t('core:dfltsuppliergrp')}
                            data={supplierGroups ?? []}
                            name="supplier_group"
                            value={state.supplier_group}
                            sx={{ mb: 2 }}
                            handleChange={handleChange}
                            propToRender={'name'}
                        />
                    </Box>
                    <Box sx={{ width: '50%', pr: 2 }}>
                        <SelectForm
                            fullwidth
                            label={t('core:porequired')}
                            name="po_required"
                            value={state.pr_required}
                            sx={{ mb: 2 }}
                            data={porequiredOptions}
                            handleChange={handleChange}
                        />
                    </Box>
                    <Box sx={{ width: '50%', pr: 2 }}>
                        <SelectForm
                            fullwidth
                            label={t('core:dfltpricelist')}
                            data={priceLists ?? []}
                            name="buying_price_list"
                            value={state.buying_price_list}
                            sx={{ mb: 2 }}
                            handleChange={handleChange}
                            propToRender={'name'}
                        />
                    </Box>
                    <Box sx={{ width: '50%', pr: 2 }}>
                        <InputCheckboxField
                            fullwidth
                            label={t('core:mntnrate')}
                            name="maintain_same_rate"
                            value={state.maintain_same_rate === 1 ? true : false}
                            sx={{ mb: 2 }}
                            handleChange={handleChangeCheckbox}
                        />
                    </Box>
                    <Box sx={{ width: '50%', pr: 2 }}>
                        <SelectForm
                            fullwidth
                            label={t('core:mtnrateact')}
                            name="maintain_same_rate_action"
                            value={state.maintain_same_rate_action}
                            sx={{ mb: 2 }}
                            data={maintainSameRateOptions}
                            handleChange={handleChange}
                            helper={t('core:mntnhlpr')}
                        />
                    </Box>
                    <Box sx={{ width: '50%', pr: 2 }}>
                        <InputCheckboxField
                            fullwidth
                            label={t('core:allowmultitems')}
                            name="allow_multiple_items"
                            value={state.allow_multiple_items === 1 ? true : false}
                            sx={{ mb: 2 }}
                            handleChange={handleChangeCheckbox}
                        />
                    </Box>
                    <Box sx={{ width: '50%', pr: 2 }}>
                        <SelectForm
                            fullwidth
                            label={t('core:roletooverride')}
                            data={roleLists ?? []}
                            name="role_to_override_stop_action"
                            value={state.role_to_override_stop_action}
                            sx={{ mb: 2 }}
                            handleChange={handleChange}
                            propToRender={'name'}
                        />
                    </Box>
                    <Box sx={{ width: '50%', pr: 2 }}>
                        <InputCheckboxField
                            fullwidth
                            label={t('core:billforrejected')}
                            name="bill_for_rejected_quantity_in_purchase_invoice"
                            value={state.bill_for_rejected_quantity_in_purchase_invoice === 1 ? true : false}
                            sx={{ mb: 2 }}
                            handleChange={handleChangeCheckbox}
                        />
                    </Box>
                </Box>
            </MainCard>
            <MainCard
                border
                isWhite
                boxShadow
                divider
                title={
                    <Box display={'flex'} alignItems={'center'}>
                        <SettingsIcon sx={{ mr: 1 }} /> {t('core:Subcontract')}
                    </Box>
                }
                sx={{ height: '40%', mt: 2 }}
            >
                <Box display="flex" flexWrap="wrap">
                    <Box sx={{ width: '50%', pr: 2 }}>
                        <SelectForm
                            fullwidth
                            label={t('core:backraw')}
                            name="backflush_raw_materials_of_subcontract_based_on"
                            value={state.backflush_raw_materials_of_subcontract_based_on}
                            sx={{ mb: 2 }}
                            data={backflushRawMaterialsOptions}
                            handleChange={handleChange}
                        />
                    </Box>
                    <Box sx={{ width: '50%', pr: 2 }}>
                        {state.backflush_raw_materials_of_subcontract_based_on === 'BOM' && (
                            <InputTextField
                                fullwidth
                                label={t('core:overtransfertoal')}
                                name="over_transfer_allowance"
                                value={state.over_transfer_allowance}
                                handleChange={handleNumericInputChange}
                                helper={t('core:overTRhelper')}
                            />
                        )}
                    </Box>
                </Box>
            </MainCard>
            <Box display={'flex'} justifyContent={'right'} mt={3}>
                <LoadingButton variant="contained" size="large" color="blue" onClick={handleSubmit} loading={loadingButton}>
                    {t('settings:text-50')}
                </LoadingButton>
            </Box>
        </>
    );
};

export default BuyingSettings;
