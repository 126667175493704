// assets
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import { ROLE } from '../configs/constants';

// constant
const icons = {
    CategoryOutlinedIcon
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pim = {
    id: 'pim',
    title: 'PIM',
    caption: 'Gestion des informations sur les articles',
    type: 'group',
    role: [ROLE.ADMIN, ROLE.CASHIER, ROLE.ADMIN_WAREHOUSE],
    children: [
        {
            id: 'PIM',
            title: 'dashboard:PIM',
            type: 'collapse',
            icon: icons.CategoryOutlinedIcon,
            children: [
                {
                    id: 'pim-dashboard',
                    title: 'Dashboard',
                    type: 'item',
                    url: '/pim/dashboard'
                },
                {
                    id: 'product',
                    title: 'common:prdcts',
                    type: 'collapse',
                    children: [
                        {
                            id: 'products',
                            title: 'common:prdct',
                            type: 'item',
                            url: '/pim/products-view'
                        },
                        {
                            id: 'products-modele',
                            title: 'products:prodgenerique',
                            type: 'item',
                            url: '/pim/products-modele-view'
                        },
                        {
                            id: 'products-variant',
                            title: 'products:variants',
                            type: 'item',
                            url: '/pim/products-variant-view'
                        },
                        {
                            id: 'etiquettes',
                            title: 'products:etiquette',
                            type: 'item',
                            url: '/pim/etiquette'
                        }
                    ]
                },

                // {
                //     id: 'canaux',
                //     title: 'common:canals',
                //     type: 'item',
                //     url: '/pim/canaux'
                // },
                {
                    id: 'product-bundles',
                    title: 'common:ep',
                    type: 'item',
                    url: '/pim/product-bundles'
                },
                {
                    id: 'product-financier',
                    title: 'products:financierList',
                    type: 'item',
                    url: '/pim/products-financier'
                },
                {
                    id: 'product-provision',
                    title: 'products:prestations',
                    type: 'item',
                    url: '/pim/products-provision'
                },
                {
                    id: 'brand',
                    title: 'products:brands',
                    type: 'item',
                    url: '/pim/brand'
                },
                {
                    id: 'tarifs',
                    title: 'core:menutariff',
                    type: 'collapse',
                    children: [
                        {
                            id: 'regletarifs',
                            title: 'core:tarifrule',
                            type: 'item',
                            url: '/pim/pricing-rules'
                        },
                        {
                            id: 'tarifspromotionnels',
                            title: 'products:tarifpromo',
                            type: 'item',
                            url: '/pim/promotional_scheme'
                        },
                        {
                            id: 'tarif-article',
                            title: 'products:tarif_article',
                            type: 'item',
                            url: '/pim/tarif-article'
                        }
                    ]
                },
                {
                    id: 'pricelist',
                    title: 'core:pricelistsmenuselling',
                    type: 'collapse',
                    children: [
                        {
                            id: 'products',
                            title: 'core:pricelistsmenutypesselling',
                            type: 'item',
                            url: '/pim/tarifs'
                        }
                    ]
                },
                {
                    id: 'setting-product',
                    title: 'products:pimsettings',
                    type: 'collapse',
                    children: [
                        {
                            id: 'product-attributes',
                            title: 'common:tdv',
                            type: 'item',
                            url: '/pim/product-attributes'
                        },
                        {
                            id: 'customize-form',
                            title: 'common:tatt',
                            type: 'item',
                            url: '/pim/customize'
                        },
                        {
                            id: 'product-collections',
                            title: 'common:coll',
                            type: 'item',
                            url: '/pim/product-collections'
                        },
                        {
                            id: 'products-category',
                            title: 'common:prod-cat',
                            type: 'item',
                            url: '/pim/products-category-list-view'
                        },
                        {
                            id: 'famille-products',
                            title: 'products:famprod',
                            type: 'collapse',
                            children: [
                                {
                                    id: 'add-famille-products',
                                    title: 'products:famille-des-produits',
                                    type: 'item',
                                    url: '/pim/famille-products-view'
                                },
                                {
                                    id: 'add-famille-products',
                                    title: 'products:Sous-famille-des-produits',
                                    type: 'item',
                                    url: '/pim/sous-famille-products-view'
                                }
                            ]
                        },
                        {
                            id: 'product-files',
                            title: 'common:ass',
                            type: 'item',
                            url: '/pim/product-files'
                        },
                        {
                            id: 'mesures',
                            title: 'common:mesures',
                            type: 'collapse',
                            children: [
                                {
                                    id: 'foms',
                                    title: 'common:famille-de-mesure',
                                    type: 'item',
                                    url: '/pim/family-measures'
                                },
                                {
                                    id: 'uoms',
                                    title: 'common:unité-de-mesure',
                                    type: 'item',
                                    url: '/pim/uoms'
                                }
                            ]
                        },
                        {
                            id: 'libre-products',
                            title: 'products:table_libre_pro',
                            type: 'collapse',
                            children: [
                                {
                                    id: 'libre',
                                    title: 'core:libres',
                                    type: 'item',
                                    url: '/pim/table_libre'
                                },
                                {
                                    id: 'soustable_libre',
                                    title: 'products:sous-tables',
                                    type: 'item',
                                    url: '/pim/soustable-libre'
                                }
                            ]
                        },
                        {
                            id: 'barcode_settings',
                            title: 'products:barcode_settings',
                            type: 'collapse',
                            children: [
                                {
                                    id: 'barcode_settings',
                                    title: 'products:barcode_settings',
                                    type: 'item',
                                    url: '/pim/barcode_settings'
                                }
                            ]
                        },
                        {
                            id: 'products',
                            title: 'products:Product_Prefix',
                            type: 'item',
                            url: '/pim/product-prefix'
                        }
                    ]
                }
            ]
        }
    ]
};

export default pim;
