import useFetch from 'hooks/useFetch';
import { useParams } from 'react-router';
import Loader from 'ui-component/Loader';

import { Box, Typography } from '@mui/material';
import React from 'react';
import MainCard from 'ui-component/cards/MainCard';
import PersonIcon from '@mui/icons-material/Person';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { formatDate } from 'helpers/utils';
import { CurrencyFormatter } from 'utils/utils';

function CoffreOperationDetails() {
    const { id } = useParams();
    const { t } = useTranslation();
    const globalDefaults = useSelector((state) => state.login.globalDefaults);
    const { data, isPending } = useFetch(`/api/coffre-operation`, { id: id }, 'POST');

    if (id && isPending) return <Loader />;
    const doc = {};
    return (
        <div>
            <MainCard
                border
                isWhite
                title={
                    <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} gap={5}>
                        <Box display={'flex'} alignItems={'center'}>
                            <PersonIcon sx={{ mr: 1 }} /> {t('documents:ud')}
                            <RequestQuoteIcon sx={{ mr: 1 }} /> {t('documents:cd')}
                        </Box>
                        <Typography color={'ActiveCaption'} variant="body2" fontWeight={500}>
                            {t('documents:placed')}: <strong>{formatDate(data?.created_at, 'DD-MM-YYYY HH:mm')}</strong>
                        </Typography>
                    </Box>
                }
                boxShadow
                divider
            >
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: 50 }}>
                    <div>
                        <Typography color={'GrayText'} variant="body2" fontWeight={500}>
                            {t('documents:comp')}:
                            <Typography color={'ActiveCaption'} variant="body2" fontWeight={700}>
                                {globalDefaults?.default_company}
                            </Typography>
                        </Typography>
                        <Typography color={'GrayText'} variant="body2" fontWeight={500}>
                            {t('documents:warehouse')}:
                            <Typography color={'ActiveCaption'} variant="body2" fontWeight={700}>
                                {data?.warehouse}
                            </Typography>
                        </Typography>
                    </div>
                    <div>
                        <Typography color={'GrayText'} variant="body2" fontWeight={500}>
                            {t('documents:posp')}:
                            <Typography color={'ActiveCaption'} variant="body2" fontWeight={700}>
                                {data?.cashier}
                            </Typography>
                        </Typography>
                        <Typography color={'GrayText'} variant="body2" fontWeight={500}>
                            {t('documents:poe')}:
                            <Typography color={'ActiveCaption'} variant="body2" fontWeight={700}>
                                {data?.opening_entry}
                            </Typography>
                        </Typography>
                    </div>
                    <div>
                        <Typography color={'GrayText'} variant="body2" fontWeight={500}>
                            {t('documents:amnt')}:
                            <Typography color={'ActiveCaption'} variant="body2" fontWeight={700}>
                                <CurrencyFormatter
                                    locale="fr-FR"
                                    minimumFractionDigits={3}
                                    value={data?.amount}
                                    currency={globalDefaults?.default_currency}
                                />
                            </Typography>
                        </Typography>
                        <Typography color={'GrayText'} variant="body2" fontWeight={500}>
                            {t('documents:mp')}:
                            <Typography color={'ActiveCaption'} variant="body2" fontWeight={700}>
                                {data?.payment_method}
                            </Typography>
                        </Typography>
                    </div>
                    {data?.ref_cheque ? (
                        <div>
                            <Typography color={'GrayText'} variant="body2" fontWeight={500}>
                                {t('documents:tc')}:
                                <Typography color={'ActiveCaption'} variant="body2" fontWeight={700}>
                                    {data?.type_cheque}
                                </Typography>
                            </Typography>
                            <Typography color={'GrayText'} variant="body2" fontWeight={500}>
                                {t('documents:rc')}:
                                <Typography color={'ActiveCaption'} variant="body2" fontWeight={700}>
                                    {data?.ref_cheque}
                                </Typography>
                            </Typography>
                            <Typography color={'GrayText'} variant="body2" fontWeight={500}>
                                {t('documents:bank')}:
                                <Typography color={'ActiveCaption'} variant="body2" fontWeight={700}>
                                    {data?.bank}
                                </Typography>
                            </Typography>
                        </div>
                    ) : null}
                </div>
            </MainCard>
        </div>
    );
}

export default CoffreOperationDetails;
