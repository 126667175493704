import { Box } from '@mui/system';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import MainCard from 'ui-component/cards/MainCard';
import { useStyles } from 'views/utilities/Style';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
    FormControlLabel,
    Checkbox,
    Grid,
    Typography,
    Button,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    OutlinedInput
} from '@mui/material';
import InputTextField from 'ui-component/form/InputTextField';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';
import { LoadingButton } from '@mui/lab';
import { SetNotification } from 'store/services/api';
import { useDispatch } from 'react-redux';
import { formatErpResponse } from 'utils/utils';
import { useTranslation } from 'react-i18next';
import validator from 'validator';

export const AddSupplierContact = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const classes = useStyles();
    const { t } = useTranslation();
    const { id } = useParams();

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const [loadingButton, setLoadingButton] = useState(false);
    const [emailsValues, setEmailsValues] = useState([]);
    const [emailsValuesState, setEmailsValuesState] = useState({
        email_id: '',
        is_primary: false
    });
    const handleCheckboxChange = () => {
        setEmailsValuesState((prevState) => ({
            ...prevState,
            is_primary: !prevState.is_primary
        }));
    };

    const [openEmails, setOpenEmails] = useState(true);
    const handleOpenEmails = () => setOpenEmails(true);
    const handleCloseEmails = () => {
        setOpenEmails(false);
        setEmailsValuesState({ email_id: '', is_primary: 0 });
    };

    const handleChangeEmailsValues = (e) => {
        setEmailsValuesState({ ...emailsValuesState, [e.target.name]: e.target.value });
    };

    const handleAddEmailsValues = () => {
        const rowIndex = emailsValues.findIndex((el) => el.email_id == emailsValuesState.email_id);
        if (rowIndex == -1) {
            setEmailsValues([...emailsValues, emailsValuesState]);
        }
        handleCloseEmails();
        setEmailsValuesState({ email_id: '', is_primary: 0 });
    };

    const handleDelete = (el) => {
        const data = emailsValues.filter((row) => row.email_id != el.email_id);
        setEmailsValues(data);
    };

    const [openNumbers, setOpenNumbers] = useState(true);
    const [numbersValues, setNumbersValues] = useState([]);
    const [numbersValuesState, setNumbersValuesState] = useState({
        phone: '',
        is_primary_mobile: 0
    });

    const handleOpenNumbers = () => setOpenNumbers(true);
    const handleCloseNumbers = () => {
        setOpenNumbers(false);
        setNumbersValuesState({ email_id: '', is_primary: 0 });
    };

    const handleChangeNumbersValues = (e) => {
        setNumbersValuesState({ ...numbersValuesState, [e.target.name]: e.target.value });
    };

    const handleAddNumbersValues = () => {
        const rowIndex = numbersValues.findIndex((el) => el.phone == numbersValuesState.email_id);
        if (rowIndex == -1) {
            setNumbersValues([...numbersValues, numbersValuesState]);
        }
        handleCloseNumbers();
        setNumbersValuesState({ phone: '', is_primary_mobile: 0 });
    };

    const handleDeleteNumbers = (el) => {
        const data = numbersValues.filter((row) => row.phone != el.phone);
        setNumbersValues(data);
    };

    const handleCheckboxNumbersChange = (fieldName) => {
        setNumbersValuesState((prevState) => ({
            ...prevState,
            is_primary_mobile: !prevState.is_primary_mobile
        }));
    };

    const [state, setState] = useState({
        first_name: '',
        last_name: '',
        status: 'Passive'
    });

    const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
    };

    const handleSaveContact = async () => {
        setLoadingButton(true);
        let phone_nos = [];
        let email_ids = [];
        let links = [];
        const linksdata = {
            doctype: 'Dynamic Link',
            link_doctype: 'Supplier',
            link_name: id,
            parentfield: 'links',
            parenttype: 'Address'
        };
        links.push(linksdata);
        emailsValues.map((el) => {
            email_ids.push({
                doctype: 'Contact Email',
                is_primary: el.is_primary == true ? 1 : 0,
                parentfield: 'email_ids',
                parenttype: 'Contact',
                email_id: el.email_id
            });
        });
        numbersValues.map((el) => {
            phone_nos.push({
                doctype: 'Contact Phone',
                is_primary_mobile_no: el.is_primary_mobile == true ? 1 : 0,
                parentfield: 'phone_nos',
                parenttype: 'Contact',
                phone: el.phone
            });
        });
        const data = {
            doc: {
                doctype: 'Contact',
                first_name: state.first_name,
                last_name: state.last_name,
                status: state.status,
                email_ids: email_ids,
                phone_nos: phone_nos,
                links: links
            },
            action: 'Save'
        };

        fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(data)
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    dispatch(SetNotification({ code: 'success', message: 'Contact sauvegardé avec succès' }));
                    navigate(-1);
                }
            });
    };

    const title = (
        <Box p={-1} display={'flex'} justifyContent={'space-between'}>
            <Box p={-1} display={'flex'} flexDirection={'column'}>
                <ArrowBackIcon
                    className={classes.backLink}
                    onClick={() => {
                        navigate(-1);
                    }}
                />
                {t('core:addContact')} : {id}
            </Box>
        </Box>
    );

    return (
        <Box>
            <MainCard title={title}>
                <Grid container spacing={2} display={'flex'} justifyContent={'left'}>
                    <InputTextField
                        label={t('users:FirstName')}
                        error={state.first_name == ''}
                        name="first_name"
                        value={state.first_name}
                        handleChange={handleChange}
                    />
                    <InputTextField label={t('users:LastName')} name="last_name" value={state.last_name} handleChange={handleChange} />
                </Grid>
                <Grid container xs={12} sm={12} md={8} lg={8} xl={8} justifyContent="center" alignItems="center">
                    <Grid item xs={12}>
                        <Box mt={3}>
                            <Grid container justifyContent={'space-between'}>
                                <Typography fontWeight={600} variant="h4">
                                    Email IDs
                                </Typography>
                                <Button color="blue" onClick={handleOpenEmails}>
                                    <AddIcon sx={{ marginLeft: -1 }} /> {t('core:addEmails')}
                                </Button>
                            </Grid>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <strong>Email</strong>
                                        </TableCell>
                                        <TableCell align="center">
                                            <strong>{t('core:isPrimary')}</strong>
                                        </TableCell>
                                        <TableCell align="right" width={50}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {emailsValues.map((el, i) => (
                                        <TableRow key={i}>
                                            <TableCell sx={{ textTransform: 'uppercase' }}>{el.email_id}</TableCell>
                                            <TableCell align="center">
                                                <FormControlLabel label={''} control={<Checkbox checked={el.is_primary} />} />
                                            </TableCell>
                                            <TableCell align="right" width={50}>
                                                <DeleteIcon color="error" onClick={() => handleDelete(el)} sx={{ cursor: 'pointer' }} />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    {openEmails && (
                                        <TableRow key={999}>
                                            <TableCell>
                                                <OutlinedInput
                                                    type="text"
                                                    name="email_id"
                                                    sx={{ height: 28 }}
                                                    placeholder="Email"
                                                    onChange={handleChangeEmailsValues}
                                                    value={emailsValuesState.email_id}
                                                    error={
                                                        emailsValuesState?.email_id == '' || !validator.isEmail(emailsValuesState?.email_id)
                                                    }
                                                />
                                            </TableCell>
                                            <TableCell align="center">
                                                <FormControlLabel
                                                    label={''}
                                                    control={
                                                        <Checkbox checked={emailsValuesState.is_primary} onChange={handleCheckboxChange} />
                                                    }
                                                />
                                            </TableCell>
                                            <TableCell align="right" width={50}>
                                                <DoneIcon
                                                    color={
                                                        emailsValuesState?.email_id == '' || !validator.isEmail(emailsValuesState?.email_id)
                                                            ? 'disabled'
                                                            : ''
                                                    }
                                                    onClick={
                                                        emailsValuesState?.email_id == '' || !validator.isEmail(emailsValuesState?.email_id)
                                                            ? null
                                                            : handleAddEmailsValues
                                                    }
                                                    sx={{ cursor: 'pointer' }}
                                                />
                                            </TableCell>
                                            <TableCell align="right" width={50}>
                                                <DeleteIcon color="error" sx={{ cursor: 'pointer' }} onClick={handleCloseEmails} />
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </Box>
                        <Box mt={3}>
                            <Grid container justifyContent={'space-between'}>
                                <Typography fontWeight={600} variant="h4">
                                    {t('core:contactNumbers')}
                                </Typography>
                                <Button color="blue" onClick={handleOpenNumbers}>
                                    <AddIcon sx={{ marginLeft: -1 }} /> {t('core:addNumber')}
                                </Button>
                            </Grid>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <strong>{t('columns:mobileNo')}</strong>
                                        </TableCell>

                                        <TableCell>
                                            <strong>{t('core:isPrimaryMobileNo')}</strong>
                                        </TableCell>
                                        <TableCell align="right"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {numbersValues.map((el, i) => (
                                        <TableRow key={i}>
                                            <TableCell>{el.phone}</TableCell>
                                            <TableCell align="center">
                                                <FormControlLabel label={''} control={<Checkbox checked={el.is_primary_mobile} />} />
                                            </TableCell>
                                            <TableCell align="right" width={50}>
                                                <DeleteIcon
                                                    color="error"
                                                    sx={{ cursor: 'pointer' }}
                                                    onClick={() => handleDeleteNumbers(el)}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}

                                    {openNumbers && (
                                        <TableRow key={999}>
                                            <TableCell>
                                                <OutlinedInput
                                                    name="phone"
                                                    sx={{ height: 28 }}
                                                    placeholder={t('columns:mobileNo')}
                                                    value={numbersValuesState.phone}
                                                    onChange={handleChangeNumbersValues}
                                                    error={
                                                        numbersValuesState?.phone == '' ||
                                                        !validator.isMobilePhone(numbersValuesState?.phone)
                                                    }
                                                />
                                            </TableCell>
                                            <TableCell align="center">
                                                <FormControlLabel
                                                    label={''}
                                                    control={
                                                        <Checkbox
                                                            checked={numbersValuesState.is_primary_mobile}
                                                            onChange={() => handleCheckboxNumbersChange()}
                                                        />
                                                    }
                                                />
                                            </TableCell>
                                            <TableCell align="right" width={50}>
                                                <DoneIcon
                                                    color={
                                                        numbersValuesState?.phone == '' ||
                                                        !validator.isMobilePhone(numbersValuesState?.phone)
                                                            ? 'disabled'
                                                            : ''
                                                    }
                                                    onClick={
                                                        numbersValuesState?.phone == '' ||
                                                        !validator.isMobilePhone(numbersValuesState?.phone)
                                                            ? null
                                                            : handleAddNumbersValues
                                                    }
                                                    sx={{ cursor: 'pointer' }}
                                                />
                                            </TableCell>
                                            <TableCell align="right" width={50}>
                                                <DeleteIcon color="error" sx={{ cursor: 'pointer' }} onClick={handleCloseNumbers} />
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </Box>
                        <Box display={'flex'} justifyContent={'center'} mt={3}>
                            <Button sx={{ mr: 1 }} onClick={() => navigate(-1)}>
                                {t('common:cancel')}
                            </Button>
                            <LoadingButton
                                size="large"
                                variant="contained"
                                color="blue"
                                disabled={
                                    emailsValues.length === 0 ||
                                    numbersValues.length === 0 ||
                                    openEmails ||
                                    openNumbers ||
                                    state.first_name == ''
                                }
                                loading={loadingButton}
                                onClick={handleSaveContact}
                            >
                                {t('common:save')}
                            </LoadingButton>
                        </Box>
                    </Grid>
                </Grid>
            </MainCard>
        </Box>
    );
};
