import React from 'react';
import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import EarningCard from './Default/EarningCard';
import TotalIncomeLightCard from './Default/TotalIncomeLightCard';
import { gridSpacing } from 'store/constant';
import useFetch from 'hooks/useFetch';
import { useTranslation } from 'react-i18next';

// material-ui

// project imports

// ==============================|| DEFAULT DASHBOARD ||============================== //

const OmsDashboard = () => {
    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const [isLoading, setLoading] = useState(true);
    const [getResult, setGetResult] = useState(0);
    const [percentage, setPercentage] = useState(0);
    const [getSupplResult, setGetSupplResult] = useState(0);
    const [SupplPercentage, setSupplPercentage] = useState(0);
    const [getMgResult, setGetMgResult] = useState(0);
    const [mgPercentage, setMgPercentage] = useState(0);
    const { t, i18n } = useTranslation();

    const reqWarehouse = {
        doc: {
            name: 'Total Warehouses',
            owner: 'Administrator',
            docstatus: 0,
            idx: 0,
            is_standard: 1,
            module: 'Stock',
            label: 'Total Warehouses',
            type: 'Document Type',
            function: 'Count',
            document_type: 'Warehouse',
            is_public: 1,
            show_percentage_stats: 1,
            stats_time_interval: 'Monthly',
            doctype: 'Number Card'
        },
        filters: [
            // ['Warehouse', 'disabled', '=', '0'],
        ]
    };

    const req = {
        doc: {
            name: 'Active Customers',
            owner: 'Administrator',
            idx: 0,
            docstatus: 0,
            is_standard: 1,
            module: 'Selling',
            label: 'Active Customers',
            type: 'Document Type',
            function: 'Count',
            document_type: 'Customer',
            is_public: 1,
            show_percentage_stats: 1,
            stats_time_interval: 'Monthly',
            filters_json: '[["Customer","disabled","=","0"]]',
            dynamic_filters_json: '',
            doctype: 'Number Card'
        },
        filters: [['Customer', 'disabled', '=', '0']]
    };

    const reqSupplier = {
        doc: {
            name: 'Active Suppliers',
            owner: 'Administrator',
            modified_by: 'Administrator',
            docstatus: 0,
            idx: 0,
            is_standard: 1,
            module: 'Buying',
            label: 'Active Suppliers',
            type: 'Document Type',
            function: 'Count',
            document_type: 'Supplier',
            is_public: 1,
            show_percentage_stats: 1,
            stats_time_interval: 'Monthly',
            doctype: 'Number Card'
        },
        filters: [['Supplier', 'disabled', '=', '0']]
    };

    const fetchSupplier = () => {
        fetch(`${process.env.REACT_APP_API_URI}/api/get-result-number`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(reqSupplier)
        })
            .then((res) => {
                if (!res.ok) {
                    setLoading(false);
                    throw Error('Something went wrong');
                }
                return res.json();
            })
            .then((data) => {
                setGetSupplResult(data?.message);
                if (data?.message != '') {
                    fetch(`${process.env.REACT_APP_API_URI}/api/get-percentage`, {
                        method: 'POST',
                        headers: header,
                        body: JSON.stringify({ ...reqSupplier, result: data?.message })
                    })
                        .then((res) => {
                            if (!res.ok) {
                                throw Error('Something went wrong');
                            }
                            return res.json();
                        })
                        .then((data) => {
                            setSupplPercentage(data?.message);
                            setLoading(false);
                        });
                }
            });
    };
    const fetchWarehouse = () => {
        fetch(`${process.env.REACT_APP_API_URI}/api/get-result-number`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(reqWarehouse)
        })
            .then((res) => {
                if (!res.ok) {
                    setLoading(false);
                    throw Error('Something went wrong');
                }
                return res.json();
            })
            .then((data) => {
                setGetMgResult(data?.message);
                if (data?.message != '') {
                    fetch(`${process.env.REACT_APP_API_URI}/api/get-percentage`, {
                        method: 'POST',
                        headers: header,
                        body: JSON.stringify({ ...reqWarehouse, result: data?.message })
                    })
                        .then((res) => {
                            if (!res.ok) {
                                throw Error('Something went wrong');
                            }
                            return res.json();
                        })
                        .then((data) => {
                            setMgPercentage(data?.message);
                            setLoading(false);
                        });
                }
            });
    };
    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URI}/api/get-result-number`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(req)
        })
            .then((res) => {
                if (!res.ok) {
                    setLoading(false);
                    throw Error('Something went wrong');
                }
                return res.json();
            })
            .then((data) => {
                setGetResult(data?.message);
                if (data?.message != '') {
                    fetch(`${process.env.REACT_APP_API_URI}/api/get-percentage`, {
                        method: 'POST',
                        headers: header,
                        body: JSON.stringify({ ...req, result: data?.message })
                    })
                        .then((res) => {
                            if (!res.ok) {
                                throw Error('Something went wrong');
                            }
                            return res.json();
                        })
                        .then((data) => {
                            setPercentage(data?.message);
                            setLoading(false);
                        });
                }
            });
        fetchSupplier();
        fetchWarehouse();
    }, []);

    const UserData = {
        doctype: 'User',
        fields: ['`tabUser`.`creation`', '`tabUser`.`name`', '`tabUser`.`full_name`', '`tabUser`.`enabled`'],
        filters: [['User', 'enabled', '=', 1]],
        start: 0,
        page_length: 500
    };
    const { data, isPending } = useFetch(`/api/get-list`, UserData, 'POST');
    const currencyReqData = {
        doctype: 'Currency',
        fields: ['`tabCurrency`.`name`', '`tabCurrency`.`creation`', '`tabCurrency`.`enabled`', '`tabCurrency`.`symbol`'],
        filters: [['Currency', 'enabled', '=', 1]],
        start: 0,
        page_length: 500
    };

    const profileReqData = {
        doctype: 'Role Profile',
        fields: ['`tabRole Profile`.`creation`', '`tabRole Profile`.`role_profile`', '`tabRole Profile`.`name`'],
        start: 0,
        page_length: 500
    };

    const { data: roleProfile, isPending: isPendingRoleProfile } = useFetch(`/api/get-list`, profileReqData, 'POST');

    const rolesReqData = {
        doctype: 'Role',
        fields: ['`tabRole`.`name`', '`tabRole`.`is_custom`', '`tabRole`.`desk_access`', '`tabRole`.`disabled`'],
        start: 0,
        page_length: 500
    };
    const { data: role, isPending: isPendingRole } = useFetch(`/api/get-list`, rolesReqData, 'POST');

    const companyReqData = {
        doctype: 'Company',
        fields: [
            '`tabCompany`.`name`',
            '`tabCompany`.`docstatus`',
            '`tabCompany`.`country`',
            '`tabCompany`.`parent_company`',
            '`tabCompany`.`monthly_sales_target`',
            '`tabCompany`.`total_monthly_sales`',
            '`tabCompany`.`credit_limit`',
            '`tabCompany`.`company_logo`',
            '`tabCompany`.`default_currency`'
        ],
        start: 0,
        page_length: 500
    };
    const { data: company, isPending: isPendingCompany } = useFetch(`/api/get-list`, companyReqData, 'POST');

    const ttlSalesPersonReqData = {
        doctype: 'Sales Person',
        fields: ['`tabSales Person`.`name`'],
        distinct: false
    };
    const { data: ttlSalesPerson, isPending: isTtlSalesPersonPending } = useFetch(`api/get-list-count`, ttlSalesPersonReqData, 'POST');

    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12} md={6} lg={4}>
                <EarningCard
                    title={t('common:activeClients')}
                    label={t('crm:customers')}
                    isLoading={isLoading}
                    getResult={getResult ?? getResult}
                    percentage={percentage ?? percentage}
                    isFr
                />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <EarningCard
                    title={t('core:actsupp')}
                    label={t('core:supps')}
                    isLoading={isLoading}
                    getResult={getSupplResult ?? getSupplResult}
                    percentage={SupplPercentage ?? SupplPercentage}
                    isFr
                />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <EarningCard
                    noPercentage
                    title={t('core:actusers')}
                    label={t('core:users')}
                    isLoading={isPending}
                    getResult={data?.length ?? data?.length}
                />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <EarningCard
                    noPercentage
                    label={t('core:profil')}
                    isLoading={isPendingRoleProfile}
                    getResult={roleProfile?.length ?? roleProfile?.length}
                />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <EarningCard noPercentage label={t('core:role')} isLoading={isPendingRole} getResult={role?.length ?? role?.length} />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <TotalIncomeLightCard
                    noPercentage
                    title={t('core:companies')}
                    isLoading={isPendingCompany}
                    getResult={company?.length ?? company?.length}
                />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <EarningCard
                    noPercentage
                    label={t('core:sp')}
                    isLoading={isTtlSalesPersonPending}
                    getResult={ttlSalesPerson ?? ttlSalesPerson}
                />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                {/* Add your component here */}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                {/* Add your component here */}
            </Grid>
        </Grid>
    );
};

export default OmsDashboard;
