import { Box, InputLabel } from '@mui/material';
import useFetch from 'hooks/useFetch';
import useReportQuery from 'hooks/useReportQuery';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import MainCard from 'ui-component/cards/MainCard';
import SelectForm from 'ui-component/form/SelectForm';
import FormDateTime from 'ui-component/dateTime/FormDateTime';
import Nothing2Show from 'views/SalesDashboard/components/Nothing2Show';
import Report from 'views/SalesDashboard/components/Report';

function StockLedger() {
    const { t } = useTranslation();
    const globalDefaults = useSelector((state) => state.login.globalDefaults);

    function addMonths(input, months) {
        const date = new Date(input);
        date.setDate(1);
        date.setMonth(date.getMonth() + months);
        date.setDate(Math.min(input.getDate(), new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()));
        return date;
    }

    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split('T')[0];

    const oneMonthAgo = addMonths(currentDate, -1);
    const formattedOneMonthAgo = oneMonthAgo.toISOString().split('T')[0];

    const [reqData, setReqData] = useState({
        report_name: 'Stock Ledger',
        filters: {
            company: globalDefaults?.default_company,
            from_date: formattedOneMonthAgo,
            to_date: formattedDate,
            warehouse: '',
            item_group: ''
        },
        are_default_filters: false
    });
    const handleChangeFilters = (e) => {
        setReqData({ ...reqData, filters: { ...reqData.filters, [e.target.name]: e.target.value } });
    };

    const { data: warehouses, isPending: isWarehousesPending } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Warehouse',
            fields: ['`tabWarehouse`.`name`'],
            filters: [['Warehouse', 'company', '=', globalDefaults?.default_company]],
            start: 0,
            page_length: 40
        },
        'POST'
    );

    const { data: itemGroups, isPending: isItemGroupsPending } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Item Group',
            fields: ['`tabItem Group`.`name`'],
            start: 0,
            page_length: 40
        },
        'POST'
    );

    const { data: doc, isPending } = useReportQuery(`${process.env.REACT_APP_API_URI}/api/report-query`, reqData);

    return (
        <div style={{ opacity: isPending ? 0.5 : 1, transition: 'all .2s', marginTop: 20 }}>
            <div style={{ display: 'flex', flexDirection: 'row', gap: 20 }}>
                {/* filters */}
                <div style={{ width: '20%' }}>
                    <MainCard
                        isWhite
                        title={
                            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} gap={5}>
                                <Box color={'ActiveCaption'} display={'flex'} alignItems={'center'}>
                                    {t('dashboard:f')}
                                </Box>
                                <FilterAltIcon />
                            </Box>
                        }
                        boxShadow
                        divider
                        sx={{ width: '100%' }}
                    >
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                            <div>
                                <InputLabel sx={{ fontSize: 12, p: 0.5 }}>{t('dashboard:fd')}</InputLabel>
                                <FormDateTime
                                    fullwidth={true}
                                    type="date"
                                    name="from_date"
                                    value={reqData.filters.from_date}
                                    handleChange={handleChangeFilters}
                                />
                            </div>
                            <div>
                                <InputLabel sx={{ fontSize: 12, p: 0.5 }}>{t('dashboard:td')}</InputLabel>
                                <FormDateTime
                                    fullwidth={true}
                                    type="date"
                                    name="to_date"
                                    value={reqData.filters.to_date}
                                    handleChange={handleChangeFilters}
                                />
                            </div>
                            <div>
                                <InputLabel sx={{ fontSize: 12, p: 0.5 }}>{t('columns:warehouse')}</InputLabel>
                                <SelectForm
                                    fullwidth={true}
                                    name="warehouse"
                                    value={reqData.filters.warehouse}
                                    handleChange={handleChangeFilters}
                                    data={warehouses}
                                    propToRender={'name'}
                                />
                            </div>
                            <div>
                                <InputLabel sx={{ fontSize: 12, p: 0.5 }}>{t('products:item_group')}</InputLabel>
                                <SelectForm
                                    fullwidth={true}
                                    name="item_group"
                                    value={reqData.filters.item_group}
                                    handleChange={handleChangeFilters}
                                    data={itemGroups}
                                    propToRender={'name'}
                                />
                            </div>
                        </div>
                    </MainCard>
                </div>

                {/* report */}
                <div style={{ width: '80%' }}>
                    <MainCard
                        title={
                            <Box color={'ActiveCaption'} display={'flex'} alignItems={'center'}>
                                {t('dashboard:sl')}
                            </Box>
                        }
                        isWhite
                        boxShadow
                        divider
                    >
                        {doc?.result?.length === 0 ? (
                            <Nothing2Show />
                        ) : (
                            <>
                                <Report doc={doc} title={t('dashboard:sl')} />
                            </>
                        )}
                    </MainCard>
                </div>
            </div>
        </div>
    );
}

export default StockLedger;
