import React, { useState } from 'react';
import {
    Select,
    MenuItem,
    FormControl,
    Button,
    InputLabel,
    OutlinedInput,
    Grid,
    FormHelperText,
    FormControlLabel,
    Checkbox
} from '@mui/material';
import { useStyles } from 'views/utilities/Style';
import Box from '@mui/material/Box';
import useFetch from 'hooks/useFetch';
import Loader from 'ui-component/Loader';
import { LoadingButton } from '@mui/lab';
import { formatErpResponse } from 'utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { SetNotification } from 'store/services/api';
import validator from 'validator';
import PerfectScrollbar from 'react-perfect-scrollbar';
import useGetAxios from 'hooks/useGetAxios';
import { useTranslation } from 'react-i18next';
import FormDateTime from 'ui-component/dateTime/FormDateTime';
import { DatePicker } from '@mui/lab';

const ModalForm = ({ handleClose, loadingButton, setLoadingButton, refetch, doc, isEdit }) => {
    const classes = useStyles();
    const defaultCompany = useSelector((state) => state.login.detailsDefaultCompany);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    function substringBeforeWord(sentence, word) {
        // Trouve l'index du mot donné dans la phrase
        const index = sentence.indexOf(word);

        // Si le mot est trouvé, retourne la sous-chaîne allant du début jusqu'à juste avant le mot
        if (index !== -1) {
            return sentence.substring(0, index);
        }

        // Si le mot n'est pas trouvé, retourne la phrase entière
        return sentence;
    }

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const [state, setState] = useState({
        doctype: 'Customer',
        code_client: isEdit ? doc?.code_client : '',
        automatic_generation: isEdit ? doc?.automatic_generation : false,
        customer_name: isEdit ? doc?.customer_name : '',
        customer_type: isEdit ? doc?.customer_type : '',
        customer_group: isEdit ? doc?.customer_group : '',
        territory: isEdit ? doc?.territory : '',
        email_id: isEdit ? doc?.email_id : '',
        mobile_no: isEdit ? doc?.mobile_no : '',
        address_line1: isEdit ? doc?.__onload?.addr_list[0]?.address_line1 : '',
        country: isEdit ? doc?.country : '',
        city: isEdit ? doc?.__onload?.addr_list[0]?.city : '',
        salutation: isEdit ? doc?.salutation : '',
        socios: isEdit ? doc?.socios : '',
        numero: isEdit ? doc?.numero : '',
        birth_date: isEdit ? doc?.birth_date : ''
    });
    const [error, setError] = useState({
        code_client: false,
        customer_name: false,
        customer_type: false,
        salutation: false,
        socios: false,
        numero: false,
        birth_date: false
    });

    const handleChange = (e) => {
        setError({ ...error, [e.target.name]: false });
        setState({ ...state, [e.target.name]: e.target.value });
    };

    const territoryReqData = {
        doctype: 'Territory',
        fields: [
            '`tabTerritory`.`name`',
            '`tabTerritory`.`territory_name`',
            '`tabTerritory`.`parent_territory`',
            '`tabTerritory`.`is_group`',
            '`tabTerritory`.`territory_manager`'
        ],
        filters: [['Territory', 'is_group', '=', 0]],
        order_by: '`tabTerritory`.`modified` desc'
    };
    const customerGroupReqData = {
        doctype: 'Customer Group',
        fields: [
            '`tabCustomer Group`.`name`',
            '`tabCustomer Group`.`customer_group_name`',
            '`tabCustomer Group`.`parent_customer_group`',
            '`tabCustomer Group`.`is_group`'
        ],
        order_by: '`tabCustomer Group`.`modified` desc'
    };
    const { data: territory, isTerritoryPending } = useFetch(`/api/get-list`, territoryReqData, 'POST');
    const { data: customerGroup, isCustomerGroupPending } = useFetch(`/api/get-list`, customerGroupReqData, 'POST');
    const { data: countries, isPending: isCountriesPending } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Country',
            fields: ['`tabCountry`.`name`']
        },
        'POST'
    );
    const { data: salutations, isPending: isSalutations } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Salutation',
            fields: ['`tabSalutation`.`name`']
        },
        'POST'
    );
    const {
        data: doc_contact,
        isPending,
        refetch_contact
    } = useGetAxios(`${process.env.REACT_APP_API_URI}/api/details?doctype=Contact&name=${doc?.customer_primary_contact}`);

    const { data: doc_address } = useGetAxios(
        `${process.env.REACT_APP_API_URI}/api/details?doctype=Address&name=${doc?.customer_primary_address}`
    );

    const territoryName = territory?.map((obj) => obj.territory_name);
    const customerGroupName = customerGroup?.map((obj) => obj.customer_group_name);

    const data = [
        {
            name: 'customer_group',
            label: t('core:Groupe_de_client'),
            value: state.customer_group,
            items: customerGroupName,
            error: error.customer_group
        },
        { name: 'territory', label: t('core:Territoire'), value: state.territory, items: territoryName, error: error.territory }
    ];

    const generateCustomerCode = () => {
        const randomCode = generateRandomCode(5);
        setState((prev) => ({ ...prev, code_client: randomCode }));
    };

    const generateRandomCode = (length) => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let result = 'CLI-';
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    };

    const handleAutoGenerateChange = (e) => {
        const checked = e.target.checked;
        setState((prev) => ({
            ...prev,
            automatic_generation: checked,
            code_client: checked ? generateRandomCode(5) : ''
        }));
    };

    const handleAddCustomer = async () => {
        setLoadingButton(true);
        if (isEdit) {
            await savecontact();
            await saveAddress();
            var editcustomer = doc;
            editcustomer.code_client = state?.code_client;
            editcustomer.customer_name = state?.customer_name;
            editcustomer.address_line1 = state?.address_line1;
            editcustomer.salutation = state?.salutation;
            editcustomer.customer_type = state?.customer_type;
            editcustomer.customer_group = state?.customer_group;
            editcustomer.territory = state?.territory;
            editcustomer.country = state?.country;
            editcustomer.city = state?.city;
            editcustomer.socios = state?.socios;
            editcustomer.numero = state?.numero;
            editcustomer.birth_date = state?.birth_date;
        }

        fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doc: isEdit ? editcustomer : state, action: 'Save' })
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false && isEdit) {
                    setTimeout(() => {
                        window.location.reload();
                    });
                    dispatch(SetNotification({ message: t('crm:clientModifSuccess'), code: 'sucess' }));
                } else if (data?.success === false) {
                    const errorMessage = formatErpResponse(data?.message);
                    if (errorMessage.includes('Code client must be unique')) {
                        dispatch(
                            SetNotification({
                                code: 'warning',
                                message: t('common:code_client_existe')
                            })
                        );
                    } else if (errorMessage.includes('Mobile No must be unique')) {
                        dispatch(
                            SetNotification({
                                code: 'warning',
                                message: t('core:mobile_number_existe')
                            })
                        );
                    } else if (errorMessage.includes('is not a valid Email Address')) {
                        dispatch(
                            SetNotification({
                                code: 'warning',
                                message: t('core:email_is_not_valid')
                            })
                        );
                    } else {
                        dispatch(
                            SetNotification({
                                code: 'warning',
                                message: errorMessage
                            })
                        );
                    }
                } else {
                    if (!isEdit) {
                        dispatch(SetNotification({ message: t('crm:clientCreateSuccess'), code: 'success' }));
                        refetch({});
                        handleClose();
                    } else {
                        dispatch(SetNotification({ message: t('crm:clientModifSuccess'), code: 'success' }));
                        refetch({});
                        handleClose();
                    }
                }
            });
    };
    const savecontact = async () => {
        if ((state?.mobile_no !== doc?.mobile_no || state?.email_id !== doc?.email_id) && doc_contact) {
            const var_cantact = doc_contact;
            if (var_cantact?.email_ids?.length > 0) var_cantact.email_ids[0].email_id = state?.email_id;
            if (var_cantact?.phone_nos?.length > 0) {
                var_cantact.phone_nos[0].phone = state?.mobile_no;
                var_cantact.phone_nos[0].is_primary_phone = 1;
            }
            await fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
                method: 'POST',
                headers: header,
                body: JSON.stringify({ doc: var_cantact, action: 'Save' })
            })
                .then((res) => {
                    if (!res.ok) {
                        dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                    }
                    return res.json();
                })
                .then((data) => {
                    return;
                });
        } else {
            return;
        }
    };

    const saveAddress = async () => {
        if (
            (state?.address_line1 !== doc?.__onload?.addr_list[0]?.address_line1 || state?.city !== doc?.__onload?.addr_list[0]?.city) &&
            doc_address
        ) {
            const var_address = doc_address;
            var_address.address_line1 = state?.address_line1;
            var_address.city = state?.city;
            var_address.is_primary_address = 1;

            await fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
                method: 'POST',
                headers: header,
                body: JSON.stringify({ doc: var_address, action: 'Save' })
            })
                .then((res) => {
                    if (!res.ok) {
                        dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                    }
                    return res.json();
                })
                .then((data) => {
                    return;
                });
        } else {
            return;
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        let isValid = true;
        const nextError = { ...error };

        const requiredFields = ['code_client', 'customer_name', 'customer_type', 'socios'];

        requiredFields.forEach((field) => {
            if (!state[field] || state[field].trim() === '') {
                nextError[field] = true;
                isValid = false;
            } else {
                nextError[field] = false;
            }
        });

        if (state.customer_type === 'Individual') {
            if (!state.salutation || state.salutation.trim() === '') {
                nextError.salutation = true;
                isValid = false;
            } else {
                nextError.salutation = false;
            }

            if (!state.birth_date) {
                nextError.birth_date = true;
                isValid = false;
            } else {
                nextError.birth_date = false;
            }
        }

        if (state.socios === 'Oui' && (!state.numero || state.numero.trim() === '')) {
            nextError.numero = true;
            isValid = false;
        } else {
            nextError.numero = false;
        }

        setError(nextError);

        if (isValid) {
            handleAddCustomer();
        } else {
            console.log('La validation du formulaire a échoué', nextError);
        }
    };

    if (isTerritoryPending || isCustomerGroupPending || isCountriesPending || isSalutations) return <Loader />;

    return (
        <Box>
            <Box p={1}>
                <PerfectScrollbar style={{ overflow: 'scroll', height: 400 }}>
                    <Box sx={{ padding: '5px 0px' }}>
                        {!isEdit && (
                            <Box display="flex" alignItems="center">
                                <FormControlLabel
                                    control={<Checkbox checked={state.automatic_generation} onChange={handleAutoGenerateChange} />}
                                    label={t('core:automatic_code_generation')}
                                />
                            </Box>
                        )}

                        <FormControl fullWidth required sx={{ mt: 2, mb: 1 }}>
                            <InputLabel error={error.code_client}>{t('crm:codeClient')}</InputLabel>
                            <OutlinedInput
                                label={t('crm:codeClient')}
                                autoComplete="off"
                                className={classes.formControll}
                                error={error.code_client}
                                placeholder={t('crm:codeClient')}
                                name="code_client"
                                value={state.code_client}
                                onChange={handleChange}
                                disabled={isEdit || state.automatic_generation}
                            />
                        </FormControl>
                        {state.automatic_generation && <Button onClick={generateCustomerCode}>{t('products:Genecode')}</Button>}
                        <Box key={'customer_type'}>
                            <FormControl fullWidth required sx={{ mt: 1 }}>
                                <InputLabel id="demo-simple-select-label" error={error.customer_type}>
                                    {t('core:Type_de_client')}
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label={t('core:Type_de_client')}
                                    name="customer_type"
                                    value={state.customer_type}
                                    onChange={handleChange}
                                    error={error.customer_type}
                                >
                                    {['Company', 'Individual'].map((item, index) => (
                                        <MenuItem style={{ fontSize: 12 }} value={item} key={index}>
                                            {item}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>

                        {state?.customer_type === 'Individual' && (
                            <FormControl fullWidth sx={{ mt: 2 }} required>
                                <InputLabel mt={0.5} error={error?.salutation} id="demo-simple-select-label">
                                    Salutation
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="salutation"
                                    name="salutation"
                                    value={state?.salutation}
                                    error={error?.salutation}
                                    onChange={handleChange}
                                >
                                    {Array.isArray(salutations) &&
                                        salutations.map((item, index) => (
                                            <MenuItem style={{ fontSize: 12 }} value={item.name} key={index}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        )}
                        <FormControl fullWidth required sx={{ mt: 2 }}>
                            <InputLabel error={error.customer_name}>{t('core:Nom_du_client')}</InputLabel>
                            <OutlinedInput
                                label={t('core:Nom_du_client')}
                                autoComplete="off"
                                className={classes.formControll}
                                error={error.customer_name}
                                placeholder={t('core:Nom_du_client')}
                                name="customer_name"
                                value={state.customer_name}
                                onChange={handleChange}
                            />
                        </FormControl>

                        <Box justifyContent="center">
                            {data.map((row, index) => (
                                <Box mt={2} key={index}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label" error={row.error}>
                                            {row.label}
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label={row.label}
                                            name={row.name}
                                            value={row.value}
                                            onChange={handleChange}
                                            error={row.error}
                                        >
                                            {Array.isArray(row.items) &&
                                                row.items.map((item, index) => (
                                                    <MenuItem style={{ fontSize: 12 }} value={item} key={index}>
                                                        {item}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            ))}
                            <Box justifyContent="center">
                                <FormControl fullWidth sx={{ mt: 1 }} required>
                                    <InputLabel error={error?.socios} mt={0.5} id="demo-simple-select-label">
                                        {t('crm:socios')}
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label={t('crm:socios')}
                                        name="socios"
                                        value={state?.socios}
                                        onChange={handleChange}
                                        error={error?.socios}
                                    >
                                        <MenuItem style={{ fontSize: 12 }} value="Non">
                                            Non
                                        </MenuItem>
                                        <MenuItem style={{ fontSize: 12 }} value="Oui">
                                            Oui
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                                {state?.socios === 'Oui' && (
                                    <FormControl fullWidth sx={{ mt: 1 }} required={state.socios === 'Oui'}>
                                        <InputLabel error={error.numero}>{t('crm:numero')}</InputLabel>
                                        <OutlinedInput
                                            label={t('crm:numero')}
                                            value={state.numero}
                                            onChange={handleChange}
                                            autoComplete="off"
                                            className={classes.formControll}
                                            placeholder={t('crm:numero')}
                                            name="numero"
                                            error={error.numero}
                                        />
                                        {error.numero && state.socios === 'Oui' && (
                                            <FormHelperText error>{t('crm:ThisFieldIsRequired')}</FormHelperText>
                                        )}
                                    </FormControl>
                                )}
                            </Box>
                            <Box justifyContent="center">
                                <InputLabel error={error.birth_date} sx={{ mt: 2 }}>
                                    {t('crm:birth_date')}
                                </InputLabel>
                                <FormControl fullWidth>
                                    <OutlinedInput
                                        type="date"
                                        label={t('crm:birth_date')}
                                        autoComplete="off"
                                        className={classes.formControll}
                                        error={error.birth_date}
                                        placeholder={t('crm:birth_date')}
                                        name="birth_date"
                                        value={state.birth_date}
                                        onChange={handleChange}
                                    />
                                    {error.birth_date && <FormHelperText error>{t('crm:ThisFieldIsRequired')}</FormHelperText>}
                                </FormControl>
                            </Box>
                        </Box>
                        <Box mt={1}>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <FormControl fullWidth sx={{ mt: 1 }}>
                                        <InputLabel error={error.email_id}>{t('core:email')}</InputLabel>
                                        <OutlinedInput
                                            label={t('core:email')}
                                            value={state.email_id}
                                            onChange={handleChange}
                                            autoComplete="off"
                                            className={classes.formControll}
                                            placeholder={t('core:email')}
                                            name="email_id"
                                            error={error.email_id}
                                        />
                                    </FormControl>

                                    <FormControl fullWidth sx={{ mt: 1 }}>
                                        <InputLabel error={error?.city}>{t('core:Ville')}</InputLabel>
                                        <OutlinedInput
                                            label={t('core:Ville')}
                                            value={state.city}
                                            onChange={handleChange}
                                            autoComplete="off"
                                            className={classes.formControll}
                                            placeholder={t('core:Ville')}
                                            name="city"
                                            error={error?.city}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth sx={{ mt: 1 }}>
                                        <InputLabel error={error?.address_line1}>{t('core:Addresscl')}</InputLabel>
                                        <OutlinedInput
                                            label={t('core:Addresscl')}
                                            value={state.address_line1}
                                            onChange={handleChange}
                                            autoComplete="off"
                                            className={classes.formControll}
                                            placeholder={t('core:Addresscl')}
                                            name="address_line1"
                                            error={error?.address_line1}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl fullWidth sx={{ mt: 1 }}>
                                        <InputLabel error={error.mobile_no}>{t('core:Phonecl')}</InputLabel>
                                        <OutlinedInput
                                            label={t('core:Phonecl')}
                                            value={state.mobile_no}
                                            onChange={handleChange}
                                            autoComplete="off"
                                            className={classes.formControll}
                                            placeholder={t('core:Phonecl')}
                                            name="mobile_no"
                                            error={error.mobile_no}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth sx={{ mt: 1 }}>
                                        <InputLabel error={error?.country} mt={0.5} id="demo-simple-select-label">
                                            {t('core:Pays')}
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label={t('core:Pays')}
                                            name="country"
                                            value={state?.country}
                                            onChange={handleChange}
                                            error={error?.country}
                                        >
                                            {defaultCompany?.name === 'Gautier' ? (
                                                <MenuItem style={{ fontSize: 12 }} value="United Arab Emirates" key={'uae'}>
                                                    United Arab Emirates
                                                </MenuItem>
                                            ) : null}
                                            {Array.isArray(countries) &&
                                                countries
                                                    .filter((el) =>
                                                        defaultCompany?.name === 'Gautier'
                                                            ? el.name !== 'United Arab Emirates'
                                                            : el.name !== ''
                                                    )
                                                    .map((item, index) => (
                                                        <MenuItem style={{ fontSize: 12 }} value={item.name} key={index}>
                                                            {item.name}
                                                        </MenuItem>
                                                    ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </PerfectScrollbar>
            </Box>
            <Box display={'flex'} justifyContent={'flex-end'} mt={2}>
                <Box>
                    <Grid container spacing={1}>
                        <Grid item>
                            <Button color="blue" size="large" variant="outlined" onClick={handleClose}>
                                {t('common:cancel')}
                            </Button>
                        </Grid>
                        <Grid item>
                            <LoadingButton
                                color="blue"
                                size="large"
                                variant="contained"
                                loading={loadingButton}
                                onClick={handleSubmit}
                                disabled={
                                    state.code_client === '' ||
                                    state.customer_name === '' ||
                                    state.socios === '' ||
                                    (state.customer_type === 'Individual' && state.salutation === '') ||
                                    (state.socios === 'Oui' && state.numero === '')
                                }
                            >
                                {t('common:save')}
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
};

export default ModalForm;
