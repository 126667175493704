import { LoadingButton } from '@mui/lab';
import { Grid, Box } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { t } from 'i18next';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import MainCard from 'ui-component/cards/MainCard';
import InputTextField from 'ui-component/form/InputTextField';
import { useStyles } from '../../../utilities/Style';
import { SetNotification } from '../../../../store/services/api';
import { useDispatch } from 'react-redux';

const ServicePrestation = () => {
    const navigate = useNavigate();
    const classes = useStyles();
    const dispatch = useDispatch();
    const [code, setCode] = useState('');
    const [name, setName] = useState('');
    const [amount, setAmount] = useState(0);
    const [loadingButton, setLoadingButton] = useState(false);

    const handleAddService = () => {
        setLoadingButton(true);
        const header = {
            Accept: 'application/json',
            'content-type': 'application/json',
            'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem('user'))?.token
        };
        const requestData = {
            service_code: code,
            service_name: name,
            amount: amount
        };
        fetch(`${process.env.REACT_APP_API_URI}/api/add-service-prestation`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(requestData)
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                setLoadingButton(false);
                dispatch(SetNotification({ code: 'success', message: 'Service ajouter avec succès' }));
                return navigate('/pim/service-provision');
            })
            .then((data) => {
                console.log('Response:', data);
            })
            .catch((error) => {
                dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                console.error('Error:', error);
            });
    };

    const title = (
        <Box p={-1} display={'flex'} flexDirection={'column'}>
            <ArrowBackIcon className={classes.backLink} onClick={() => navigate(-1)} />
        </Box>
    );

    return (
        <MainCard title={title}>
            <Grid container spacing={2} display={'flex'} justifyContent={'left'} flexDirection={'column'}>
                <InputTextField handleChange={(e) => setCode(e.target.value)} value={code} label={'code'} name="code" />
                <InputTextField required handleChange={(e) => setName(e.target.value)} value={name} label={'name'} name="name" />
                <InputTextField
                    required
                    type={'number'}
                    handleChange={(e) => setAmount(e.target.value)}
                    value={amount}
                    label={'amount'}
                    name="amount"
                />
            </Grid>
            <Grid container item xs={12} sm={12} md={8} lg={8} xl={8} spacing={4} display={'flex'} justifyContent={'center'}>
                <Grid item mt={3}>
                    <LoadingButton variant="contained" size="large" color="blue" onClick={handleAddService} loading={loadingButton}>
                        {t('common:save')}
                    </LoadingButton>
                </Grid>
            </Grid>
        </MainCard>
    );
};
export default ServicePrestation;
