import React, { useEffect, useState } from 'react';
import {
    Paper,
    Typography,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Checkbox,
    Button,
    Pagination,
    FormControlLabel
} from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { useTranslation } from 'react-i18next';
import * as XLSX from 'xlsx';
import useFetch from 'hooks/useFetch';
import { useTheme } from '@mui/material/styles';

const DepotsDownloader = () => {
    const [selectedDepots, setSelectedDepots] = useState([]);
    const [page, setPage] = useState(1);
    const [selectAll, setSelectAll] = useState(false);
    const { t } = useTranslation(['common']);
    const theme = useTheme();

    const itemsPerPage = 10;

    const depotsReqData = {
        doctype: 'Warehouse',
        fields: ['*'],
        order_by: '`tabWarehouse`.`modified` desc',
        filters: [['Warehouse', 'is_storage', '=', 1]],
        start: 0,
        page_length: 500
    };

    const { data: depotsData, isPending, refetch } = useFetch(`/api/get-list`, depotsReqData, 'POST');

    useEffect(() => {
        if (selectAll) {
            setSelectedDepots(depotsData ? depotsData.map((depot) => depot.name) : []);
        } else {
            setSelectedDepots([]);
        }
    }, [selectAll, depotsData]);

    const handleToggle = (value) => () => {
        const currentIndex = selectedDepots.indexOf(value);
        const newChecked = [...selectedDepots];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setSelectedDepots(newChecked);
        setSelectAll(newChecked.length === depotsData.length);
    };

    const handleSelectAllChange = (event) => {
        setSelectAll(event.target.checked);
    };

    const handleDownload = () => {
        if (selectedDepots.length === 0) {
            alert(t('common:pleaseSelectAtLeastOneDepot'));
            return;
        }

        const selectedDepotsData = depotsData.filter((depot) => selectedDepots.includes(depot.name));

        const worksheetData = selectedDepotsData.map((depot) => ({
            Name: depot.warehouse_name,
            'Warehouse Code': depot.warehouse_code,
            Company: depot.company,
            'Parent Warehouse': depot.parent_warehouse,
            'Phone Number': depot.phone_no,
            'Mobile Number': depot.mobile_no,
            'Address Line 1': depot.address_line_1,
            'Address Line 2': depot.address_line_2,
            City: depot.city,
            Email: depot.email
        }));

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(worksheetData);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Depots');

        XLSX.writeFile(workbook, 'Depots.xlsx');
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const paginatedData = depotsData ? depotsData.slice((page - 1) * itemsPerPage, page * itemsPerPage) : [];

    return (
        <Paper style={{ padding: '20px', maxWidth: '500px', margin: 'auto' }}>
            <Typography variant="h6" gutterBottom>
                {t('core:exportlist')}
            </Typography>
            <FormControlLabel
                control={<Checkbox checked={selectAll} onChange={handleSelectAllChange} color="primary" />}
                label={t('common:selectAll')}
            />
            <List dense>
                {paginatedData.map((depot) => {
                    const labelId = `checkbox-list-label-${depot.name}`;

                    return (
                        <ListItem
                            key={depot.name}
                            button
                            onClick={handleToggle(depot.name)}
                            style={{
                                backgroundColor: selectedDepots.indexOf(depot.name) !== -1 ? theme.palette.action.selected : 'transparent',
                                marginBottom: '4px',
                                borderRadius: '4px'
                            }}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    checked={selectedDepots.indexOf(depot.name) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>
                            <ListItemText
                                id={labelId}
                                primary={depot.name}
                                primaryTypographyProps={{
                                    style: {
                                        color: theme.palette.text.primary
                                    }
                                }}
                            />
                        </ListItem>
                    );
                })}
            </List>
            <Pagination
                count={Math.ceil((depotsData?.length || 0) / itemsPerPage)}
                page={page}
                onChange={handleChangePage}
                color="primary"
                style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}
            />
            <Button
                variant="contained"
                color="primary"
                startIcon={<CloudDownloadIcon />}
                onClick={handleDownload}
                disabled={isPending || selectedDepots.length === 0}
                style={{ marginTop: '20px' }}
            >
                {isPending ? t('common:loading') : t('common:export')}
            </Button>
        </Paper>
    );
};

export default DepotsDownloader;
