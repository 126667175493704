{
    /* eslint-disable */
}
import React, { useRef, useState } from 'react';
//component imports
import DataRender from 'ui-component/tables/DataRender';
import MainCard from 'ui-component/cards/MainCard';
import useFetch from 'hooks/useFetch';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { Box } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import DataImporter from './components/DataImporter';
import DepotsDownloader from './components/DepotsDownloader';

//mui
import { Button, Grid, IconButton, Modal, Typography, useMediaQuery } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

//styles
import { useTheme } from '@mui/material';
import { useStyles } from 'views/utilities/Style';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { modalStyle } from 'views/utilities/ModalStyle';
import { SetNotification } from 'store/services/api';
import { formatErpResponse, formatServerMessages } from 'utils/utils';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const DepotsList = () => {
    const theme = useTheme();
    const componentRef = useRef();
    const classes = useStyles();
    const { t } = useTranslation();
    const [selectionModel, setSelectionModel] = useState([]);
    const [loadingButton, setLoadingButton] = useState(false);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [openDownloader, setOpenDownloader] = useState(false);
    const [openImporter, setOpenImporter] = useState(false);
    const [serverMessages, setServerMessages] = useState([]);

    const handleOpenDownloader = () => setOpenDownloader(true);
    const handleCloseDownloader = () => setOpenDownloader(false);
    const handleOpenImporter = () => setOpenImporter(true);
    const handleCloseImporter = () => setOpenImporter(false);

    const warehouseReqData = {
        doctype: 'Warehouse',
        fields: [
            '`tabWarehouse`.`name`',
            '`tabWarehouse`.`warehouse_code`',
            '`tabWarehouse`.`warehouse_name`',
            '`tabWarehouse`.`is_group`',
            '`tabWarehouse`.`disabled`',
            '`tabWarehouse`.`company`',
            '`tabWarehouse`.`parent_warehouse`',
            '`tabWarehouse`.`city`',
            '`tabWarehouse`.`creation`',
            '`tabWarehouse`.`modified`'
        ],
        filters: [['Warehouse', 'is_group', '=', 0]],
        order_by: '`tabWarehouse`.`modified` desc'
    };
    const { data, isPending, refetch } = useFetch(`/api/get-list`, warehouseReqData, 'POST');

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };
    // function extractStringBetweenWords(input, word1, word2) {
    //     const regex = new RegExp(`${word1}(.*?)${word2}`, 'i'); // 'i' pour insensible à la casse
    //     const match = input.match(regex); // Utilise la regex pour chercher une correspondance

    //     if (match && match[1]) {
    //         return match[1].trim(); // Retourne la chaîne entre les deux mots
    //     }
    //     return ''; // Retourne une chaîne vide si aucune correspondance n'est trouvée
    // }

    function extractStringFromWordToEnd(input, startWord) {
        const regex = new RegExp(`${startWord}(.*)`, 'i'); // 'i' pour insensible à la casse
        const match = input.match(regex); // Utilise la regex pour chercher une correspondance

        if (match && match[1]) {
            return match[1].trim(); // Retourne la chaîne à partir du mot donné jusqu'à la fin
        }
        return ''; // Retourne une chaîne vide si aucune correspondance n'est trouvée
    }

    const handleDelete = (row) => {
        console.log('rooooow', row);
        setLoadingButton(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/delete-doc`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doctype: 'Warehouse', name: row[0] })
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(
                        SetNotification({
                            code: 'warning',
                            message:
                                t('common:msgEditWarehouse', { wrehouseName: row[0] }) +
                                extractStringFromWordToEnd(formatErpResponse(data?.message), 'Item ')
                        })
                    );
                } else {
                    dispatch(SetNotification({ message: t('common:depotDeleteSuccess'), code: 'success' }));
                    handleClose();
                    refetch({});
                }
            });
    };

    const columnsImport = [
        'warehouse_name',
        'company',
        'parent_warehouse',
        'phone_no',
        'mobile_no',
        'address_line_1',
        'address_line_2',
        'city',
        'email',
        'is_storage'
    ];

    const handleEdit = (row) => {
        navigate(`/oms/depots/${row}`);
    };

     //Bulk Edit
     const handleBulkDelete = (rows) => {
        setLoadingButton(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/delete-items`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doctype: 'Warehouse', items: rows })
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?._server_messages) {
                    setServerMessages(formatServerMessages(data?._server_messages));
                    refetch({});
                    handleClose();
                    return;
                }
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                    return;
                } else {
                    dispatch(SetNotification({ code: 'success', message: t('common:depotsDeleteSuccess') }));
                    refetch({});
                    handleClose();
                }
            });
    };

    const deleteConfirm = (
        <Box sx={modalStyle}>
            <MainCard divider title={t('common:confirm')}>
                <Typography id="modal-modal-description" sx={{ fontSize: 14 }}>
                    {t('common:deletePermanently')} <strong>{selectionModel.length}</strong> {t('core:warehouses')}  ?
                </Typography>
                <Box display={'flex'} justifyContent={'flex-end'} mt={3}>
                    <Box>
                        <Grid container spacing={1}>
                            <Grid item>
                                <Button color="blue" size="large" variant="outlined" onClick={handleClose}>
                                    {t('common:non')}
                                </Button>
                            </Grid>
                            {selectionModel.length === 1 ? (<Grid item>
                                <LoadingButton
                                    color="blue"
                                    size="large"
                                    onClick={() => handleDelete(selectionModel)}
                                    variant="contained"
                                    loading={loadingButton}
                                >
                                    {t('common:oui')}
                                </LoadingButton>
                            </Grid>
                        ):
                        (
                            <Grid item>
                                <LoadingButton
                                    color="blue"
                                    size="large"
                                    onClick={() => handleBulkDelete(selectionModel)}
                                    variant="contained"
                                    loading={loadingButton}
                                >
                                    {t('common:oui')}
                                </LoadingButton>
                            </Grid>
                        )}
                        </Grid>
                    </Box>
                </Box>
            </MainCard>
        </Box>
    );

    const dataImporter = (
        <Box sx={modalStyle}>
            <MainCard divider title={t('core:import')}>
                <DataImporter refetch={refetch} handleClose={handleClose} columns={columnsImport} doctype="Warehouse" />
            </MainCard>
        </Box>
    );

    const title = (
        <Box p={-1} display={'flex'} justifyContent={'space-between'}>
            <Grid>
                {t('core:warehouses')}
                <IconButton
                    aria-haspopup="true"
                    onClick={() => navigate('/oms/depots-tree')}
                    color="inherit"
                    aria-label="cart"
                    variant="rounded"
                    size="small"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.mediumAvatar,
                        transition: 'all .2s ease-in-out',
                        background: 'transparent !important',
                        color: theme.palette.primary.dark,
                        borderRadius: 50,
                        height: 20,
                        ml: 2,
                        mt: -0.5
                    }}
                >
                    <AccountTreeIcon
                        sx={{
                            height: 20
                        }}
                        color="blue"
                    />
                </IconButton>
            </Grid>
            <Box p={-1} display={'flex'} justifyContent={'flex-end'}>
                <Box>
                    <Grid
                        container
                        direction={isSmallScreen ? 'column' : 'row'}
                        spacing={2}
                        alignItems={isSmallScreen ? 'flex-end' : 'flex-end'}
                    >
                        {Array.isArray(selectionModel) && selectionModel?.length !== 0 && (
                            <Grid item xs>
                                {selectionModel.length === 1 ? (
                                    <Button
                                        startIcon={<DeleteIcon color="error" />}
                                        variant="outlined"
                                        color="error"
                                        onClick={() => handleOpen(1)}
                                        sx={{ marginTop: -3 }}
                                    >
                                        {t('common:delete')}
                                    </Button>
                                ) : (
                                    <Button
                                        startIcon={<DeleteIcon color="error" />}
                                        variant="outlined"
                                        color="error"
                                        onClick={() => handleOpen(2)}
                                        sx={{ marginTop: -3 }}
                                    >
                                        {t('common:delete')}
                                    </Button>
                                )}
                            </Grid>
                        )}
                        {Array.isArray(selectionModel) && selectionModel?.length !== 0 && (
                            <Grid item xs>
                                {selectionModel.length === 1 ? (
                                    <Button
                                        startIcon={<EditIcon />}
                                        variant="outlined"
                                        color="blue"
                                        onClick={() => handleEdit(selectionModel)}
                                        sx={{ marginTop: -3 }}
                                    >
                                        {t('common:edit')}
                                    </Button>
                                ) : (
                                    <Button
                                        startIcon={<EditIcon />}
                                        variant="outlined"
                                        color="blue"
                                        onClick={() => handleOpen(0)}
                                        sx={{ marginTop: -3 }}
                                    >
                                        {t('common:edit')}
                                    </Button>
                                )}
                            </Grid>
                        )}
                    </Grid>
                </Box>
                <Box>
                    <Grid container spacing={1} marginLeft={1}>
                        {false && (
                            <>
                                <Grid item>
                                    {selectionModel.length === 1 && (
                                        <Button variant="outlined" color="error" onClick={handleOpen} sx={{ marginTop: -3 }}>
                                            {t('common:delete')}
                                        </Button>
                                    )}
                                </Grid>
                                <Grid item>
                                    {selectionModel.length === 1 && (
                                        <Button
                                            variant="outlined"
                                            color="blue"
                                            sx={{ marginTop: -3 }}
                                            onClick={() => handleEdit(selectionModel)}
                                        >
                                            {t('common:edit')}
                                        </Button>
                                    )}
                                </Grid>
                            </>
                        )}
                        <Grid item>
                            <Button variant="outlined" color="blue" onClick={() => navigate('/oms/new-depots')} sx={{ marginTop: -3 }}>
                                <AddIcon /> {t('core:addwarehouse')}
                            </Button>
                            <Button variant="text" color="blue" onClick={handleOpenImporter} sx={{ marginTop: -3, ml: 1 }}>
                                {t('core:import')}
                            </Button>

                            <Button variant="text" color="blue" onClick={handleOpenDownloader} sx={{ marginTop: -3, ml: 1 }}>
                                {t('common:export')}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    );

    return (
        <div>
            <DataRender
                refetch={refetch}
                data={data}
                tag={t('columns:warehouseName')}
                loading={isPending}
                selectionModel={selectionModel}
                title={title}
                setSelectionModel={setSelectionModel}
                handleDelete={handleDelete}
                handleEdit={handleEdit}
                isPrintable
                componentRef={componentRef}
                hasCheckBoxes
                notshow={['name']}
                warehouseType="depot"
            />
            <Modal onClose={handleCloseImporter} open={openImporter}>
                {dataImporter}
            </Modal>
            <Modal onClose={handleCloseDownloader} open={openDownloader}>
                <Box sx={modalStyle}>
                    <MainCard divider title={t('common:ExportDepots')}>
                        <DepotsDownloader depotsData={data} isPending={isPending} refetch={refetch} />
                    </MainCard>
                </Box>
            </Modal>
            <Modal onClose={handleClose} open={open}>
                {deleteConfirm}
            </Modal>
        </div>
    );
};

export default DepotsList;
