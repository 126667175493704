import { ImageList, ImageListItem, Skeleton } from '@mui/material';
import React from 'react';

function LoadingList() {
    return (
        <ImageList cols={4} gap={20}>
            <ImageListItem key={1}>
                <Skeleton
                    sx={{ height: 200, borderTopLeftRadius: 10, borderTopRightRadius: 10, boxShadow: '0px 2px 8px #091F461D' }}
                    variant="rectangular"
                />
                <Skeleton sx={{ height: 70, bgcolor: 'grey.400' }} variant="rectangular" />
            </ImageListItem>
            <ImageListItem key={2}>
                <Skeleton
                    sx={{ height: 200, borderTopLeftRadius: 10, borderTopRightRadius: 10, boxShadow: '0px 2px 8px #091F461D' }}
                    variant="rectangular"
                />
                <Skeleton sx={{ height: 70, bgcolor: 'grey.400' }} variant="rectangular" />
            </ImageListItem>
            <ImageListItem key={3}>
                <Skeleton
                    sx={{ height: 200, borderTopLeftRadius: 10, borderTopRightRadius: 10, boxShadow: '0px 2px 8px #091F461D' }}
                    variant="rectangular"
                />
                <Skeleton sx={{ height: 70, bgcolor: 'grey.400' }} variant="rectangular" />
            </ImageListItem>
            <ImageListItem key={4}>
                <Skeleton
                    sx={{ height: 200, borderTopLeftRadius: 10, borderTopRightRadius: 10, boxShadow: '0px 2px 8px #091F461D' }}
                    variant="rectangular"
                />
                <Skeleton sx={{ height: 70, bgcolor: 'grey.400' }} variant="rectangular" />
            </ImageListItem>
        </ImageList>
    );
}

export default LoadingList;
