import React from 'react';
import MainCard from 'ui-component/cards/MainCard';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useStyles } from 'views/utilities/Style';
import { CurrencyFormatter } from 'utils/utils';
import { formatDate } from 'helpers/utils';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

function POSTransactions({ doc }) {
    const { t } = useTranslation();
    const classes = useStyles();
    const globalDefaults = useSelector((state) => state.login.globalDefaults);

    return (
        <MainCard
            border
            title={
                <Box display={'flex'} alignItems={'center'}>
                    <ReceiptLongIcon sx={{ mr: 1 }} /> {t('documents:pt')}
                </Box>
            }
            boxShadow
            divider
        >
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>N°</TableCell>
                        <TableCell>{t('documents:pi')}</TableCell>
                        <TableCell align="left">{t('documents:date')}</TableCell>
                        <TableCell align="left">{t('documents:cus')}</TableCell>
                        <TableCell align="right">{t('documents:amnt')}</TableCell>
                        <TableCell align="center">{t('documents:isr')}</TableCell>
                        <TableCell align="right">{t('documents:ra')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {doc?.pos_transactions?.map((el, i) => (
                        <TableRow key={i}>
                            <TableCell>
                                <strong>{el?.idx}</strong>
                            </TableCell>
                            <TableCell>
                                <strong>{el?.pos_invoice}</strong>
                            </TableCell>
                            <TableCell align="left">
                                <strong>{formatDate(el?.posting_date, 'DD-MM-YYYY')}</strong>
                            </TableCell>
                            <TableCell align="left">
                                <strong>{el?.customer}</strong>
                            </TableCell>
                            <TableCell align="right">
                                <strong>
                                    <CurrencyFormatter
                                        locale="fr-FR"
                                        minimumFractionDigits={3}
                                        value={el?.grand_total}
                                        currency={globalDefaults?.default_currency}
                                    />
                                </strong>
                            </TableCell>
                            <TableCell align="center">
                                <strong>{el?.is_return === 1 ? <span>&#10003;</span> : null}</strong>
                            </TableCell>
                            <TableCell align="right">
                                <strong>{el?.return_against}</strong>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            <div className={classes.orderDetailsContainer} style={{ marginTop: 10 }}>
                <h3>{t('documents:pr')}</h3>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>N°</TableCell>
                            <TableCell align="left">{t('documents:mp')}</TableCell>
                            <TableCell align="right">{t('documents:oa')}</TableCell>
                            <TableCell align="right">{t('documents:ea')}</TableCell>
                            <TableCell align="right">{t('documents:ca')}</TableCell>
                            <TableCell align="right">{t('documents:diff')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {doc?.payment_reconciliation?.map((el, i) => (
                            <TableRow key={i}>
                                <TableCell>
                                    <strong>{el?.idx}</strong>
                                </TableCell>
                                <TableCell align="left">
                                    <strong>{el?.mode_of_payment}</strong>
                                </TableCell>
                                <TableCell align="right">
                                    <strong>
                                        <CurrencyFormatter
                                            locale="fr-FR"
                                            minimumFractionDigits={3}
                                            value={el?.opening_amount}
                                            currency={globalDefaults?.default_currency}
                                        />
                                    </strong>
                                </TableCell>
                                <TableCell align="right">
                                    <strong>
                                        <CurrencyFormatter
                                            locale="fr-FR"
                                            minimumFractionDigits={3}
                                            value={el?.expected_amount}
                                            currency={globalDefaults?.default_currency}
                                        />
                                    </strong>
                                </TableCell>
                                <TableCell align="right">
                                    <strong>
                                        <CurrencyFormatter
                                            locale="fr-FR"
                                            minimumFractionDigits={3}
                                            value={el?.closing_amount}
                                            currency={globalDefaults?.default_currency}
                                        />
                                    </strong>
                                </TableCell>
                                <TableCell align="right">
                                    <strong>
                                        <CurrencyFormatter
                                            locale="fr-FR"
                                            minimumFractionDigits={3}
                                            value={el?.difference}
                                            currency={globalDefaults?.default_currency}
                                        />
                                    </strong>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        </MainCard>
    );
}

export default POSTransactions;
