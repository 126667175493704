import { Chip, Divider, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import MainCard from 'ui-component/cards/MainCard';
import InputTextField from 'ui-component/form/InputTextField';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useStyles } from 'views/utilities/Style';
import useGetAxios from 'hooks/useGetAxios';
import { useLocation, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import InfoIcon from '@mui/icons-material/Info';
import { formatDate } from 'utils/formatColumnsHeaders';
import { SetNotification } from 'store/services/api';
import { formatErpResponse } from 'utils/utils';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import Loader from 'ui-component/Loader';
import FormDateTime from 'ui-component/dateTime/FormDateTime';
import { useTranslation } from 'react-i18next';

const AddCouponCode = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { t } = useTranslation();
    const { id } = useParams();
    const pricingRulesId = location.state?.pricingRulesId;
    const validFrom = location.state?.valid_from;
    const validUpto = location.state?.valid_upto;
    const [loadingButton, setLoadingButton] = React.useState(false);
    const [formErrState, setFormErrState] = React.useState({});
    const title = (
        <Box display={'flex'} flexDirection={'column'}>
            <ArrowBackIcon className={classes.backLink} onClick={() => navigate(-1)} />
            {id ? `Code Promo : ${id}` : 'Créer un code promo'}
        </Box>
    );

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const { data: doc, isPending } = useGetAxios(`${process.env.REACT_APP_API_URI}/api/details?doctype=Coupon%20Code&name=${id}`);

    const [stateCouponCode, setStateCouponCode] = React.useState({
        doctype: 'Coupon Code',
        valid_from: validFrom,
        valid_upto: validUpto,
        coupon_type: 'Promotional',
        pricing_rule: '',
        coupon_name: '',
        description: '',
        maximum_use: '',
        used: ''
    });

    const handleChange = (e) => {
        setStateCouponCode({ ...stateCouponCode, [e.target.name]: e.target.value });

        // err handlers
        if (e.target.value == '') {
            setFormErrState({ ...formErrState, [e.target.name]: true });
        } else {
            setFormErrState({ ...formErrState, [e.target.name]: false });
        }
    };

    const handleSaveCouponCode = () => {
        setLoadingButton(true);

        let data = {
            docstatus: 0,
            ...(id && {
                creation: doc.creation,
                modified: doc.modified,
                owner: doc.owner,
                modified_by: doc.owner,
                name: doc.name,
                __last_sync_on: doc.__last_sync_on,
                __unsaved: doc.__unsaved
            }),
            doctype: 'Coupon Code',
            coupon_type: stateCouponCode.coupon_type,
            coupon_name: stateCouponCode.coupon_name,
            maximum_use: stateCouponCode.maximum_use,
            pricing_rule: pricingRulesId ?? pricingRulesId,
            valid_from: stateCouponCode.valid_from,
            valid_upto: stateCouponCode.valid_upto,
            description: stateCouponCode.description
        };

        fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doc: data, action: 'Save' })
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    dispatch(SetNotification({ code: 'success', message: 'Condition commerciale sauvegardé avec succès' }));
                    navigate(`/core/pricing-rule/${pricingRulesId}`);
                }
            });
    };

    useEffect(() => {
        if (id && !Array.isArray(doc) && doc) {
            setStateCouponCode({
                ...stateCouponCode,
                valid_from: formatDate(doc?.valid_from, 'YYYY-MM-DD'),
                valid_upto: formatDate(doc?.valid_upto, 'YYYY-MM-DD'),
                pricing_rule: doc?.pricing_rule,
                coupon_name: doc?.coupon_name,
                description: doc?.description?.replace(/<\/?[^>]+(>|$)/g, ''),
                maximum_use: doc?.maximum_use,
                used: doc?.used
            });
        }
    }, [id, doc]);

    if (id && isPending) return <Loader />;
    return (
        <Box>
            <MainCard title={title}>
                <Box display={'flex'} mt={-4} mb={4}>
                    <InfoIcon sx={{ mt: 0.6 }} />
                    <Typography p={1} variant="h5" color="primary">
                        {`Le code promo permet à un client de bénéficier d’une réduction sur les produits du panier d’achat.`}
                    </Typography>
                </Box>
                <Box p={5} sx={{ borderRadius: 2, bgcolor: 'Menu' }}>
                    <Box mt={3}>
                        <Grid container spacing={2} columns={2} display={'flex'} justifyContent={'center'}>
                            <Grid item xs={1}>
                                <InputTextField
                                    fullwidth
                                    value={stateCouponCode.coupon_name}
                                    handleChange={handleChange}
                                    name="coupon_name"
                                    label="Nom du coupon"
                                    required
                                    sx={{ mb: 1 }}
                                    disabled={id}
                                />
                                {id && doc && (
                                    <>
                                        <InputTextField
                                            type="number"
                                            fullwidth
                                            value={stateCouponCode.used}
                                            handleChange={handleChange}
                                            name="used"
                                            label="Utilisé"
                                            sx={{ mb: 1 }}
                                            disabled={id}
                                        />
                                    </>
                                )}
                            </Grid>
                            <Grid item xs={1}>
                                <InputTextField
                                    type="number"
                                    fullwidth
                                    value={stateCouponCode.maximum_use}
                                    handleChange={handleChange}
                                    name="maximum_use"
                                    label="Utilisation maximale "
                                    sx={{ mb: 1 }}
                                    disabled={id}
                                />
                                {id && doc && (
                                    <InputTextField
                                        fullwidth
                                        value={stateCouponCode.pricing_rule}
                                        handleChange={handleChange}
                                        name="pricing_rule"
                                        label="Condition"
                                        sx={{ mb: 1 }}
                                        disabled={id}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </Box>

                    <Box mt={2}>
                        <Divider>
                            <Chip label="Paramètres de la période" />
                        </Divider>
                    </Box>
                    <Grid mt={2} container spacing={2} columns={2} display={'flex'} justifyContent={'center'}>
                        <Grid item xs={1}>
                            <FormDateTime
                                fullwidth
                                type="date"
                                label={'Valable à partir de '}
                                name="valid_from"
                                value={stateCouponCode.valid_from}
                                handleChange={handleChange}
                                disabled={id}
                                sx={{ mb: 1 }}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <FormDateTime
                                fullwidth
                                type="date"
                                label={"valable jusqu'à"}
                                name="valid_upto"
                                value={stateCouponCode.valid_upto}
                                handleChange={handleChange}
                                disabled={id}
                                sx={{ mb: 1 }}
                            />
                        </Grid>
                    </Grid>
                    <Box mt={2}>
                        <Divider>
                            <Chip label={t('common:description')} />
                        </Divider>
                    </Box>

                    <Grid mt={2} container spacing={2} display={'flex'} justifyContent={'left'} flexDirection={'column'}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            <InputTextField
                                multiline
                                rows={5}
                                value={stateCouponCode.description}
                                handleChange={handleChange}
                                name="description"
                                label={t('common:description')}
                                disabled={id}
                            />
                        </Box>
                    </Grid>
                    {!id && (
                        <Box display={'flex'} mt={3} justifyContent="right">
                            <LoadingButton
                                variant="contained"
                                size="large"
                                color="blue"
                                loading={loadingButton}
                                onClick={handleSaveCouponCode}
                            >
                                {t('common:save')}
                            </LoadingButton>
                        </Box>
                    )}
                </Box>
            </MainCard>
        </Box>
    );
};

export default AddCouponCode;
