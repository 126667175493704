import React, { useEffect, useState } from 'react';
import { useStyles } from 'views/utilities/Style';
import Chart from 'react-apexcharts';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { useSelector } from 'react-redux';
import MainCard from 'ui-component/cards/MainCard';
import { Box, IconButton } from '@mui/material';
import { BarChart, LineChart } from '@mui/x-charts';

function DynamicChart({ doc, isPending, reqData, title, type }) {
    const classes = useStyles();
    const globalDefaults = useSelector((state) => state.login.globalDefaults);

    const [chartState, setChartState] = useState({
        options: {
            tooltip: {},
            chart: {
                id: 'dyn-chart',
                stacked: false,
                type: type,
                toolbar: {
                    show: true
                },
                zoom: {
                    enabled: true
                }
            },
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        legend: {
                            position: 'bottom',
                            offsetX: -10,
                            offsetY: 0
                        }
                    }
                }
            ],
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '50%'
                }
            },
            legend: {
                show: true,
                fontSize: '12px',
                fontFamily: `'Quicksand', sans-serif`,
                position: 'bottom',
                offsetX: 20,
                labels: {
                    useSeriesColors: false
                },
                markers: {
                    width: 16,
                    height: 16,
                    radius: 5
                },
                itemMargin: {
                    horizontal: 15,
                    vertical: 8
                }
            },
            fill: {
                type: 'solid'
            },
            dataLabels: {
                enabled: false
            },
            grid: {
                show: true
            },
            xaxis: {
                type: 'category',
                categories: []
            }
        },
        series: []
    });

    const lookup = {
        Warehouse: 'warehouse',
        Invoice: 'sales_invoice',
        'Item Code': 'item_code',
        'Item Group': 'item_group',
        Brand: 'brand',
        Customer: 'customer',
        'Customer Group': 'customer_group',
        Territory: 'territory',
        Item: 'item',
        Supplier: 'supplier',
        'Supplier Group': 'supplier_group'
    };

    function getField(name) {
        return lookup[name] || 'Unknown';
    }

    useEffect(() => {
        if (!isPending && doc?.result) {
            let categories = [];

            if (reqData?.report_name === 'Sales Analytics' || reqData?.report_name === 'Stock Analytics') {
                doc?.chart?.data?.labels?.map((el) => {
                    categories.push(el);
                });
                let series = [];
                doc?.result
                    ?.filter((el) => !Array.isArray(el))
                    ?.map((item) => {
                        series.push({
                            name: reqData?.report_name === 'Sales Analytics' ? item.entity : `${item.name}: ${item.item_name}`,
                            data: Object.keys(item)
                                .filter((key) => key.includes('_20'))
                                .map((key) => item[key])
                        });
                    });
                setChartState((prevChartState) => ({
                    ...prevChartState,
                    options: {
                        ...prevChartState.options,
                        xaxis: { ...prevChartState.options.xaxis, categories }
                    },
                    series: series
                }));
            } else if (reqData?.report_name === 'Purchase Receipt Trends') {
                let ttlRecievedAmount = [];
                doc.result?.forEach((el) => {
                    if (!Array.isArray(el)) {
                        categories.push(el?.[getField(reqData?.filters?.based_on)]);
                        ttlRecievedAmount.push(el?.['total(amt)']);
                    }
                });

                setChartState((prevChartState) => ({
                    ...prevChartState,
                    options: {
                        ...prevChartState.options,
                        xaxis: { ...prevChartState.options.xaxis, categories }
                    },
                    series: [{ name: `Total Recieved Amount (${globalDefaults?.default_currency})`, data: ttlRecievedAmount }]
                }));
            } else if (reqData?.report_name === 'Sales Order Analysis') {
                doc?.chart?.data?.labels?.map((el) => {
                    categories.push(el);
                });
                setChartState((prevChartState) => ({
                    ...prevChartState,
                    options: {
                        ...prevChartState.options,
                        labels: categories
                    },
                    series: doc?.chart?.data?.datasets?.[0]?.values
                }));
            } else {
                let sellingAmount = [];
                let buyingAmount = [];
                let grossProfit = [];

                doc.result?.forEach((el) => {
                    if (!Array.isArray(el)) {
                        categories.push(el?.[getField(reqData?.filters?.group_by)]);
                        sellingAmount.push(el?.selling_amount);
                        buyingAmount.push(el?.buying_amount);
                        grossProfit.push(el?.gross_profit);
                    }
                });

                setChartState((prevChartState) => ({
                    ...prevChartState,
                    options: {
                        ...prevChartState.options,
                        xaxis: { ...prevChartState.options.xaxis, categories }
                    },
                    series: [
                        { name: 'Selling Amount', data: sellingAmount },
                        { name: 'Buying Amount', data: buyingAmount },
                        { name: 'Gross Profit', data: grossProfit }
                    ]
                }));
            }
        }
    }, [doc]);

    return (
        <MainCard
            isWhite
            boxShadow
            divider
            title={
                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} gap={5}>
                    <Box color={'ActiveCaption'} display={'flex'} alignItems={'center'}>
                        {title}
                    </Box>
                    <IconButton
                        onClick={() =>
                            setChartState({
                                ...chartState,
                                options: {
                                    ...chartState.options,
                                    chart: {
                                        ...chartState.options.chart,
                                        type: 'bar'
                                    }
                                }
                            })
                        }
                    >
                        <ShowChartIcon />
                    </IconButton>
                </Box>
            }
            // sx={{ width: '100%', height: 400 }}
        >
            <BarChart
                series={chartState.series} // Pass the series data directly
                height={300}
                xAxis={[{ data: chartState.options.xaxis.categories, scaleType: 'band' }]} // Extract categories for x-axis
                margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
            />
        </MainCard>
    );
}

export default DynamicChart;
