import { Box, Button, Grid, IconButton, OutlinedInput, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import React, { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';
import SelectForm from 'ui-component/form/SelectForm';
import { useTranslation } from 'react-i18next';

function SupplierItemsForm({ setSupplierItemsState, supplierItemsState, doc, disabled, suppliers, formState }) {
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setRow2Insert({
            doctype: 'Item Supplier',
            parent: doc?.name ? doc?.name : formState.item_code,
            parentfield: 'supplier_items',
            parenttype: 'Item',
            supplier: '',
            supplier_part_no: ''
        });
    };

    const [row2Insert, setRow2Insert] = useState({
        doctype: 'Item Supplier',
        parent: doc?.name ? doc?.name : formState.item_code,
        parentfield: 'supplier_items',
        parenttype: 'Item',
        supplier: '',
        supplier_part_no: ''
    });

    const handleChangeValues = (e) => {
        setRow2Insert({ ...row2Insert, [e.target.name]: e.target.value });
    };

    const handleInsert = () => {
        if (supplierItemsState.some((item) => item.supplier === row2Insert.supplier)) {
            return;
        }
        setSupplierItemsState([...supplierItemsState, row2Insert]);
        handleClose();
    };

    const handleDelete = (el) => {
        setSupplierItemsState(supplierItemsState.filter((row) => row.supplier !== el.supplier));
    };

    return (
        <div style={{ padding: 20 }}>
            <Box>
                <Grid container justifyContent={'space-between'} display={'flex'} alignItems={'center'}>
                    <Typography fontWeight={600} variant="h4">
                        {t('core:supplierDetails')}
                    </Typography>
                    <Button color="blue" onClick={handleOpen} disabled={disabled}>
                        <AddIcon sx={{ marginLeft: -1 }} /> {t('core:addN')}
                    </Button>
                </Grid>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <strong>{t('core:supplier')}</strong>
                            </TableCell>
                            <TableCell>
                                <strong>{t('core:supplierPN')}</strong>
                            </TableCell>
                            <TableCell align="right" width={50}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {supplierItemsState?.map((el, i) => (
                            <TableRow key={i}>
                                <TableCell>
                                    <strong>{el?.supplier}</strong>
                                </TableCell>
                                <TableCell>
                                    <strong>{el?.supplier_part_no}</strong>
                                </TableCell>
                                <TableCell align="right">
                                    <IconButton disabled={disabled} onClick={() => handleDelete(el)}>
                                        <DeleteIcon color="error" />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                        {(open || supplierItemsState?.length === 0) && !disabled && (
                            <TableRow key={999}>
                                <TableCell>
                                    <SelectForm
                                        fullwidth
                                        handleChange={handleChangeValues}
                                        value={row2Insert.supplier}
                                        name="supplier"
                                        propToRender={'name'}
                                        data={suppliers}
                                    />
                                </TableCell>
                                <TableCell>
                                    <OutlinedInput
                                        fullwidth
                                        handleChange={handleChangeValues}
                                        value={row2Insert.supplier_part_no}
                                        name="supplier_part_no"
                                        placeholder={'Supplier Part Number'}
                                    />
                                </TableCell>
                                <TableCell align="right" width={100}>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <IconButton onClick={handleInsert} disabled={!row2Insert.supplier}>
                                            <DoneIcon color={!row2Insert.supplier ? 'disabled' : ''} />
                                        </IconButton>
                                        <IconButton onClick={handleClose}>
                                            <DeleteIcon color="error" />
                                        </IconButton>
                                    </div>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Box>
        </div>
    );
}

export default SupplierItemsForm;
