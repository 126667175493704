import { useNavigate, useParams } from 'react-router';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/system';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { Grid } from '@mui/material';

import { LoadingButton } from '@mui/lab';
import { useStyles } from 'views/utilities/Style';
import InputTextField from 'ui-component/form/InputTextField';
import MainCard from 'ui-component/cards/MainCard';
import Loader from 'ui-component/Loader';
import useGetAxios from '../../../hooks/useGetAxios';
import { SetNotification } from 'store/services/api';
import { useEffect } from 'react';
const FamilleProductEdit = () => {
    const classes = useStyles();
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [libelle, setLibille] = useState('');
    const [abrege, setAbrege] = useState('');
    const [code, setCode] = useState('');
    const [loadingButton, setLoadingButton] = useState(false);

    const { data: doc, isPending } = useGetAxios(`${process.env.REACT_APP_API_URI}/api/details?doctype=Famille&name=${id}`);
    useEffect(() => {
        if (doc) {
            setLibille(doc.libelle || '');
            setAbrege(doc.abrege || '');
            setCode(doc.code || '');
        }
    }, [doc]);
    const handleEditFamille = () => {
        setLoadingButton(true);
        const header = {
            Accept: 'application/json',
            'content-type': 'application/json',
            'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem('user'))?.token
        };
        const requestData = {
            doc: {
                ...(!id && { __islocal: 1 }),
                ...(id && {
                    creation: doc.creation,
                    modified: doc.modified,
                    owner: doc.owner,
                    modified_by: doc.owner,
                    name: doc.name
                }),
                doctype: 'Famille',
                code: code,
                libelle: libelle,
                abrege: abrege,
                __unsaved: 1
            },
            action: 'Save'
        };
        console.log(requestData);
        fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(requestData)
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                setLoadingButton(false);
                dispatch(SetNotification({ code: 'success', message: t('common:family_edit_success') }));
                return navigate('/pim/famille-products-view');
            })
            .then((data) => {
                console.log('Response:', data);
            })
            .catch((error) => {
                dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                console.error('Error:', error);
            });
    };

    const disabledFieldStyle = {
        opacity: 0.7,
        pointerEvents: 'none'
    };

    if (id && isPending) return <Loader />;
    const title = (
        <Box p={-1} display={'flex'} flexDirection={'column'}>
            <ArrowBackIcon className={classes.backLink} onClick={() => navigate(-1)} />
            {id ? `${t('common:modifier-famille-des-produits')} : ${doc?.name}` : ''}
        </Box>
    );

    return (
        <Box>
            <MainCard title={title}>
                <Grid container spacing={2} display={'flex'} justifyContent={'left'} flexDirection={'column'}>
                    <div style={disabledFieldStyle}>
                        <InputTextField
                            handleChange={(e) => setCode(e.target.value)}
                            value={code}
                            label={t('common:code')}
                            name="code"
                            disabled={true}
                            sx={{ mb: 2 }}
                        />
                    </div>
                    <div>
                        <InputTextField
                            handleChange={(e) => setLibille(e.target.value)}
                            value={libelle}
                            label={t('common:uom_name')}
                            name="libelle"
                        />
                    </div>
                    {/* <InputTextField
                       handleChange={(e) => setAbrege(e.target.value)}
                        value={abrege}
                        label={'Abrégé'}
                        name="Abrégé"
                    />*/}
                </Grid>
                <Grid container item xs={12} sm={12} md={8} lg={8} xl={8} spacing={4} display={'flex'} justifyContent={'center'}>
                    <Grid item mt={3}>
                        <LoadingButton
                            variant="contained"
                            size="large"
                            color="blue"
                            onClick={() => handleEditFamille()}
                            loading={loadingButton}
                        >
                            {t('common:save')}
                        </LoadingButton>
                    </Grid>
                </Grid>
            </MainCard>
        </Box>
    );
};
export default FamilleProductEdit;
