import { useNavigate, useParams } from 'react-router';
import { useStyles } from '../../../utilities/Style';
import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MainCard from '../../../../ui-component/cards/MainCard';
import InputTextField from '../../../../ui-component/form/InputTextField';
import { LoadingButton } from '@mui/lab';
import { t } from 'i18next';
import useGetAxios from '../../../../hooks/useGetAxios';
import Loader from '../../../../ui-component/Loader';
import { SetNotification } from '../../../../store/services/api';
import { useDispatch } from 'react-redux';

const ServicePrestationEdit = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const classes = useStyles();
    const [code, setCode] = useState('');
    const [name, setName] = useState('');
    const [amount, setAmount] = useState(0);
    const [loadingButton, setLoadingButton] = useState(false);
    const dispatch = useDispatch();
    const { data: doc, isPending } = useGetAxios(`${process.env.REACT_APP_API_URI}/api/details?doctype=Service&name=${id}`);
    useEffect(() => {
        if (doc) {
            setCode(doc.service_code || '');
            setName(doc.service_name || '');
            setAmount(doc.amount || 0);
        }
    }, [doc]);
    const handleEditService = () => {
        setLoadingButton(true);
        const header = {
            Accept: 'application/json',
            'content-type': 'application/json',
            'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem('user'))?.token
        };
        const requestData = {
            docname: id,
            service_code: doc?.name,
            service_name: name,
            amount: amount
        };
        fetch(`${process.env.REACT_APP_API_URI}/api/update-service-prestation`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(requestData)
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                setLoadingButton(false);
                dispatch(SetNotification({ code: 'success', message: 'Service modifier avec succès' }));
                return navigate('/pim/service-provision');
            })
            .then((data) => {
                console.log('Response:', data);
            })
            .catch((error) => {
                dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                console.error('Error:', error);
            });
    };

    const title = (
        <Box p={-1} display={'flex'} flexDirection={'column'}>
            <ArrowBackIcon className={classes.backLink} onClick={() => navigate(-1)} />
            {!id ? '' : `${'Service'}: ${doc?.service_name}`}
        </Box>
    );
    if (id && isPending) return <Loader />;
    return (
        <MainCard title={title}>
            <Grid container spacing={2} display={'flex'} justifyContent={'left'} flexDirection={'column'}>
                <InputTextField readOnly className={classes.readOnlyInput} value={code} label={'code'} name="code" />
                <InputTextField required handleChange={(e) => setName(e.target.value)} value={name} label={'name'} name="name" />
                <InputTextField
                    required
                    type={'number'}
                    handleChange={(e) => setAmount(e.target.value)}
                    value={amount}
                    label={'amount'}
                    name="amount"
                />
            </Grid>
            <Grid container item xs={12} sm={12} md={8} lg={8} xl={8} spacing={4} display={'flex'} justifyContent={'center'}>
                <Grid item mt={3}>
                    <LoadingButton variant="contained" size="large" color="blue" onClick={handleEditService} loading={loadingButton}>
                        {t('common:save')}
                    </LoadingButton>
                </Grid>
            </Grid>
        </MainCard>
    );
};
export default ServicePrestationEdit;
