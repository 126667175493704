import { Box, InputLabel, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import SelectForm from 'ui-component/form/SelectForm';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FormDateTime from 'ui-component/dateTime/FormDateTime';
import { useTranslation } from 'react-i18next';
import Nothing2Show from 'views/SalesDashboard/components/Nothing2Show';
import Report from 'views/SalesDashboard/components/Report';
import moment from 'moment';
import { formatDate } from 'helpers/utils';
import useFetch from 'hooks/useFetch';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { LoadingButton } from '@mui/lab';
import useReportMoyenStock from 'hooks/useReportMoyenStock';
import InfoIcon from '@mui/icons-material/Info';

function StockBalance() {
    const globalDefaults = useSelector((state) => state.login.globalDefaults);
    const { t } = useTranslation();
    const [loadingButton, setLoadingButton] = useState(false);
    const [date, setDate] = useState({
        from_date: moment().subtract(1, 'months').format('YYYY-MM-DD'),
        to_date: moment().format('YYYY-MM-DD')
    });

    const handleFromDateChange = (e) => {
        setDate({ ...date, from_date: formatDate(e.target.value, 'YYYY-MM-DD') });
    };

    const handleToDateChange = (e) => {
        setDate({ ...date, to_date: formatDate(e.target.value, 'YYYY-MM-DD') });
    };
    const [filterState, setFilterState] = useState({
        warehouse: '',
        item_code: '',
        item_group: ''
    });

    const handleChange = (event) => {
        setFilterState({ ...filterState, [event.target.name]: event.target.value });
    };

    useEffect(() => {
        setBalanceReqData({
            report_name: 'Stock Balance',
            filters: {
                company: globalDefaults?.default_company,
                from_date: date?.from_date,
                to_date: date?.to_date,
                warehouse: filterState?.warehouse,
                item_code: filterState?.item_code,
                item_group: filterState?.item_group
            }
        });
    }, [filterState, date]);

    const [balanceReqData, setBalanceReqData] = useState({
        report_name: 'Stock Balance',
        filters: {
            company: globalDefaults?.default_company,
            from_date: date?.from_date,
            to_date: date?.to_date,
            warehouse: filterState?.warehouse,
            item_code: filterState?.item_code,
            item_group: filterState?.item_group
        }
    });

    const { data, isPending, refetch } = useReportMoyenStock(`${process.env.REACT_APP_API_URI}/api/report-query`, balanceReqData);

    const warehouseReqData = {
        doctype: 'Warehouse',
        fields: ['`tabWarehouse`.`name`'],
        start: 0,
        page_length: 500
    };
    const itemReqData = {
        doctype: 'Item',
        fields: ['`tabItem`.`name`'],
        start: 0,
        page_length: 500
    };
    const itemGroupReqData = {
        doctype: 'Item Group',
        fields: ['`tabItem Group`.`name`'],
        start: 0,
        page_length: 500
    };

    const { data: warehouse, isPending: isWrhPending } = useFetch(`/api/get-list`, warehouseReqData, 'POST');
    const { data: items, isPending: isItemsPending } = useFetch(`/api/get-list`, itemReqData, 'POST');
    const { data: itemGroups, isPending: isItemGroupPending } = useFetch(`/api/get-list`, itemGroupReqData, 'POST');

    const generateReport = async () => {
        setLoadingButton(true);

        const header = {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
                Authorization: JSON.parse(localStorage.getItem('user'))?.token
            }
        };
        axios
            .post(`${process.env.REACT_APP_API_URI}/api/stock/prepare-report`, balanceReqData, header)
            .then(async function (response) {
                await axios.post(`${process.env.REACT_APP_API_URI}/api/get-prepared-report`, balanceReqData, header).then((res) => {
                    if (res?.data) {
                        setTimeout(() => {
                            refetch({});
                        }, 1000);
                    }
                });
            })
            .catch(function (error) {})
            .finally(function () {
                setLoadingButton(false);
            });
    };
    function formatRelativeDateTime(datetimeString) {
        const date = new Date(datetimeString);
        const now = new Date();

        const diffInSeconds = Math.floor((now - date) / 1000);

        if (diffInSeconds < 60) {
            return 'just now';
        } else if (diffInSeconds < 3600) {
            const minutes = Math.floor(diffInSeconds / 60);
            return `${minutes}  ${t('common:minutes')}.`;
        } else if (diffInSeconds < 86400) {
            const hours = Math.floor(diffInSeconds / 3600);
            return `${hours} ${t('common:hours')}.`;
        } else {
            const formatter = new Intl.DateTimeFormat('en', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric'
            });
            return formatter.format(date);
        }
    }

    const formattedDateTime = data?.doc?.report_end_time && formatRelativeDateTime(data?.doc?.report_end_time);
    return (
        <div style={{ opacity: isPending ? 0.5 : 1, transition: 'all .2s' }}>
            <div style={{ display: 'flex', flexDirection: 'row', gap: 20 }}>
                {/* filters */}
                <div style={{ width: '20%' }}>
                    <MainCard
                        isWhite
                        title={
                            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} gap={5}>
                                <Box color={'ActiveCaption'} display={'flex'} alignItems={'center'}>
                                    {t('dashboard:f')}
                                </Box>
                                <FilterAltIcon />
                            </Box>
                        }
                        boxShadow
                        divider
                        sx={{ width: '100%' }}
                    >
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                            <div>
                                <InputLabel sx={{ fontSize: 12, p: 0.5 }}>{t('dashboard:fd')}</InputLabel>
                                <FormDateTime
                                    fullwidth={true}
                                    name="from_date"
                                    value={date.from_date}
                                    handleChange={handleFromDateChange}
                                    type="date"
                                />
                            </div>
                            <div>
                                <InputLabel sx={{ fontSize: 12, p: 0.5 }}>{t('dashboard:td')}</InputLabel>
                                <FormDateTime
                                    handleChange={handleToDateChange}
                                    value={date.to_date}
                                    fullwidth={true}
                                    type="date"
                                    name="to_date"
                                />
                            </div>
                            <div>
                                <InputLabel sx={{ fontSize: 12, p: 0.5 }}>{t('columns:warehouse')}</InputLabel>
                                <SelectForm
                                    fullwidth={true}
                                    isFilter={true}
                                    name="warehouse"
                                    value={filterState.warehouse}
                                    handleChange={handleChange}
                                    data={warehouse ?? warehouse}
                                    propToRender={'name'}
                                />
                            </div>
                            <div>
                                <InputLabel sx={{ fontSize: 12, p: 0.5 }}>{t('products:item_code')}</InputLabel>
                                <SelectForm
                                    fullwidth={true}
                                    isFilter={true}
                                    name="item_code"
                                    value={filterState.item_code}
                                    handleChange={handleChange}
                                    data={items ?? items}
                                    propToRender={'name'}
                                />
                            </div>
                            <div>
                                <InputLabel sx={{ fontSize: 12, p: 0.5 }}>{t('products:item_group')}</InputLabel>
                                <SelectForm
                                    fullwidth={true}
                                    isFilter={true}
                                    name="item_group"
                                    value={filterState.item_group}
                                    handleChange={handleChange}
                                    data={itemGroups ?? itemGroups}
                                    propToRender={'name'}
                                />
                            </div>
                        </div>
                    </MainCard>
                </div>

                <div style={{ width: '80%' }}>
                    <MainCard
                        title={
                            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} gap={5}>
                                <Box color="blue" display={'flex'} alignItems={'center'} fontSize={20} fontWeight={800}>
                                    {t('dashboard:stb')}
                                </Box>
                                <LoadingButton loading={loadingButton} color="primary" variant="contained" onClick={generateReport}>
                                    {data?.doc === null ? t('common:generateReport') : t('common:rebuild')}
                                </LoadingButton>
                            </Box>
                        }
                        isWhite
                        boxShadow
                        divider
                    >
                        <Box display={'flex'} mt={-2} mb={2}>
                            <InfoIcon sx={{ mt: 0.6 }} />
                            <Typography p={1} variant="h5" color="primary">
                                {data?.doc != null
                                    ? formattedDateTime === 'just now'
                                        ? t('common:info')
                                        : `${t('common:info1Report')} ${formattedDateTime} ${t('common:info2Report')}`
                                    : t('dashboard:info')}
                            </Typography>
                        </Box>
                        {data?.doc === null ? (
                            <Nothing2Show message="This is a background report. Please set the appropriate filters and then generate a new one." />
                        ) : data?.result?.length === 0 ? (
                            <Nothing2Show message={t('common:nothing2show')} />
                        ) : (
                            <Report doc={data} isBalance={true} title={t('dashboard:stb')} />
                        )}
                    </MainCard>
                </div>
            </div>
        </div>
    );
}

export default StockBalance;
