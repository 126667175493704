import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import MainCard from 'ui-component/cards/MainCard';
import { useStyles } from 'views/utilities/Style';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Grid, Typography, Button, Table, TableHead, TableBody, TableRow, TableCell, OutlinedInput, Chip, Divider } from '@mui/material';
import InputTextField from 'ui-component/form/InputTextField';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';
import { LoadingButton } from '@mui/lab';
import useGetAxios from 'hooks/useGetAxios';
import Loader from 'ui-component/Loader';
import { SetNotification } from 'store/services/api';
import { useDispatch, useSelector } from 'react-redux';
import { formatErpResponse } from 'utils/utils';
import SelectForm from 'ui-component/form/SelectForm';
import useFetch from 'hooks/useFetch';
import FormDateTime from 'ui-component/dateTime/FormDateTime';
import InfoIcon from '@mui/icons-material/Info';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const LoyaltyProgramForm = () => {
    const classes = useStyles();
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const globalDefaults = useSelector((state) => state.login.globalDefaults);

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const [accounts, setAccounts] = useState([]);
    const [loadingButton, setLoadingButton] = useState(false);
    const [collectionValues, setcollectionValues] = useState([]);
    const [collectionRulesValues, setcollectionRulesValues] = useState({
        tier_name: '',
        min_spent: '',
        collection_factor: ''
    });

    const [state, setState] = useState({
        title: '',
        loyalty_program_type: 'Single Tier Program',
        customer_group: '',
        customer_territory: '',
        company: globalDefaults?.default_company,
        from_date: moment().format('YYYY-MM-DD'),
        to_date: '',
        conversion_factor: '',
        expiry_duration: 0
    });

    const nextDate = moment(state?.from_date).add(1, 'days').format('YYYY-MM-DD');

    const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
    };

    const [open, setOpen] = useState(true);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setcollectionRulesValues({ tier_name: '', min_spent: '', collection_factor: '' });
    };

    const handleChangeCollectionValues = (e) => {
        setcollectionRulesValues({ ...collectionRulesValues, [e.target.name]: e.target.value });
    };

    const handleAddcollectionValues = () => {
        const rowIndex = collectionValues.findIndex((el) => el.tier_name == collectionRulesValues.tier_name);
        if (rowIndex == -1) {
            setcollectionValues([...collectionValues, collectionRulesValues]);
        }
        handleClose();
        setcollectionRulesValues({ tier_name: '', min_spent: '', collection_factor: '' });
    };

    const handleDelete = (el) => {
        const data = collectionValues.filter((row) => row.tier_name != el.tier_name);
        setcollectionValues(data);
    };

    const { data: territory, isPending: isPendingTerritory } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Territory',
            fields: ['`tabTerritory`.`name`']
        },
        'POST'
    );
    const { data: customerGroup, isPending: isPendingCustomerGroup } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Customer Group',
            fields: ['`tabCustomer Group`.`name`']
        },
        'POST'
    );
    const { data: company, isPending: isPendingCompany } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Company',
            fields: ['`tabCompany`.`name`']
        },
        'POST'
    );

    const { data: doc, isPending } = useGetAxios(`${process.env.REACT_APP_API_URI}/api/details?doctype=Loyalty%20Program&name=${id}`);

    const convert1DinarToPoints = (conversionFactor) => {
        if (conversionFactor === 0) {
            return 0;
        }
        var loyaltyPoints = 1 / conversionFactor;
        return loyaltyPoints;
    };

    const handleSaveProgram = async () => {
        setLoadingButton(true);
        let collection_values = [];
        collectionValues.map((el) => {
            collection_values.push({
                ...(id && { parent: id }),
                doctype: 'Loyalty Program Collection',
                tier_name: el.tier_name,
                collection_factor: el.collection_factor,
                min_spent: el.min_spent,
                parentfield: 'collection_rules',
                parenttype: 'Loyalty Program',
                __islocal: 1,
                __unsaved: 1
            });
        });
        const data = {
            doc: {
                ...(!id && { __islocal: 1 }),
                ...(id && {
                    creation: doc.creation,
                    modified: doc.modified,
                    owner: doc.owner,
                    modified_by: doc.owner,
                    name: doc.name
                }),
                doctype: 'Loyalty Program',
                loyalty_program_name: state.title,
                loyalty_program_type: state.loyalty_program_type,
                customer_group: state.customer_group,
                customer_territory: state.customer_territory,
                company: state.company,
                from_date: state.from_date,
                to_date: state.to_date,
                conversion_factor: convert1DinarToPoints(state.conversion_factor),
                expiry_duration: state.expiry_duration,
                collection_rules: collection_values,
                auto_opt_in: 0,
                __unsaved: 1
            },
            action: 'Save'
        };

        fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(data)
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    dispatch(SetNotification({ code: 'success', message: 'Le Programme de fidélité sauvegardé avec succès' }));
                    navigate('/crm/loyatly-programs');
                }
            });
    };

    const title = (
        <Box p={-1} display={'flex'} flexDirection={'column'}>
            <ArrowBackIcon className={classes.backLink} onClick={() => navigate(-1)} />
            {id ? `${t('crm:lp')}: ${id}` : t('crm:newlp')}
        </Box>
    );

    useEffect(() => {
        if (id && !Array.isArray(doc) && doc) {
            setState({
                ...state,
                title: doc.loyalty_program_name,
                loyalty_program_type: doc.loyalty_program_type,
                customer_group: doc.customer_group,
                customer_territory: doc.customer_territory,
                company: doc.company,
                from_date: doc.from_date,
                to_date: doc.to_date,
                conversion_factor: convert1DinarToPoints(doc.conversion_factor),
                expiry_duration: doc.expiry_duration
            });
            let values = [];
            doc?.collection_rules?.map((el) => {
                values.push({
                    tier_name: el.tier_name,
                    min_spent: el.min_spent,
                    collection_factor: el.collection_factor
                });
            });
            setcollectionValues(values);
        }
    }, [id, doc]);

    if (id && isPending) return <Loader />;
    return (
        <Box>
            <MainCard title={title}>
                <Grid container spacing={2} display={'flex'} justifyContent={'left'} flexDirection={'column'}>
                    {!id && (
                        <InputTextField
                            error={state.title == ''}
                            label={t('crm:title')}
                            value={state.title}
                            name="title"
                            handleChange={handleChange}
                        />
                    )}
                    <SelectForm
                        label={t('crm:lptype')}
                        value={state.loyalty_program_type}
                        handleChange={handleChange}
                        data={['Single Tier Program', 'Multiple Tier Program']}
                        name="loyalty_program_type"
                    />

                    <SelectForm
                        label={t('crm:trrtry')}
                        propToRender={'name'}
                        name="customer_territory"
                        value={state.customer_territory}
                        data={territory ?? territory}
                        handleChange={handleChange}
                    />
                    <SelectForm
                        label={t('crm:customergroup')}
                        propToRender={'name'}
                        name="customer_group"
                        value={state.customer_group}
                        data={customerGroup ?? customerGroup}
                        handleChange={handleChange}
                    />
                    <FormDateTime
                        error={state.from_date == ''}
                        type="date"
                        label={t('crm:fromdate')}
                        name="from_date"
                        value={state.from_date}
                        handleChange={handleChange}
                        isValidDate
                    />
                    <FormDateTime
                        type="date"
                        label={t('crm:todate')}
                        name="to_date"
                        value={state.to_date}
                        handleChange={handleChange}
                        nextDate={nextDate}
                    />
                </Grid>
                <Divider sx={{ m: 2 }}>
                    <Chip label={t('crm:tc')} />
                </Divider>
                <Grid container xs={12} sm={12} md={8} lg={8} xl={8}>
                    <Grid item xs={12}>
                        <Box>
                            <Grid container justifyContent={'space-between'} display={'flex'} alignItems={'center'}>
                                <Typography fontWeight={600} variant="h4">
                                    {t('crm:collevel')}
                                </Typography>
                                <Button color="blue" onClick={handleOpen}>
                                    <AddIcon sx={{ marginLeft: -1 }} /> {t('crm:addrule')}
                                </Button>
                            </Grid>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <strong>{t('crm:name')}</strong>
                                        </TableCell>
                                        <TableCell>
                                            <strong>{t('crm:exmple')}</strong> <br />
                                            {t('crm:exmple-two')}
                                        </TableCell>
                                        <TableCell>
                                            <strong>{t('crm:expensemin')}</strong>
                                        </TableCell>
                                        <TableCell align="right" width={50}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {collectionValues.map((el, i) => (
                                        <TableRow key={i}>
                                            <TableCell>{el.tier_name}</TableCell>
                                            <TableCell sx={{ textTransform: 'uppercase' }}>{el.collection_factor}</TableCell>
                                            <TableCell sx={{ textTransform: 'uppercase' }}>{el.min_spent}</TableCell>
                                            <TableCell align="right" width={50}>
                                                <DeleteIcon color="error" onClick={() => handleDelete(el)} sx={{ cursor: 'pointer' }} />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    {open && (
                                        <TableRow key={999}>
                                            <TableCell>
                                                <OutlinedInput
                                                    value={collectionRulesValues.tier_name}
                                                    name="tier_name"
                                                    onChange={handleChangeCollectionValues}
                                                    sx={{ height: 28 }}
                                                    placeholder={t('crm:name')}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <OutlinedInput
                                                    type="number"
                                                    value={collectionRulesValues.collection_factor}
                                                    name="collection_factor"
                                                    onChange={handleChangeCollectionValues}
                                                    sx={{ height: 28 }}
                                                    placeholder={t('crm:fc')}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <OutlinedInput
                                                    type="number"
                                                    value={collectionRulesValues.min_spent}
                                                    name="min_spent"
                                                    onChange={handleChangeCollectionValues}
                                                    sx={{ height: 28 }}
                                                    placeholder={t('crm:expensemin')}
                                                />
                                            </TableCell>
                                            <TableCell align="right" width={50}>
                                                <DoneIcon
                                                    color={
                                                        collectionRulesValues.tier_name == '' ||
                                                        collectionRulesValues.collection_factor == ''
                                                            ? 'disabled'
                                                            : ''
                                                    }
                                                    onClick={
                                                        collectionRulesValues.tier_name == '' ||
                                                        collectionRulesValues.collection_factor == ''
                                                            ? null
                                                            : handleAddcollectionValues
                                                    }
                                                    sx={{ cursor: 'pointer' }}
                                                />
                                            </TableCell>
                                            <TableCell align="right" width={50}>
                                                <DeleteIcon color="error" onClick={handleClose} sx={{ cursor: 'pointer' }} />
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </Box>
                    </Grid>
                </Grid>
                <Divider sx={{ m: 2 }}>
                    <Chip label={t('crm:redeem')} />
                </Divider>
                <Grid container spacing={2} display={'flex'} justifyContent={'left'} flexDirection={'column'}>
                    <InputTextField
                        type="number"
                        handleChange={handleChange}
                        value={state.conversion_factor}
                        label={t('crm:cfactor')}
                        name="conversion_factor"
                        helper={t('crm:helper')}
                    />
                    <InputTextField
                        type="number"
                        handleChange={handleChange}
                        value={state.expiry_duration}
                        label={t('crm:expires')}
                        name="expiry_duration"
                        error={state.expiry_duration === 0}
                    />
                    <SelectForm
                        label={t('crm:company')}
                        propToRender={'name'}
                        name="company"
                        value={state.company}
                        data={company ?? company}
                        handleChange={handleChange}
                    />
                </Grid>
                <MainCard
                    sx={{ mt: 4 }}
                    isWhite
                    divider
                    title={
                        <Box display={'flex'} alignItems={'center'}>
                            <InfoIcon sx={{ mr: 1 }} /> Notes
                        </Box>
                    }
                >
                    <ul>
                        <li>{t('crm:lpnote-one')}</li>
                        <li>{t('crm:lpnote-two')}</li>
                        <li>{t('crm:lpnote-three')}</li>
                        <li>{t('crm:lpnote-four')}</li>
                        <li>{t('crm:lpnote-five')}</li>
                    </ul>
                </MainCard>
                <Box display={'flex'} justifyContent={'center'} mt={4}>
                    <Button sx={{ mr: 1 }} onClick={() => navigate(-1)}>
                        {t('common:cancel')}
                    </Button>
                    <LoadingButton
                        disabled={
                            !id
                                ? collectionValues.length == 0 || open || state.title == '' || state.from_date == ''
                                : state.title == '' || state.expiry_duration === 0
                        }
                        loading={loadingButton}
                        onClick={handleSaveProgram}
                        size="large"
                        variant="contained"
                        color="blue"
                    >
                        {t('crm:save')}
                    </LoadingButton>
                </Box>
            </MainCard>
        </Box>
    );
};

export default LoyaltyProgramForm;
