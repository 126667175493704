import { LoadingButton } from '@mui/lab';
import { Button, Checkbox, FormControlLabel, Grid, Modal, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useFetch from 'hooks/useFetch';
import useGetAxios from 'hooks/useGetAxios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import MainCard from 'ui-component/cards/MainCard';
import Loader from 'ui-component/Loader';
import EditIcon from '@mui/icons-material/Edit';
import { useStyles } from 'views/utilities/Style';
import { modalStyle } from 'views/utilities/ModalStyle';
import SelectForm from 'ui-component/form/SelectForm';
import { SetNotification } from 'store/services/api';
import { useDispatch } from 'react-redux';
import { formatErpResponse } from 'utils/utils';
import { useTranslation } from 'react-i18next';
import InputTextField from 'ui-component/form/InputTextField';

const CustomerGroupDetails = () => {
    const classes = useStyles();
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { data, isPending, refetch } = useGetAxios(`${process.env.REACT_APP_API_URI}/api/details?doctype=Customer%20Group&name=${id}`);

    const customerGroupsReqData = {
        doctype: 'Customer Group',
        fields: ['`tabCustomer Group`.`name`'],
        filters: [['Customer Group', 'is_group', '=', 1]],
        start: 0,
        page_length: 50
    };

    const { data: customerGroupsParents, isPending: parentsIsPending } = useFetch(`/api/get-list`, customerGroupsReqData, 'POST');

    const { data: priceLists, isPriceListsPending } = useFetch(
        `/api/get-list`,
        { doctype: 'Price List', fields: ['`tabPrice List`.`name`'], start: 0, page_length: 50 },
        'POST'
    );

    const [parent, setParent] = useState(null);
    const [defaultPriceList, setDefaultPriceList] = useState('');
    const [customerGroupCode, setCustomerGroupCode] = useState('');
    const [isGroup, setIsGroup] = useState(null);
    const [initialEnabledState, setInitialEnabledState] = useState(true);
    const [loadingButton, setLoadingButton] = useState(false);

    const handleSelectChange = (event) => {
        const {
            target: { value }
        } = event;
        setParent(value);
    };

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [newName, setNewName] = useState('');
    const handleChangeNewName = (e) => setNewName(e.target.value);

    const handleSelectChangePriceList = (event) => {
        const {
            target: { value }
        } = event;
        setDefaultPriceList(value);
    };

    const saveCustomerGroupData = {
        doctype: 'Customer Group',
        freeze: true,
        docnames: [id],
        action: 'update',
        data: {
            is_group: isGroup == null ? data.is_group : isGroup ? 1 : 0,
            parent_customer_group: parent ? parent : data?.parent_customer_group,
            default_price_list: defaultPriceList,
            customer_group_code: customerGroupCode
        }
    };

    const renameCustomerGroupData = {
        doctype: 'Customer Group',
        freeze: true,
        docnames: [id],
        action: 'update',
        data: { customer_group_name: newName }
    };

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const handleSaveCustomerGroup = async () => {
        setLoadingButton(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/update-status`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(saveCustomerGroupData)
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    let successMessage;
                    if (id) {
                        if (isGroup !== initialEnabledState) {
                            if (isGroup) {
                                successMessage = t('common:statCatClientIsGroup');
                            } else {
                                successMessage = t('common:statCatClientIsNotGroup');
                            }
                        } else {
                            successMessage = t('common:grpeClientEditSuccess');
                        }
                    } else {
                        successMessage = t('common:catClientCreateSuccess');
                    }
                    dispatch(SetNotification({ code: 'success', message: successMessage }));
                    navigate('/crm/customer-category-list-view');
                }
            });
    };

    const handleRenameCustomerGroup = async () => {
        fetch(`${process.env.REACT_APP_API_URI}/api/update-status`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(renameCustomerGroupData)
        })
            .then((res) => {
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                    throw Error('Something went wrong');
                }
                return res.json();
            })
            .then((data) => {
                dispatch(SetNotification({ code: 'success', message: t('common:grpeClientsRenameSuccess') }));
                handleClose();
                refetch({});
            });
    };

    const renameForm = (
        <Box sx={modalStyle}>
            <MainCard divider title={`Renommer ${data?.customer_group_name}`}>
                <TextField
                    onChange={handleChangeNewName}
                    value={newName}
                    required
                    sx={{ width: '100%' }}
                    label="Nouveau nom de catégorie"
                    id="outlined-size-small"
                />
                {/* <FormControlLabel
                    sx={{ mt: 2 }}
                    label="Fusionner avec l'existant (cette opération ne peut être annulée)."
                    control={<Checkbox checked={false} />}
                />*/}
                <Box display={'flex'} justifyContent={'flex-end'} mt={3}>
                    <LoadingButton
                        color="blue"
                        size="large"
                        variant="contained"
                        disabled={newName === ''}
                        onClick={handleRenameCustomerGroup}
                    >
                        Renommer
                    </LoadingButton>
                </Box>
            </MainCard>
        </Box>
    );

    useEffect(() => {
        if (id && !Array.isArray(data) && data) {
            setParent(data?.parent ?? data?.parent_customer_group);
            setIsGroup(data?.is_group === 1 ? true : false);
            setNewName(data?.customer_group_name);
            setDefaultPriceList(data?.default_price_list);
            setInitialEnabledState(data?.is_group === 1 ? true : false);
            setCustomerGroupCode(data?.customer_group_code);
        }
    }, [id, data]);

    if (isPending || parentsIsPending || isPriceListsPending) return <Loader />;

    return (
        <div>
            <MainCard
                title={
                    <Box p={-1} display={'flex'} justifyContent={'space-between'}>
                        <Box display={'flex'} flexDirection={'column'}>
                            <ArrowBackIcon className={classes.backLink} onClick={() => navigate(-1)} />
                            <Box>
                                {t('crm:cstmergrp')}: {data?.customer_group_name}{' '}
                                <EditIcon sx={{ fontSize: 17, ml: 1, cursor: 'pointer' }} onClick={handleOpen} />
                            </Box>
                        </Box>
                        {false && (
                            <Box>
                                <Button variant="outlined" color="blue">
                                    {t('crm:cstmergrouplist')}
                                </Button>
                            </Box>
                        )}
                    </Box>
                }
            >
                <FormControlLabel
                    label="Parent"
                    control={
                        <Checkbox
                            color="blue"
                            checked={isGroup == null ? Boolean(data.is_group) : isGroup}
                            onChange={() => (isGroup == null ? setIsGroup(!data.is_group) : setIsGroup(!isGroup))}
                        />
                    }
                />
                <Typography id="modal-modal-description" sx={{ fontSize: 11 }}>
                    {t('common:msgcheckbox')}
                </Typography>
                <InputTextField
                    sx={{ mt: 0 }}
                    error={customerGroupCode === ''}
                    handleChange={(e) => setCustomerGroupCode(e.target.value)}
                    value={customerGroupCode}
                    required
                    label={t('crm:cstmergrpcode')}
                    id="outlined-size-small"
                    size="small"
                />
                <SelectForm
                    sx={{ mt: 0 }}
                    label={t('columns:parent_customer_group')}
                    name="parent_item_group"
                    value={parent ? parent : data?.parent_item_group}
                    handleChange={handleSelectChange}
                    data={customerGroupsParents ?? customerGroupsParents}
                    propToRender={'name'}
                />
                <SelectForm
                    sx={{ mt: -2 }}
                    label={t('products:defaultPriceList')}
                    value={defaultPriceList}
                    handleChange={handleSelectChangePriceList}
                    data={priceLists}
                    propToRender={'name'}
                />
                <Grid container xs={12} sm={12} md={8} lg={8} xl={8}>
                    <Grid item xs={12}>
                        <Box display={'flex'} justifyContent={'center'} mt={4}>
                            <Button sx={{ mr: 1 }} onClick={() => navigate(-1)}>
                                {t('common:cancel')}
                            </Button>
                            <LoadingButton
                                onClick={handleSaveCustomerGroup}
                                variant="contained"
                                color="blue"
                                size="large"
                                loading={loadingButton}
                            >
                                {t('common:save')}
                            </LoadingButton>
                        </Box>
                    </Grid>
                </Grid>
            </MainCard>
            <Modal onClose={handleClose} open={open}>
                {renameForm}
            </Modal>
        </div>
    );
};

export default CustomerGroupDetails;
