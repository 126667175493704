import dashboard from './dashboard';
import core from './core';
import pim from './pim';
import oms from './oms';
import users from './users';
import sales from './sales';
import crm from './crm';
import documents from './documents';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, documents, pim, oms, core, crm, sales, users]
};

export default menuItems;
