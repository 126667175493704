// assets
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { ROLE } from '../configs/constants';

// constant
const icons = {
    SupportAgentIcon
};

const crm = {
    id: 'crm',
    title: 'CRM',
    caption: 'Gestion CRM',
    type: 'group',
    role: [ROLE.ADMIN, ROLE.COMMERCIAL, ROLE.ADMIN_WAREHOUSE],
    children: [
        {
            id: 'CRM',
            title: 'dashboard:CRM',
            type: 'collapse',
            icon: icons.SupportAgentIcon,
            children: [
                {
                    id: 'customers',
                    title: 'crm:customers',
                    type: 'collapse',
                    children: [
                        {
                            id: 'customer_group',
                            title: 'crm:grpsdeclient',
                            type: 'item',
                            url: '/crm/customer-category-list-view'
                        },
                        {
                            id: 'default',
                            title: 'crm:Qrcode',
                            type: 'item',
                            url: '/qrcode/list',
                            breadcrumbs: false
                        },
                        {
                            id: 'customer',
                            title: 'crm:customers',
                            type: 'item',
                            url: '/crm/customer'
                        }
                    ]
                },
                {
                    id: 'customers',
                    title: 'crm:lyltysec',
                    type: 'collapse',
                    children: [
                        {
                            id: 'loyatly-programs',
                            title: 'crm:lps',
                            type: 'item',
                            url: '/crm/loyatly-programs'
                        },
                        {
                            id: 'loyatly-cards',
                            title: 'crm:lyltycard',
                            type: 'item',
                            url: '/crm/loyatly-cards'
                        }
                    ]
                }
            ]
        }
    ]
};

export default crm;
