import React, { useEffect, useState } from 'react';
import { Box, IconButton } from '@mui/material';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { useSelector } from 'react-redux';
import MainCard from 'ui-component/cards/MainCard';
import { BarChart } from '@mui/x-charts';

function ChartPimDash({ doc, isPending, reqData, title, type }) {
    const globalDefaults = useSelector((state) => state.login.globalDefaults);

    const [chartState, setChartState] = useState({
        options: {
            tooltip: {},
            chart: {
                id: 'dyn-chart',
                stacked: false,
                type: type,
                toolbar: {
                    show: true
                },
                zoom: {
                    enabled: true
                }
            },
            xaxis: {
                type: 'category',
                categories: []
            }
        },
        series: []
    });

    useEffect(() => {
        if (!isPending && doc?.result?.length > 0) {
            let categories = ['Amount to Bill', 'Billed Amount']; // Categories for the donut chart
            let data = doc?.chart?.data?.datasets?.[0]?.values || [0, 0]; // Get the values for chart

            // Set the chart state
            setChartState({
                ...chartState,
                options: {
                    ...chartState.options,
                    xaxis: {
                        categories: categories
                    }
                },
                series: [{ name: 'Amounts', data }] // Set data for chart series
            });
        } else {
            // If no data, show "No Data" chart
            setChartState({
                ...chartState,
                options: {
                    ...chartState.options,
                    xaxis: { categories: ['No Data'] }
                },
                series: [{ name: 'No Data', data: [0] }]
            });
        }
    }, [doc, isPending, reqData]);

    return (
        <MainCard
            isWhite
            boxShadow
            divider
            title={
                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} gap={5}>
                    <Box color={'ActiveCaption'} display={'flex'} alignItems={'center'}>
                        {title}
                    </Box>
                    <IconButton
                        onClick={() =>
                            setChartState({
                                ...chartState,
                                options: {
                                    ...chartState.options,
                                    chart: {
                                        ...chartState.options.chart,
                                        type: chartState.options.chart.type === 'bar' ? 'line' : 'bar' // Toggle chart type
                                    }
                                }
                            })
                        }
                    >
                        <ShowChartIcon />
                    </IconButton>
                </Box>
            }
        >
            <BarChart
                series={chartState.series} // Pass the series data directly
                height={300}
                xAxis={[{ data: chartState.options.xaxis.categories, scaleType: 'band' }]} // Extract categories for x-axis
                margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
            />
        </MainCard>
    );
}

export default ChartPimDash;
