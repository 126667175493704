/* eslint-disable no-undef */
import React, { useState, useRef } from 'react';
import Box from '@mui/material/Box';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useEffect } from 'react';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import axios from 'axios';
import { t } from 'i18next';

const AddAssets = ({ handleClose, loadingButton, setLoadingButton, refetch, doc, isEdit, onSuccess }) => {
    const [data, setData] = useState();
    const [isDoctypeAssets, setDoctypeAssets] = useState([]);
    const [isDoctypeSelected, setDoctypeSelected] = useState('');
    const [isDocnameSelected, setDocnameSelected] = useState('');
    const [isDoctypeData, setDoctypeData] = useState([]);
    const [isDocnameData, setDocnameData] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [IsFileSelectedError, setIsFileSelectedError] = useState(false);
    const [open, setOpen] = useState(false);
    const [isDoctypeSelectedError, setIsDoctypeSelectedError] = useState(false);
    const [isDocnameSelectedError, setIsDocnameSelectedError] = useState(false);
    const handleOpen = () => setOpen(true);

    const fileInputRef = useRef(null);

    const handleBoxClick = () => {
        // Clicking the Box component triggers this function
        // You can use it to focus the file input element
        fileInputRef.current.click();
    };

    const header = {
        Accept: 'application/json',
        // "content-type": "multipart/form-data",
        'content-type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };
    const header2 = {
        Accept: 'application/json',
        'content-type': 'multipart/form-data',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URI}/api/get-doctype-assets-file`, { method: 'POST', headers: header })
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    throw new Error('Request failed');
                }
            })
            .then((data) => {
                console.log(data);
                setData(data);
                setDoctypeAssets(Object.keys(data));
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setData('not exist');
                setDoctypeAssets(['not exist data']);
                setIsLoading(false);
            });
    }, []);

    const handleCancel = () => {
        handleClose();
    };
    const handleSelectChangeDoctype = (event) => {
        const selectedValue = event.target.value;
        setDoctypeSelected(selectedValue);
        console.log('Selected Item:', selectedValue);
        setDoctypeData(data[selectedValue].values.map((item) => item[0]));
        if (selectedValue === 'Item' || selectedValue === 'Customer') {
            setDocnameData(data[selectedValue].values.map((item) => item[1]));
        }
    };
    const handleSelectChangeDocname = (event) => {
        const selectedValue = event.target.value;
        setDocnameSelected(selectedValue);
    };

    const uploadHandler = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };

    const handleUpload = async () => {
        setLoadingButton(true);
        const data = {
            file: selectedFile,
            doctype: isDoctypeSelected,
            docname: isDocnameSelected
        };
        if (!selectedFile || !isDoctypeSelected || !isDocnameSelected) {
            !selectedFile ? setIsFileSelectedError(true) : setIsFileSelectedError(false);
            !isDoctypeSelected ? setIsDoctypeSelectedError(true) : setIsDoctypeSelectedError(false);
            !isDocnameSelected ? setIsDocnameSelectedError(true) : setIsDocnameSelectedError(false);
            return;
        }
        setIsFileSelectedError(false);
        setIsDoctypeSelectedError(false);
        setIsDocnameSelectedError(false);
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URI}/api/add-assets-file  `, data, { headers: header2 });
            console.log('Upload successful', response);
            setLoadingButton(false);
            onSuccess();
            handleClose();
            // Handle success response here
        } catch (error) {
            console.error('Upload failed', error);
            setLoadingButton(false);
            // Handle error response here
        }
    };

    return (
        <Box>
            <Box p={1}>
                <PerfectScrollbar style={{ overflow: 'scroll', height: 200 }}>
                    <Box sx={{ padding: '5px 0px' }}>
                        <Box key={'assets'}>
                            <FormControl fullWidth required>
                                <InputLabel id="demo-simple-select-label">{t('products:Attachedto')}</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Customer Type"
                                    name="customer_type"
                                    error={isDoctypeSelectedError}
                                    onChange={handleSelectChangeDoctype}
                                >
                                    {isLoading ? (
                                        <div style={{ padding: '10px', textAlign: 'center' }}>Loading...</div>
                                    ) : (
                                        isDoctypeAssets.map((item, index) => (
                                            <MenuItem key={index} value={item}>
                                                {item}
                                            </MenuItem>
                                        ))
                                    )}
                                </Select>
                            </FormControl>
                        </Box>
                        <FormControl fullWidth sx={{ mt: 2 }} required>
                            <InputLabel mt={0.5} id="demo-simple-select-label2">
                                {t('products:Nameof')} {isDoctypeSelected}
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="salutation"
                                name="salutation"
                                error={isDocnameSelectedError}
                                onChange={handleSelectChangeDocname}
                            >
                                {isDoctypeData.map((item, index) => (
                                    <MenuItem key={index} value={item}>
                                        {item}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <Box
                            p={1}
                            display="flex"
                            alignItems="center"
                            flexDirection="row"
                            bgcolor="Menu"
                            sx={{
                                borderRadius: 2,
                                mt: 2,
                                border: IsFileSelectedError ? '1px solid red' : 'none'
                            }}
                            onClick={handleBoxClick}
                        >
                            <UploadFileIcon />
                            <Typography id="modal-modal-description" color="primary" sx={{ ml: 2, fontSize: 14 }} fontWeight={500}>
                                {selectedFile?.name}
                            </Typography>
                            <Typography id="modal-modal-description" color="primary" sx={{ ml: 2, fontSize: 14 }}>
                                {selectedFile?.type}
                            </Typography>
                            <Typography id="modal-modal-description" color="error" sx={{ ml: 2, fontSize: 14, width: 20 }}>
                                {selectedFile?.size && (selectedFile?.size / 1024 / 1024).toFixed(2)} MB
                            </Typography>
                            <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={uploadHandler} />
                        </Box>
                    </Box>
                </PerfectScrollbar>
            </Box>
            <Box display={'flex'} justifyContent={'flex-end'} mt={2}>
                <Box>
                    <Grid container spacing={1}>
                        <Grid item>
                            <Button color="blue" size="large" variant="outlined" onClick={handleCancel}>
                                {t('common:cancel')}
                            </Button>
                        </Grid>
                        <Grid item>
                            <LoadingButton color="blue" size="large" variant="contained" loading={loadingButton} onClick={handleUpload}>
                                {t('common:save')}
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
};

export default AddAssets;
