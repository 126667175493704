import React from 'react';
import { useState, useEffect } from 'react';
// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Avatar, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Typography } from '@mui/material';

// assets
import {
    IconPackgeExport,
    IconPackgeImport,
    IconReceiptRefund,
    IconHomePlus,
    IconHomeCancel,
    IconBellRinging2,
    IconBrandShopee,
    IconShoppingCartPlus,
    IconClipboardList
} from '@tabler/icons';
import { FormatNotifications } from 'helpers/utils';
import { useNavigate } from 'react-router';
import { SetNotification } from 'store/services/api';
import { useDispatch } from 'react-redux';

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 16,
    borderRadius: 10,
    '&:hover': {
        background: theme.palette.background.paper
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));

const header = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
    Authorization: JSON.parse(localStorage.getItem('user'))?.token
};

const NotificationList = (props) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { notificationList, refetchNotifications, isPopper, filter } = props;

    const [notifications, setNotifications] = useState([]);

    const lookup = {
        'Material Request Material Transfer': '/documents/inventory-docs/',
        'Material Request Purchase': '/documents/inventory-docs/',
        'Stock Entry': '/documents/inventory-docs/',
        Fulfillment: '/documents/sales-docs/',
        'POS Opening Entry': '/documents/pos-docs/',
        'POS Closing Entry': '/documents/pos-docs/',
        'POS Invoice': '/documents/sales-docs/',
        Coffre: '/documents/pos-docs/coffre-operation/',
        Inventory: '/documents/inventory-docs/'
    };

    function getUrl(name) {
        for (let key in lookup) {
            if (name === key) {
                return lookup[key];
            }
        }
        return '';
    }

    function getName(doc) {
        if (doc?.message?.list_id) {
            return doc?.message?.list_id;
        } else if (doc?.message?.name) {
            return doc?.message?.name;
        } else if (doc?.message?.id) {
            return doc?.message?.id;
        }
        return '';
    }

    function handleNavigation(doc) {
        const formatted = FormatNotifications([doc]);
        const url = getUrl(formatted?.[0]?.doctype);
        const name = getName(doc);

        if (url === '' || name === '') {
            dispatch(SetNotification({ message: 'The document is not found', code: 'error' }));
        } else {
            navigate(`${url + name}`);
        }
    }

    const markAsRead = async (id) => {
        let currentUserId = JSON.parse(localStorage.getItem('user'))?.id;
        const notifIndex = notificationList?.findIndex((el) => el.id === id);
        if (notifIndex !== -1 && currentUserId) {
            handleNavigation(notificationList[notifIndex]);
            const isRead = notificationList[notifIndex]?.users?.some((user) => user.id === currentUserId);
            if (!isRead) {
                await fetch(`${process.env.REACT_APP_API_URI}/api/mark-as-read`, {
                    method: 'POST',
                    headers: header,
                    body: JSON.stringify({
                        id: id,
                        user_id: currentUserId
                    })
                });
                refetchNotifications({});
            }
        }
    };

    function readByCurrentUser(users, currentUserId) {
        return users?.some((user) => user.id === currentUserId);
    }

    useEffect(() => {
        let currentUserId = JSON.parse(localStorage.getItem('user'))?.id;
        if (notificationList) {
            const notificationRows = notificationList?.filter((e) => e != null && e != undefined);
            if (filter === 'Unread') {
                let rows = notificationRows;
                let resultRows = [];
                rows?.forEach((el) => {
                    if (!readByCurrentUser(el.users, currentUserId)) {
                        resultRows.push(el);
                    }
                });
                const formatted = FormatNotifications(resultRows)?.filter((e) => e != null && e != undefined);
                setNotifications(formatted);
            } else {
                setNotifications(FormatNotifications(notificationRows)?.filter((e) => e != null && e != undefined));
            }
        }
    }, [filter, notificationList]);

    const getIcon = (type) => {
        switch (type) {
            case 'Material Request Material Transfer':
                return (
                    <Avatar
                        sx={{
                            color: theme.palette.warning.dark,
                            backgroundColor: theme.palette.warning.light,
                            border: 'none',
                            borderColor: theme.palette.warning.main
                        }}
                    >
                        <IconPackgeExport stroke={1.5} size="1.3rem" />
                    </Avatar>
                );
            case 'Material Request Purchase':
                return (
                    <Avatar
                        sx={{
                            color: theme.palette.success.dark,
                            backgroundColor: theme.palette.success.light,
                            border: 'none',
                            borderColor: theme.palette.success.main
                        }}
                    >
                        <IconShoppingCartPlus stroke={1.5} size="1.3rem" />
                    </Avatar>
                );
            case 'Stock Entry':
                return (
                    <Avatar
                        sx={{
                            color: theme.palette.success.dark,
                            backgroundColor: theme.palette.success.light,
                            border: 'none',
                            borderColor: theme.palette.success.main
                        }}
                    >
                        <IconPackgeImport stroke={1.5} size="1.3rem" />
                    </Avatar>
                );
            case 'Fulfillment':
                return (
                    <Avatar
                        sx={{
                            color: theme.palette.success.dark,
                            backgroundColor: theme.palette.success.light,
                            border: 'none',
                            borderColor: theme.palette.success.main
                        }}
                    >
                        <IconBrandShopee stroke={1.5} size="1.3rem" />
                    </Avatar>
                );
            case 'POS Opening Entry':
                return (
                    <Avatar
                        sx={{
                            color: theme.palette.success.dark,
                            backgroundColor: theme.palette.success.light,
                            border: 'none',
                            borderColor: theme.palette.success.main
                        }}
                    >
                        <IconHomePlus stroke={1.5} size="1.3rem" />
                    </Avatar>
                );
            case 'POS Closing Entry':
                return (
                    <Avatar
                        sx={{
                            color: theme.palette.error.dark,
                            backgroundColor: theme.palette.error.light,
                            border: 'none',
                            borderColor: theme.palette.error.main
                        }}
                    >
                        <IconHomeCancel stroke={1.5} size="1.3rem" />
                    </Avatar>
                );
            case 'POS Invoice':
                return (
                    <Avatar
                        sx={{
                            color: theme.palette.success.dark,
                            backgroundColor: theme.palette.success.light,
                            border: 'none',
                            borderColor: theme.palette.success.main
                        }}
                    >
                        <IconReceiptRefund stroke={1.5} size="1.3rem" />
                    </Avatar>
                );
            case 'Inventory':
                return (
                    <Avatar
                        sx={{
                            color: theme.palette.success.dark,
                            backgroundColor: theme.palette.success.light,
                            border: 'none',
                            borderColor: theme.palette.success.main
                        }}
                    >
                        <IconClipboardList stroke={1.5} size="1.3rem" />
                    </Avatar>
                );

            default:
                return (
                    <Avatar
                        sx={{
                            color: theme.palette.success.dark,
                            backgroundColor: theme.palette.success.light,
                            border: 'none',
                            borderColor: theme.palette.success.main
                        }}
                    >
                        <IconBellRinging2 stroke={1.5} size="1.3rem" />
                    </Avatar>
                );
        }
    };

    function formatRelativeTime(created_at) {
        let now = new Date();
        let createdDate = new Date(created_at);

        let diffInMs = now - createdDate;
        let diffInSeconds = Math.floor(diffInMs / 1000);
        let diffInMinutes = Math.floor(diffInSeconds / 60);
        let diffInHours = Math.floor(diffInMinutes / 60);
        let diffInDays = Math.floor(diffInHours / 24);

        if (diffInDays > 0) {
            return `${diffInDays} day${diffInDays > 1 ? 's' : ''} ago`;
        } else if (diffInHours > 0) {
            return `${diffInHours} hour${diffInHours > 1 ? 's' : ''} ago`;
        } else if (diffInMinutes > 0) {
            return `${diffInMinutes} minute${diffInMinutes > 1 ? 's' : ''} ago`;
        } else {
            return `${diffInSeconds} second${diffInSeconds > 1 ? 's' : ''} ago`;
        }
    }

    return (
        <List
            sx={{
                width: '100%',
                maxWidth: 330,
                py: 0
            }}
        >
            {notifications?.slice(0, isPopper ? 3 : notifications?.length)?.map((notification, index) => (
                <div key={notification?.id} style={{ minWidth: 330 }}>
                    <ListItemWrapper style={{ margin: 5 }} components={'button'} onClick={() => markAsRead(notification?.id)}>
                        <ListItem alignItems="center">
                            <ListItemAvatar>{getIcon(notification?.doctype)}</ListItemAvatar>
                            <ListItemText
                                sx={{ maxWidth: '55%' }}
                                primary={
                                    <Typography variant="subtitle1">
                                        {notification?.read == true ? notification?.doctype : <strong>{notification?.doctype}</strong>}
                                    </Typography>
                                }
                            />
                            <ListItemSecondaryAction>
                                <Typography variant="caption" display="block">
                                    {formatRelativeTime(notification?.created_at)}
                                </Typography>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <div>
                            <Typography variant="subtitle2">
                                {notification?.read == true ? notification?.text : <strong>{notification?.text}</strong>}.
                            </Typography>
                        </div>
                    </ListItemWrapper>
                </div>
            ))}
        </List>
    );
};

export default NotificationList;
