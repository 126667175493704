import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';

const CountriesSelect = ({ value, onChange, error, countries, required = false, disabled = false }) => {
    const { t } = useTranslation();

    const countryTranslations = {
        Afghanistan: t('countries:afghanistan'),
        'Åland Islands': t('countries:alandIslands'),
        Albania: t('countries:albania'),
        Algeria: t('countries:algeria'),
        'American Samoa': t('countries:americanSamoa'),
        Andorra: t('countries:andorra'),
        Angola: t('countries:angola'),
        Anguilla: t('countries:anguilla'),
        Antarctica: t('countries:antarctica'),
        'Antigua and Barbuda': t('countries:antiguaAndBarbuda'),
        Argentina: t('countries:argentina'),
        Armenia: t('countries:armenia'),
        Aruba: t('countries:aruba'),
        Australia: t('countries:australia'),
        Austria: t('countries:austria'),
        Azerbaijan: t('countries:azerbaijan'),
        Bahamas: t('countries:bahamas'),
        Bahrain: t('countries:bahrain'),
        Bangladesh: t('countries:bangladesh'),
        Barbados: t('countries:barbados'),
        Belarus: t('countries:belarus'),
        Belgium: t('countries:belgium'),
        Belize: t('countries:belize'),
        Benin: t('countries:benin'),
        Bermuda: t('countries:bermuda'),
        Bhutan: t('countries:bhutan'),
        'Bolivia, Plurinational State of': t('countries:bolivia'),
        'Bonaire, Sint Eustatius and Saba': t('countries:bonaireSintEustatiusAndSaba'),
        'Bosnia and Herzegovina': t('countries:bosniaAndHerzegovina'),
        Botswana: t('countries:botswana'),
        'Bouvet Island': t('countries:bouvetIsland'),
        Brazil: t('countries:brazil'),
        'British Indian Ocean Territory': t('countries:britishIndianOceanTerritory'),
        'Brunei Darussalam': t('countries:bruneiDarussalam'),
        Bulgaria: t('countries:bulgaria'),
        'Burkina Faso': t('countries:burkinaFaso'),
        Burundi: t('countries:burundi'),
        Cambodia: t('countries:cambodia'),
        Cameroon: t('countries:cameroon'),
        Canada: t('countries:canada'),
        'Cape Verde': t('countries:capeVerde'),
        'Cayman Islands': t('countries:caymanIslands'),
        'Central African Republic': t('countries:centralAfricanRepublic'),
        Chad: t('countries:chad'),
        Chile: t('countries:chile'),
        China: t('countries:china'),
        'Christmas Island': t('countries:christmasIsland'),
        'Cocos (Keeling) Islands': t('countries:cocosKeelingIslands'),
        Colombia: t('countries:colombia'),
        Comoros: t('countries:comoros'),
        Congo: t('countries:congo'),
        'Congo, The Democratic Republic of the': t('countries:democraticRepublicOfTheCongo'),
        'Cook Islands': t('countries:cookIslands'),
        'Costa Rica': t('countries:costaRica'),
        'Ivory Coast': t('countries:coteDIvoire'),
        Croatia: t('countries:croatia'),
        Cuba: t('countries:cuba'),
        Curaçao: t('countries:curacao'),
        Cyprus: t('countries:cyprus'),
        'Czech Republic': t('countries:czechRepublic'),
        Denmark: t('countries:denmark'),
        Djibouti: t('countries:djibouti'),
        Dominica: t('countries:dominica'),
        'Dominican Republic': t('countries:dominicanRepublic'),
        Ecuador: t('countries:ecuador'),
        Egypt: t('countries:egypt'),
        'El Salvador': t('countries:elSalvador'),
        'Equatorial Guinea': t('countries:equatorialGuinea'),
        Eritrea: t('countries:eritrea'),
        Estonia: t('countries:estonia'),
        Ethiopia: t('countries:ethiopia'),
        'Falkland Islands (Malvinas)': t('countries:falklandIslands'),
        'Faroe Islands': t('countries:faroeIslands'),
        Fiji: t('countries:fiji'),
        Finland: t('countries:finland'),
        France: t('countries:france'),
        'French Guiana': t('countries:frenchGuiana'),
        'French Polynesia': t('countries:frenchPolynesia'),
        'French Southern Territories': t('countries:frenchSouthernTerritories'),
        Gabon: t('countries:gabon'),
        Gambia: t('countries:gambia'),
        Georgia: t('countries:georgia'),
        Germany: t('countries:germany'),
        Ghana: t('countries:ghana'),
        Gibraltar: t('countries:gibraltar'),
        Greece: t('countries:greece'),
        Greenland: t('countries:greenland'),
        Grenada: t('countries:grenada'),
        Guadeloupe: t('countries:guadeloupe'),
        Guam: t('countries:guam'),
        Guatemala: t('countries:guatemala'),
        Guernsey: t('countries:guernsey'),
        Guinea: t('countries:guinea'),
        'Guinea-Bissau': t('countries:guineaBissau'),
        Guyana: t('countries:guyana'),
        Haiti: t('countries:haiti'),
        'Heard Island and McDonald Islands': t('countries:heardIslandAndMcDonaldIslands'),
        'Holy See (Vatican City State)': t('countries:vaticanCity'),
        Honduras: t('countries:honduras'),
        'Hong Kong': t('countries:hongKong'),
        Hungary: t('countries:hungary'),
        Iceland: t('countries:iceland'),
        India: t('countries:india'),
        Indonesia: t('countries:indonesia'),
        Iran: t('countries:iran'),
        Iraq: t('countries:iraq'),
        Ireland: t('countries:ireland'),
        'Isle of Man': t('countries:isleOfMan'),
        Israel: t('countries:israel'),
        Italy: t('countries:italy'),
        Jamaica: t('countries:jamaica'),
        Japan: t('countries:japan'),
        Jersey: t('countries:jersey'),
        Jordan: t('countries:jordan'),
        Kazakhstan: t('countries:kazakhstan'),
        Kenya: t('countries:kenya'),
        Kiribati: t('countries:kiribati'),
        'Korea, Democratic Peoples Republic of': t('countries:northKorea'),
        'Korea, Republic of': t('countries:southKorea'),
        Kuwait: t('countries:kuwait'),
        Kyrgyzstan: t('countries:kyrgyzstan'),
        'Lao Peoples Democratic Republic': t('countries:laos'),
        Latvia: t('countries:latvia'),
        Lebanon: t('countries:lebanon'),
        Lesotho: t('countries:lesotho'),
        Liberia: t('countries:liberia'),
        Libya: t('countries:libya'),
        Liechtenstein: t('countries:liechtenstein'),
        Lithuania: t('countries:lithuania'),
        Luxembourg: t('countries:luxembourg'),
        Macao: t('countries:macao'),
        Macedonia: t('countries:macedonia'),
        Madagascar: t('countries:madagascar'),
        Malawi: t('countries:malawi'),
        Malaysia: t('countries:malaysia'),
        Maldives: t('countries:maldives'),
        Mali: t('countries:mali'),
        Malta: t('countries:malta'),
        'Marshall Islands': t('countries:marshallIslands'),
        Martinique: t('countries:martinique'),
        Mauritania: t('countries:mauritania'),
        Mauritius: t('countries:mauritius'),
        Mayotte: t('countries:mayotte'),
        Mexico: t('countries:mexico'),
        'Micronesia, Federated States of': t('countries:micronesia'),
        'Moldova, Republic of': t('countries:moldova'),
        Monaco: t('countries:monaco'),
        Mongolia: t('countries:mongolia'),
        Montenegro: t('countries:montenegro'),
        Montserrat: t('countries:montserrat'),
        Morocco: t('countries:morocco'),
        Mozambique: t('countries:mozambique'),
        Myanmar: t('countries:myanmar'),
        Namibia: t('countries:namibia'),
        Nauru: t('countries:nauru'),
        Nepal: t('countries:nepal'),
        Netherlands: t('countries:netherlands'),
        'New Caledonia': t('countries:newCaledonia'),
        'New Zealand': t('countries:newZealand'),
        Nicaragua: t('countries:nicaragua'),
        Niger: t('countries:niger'),
        Nigeria: t('countries:nigeria'),
        Niue: t('countries:niue'),
        'Norfolk Island': t('countries:norfolkIsland'),
        'Northern Mariana Islands': t('countries:northernMarianaIslands'),
        Norway: t('countries:norway'),
        Oman: t('countries:oman'),
        Pakistan: t('countries:pakistan'),
        Palau: t('countries:palau'),
        'Palestinian Territory, Occupied': t('countries:palestine'),
        Panama: t('countries:panama'),
        'Papua New Guinea': t('countries:papuaNewGuinea'),
        Paraguay: t('countries:paraguay'),
        Peru: t('countries:peru'),
        Philippines: t('countries:philippines'),
        Pitcairn: t('countries:pitcairn'),
        Poland: t('countries:poland'),
        Portugal: t('countries:portugal'),
        'Puerto Rico': t('countries:puertoRico'),
        Qatar: t('countries:qatar'),
        Réunion: t('countries:reunion'),
        Romania: t('countries:romania'),
        'Russian Federation': t('countries:russia'),
        Rwanda: t('countries:rwanda'),
        'Saint Barthélemy': t('countries:saintBarthelemy'),
        'Saint Helena, Ascension and Tristan da Cunha': t('countries:saintHelena'),
        'Saint Kitts and Nevis': t('countries:saintKittsAndNevis'),
        'Saint Lucia': t('countries:saintLucia'),
        'Saint Martin (French part)': t('countries:saintMartin'),
        'Saint Pierre and Miquelon': t('countries:saintPierreAndMiquelon'),
        'Saint Vincent and the Grenadines': t('countries:saintVincentAndGrenadines'),
        Samoa: t('countries:samoa'),
        'San Marino': t('countries:sanMarino'),
        'Sao Tome and Principe': t('countries:saoTomeAndPrincipe'),
        'Saudi Arabia': t('countries:saudiArabia'),
        Senegal: t('countries:senegal'),
        Serbia: t('countries:serbia'),
        Seychelles: t('countries:seychelles'),
        'Sierra Leone': t('countries:sierraLeone'),
        Singapore: t('countries:singapore'),
        'Sint Maarten (Dutch part)': t('countries:sintMaarten'),
        Slovakia: t('countries:slovakia'),
        Slovenia: t('countries:slovenia'),
        'Solomon Islands': t('countries:solomonIslands'),
        Somalia: t('countries:somalia'),
        'South Africa': t('countries:southAfrica'),
        'South Georgia and the South Sandwich Islands': t('countries:southGeorgiaAndSouthSandwichIslands'),
        'South Sudan': t('countries:southSudan'),
        Spain: t('countries:spain'),
        'Sri Lanka': t('countries:sriLanka'),
        Sudan: t('countries:sudan'),
        Suriname: t('countries:suriname'),
        'Svalbard and Jan Mayen': t('countries:svalbardAndJanMayen'),
        Swaziland: t('countries:swaziland'),
        Sweden: t('countries:sweden'),
        Switzerland: t('countries:switzerland'),
        Syria: t('countries:syria'),
        Taiwan: t('countries:taiwan'),
        Tajikistan: t('countries:tajikistan'),
        Tanzania: t('countries:tanzania'),
        Thailand: t('countries:thailand'),
        'Timor-Leste': t('countries:timorLeste'),
        Togo: t('countries:togo'),
        Tokelau: t('countries:tokelau'),
        Tonga: t('countries:tonga'),
        'Trinidad and Tobago': t('countries:trinidadAndTobago'),
        Tunisia: t('countries:tunisia'),
        Turkey: t('countries:turkey'),
        Turkmenistan: t('countries:turkmenistan'),
        'Turks and Caicos Islands': t('countries:turksAndCaicosIslands'),
        Tuvalu: t('countries:tuvalu'),
        Uganda: t('countries:uganda'),
        Ukraine: t('countries:ukraine'),
        'United Arab Emirates': t('countries:unitedArabEmirates'),
        'United Kingdom': t('countries:unitedKingdom'),
        'United States': t('countries:unitedStates'),
        'United States Minor Outlying Islands': t('countries:unitedStatesMinorOutlyingIslands'),
        Uruguay: t('countries:uruguay'),
        Uzbekistan: t('countries:uzbekistan'),
        Vanuatu: t('countries:vanuatu'),
        'Venezuela, Bolivarian Republic of': t('countries:venezuela'),
        Vietnam: t('countries:vietnam'),
        'Virgin Islands, British': t('countries:britishVirginIslands'),
        'Virgin Islands, U.S.': t('countries:usVirginIslands'),
        'Wallis and Futuna': t('countries:wallisAndFutuna'),
        'Western Sahara': t('countries:westernSahara'),
        Yemen: t('countries:yemen'),
        Zambia: t('countries:zambia'),
        Zimbabwe: t('countries:zimbabwe')
    };

    const handleChange = (event) => {
        onChange({
            target: {
                name: 'country',
                value: event.target.value
            }
        });
    };

    const getTranslatedCountry = (countryName) => {
        return countryTranslations[countryName] || countryName;
    };

    return (
        <FormControl fullWidth required={required} error={error}>
            <InputLabel id="country-select-label" error={error}>
                {t('core:country')}
            </InputLabel>
            <Select
                labelId="country-select-label"
                id="country-select"
                value={value || ''}
                label={t('core:country')}
                onChange={handleChange}
                disabled={disabled}
                name="country"
                error={error}
            >
                {countries?.map((country, index) => (
                    <MenuItem key={index} value={country.name}>
                        {getTranslatedCountry(country.name)}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default CountriesSelect;
