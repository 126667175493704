import { LoadingButton } from '@mui/lab';
import { Grid, Checkbox, FormControlLabel, TextField } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { useStyles } from 'views/utilities/Style';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MainCard from 'ui-component/cards/MainCard';
import InputTextField from 'ui-component/form/InputTextField';
import SelectForm from 'ui-component/form/SelectForm';
import useFetch from 'hooks/useFetch';
import Loader from 'ui-component/Loader';
import { SetNotification } from 'store/services/api';
import { useEffect } from 'react';
import { formatErpResponse } from 'utils/utils';
import { SetDoc2Duplicate } from 'store/services/api';
import AutoCompleteField from '../../ui-component/form/AutoCompleteField';

const PriceListForm = () => {
    const classes = useStyles();
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const detailsDefaultCompany = useSelector((state) => state.login.detailsDefaultCompany);
    const globalDefaults = useSelector((state) => state.login.globalDefaults);
    const { t } = useTranslation();
    const [loadingButton, setLoadingButton] = useState(false);
    const [currency, setCurrency] = useState('');
    const [buying, setBuying] = useState(true);
    const [uomDependent, setUomDependent] = useState(false);
    const [nameRate, setNameRate] = useState('');
    const [priceCode, setPriceCode] = useState('');
    const [country, setCountry] = useState();
    const [enabled, setEnabled] = useState(true);
    const [countries, setCountries] = useState();
    const docToDuplicate = useSelector((state) => state.pim.docToDuplicate);
    const [state, setState] = useState({
        country: []
    });

    const [error, setError] = React.useState({
        country: false
    });

    const { data: currencyList, isPending: isCurrencyPending } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Currency',
            fields: ['`tabCurrency`.`name`', '`tabCurrency`.`currency_name`'],
            filters: [['Currency', 'enabled', '=', 1]]
        },
        'POST'
    );
    const { data: countryList, isPending: isCountryPending } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Country',
            fields: ['`tabCountry`.`name`']
        },
        'POST'
    );
    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };
    useEffect(() => {
        state.country.map((country) => {
            setCountries(
                state.country.map((country) => ({
                    doctype: 'Price List Country',
                    country: country.name,
                    parentfield: 'countries',
                    parenttype: 'Price List'
                }))
            );
        });
    }, [state.country]);

    const handleAddTarif = async () => {
        // setLoadingButton(true);
        const data = {
            doc: {
                doctype: 'Price List',
                enabled: enabled,
                currency: currency,
                countries: countries,
                buying: buying,
                price_list_name: nameRate,
                price_not_uom_dependent: uomDependent,
                price_code: priceCode
            },
            action: 'Save'
        };
        fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(data)
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    if (typeof data.message === 'string') {
                        if (data.message.includes('already exists')) {
                            dispatch(SetNotification({ code: 'warning', message: t('common:typedetarifExists') }));
                        } else if (data.message.includes('must be unique')) {
                            dispatch(SetNotification({ code: 'warning', message: t('common:code_typedetarif_existe') }));
                        } else {
                            dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                        }
                    }
                } else {
                    const successMessage = docToDuplicate
                        ? t('common:typeTarifDuplicateSuccess', { name: docToDuplicate.price_list_name })
                        : t('common:typeTarifAddSuccess');
                    dispatch(SetNotification({ code: 'success', message: successMessage }));
                    dispatch(SetDoc2Duplicate(null));
                    navigate('/oms/price_list');
                }
            });
    };

    const handleChangeSelectmultiple = (event) => {
        setError({ ...error, [event.target.name]: false });
        setState({ ...state, [event.target.name]: event.target.value });
    };

    const handleChange = (event) => {
        setNameRate(event.target.value);
    };

    useEffect(() => {
        if (docToDuplicate) {
            setCurrency(docToDuplicate?.currency);
            setBuying(docToDuplicate?.buying);
            setUomDependent(docToDuplicate?.price_not_uom_dependent);
            setEnabled(docToDuplicate?.enabled);
            setNameRate(docToDuplicate?.price_list_name);
            setState({ country: docToDuplicate?.countries.map((c) => ({ name: c.country })) });
        }
    }, [docToDuplicate]);

    const title = (
        <Box p={-1} display={'flex'} flexDirection={'column'}>
            <ArrowBackIcon className={classes.backLink} onClick={() => navigate(-1)} />
            {t('core:newpricelist')}
        </Box>
    );

    if (isCurrencyPending && isCountryPending) return <Loader />;
    return (
        <Box>
            <MainCard title={title}>
                <Grid container spacing={2} justifyContent="center" alignItems="center" pl={20}>
                    {/* Left Section */}
                    <Grid item xs={6}>
                        <Grid container spacing={2} direction="column">
                            <Grid item>
                                <InputTextField
                                    required
                                    handleChange={(e) => setPriceCode(e.target.value)}
                                    value={priceCode}
                                    label={t('core:price_code')}
                                    name="price_code"
                                />
                            </Grid>
                            {/* Enable Field */}
                            <Grid item>
                                <InputTextField
                                    required
                                    handleChange={handleChange}
                                    value={nameRate}
                                    label={t('core:pricelistname')}
                                    name="price_list_name"
                                />
                            </Grid>
                            {/* Currency Select */}
                            <Grid item>
                                <SelectForm
                                    label={t('currencies:currency')}
                                    value={currency ? currency : 'Currency'}
                                    handleChange={(e) => setCurrency(e.target.value)}
                                    data={currencyList}
                                    name="currency"
                                    propToRender="name"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item width={'200%'}>
                                <AutoCompleteField
                                    multiple
                                    label={t('core:countries')}
                                    options={countryList || []}
                                    value={state.country}
                                    name="country"
                                    handleChange={(newValue, name) => {
                                        setState({
                                            ...state,
                                            country: newValue.map((country) => ({ name: country.name }))
                                        });
                                    }}
                                />
                            </Grid>
                            <Grid item></Grid>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={true}
                                            onChange={(e) => setBuying(e.target.checked)}
                                            name="buying"
                                            color="primary"
                                            disabled
                                        />
                                    }
                                    label={t('core:buying')}
                                    style={{ display: 'none' }}
                                />
                            </Grid>
                            {/* Selling Checkbox */}
                            {/* price not UOM dependent */}
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={uomDependent}
                                            onChange={(e) => setUomDependent(e.target.checked)}
                                            name="uomDependent"
                                            color="primary"
                                        />
                                    }
                                    label={t('core:PriceNotUOMDependent')}
                                />
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={enabled}
                                            onChange={(e) => setEnabled(e.target.checked)}
                                            name="enabled"
                                            color="primary"
                                        />
                                    }
                                    label={t('columns:enabled')}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Middle Section */}
                    <Grid item xs={6}>
                        {/* Your middle content here */}
                    </Grid>

                    {/* Save Button */}
                    <Grid item xs={12}>
                        <Grid container justifyContent="center">
                            <Grid item>
                                <LoadingButton
                                    loading={loadingButton}
                                    variant="contained"
                                    size="large"
                                    color="blue"
                                    onClick={() => handleAddTarif()}
                                >
                                    {t('common:save')}
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </MainCard>
        </Box>
    );
};
export default PriceListForm;
