import React from 'react';
import { SvgIcon, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

function Nothing2Show({ message }) {
    const { t } = useTranslation();
    return (
        <div style={{ display: 'flex', flexDirection: 'row', gap: 10, alignItems: 'center' }}>
            <SvgIcon version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <g>
                    <rect x="50.187" y="86.061" style={{ fill: '#57555C' }} width="353.094" height="95.716" />
                    <rect x="50.187" y="228.855" style={{ fill: '#57555C' }} width="353.094" height="95.705" />
                    <rect x="50.187" y="371.648" style={{ fill: '#57555C' }} width="353.094" height="95.705" />
                </g>
                <g>
                    <rect x="226.729" y="86.061" style={{ fill: '#3C3A41' }} width="176.552" height="95.716" />
                    <rect x="226.729" y="228.855" style={{ fill: '#3C3A41' }} width="176.552" height="95.705" />
                    <rect x="226.729" y="371.648" style={{ fill: '#3C3A41' }} width="176.552" height="95.705" />
                </g>
                <rect x="23.349" style={{ fill: '#FFB74D' }} width="406.768" height="120.972" />
                <g>
                    <rect x="59.915" y="43.965" style={{ fill: '#FFFFFF' }} width="23.235" height="24.178" />
                    <rect x="101.726" y="43.965" style={{ fill: '#FFFFFF' }} width="23.235" height="24.178" />
                </g>
                <rect x="23.349" y="156.228" style={{ fill: '#FFB74D' }} width="406.768" height="120.972" />
                <g>
                    <rect x="59.915" y="199.965" style={{ fill: '#FFFFFF' }} width="23.235" height="24.178" />
                    <rect x="101.726" y="199.965" style={{ fill: '#FFFFFF' }} width="23.235" height="24.178" />
                </g>
                <rect x="23.349" y="312.445" style={{ fill: '#FFB74D' }} width="406.768" height="120.982" />
                <g>
                    <rect x="59.915" y="360.853" style={{ fill: '#FFFFFF' }} width="23.235" height="24.178" />
                    <rect x="101.726" y="360.853" style={{ fill: '#FFFFFF' }} width="23.235" height="24.178" />
                </g>
                <g>
                    <rect x="226.729" style={{ fill: '#FFA91E' }} width="203.389" height="120.972" />
                    <rect x="226.729" y="156.228" style={{ fill: '#FFA91E' }} width="203.389" height="120.972" />
                    <rect x="226.729" y="312.445" style={{ fill: '#FFA91E' }} width="203.389" height="120.982" />
                </g>
                <path
                    style={{ fill: '#FD7052' }}
                    d="M488.651,406.379c0,58.334-47.287,105.621-105.621,105.621c-0.251,0-0.503,0-0.754-0.01
	c-28.534-0.188-54.366-11.707-73.255-30.272c-16.963-16.67-28.324-39.015-30.994-63.978c-0.398-3.728-0.618-7.518-0.618-11.361
	c0-31.654,13.937-60.051,35.989-79.412c0.241-0.209,0.482-0.419,0.723-0.618c8.597-7.413,18.408-13.455,29.078-17.811
	c12.073-4.911,25.256-7.675,39.078-7.759c0.251-0.01,0.503-0.01,0.754-0.01C441.363,300.768,488.651,348.045,488.651,406.379z"
                />
                <path
                    style={{ fill: '#FF4F18' }}
                    d="M488.651,406.379c0,58.334-47.287,105.621-105.621,105.621c-0.251,0-0.503,0-0.754-0.01V300.778
	c0.251-0.01,0.503-0.01,0.754-0.01C441.363,300.768,488.651,348.045,488.651,406.379z"
                />
                <polygon
                    style={{ fill: '#FFFFFF' }}
                    points="404.49,407.41 432.688,435.598 410.469,457.818 382.281,429.619 352.616,459.284 
	330.407,437.064 360.061,407.41 331.873,379.212 354.082,357.003 382.27,385.18 382.281,385.191 411.935,355.537 434.144,377.756 
	"
                />
                <polygon
                    style={{ fill: '#D9D8DB' }}
                    points="404.49,407.41 432.688,435.598 410.469,457.818 382.281,429.619 382.27,429.63 
	382.27,385.18 382.281,385.191 411.935,355.537 434.144,377.756 "
                />
            </SvgIcon>
            <Typography color={'ActiveCaption'}>{message ? message : t('dashboard:message')}</Typography>
        </div>
    );
}

export default Nothing2Show;
