import { useTheme } from '@mui/material/styles';
import React from 'react';
// import strass from 'assets/images/strass.jpg';
import css from 'assets/images/css.png';
import { useSelector } from 'react-redux';

// ==============================|| LOGO PNG ||============================== //
const Logo = () => {
    const theme = useTheme();
    const detailsDefaultCompany = useSelector((state) => state.login.detailsDefaultCompany);

    return (
        <span
            style={{
                width: 155,
                height: 56,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <img
                src={
                    detailsDefaultCompany && detailsDefaultCompany?.company_logo
                        ? // ? `${process.env.REACT_APP_IMAGE_URL}` + detailsDefaultCompany?.company_logo
                          css
                        : css
                }
                alt="Company Logo"
                style={{ width: '70%', aspectRatio: '3/2', objectFit: 'contain', mixBlendMode: 'darken' }}
            />
        </span>
    );
};

export default Logo;
