import { Box } from '@mui/material';
import useFetch from 'hooks/useFetch';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { SetNotification } from 'store/services/api';
import DataRender from 'ui-component/tables/DataRender';
import { formatErpResponse } from 'utils/utils';

function InventoryDocumentsList() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const defaultCompany = useSelector((state) => state.login.detailsDefaultCompany);
    const { t } = useTranslation();

    const materialRequestReqData = {
        doctype: 'Material Request',
        fields: [
            '`tabMaterial Request`.`name`',
            '`tabMaterial Request`.`creation`',
            '`tabMaterial Request`.`status`',
            '`tabMaterial Request`.`material_request_type`',
            '`tabMaterial Request`.`set_warehouse`',
            '`tabMaterial Request`.`schedule_date`',
            '`tabMaterial Request`.`title`'
        ],
        filters: [['Material Request', 'company', '=', defaultCompany?.name]],
        start: 0,
        page_length: 50
    };

    const stockEntryReqData = {
        doctype: 'Stock Entry',
        fields: [
            '`tabStock Entry`.`name`',
            '`tabStock Entry`.`creation`',
            '`tabStock Entry`.`docstatus`',
            '`tabStock Entry`.`stock_entry_type`',
            '`tabStock Entry`.`to_warehouse`',
            '`tabStock Entry`.`from_warehouse`',
            '`tabStock Entry`.`purpose`'
        ],
        filters: [['Stock Entry', 'company', '=', defaultCompany?.name]],
        start: 0,
        page_length: 50
    };

    const invListsReqData = {
        company: defaultCompany?.name
    };

    const {
        data: materialRequests,
        isPending: isPendingMaterialRequests,
        refetch: refetchMaterialRequests
    } = useFetch(`/api/get-list`, materialRequestReqData, 'POST');

    const {
        data: invLists,
        isPending: isPendingInvLists,
        refetch: refetchInvLists
    } = useFetch(`/api/inventory-lists`, invListsReqData, 'POST');

    const {
        data: stockEntries,
        isPending: isPendingStockEntries,
        refetch: refetchStockEntries
    } = useFetch(`/api/get-list`, stockEntryReqData, 'POST');

    function sortObjectsByDate(array) {
        array.sort((a, b) => {
            const dateA = new Date(a.creation);
            const dateB = new Date(b.creation);

            return dateB - dateA;
        });

        return array;
    }

    const [data, setData] = useState([]);
    const [selectionModel, setSelectionModel] = useState([]);

    const handleEdit = (row) => {
        navigate(`/documents/inventory-docs/${row}`);
    };

    function handleRefetch(doctype) {
        switch (doctype) {
            case 'Material Request':
                return refetchMaterialRequests({});
            case 'Stock Entry':
                return refetchStockEntries({});
            default:
                return;
        }
    }

    const lookup = {
        'MAT-MR': 'Material Request',
        'MAT-STE': 'Stock Entry'
    };

    function getDocType(name) {
        for (let key in lookup) {
            if (name.startsWith(key)) {
                return lookup[key];
            }
        }
        return 'Unknown';
    }

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const handleDelete = (row) => {
        fetch(`${process.env.REACT_APP_API_URI}/api/delete-doc`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doctype: getDocType(row[0]), name: row[0] })
        })
            .then((res) => {
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: 'An error has occurred' }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    dispatch(SetNotification({ code: 'success', message: `${getDocType(row[0])} successfully deleted` }));
                    handleRefetch(getDocType(row[0]));
                }
            });
    };

    useEffect(() => {
        if (!isPendingMaterialRequests && !isPendingStockEntries && !isPendingInvLists) {
            let result = [];
            invLists.forEach((el) => {
                result.push({
                    name: el.list_id,
                    creation: el.creation_date,
                    purpose: 'Stock Reconciliation',
                    status: el.status,
                    title: 'Stock Reconciliation',
                    set_warehouse: el.warehouse,
                    from_warehouse: '',
                    schedule_date: '',
                    document: 'Stock Reconciliation'
                });
            });
            materialRequests.forEach((el) => {
                result.push({
                    name: el.name,
                    creation: el.creation,
                    purpose: el.material_request_type,
                    status: el.status,
                    title: el.title,
                    set_warehouse: el.set_warehouse,
                    from_warehouse: '',
                    schedule_date: el.schedule_date,
                    document: 'Material Request'
                });
            });
            stockEntries.forEach((el) => {
                result.push({
                    name: el.name,
                    creation: el.creation,
                    purpose: el.stock_entry_type,
                    status: el.docstatus === 0 ? 'Draft' : el.docstatus === 1 ? 'Submitted' : 'Cancelled',
                    title: el.purpose,
                    set_warehouse: el.to_warehouse,
                    from_warehouse: el.from_warehouse,
                    schedule_date: '',
                    document: 'Stock Entry'
                });
            });
            setData(result);
        }
    }, [isPendingMaterialRequests, isPendingStockEntries, isPendingInvLists]);

    return (
        <>
            <DataRender
                title={
                    <Box p={-1} display={'flex'} justifyContent={'space-between'}>
                        {t('documents:id')}
                    </Box>
                }
                isViewAction
                data={sortObjectsByDate(data)}
                loading={isPendingMaterialRequests || isPendingStockEntries || isPendingInvLists}
                selectionModel={selectionModel}
                setSelectionModel={setSelectionModel}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
            />
        </>
    );
}

export default InventoryDocumentsList;
