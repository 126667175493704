import { Box, Button, Grid, IconButton, OutlinedInput, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import React, { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';
import SelectForm from 'ui-component/form/SelectForm';
import { useDispatch } from 'react-redux';
import { SetNotification } from 'store/services/api';
import ReactJSBarcode from 'react-jsbarcode';
import useFetch from 'hooks/useFetch';
import { t } from 'i18next';

function BarcodesForm({ setBarcodesState, barcodesState, doc, disabled, automatic }) {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [showFirstBarcodeType, setShowFirstBarcodeType] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setRow2Insert({
            doctype: 'Item Barcode',
            barcode_type: '',
            barcode_type_name: '',
            parent: 'Item',
            parentfield: 'barcodes',
            parenttype: 'Item',
            barcode: ''
        });
    };

    const [row2Insert, setRow2Insert] = useState({
        doctype: 'Item Barcode',
        barcode_type: '',
        barcode_type_name: '',
        parent: 'Item',
        parentfield: 'barcodes',
        parenttype: 'Item',
        barcode: ''
    });

    const barcodesettingsReqData = {
        doctype: 'Barcode Settings',
        fields: [
            '`tabBarcode Settings`.`name`',
            '`tabBarcode Settings`.`barcode_type`',
            '`tabBarcode Settings`.`suffix`',
            '`tabBarcode Settings`.`automatic`',
            '`tabBarcode Settings`.`prefix`'
        ]
    };
    const { data: barcodesettings } = useFetch(`/api/get-list`, barcodesettingsReqData, 'POST');

    let globalAutomatic;
    let globalBarcodeType;
    let globalSuffix;
    let globalPrefix;

    if (barcodesettings) {
        barcodesettings.forEach((setting) => {
            const barcode_type = setting.barcode_type;
            const suffix = setting.suffix;
            const automatic = setting.automatic;
            const prefix = setting.prefix;
            globalAutomatic = automatic;
            globalBarcodeType = barcode_type;
            globalSuffix = suffix;
            globalPrefix = prefix;
        });
    }

    const barcodesReqData = {
        doctype: 'Item Barcode',
        fields: [
            '`tabItem Barcode`.`name`',
            '`tabItem Barcode`.`barcode_type_name`',
            '`tabItem Barcode`.`parent`',
            '`tabItem Barcode`.`barcode`'
        ],
        order_by: '`tabItem Barcode`.`creation` DESC'
    };
    const { data: barcodes } = useFetch(`/api/get-list`, barcodesReqData, 'POST');
    const EAN8Barcodes = barcodes.filter((barcode) => barcode.barcode_type_name === 'EAN-8');
    const EAN12Barcodes = barcodes.filter((barcode) => barcode.barcode_type_name === 'EAN-12');
    const EAN13Barcodes = barcodes.filter((barcode) => barcode.barcode_type_name === 'EAN-13');
    const UPCABarcodes = barcodes.filter((barcode) => barcode.barcode_type_name === 'UPC-A');

    const handleChangeValues = (e) => {
        const { name, value } = e.target;

        if (globalAutomatic && name === 'barcode' && !isValidBarcodeType(row2Insert.barcode_type, value)) {
            dispatch(
                SetNotification({
                    // message: `"${value}" ${t('common:isNotAvalidInputFor')} ${row2Insert.barcode_type}`,
                    message: t('common:invalideBarcode'),
                    code: 'error'
                })
            );
            return;
        }

        if (name === 'barcode_type') {
            const barcodeTypeName = value === '' ? '' : value;
            setRow2Insert({ ...row2Insert, [name]: value, barcode_type_name: barcodeTypeName });
        } else {
            setRow2Insert({ ...row2Insert, [name]: value });
        }

        if (
            globalAutomatic &&
            name === 'barcode_type' &&
            value !== 'Custom Barcode' &&
            ['EAN-8', 'EAN-12', 'EAN-13', 'UPC-A', 'CODE-39', 'ITF'].includes(value)
        ) {
            try {
                let generatedBarcode = generateBarcode(value, row2Insert.barcode);
                setRow2Insert((prevRow) => ({ ...prevRow, barcode: generatedBarcode }));
            } catch (error) {
                console.error(error);
                dispatch(
                    SetNotification({
                        message: error.message,
                        code: 'error'
                    })
                );
            }
        }
    };

    const handleInsert = () => {
        let isValidBarcode = false;

        switch (row2Insert.barcode_type) {
            case '':
                isValidBarcode = true;
                break;
            case 'EAN-8':
                isValidBarcode = isValidEAN8(row2Insert.barcode);
                break;
            case 'UPC-A':
                isValidBarcode = isValidUPCA(row2Insert.barcode);
                break;
            case 'CODE-39':
                isValidBarcode = isValidCode39(row2Insert.barcode);
                break;
            case 'EAN-13':
                isValidBarcode = isValidEAN13(row2Insert.barcode);
                break;
            case 'EAN-12':
                isValidBarcode = isValidEAN12(row2Insert.barcode);
                break;
            case 'ITF':
                isValidBarcode = isValidITF(row2Insert.barcode);
                break;
            default:
                isValidBarcode = true;
        }

        if (isValidBarcode) {
            setBarcodesState([...barcodesState, { ...row2Insert, barcode_type_name: row2Insert.barcode_type_name }]);
            handleClose();
            setShowFirstBarcodeType(true);
        } else {
            dispatch(
                SetNotification({
                    // message: `${t('common:thisIsNotAvalid')} ${row2Insert.barcode_type_name} ${t('common:barcode')}`,
                    message: t('common:invalideBarcode'),
                    code: 'error'
                })
            );
        }
    };

    const isValidBarcodeType = (barcodeType, barcodeValue) => {
        switch (barcodeType) {
            case 'EAN-8':
                return isValidEAN8(barcodeValue);
            case 'UPC-A':
                return isValidUPCA(barcodeValue);
            case 'CODE-39':
                return isValidCode39(barcodeValue);
            case 'EAN-13':
                return isValidEAN13(barcodeValue);
            case 'EAN-12':
                return isValidEAN12(barcodeValue);
            case 'ITF':
                return isValidITF(barcodeValue);
            default:
                return true;
        }
    };
    const getBarcodeFormat = (barcodeType) => {
        switch (barcodeType) {
            case 'EAN-8':
                return { format: 'CODE128' };
            case 'UPC-A':
                return { format: 'CODE128' };
            case 'CODE-39':
                return { format: 'CODE128' };
            case 'EAN-13':
                return { format: 'CODE128' };
            case 'EAN-12':
                return { format: 'CODE128' };
            case 'ITF':
                return { format: 'CODE128' };
            default:
                return { format: 'CODE128' };
        }
    };

    const handleDelete = (el) => {
        const updatedBarcodes = barcodesState.filter((row) => row.barcode !== el.barcode);
        setBarcodesState(updatedBarcodes);

        setShowFirstBarcodeType(false);
    };

    let generatedUPCABarcodes = new Set();

    function generateUPCABarcode() {
        const prefixLength = globalPrefix.length;
        const barcodeLength = 11 - prefixLength;
        let barcode = '0'.repeat(barcodeLength);

        let barcodeWithPrefix = globalPrefix + barcode;
        let checksum = calculateUPCAChecksum(barcodeWithPrefix);
        let validUPCABarcode = barcodeWithPrefix + checksum;

        // Extracted the validation logic into a function to avoid the no-loop-func warning
        function isBarcodeAlreadyExists(barcode) {
            return UPCABarcodes.some((barcodeData) => barcodeData.barcode === barcode) || generatedUPCABarcodes.has(barcode);
        }

        while (isBarcodeAlreadyExists(validUPCABarcode)) {
            let barcodeInt = parseInt(barcode, 10) + 1;
            barcode = barcodeInt.toString().padStart(barcodeLength, '0');
            barcodeWithPrefix = globalPrefix + barcode;
            checksum = calculateUPCAChecksum(barcodeWithPrefix);
            validUPCABarcode = barcodeWithPrefix + checksum;
        }

        generatedUPCABarcodes.add(validUPCABarcode);

        return validUPCABarcode;
    }

    function calculateUPCAChecksum(upcCode) {
        let checksum = 0;
        for (let i = 0; i < 11; i++) {
            checksum += (i % 2 === 0 ? 3 : 1) * Number(upcCode[i]);
        }
        checksum = 10 - (checksum % 10);
        if (checksum === 10) {
            checksum = 0;
        }
        return checksum;
    }

    function isValidUPCA(upcCode) {
        if (upcCode.length !== 12) {
            return false;
        }
        const check = Number(upcCode[11]);
        let checksum = 0;
        for (let i = 0; i < 11; i++) {
            checksum += (i % 2 === 0 ? 3 : 1) * Number(upcCode[i]);
        }
        checksum = 10 - (checksum % 10);
        if (checksum === 10) {
            checksum = 0;
        }
        return check === checksum;
    }

    function generateCode39Barcode() {
        const validCharacters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ-. $/+';
        let barcode = globalPrefix; // Start with the prefix

        // Calculate the remaining length for the barcode
        const barcodeLength = 9 - globalPrefix.length;

        // Generate random characters for the barcode
        for (let i = 0; i < barcodeLength; i++) {
            const randomIndex = Math.floor(Math.random() * validCharacters.length);
            barcode += validCharacters[randomIndex];
        }

        // Calculate checksum and append if the length is correct
        if (barcode.length === 9) {
            const checksum = calculateCode39Checksum(barcode);
            barcode += checksum;
        }

        return barcode;
    }

    function calculateCode39Checksum(barcode) {
        const code39Values = {
            0: 0,
            1: 1,
            2: 2,
            3: 3,
            4: 4,
            5: 5,
            6: 6,
            7: 7,
            8: 8,
            9: 9,
            A: 10,
            B: 11,
            C: 12,
            D: 13,
            E: 14,
            F: 15,
            G: 16,
            H: 17,
            I: 18,
            J: 19,
            K: 20,
            L: 21,
            M: 22,
            N: 23,
            O: 24,
            P: 25,
            Q: 26,
            R: 27,
            S: 28,
            T: 29,
            U: 30,
            V: 31,
            W: 32,
            X: 33,
            Y: 34,
            Z: 35,
            '-': 36,
            '.': 37,
            ' ': 38,
            $: 39,
            '/': 40,
            '+': 41
        };

        let sum = 0;
        for (let i = 0; i < barcode.length; i++) {
            sum += code39Values[barcode[i]];
        }

        const checksumIndex = sum % 43;

        const checksumChar = Object.keys(code39Values).find((key) => code39Values[key] === checksumIndex);

        return checksumChar || '*';
    }

    function isValidCode39(code39) {
        if (code39.length !== 10) {
            return false;
        }

        const validCharacters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ-. $/+';

        for (let i = 0; i < code39.length; i++) {
            if (!validCharacters.includes(code39[i])) {
                return false;
            }
        }

        return true;
    }

    function generateBarcode(type, value) {
        if (type === 'UPC-A') {
            return generateUPCABarcode();
        } else if (type === 'EAN-8') {
            return generateEAN8Barcode();
        } else if (type === 'CODE-39') {
            return generateCode39Barcode();
        } else if (type === 'EAN-13') {
            return generateEAN13Barcode();
        } else if (type === 'EAN-12') {
            return generateEAN12Barcode();
        } else if (type === 'ITF') {
            return generateITFBarcode();
        } else {
            console.error('Unsupported barcode type');
            return '';
        }
    }
    let generatedBarcodes = new Set();
    function generateEAN8Barcode() {
        const prefixLength = globalPrefix.length;
        const barcodeLength = 7 - prefixLength;
        let barcode = '0'.repeat(barcodeLength);

        let barcodeWithPrefix = globalPrefix + barcode;
        let checksum = calculateEAN8Checksum(barcodeWithPrefix);
        let validEAN8Barcode = barcodeWithPrefix + checksum;

        while (EAN8Barcodes.some((barcodeData) => barcodeData.barcode === validEAN8Barcode) || generatedBarcodes.has(validEAN8Barcode)) {
            let barcodeInt = parseInt(barcode, 10) + 1;
            barcode = barcodeInt.toString().padStart(barcodeLength, '0');
            barcodeWithPrefix = globalPrefix + barcode;
            checksum = calculateEAN8Checksum(barcodeWithPrefix);
            validEAN8Barcode = barcodeWithPrefix + checksum;
        }
        generatedBarcodes.add(validEAN8Barcode);

        return validEAN8Barcode;
    }

    function calculateEAN8Checksum(ean8Code) {
        let checksum = 0;
        for (let i = 0; i < ean8Code.length; i++) {
            let digit = Number(ean8Code[i]);
            checksum += i % 2 === 0 ? digit * 3 : digit;
        }
        checksum = (10 - (checksum % 10)) % 10;
        return checksum;
    }

    function isValidEAN8(eanCode) {
        if (eanCode.length !== 8) {
            return false;
        }

        const check = Number(eanCode[eanCode.length - 1]);
        const codeWithoutCheckDigit = eanCode.slice(0, eanCode.length - 1);

        let checksum = 0;
        for (let i = 0; i < codeWithoutCheckDigit.length; i++) {
            let digit = Number(codeWithoutCheckDigit[i]);
            checksum += i % 2 === 0 ? digit * 3 : digit;
        }
        checksum = (10 - (checksum % 10)) % 10;

        return check === checksum;
    }

    function isValidEAN13(eanCode) {
        if (eanCode.length !== 13) {
            return false;
        }

        const check = Number(eanCode[12]);
        let checksum = 0;

        for (let i = 0; i < 12; i++) {
            const digit = Number(eanCode[i]);
            checksum += i % 2 === 0 ? digit : digit * 3;
        }

        checksum = (10 - (checksum % 10)) % 10;

        return check === checksum;
    }

    function calculateEAN13Checksum(ean13Code) {
        let checksum = 0;
        for (let i = 0; i < 12; i++) {
            checksum += (i % 2 === 0 ? 1 : 3) * Number(ean13Code[i]);
        }
        checksum = (10 - (checksum % 10)) % 10;
        return checksum;
    }

    let generatedEAN13Barcodes = new Set();
    function generateEAN13Barcode() {
        const prefixLength = globalPrefix.length;
        const barcodeLength = 12 - prefixLength;
        let barcode = '0'.repeat(barcodeLength);

        let barcodeWithPrefix = globalPrefix + barcode;
        let checksum = calculateEAN13Checksum(barcodeWithPrefix);
        let validEAN13Barcode = barcodeWithPrefix + checksum;

        while (
            EAN13Barcodes.some((barcodeData) => barcodeData.barcode === validEAN13Barcode) ||
            generatedEAN13Barcodes.has(validEAN13Barcode)
        ) {
            let barcodeInt = parseInt(barcode, 10) + 1;
            barcode = barcodeInt.toString().padStart(barcodeLength, '0');
            barcodeWithPrefix = globalPrefix + barcode;
            checksum = calculateEAN13Checksum(barcodeWithPrefix);
            validEAN13Barcode = barcodeWithPrefix + checksum;
        }

        generatedEAN13Barcodes.add(validEAN13Barcode);

        return validEAN13Barcode;
    }

    function isValidEAN12(eanCode) {
        if (eanCode.length !== 12) {
            return false;
        }

        let sum = 0;
        for (let i = 0; i < 11; i++) {
            const digit = parseInt(eanCode[i], 10);
            sum += i % 2 === 0 ? digit : digit * 3;
        }

        const checkDigit = parseInt(eanCode[11], 10);
        const expectedCheckDigit = (10 - (sum % 10)) % 10;

        return checkDigit === expectedCheckDigit;
    }

    function calculateEAN12Checksum(ean12Code) {
        let sum = 0;
        for (let i = 0; i < ean12Code.length; i++) {
            const digit = Number(ean12Code[i]);
            sum += i % 2 === 0 ? digit : digit * 3;
        }
        const checkDigit = (10 - (sum % 10)) % 10;
        return checkDigit;
    }

    let generatedEAN12Barcodes = new Set();

    function generateEAN12Barcode() {
        const prefixLength = globalPrefix.length;
        const barcodeLength = 11 - prefixLength;
        let barcode = '0'.repeat(barcodeLength);

        let barcodeWithPrefix = globalPrefix + barcode;
        let checksum = calculateEAN12Checksum(barcodeWithPrefix);
        let validEAN12Barcode = barcodeWithPrefix + checksum;

        while (
            EAN12Barcodes.some((barcodeData) => barcodeData.barcode === validEAN12Barcode) ||
            generatedEAN12Barcodes.has(validEAN12Barcode)
        ) {
            let barcodeInt = parseInt(barcode, 10) + 1;
            barcode = barcodeInt.toString().padStart(barcodeLength, '0');
            barcodeWithPrefix = globalPrefix + barcode;
            checksum = calculateEAN12Checksum(barcodeWithPrefix);
            validEAN12Barcode = barcodeWithPrefix + checksum;
        }

        generatedEAN12Barcodes.add(validEAN12Barcode);

        return validEAN12Barcode;
    }

    function calculateITFChecksum(itfCode) {
        let checksum = 0;
        for (let i = 0; i < itfCode.length; i++) {
            const digit = parseInt(itfCode[i], 10);
            checksum += i % 2 === 0 ? digit * 1 : digit * 3;
        }
        checksum = (10 - (checksum % 10)) % 10;
        return checksum;
    }

    let generatedITFBarcodes = new Set();

    function barcodeExists(validITFBarcode) {
        return barcodes.some((barcode) => barcode.barcode === validITFBarcode);
    }

    function generateITFBarcode() {
        const prefixLength = globalPrefix.length;
        const barcodeLength = 13 - prefixLength;
        let barcode = '0'.repeat(barcodeLength);

        let barcodeWithPrefix = globalPrefix + barcode;
        let checksum = calculateITFChecksum(barcodeWithPrefix);
        let validITFBarcode = barcodeWithPrefix + checksum;

        while (barcodeExists(validITFBarcode) || generatedITFBarcodes.has(validITFBarcode)) {
            let barcodeInt = parseInt(barcode, 10) + 1;
            barcode = barcodeInt.toString().padStart(barcodeLength, '0');
            barcodeWithPrefix = globalPrefix + barcode;
            checksum = calculateITFChecksum(barcodeWithPrefix);
            validITFBarcode = barcodeWithPrefix + checksum;
        }
        return validITFBarcode;
    }

    function isValidITF(itfCode) {
        if (!/^\d+$/.test(itfCode) || itfCode.length !== 14) {
            return false;
        }
        return true;
    }

    const barcodeTypeOptions = automatic ? [globalBarcodeType] : ['EAN-8', 'EAN-12', 'EAN-13', 'UPC-A', 'CODE-39', 'ITF'];

    return (
        <div style={{ padding: 20 }}>
            <Box>
                <Grid container justifyContent={'space-between'} display={'flex'} alignItems={'center'}>
                    <Typography fontWeight={600} variant="h4">
                        {t('columns:Barcodes')}
                    </Typography>
                    <Button color="blue" onClick={handleOpen} disabled={disabled}>
                        <AddIcon sx={{ marginLeft: -1 }} /> {t('core:add')}
                    </Button>
                </Grid>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <strong>{t('columns:Barcode')}</strong>
                            </TableCell>
                            <TableCell>
                                <strong style={{ visibility: showFirstBarcodeType ? 'visible' : 'hidden' }}>
                                    {t('columns:Barcode_type')}
                                </strong>
                            </TableCell>
                            <TableCell>
                                <strong style={{ visibility: showFirstBarcodeType ? 'hidden' : 'visible' }}>
                                    {t('columns:Barcode_type')}
                                </strong>
                            </TableCell>
                            <TableCell align="right" width={50}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {barcodesState?.map((el, i) => (
                            <TableRow key={i}>
                                <TableCell>
                                    <strong>{el.barcode}</strong>
                                </TableCell>
                                <TableCell>{el.barcode_type_name}</TableCell>
                                <TableCell align="right" width={50}>
                                    <IconButton disabled={disabled} onClick={() => handleDelete(el)}>
                                        <DeleteIcon color="error" />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                        {(open || barcodesState?.length === 0) && !disabled && (
                            <TableRow key={999}>
                                <TableCell colSpan={2}>
                                    {' '}
                                    {/* Make the cell span across two columns */}
                                    <OutlinedInput
                                        value={row2Insert.barcode}
                                        onChange={handleChangeValues}
                                        name="barcode"
                                        placeholder={'Barcode'}
                                        disabled={row2Insert.barcode_type === ''}
                                    />
                                </TableCell>
                                <TableCell style={{ width: 200 }}>
                                    {' '}
                                    <SelectForm
                                        fullwidth
                                        handleChange={handleChangeValues}
                                        value={row2Insert.barcode_type}
                                        name="barcode_type"
                                        data={barcodeTypeOptions}
                                    />
                                </TableCell>
                                <TableCell>
                                    <ReactJSBarcode
                                        value={row2Insert.barcode || ' '}
                                        options={{
                                            ...getBarcodeFormat(row2Insert.barcode_type),
                                            lineColor: '#364152'
                                        }}
                                        renderer="svg"
                                        debug={true}
                                    />
                                </TableCell>
                                <TableCell align="right" width={100}>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                        <IconButton onClick={handleInsert} disabled={!row2Insert.barcode}>
                                            <DoneIcon color={!row2Insert.barcode ? 'disabled' : ''} />
                                        </IconButton>
                                        <IconButton onClick={handleClose}>
                                            <DeleteIcon color="error" />
                                        </IconButton>
                                    </div>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Box>
        </div>
    );
}

export default BarcodesForm;
