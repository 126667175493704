import React from 'react';
import moment from 'moment';
import process from 'prop-types/prop-types';

export const isExistInDoc = (value, arr, key) => {
    const index = arr.findIndex((el) => el[key] === value);
    if (index === -1) return false;
    return true;
};

export const formatErpResponse = (str) => {
    return str?.replace(/<\/?[^>]+(>|$)/g, ' ');
};

export const formatServerMessages = (str) => {
    var result = [];
    JSON.parse(formatErpResponse(str))?.forEach((el) => {
        result.push(JSON.parse(el));
    });
    return result;
};

export const header = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
    Authorization: JSON.parse(localStorage.getItem('user'))?.token
};

export function CurrencyFormatter(props) {
    const formatter = new Intl.NumberFormat(props.locale, {
        style: 'currency',
        currency: props?.currency,
        minimumFractionDigits: props?.currency === 'AED' ? 2 : props?.minimumFractionDigits
    });

    const formattedValue = formatter.format(props?.value);

    return <span>{formattedValue}</span>;
}

export const isSameDate = (doc) => {
    const currentDate = moment().format('YYYY-MM-DD');
    const validTillDate = moment(doc.valid_till).format('YYYY-MM-DD');

    return !(validTillDate >= currentDate);
};
