import { Button, Grid, IconButton } from '@mui/material';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { Box } from '@mui/system';
import useFetch from 'hooks/useFetch';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import DataRender from 'ui-component/tables/DataRender';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useTheme } from '@mui/material/styles';
import { SetNotification } from 'store/services/api';
import { useDispatch } from 'react-redux';
import { formatErpResponse } from 'utils/utils';
import { useTranslation } from 'react-i18next';
import DetailModal from '../component/DetailModal';

const CustomerGroupList = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [selectionModel, setSelectionModel] = useState([]);
    const [loadingButton, setLoadingButton] = useState(false);
    const [detailModalOpen, setDetailModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    const customerGroupsReqData = {
        doctype: 'Customer Group',
        fields: [
            '`tabCustomer Group`.`name`',
            '`tabCustomer Group`.`customer_group_code`',
            '`tabCustomer Group`.`customer_group_name`',
            '`tabCustomer Group`.`is_group`',
            '`tabCustomer Group`.`parent_customer_group`',
            '`tabCustomer Group`.`creation`'
        ],
        start: 0,
        page_length: 500
    };

    const { data, isPending, refetch } = useFetch(`/api/get-list`, customerGroupsReqData, 'POST');

    const handleEdit = (row) => {
        navigate(`/crm/category-view/${row}`);
    };

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const handleDelete = (row) => {
        setLoadingButton(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/delete-doc`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doctype: 'Customer Group', name: row[0] })
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    dispatch(SetNotification({ code: 'success', message: t('common:grpeClientDeleteSuccess') }));
                    refetch({});
                }
            });
    };

    const title = (
        <Box p={-1} display={'flex'} justifyContent={'space-between'}>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    {t('crm:cstmergrouplist')}
                    <IconButton
                        onClick={() => navigate('/crm/customer-category-tree-view')}
                        aria-haspopup="true"
                        color="inherit"
                        aria-label="cart"
                        variant="rounded"
                        size="small"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: 'transparent !important',
                            color: theme.palette.primary.dark,
                            borderRadius: 50,
                            height: 20,
                            ml: 2,
                            mt: -0.5
                        }}
                    >
                        <AccountTreeIcon
                            sx={{
                                height: 20
                            }}
                            color="blue"
                        />
                    </IconButton>
                </Grid>
                <Grid item xs={6} container justifyContent="flex-end">
                    <Button
                        onClick={() => navigate('/crm/customer-new-category')}
                        startIcon={<AddCircleIcon />}
                        variant="outlined"
                        color="blue"
                    >
                        {t('crm:addcstmergroup')}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );

    const handleOpenDetailModal = (row) => {
        setSelectedRow(row);
        setDetailModalOpen(true);
    };

    const handleCloseDetailModal = () => {
        setDetailModalOpen(false);
        setSelectedRow(null);
    };

    return (
        <div>
            <DataRender
                refetch={refetch}
                title={title}
                data={data}
                loading={isPending}
                selectionModel={selectionModel}
                setSelectionModel={setSelectionModel}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                tag={t('columns:reference')}
                notshow={['name']}
                handleOpenDetailModal={handleOpenDetailModal}
                Visualize={true}
            />
            <DetailModal open={detailModalOpen} onClose={handleCloseDetailModal} row={selectedRow} />
        </div>
    );
};

export default CustomerGroupList;
