import {
    Box,
    Divider,
    Grid,
    Typography,
    Chip,
    FormControlLabel,
    Checkbox,
    IconButton,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Modal,
    OutlinedInput,
    TextField,
    Stack
} from '@mui/material';
import useGetAxios from 'hooks/useGetAxios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { useStyles } from 'views/utilities/Style';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MainCard from 'ui-component/cards/MainCard';
import InfoIcon from '@mui/icons-material/Info';
import Loader from 'ui-component/Loader';
import { LoadingButton } from '@mui/lab';
import InputTextField from 'ui-component/form/InputTextField';
import SelectForm from 'ui-component/form/SelectForm';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { frLocal } from 'utils/dataGridFRLocal';
import useFetch from 'hooks/useFetch';
import FormDateTime from 'ui-component/dateTime/FormDateTime';
import moment from 'moment';
import { SetNotification } from 'store/services/api';
import { formatErpResponse } from 'utils/utils';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import ApprovalIcon from '@mui/icons-material/Approval';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import EditNoteIcon from '@mui/icons-material/EditNote';
import PerfectScrollbar from 'react-perfect-scrollbar';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}
const PromotionalSchemeForm = () => {
    const classes = useStyles();
    const { id } = useParams();
    const { t } = useTranslation();

    const globalDefaults = useSelector((state) => state.login.globalDefaults);
    //const [warehouses, setWarehouses] = React.useState([]);
    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [value, setValue] = React.useState(0);
    const [loadingButton, setLoadingButton] = useState(false);

    const handleChangeValue = (event, newValue) => {
        setValue(newValue);
    };

    const [selectionItemsModel, setSelectionItemsModel] = useState([]);
    const [selectionItemsModell, setSelectionItemsModell] = useState([]);
    const [selectionItemsAppForModel, setSelectionItemsAppForModel] = useState([]);

    const { data: doc, isPending } = useGetAxios(`${process.env.REACT_APP_API_URI}/api/details?doctype=Promotional%20Scheme&name=${id}`);

    const { data: companies, isPending: isCompaniesPending } = useFetch(
        `/api/get-list`,
        { doctype: 'Company', fields: ['`tabCompany`.`name`'] },
        'POST'
    );

    const { data: warehouses, isPending: isWarehousesPending } = useFetch(
        `/api/get-list`,
        { doctype: 'Warehouse', fields: ['`tabWarehouse`.`name`'], filters: [['Warehouse', 'disabled', '=', 0]] },
        'POST'
    );

    const itemUOMReqData = {
        doctype: 'UOM',
        fields: ['`tabUOM`.`name`'],
        start: 0,
        page_length: 100
    };
    const { data: uoms, isUomsPending } = useFetch(`/api/get-list`, itemUOMReqData, 'POST');

    const { data: currencies, isPending: isCurrenciesPending } = useFetch(
        `/api/get-list`,
        { doctype: 'Currency', fields: ['`tabCurrency`.`name`'], filters: [['Currency', 'enabled', '=', 1]] },
        'POST'
    );
    //fetch items
    const {
        data: itemsList,
        isPending: isItems,
        refetch
    } = useFetch(`/api/get-list`, { doctype: 'Item', fields: ['`tabItem`.`item_code`', '`tabItem`.`name`'] }, 'POST');

    const [formState, setFormState] = useState({
        title: '',
        apply_on: '',
        disable: 0,
        items: '',
        item_group: '',
        Brands: '',
        is_cumulative: 0,
        mixed_conditions: 0,
        apply_rule_on_other: '',
        valid_from: moment().format('YYYY-MM-DD'),
        valid_upto: moment().format('YYYY-MM-DD'),
        other_item_group: '',
        other_item_code: '',
        other_brand: '',
        selling: 0,
        buying: 0,
        applicable_for: '',
        sales_partner: '',
        customer: '',
        customer_group: '',
        territoy: '',
        campaign: '',
        supplier: '',
        supplier_group: '',
        company: globalDefaults?.default_company,
        currency: globalDefaults?.default_currency
    });

    const [formErrState, setFormErrState] = useState({
        title: false,
        company: false,
        apply_on: false,
        currency: false,
        applicable_for: false,
        customer: false,
        customer_group: false,
        territory: false,
        sales_partner: false,
        campaign: false,
        supplier: false,
        supplier_group: false,
        buying: true,
        selling: false
    });
    const [error, setError] = useState('');
    const nextDate = moment(formState?.valid_from).add(1, 'days').format('YYYY-MM-DD');
    const columns = [{ field: 'name', headerName: 'Nom', flex: 1 }];
    const itemCodeColumns = [{ field: 'item_code', headerName: 'Code De Produit', flex: 1 }];
    const itemColumns = [{ field: 'name', headerName: 'Nom', flex: 1 }];
    const [applyOnError, setApplyOnError] = useState('');
    const [applicableForError, setApplicableForError] = useState('');

    const handleChange = (e) => {
        setFormState({ ...formState, [e.target.name]: e.target.value });

        // err handlers
        if (e.target.value == '') {
            setFormErrState({ ...formErrState, [e.target.name]: true });
        } else {
            setFormErrState({ ...formErrState, [e.target.name]: false });
        }
    };

    const handleReset = (fieldToReset) => {
        const updatedFormState = { ...formState };
        updatedFormState[fieldToReset] = '';
        setFormState(updatedFormState);
    };

    const [applyOnItems, setApplyOnItems] = useState([]);
    const [applyForItems, setApplyForItems] = useState([]);
    //const [applyOnItemsSupplier, setApplyOnItemsSupplier] = useState([]);
    const [isItemsPending, setIsItemsPending] = useState(false);
    const [isItemssPending, setIsItemssPending] = useState(false);
    //const [isItemsSupplierPending, setIsItemsSupplierPending] = useState(false);
    const [appForItems, setAppForItems] = useState([]);
    //const [appForSuppliers, setAppForSuppliers] = useState([]);
    const [isappForItemsPending, setIsappForItemsPending] = useState(false);

    const handleChangeApplyOn = (e) => {
        setFormState({ ...formState, [e.target.name]: e.target.value });
        setIsItemsPending(true);
        if (e.target.value === 'Item Code') {
            fetchItems();
            setError('');
        } else if (e.target.value === 'Item Group') {
            fetchItemGroups();
            setError('');
        } else if (e.target.value === 'Supplier') {
            fetchSuppliers();
            setError('');
        } else {
            fetchBrands();
            setError('');
        }
    };

    const handleChangeApplyOnOther = (e) => {
        setFormState({ ...formState, [e.target.name]: e.target.value });
        setIsappForItemsPending(true);

        setFormState((prevState) => ({
            ...prevState,
            apply_rule_on_other: e.target.value
        }));

        if (e.target.value === 'Item Code') {
            fetchItemsonother();
        } else if (e.target.value === 'Item Group') {
            fetchItemGroupsonother();
        } else {
            fetchBrandsonother();
        }
    };

    const handleChangeAppFor = (e) => {
        setFormState({ ...formState, [e.target.name]: e.target.value });
        setIsItemssPending(true);

        setFormState((prevState) => ({
            ...prevState,
            applicable_for: e.target.value
        }));

        if (e.target.value === 'Customer') {
            fetchCustomers();
        } else if (e.target.value === 'Customer Group') {
            fetchCustomerGroups();
        } else if (e.target.value === 'Sales Partner') {
            fetchSalesPartners();
        } else if (e.target.value === 'Campaign') {
            fetchCampaigns();
        } else if (e.target.value === 'Supplier') {
            fetchSuppliers();
        } else if (e.target.value === 'Supplier Group') {
            fetchSuppliersGroup();
        } else {
            fetchTerritories();
        }
    };

    const fetchItemsonother = () => {
        setIsappForItemsPending(true);
        let data = {
            doctype: 'Item',
            fields: ['`tabItem`.`name`'],
            start: 0,
            page_length: 500
        };
        fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(data)
        })
            .then((res) => {
                setIsappForItemsPending(false);
                return res.json();
            })
            .then((data) => {
                setAppForItems(data);
            });
    };
    const fetchItemGroupsonother = () => {
        setIsappForItemsPending(true);
        let data = {
            doctype: 'Item Group',
            fields: ['`tabItem Group`.`name`'],
            start: 0,
            page_length: 500
        };
        fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(data)
        })
            .then((res) => {
                setIsappForItemsPending(false);
                return res.json();
            })
            .then((data) => {
                setAppForItems(data);
            });
    };
    const fetchBrandsonother = () => {
        setIsappForItemsPending(true);
        let data = {
            doctype: 'Brand',
            fields: ['`tabBrand`.`name`'],
            start: 0,
            page_length: 500
        };
        fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(data)
        })
            .then((res) => {
                setIsappForItemsPending(false);
                return res.json();
            })
            .then((data) => {
                setAppForItems(data);
            });
    };

    const fetchItemGroups = async () => {
        setIsItemsPending(true);
        let data = {
            doctype: 'Item Group',
            fields: ['`tabItem Group`.`name`'],
            start: 0,
            page_length: 50
        };
        fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(data)
        })
            .then((res) => {
                setIsItemsPending(false);
                return res.json();
            })
            .then((data) => {
                setApplyOnItems(data);
            });
    };

    const fetchItems = () => {
        setIsItemsPending(true);
        let data = {
            doctype: 'Item',
            fields: ['`tabItem`.`item_code`'],
            filters: [['Item', 'not_discountable', '=', 0]],
            start: 0,
            page_length: 500
        };
        fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(data)
        })
            .then((res) => {
                setIsItemsPending(false);
                return res.json();
            })
            .then((data) => {
                setApplyOnItems(data);
            });
    };

    const fetchBrands = () => {
        setIsItemsPending(true);
        let data = {
            doctype: 'Brand',
            fields: ['`tabBrand`.`name`'],
            // filters: [['Brand', 'company', '=', 'Petit Bateau']],
            start: 0,
            page_length: 50
        };
        fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(data)
        })
            .then((res) => {
                setIsItemsPending(false);
                return res.json();
            })
            .then((data) => {
                setApplyOnItems(data);
            });
    };

    const fetchSuppliersGroup = () => {
        setIsItemssPending(true);
        let data = {
            doctype: 'Supplier Group',
            fields: ['`tabSupplier Group`.`name`'],
            start: 0,
            page_length: 500
        };
        fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(data)
        })
            .then((res) => {
                setIsItemssPending(false);
                return res.json();
            })
            .then((data) => {
                setApplyForItems(data);
            });
    };

    const fetchSalesPartners = () => {
        setIsItemssPending(true);
        let data = {
            doctype: 'Sales Partner',
            fields: ['`tabSales Partner`.`name`'],
            start: 0,
            page_length: 500
        };
        fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(data)
        })
            .then((res) => {
                setIsItemssPending(false);
                return res.json();
            })
            .then((data) => {
                setApplyForItems(data);
            });
    };

    const fetchCampaigns = () => {
        setIsItemssPending(true);
        let data = {
            doctype: 'Campaign',
            fields: ['`tabCampaign`.`name`'],
            start: 0,
            page_length: 500
        };
        fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(data)
        })
            .then((res) => {
                setIsItemssPending(false);
                return res.json();
            })
            .then((data) => {
                setApplyForItems(data);
            });
    };

    const fetchCustomers = () => {
        setIsItemssPending(true);
        let data = {
            doctype: 'Customer',
            fields: ['`tabCustomer`.`name`'],
            start: 0,
            page_length: 500
        };
        fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(data)
        })
            .then((res) => {
                setIsItemssPending(false);
                return res.json();
            })
            .then((data) => {
                setApplyForItems(data);
            });
    };

    const fetchCustomerGroups = () => {
        setIsItemssPending(true);
        let data = {
            doctype: 'Customer Group',
            fields: ['`tabCustomer Group`.`name`'],
            // filters: [['Item Group', 'company', '=', 'Petit Bateau']],
            start: 0,
            page_length: 50
        };
        fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(data)
        })
            .then((res) => {
                setIsItemssPending(false);
                return res.json();
            })
            .then((data) => {
                setApplyForItems(data);
            });
    };

    const fetchSuppliers = () => {
        setIsItemssPending(true);
        let data = {
            doctype: 'Supplier',
            fields: ['`tabSupplier`.`name`'],
            start: 0,
            page_length: 500
        };
        fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(data)
        })
            .then((res) => {
                setIsItemssPending(false);
                return res.json();
            })
            .then((data) => {
                setApplyForItems(data);
            });
    };

    const fetchTerritories = () => {
        setIsItemssPending(true);
        let data = {
            doctype: 'Territory',
            fields: ['`tabTerritory`.`name`'],
            filters: [['Territory', 'is_group', '=', 0]],
            start: 0,
            page_length: 50
        };
        fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(data)
        })
            .then((res) => {
                setIsItemssPending(false);
                return res.json();
            })
            .then((data) => {
                setApplyForItems(data);
            });
    };

    useEffect(() => {
        if (formState.apply_on != '') {
            setFormErrState({ ...formErrState, apply_on: false });
        }
        if (selectionItemsModel?.length != 0) {
            setError('');
        }
    }, [formState.apply_on, selectionItemsModel]);

    useEffect(() => {
        if (formState.applicable_for != '') {
            setFormErrState({ ...formErrState, applicable_for: false });
        }
        if (selectionItemsModell?.length != 0) {
            setError('');
        }
    }, [formState.applicable_for, selectionItemsModell]);

    const dataSelectionApplayOn = (
        <Box height={417} width={'100%'} m={2}>
            {applyOnError && <p style={{ color: 'red' }}>{applyOnError}</p>}
            <DataGrid
                rows={applyOnItems}
                columns={formState.apply_on === 'Item Code' ? itemCodeColumns : columns}
                checkboxSelection
                getRowId={(row) => (formState?.apply_on === 'Item Code' ? row?.item_code : row?.name)}
                localeText={frLocal}
                hideFooterPagination
                hideFooterSelectedRowCount
                rowSelectionModel={selectionItemsModel}
                onRowSelectionModelChange={(newSelectionModel) => {
                    setSelectionItemsModel(newSelectionModel);
                }}
                loading={isItemsPending}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                        showQuickExport: false,
                        quickFilterProps: { debounceMs: 500 }
                    }
                }}
                disableColumnSelector
                disableDensitySelector
                disableColumnFilter
                disableColumnMenu
            />
        </Box>
    );

    const dataSelectionApplicableFor = (
        <Box height={417} width={'100%'} m={2}>
            {applicableForError && <p style={{ color: 'red' }}>{applicableForError}</p>}
            <DataGrid
                rows={applyForItems}
                columns={itemColumns}
                checkboxSelection
                getRowId={(row) => row.name}
                localeText={frLocal}
                hideFooterPagination
                hideFooterSelectedRowCount
                rowSelectionModel={selectionItemsModell}
                onRowSelectionModelChange={(newSelectionModel) => {
                    setSelectionItemsModell(newSelectionModel);
                }}
                loading={isItemssPending}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                        showQuickExport: false,
                        quickFilterProps: { debounceMs: 500 }
                    }
                }}
                disableColumnSelector
                disableDensitySelector
                disableColumnFilter
                disableColumnMenu
            />
        </Box>
    );

    const [productDiscountSlabs, setProductDiscountSlabs] = useState([
        {
            docstatus: 0,
            doctype: 'Promotional Scheme Product Discount',
            __islocal: 1,
            __unsaved: 1,
            disable: 0,
            rule_description: '',
            apply_multiple_pricing_rules: 0,
            min_qty: 0,
            max_qty: 0,
            min_amount: 0,
            max_amount: 0,
            parentfield: 'product_discount_slabs',
            parenttype: 'Promotional Scheme',
            free_item: '',
            same_item: 0,
            free_qty: '',
            free_item_rate: '',
            free_item_uom: '',
            warehouse: '',
            priority: '',
            threshold_percentage: '',
            is_recursive: 0
        }
    ]);

    const handleProductDiscountSlabChange = (index, field, value) => {
        const updatedSlabs = [...productDiscountSlabs];
        updatedSlabs[index][field] = value;
        setProductDiscountSlabs(updatedSlabs);
    };

    const addNewSlab = () => {
        setProductDiscountSlabs([
            ...productDiscountSlabs,
            {
                docstatus: 0,
                doctype: 'Promotional Scheme Product Discount',
                __islocal: 1,
                __unsaved: 1,
                disable: 0,
                rule_description: '',
                apply_multiple_pricing_rules: 0,
                min_qty: 0,
                max_qty: 0,
                min_amount: 0,
                max_amount: 0,
                parentfield: 'product_discount_slabs',
                parenttype: 'Promotional Scheme',
                free_item: '',
                same_item: 0,
                free_qty: '',
                free_item_rate: '',
                free_item_uom: '',
                warehouse: '',
                priority: '',
                threshold_percentage: '',
                is_recursive: 0
            }
        ]);
    };
    const [priceDiscountSlabs, setPriceDiscountSlabs] = useState([
        {
            docstatus: 0,
            doctype: 'Promotional Scheme Price Discount',
            __islocal: 1,
            __unsaved: 1,
            disable: 0,
            min_qty: 0,
            max_qty: 0,
            min_amount: 0,
            max_amount: 0,
            parentfield: 'price_discount_slabs',
            parenttype: 'Promotional Scheme',
            rate_or_discount: '',
            rule_description: '',
            discount_percentage: '',
            discount_amount: '',
            rate: '',
            apply_multiple_pricing_rules: 0,
            validate_applied_rule: 0,
            apply_discount_on_rate: 0,
            threshold_percentage: 0,
            priority: '',
            warehouse: ''
        }
    ]);

    const handlePriceDiscountSlabChange = (index, field, value) => {
        const updatedSlabs = [...priceDiscountSlabs];
        updatedSlabs[index][field] = value;
        setPriceDiscountSlabs(updatedSlabs);
    };

    const addNewSlabPrice = () => {
        setPriceDiscountSlabs([
            ...priceDiscountSlabs,
            {
                docstatus: 0,
                doctype: 'Promotional Scheme Price Discount',
                __islocal: 1,
                __unsaved: 1,
                disable: 0,
                min_qty: 0,
                max_qty: 0,
                min_amount: 0,
                max_amount: 0,
                parentfield: 'price_discount_slabs',
                parenttype: 'Promotional Scheme',
                rate_or_discount: '',
                rule_description: '',
                discount_percentage: '',
                discount_amount: '',
                rate: '',
                apply_multiple_pricing_rules: 0,
                validate_applied_rule: 0,
                apply_discount_on_rate: 0,
                threshold_percentage: 0,
                priority: '',
                warehouse: ''
            }
        ]);
    };
    const handleSavePricingRule = () => {
        setLoadingButton(true);
        let data = {
            ...(!id && { __islocal: 1 }),
            ...(id && {
                creation: doc.creation,
                modified: doc.modified,
                owner: doc.owner,
                modified_by: doc.owner,
                name: doc.name
            }),
            docstatus: 0,
            doctype: 'Promotional Scheme',
            __unsaved: 1,
            disable: formState.disable ? 1 : 0,
            apply_on: formState.apply_on,
            mixed_conditions: formState.mixed_conditions ? 1 : 0,
            is_cumulative: formState.is_cumulative ? 1 : 0,
            applicable_for: formState.applicable_for,
            valid_from: formState.valid_from,
            company: formState.company,
            valid_upto: formState.valid_upto,
            customer: formState.customer,
            title: formState.title,
            customer_group: formState.customer_group,
            territoy: formState.territoy,
            buying: formState.buying ? 1 : 0,
            selling: formState.selling ? 1 : 0,
            items: formState.items,
            item_group: formState.item_group,
            Brands: formState.Brands,
            apply_rule_on_other: formState.apply_rule_on_other,
            other_item_group: formState.other_item_group,
            other_item_code: formState.other_item_code,
            other_brand: formState.other_brand,
            sales_partner: formState.sales_partner,
            campaign: formState.campaign,
            supplier: formState.supplier,
            supplier_group: formState.supplier_group,
            currency: formState.currency,
            product_discount_slabs: productDiscountSlabs,
            price_discount_slabs: priceDiscountSlabs
        };
        let applyOnData = [];
        if (formState.apply_on === 'Item Code') {
            selectionItemsModel.map((el, i) => {
                let dataToInsert = {
                    docstatus: 0,
                    doctype: 'Pricing Rule Item Code',
                    __islocal: 1,
                    __unsaved: 1,
                    parentfield: 'items',
                    parenttype: 'Promotional Scheme',
                    idx: i,
                    __unedited: false,
                    item_code: el
                };
                applyOnData.push(dataToInsert);
            });
        } else if (formState.apply_on === 'Item Group') {
            selectionItemsModel.map((el, i) => {
                let dataToInsert = {
                    docstatus: 0,
                    doctype: 'Pricing Rule Item Group',
                    __islocal: 1,
                    __unsaved: 1,
                    parentfield: 'item_groups',
                    parenttype: 'Promotional Scheme',
                    idx: i,
                    __unedited: false,
                    item_group: el
                };
                applyOnData.push(dataToInsert);
            });
        } else if (formState.apply_on === 'Brand') {
            selectionItemsModel.map((el, i) => {
                let dataToInsert = {
                    docstatus: 0,
                    doctype: 'Pricing Rule Brand',
                    __islocal: 1,
                    __unsaved: 1,
                    parentfield: 'brands',
                    parenttype: 'Promotional Scheme',
                    idx: i,
                    __unedited: false,
                    brand: el
                };
                applyOnData.push(dataToInsert);
            });
        }
        let applicableforData = [];
        if (formState.applicable_for === 'Customer') {
            selectionItemsModell.map((el, i) => {
                let dataToInsert = {
                    docstatus: 0,
                    doctype: 'Customer Item',
                    __islocal: 1,
                    __unsaved: 1,
                    parentfield: 'customer',
                    parenttype: 'Promotional Scheme',
                    idx: i,
                    __unedited: false,
                    customer: el
                };
                applicableforData.push(dataToInsert);
            });
        } else if (formState.applicable_for === 'Customer Group') {
            selectionItemsModell.map((el, i) => {
                let dataToInsert = {
                    docstatus: 0,
                    doctype: 'Customer Group Item',
                    __islocal: 1,
                    __unsaved: 1,
                    parentfield: 'customer_group',
                    parenttype: 'Promotional Scheme',
                    idx: i,
                    __unedited: false,
                    customer_group: el
                };
                applicableforData.push(dataToInsert);
            });
        } else if (formState.applicable_for === 'Territory') {
            selectionItemsModell.map((el, i) => {
                let dataToInsert = {
                    docstatus: 0,
                    doctype: 'Territory Item',
                    __islocal: 1,
                    __unsaved: 1,
                    parentfield: 'territory',
                    parenttype: 'Promotional Scheme',
                    idx: i,
                    __unedited: false,
                    territory: el
                };
                applicableforData.push(dataToInsert);
            });
        } else if (formState.applicable_for === 'Sales Partner') {
            selectionItemsModell.map((el, i) => {
                let dataToInsert = {
                    docstatus: 0,
                    doctype: 'Sales Partner Item',
                    __islocal: 1,
                    __unsaved: 1,
                    parentfield: 'sales_partner',
                    parenttype: 'Promotional Scheme',
                    idx: i,
                    __unedited: false,
                    sales_partner: el
                };
                applicableforData.push(dataToInsert);
            });
        } else if (formState.applicable_for === 'Campaign') {
            selectionItemsModell.map((el, i) => {
                let dataToInsert = {
                    docstatus: 0,
                    doctype: 'Campaign Item',
                    __islocal: 1,
                    __unsaved: 1,
                    parentfield: 'campaign',
                    parenttype: 'Promotional Scheme',
                    idx: i,
                    __unedited: false,
                    campaign: el
                };
                applicableforData.push(dataToInsert);
            });
        } else if (formState.applicable_for === 'Supplier') {
            selectionItemsModell.map((el, i) => {
                let dataToInsert = {
                    docstatus: 0,
                    doctype: 'Supplier Item',
                    __islocal: 1,
                    __unsaved: 1,
                    parentfield: 'supplier',
                    parenttype: 'Promotional Scheme',
                    idx: i,
                    __unedited: false,
                    supplier: el
                };
                applicableforData.push(dataToInsert);
            });
        } else if (formState.applicable_for === 'Supplier Group') {
            selectionItemsModell.map((el, i) => {
                let dataToInsert = {
                    docstatus: 0,
                    doctype: 'Supplier Group Item',
                    __islocal: 1,
                    __unsaved: 1,
                    parentfield: 'supplier_group',
                    parenttype: 'Promotional Scheme',
                    idx: i,
                    __unedited: false,
                    supplier_group: el
                };
                applicableforData.push(dataToInsert);
            });
        }

        data = {
            ...data,
            [formState.apply_on === 'Brand' ? 'brands' : formState.apply_on === 'Item Group' ? 'item_groups' : 'items']: applyOnData,
            [formState.applicable_for === 'Customer'
                ? 'customer'
                : formState.applicable_for === 'Customer Group'
                ? 'customer_group'
                : formState.applicable_for === 'Territory'
                ? 'territory'
                : formState.applicable_for === 'Sales Partner'
                ? 'sales_partner'
                : formState.applicable_for === 'Campaign'
                ? 'campaign'
                : formState.applicable_for === 'Supplier'
                ? 'supplier'
                : 'supplier_group']: applicableforData
        };

        fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doc: data, action: 'Save' })
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    dispatch(SetNotification({ code: 'success', message: t('common:addPromSchemeSuccess') }));
                    navigate('/pim/promotional_scheme');
                }
            });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const fieldsToValidate = ['title', 'company', 'apply_on', 'applicable_for'];
        let errorFields = [];
        let isValid = true;
        const nextError = { ...formErrState };

        fieldsToValidate.forEach((field) => {
            if (!formState[field] || formState[field].trim() === '') {
                nextError[field] = true;
                isValid = false;
                errorFields.push(field);
            } else {
                nextError[field] = false;
            }
        });

        if (selectionItemsModel?.length === 0) {
            isValid = false;
            setApplyOnError('Veuillez sélectionner au moins un élément pour appliquer sur.');
            errorFields.push('Appliquer sur');
        } else {
            setApplyOnError('');
        }

        if (selectionItemsModell?.length === 0) {
            isValid = false;
            setApplicableForError('Veuillez sélectionner au moins un élément pour applicable pour.');
            errorFields.push('Applicable pour');
        } else {
            setApplicableForError('');
        }

        const isProductSlabsValid = validateTabProduit(productDiscountSlabs);
        const isPriceSlabsValid = validateTabPrix(priceDiscountSlabs);

        if (!isProductSlabsValid && !isPriceSlabsValid) {
            isValid = false;
            errorFields.push('Dalles de remise sur produit ou sur prix');
        }

        setFormErrState(nextError);

        if (!isValid) {
            dispatch(
                SetNotification({
                    code: 'error',
                    message: `Veuillez remplir tous les champs obligatoires : ${errorFields.join(', ')}.`
                })
            );
        } else {
            handleSavePricingRule();
        }
    };

    useEffect(() => {
        if (id && doc?.name) {
            setFormState({
                ...formState,
                disable: doc?.disable == 1 ? true : false,
                apply_on: doc?.apply_on,
                mixed_conditions: doc?.mixed_conditions == 1 ? true : false,
                is_cumulative: doc?.is_cumulative == 1 ? true : false,
                applicable_for: doc?.applicable_for,
                valid_from: doc?.valid_from,
                company: doc?.company,
                currency: doc?.currency,
                valid_upto: doc?.valid_upto,
                customer: doc?.customer,
                customer_group: doc?.customer_group,
                territoy: doc?.territoy,
                buying: doc?.buying == 1 ? true : false,
                selling: doc?.selling == 1 ? true : false,
                items: doc?.items,
                title: doc?.title,
                item_group: doc?.item_group,
                Brands: doc?.Brands,
                apply_rule_on_other: doc?.apply_rule_on_other,
                other_item_group: doc?.other_item_group,
                other_item_code: doc?.other_item_code,
                other_brand: doc?.other_brand,
                sales_partner: doc?.sales_partner,
                campaign: doc?.campaign,
                supplier: doc?.supplier,
                supplier_group: doc?.supplier_group
            });
            if (doc?.product_discount_slabs) {
                setProductDiscountSlabs(doc.product_discount_slabs);
            }
            if (doc?.price_discount_slabs) {
                setPriceDiscountSlabs(doc.price_discount_slabs);
            }
            if (doc?.apply_on === 'Item Group') {
                fetchItemGroups();
                setTimeout(() => {
                    let forSelection = [];
                    doc?.item_groups?.map((el) => {
                        forSelection.push(el.item_group);
                    });
                    setSelectionItemsModel(forSelection);
                }, 1000);
            } else if (doc?.apply_on === 'Item Code') {
                fetchItems();
                setTimeout(() => {
                    let forSelection = [];
                    doc?.items?.map((el) => {
                        forSelection.push(el.item_code);
                    });
                    setSelectionItemsModel(forSelection);
                }, 1000);
            } else if (doc?.apply_on === 'Brand') {
                fetchBrands();
                setTimeout(() => {
                    let forSelection = [];
                    doc?.brands?.map((el) => {
                        forSelection.push(el.brand);
                    });
                    setSelectionItemsModel(forSelection);
                }, 1000);
            }
            if (doc?.applicable_for === 'Supplier Group') {
                fetchSuppliersGroup();
                setTimeout(() => {
                    let forSelection = [];
                    doc?.supplier_group?.map((el) => {
                        forSelection.push(el.supplier_group);
                    });
                    setSelectionItemsModell(forSelection);
                }, 1000);
            } else if (doc?.applicable_for === 'Supplier') {
                fetchSuppliers();
                setTimeout(() => {
                    let forSelection = [];
                    doc?.supplier?.map((el) => {
                        forSelection.push(el.supplier);
                    });
                    setSelectionItemsModell(forSelection);
                }, 1000);
            } else if (doc?.applicable_for === 'Sales Partner') {
                fetchSalesPartners();
                setTimeout(() => {
                    let forSelection = [];
                    doc?.sales_partner?.map((el) => {
                        forSelection.push(el.sales_partner);
                    });
                    setSelectionItemsModell(forSelection);
                }, 1000);
            } else if (doc?.applicable_for === 'Campaign') {
                fetchCampaigns();
                setTimeout(() => {
                    let forSelection = [];
                    doc?.campaign?.map((el) => {
                        forSelection.push(el.campaign);
                    });
                    setSelectionItemsModell(forSelection);
                }, 1000);
            } else if (doc?.applicable_for === 'Territory') {
                fetchTerritories();
                setTimeout(() => {
                    let forSelection = [];
                    doc?.territory?.map((el) => {
                        forSelection.push(el.territory);
                    });
                    setSelectionItemsModell(forSelection);
                }, 1000);
            } else if (doc?.applicable_for === 'Customer Group') {
                fetchCustomerGroups();
                setTimeout(() => {
                    let forSelection = [];
                    doc?.customer_group?.map((el) => {
                        forSelection.push(el.customer_group);
                    });
                    setSelectionItemsModell(forSelection);
                }, 1000);
            } else if (doc?.applicable_for === 'Customer') {
                fetchCustomers();
                setTimeout(() => {
                    let forSelection = [];
                    doc?.customer?.map((el) => {
                        forSelection.push(el.customer);
                    });
                    setSelectionItemsModell(forSelection);
                }, 1000);
                if (doc?.apply_rule_on_other === 'Item Code') {
                    fetchItemsonother();
                    setTimeout(() => {
                        setSelectionItemsAppForModel([doc?.other_item_code]);
                    }, 1000);
                } else if (doc?.apply_rule_on_other === 'Item Group') {
                    fetchItemGroupsonother();
                    setTimeout(() => {
                        setSelectionItemsAppForModel([doc?.other_item_group]);
                    }, 1000);
                } else if (doc?.apply_rule_on_other === 'Brand') {
                    fetchBrandsonother();
                    setTimeout(() => {
                        setSelectionItemsAppForModel([doc?.other_brand]);
                    }, 1000);
                }
            }
        }
    }, [doc]);

    const title = (
        <Box p={-1} display={'flex'} justifyContent={'space-between'}>
            <Box>
                <ArrowBackIcon className={classes.backLink} onClick={() => navigate(-1)} />
                {id ? `${t('products:promotionalscheme')}: ${doc?.title}` : t('products:newpromotionalscheme')}
            </Box>
        </Box>
    );

    const handleBackClick = () => {
        setValue(value - 1);
    };

    const handleNextClick = (event) => {
        event.preventDefault();
        let errorFields = [];
        let isValid = true;
        const nextError = { ...formErrState };

        if (value === 0) {
            const fieldsToValidate = ['title', 'company'];

            fieldsToValidate.forEach((field) => {
                if (!formState[field] || formState[field].trim() === '') {
                    nextError[field] = true;
                    isValid = false;
                    errorFields.push(field);
                } else {
                    nextError[field] = false;
                }
            });

            setFormErrState(nextError);
            if (!isValid) {
                dispatch(
                    SetNotification({ code: 'error', message: `Veuillez remplir tous les champs obligatoires: ${errorFields.join(', ')}.` })
                );
            } else {
                setValue(value + 1);
            }
        }

        if (value === 1) {
            if (!formState['apply_on'] || formState['apply_on'].trim() === '') {
                nextError['apply_on'] = true;
                isValid = false;
                errorFields.push('Apply On');
            } else {
                nextError['apply_on'] = false;
            }
            setFormErrState(nextError);
            if (selectionItemsModel?.length === 0) {
                isValid = false;
                setApplyOnError('Veuillez sélectionner au moins un élément pour appliquer sur.');
            } else {
                setApplyOnError('');
            }
            if (!isValid) {
                dispatch(
                    SetNotification({
                        code: 'error',
                        message: `Veuillez remplir tous les champs obligatoires.`
                    })
                );
            } else {
                setValue(value + 1);
            }
        }

        if (value === 2) {
            const isProductSlabsValid = validateTabProduit(productDiscountSlabs);
            const isPriceSlabsValid = validateTabPrix(priceDiscountSlabs);

            if (!isProductSlabsValid && !isPriceSlabsValid) {
                dispatch(
                    SetNotification({
                        code: 'error',
                        message: t('products:the_slab_required')
                    })
                );
            } else {
                setValue(value + 1);
            }
        }
    };

    const validateTabProduit = (slabs) => {
        if (slabs.length === 0) return false;

        const requiredFields = ['min_qty', 'max_qty', 'min_amount', 'max_amount', 'rule_description'];

        return slabs.every((slab) =>
            requiredFields.every((field) => slab[field] !== '' && slab[field] !== null && slab[field] !== undefined)
        );
    };

    const validateTabPrix = (slabs) => {
        if (slabs.length === 0) return false;

        const requiredFields = ['min_qty', 'max_qty', 'min_amount', 'max_amount', 'rule_description', 'rate_or_discount'];

        return slabs.every((slab) =>
            requiredFields.every((field) => slab[field] !== '' && slab[field] !== null && slab[field] !== undefined)
        );
    };

    const buyingOptions = ['Supplier', 'Supplier Group'];
    const sellingOptions = ['Customer', 'Customer Group', 'Territory', 'Sales Partner', 'Campaign'];
    const rate_or_discount_options = ['Rate', 'Discount Amount', 'Discount Percentage'];
    const priority_options = [
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '19',
        '20'
    ];

    const selectionData = [];

    if (formState.buying && formState.selling) {
        selectionData.push(...buyingOptions, ...sellingOptions);
    } else if (formState.buying) {
        selectionData.push(...buyingOptions);
    } else if (formState.selling) {
        selectionData.push(...sellingOptions);
    }

    const handleCheckChange = (e) => {
        setFormState({ ...formState, [e.target.name]: e.target.checked ? 1 : 0 });
    };

    const handleResetRow = (index) => {
        const updatedSlabs = [...priceDiscountSlabs];

        updatedSlabs.splice(index, 1);

        setPriceDiscountSlabs(updatedSlabs);
    };

    const handleResetProductRow = (index) => {
        const updatedSlabs = [...productDiscountSlabs];

        updatedSlabs.splice(index, 1);

        setProductDiscountSlabs(updatedSlabs);
    };

    const [editingIndex, setEditingIndex] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [editedSlab, setEditedSlab] = useState(null);

    const handleEditSlab = (index) => {
        setEditingIndex(index);
        setEditedSlab(priceDiscountSlabs[index]);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setEditingIndex(null);
        setModalOpen(false);
        setEditedSlab(null);
    };

    const handleSaveSlab = () => {
        const updatedSlabs = [...priceDiscountSlabs];
        updatedSlabs[editingIndex] = editedSlab;
        setPriceDiscountSlabs(updatedSlabs);
        handleCloseModal();
    };

    const [editingProductIndex, setEditingProductIndex] = useState(null);
    const [ProductmodalOpen, setProductModalOpen] = useState(false);
    const [editedProductSlab, setEditedProductSlab] = useState(null);

    const handleEditProductSlab = (index) => {
        setEditingProductIndex(index);
        setEditedProductSlab(productDiscountSlabs[index]);
        setProductModalOpen(true);
    };

    const handleCloseProductModal = () => {
        setEditingProductIndex(null);
        setProductModalOpen(false);
        setEditedProductSlab(null);
    };

    const handleSaveProductSlab = () => {
        const updatedSlabs = [...productDiscountSlabs];
        updatedSlabs[editingProductIndex] = editedProductSlab;
        setProductDiscountSlabs(updatedSlabs);
        handleCloseProductModal();
    };

    useEffect((index) => {
        const updatedSlabs = [...priceDiscountSlabs];

        updatedSlabs.splice(index, 1);

        setPriceDiscountSlabs(updatedSlabs);
    }, []);

    useEffect((index) => {
        const updatedSlabs = [...productDiscountSlabs];

        updatedSlabs.splice(index, 1);

        setProductDiscountSlabs(updatedSlabs);
    }, []);

    if (id && isPending && doc) return <Loader />;

    return (
        <Box>
            <MainCard title={title}>
                {/* header */}
                <Box display={'flex'} mt={-4} mb={4}>
                    <InfoIcon sx={{ mt: 0.6 }} />
                    <Typography p={1} variant="h5" color="primary">
                        {t('products:promotionalschemedesc')}
                    </Typography>
                </Box>
                {/*tabs*/}
                <Box
                    className={classes.centeredTabs}
                    sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'center' }}
                >
                    <Tabs value={value} onChange={handleChangeValue} aria-label="basic tabs example">
                        <Tab label={t('crm:details')} {...a11yProps(0)} />
                        <Tab label={t('products:Remise_applicable_sur')} {...a11yProps(1)} />
                        <Tab label={t('products:product_slabs')} {...a11yProps(2)} />
                        <Tab label={t('products:Remise_applicable_pour')} {...a11yProps(3)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <Grid container spacing={2} display={'flex'} justifyContent={'left'} flexDirection={'column'}>
                        <InputTextField
                            handleChange={handleChange}
                            value={formState.title}
                            error={formErrState?.title}
                            label={t('crm:title')}
                            name="title"
                            required
                        />
                        <SelectForm
                            label={t('crm:company')}
                            value={formState.company}
                            error={formErrState.company}
                            handleChange={handleChange}
                            data={companies}
                            name="company"
                            propToRender="name"
                            reset={formState.company != ''}
                            handleReset={() => handleReset('company')}
                            required
                        />
                        <SelectForm
                            label={t('currencies:currency')}
                            error={formErrState?.currency}
                            value={formState.currency}
                            handleChange={handleChange}
                            data={currencies}
                            name="currency"
                            propToRender="name"
                            required
                        />
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formState.disable}
                                        onChange={(e) => setFormState((prevState) => ({ ...prevState, disable: e.target.checked }))}
                                        value={formState.disable}
                                        name="disable"
                                        color="primary"
                                    />
                                }
                                label={t('core:disable')}
                            />
                        </Grid>
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formState.mixed_conditions}
                                        onChange={(e) =>
                                            setFormState((prevState) => ({ ...prevState, mixed_conditions: e.target.checked }))
                                        }
                                        value={formState.mixed_conditions}
                                        name="mixed_conditions"
                                        color="primary"
                                    />
                                }
                                label={t('products:mixedconditions')}
                            />
                        </Grid>
                        <Box mt={2}>
                            <Divider>
                                <Chip label={t('crm:timeparams')} />
                            </Divider>
                        </Box>
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formState.is_cumulative}
                                        onChange={(e) => setFormState((prevState) => ({ ...prevState, is_cumulative: e.target.checked }))}
                                        value={formState.is_cumulative}
                                        name="is_cumulative"
                                        color="primary"
                                    />
                                }
                                label={t('products:is_cumulative')}
                            />
                        </Grid>
                        <FormDateTime
                            type="date"
                            label={t('crm:validfrom')}
                            name="valid_from"
                            value={formState.valid_from}
                            handleChange={handleChange}
                            isValidDate
                        />
                        <FormDateTime
                            type="date"
                            label={t('crm:validtill')}
                            name="valid_upto"
                            value={formState.valid_upto}
                            handleChange={handleChange}
                            nextDate={nextDate}
                        />
                    </Grid>
                    <Box display={'flex'} justifyContent={'right'} mt={3}>
                        {value === 0 ? (
                            <IconButton
                                aria-label="delete"
                                sx={{ fontSize: '3rem', backgroundColor: '#ffffff', borderRadius: '50%' }}
                                onClick={handleNextClick}
                            >
                                <KeyboardDoubleArrowRightIcon size="2rem" />
                            </IconButton>
                        ) : null}
                    </Box>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Grid container spacing={2} columns={2} display={'flex'} justifyContent={'center'}>
                        <Grid item xs={1}>
                            <Box m={2}>
                                <Divider>
                                    <Chip label={t('products:Discount_on_Other_Item')} />
                                </Divider>
                            </Box>
                            <SelectForm
                                sx={{ mt: -2, ml: -8 }}
                                fullwidth
                                label={t('products:apply_rule_on_other')}
                                value={formState?.apply_rule_on_other}
                                handleChange={handleChangeApplyOnOther}
                                data={['Item Code', 'Item Group', 'Brand']}
                                name="apply_rule_on_other"
                                reset={formState?.apply_rule_on_other != ''}
                                handleReset={() => handleReset('apply_rule_on_other')}
                            />

                            {formState.apply_rule_on_other === 'Item Code' && (
                                <SelectForm
                                    fullwidth
                                    sx={{ mt: -2, ml: -8 }}
                                    label={t('products:itemcode')}
                                    value={formState.other_item_code}
                                    error={formErrState.other_item_code}
                                    handleChange={handleChange}
                                    data={appForItems}
                                    name="other_item_code"
                                    propToRender={'name'}
                                    reset={formState?.other_item_code != ''}
                                    handleReset={() => handleReset('other_item_code')}
                                />
                            )}
                            {formState.apply_rule_on_other === 'Item Group' && (
                                <SelectForm
                                    fullwidth
                                    sx={{ mt: -2, ml: -8 }}
                                    label={t('crm:ItemGroup')}
                                    value={formState.other_item_group}
                                    error={formErrState.other_item_group}
                                    handleChange={handleChange}
                                    data={appForItems}
                                    name="other_item_group"
                                    propToRender={'name'}
                                    reset={formState?.other_item_group != ''}
                                    handleReset={() => handleReset('other_item_group')}
                                />
                            )}
                            {formState.apply_rule_on_other === 'Brand' && (
                                <SelectForm
                                    fullwidth
                                    sx={{ mt: -2, ml: -8 }}
                                    label={t('crm:Brand')}
                                    value={formState.other_brand}
                                    error={formErrState.other_brand}
                                    handleChange={handleChange}
                                    data={appForItems}
                                    name="other_brand"
                                    propToRender={'name'}
                                    reset={formState?.other_brand != ''}
                                    handleReset={() => handleReset('other_brand')}
                                />
                            )}
                        </Grid>
                        <Grid item xs={1}>
                            <MainCard
                                title={
                                    <Box display={'flex'}>
                                        <ApprovalIcon sx={{ mr: 1.5, ml: -1.5 }} />
                                        {t('crm:appon')} {formState.apply_on}
                                    </Box>
                                }
                                divider
                                border
                            >
                                <SelectForm
                                    sx={{ ml: -5 }}
                                    fullwidth
                                    label={t('crm:appon')}
                                    error={formErrState?.apply_on}
                                    value={formState?.apply_on}
                                    handleChange={handleChangeApplyOn}
                                    data={['Item Code', 'Item Group', 'Brand']}
                                    name="apply_on"
                                    required
                                />
                                <Box m={1}>
                                    <Divider />
                                </Box>
                                {formState?.apply_on != '' && dataSelectionApplayOn}
                            </MainCard>
                        </Grid>
                    </Grid>
                    <Box display={'flex'} justifyContent={'space-between'} mt={3}>
                        {value === 1 ? (
                            <>
                                <IconButton
                                    aria-label="delete"
                                    sx={{ fontSize: '3rem', backgroundColor: '#ffffff', borderRadius: '50%' }}
                                    onClick={handleBackClick}
                                >
                                    <KeyboardDoubleArrowLeftIcon size="2rem" />
                                </IconButton>
                                <IconButton
                                    aria-label="delete"
                                    sx={{ fontSize: '3rem', backgroundColor: '#ffffff', borderRadius: '50%' }}
                                    onClick={handleNextClick}
                                >
                                    <KeyboardDoubleArrowRightIcon size="2rem" />
                                </IconButton>
                            </>
                        ) : null}
                    </Box>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <Grid container spacing={2} justifyContent={'space-between'} alignItems={'center'} sx={{ mt: 2, mb: 1 }}>
                        <h3>{t('products:product_discount')}</h3>
                        <Button color="blue" onClick={addNewSlab}>
                            <AddIcon sx={{ marginLeft: -1 }} /> {t('products:add_product_discount')}
                        </Button>
                        {productDiscountSlabs.map((slab, index) => (
                            <Grid container alignItems={'center'} spacing={1} key={index}>
                                <Grid item xs={1.5}>
                                    <FormControl required fullWidth sx={{ display: 'flex', alignItems: 'center' }}>
                                        <InputLabel>{t('crm:qtymin')}</InputLabel>
                                        <OutlinedInput
                                            type="number"
                                            value={slab.min_qty}
                                            onChange={(e) => handleProductDiscountSlabChange(index, 'min_qty', Number(e.target.value))}
                                            sx={{ mt: 2, mb: 1 }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1.5}>
                                    <FormControl required fullWidth sx={{ display: 'flex', alignItems: 'center' }}>
                                        <InputLabel>{t('crm:qtymax')}</InputLabel>
                                        <OutlinedInput
                                            type="number"
                                            value={slab.max_qty}
                                            onChange={(e) => handleProductDiscountSlabChange(index, 'max_qty', Number(e.target.value))}
                                            sx={{ mt: 2, mb: 1 }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1.5}>
                                    <FormControl required fullWidth sx={{ display: 'flex', alignItems: 'center' }}>
                                        <InputLabel>{t('crm:amountmin')}</InputLabel>
                                        <OutlinedInput
                                            type="number"
                                            value={slab.min_amount}
                                            onChange={(e) => handleProductDiscountSlabChange(index, 'min_amount', Number(e.target.value))}
                                            sx={{ mt: 2, mb: 1 }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1.5}>
                                    <FormControl required fullWidth sx={{ display: 'flex', alignItems: 'center' }}>
                                        <InputLabel>{t('crm:amountmax')}</InputLabel>
                                        <OutlinedInput
                                            type="number"
                                            value={slab.max_amount}
                                            onChange={(e) => handleProductDiscountSlabChange(index, 'max_amount', Number(e.target.value))}
                                            sx={{ mt: 2, mb: 1 }}
                                        />
                                    </FormControl>
                                </Grid>
                                {/*<Grid item xs={2}>
                                    <FormControl fullWidth sx={{ display: 'flex', alignItems: 'center' }}>
                                        <InputLabel id={`free-item-label-${index}`}>{t('crm:freeitem')}</InputLabel>
                                        <Select
                                            labelId={`free-item-label-${index}`}
                                            id={`free-item-select-${index}`}
                                            label="Free Item"
                                            value={slab.free_item}
                                            onChange={(e) => handleProductDiscountSlabChange(index, 'free_item', e.target.value)}
                                            sx={{ mt: 2, mb: 1 }}
                                        >
                                            {itemsList &&
                                                itemsList.map((item) => (
                                                    <MenuItem key={item.item_code} value={item.item_code} style={{ fontSize: 12 }}>
                                                        {item.name}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </Grid>*/}
                                <Grid item xs={2}>
                                    <FormControl fullWidth sx={{ display: 'flex', alignItems: 'center' }}>
                                        <TextField
                                            multiline
                                            label={t('common:description')}
                                            rows={6}
                                            value={slab.rule_description}
                                            onChange={(e) => handleProductDiscountSlabChange(index, 'rule_description', e.target.value)}
                                            sx={{ mt: 2, mb: 1 }}
                                            required
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton onClick={() => handleResetProductRow(index)}>
                                        <RestartAltIcon />
                                    </IconButton>
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton onClick={() => handleEditProductSlab(index)}>
                                        <EditNoteIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        ))}
                        <Modal
                            open={ProductmodalOpen}
                            onClose={handleCloseProductModal}
                            closeAfterTransition
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <div
                                style={{
                                    width: '70%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    padding: '0 40px',
                                    borderRadius: '16px',
                                    boxSizing: 'border-box',
                                    backgroundColor: 'white',
                                    height: '80%'
                                }}
                            >
                                <PerfectScrollbar
                                    style={{
                                        overflow: 'scroll',
                                        height: 550,
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center'
                                    }}
                                >
                                    <div style={{ width: '100%' }}>
                                        <h3>{t('products:edit_product_slab')}</h3>
                                        <hr></hr>
                                        <br></br>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={5.5}>
                                                <FormControl fullWidth>
                                                    <InputLabel>{t('crm:qtymin')}</InputLabel>
                                                    <OutlinedInput
                                                        type="number"
                                                        value={editedProductSlab ? editedProductSlab.min_qty : ''}
                                                        onChange={(e) =>
                                                            setEditedProductSlab({ ...editedProductSlab, min_qty: e.target.value })
                                                        }
                                                        sx={{ mt: 2, mb: 1 }}
                                                    />
                                                </FormControl>
                                                <FormControl fullWidth>
                                                    <InputLabel>{t('crm:qtymax')}</InputLabel>
                                                    <OutlinedInput
                                                        type="number"
                                                        value={editedProductSlab ? editedProductSlab.max_qty : ''}
                                                        onChange={(e) =>
                                                            setEditedProductSlab({ ...editedProductSlab, max_qty: e.target.value })
                                                        }
                                                        sx={{ mt: 2, mb: 1 }}
                                                    />
                                                </FormControl>
                                                <FormControl fullWidth>
                                                    <InputLabel>{t('crm:amountmin')}</InputLabel>
                                                    <OutlinedInput
                                                        type="number"
                                                        value={editedProductSlab ? editedProductSlab.min_amount : ''}
                                                        onChange={(e) =>
                                                            setEditedProductSlab({ ...editedProductSlab, min_amount: e.target.value })
                                                        }
                                                        sx={{ mt: 2, mb: 1 }}
                                                    />
                                                </FormControl>
                                                <FormControl fullWidth>
                                                    <InputLabel>{t('crm:amountmax')}</InputLabel>
                                                    <OutlinedInput
                                                        type="number"
                                                        value={editedProductSlab ? editedProductSlab.max_amount : ''}
                                                        onChange={(e) =>
                                                            setEditedProductSlab({ ...editedProductSlab, max_amount: e.target.value })
                                                        }
                                                        sx={{ mt: 2, mb: 1 }}
                                                    />
                                                </FormControl>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={editedProductSlab ? editedProductSlab.same_item === 1 : false}
                                                            onChange={(e) =>
                                                                setEditedProductSlab({
                                                                    ...editedProductSlab,
                                                                    same_item: e.target.checked ? 1 : 0
                                                                })
                                                            }
                                                            sx={{ mt: 2, mb: 1 }}
                                                        />
                                                    }
                                                    label={t('crm:sameitem')}
                                                />
                                                {editedProductSlab && editedProductSlab.same_item !== 1 && (
                                                    <FormControl fullWidth>
                                                        <InputLabel>{t('crm:freeitem')}</InputLabel>
                                                        <Select
                                                            value={editedProductSlab ? editedProductSlab.free_item : ''}
                                                            onChange={(e) =>
                                                                setEditedProductSlab({ ...editedProductSlab, free_item: e.target.value })
                                                            }
                                                            sx={{ mt: 2, mb: 1 }}
                                                        >
                                                            {itemsList &&
                                                                itemsList.map((item) => (
                                                                    <MenuItem
                                                                        key={item.item_code}
                                                                        value={item.item_code}
                                                                        style={{ fontSize: 12 }}
                                                                    >
                                                                        {item.item_code}
                                                                    </MenuItem>
                                                                ))}
                                                        </Select>
                                                    </FormControl>
                                                )}
                                                <FormControl fullWidth>
                                                    <InputLabel>{t('crm:freeitemrate')}</InputLabel>
                                                    <OutlinedInput
                                                        type="number"
                                                        value={editedProductSlab ? editedProductSlab.free_item_rate : ''}
                                                        onChange={(e) =>
                                                            setEditedProductSlab({
                                                                ...editedProductSlab,
                                                                free_item_rate: e.target.value
                                                            })
                                                        }
                                                        sx={{ mt: 2, mb: 1 }}
                                                    />
                                                </FormControl>
                                                <FormControl fullWidth>
                                                    <InputLabel>{t('crm:freeqty')}</InputLabel>
                                                    <OutlinedInput
                                                        type="number"
                                                        value={editedProductSlab ? editedProductSlab.free_qty : ''}
                                                        onChange={(e) =>
                                                            setEditedProductSlab({
                                                                ...editedProductSlab,
                                                                free_qty: e.target.value
                                                            })
                                                        }
                                                        sx={{ mt: 2, mb: 1 }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={5.5}>
                                                <FormControl fullWidth>
                                                    <InputLabel>{t('columns:warehouse')}</InputLabel>
                                                    <Select
                                                        value={editedProductSlab ? editedProductSlab.warehouse : ''}
                                                        onChange={(e) =>
                                                            setEditedProductSlab({ ...editedProductSlab, warehouse: e.target.value })
                                                        }
                                                        sx={{ mt: 2, mb: 1 }}
                                                    >
                                                        {warehouses &&
                                                            warehouses.map((warehouse) => (
                                                                <MenuItem
                                                                    key={warehouse.name}
                                                                    value={warehouse.name}
                                                                    style={{ fontSize: 12 }}
                                                                >
                                                                    {warehouse.name}
                                                                </MenuItem>
                                                            ))}
                                                    </Select>
                                                </FormControl>
                                                <FormControl fullWidth>
                                                    <InputLabel>{t('products:stockUOM')}</InputLabel>
                                                    <Select
                                                        value={editedProductSlab ? editedProductSlab.free_item_uom : ''}
                                                        onChange={(e) =>
                                                            setEditedProductSlab({ ...editedProductSlab, free_item_uom: e.target.value })
                                                        }
                                                        sx={{ mt: 2, mb: 1 }}
                                                    >
                                                        {uoms &&
                                                            uoms.map((uom) => (
                                                                <MenuItem key={uom.name} value={uom.name} style={{ fontSize: 12 }}>
                                                                    {uom.name}
                                                                </MenuItem>
                                                            ))}
                                                    </Select>
                                                </FormControl>
                                                <FormControl fullWidth>
                                                    <InputLabel>{t('columns:priority')}</InputLabel>
                                                    <Select
                                                        value={editedProductSlab ? editedProductSlab.priority : ''}
                                                        onChange={(e) =>
                                                            setEditedProductSlab({ ...editedProductSlab, priority: e.target.value })
                                                        }
                                                        sx={{ mt: 2, mb: 1 }}
                                                    >
                                                        {priority_options.map((option, i) => (
                                                            <MenuItem key={i} value={option}>
                                                                {option}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                <FormControl fullWidth>
                                                    <InputLabel>{t('products:threshold_percentage')}</InputLabel>
                                                    <OutlinedInput
                                                        type="number"
                                                        value={editedProductSlab ? editedProductSlab.threshold_percentage : ''}
                                                        onChange={(e) =>
                                                            setEditedProductSlab({
                                                                ...editedProductSlab,
                                                                threshold_percentage: e.target.value
                                                            })
                                                        }
                                                        sx={{ mt: 2, mb: 1 }}
                                                    />
                                                </FormControl>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={editedProductSlab ? editedProductSlab.is_recursive === 1 : false}
                                                            onChange={(e) =>
                                                                setEditedProductSlab({
                                                                    ...editedProductSlab,
                                                                    is_recursive: e.target.checked ? 1 : 0
                                                                })
                                                            }
                                                            sx={{ mt: 2, mb: 1 }}
                                                        />
                                                    }
                                                    label={t('crm:isrecursive')}
                                                />
                                                <FormControl fullWidth>
                                                    <TextField
                                                        multiline
                                                        label={t('common:description')}
                                                        rows={6}
                                                        value={editedProductSlab ? editedProductSlab.rule_description : ''}
                                                        onChange={(e) =>
                                                            setEditedProductSlab({ ...editedProductSlab, rule_description: e.target.value })
                                                        }
                                                        sx={{ mt: 2, mb: 1 }}
                                                    />
                                                </FormControl>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={
                                                                editedProductSlab
                                                                    ? editedProductSlab.apply_multiple_pricing_rules === 1
                                                                    : false
                                                            }
                                                            onChange={(e) =>
                                                                setEditedProductSlab({
                                                                    ...editedProductSlab,
                                                                    apply_multiple_pricing_rules: e.target.checked ? 1 : 0
                                                                })
                                                            }
                                                            sx={{ mt: 2, mb: 1 }}
                                                        />
                                                    }
                                                    label={t('products:apply_multiple_pricing_rules')}
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={editedProductSlab ? editedProductSlab.disable === 1 : false}
                                                            onChange={(e) =>
                                                                setEditedProductSlab({
                                                                    ...editedProductSlab,
                                                                    disable: e.target.checked ? 1 : 0
                                                                })
                                                            }
                                                            sx={{ mt: 2, mb: 1 }}
                                                        />
                                                    }
                                                    label={t('core:disable')}
                                                />
                                                <Grid item xs={12} sx={{ ml: 27, mt: 5, mb: 1 }}>
                                                    <Stack direction="row" spacing={2}>
                                                        <LoadingButton
                                                            color="blue"
                                                            size="large"
                                                            variant="contained"
                                                            loading={loadingButton}
                                                            onClick={handleSaveProductSlab}
                                                        >
                                                            {t('common:save')}
                                                        </LoadingButton>
                                                        <Button
                                                            color="blue"
                                                            size="large"
                                                            variant="outlined"
                                                            onClick={handleCloseProductModal}
                                                        >
                                                            {t('common:cancel')}
                                                        </Button>
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </PerfectScrollbar>
                            </div>
                        </Modal>
                    </Grid>
                    <hr></hr>
                    <Grid container spacing={2} justifyContent={'space-between'} alignItems={'center'} sx={{ mt: 2, mb: 1 }}>
                        <h3>{t('products:price_discount')}</h3>
                        <Button color="blue" onClick={addNewSlabPrice}>
                            <AddIcon sx={{ marginLeft: -1 }} /> {t('products:add_price_discount')}
                        </Button>
                        {priceDiscountSlabs.map((slab, index) => (
                            <Grid container alignItems={'center'} spacing={1} key={index}>
                                <Grid item xs={1.5}>
                                    <FormControl required fullWidth sx={{ display: 'flex', alignItems: 'center' }}>
                                        <InputLabel>{t('crm:qtymin')}</InputLabel>
                                        <OutlinedInput
                                            type="number"
                                            value={slab.min_qty}
                                            onChange={(e) => handlePriceDiscountSlabChange(index, 'min_qty', Number(e.target.value))}
                                            sx={{ mt: 2, mb: 1 }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1.5}>
                                    <FormControl required fullWidth sx={{ display: 'flex', alignItems: 'center' }}>
                                        <InputLabel>{t('crm:qtymax')}</InputLabel>
                                        <OutlinedInput
                                            type="number"
                                            value={slab.max_qty}
                                            onChange={(e) => handlePriceDiscountSlabChange(index, 'max_qty', Number(e.target.value))}
                                            sx={{ mt: 2, mb: 1 }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1.5}>
                                    <FormControl required fullWidth sx={{ display: 'flex', alignItems: 'center' }}>
                                        <InputLabel>{t('crm:amountmin')}</InputLabel>
                                        <OutlinedInput
                                            type="number"
                                            value={slab.min_amount}
                                            onChange={(e) => handlePriceDiscountSlabChange(index, 'min_amount', Number(e.target.value))}
                                            sx={{ mt: 2, mb: 1 }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1.5}>
                                    <FormControl required fullWidth sx={{ display: 'flex', alignItems: 'center' }}>
                                        <InputLabel>{t('crm:amountmax')}</InputLabel>
                                        <OutlinedInput
                                            type="number"
                                            value={slab.max_amount}
                                            onChange={(e) => handlePriceDiscountSlabChange(index, 'max_amount', Number(e.target.value))}
                                            sx={{ mt: 2, mb: 1 }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControl required fullWidth sx={{ display: 'flex', alignItems: 'center' }}>
                                        <InputLabel id={`rate-or-discount-label-${index}`}>{t('products:discount_type')}</InputLabel>
                                        <Select
                                            labelId={`rate-or-discount-label-${index}`}
                                            id={`rate-or-discount-select-${index}`}
                                            label={t('products:discount_type')}
                                            value={slab.rate_or_discount}
                                            onChange={(e) => handlePriceDiscountSlabChange(index, 'rate_or_discount', e.target.value)}
                                            sx={{ mt: 2, mb: 1 }}
                                        >
                                            {rate_or_discount_options.map((option, i) => (
                                                <MenuItem key={i} value={option} style={{ fontSize: 12 }}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControl fullWidth sx={{ display: 'flex', alignItems: 'center' }}>
                                        <TextField
                                            multiline
                                            label={t('common:description')}
                                            rows={6}
                                            value={slab.rule_description}
                                            onChange={(e) => handlePriceDiscountSlabChange(index, 'rule_description', e.target.value)}
                                            sx={{ mt: 2, mb: 1 }}
                                            required
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton onClick={() => handleResetRow(index)}>
                                        <RestartAltIcon />
                                    </IconButton>
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton onClick={() => handleEditSlab(index)}>
                                        <EditNoteIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        ))}
                        <Modal
                            open={modalOpen}
                            onClose={handleCloseModal}
                            closeAfterTransition
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <div
                                style={{
                                    width: '70%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    padding: '0 40px',
                                    borderRadius: '16px',
                                    boxSizing: 'border-box',
                                    backgroundColor: 'white',
                                    height: '80%'
                                }}
                            >
                                <PerfectScrollbar
                                    style={{
                                        overflow: 'scroll',
                                        height: 550,
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center'
                                    }}
                                >
                                    <div style={{ width: '100%' }}>
                                        <h3>{t('products:edit_product_slab')}</h3>
                                        <hr></hr>
                                        <br></br>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={5.5}>
                                                <FormControl fullWidth>
                                                    <InputLabel>{t('crm:qtymin')}</InputLabel>
                                                    <OutlinedInput
                                                        type="number"
                                                        value={editedSlab ? editedSlab.min_qty : ''}
                                                        onChange={(e) => setEditedSlab({ ...editedSlab, min_qty: e.target.value })}
                                                        sx={{ mt: 2, mb: 1 }}
                                                    />
                                                </FormControl>
                                                <FormControl fullWidth>
                                                    <InputLabel>{t('crm:qtymax')}</InputLabel>
                                                    <OutlinedInput
                                                        type="number"
                                                        value={editedSlab ? editedSlab.max_qty : ''}
                                                        onChange={(e) => setEditedSlab({ ...editedSlab, max_qty: e.target.value })}
                                                        sx={{ mt: 2, mb: 1 }}
                                                    />
                                                </FormControl>
                                                <FormControl fullWidth>
                                                    <InputLabel>{t('crm:amountmin')}</InputLabel>
                                                    <OutlinedInput
                                                        type="number"
                                                        value={editedSlab ? editedSlab.min_amount : ''}
                                                        onChange={(e) => setEditedSlab({ ...editedSlab, min_amount: e.target.value })}
                                                        sx={{ mt: 2, mb: 1 }}
                                                    />
                                                </FormControl>
                                                <FormControl fullWidth>
                                                    <InputLabel>{t('crm:amountmax')}</InputLabel>
                                                    <OutlinedInput
                                                        type="number"
                                                        value={editedSlab ? editedSlab.max_amount : ''}
                                                        onChange={(e) => setEditedSlab({ ...editedSlab, max_amount: e.target.value })}
                                                        sx={{ mt: 2, mb: 1 }}
                                                    />
                                                </FormControl>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        multiline
                                                        label={t('common:description')}
                                                        rows={6}
                                                        value={editedSlab ? editedSlab.rule_description : ''}
                                                        onChange={(e) => setEditedSlab({ ...editedSlab, rule_description: e.target.value })}
                                                        sx={{ mt: 2, mb: 1 }}
                                                    />
                                                </FormControl>
                                                {editedSlab && editedSlab.priority !== 0 && (
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={editedSlab ? editedSlab.apply_multiple_pricing_rules === 1 : false}
                                                                onChange={(e) =>
                                                                    setEditedSlab({
                                                                        ...editedSlab,
                                                                        apply_multiple_pricing_rules: e.target.checked ? 1 : 0
                                                                    })
                                                                }
                                                                sx={{ mt: 2, mb: 1 }}
                                                            />
                                                        }
                                                        label={t('products:apply_multiple_pricing_rules')}
                                                    />
                                                )}
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={editedSlab ? editedSlab.disable === 1 : false}
                                                            onChange={(e) =>
                                                                setEditedSlab({ ...editedSlab, disable: e.target.checked ? 1 : 0 })
                                                            }
                                                            sx={{ mt: 2, mb: 1 }}
                                                        />
                                                    }
                                                    label={t('core:disable')}
                                                />
                                            </Grid>
                                            <Grid item xs={5.5}>
                                                <FormControl fullWidth>
                                                    <InputLabel>{t('products:discount_type')}</InputLabel>
                                                    <Select
                                                        value={editedSlab ? editedSlab.rate_or_discount : ''}
                                                        onChange={(e) => setEditedSlab({ ...editedSlab, rate_or_discount: e.target.value })}
                                                        sx={{ mt: 2, mb: 1 }}
                                                    >
                                                        {rate_or_discount_options.map((option, i) => (
                                                            <MenuItem key={i} value={option}>
                                                                {option}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                {editedSlab && editedSlab.rate_or_discount === 'Rate' && (
                                                    <FormControl fullWidth>
                                                        <InputLabel>{t('products:Rate')}</InputLabel>
                                                        <OutlinedInput
                                                            type="number"
                                                            value={editedSlab.rate || ''}
                                                            onChange={(e) => setEditedSlab({ ...editedSlab, rate: e.target.value })}
                                                            sx={{ mt: 2, mb: 1 }}
                                                        />
                                                    </FormControl>
                                                )}

                                                {editedSlab && editedSlab.rate_or_discount === 'Discount Percentage' && (
                                                    <FormControl fullWidth>
                                                        <InputLabel>{t('products:Discount_Percentage')}</InputLabel>
                                                        <OutlinedInput
                                                            type="number"
                                                            value={editedSlab.discount_percentage || ''}
                                                            onChange={(e) =>
                                                                setEditedSlab({ ...editedSlab, discount_percentage: e.target.value })
                                                            }
                                                            sx={{ mt: 2, mb: 1 }}
                                                        />
                                                    </FormControl>
                                                )}

                                                {editedSlab && editedSlab.rate_or_discount === 'Discount Amount' && (
                                                    <FormControl fullWidth>
                                                        <InputLabel>{t('products:Discount_Amount')}</InputLabel>
                                                        <OutlinedInput
                                                            type="number"
                                                            value={editedSlab.discount_amount || ''}
                                                            onChange={(e) =>
                                                                setEditedSlab({ ...editedSlab, discount_amount: e.target.value })
                                                            }
                                                            sx={{ mt: 2, mb: 1 }}
                                                        />
                                                    </FormControl>
                                                )}
                                                <FormControl fullWidth>
                                                    <InputLabel>{t('columns:warehouse')}</InputLabel>
                                                    <Select
                                                        value={editedSlab ? editedSlab.warehouse : ''}
                                                        onChange={(e) => setEditedSlab({ ...editedSlab, warehouse: e.target.value })}
                                                        sx={{ mt: 2, mb: 1 }}
                                                    >
                                                        {warehouses &&
                                                            warehouses.map((warehouse) => (
                                                                <MenuItem
                                                                    key={warehouse.name}
                                                                    value={warehouse.name}
                                                                    style={{ fontSize: 12 }}
                                                                >
                                                                    {warehouse.name}
                                                                </MenuItem>
                                                            ))}
                                                    </Select>
                                                </FormControl>
                                                <FormControl fullWidth>
                                                    <InputLabel>{t('columns:priority')}</InputLabel>
                                                    <Select
                                                        value={editedSlab ? editedSlab.priority : ''}
                                                        onChange={(e) => setEditedSlab({ ...editedSlab, priority: e.target.value })}
                                                        sx={{ mt: 2, mb: 1 }}
                                                    >
                                                        {priority_options.map((option, i) => (
                                                            <MenuItem key={i} value={option}>
                                                                {option}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                {editedSlab &&
                                                    editedSlab.rate_or_discount &&
                                                    (editedSlab.rate_or_discount === 'Discount Amount' ||
                                                        editedSlab.rate_or_discount === 'Discount Percentage') &&
                                                    editedSlab.apply_multiple_pricing_rules === 1 && (
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={editedSlab.apply_discount_on_rate === 1}
                                                                    onChange={(e) =>
                                                                        setEditedSlab({
                                                                            ...editedSlab,
                                                                            apply_discount_on_rate: e.target.checked ? 1 : 0
                                                                        })
                                                                    }
                                                                    sx={{ mt: 2, mb: 1 }}
                                                                />
                                                            }
                                                            label="apply_discount_on_rate"
                                                        />
                                                    )}
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={editedSlab ? editedSlab.validate_applied_rule === 1 : false}
                                                            onChange={(e) =>
                                                                setEditedSlab({
                                                                    ...editedSlab,
                                                                    validate_applied_rule: e.target.checked ? 1 : 0
                                                                })
                                                            }
                                                            sx={{ mt: 2, mb: 1 }}
                                                        />
                                                    }
                                                    label={t('products:validate_applied_rule')}
                                                />
                                                <FormControl fullWidth>
                                                    <InputLabel>{t('products:threshold_percentage')}</InputLabel>
                                                    <OutlinedInput
                                                        type="number"
                                                        value={editedSlab ? editedSlab.threshold_percentage : ''}
                                                        onChange={(e) =>
                                                            setEditedSlab({ ...editedSlab, threshold_percentage: e.target.value })
                                                        }
                                                        sx={{ mt: 2, mb: 1 }}
                                                    />
                                                </FormControl>
                                                <Grid item xs={12} sx={{ ml: 27, mt: 15, mb: 1 }}>
                                                    <Stack direction="row" spacing={2}>
                                                        <LoadingButton
                                                            color="blue"
                                                            size="large"
                                                            variant="contained"
                                                            loading={loadingButton}
                                                            onClick={handleSaveSlab}
                                                        >
                                                            {t('common:save')}
                                                        </LoadingButton>
                                                        <Button color="blue" size="large" variant="outlined" onClick={handleCloseModal}>
                                                            {t('common:cancel')}
                                                        </Button>
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </PerfectScrollbar>
                            </div>
                        </Modal>
                    </Grid>
                    <Box display={'flex'} justifyContent={'space-between'} mt={3}>
                        {value === 2 ? (
                            <>
                                <IconButton
                                    aria-label="delete"
                                    sx={{ fontSize: '3rem', backgroundColor: '#ffffff', borderRadius: '50%' }}
                                    onClick={handleBackClick}
                                >
                                    <KeyboardDoubleArrowLeftIcon size="2rem" />
                                </IconButton>
                                <IconButton
                                    aria-label="delete"
                                    sx={{ fontSize: '3rem', backgroundColor: '#ffffff', borderRadius: '50%' }}
                                    onClick={handleNextClick}
                                >
                                    <KeyboardDoubleArrowRightIcon size="2rem" />
                                </IconButton>
                            </>
                        ) : null}
                    </Box>
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <Grid container spacing={2} columns={2} display={'flex'} justifyContent={'center'}>
                        <Grid item xs={1}>
                            <Box m={2}>
                                <Divider>
                                    <Chip label={t('products:Applicable_for')} />
                                </Divider>
                            </Box>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formState.buying}
                                        onChange={(e) => setFormState((prevState) => ({ ...prevState, buying: e.target.checked }))}
                                        value={formState.buying}
                                        name="buying"
                                        color="primary"
                                    />
                                }
                                label={t('core:buying')}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formState.selling}
                                        onChange={(e) => setFormState((prevState) => ({ ...prevState, selling: e.target.checked }))}
                                        value={formState.selling}
                                        name="selling"
                                        color="primary"
                                    />
                                }
                                label={t('core:selling')}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            {(formState.buying || formState.selling == true) && (
                                <MainCard
                                    title={
                                        <Box display={'flex'}>
                                            <ApprovalIcon sx={{ mr: 1.5, ml: -1.5 }} />
                                            {t('products:Applicable_for')} {formState.applicable_for}
                                        </Box>
                                    }
                                    divider
                                    border
                                >
                                    <SelectForm
                                        sx={{ ml: -5 }}
                                        fullwidth
                                        label={t('products:Applicable_for')}
                                        error={formErrState?.applicable_for}
                                        value={formState?.applicable_for}
                                        handleChange={handleChangeAppFor}
                                        data={selectionData}
                                        name="applicable_for"
                                        required
                                    />
                                    <Box m={1}>
                                        <Divider />
                                    </Box>
                                    {formState?.applicable_for != '' && dataSelectionApplicableFor}
                                </MainCard>
                            )}
                        </Grid>
                    </Grid>
                    <Box display={'flex'} justifyContent={'space-between'} mt={3}>
                        {value === 3 ? (
                            <IconButton
                                aria-label="delete"
                                color="primary"
                                onClick={handleBackClick}
                                sx={{ fontSize: '3rem', backgroundColor: '#ffffff', borderRadius: '50%' }}
                            >
                                <KeyboardDoubleArrowLeftIcon size="2rem" />
                            </IconButton>
                        ) : null}
                        <LoadingButton loading={loadingButton} variant="contained" size="large" color="blue" onClick={handleSubmit}>
                            {t('crm:save')}
                        </LoadingButton>
                    </Box>
                </TabPanel>
            </MainCard>
        </Box>
    );
};

export default PromotionalSchemeForm;
