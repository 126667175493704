import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Grid,
    IconButton,
    OutlinedInput,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import SelectForm from 'ui-component/form/SelectForm';
import { CurrencyFormatter, formatErpResponse } from 'utils/utils';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { SetNotification } from 'store/services/api';
import { t } from 'i18next';

function ItemPriceForm({ setItemPriceState, itemPriceState, doc, disabled, priceLists, onPriceChange }) {
    const dispatch = useDispatch();
    const globalDefaults = useSelector((state) => state.login.globalDefaults);
    const [open, setOpen] = useState(false);
    const [editingIndex, setEditingIndex] = useState(null);
    const [loadingButton, setLoadingButton] = useState(false);
    const [taxRate, setTaxRate] = useState(null);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [currentEditItem, setCurrentEditItem] = useState(null);

    const initialRow = {
        doctype: 'Item Price',
        price_list: '',
        price_list_rate: '',
        item_price_ttc: '',
        item_code: doc.name,
        currency: globalDefaults?.default_currency,
        selling: 1
    };

    const [row2Insert, setRow2Insert] = useState(initialRow);

    useEffect(() => {
        const hasUnsaved = itemPriceState?.some((item) => item.__unsaved || item.__islocal);
        setHasUnsavedChanges(hasUnsaved);
    }, [itemPriceState]);

    useEffect(() => {
        const fetchTaxRate = async () => {
            if (doc?.item_tax_category) {
                try {
                    const response = await fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
                        method: 'POST',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
                            Authorization: JSON.parse(localStorage.getItem('user'))?.token
                        },
                        body: JSON.stringify({
                            doctype: 'Item Tax Template Detail',
                            fields: ['tax_rate'],
                            filters: [['Item Tax Template Detail', 'parent', '=', doc.item_tax_category]]
                        })
                    });
                    const data = await response.json();
                    if (data && data.length > 0) {
                        setTaxRate(parseFloat(data[0].tax_rate));
                    }
                } catch (error) {
                    console.error('error fetching tax:', error);
                }
            }
        };

        fetchTaxRate();
    }, [doc?.item_tax_category]);

    const handleOpen = () => {
        setOpen(true);
        setEditingIndex(null);
        setRow2Insert(initialRow);
    };

    const handleClose = () => {
        setOpen(false);
        setEditingIndex(null);
        setRow2Insert(initialRow);
    };
    const formatDecimal = (num) => {
        if (!num || num === '') return '';
        return Number(parseFloat(num).toFixed(3));
    };

    const calculateTTC = (ht) => {
        if (!ht || ht === '') return '';
        if (!taxRate) return formatDecimal(ht);
        return formatDecimal(parseFloat(ht) * (1 + taxRate / 100));
    };

    const calculateHT = (ttc) => {
        if (!ttc || ttc === '') return '';
        if (!taxRate) return formatDecimal(ttc);
        return formatDecimal(parseFloat(ttc) / (1 + taxRate / 100));
    };

    const handleChangeValues = (e) => {
        const { name, value } = e.target;
        const validateInput = (input) => {
            if (input === '') return '';
            const regex = /^\d*\.?\d{0,3}$/;
            if (regex.test(input)) {
                return input;
            }
            return row2Insert[name];
        };

        if (name === 'price_list_rate') {
            const validatedValue = validateInput(value);
            const ttc = calculateTTC(validatedValue);
            const newRow = {
                ...row2Insert,
                price_list_rate: validatedValue,
                item_price_ttc: ttc
            };
            setRow2Insert(newRow);

            if (row2Insert.selling === 1) {
                onPriceChange({
                    price_list_rate: validatedValue,
                    item_price_ttc: ttc
                });
            }
        } else if (name === 'item_price_ttc') {
            const validatedValue = validateInput(value);
            const ht = calculateHT(validatedValue);
            const newRow = {
                ...row2Insert,
                item_price_ttc: validatedValue,
                price_list_rate: ht
            };
            setRow2Insert(newRow);

            if (row2Insert.selling === 1) {
                onPriceChange({
                    price_list_rate: ht,
                    item_price_ttc: validatedValue
                });
            }
        } else {
            setRow2Insert({ ...row2Insert, [name]: value });
        }
    };

    const handleEdit = async (index) => {
        const itemToEdit = itemPriceState[index];

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
                    Authorization: JSON.parse(localStorage.getItem('user'))?.token
                },
                body: JSON.stringify({
                    doctype: 'Item Price',
                    fields: ['*'],
                    filters: [
                        ['Item Price', 'item_code', '=', doc.name],
                        ['Item Price', 'price_list', '=', itemToEdit.price_list]
                    ]
                })
            });
            const data = await response.json();
            const existingRecord = data && data.length > 0 ? data[0] : null;

            if (existingRecord) {
                setCurrentEditItem(existingRecord);
                setRow2Insert({
                    ...existingRecord,
                    price_list_rate: formatDecimal(itemToEdit.price_list_rate),
                    item_price_ttc: formatDecimal(itemToEdit.item_price_ttc || calculateTTC(itemToEdit.price_list_rate))
                });
                setEditingIndex(index);
                setOpen(true);
            }
        } catch (error) {
            console.error('Error fetching item price:', error);
            dispatch(
                SetNotification({
                    code: 'error',
                    message: 'Error fetching price record'
                })
            );
        }
    };
    const handleUpdate = () => {
        const parsedRate = row2Insert.price_list_rate ? formatDecimal(row2Insert.price_list_rate) : 0;
        const ttc = calculateTTC(parsedRate);

        const updatedState = [...itemPriceState];
        updatedState[editingIndex] = {
            ...currentEditItem,
            price_list_rate: parsedRate,
            item_price_ttc: ttc,
            modified: true,
            __unsaved: 1,
            name: currentEditItem?.name
        };

        setItemPriceState(updatedState);
        setHasUnsavedChanges(true);

        if (row2Insert.selling === 1) {
            onPriceChange({
                price_list_rate: parsedRate,
                item_price_ttc: ttc
            });
        }

        handleClose();
    };

    const handleInsert = () => {
        const parsedRate = row2Insert.price_list_rate ? formatDecimal(row2Insert.price_list_rate) : 0;
        const ttc = calculateTTC(parsedRate);

        const newRow = {
            ...row2Insert,
            price_list_rate: parsedRate,
            item_price_ttc: ttc,
            __islocal: 1,
            __unsaved: 1
        };

        setItemPriceState([...itemPriceState, newRow]);
        setHasUnsavedChanges(true);

        if (row2Insert.selling === 1) {
            onPriceChange({
                price_list_rate: parsedRate,
                item_price_ttc: ttc
            });
        }

        handleClose();
    };

    const handleDelete = async (el) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
                method: 'POST',
                headers: header,
                body: JSON.stringify({
                    doctype: 'Item Price',
                    fields: ['name'],
                    filters: [
                        ['Item Price', 'item_code', '=', doc.name],
                        ['Item Price', 'price_list', '=', el.price_list]
                    ]
                })
            });

            const data = await response.json();
            const itemToDelete = data && data.length > 0 ? data[0] : null;

            if (!itemToDelete?.name) {
                dispatch(
                    SetNotification({
                        code: 'error',
                        message: t('products:item_price_not_found')
                    })
                );
                return;
            }
            const deleteResponse = await fetch(`${process.env.REACT_APP_API_URI}/api/delete-doc`, {
                method: 'POST',
                headers: header,
                body: JSON.stringify({
                    doctype: 'Item Price',
                    name: itemToDelete.name
                })
            });

            const deleteResult = await deleteResponse.json();

            if (deleteResult.success !== false) {
                setItemPriceState(
                    itemPriceState.filter((row) => !(row.price_list === el.price_list && row.price_list_rate === el.price_list_rate))
                );
                dispatch(
                    SetNotification({
                        code: 'success',
                        message: t('products:deleted_successfully')
                    })
                );
            } else {
                dispatch(
                    SetNotification({
                        code: 'error',
                        message: formatErpResponse(deleteResult.message) || t('products:delete_failed')
                    })
                );
            }
        } catch (error) {
            dispatch(
                SetNotification({
                    code: 'error',
                    message: t('products:error_deleting')
                })
            );
        }
    };

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const handleSubmit = async () => {
        try {
            setLoadingButton(true);

            const filteredDocs = itemPriceState
                .filter((doc) => doc.modified === true || doc.__unsaved === 1)
                .map((doc) => {
                    const baseDoc = {
                        doctype: 'Item Price',
                        item_code: doc.item_code || currentEditItem?.item_code || row2Insert.item_code,
                        price_list: doc.price_list,
                        price_list_rate: parseFloat(doc.price_list_rate).toFixed(3),
                        item_price_ttc: doc.item_price_ttc ? parseFloat(doc.item_price_ttc).toFixed(3) : calculateTTC(doc.price_list_rate),
                        currency: doc.currency || globalDefaults?.default_currency,
                        __unsaved: 1
                    };

                    if (currentEditItem && currentEditItem.name) {
                        return {
                            ...baseDoc,
                            name: currentEditItem.name,
                            modified: currentEditItem.modified,
                            modified_by: currentEditItem.modified_by,
                            creation: currentEditItem.creation,
                            owner: currentEditItem.owner,
                            docstatus: currentEditItem.docstatus,
                            __islocal: 0
                        };
                    }

                    return {
                        ...baseDoc,
                        buying: doc.price_list.toLowerCase().includes('buying') ? 1 : 0,
                        selling: doc.price_list.toLowerCase().includes('selling') ? 1 : 0,
                        valid_from: doc.valid_from || new Date().toISOString().split('T')[0],
                        uom: 'Nos',
                        __islocal: 1
                    };
                });

            //console.log('filteredDocs', filteredDocs);

            const results = await Promise.all(
                filteredDocs.map(async (doc) => {
                    try {
                        const body = {
                            doc: doc,
                            action: 'Save'
                        };

                        //console.log('body:', body);

                        const response = await fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
                            method: 'POST',
                            headers: header,
                            body: JSON.stringify(body)
                        });

                        const result = await response.json();
                        //console.log('result', result);
                        return result;
                    } catch (error) {
                        console.error('Error saving document:', error);
                        return { success: false, message: error.message };
                    }
                })
            );

            let hasSuccess = false;
            let hasError = false;

            results.forEach((data) => {
                if (data?.success === false) {
                    hasError = true;
                    console.error('Save error:', data);
                    let errorMessage = 'An error occurred while saving';
                    if (typeof data.message === 'string') {
                        errorMessage = formatErpResponse(data.message);
                    } else if (data.message?.message) {
                        errorMessage = formatErpResponse(data.message.message);
                    }
                    dispatch(SetNotification({ code: 'warning', message: errorMessage }));
                } else {
                    hasSuccess = true;
                    //console.log('data', data);
                }
            });

            if (hasSuccess) {
                const updatedState = itemPriceState.map((item) => {
                    if (item.__unsaved || item.modified) {
                        return {
                            ...item,
                            __unsaved: 0,
                            modified: false
                        };
                    }
                    return item;
                });

                setItemPriceState(updatedState);
                setHasUnsavedChanges(false);
                setCurrentEditItem(null);
                dispatch(SetNotification({ code: 'success', message: t('products:price_saved_succesfully') }));
            }

            if (!hasSuccess && !hasError) {
                dispatch(SetNotification({ code: 'warning', message: 'No changes were saved' }));
            }
        } catch (error) {
            console.error('Save operation error:', error);
            dispatch(SetNotification({ code: 'error', message: 'An error occurred while saving' }));
        } finally {
            setLoadingButton(false);
        }
    };

    const isAddButtonDisabled = disabled || (itemPriceState && itemPriceState.length > 0);

    return (
        <div style={{ padding: 20 }}>
            <Box>
                <Grid container justifyContent={'space-between'} display={'flex'} alignItems={'center'}>
                    <Typography fontWeight={600} variant="h4">
                        {t('products:newitemP')}
                    </Typography>
                    <Button color="blue" onClick={handleOpen} disabled={isAddButtonDisabled}>
                        <AddIcon sx={{ marginLeft: -1 }} /> {t('core:add')}
                    </Button>
                </Grid>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <strong>{t('columns:price_list_name')}</strong>
                            </TableCell>
                            <TableCell>
                                <strong>{t('products:price_list_rate')}</strong>
                            </TableCell>
                            <TableCell>
                                <strong>{t('products:price_ttc')}</strong>
                            </TableCell>
                            <TableCell align="right" width={100}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {itemPriceState?.map((el, i) => (
                            <TableRow key={i}>
                                <TableCell>
                                    <strong>{el?.price_list}</strong>
                                </TableCell>
                                <TableCell>
                                    <strong>
                                        <CurrencyFormatter
                                            locale="fr-FR"
                                            currency={el?.currency}
                                            minimumFractionDigits={3}
                                            value={el?.price_list_rate}
                                        />
                                    </strong>
                                </TableCell>
                                <TableCell>
                                    <strong>
                                        <CurrencyFormatter
                                            locale="fr-FR"
                                            currency={el?.currency}
                                            minimumFractionDigits={3}
                                            value={el?.item_price_ttc || calculateTTC(el?.price_list_rate)}
                                        />
                                    </strong>
                                </TableCell>
                                <TableCell align="right" width={100}>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <IconButton disabled={disabled} onClick={() => handleEdit(i)}>
                                            <EditIcon color="primary" />
                                        </IconButton>
                                        <IconButton disabled={disabled} onClick={() => handleDelete(el)}>
                                            <DeleteIcon color="error" />
                                        </IconButton>
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                        {(open || itemPriceState?.length === 0) && !disabled && (
                            <TableRow key={999}>
                                <TableCell>
                                    <SelectForm
                                        fullwidth
                                        handleChange={handleChangeValues}
                                        value={row2Insert.price_list}
                                        name="price_list"
                                        data={priceLists}
                                        propToRender={'name'}
                                    />
                                </TableCell>
                                <TableCell>
                                    <OutlinedInput
                                        type="number"
                                        value={row2Insert.price_list_rate}
                                        onChange={handleChangeValues}
                                        name="price_list_rate"
                                        placeholder={t('products:price_ht')}
                                        inputProps={{
                                            min: 0,
                                            step: '0.001'
                                        }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <OutlinedInput
                                        type="number"
                                        value={row2Insert.item_price_ttc}
                                        onChange={handleChangeValues}
                                        name="item_price_ttc"
                                        placeholder={t('products:price_ttc')}
                                        inputProps={{
                                            min: 0,
                                            step: '0.001'
                                        }}
                                    />
                                </TableCell>
                                <TableCell align="right" width={100}>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <IconButton
                                            onClick={editingIndex !== null ? handleUpdate : handleInsert}
                                            disabled={!row2Insert.price_list}
                                        >
                                            <DoneIcon color={!row2Insert.price_list ? 'disabled' : 'primary'} />
                                        </IconButton>
                                        <IconButton onClick={handleClose}>
                                            <DeleteIcon color="error" />
                                        </IconButton>
                                    </div>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                {hasUnsavedChanges && (
                    <Box display={'flex'} justifyContent={'flex-end'} mt={2}>
                        <LoadingButton variant="contained" color="blue" loading={loadingButton} onClick={handleSubmit} disabled={disabled}>
                            {t('columns:save')}
                        </LoadingButton>
                    </Box>
                )}
            </Box>
        </div>
    );
}

export default ItemPriceForm;
