import { useEffect, useState } from 'react';
import React from 'react';
// material-ui
import { Grid } from '@mui/material';

// project imports
import EarningCard from './Default/EarningCard';
import TotalIncomeDarkCard from './Default/TotalIncomeDarkCard';
import TotalIncomeLightCard from './Default/TotalIncomeLightCard';
import { gridSpacing } from 'store/constant';
import useFetch from 'hooks/useFetch';
import { useTranslation } from 'react-i18next';
// ==============================|| DEFAULT DASHBOARD ||============================== //

const CoreDashboard = () => {
    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const [isLoading, setLoading] = useState(true);
    const [getResult, setGetResult] = useState(0);
    const [percentage, setPercentage] = useState(0);
    const [getSupplResult, setGetSupplResult] = useState(0);
    const [SupplPercentage, setSupplPercentage] = useState(0);
    const [getMgResult, setGetMgResult] = useState(0);
    const [mgPercentage, setMgPercentage] = useState(0);
    const { t, i18n } = useTranslation();

    const reqWarehouse = {
        doc: {
            name: 'Total Warehouses',
            owner: 'Administrator',
            docstatus: 0,
            idx: 0,
            is_standard: 1,
            module: 'Stock',
            label: 'Total Warehouses',
            type: 'Document Type',
            function: 'Count',
            document_type: 'Warehouse',
            is_public: 1,
            show_percentage_stats: 1,
            stats_time_interval: 'Monthly',
            doctype: 'Number Card'
        },
        filters: [
            // ['Warehouse', 'disabled', '=', '0'],
        ]
    };

    const req = {
        doc: {
            name: 'Active Customers',
            owner: 'Administrator',
            idx: 0,
            docstatus: 0,
            is_standard: 1,
            module: 'Selling',
            label: 'Active Customers',
            type: 'Document Type',
            function: 'Count',
            document_type: 'Customer',
            is_public: 1,
            show_percentage_stats: 1,
            stats_time_interval: 'Monthly',
            filters_json: '[["Customer","disabled","=","0"]]',
            dynamic_filters_json: '',
            doctype: 'Number Card'
        },
        filters: [['Customer', 'disabled', '=', '0']]
    };

    const reqSupplier = {
        doc: {
            name: 'Active Suppliers',
            owner: 'Administrator',
            modified_by: 'Administrator',
            docstatus: 0,
            idx: 0,
            is_standard: 1,
            module: 'Buying',
            label: 'Active Suppliers',
            type: 'Document Type',
            function: 'Count',
            document_type: 'Supplier',
            is_public: 1,
            show_percentage_stats: 1,
            stats_time_interval: 'Monthly',
            doctype: 'Number Card'
        },
        filters: [['Supplier', 'disabled', '=', '0']]
    };

    const fetchSupplier = () => {
        fetch(`${process.env.REACT_APP_API_URI}/api/get-result-number`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(reqSupplier)
        })
            .then((res) => {
                if (!res.ok) {
                    setLoading(false);
                    throw Error('Something went wrong');
                }
                return res.json();
            })
            .then((data) => {
                setGetSupplResult(data?.message);
                if (data?.message != '') {
                    fetch(`${process.env.REACT_APP_API_URI}/api/get-percentage`, {
                        method: 'POST',
                        headers: header,
                        body: JSON.stringify({ ...reqSupplier, result: data?.message })
                    })
                        .then((res) => {
                            if (!res.ok) {
                                throw Error('Something went wrong');
                            }
                            return res.json();
                        })
                        .then((data) => {
                            setSupplPercentage(data?.message);
                            setLoading(false);
                        });
                }
            });
    };
    const fetchWarehouse = () => {
        fetch(`${process.env.REACT_APP_API_URI}/api/get-result-number`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(reqWarehouse)
        })
            .then((res) => {
                if (!res.ok) {
                    setLoading(false);
                    throw Error('Something went wrong');
                }
                return res.json();
            })
            .then((data) => {
                setGetMgResult(data?.message);
                if (data?.message != '') {
                    fetch(`${process.env.REACT_APP_API_URI}/api/get-percentage`, {
                        method: 'POST',
                        headers: header,
                        body: JSON.stringify({ ...reqWarehouse, result: data?.message })
                    })
                        .then((res) => {
                            if (!res.ok) {
                                throw Error('Something went wrong');
                            }
                            return res.json();
                        })
                        .then((data) => {
                            setMgPercentage(data?.message);
                            setLoading(false);
                        });
                }
            });
    };
    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URI}/api/get-result-number`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(req)
        })
            .then((res) => {
                if (!res.ok) {
                    setLoading(false);
                    throw Error('Something went wrong');
                }
                return res.json();
            })
            .then((data) => {
                setGetResult(data?.message);
                if (data?.message != '') {
                    fetch(`${process.env.REACT_APP_API_URI}/api/get-percentage`, {
                        method: 'POST',
                        headers: header,
                        body: JSON.stringify({ ...req, result: data?.message })
                    })
                        .then((res) => {
                            if (!res.ok) {
                                throw Error('Something went wrong');
                            }
                            return res.json();
                        })
                        .then((data) => {
                            setPercentage(data?.message);
                            setLoading(false);
                        });
                }
            });
        fetchSupplier();
        fetchWarehouse();
    }, []);

    const poslistReqData = {
        doctype: 'POS Profile',
        fields: ['`tabPOS Profile`.`name`', '`tabPOS Profile`.`warehouse`', '`tabPOS Profile`.`company`'],
        filters: [],
        start: 0,
        page_length: 500
    };
    const { data: posProfile, isPending: isPendingPosProfile } = useFetch(`/api/get-list`, poslistReqData, 'POST');

    const UserData = {
        doctype: 'User',
        fields: ['`tabUser`.`creation`', '`tabUser`.`name`', '`tabUser`.`full_name`', '`tabUser`.`enabled`'],
        filters: [['User', 'enabled', '=', 1]],
        start: 0,
        page_length: 500
    };
    const { data, isPending } = useFetch(`/api/get-list`, UserData, 'POST');
    const currencyReqData = {
        doctype: 'Currency',
        fields: ['`tabCurrency`.`name`', '`tabCurrency`.`creation`', '`tabCurrency`.`enabled`', '`tabCurrency`.`symbol`'],
        filters: [['Currency', 'enabled', '=', 1]],
        start: 0,
        page_length: 500
    };

    const { data: currency, isPending: isPendingCurrency } = useFetch(`/api/get-list`, currencyReqData, 'POST');
    const paymentModeReqData = {
        doctype: 'Mode of Payment',
        fields: ['`tabMode of Payment`.`name`', '`tabMode of Payment`.`mode_of_payment`', '`tabMode of Payment`.`enabled`'],
        filters: [['Mode of Payment', 'enabled', '=', 1]],
        start: 0,
        page_length: 500
    };

    const { data: modePayment, isPending: isPendingPayment } = useFetch(`/api/get-list`, paymentModeReqData, 'POST');

    const profileReqData = {
        doctype: 'Role Profile',
        fields: ['`tabRole Profile`.`creation`', '`tabRole Profile`.`role_profile`', '`tabRole Profile`.`name`'],
        start: 0,
        page_length: 500
    };

    const { data: roleProfile, isPending: isPendingRoleProfile } = useFetch(`/api/get-list`, profileReqData, 'POST');

    const rolesReqData = {
        doctype: 'Role',
        fields: ['`tabRole`.`name`', '`tabRole`.`is_custom`', '`tabRole`.`desk_access`', '`tabRole`.`disabled`'],
        start: 0,
        page_length: 500
    };
    const { data: role, isPending: isPendingRole } = useFetch(`/api/get-list`, rolesReqData, 'POST');

    const POSOpeningData = {
        doctype: 'POS Opening Entry',
        fields: [
            '`tabPOS Opening Entry`.`name`',
            '`tabPOS Opening Entry`.`owner`',
            '`tabPOS Opening Entry`.`creation`',
            '`tabPOS Opening Entry`.`period_start_date`',
            '`tabPOS Opening Entry`.`period_end_date`',
            '`tabPOS Opening Entry`.`status`',
            '`tabPOS Opening Entry`.`posting_date`',
            '`tabPOS Opening Entry`.`pos_profile`'
        ],
        filters: [['POS Opening Entry', 'status', '=', 'Open']],
        start: 0,
        page_length: 500
    };

    const { data: posOpening, isPending: isPendingPosOpening } = useFetch(`/api/get-list`, POSOpeningData, 'POST');

    const companyReqData = {
        doctype: 'Company',
        fields: [
            '`tabCompany`.`name`',
            '`tabCompany`.`docstatus`',
            '`tabCompany`.`country`',
            '`tabCompany`.`parent_company`',
            '`tabCompany`.`monthly_sales_target`',
            '`tabCompany`.`total_monthly_sales`',
            '`tabCompany`.`credit_limit`',
            '`tabCompany`.`company_logo`',
            '`tabCompany`.`default_currency`'
        ],
        start: 0,
        page_length: 500
    };
    const { data: company, isPending: isPendingCompany } = useFetch(`/api/get-list`, companyReqData, 'POST');

    const ttlItemTaxTemplateReqData = {
        doctype: 'Item Tax Template',
        fields: ['`tabItem Tax Template`.`name`'],
        distinct: false
    };
    const { data: ttlItemTaxTemplate, isPending: isTtlItemTaxTemplatePending } = useFetch(
        `api/get-list-count`,
        ttlItemTaxTemplateReqData,
        'POST'
    );
    const ttlSalesPersonReqData = {
        doctype: 'Sales Person',
        fields: ['`tabSales Person`.`name`'],
        distinct: false
    };
    const { data: ttlSalesPerson, isPending: isTtlSalesPersonPending } = useFetch(`api/get-list-count`, ttlSalesPersonReqData, 'POST');
    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
                <Grid container spacing={gridSpacing}>
                    <Grid item lg={4} md={6} sm={6} xs={12}>
                        <EarningCard
                            title={t('common:activeClients')}
                            label={t('crm:customers')}
                            isLoading={isLoading}
                            getResult={getResult ?? getResult}
                            percentage={percentage ?? percentage}
                            isFr
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={6} xs={12}>
                        <EarningCard
                            title={t('core:actsupp')}
                            label={t('core:supps')}
                            isLoading={isLoading}
                            getResult={getSupplResult ?? getSupplResult}
                            percentage={SupplPercentage ?? SupplPercentage}
                            isFr
                        />
                    </Grid>
                    <Grid item lg={4} md={12} sm={12} xs={12}>
                        <Grid container spacing={gridSpacing}>
                            <Grid item sm={6} xs={12} md={6} lg={12}>
                                <TotalIncomeDarkCard
                                    title={t('core:pos')}
                                    isLoading={isPendingPosProfile}
                                    getResult={posProfile?.length ?? posProfile?.length}
                                />
                            </Grid>
                            <Grid item sm={6} xs={12} md={6} lg={12}>
                                <TotalIncomeLightCard
                                    title={t('core:stores')}
                                    isLoading={isLoading}
                                    getResult={getMgResult ?? getMgResult}
                                    percentage={mgPercentage ?? mgPercentage}
                                    isFr
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={gridSpacing}>
                    <Grid item lg={4} md={6} sm={6} xs={12}>
                        <EarningCard
                            noPercentage
                            title={t('core:actusers')}
                            label={t('core:users')}
                            isLoading={isPending}
                            getResult={data?.length ?? data?.length}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={6} xs={12}>
                        <EarningCard
                            noPercentage
                            title={t('core:actcurr')}
                            label={t('core:crr')}
                            isLoading={isPendingCurrency}
                            getResult={currency?.length ?? currency?.length}
                        />
                    </Grid>

                    <Grid item lg={4} md={12} sm={12} xs={12}>
                        <Grid container spacing={gridSpacing}>
                            <Grid item sm={6} xs={12} md={6} lg={12}>
                                <TotalIncomeDarkCard
                                    payment
                                    title={t('core:actpm')}
                                    isLoading={isPendingPayment}
                                    getResult={modePayment?.length ?? modePayment?.length}
                                />
                            </Grid>
                            <Grid item sm={6} xs={12} md={6} lg={12}>
                                <TotalIncomeLightCard
                                    noPercentage
                                    title={t('core:taxmod')}
                                    isLoading={isTtlItemTaxTemplatePending}
                                    getResult={ttlItemTaxTemplate ?? ttlItemTaxTemplate}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={gridSpacing}>
                    <Grid item lg={4} md={6} sm={6} xs={12}>
                        <EarningCard
                            noPercentage
                            label={t('core:profil')}
                            isLoading={isPendingRoleProfile}
                            getResult={roleProfile?.length ?? roleProfile?.length}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={6} xs={12}>
                        <EarningCard
                            noPercentage
                            label={t('core:role')}
                            isLoading={isPendingRole}
                            getResult={role?.length ?? role?.length}
                        />
                    </Grid>

                    <Grid item lg={4} md={12} sm={12} xs={12}>
                        <Grid container spacing={gridSpacing}>
                            <Grid item sm={6} xs={12} md={6} lg={12}>
                                <TotalIncomeDarkCard
                                    title={t('core:onlineusers')}
                                    isLoading={isPendingPosOpening}
                                    getResult={posOpening?.length ?? posOpening?.length}
                                />
                            </Grid>
                            <Grid item sm={6} xs={12} md={6} lg={12}>
                                <TotalIncomeLightCard
                                    noPercentage
                                    title={t('core:companies')}
                                    isLoading={isPendingCompany}
                                    getResult={company?.length ?? company?.length}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={gridSpacing}>
                    <Grid item lg={4} md={6} sm={6} xs={12}>
                        <EarningCard
                            noPercentage
                            label={t('core:sp')}
                            isLoading={isTtlSalesPersonPending}
                            getResult={ttlSalesPerson ?? ttlSalesPerson}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={6} xs={12}>
                        {/* <EarningCard  /> */}
                    </Grid>

                    <Grid item lg={4} md={12} sm={12} xs={12}>
                        <Grid container spacing={gridSpacing}>
                            <Grid item sm={6} xs={12} md={6} lg={12}>
                                {/*  <TotalIncomeDarkCard
                                   
                                /> */}
                            </Grid>
                            <Grid item sm={6} xs={12} md={6} lg={12}>
                                {/*  <TotalIncomeLightCard
                                  
                                /> */}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default CoreDashboard;
