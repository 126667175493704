import axios from 'axios';
import { useState, useEffect, useCallback } from 'react';

const useReportQuery = (url, reqData) => {
    const [data, setData] = useState([]); // Initial state as an empty array
    const [isPending, setIsPending] = useState(true);
    const [error, setError] = useState(null);

    // Memoized fetchData function to avoid unnecessary re-renders
    const fetchData = useCallback(async () => {
        setIsPending(true);
        try {
            const response = await axios({
                method: 'post',
                url: url,
                data: reqData,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-API-Key': process.env.REACT_APP_API_KEY,
                    Authorization: JSON.parse(localStorage.getItem('user'))?.token
                }
            });
            setData(response.data?.message || []);
            setIsPending(false);
            setError(null);
        } catch (err) {
            setIsPending(false);
            setError(err.message);
        }
    }, [url, reqData]); // Only recreate if url or reqData change

    useEffect(() => {
        if (url && reqData) {
            fetchData(); // Fetch data when url or reqData changes
        }
    }, [url, reqData, fetchData]);

    return { data, isPending, error, refetch: fetchData }; // Return refetch function
};

export default useReportQuery;
