import useFetch from 'hooks/useFetch';
import React, { useEffect, useState } from 'react';
import StoreIcon from '@mui/icons-material/Store';
import PaymentsIcon from '@mui/icons-material/Payments';
import InventoryIcon from '@mui/icons-material/Inventory';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import TotalIncomeDarkCard from 'views/dashboard/Default/TotalIncomeDarkCard';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import OutOfStockDarkCard from 'views/dashboard/Default/OutOfStockDarkCard';

function PIMChips() {
    const { t } = useTranslation();
    const globalDefaults = useSelector((state) => state.login.globalDefaults);

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const activeItemsReqData = {
        doc: {
            name: 'Total Active Items',
            is_standard: 1,
            module: 'Stock',
            label: 'Total Active Items',
            type: 'Document Type',
            function: 'Count',
            document_type: 'Item',
            is_public: 1,
            show_percentage_stats: 1,
            stats_time_interval: 'Monthly',
            filters_json: '[["Item","disabled","=",0]]',
            dynamic_filters_json: '',
            doctype: 'Number Card'
        },
        filters: [['Item', 'disabled', '=', '0']]
    };

    const ttlWarehousesReqData = {
        doc: {
            name: 'Total Warehouses',
            is_standard: 1,
            module: 'Stock',
            label: 'Total Warehouses',
            type: 'Document Type',
            function: 'Count',
            document_type: 'Warehouse',
            is_public: 1,
            show_percentage_stats: 1,
            stats_time_interval: 'Monthly',
            filters_json: '[["Warehouse","disabled","=",0]]',
            doctype: 'Number Card'
        },
        filters: [['Warehouse', 'disabled', '=', 0]]
    };

    const stockValueReqData = {
        doc: {
            name: 'Total Stock Value',
            is_standard: 1,
            module: 'Stock',
            label: 'Total Stock Value',
            type: 'Document Type',
            function: 'Sum',
            aggregate_function_based_on: 'stock_value',
            document_type: 'Bin',
            is_public: 1,
            show_percentage_stats: 1,
            stats_time_interval: 'Daily',
            filters_json: '[]',
            doctype: 'Number Card'
        },
        filters: []
    };

    const ttlPOSReqData = {
        doc: {
            name: 'Total POSs',
            is_standard: 1,
            module: 'Accounting',
            label: 'Total POSs',
            type: 'Document Type',
            function: 'Count',
            document_type: 'POS Profile',
            is_public: 1,
            show_percentage_stats: 1,
            stats_time_interval: 'Monthly',
            filters_json: '[["POS Profile","disabled","=",0]]',
            doctype: 'Number Card'
        },
        filters: [
            ['POS Profile', 'disabled', '=', 0],
            ['POS Profile', 'company', '=', globalDefaults?.default_company]
        ]
    };

    const [activeItemsPrcntg, setActiveItemsPrcntg] = useState(0);
    const [ttlWarehousesPrcntg, setTtlWarehousesPrcntg] = useState(0);
    const [stockValuePrcntg, setStockValuePrcntg] = useState(0);

    const { data: activeItems, isPending: isActiveItemsPending } = useFetch(`/api/get-result-number`, activeItemsReqData, 'POST');

    const { data: warehouses, isPending: isWarehousesPending } = useFetch(`/api/get-result-number`, ttlWarehousesReqData, 'POST');

    const { data: stockValue, isPending: isStockValuePending } = useFetch(`/api/get-result-number`, stockValueReqData, 'POST');

    const { data: POSs, isPending: isPOSsPending } = useFetch(`/api/get-result-number`, ttlPOSReqData, 'POST');

    const [reqData, setReqData] = useState({
        doctype: 'Sales Order',
        fields: [
            '`tabSales Order`.`creation`',
            '`tabSales Order`.`name`',
            '`tabSales Order`.`status`',
            '`tabSales Order`.`grand_total`',
            '`tabSales Order`.`currency`',
            '`tabSales Order`.`total_qty`',
            '`tabSales Order`.`reason`'
        ],
        filters: [
            ['Sales Order', 'company', '=', globalDefaults?.default_company],
            ['Sales Order', 'transaction_date', 'Timespan', 'this year']
        ]
    });

    const {
        data: salesOrders,
        isPending: isSOPending,
        refetch
    } = useFetch(`${process.env.REACT_APP_API_URI}/api/get-list`, reqData, 'POST');

    useEffect(() => {
        if (activeItems && !Array.isArray(activeItems)) {
            fetch(`${process.env.REACT_APP_API_URI}/api/get-percentage`, {
                method: 'POST',
                headers: header,
                body: JSON.stringify({ ...activeItemsReqData, result: activeItems })
            })
                .then((res) => {
                    if (!res.ok) {
                        throw Error('Something went wrong');
                    }
                    return res.json();
                })
                .then((data) => {
                    setActiveItemsPrcntg(data?.message);
                });
        }
    }, [activeItems]);

    useEffect(() => {
        if (warehouses && !Array.isArray(warehouses)) {
            fetch(`${process.env.REACT_APP_API_URI}/api/get-percentage`, {
                method: 'POST',
                headers: header,
                body: JSON.stringify({ ...ttlWarehousesReqData, result: warehouses })
            })
                .then((res) => {
                    if (!res.ok) {
                        throw Error('Something went wrong');
                    }
                    return res.json();
                })
                .then((data) => {
                    setTtlWarehousesPrcntg(data?.message);
                });
        }
    }, [warehouses]);

    useEffect(() => {
        if (stockValue && !Array.isArray(stockValue)) {
            fetch(`${process.env.REACT_APP_API_URI}/api/get-percentage`, {
                method: 'POST',
                headers: header,
                body: JSON.stringify({ ...stockValueReqData, result: stockValue })
            })
                .then((res) => {
                    if (!res.ok) {
                        throw Error('Something went wrong');
                    }
                    return res.json();
                })
                .then((data) => {
                    setStockValuePrcntg(data?.message);
                });
        }
    }, [stockValue]);

    return (
        <Grid container spacing={2} marginBottom={2}>
            <Grid item xs={12} md={4}>
                <TotalIncomeDarkCard
                    icon={<InventoryIcon />}
                    title={t('dashboard:tai')}
                    result={activeItems}
                    percentage={activeItemsPrcntg}
                    isLoading={isActiveItemsPending}
                    since={t('dashboard:slm')}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <TotalIncomeDarkCard
                    icon={<StoreIcon />}
                    title={t('dashboard:tw')}
                    result={warehouses}
                    percentage={ttlWarehousesPrcntg}
                    isLoading={isWarehousesPending}
                    since={t('dashboard:slm')}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <TotalIncomeDarkCard
                    icon={<PaymentsIcon />}
                    isCurr
                    title={t('dashboard:tsv')}
                    result={stockValue}
                    percentage={stockValuePrcntg}
                    isLoading={isStockValuePending}
                    since={t('dashboard:sy')}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <TotalIncomeDarkCard icon={<ReceiptLongIcon />} title={t('dashboard:tp')} result={POSs} isLoading={isPOSsPending} />
            </Grid>
            <Grid item xs={12} md={4}>
                <OutOfStockDarkCard
                    icon={<InventoryIcon />}
                    title={t('dashboard:outOfStock')}
                    isLoading={isSOPending}
                    doc={salesOrders}
                    reqData={reqData}
                    setReqData={setReqData}
                    refetch={refetch}
                />
            </Grid>
        </Grid>
    );
}

export default PIMChips;
