import React, { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import ItemGroupDetails from 'views/products/category/ItemGroupDetails';
import ItemGroupList from 'views/products/category/ItemGroupList';
import AddCategoryForm from 'views/products/category/AddCategoryForm';
import ItemAttributesList from 'views/products/itemAttributes/ItemAttributesList';
import AddItemAttributeForm from 'views/products/itemAttributes/AddItemAttributeForm';
import ItemGroupTree from 'views/products/category/ItemGroupTree';
import AddItemTemplateForm from 'views/products/productManagement/AddItemTemplateForm';
import TemplateProdDetails from 'views/products/productManagement/components/TemplateProdDetails';
import EditUOMForm from '../views/uom/EditUom';
import SousFamilleProductEdit from '../views/products/familleProduct/sousFamille/SousFamilleProduitEdit';
import SousFamilleProductAdd from '../views/products/familleProduct/sousFamille/SousFamilleProductAdd';
import SousFamilleProductList from '../views/products/familleProduct/sousFamille/SousFamilleProductList';
import OnlineUsers from '../views/profile/users/OnlineUsers';
import QuotationForm from '../views/quotations/components/QuotationForm';
import Settings from 'views/settings/Settings';
import CustomerGroupDetails from 'views/crm/category/CustomerGroupDetails';
import CustomerGroupForm from 'views/crm/category/CustomerGroupForm';
import CustomerGroupList from 'views/crm/category/CustomerGroupList';
import CustomerGroupTree from 'views/crm/category/CustomerGroupTree';
import CustomerDetails from 'views/crm/customer/CustomerDetails';
import CustomerList from 'views/crm/customer/CustomerList';
import LoyaltyCardForm from 'views/crm/loyaltyCard/LoyaltyCardForm';
import LoyaltyCardList from 'views/crm/loyaltyCard/LoyaltyCardList';
import LoyaltyProgramForm from 'views/crm/loyatlyProgram/LoyaltyProgramForm';
import LoyaltyProgramList from 'views/crm/loyatlyProgram/LoyaltyProgramList';
import PricingRuleForm from 'views/crm/pricingRules/PricingRuleForm';
import PricingRulesList from 'views/crm/pricingRules/PricingRulesList';
import AddCouponCode from 'views/crm/pricingRules/codeCoupon/AddCouponCode';
import CouponCodeList from 'views/crm/pricingRules/codeCoupon/CouponCodeList';
import CoreDashboard from 'views/dashboard/CoreDashboard';
import AddNewDepot from 'views/depots/AddNewDepot';
import DepotTree from 'views/depots/DepotTree';
import DepotsList from 'views/depots/DepotsList';
import Discount from 'views/discount/Discount';

import DiscountForm from 'views/discount/DiscountForm';
import BuyingDocumentsDetails from 'views/documents/BuyingDocumentsDetails';
import BuyingDocumentsList from 'views/documents/BuyingDocumentsList';
import InventoryDocumentsDetails from 'views/documents/InventoryDocumentsDetails';
import InventoryDocumentsList from 'views/documents/InventoryDocumentsList';
import POSDocumentsDetails from 'views/documents/POSDocumentsDetails';
import POSDocumentsList from 'views/documents/POSDocumentsList';
import SalesDocumentsDetails from 'views/documents/SalesDocumentsDetails';
import SalesDocumentsList from 'views/documents/SalesDocumentsList';
import CoffreOperationDetails from 'views/documents/components/CoffreOperationDetails';
import BonLivraisonForm from 'views/facturation/BonLivraisonForm';
import AllFilesList from 'views/files/AllFilesList';
import FOMForm from 'views/fom/FOMForm';
import FOMFormEdit from 'views/fom/FOMFormEdit';
import FOMList from 'views/fom/FOMList';
import SalesInvoiceList from 'views/invoices/sales/SalesInvoice';
import SalesInvoiceForm from 'views/invoices/sales/SalesInvoice/components/SalesInvoiceForm';
import DepositInvoiceList from 'views/invoices/sales/depositInvoice';
import CustomizeFormItem from 'views/products/customizeForm/CustomizeFormItem';
import FamilleProductAdd from 'views/products/familleProduct/FamilleProductAdd';
import FamilleProductList from 'views/products/familleProduct/FamilleProductList';
import FamilleProductEdit from 'views/products/familleProduct/FamilleProduitEdit';
import ItemAttributeEdit from 'views/products/itemAttributes/ItemAttributeEdit';
import ItemChannelsForm from 'views/products/itemChannels/ItemChannelsForm';
import ItemCollectionEdit from 'views/products/itemcollection/ItemCollectionEdit';
import ItemCollectionForm from 'views/products/itemcollection/ItemCollectionForm';
import ItemCollectionList from 'views/products/itemcollection/ItemCollectionList';
import DashboardPIM from 'views/products/pimDashboard/DashboardPIM';
import ProductBundleForm from 'views/products/productBundle/ProductBundleForm';
import ProductBundleList from 'views/products/productBundle/ProductBundleList';
import BulkEditForm from 'views/products/productManagement/components/BulkEditForm';
import AddItemVariantTemplateForm from 'views/products/productManagement/productVariant/AddItemVariantTemplateForm';
import TemplateProdVariantDetails from 'views/products/productManagement/productVariant/TemplateProdVariantDetais';
import ProductPrestationList from 'views/products/productPrestation/productPrestation/ProductPrestationList';
import ServicePrestationAdd from 'views/products/productPrestation/service/ServicePrestationAdd';
import ServicePrestationEdit from 'views/products/productPrestation/service/ServicePrestationEdit';
import ServicePrestationList from 'views/products/productPrestation/service/ServicePrestationList';
import SalesOrderForm from 'views/purchaseOrder/components/SalesOrderForm';
import AddNewQrcode from 'views/qrCode/AddNewQrCode';
import EditQrcode from 'views/qrCode/EditQrCode';
import AddSalesPerson from 'views/salesPerson/AddSalesPerson';
import EditSalesPerson from 'views/salesPerson/EditSalesPerson';
import SalesPersonList from 'views/salesPerson/SalesPersonList';
import SalesPersonTree from 'views/salesPerson/SalesPersonTree';
import SupplierList from 'views/supplier';
import SuppliersForm from 'views/supplier/SupplierForm';
import { AddSupplierContact } from 'views/supplier/component/AddSupplierContact';
import SupplierForm from 'views/supplier/component/SupplierForm';
import EditSupplierCatalog from 'views/supplier/supplierCatalog/EditSupplierCatalog';
import SupplierCatalog from 'views/supplier/supplierCatalog/SupplierCatalog';
import BuyingSettings from 'views/buyingsettings/BuyingSettings';
import BarcodeSettings from 'views/barcodesettings/BarcodeSettings';
import SupplierCodeSettings from 'views/supplier/suppliercodesettings/SupplierCodeSettings';
import UOMForm from 'views/uom/UOMForm';
import UOMList from 'views/uom/UOMList';
import ProductFinancierList from '../views/products/ProductFinancier/ProductFinancierList';
import ProductFinancierAdd from '../views/products/ProductFinancier/ProductFinancierAdd';
import ProductFinancierEdit from '../views/products/ProductFinancier/ProductFinancierEdit';

import SousTableLibre from 'views/soustablelibre/SousTableLibre';
import SousTablelibreForm from 'views/soustablelibre/SousTableLibreForm';
import Libre from 'views/libre/Libre';
import TablelibreForm from 'views/libre/LibreForm';
import ProductNamingSeries from 'views/ProductPrefix/ProductNamingSeries';
import BrandForm from 'views/brand/BrandForm';
import Brand from 'views/brand/BrandList';
import PromotionalScheme from 'views/promotionalscheme/PromotionalScheme';
import PromotionalSchemeForm from 'views/promotionalscheme/PromotionalSchemeForm';
import Tarifs from 'views/tarifs/Tarifs';
import AddTarif from 'views/tarifs/AddTarif';
import EditTarif from 'views/tarifs/EditTarif';
import PriceList from 'views/pricelist/priceList';
import PriceListForm from 'views/pricelist/priceListForm';
import EditTarifachat from 'views/pricelist/pricelistedit';
import OmsDashboard from 'views/dashboard/OmsDashboard';
import TarifPricingRuleForm from 'views/pricelist/pricesrules/PricingRuleForm';
import TarifPricingRulesList from 'views/pricelist/pricesrules/PricingRulesList';
import AddCouponCodeTarif from 'views/pricelist/pricesrules/codeCoupon/AddCouponCode';
import CouponCodeListTarif from 'views/pricelist/pricesrules/codeCoupon/CouponCodeList';
import ItemPriceForm from '../views/itemPrice/ItemPriceForm';
import AddItemPrestationForm from '../views/products/productManagement/AddItemPrestationForm';
import ServiceProdDetails from '../views/products/productManagement/components/ServiceProdDetails';
import CatgoryItemList from '../views/products/category/CatgoryItemList';
import EtiquetteBarcode from 'views/products/etiquetteBarcode/EtiquetteBarcode';
import AddItemBundleForm from '../views/products/productBundle/AddItemBundleForm';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));
const PimProducts = Loadable(lazy(() => import('views/products/index')));
const PimProductsVariant = Loadable(lazy(() => import('views/products/productManagement/productVariant/ListProductVariant')));
const PimProductsModele = Loadable(lazy(() => import('views/products/productManagement/productModele/ListProductTemplate')));
const WarehouseTree = Loadable(lazy(() => import('views/warehouse/WarehouseTree')));
// sample page routing
const PosProfile = Loadable(lazy(() => import('views/profile')));
const AddNewProfile = Loadable(lazy(() => import('views/profile/AddNewProfile')));
const Warehouse = Loadable(lazy(() => import('views/warehouse')));
const AddNewWarehouse = Loadable(lazy(() => import('views/warehouse/AddNewWarehouse')));
const RoleDetails = Loadable(lazy(() => import('views/profile/RoleDetails/RoleDetails')));
const UserList = Loadable(lazy(() => import('views/profile/users/UserList')));
const AddNewCompany = Loadable(lazy(() => import('views/company/AddNewCompany')));
const CompanyList = Loadable(lazy(() => import('views/company/CompanyList')));
const CompanyTree = Loadable(lazy(() => import('views/company/CompanyTree')));
const RolesList = Loadable(lazy(() => import('views/roles/RolesList')));
const AddNewRoles = Loadable(lazy(() => import('views/roles/AddNewRoles')));

const AddNewUser = Loadable(lazy(() => import('views/profile/users/AddNewUser')));

const AddNewPOS = Loadable(lazy(() => import('views/Pointofsale/AddNewPOS')));
const POSForm = Loadable(lazy(() => import('views/Pointofsale/POSForm')));
const POSList = Loadable(lazy(() => import('views/Pointofsale/POSList')));
const HistoryPosProfile = Loadable(lazy(() => import('views/Pointofsale/HistoryPosProfile')));
const UserConnectedList = Loadable(lazy(() => import('views/profile/userConnected/UserConnectedList')));
const PaymentMethodsList = Loadable(lazy(() => import('views/paymentMethods/PaymentMethodsList')));
const AddPaymentMethodsForm = Loadable(lazy(() => import('views/paymentMethods/AddPaymentMethodsForm')));
const AccountSettings = Loadable(lazy(() => import('views/accountSetting/AccountSettings')));
const CurrenciesList = Loadable(lazy(() => import('views/currencies/CurrenciesList')));
const AddCurrencies = Loadable(lazy(() => import('views/currencies/AddCurrencies')));
const TaxesList = Loadable(lazy(() => import('views/taxes/TaxesList')));
const AddTaxes = Loadable(lazy(() => import('views/taxes/AddTaxes')));
const AddNewChannel = Loadable(lazy(() => import('views/canaux/AddNewChannel')));
const CanauxList = Loadable(lazy(() => import('views/canaux/CanauxList')));
const MyStock = Loadable(lazy(() => import('views/stock/index')));
const MyStockDetails = Loadable(lazy(() => import('views/stock/stockDetails/index')));
const QuotationList = Loadable(lazy(() => import('views/quotations/index')));
const SalesOrderList = Loadable(lazy(() => import('views/purchaseOrder/index')));
const FacturationList = Loadable(lazy(() => import('views/facturation/index')));
const SalesDashboard = Loadable(lazy(() => import('views/SalesDashboard/SalesDashboard')));
/***************[520]**************************** */
const StoresList = Loadable(lazy(() => import('views/salesbystore/StoresList')));
const SalesList = Loadable(lazy(() => import('views/salesbystore/SalesList')));
const DefaultDashboard = Loadable(lazy(() => import('views/dashboard/DefaultDashboard')));
const QrCode = Loadable(lazy(() => import('views/qrCode/index')));
const Change_CurrenciesList = Loadable(lazy(() => import('views/taux_de_change/Change_CurrenciesList')));
const Change_Currencies_Form = Loadable(lazy(() => import('views/taux_de_change/Add_Change_Currencies')));
const SupplierGroupList = Loadable(lazy(() => import('views/supplier/category/SupplierGroupList')));
const SupplierGroupForm = Loadable(lazy(() => import('views/supplier/category/SupplierGroupForm')));
const SupplierGroupDetails = Loadable(lazy(() => import('views/supplier/category/SupplierGroupDetails')));
const SupplierGroupTree = Loadable(lazy(() => import('views/supplier/category/SupplierGroupTree')));
const SellingSettings = Loadable(lazy(() => import('views/settings/SellingSettings')));
const CategoryTaxesList = Loadable(lazy(() => import('views/taxes/category/CategoryTaxeList')));
const AddCategoryTaxe = Loadable(lazy(() => import('views/taxes/category/AddCategoryTaxe')));
const ItemPriceList = Loadable(lazy(() => import('views/itemPrice/ItemPriceLists')));
const BulkEditSuppForm = Loadable(lazy(() => import('views/supplier/component/BulkEditSuppForm')));
const BulkEditUomForm = Loadable(lazy(() => import('views/uom/component/BulkEditUomForm')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <SalesDashboard />
        },
        {
            path: 'home',
            element: <SalesDashboard />
        },
        {
            path: 'online',
            children: [
                {
                    path: 'users',
                    element: <OnlineUsers />
                }
            ]
        },
        {
            path: 'sales',
            children: [
                {
                    path: 'dashboard',
                    element: <SalesDashboard />
                },
                {
                    path: 'stock-view',
                    element: <MyStock />
                },
                {
                    path: 'stock/:id',
                    element: <MyStockDetails />
                },
                {
                    path: 'quotation-list',
                    element: <QuotationList />
                },
                {
                    path: 'quotation/:id',
                    element: <QuotationForm />
                },
                {
                    path: 'quotation',
                    element: <QuotationForm />
                },
                {
                    path: 'sales-order-list',
                    element: <SalesOrderList />
                },
                {
                    path: 'sales-order/:id',
                    element: <SalesOrderForm />
                },
                {
                    path: 'sales-order',
                    element: <SalesOrderForm />
                },
                {
                    path: 'delivery-notes',
                    element: <FacturationList />
                },
                {
                    path: 'stores',
                    element: <StoresList />
                },
                {
                    path: 'bystore/:idstore/:idcompany',
                    element: <SalesList />
                },
                {
                    path: 'delivery-note',
                    element: <BonLivraisonForm />
                },
                {
                    path: 'delivery-note/:id',
                    element: <BonLivraisonForm />
                },
                {
                    path: 'tax-invoice-list',
                    element: <SalesInvoiceList />
                },
                {
                    path: 'deposit-invoice-list',
                    element: <DepositInvoiceList />
                },
                {
                    path: 'tax-invoice/:id',
                    element: <SalesInvoiceForm />
                },
                {
                    path: 'tax-invoice',
                    element: <SalesInvoiceForm />
                },
                {
                    path: 'customer-details/:id',
                    element: <CustomerDetails />
                },
                {
                    path: 'vente-settings',
                    element: <SellingSettings />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-typography',
                    element: <UtilsTypography />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-color',
                    element: <UtilsColor />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-shadow',
                    element: <UtilsShadow />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'tabler-icons',
                    element: <UtilsTablerIcons />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'material-icons',
                    element: <UtilsMaterialIcons />
                }
            ]
        },
        {
            path: 'core',
            children: [
                {
                    path: 'profiles',
                    element: <PosProfile />
                },
                {
                    path: 'new-profiles',
                    element: <AddNewProfile />
                },
                {
                    path: 'role-profile/:id',
                    element: <RoleDetails />
                },
                {
                    path: 'edit-role-profile/:id',
                    element: <AddNewProfile />
                },
                {
                    path: 'user-list-view',
                    element: <UserList />
                },
                {
                    path: 'new-user',
                    element: <AddNewUser />
                },
                {
                    path: 'edit-user/:id',
                    element: <AddNewUser />
                },
                {
                    path: 'roles',
                    element: <RolesList />
                },
                {
                    path: 'edit-roles/:id',
                    element: <AddNewRoles />
                },
                {
                    path: 'connected-user',
                    element: <UserConnectedList />
                },
                {
                    path: 'user/account-profile',
                    element: <AccountSettings />
                },
                {
                    path: 'mode-of-payment',
                    element: <PaymentMethodsList />
                },
                {
                    path: 'new-mode-of-payment',
                    element: <AddPaymentMethodsForm />
                },
                {
                    path: 'mode-of-payment/:id',
                    element: <AddPaymentMethodsForm />
                },
                {
                    path: 'currencies-list',
                    element: <CurrenciesList />
                },
                {
                    path: 'currencies',
                    element: <AddCurrencies />
                },
                {
                    path: 'currencies/:id',
                    element: <AddCurrencies />
                },
                {
                    path: 'taxes-list',
                    element: <TaxesList />
                },
                {
                    path: 'category-taxes-list',
                    element: <CategoryTaxesList />
                },
                {
                    path: 'new-category-taxe',
                    element: <AddCategoryTaxe />
                },
                {
                    path: 'category-taxe/:id',
                    element: <AddCategoryTaxe />
                },
                {
                    path: 'new-taxe',
                    element: <AddTaxes />
                },
                {
                    path: 'taxe/:id',
                    element: <AddTaxes />
                },
                {
                    path: 'vendeurs',
                    element: <SalesPersonList />
                },
                {
                    path: 'new-vendeur',
                    element: <AddSalesPerson />
                },
                {
                    path: 'vendeur/:id',
                    // element: <AddSalesPerson />
                    element: <EditSalesPerson />
                },
                {
                    path: 'vendeur-tree',
                    element: <SalesPersonTree />
                },
                {
                    path: 'dashboard',
                    element: <CoreDashboard />
                },
                {
                    path: 'discount',
                    element: <Discount />
                },
                {
                    path: 'new-discount',
                    element: <DiscountForm />
                },
                {
                    path: 'edit-discount/:id',
                    element: <DiscountForm />
                },
                {
                    path: 'taux-de-change',
                    element: <Change_CurrenciesList />
                },
                {
                    path: 'new-taux-de-change',
                    element: <Change_Currencies_Form />
                },
                {
                    path: 'taux-de-change/:id',
                    element: <Change_Currencies_Form />
                },
                {
                    path: 'pricing-rules',
                    element: <PricingRulesList />
                },
                {
                    path: 'pricing-rule/:id',
                    element: <PricingRuleForm />
                },
                {
                    path: 'pricing-rule',
                    element: <PricingRuleForm />
                },
                {
                    path: 'coupon',
                    children: [
                        {
                            path: 'code',
                            element: <AddCouponCode />
                        },
                        {
                            path: 'code-detail/:id',
                            element: <AddCouponCode />
                        },
                        {
                            path: 'code-list',
                            element: <CouponCodeList />
                        }
                    ]
                },
                {
                    path: '/core/global-settings',
                    element: <Settings />
                }
            ]
        },

        {
            path: 'oms',
            children: [
                {
                    path: 'new-company',
                    element: <AddNewCompany />
                },
                {
                    path: 'edit-company/:id',
                    element: <AddNewCompany />
                },
                {
                    path: 'company',
                    element: <CompanyList />
                },
                {
                    path: 'company-tree',
                    element: <CompanyTree />
                },
                {
                    path: 'magasins',
                    element: <Warehouse />
                },
                {
                    path: 'magasins-tree',
                    element: <WarehouseTree />
                },
                {
                    path: 'new-magasin',
                    element: <AddNewWarehouse />
                },
                {
                    path: 'edit-magasin/:id',
                    element: <AddNewWarehouse />
                },
                {
                    path: 'depots',
                    element: <DepotsList />
                },
                {
                    path: 'depots-tree',
                    element: <DepotTree />
                },
                {
                    path: 'new-depots',
                    element: <AddNewDepot />
                },
                {
                    path: 'depots/:id',
                    element: <AddNewDepot />
                },
                {
                    path: 'dashboard',
                    element: <OmsDashboard />
                },
                {
                    path: 'supplier',
                    element: <SupplierList />
                },
                {
                    path: 'supplier-details/:id',
                    element: <SupplierForm />
                },
                {
                    path: 'fournisseur-bulk-edit',
                    element: <BulkEditSuppForm />
                },
                {
                    path: 'add-contact/:id',
                    element: <AddSupplierContact />
                },
                {
                    path: 'supplier-catalog/:id',
                    element: <SupplierCatalog />
                },
                {
                    path: 'edit-supplier/:id',
                    element: <EditSupplierCatalog />
                },
                {
                    path: 'new-supplier',
                    element: <SuppliersForm doc={undefined} isEdit={undefined} isDuplicating={false} />
                },
                {
                    path: 'duplicate-supplier',
                    element: <SuppliersForm doc={undefined} isEdit={undefined} isDuplicating={true} />
                },
                {
                    path: 'supplier-settings/',
                    element: <SupplierCodeSettings />
                },
                //{
                //   path: 'new-supplier-settings',
                //  element: <SupplierCodeSettingsForm />
                //},
                //{
                // path: 'edit-supplier-settings/:id',
                //  element: <SupplierCodeSettingsForm />
                //},
                {
                    path: 'supplier-category-list',
                    element: <SupplierGroupList />
                },
                {
                    path: 'new-supplier-category',
                    element: <SupplierGroupForm />
                },
                {
                    path: 'supplier-view/:id',
                    element: <SupplierGroupDetails />
                },
                {
                    path: 'supplier-category-tree-view',
                    element: <SupplierGroupTree />
                },
                {
                    path: 'price_list',
                    element: <PriceList />
                },
                {
                    path: 'new-pricelist',
                    element: <PriceListForm />
                },
                {
                    path: 'edit-pricelist/:id',
                    element: <EditTarifachat />
                },
                {
                    path: 'buying-settings/',
                    element: <BuyingSettings />
                },
                {
                    path: 'new-pos',
                    element: <AddNewPOS />
                },
                {
                    path: 'pos-edit/:id',
                    element: <POSForm />
                },
                {
                    path: 'new-pos/:id',
                    element: <AddNewPOS />
                },
                {
                    path: 'pos',
                    element: <POSList />
                },
                {
                    path: 'pos-details/:id',
                    element: <HistoryPosProfile />
                },
                {
                    path: 'profiles',
                    element: <PosProfile />
                },
                {
                    path: 'new-profiles',
                    element: <AddNewProfile />
                },
                {
                    path: 'role-profile/:id',
                    element: <RoleDetails />
                },
                {
                    path: 'edit-role-profile/:id',
                    element: <AddNewProfile />
                },
                {
                    path: 'user-list-view',
                    element: <UserList />
                },
                {
                    path: 'new-user',
                    element: <AddNewUser />
                },
                {
                    path: 'edit-user/:id',
                    element: <AddNewUser />
                },
                {
                    path: 'roles',
                    element: <RolesList />
                },
                {
                    path: 'edit-roles/:id',
                    element: <AddNewRoles />
                },
                {
                    path: 'connected-user',
                    element: <UserConnectedList />
                },
                {
                    path: 'user/account-profile',
                    element: <AccountSettings />
                }
            ]
        },

        {
            path: 'pim',
            children: [
                {
                    path: 'dashboard',
                    element: <DashboardPIM />
                },
                {
                    path: 'canaux',
                    element: <CanauxList />
                },
                {
                    path: 'new-channel',
                    element: <AddNewChannel />
                },
                {
                    path: 'edit-channel/:id',
                    element: <AddNewChannel />
                },
                {
                    path: 'products-view',
                    element: <PimProducts />
                },
                {
                    path: 'etiquette',
                    element: <EtiquetteBarcode />
                },
                {
                    path: 'product-modele/:id',
                    element: <TemplateProdDetails />
                },
                {
                    path: 'products-modele-view',
                    element: <PimProductsModele />
                },
                {
                    path: 'products-modele-view/:id',
                    element: <TemplateProdDetails />
                },
                {
                    path: 'product-view/:id',
                    element: <TemplateProdDetails />
                },
                {
                    path: 'edit-product-provision/:id',
                    element: <ServiceProdDetails />
                },
                {
                    path: 'products-variant-view',
                    element: <PimProductsVariant />
                },
                {
                    path: 'products-variant-view/:id',
                    element: <TemplateProdVariantDetails />
                },
                {
                    path: 'category-view/:id',
                    element: <ItemGroupDetails />
                },
                {
                    path: 'products-category-list-view',
                    element: <ItemGroupList />
                },
                {
                    path: 'products-category-list-view/:id',
                    element: <CatgoryItemList />
                },
                {
                    path: 'products-category-tree-view',
                    element: <ItemGroupTree />
                },
                {
                    path: 'products-new-category',
                    element: <AddCategoryForm />
                },
                {
                    path: 'add-products',
                    element: <AddItemTemplateForm />
                },
                {
                    path: 'add-products-service',
                    element: <AddItemPrestationForm />
                },
                {
                    path: 'add-products-variant',
                    element: <AddItemVariantTemplateForm />
                },
                {
                    path: 'product-attributes',
                    element: <ItemAttributesList />
                },
                {
                    path: 'products-new-attribute',
                    element: <AddItemAttributeForm />
                },
                {
                    path: 'product-attribute/:id',
                    element: <ItemAttributeEdit />
                },
                {
                    path: 'products-bulk-edit',
                    element: <BulkEditForm />
                },
                {
                    path: 'customize',
                    element: <CustomizeFormItem />
                },
                {
                    path: 'product-collections',
                    element: <ItemCollectionList />
                },
                {
                    path: 'product-collection/:id',
                    element: <ItemCollectionEdit />
                },
                {
                    path: 'product-collection',
                    element: <ItemCollectionForm />
                },
                {
                    path: 'add-products-bundle',
                    element: <AddItemBundleForm />
                },
                {
                    path: 'product-bundles',
                    element: <ProductBundleList />
                },
                {
                    path: 'product-bundle/:id',
                    element: <ProductBundleForm />
                },
                {
                    path: 'product-bundle',
                    element: <ProductBundleForm />
                },
                {
                    path: 'product-view/:id/channels',
                    element: <ItemChannelsForm />
                },
                {
                    path: 'product-files',
                    element: <AllFilesList />
                },
                {
                    path: 'uoms',
                    element: <UOMList />
                },
                {
                    path: 'uom/:id',
                    element: <EditUOMForm />
                },
                {
                    path: 'family-measures',
                    element: <FOMList />
                },
                {
                    path: 'family-measure/:id',
                    element: <FOMForm />
                },
                {
                    path: '/pim/family-measure-update/:id',
                    element: <FOMFormEdit />
                },
                {
                    path: 'family-measure',
                    element: <FOMForm />
                },
                {
                    path: '/pim/tarifs',
                    element: <Tarifs />
                },
                {
                    path: '/pim/add-tarif',
                    element: <AddTarif />
                },
                {
                    path: '/pim/edit-tarif/:id',
                    element: <EditTarif />
                },
                {
                    path: 'table_libre',
                    element: <Libre />
                },
                {
                    path: 'new-libre',
                    element: <TablelibreForm />
                },
                {
                    path: 'edit-libre/:id',
                    element: <TablelibreForm />
                },
                {
                    path: 'soustable-libre',
                    element: <SousTableLibre />
                },
                {
                    path: 'new-soustable-libre',
                    element: <SousTablelibreForm />
                },
                {
                    path: 'edit-soustable-libre/:id',
                    element: <SousTablelibreForm />
                },
                {
                    path: 'uom',
                    element: <UOMForm />
                },
                {
                    path: 'uom-bulk-edit',
                    element: <BulkEditUomForm />
                },
                {
                    path: 'pricing-rules',
                    element: <TarifPricingRulesList />
                },
                {
                    path: 'pricing-rule/:id',
                    element: <TarifPricingRuleForm />
                },
                {
                    path: 'pricing-rule',
                    element: <TarifPricingRuleForm />
                },
                {
                    path: 'promotional_scheme',
                    element: <PromotionalScheme />
                },
                {
                    path: 'new-promotional_scheme',
                    element: <PromotionalSchemeForm />
                },
                {
                    path: 'edit-promotional_scheme/:id',
                    element: <PromotionalSchemeForm />
                },
                {
                    path: 'tarif-article',
                    element: <ItemPriceList />
                },
                {
                    path: 'new-tarif-article',
                    element: <ItemPriceForm />
                },
                {
                    path: 'tarif-article/:id',
                    element: <ItemPriceForm />
                },
                {
                    path: 'product-prefix',
                    element: <ProductNamingSeries />
                },
                {
                    path: 'coupon',
                    children: [
                        {
                            path: 'code',
                            element: <AddCouponCodeTarif />
                        },
                        {
                            path: 'code-detail/:id',
                            element: <AddCouponCodeTarif />
                        },
                        {
                            path: 'code-list',
                            element: <CouponCodeListTarif />
                        }
                    ]
                },
                {
                    path: 'measure',
                    children: [
                        {
                            path: 'uom',
                            element: <UOMForm />
                        }
                    ]
                },
                {
                    path: '/pim/products-provision',
                    element: <ProductPrestationList />
                },
                {
                    path: '/pim/service-provision',
                    element: <ServicePrestationList />
                },
                {
                    path: '/pim/add-products-service',
                    element: <AddItemPrestationForm />
                },
                {
                    path: '/pim/products-financier',
                    element: <ProductFinancierList />
                },
                {
                    path: '/pim/add-product-financier',
                    element: <ProductFinancierAdd />
                },
                {
                    path: '/pim/product-financier/:id',
                    element: <ProductFinancierEdit />
                },
                {
                    path: '/pim/add-service-provision',
                    element: <ServicePrestationAdd />
                },
                {
                    path: '/pim/edit-service-provision/:id',
                    element: <ServicePrestationEdit />
                },
                {
                    path: '/pim/famille-products-view',
                    element: <FamilleProductList />
                },
                {
                    path: '/pim/edit-famille-produit/:id',
                    element: <FamilleProductEdit />
                },
                {
                    path: '/pim/add-famille-products',
                    element: <FamilleProductAdd />
                },
                {
                    path: '/pim/sous-famille-products-view',
                    element: <SousFamilleProductList />
                },
                {
                    path: '/pim/edit-sous-famille-produit/:id',
                    element: <SousFamilleProductEdit />
                },
                {
                    path: '/pim/add-sous-famille-products',
                    element: <SousFamilleProductAdd />
                },
                {
                    path: 'barcode_settings/',
                    element: <BarcodeSettings />
                },
                {
                    path: 'new-brand',
                    element: <BrandForm />
                },
                {
                    path: 'brand',
                    element: <Brand />
                },
                {
                    path: 'edit-brand/:id',
                    element: <BrandForm />
                }
            ]
        },
        {
            path: 'crm',
            children: [
                {
                    path: 'loyatly-programs',
                    element: <LoyaltyProgramList />
                },
                {
                    path: 'loyatly-program',
                    element: <LoyaltyProgramForm />
                },
                {
                    path: 'loyatly-program/:id',
                    element: <LoyaltyProgramForm />
                },
                {
                    path: 'customer',
                    element: <CustomerList />
                },
                {
                    path: 'loyatly-cards',
                    element: <LoyaltyCardList />
                },
                {
                    path: 'loyatly-card/:id',
                    element: <LoyaltyCardForm />
                },
                {
                    path: 'loyatly-card',
                    element: <LoyaltyCardForm />
                },
                {
                    path: 'customer-category-list-view',
                    element: <CustomerGroupList />
                },
                {
                    path: 'customer-new-category',
                    element: <CustomerGroupForm />
                },
                {
                    path: 'category-view/:id',
                    element: <CustomerGroupDetails />
                },
                {
                    path: 'customer-category-tree-view',
                    element: <CustomerGroupTree />
                }
            ]
        },
        {
            path: 'coupon',
            children: [
                {
                    path: 'code',
                    element: <AddCouponCode />
                },
                {
                    path: 'code-detail/:id',
                    element: <AddCouponCode />
                },
                {
                    path: 'code-list',
                    element: <CouponCodeList />
                }
            ]
        },
        // {
        //   path: 'global-settings',
        //  element: <Settings />
        // },
        // {
        //  path: 'global-settings',
        // children: [
        {
            //   path: 'global-settings',
            // element: <Settings />
            //   },
            // {
            //   path: 'stock-settings',
            //   element: <StockSettings />
            //  }
            // ]
            // },
            //{
            //path: 'global-settings',
            // children: [
            //{
            // path: 'global-settings',
            // element: <Settings />
            //},
            // {
            // path: 'system-settings',
            // element: <SystemSettings />
            //  }
            // ]
        },
        {
            path: 'qrcode',
            children: [
                {
                    path: 'list',
                    element: <QrCode />
                },
                {
                    path: 'add-qrcode',
                    element: <AddNewQrcode />
                },
                {
                    path: 'edit-qrcode/:id',
                    element: <EditQrcode />
                }
            ]
        },
        {
            path: 'documents',
            children: [
                {
                    path: 'sales-docs',
                    element: <SalesDocumentsList />
                },
                {
                    path: 'sales-docs/:id',
                    element: <SalesDocumentsDetails />
                },
                {
                    path: 'buying-docs',
                    element: <BuyingDocumentsList />
                },
                {
                    path: 'buying-docs/:id',
                    element: <BuyingDocumentsDetails />
                },
                {
                    path: 'inventory-docs',
                    element: <InventoryDocumentsList />
                },
                {
                    path: 'inventory-docs/:id',
                    element: <InventoryDocumentsDetails />
                },
                {
                    path: 'pos-docs',
                    element: <POSDocumentsList />
                },
                {
                    path: 'pos-docs/:id',
                    element: <POSDocumentsDetails />
                },
                {
                    path: 'pos-docs/coffre-operation/:id',
                    element: <CoffreOperationDetails />
                }
            ]
        }
    ]
};

export default MainRoutes;
