// assets
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import { ROLE } from '../configs/constants';

// constant
const icons = { LeaderboardIcon };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    type: 'group',
    role: [ROLE.ADMIN, ROLE.ADMIN_WAREHOUSE],
    children: [
        {
            id: 'default',
            title: 'common:dash',
            type: 'item',
            url: '/sales/dashboard',
            icon: icons.LeaderboardIcon,
            breadcrumbs: false
        }
    ]
};

export default dashboard;
