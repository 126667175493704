import React, { useEffect, useState } from 'react';
import { TextField, Box } from '@mui/material';
import { modalStyle } from 'views/utilities/ModalStyle';
import { LoadingButton } from '@mui/lab';
import MainCard from 'ui-component/cards/MainCard';
import { useTranslation } from 'react-i18next';

const RenameForm = ({ state, setState, message_success, title, handleClose, handleRename }) => {
    const [loadingButton, setLoadingButton] = useState(false);
    const [localNewName, setLocalNewName] = useState(state.new_name);
    const { t } = useTranslation();

    useEffect(() => {
        setLocalNewName(state.new_name);
    }, [state.new_name]);

    const handleChange = (event) => {
        const updatedName = event.target.value;
        setLocalNewName(updatedName);
        setState((prevState) => ({ ...prevState, new_name: updatedName }));
    };

    const onRename = async () => {
        setLoadingButton(true);
        await handleRename(localNewName);
        setLoadingButton(false);
        handleClose();
    };

    const isRenameDisabled = localNewName.trim() === '' || localNewName.trim() === state.docname;

    return (
        <Box sx={modalStyle}>
            <MainCard title={title} divider>
                <TextField
                    name="new_name"
                    value={localNewName}
                    onChange={handleChange}
                    required
                    sx={{ width: '100%' }}
                    label={t('crm:new_name')}
                    id="outlined-size-small"
                />
                <Box display={'flex'} justifyContent={'flex-end'} mt={3}>
                    <LoadingButton
                        loading={loadingButton}
                        disabled={isRenameDisabled}
                        onClick={onRename}
                        variant="contained"
                        color="primary"
                        size="large"
                    >
                        {t('crm:rename')}
                    </LoadingButton>
                </Box>
            </MainCard>
        </Box>
    );
};

export default RenameForm;
